import { object, string } from "yup";

import validBirthday from "../../../utils/birthdayValid";
import validCancellationDate from "../../../utils/cancellationDateValid";
import validDesiredDelivery from "../../../utils/desiredDeliveryValid";
import { isValidDateAsString } from "../../../utils/isValidDate";
import validMoveInDate from "../../../utils/moveInDateValid";
import validReadingDate from "../../../utils/readingDateValid";
import useFeatureInputPage from "../../TCInputs/hooks/useFeatureInputPage";
import useFeaturesCC from "../hooks/useFeaturesCC";

export const useValidation = (
  moveInDateDaysInPast: number,
  moveInDateMonthsInFuture: number,
  requestedDesiredDateDaysInAdvanceEnergy: number,
  requestedDesiredDateDaysMax: number,
  switchAccountLandHolder: boolean
) => {
  const { cityNotFound, maLoIDNotValid } = useFeaturesCC();
  const { zipCodeValidationText } = useFeatureInputPage();

  const { getMinAge, getMaxAge } = validBirthday("", 18, 124);
  const { minReadingDate, maxReadingDate } = validReadingDate("", 6, 0);
  const { minMovingInDate, maxMovingInDate } = validMoveInDate("", moveInDateDaysInPast, moveInDateMonthsInFuture);
  const { minDesiredDeliveryDate, maxDesiredDeliveryDate } = validDesiredDelivery(
    "",
    requestedDesiredDateDaysInAdvanceEnergy,
    requestedDesiredDateDaysMax
  );
  const { minCancellationDate, maxCancellationDate } = validCancellationDate("", -6, 0);
  return object().shape({
    iban: string()
      .test({
        name: "germanRequired",
        exclusive: false,
        message: "Nur deutsche IBAN erlaubt",
        test: (value, context) => {
          if (
            !switchAccountLandHolder &&
            !value?.startsWith("DE") &&
            context?.parent?.paymentOptions !== "bankTransfer" &&
            value != undefined
          ) {
            return false;
          }
          return true;
        },
      })
      .test({
        name: "max",
        exclusive: false,
        message: "IBAN zu lang",
        test: (v) => {
          const value = v?.replaceAll(" ", "");
          if (value?.startsWith("DE")) {
            return value?.length <= 22;
          }
          if (value?.startsWith("CH")) {
            return value?.length <= 21;
          }
          if (value?.startsWith("AT")) {
            return value?.length <= 20;
          }
          return true;
        },
      })
      .test({
        name: "min",
        exclusive: false,
        message: "IBAN zu kurz",
        test: (v) => {
          const value = v?.replaceAll(" ", "");
          if (value?.startsWith("DE")) {
            return value?.length >= 22;
          }
          if (value?.startsWith("CH")) {
            return value?.length >= 21;
          }
          if (value?.startsWith("AT")) {
            return value?.length >= 20;
          }
          return true;
        },
      })
      .test({
        name: "wrongCheckSum",
        exclusive: false,
        message: "IBAN ungültig",
        test: (v) => {
          const value = v?.replaceAll(" ", "");
          if (value?.length !== undefined && value?.length >= 6 && value?.slice(4).match("[^0-9]") === null) {
            const reorganizedIBAN = value
              ?.slice(4)
              .concat(
                String(parseInt(value?.slice(0, 1), 36)),
                String(parseInt(value?.slice(1, 2), 36)),
                value?.slice(2, 4)
              );
            return BigInt(reorganizedIBAN) % BigInt(97) === BigInt(1);
          }
          return true;
        },
      })
      .test({
        name: "wrong",
        exclusive: false,
        message: "IBAN ungültig",
        test: (_, context) => context?.parent?.IBANError,
      }),
    safetyIndex: string().when("$isShowPassword", {
      is: true,
      then: string()
        .matches(/^\d{4}$/, "Sicherheitskennzahl muss vierstellig sein")
        .required("Eingabe erforderlich"),
      otherwise: string().notRequired(),
    }),
    bic: string().min(8, "BIC zu kurz").max(11, "BIC zu lang"),
    phoneNumber: string().min(6, "Telefonnummer unvollständig"),
    mobileNumber: string().min(6, "Mobilnummer unvollständig"),
    faxNumber: string().min(6, "Faxnummer unvollständig"),
    birthday: string()
      .test({
        name: "validDate",
        message: "Datum ungültig",
        test: (value) => {
          return isValidDateAsString(value ?? "") || value?.length === 0 || value === undefined;
        },
      })
      .test({
        name: "minAge",
        message: "Mindestalter 18 Jahre",
        test: (value) => {
          if (value?.length === 10) {
            const maxDate = getMinAge();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const birthday = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return birthday <= maxDate;
          }
          return true;
        },
      })
      .test({
        name: "maxAge",
        message: "Datum liegt zu weit zurück",
        test: (value) => {
          if (value?.length === 10) {
            const minDate = getMaxAge();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const birthday = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return birthday >= minDate;
          }
          return true;
        },
      }),
    readingDate: string()
      .test({
        name: "validDate",
        message: "Datum ungültig",
        test: (value) => {
          return isValidDateAsString(value ?? "") || value?.length === 0 || value === undefined;
        },
      })
      .test({
        name: "minReadingDate",
        message: Math.random() > 0.01 ? "Datum liegt in der Zukunft" : "Haben Sie eine Zeitmaschine?",
        test: (value) => {
          if (value?.length === 10) {
            const maxDate = maxReadingDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate <= maxDate;
          }
          return true;
        },
      })
      .test({
        name: "maxReadingDate",
        message: "Datum liegt zu weit zurück",
        test: (value) => {
          if (value?.length === 10) {
            const minDate = minReadingDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate >= minDate;
          }
          return true;
        },
      }),
    cancellationDate: string()
      .test({
        name: "validDate",
        message: "Datum ungültig",
        test: (value) => {
          return isValidDateAsString(value ?? "") || value?.length === 0 || value === undefined;
        },
      })
      .test({
        name: "minCancelationDate",
        message: "Datum liegt zu weit in der Zukunft",
        test: (value) => {
          if (value?.length === 10) {
            const maxDate = maxCancellationDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate <= maxDate;
          }
          return true;
        },
      })
      .test({
        name: "maxCancelationDate",
        message: "Datum liegt zu weit zurück",
        test: (value) => {
          if (value?.length === 10) {
            const minDate = minCancellationDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate >= minDate;
          }
          return true;
        },
      }),
    desiredDeliveryDate: string()
      .test({
        name: "validDate",
        message: "Datum ungültig",
        test: (value) => {
          return isValidDateAsString(value ?? "") || value?.length === 0 || value === undefined;
        },
      })
      .test({
        name: "minDesiredDeliveryDate",
        message: "Datum liegt zu weit in der Zukunft",
        test: (value) => {
          if (value?.length === 10) {
            const maxDate = maxDesiredDeliveryDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate <= maxDate;
          }
          return true;
        },
      })
      .test({
        name: "maxDesiredDeliveryDate",
        message: "Frühestes Datum in " + requestedDesiredDateDaysInAdvanceEnergy + " Tagen",
        test: (value) => {
          if (value?.length === 10) {
            const minDate = minDesiredDeliveryDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate >= minDate;
          }
          return true;
        },
      }),
    moveInDate: string()
      .test({
        name: "validDate",
        message: "Datum ungültig",
        test: (value) => {
          return isValidDateAsString(value ?? "") || value?.length === 0 || value === undefined;
        },
      })
      .test({
        name: "minMoveInDate",
        message: "Datum liegt zu weit in der Zukunft",
        test: (value) => {
          if (value?.length === 10) {
            const maxDate = maxMovingInDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate <= maxDate;
          }
          return true;
        },
      })
      .test({
        name: "maxMoveInDate",
        message: "Datum liegt zu weit zurück",
        test: (value) => {
          if (value?.length === 10) {
            const minDate = minMovingInDate();
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const valueDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
            return valueDate >= minDate;
          }
          return true;
        },
      }),
    billingZipCode: string()
      .test({
        name: "hasCity",
        message: cityNotFound,
        test: (value, context) => {
          if (value?.length === 5) {
            return context?.parent?.zipCodeError_billingZipCode ?? true;
          }
          return true;
        },
      })
      .test({
        name: "too short",
        message: zipCodeValidationText,
        test: (value) => {
          if (value !== undefined) {
            return value?.length === 5 || value?.length === 0;
          }
          return true;
        },
      }),
    customerNumber: string().test({
      name: "not0",
      message: "Kundennummer darf nicht 0 sein",
      test: (value) => {
        return value !== "0";
      },
    }),
    actualCounterReading: string().test({
      name: "too long",
      message: "Zählerstand zu lang",
      test: (value) => {
        if (value && value.length > 15) {
          return false;
        }
        return true;
      },
    }),

    maLoID: string()
      .test({
        name: "too short",
        message: "Marktlokationsnummer zu kurz",
        test: (value) => {
          if (value && value.length <= 10 && value.length > 0) {
            return false;
          }
          return true;
        },
      })
      .test({
        name: "not correct",
        message: maLoIDNotValid,
        test: (value, context) => {
          if (value && value.length > 0) {
            return (context?.parent?.maloIDError!==undefined)?context?.parent?.maloIDError:true;
          }
          return true;
        },
      }),
  });
};
