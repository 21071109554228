import { useField, useFormikContext } from "formik";
import React from "react";

import { useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useSelectTelco } from "../../../../context/Telco";
import { FormValues } from "../../../../types/FormValues";
import { uniqueArr } from "../../../../utils/uniqueArr";
import { preSupplierValidateKeysEnergy, preSupplierValidateKeysTelco } from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import { checkFormValidationAndRedirect } from "../../hooks/useCheckFormValidationAndRedirect";
import useCheckMaloID from "../../hooks/useCheckMaloID";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";

import FooterButtons from "../FooterButtons";
import Headline from "../Headline";
import Offermode from "../Offermode";
import CounterData from "./CounterData";
import DesiredDeliveryDate from "./DesiredDeliveryDate";
import EVNForm from "./EVNForm";
import ExchangeNewTariffForm from "./ExchangeNewTariffForm";
import InhaberForm from "./InhaberForm";
import NewSupplierForm from "./NewSupplierForm";
import ReasonToChangeBox from "./ReasonToChangeBox";
import RelocationForm from "./RelocationForm";
import SubscribeListForm from "./SubscribeListForm";
import TelephoneInstallationRequest from "./TelephoneInstallationRequest";

const NEXT_STEP = "bankForm";
const PREV_STEP = "addressForm";

const PreSupplierData = ({ disableFooterButtons = false }) => {
  const { values, setFieldError } = useFormikContext<FormValues>();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { selectedTelco } = useSelectTelco();
  const { calcParams } = useCalcParams();
  const tariff = useTariff();
  const [fields] = useField("asOffer");
  const { reasonToChange, mandatoryFields, connectionData, maLoIDNotValid } = useFeaturesCC();

  const { mutateAsync } = useServerValidationForm("preSupplierForm", {
    tarifParameter: calcParams,
    keysToValidate: tariff.id === "7" ? preSupplierValidateKeysTelco : preSupplierValidateKeysEnergy,
  });
  const maloService = useCheckMaloID(values.maLoID);

  useSetFieldWithVPPData("preSupplierForm");

  return (
    <>
      <div>
        <Headline>{connectionData}</Headline>
        <div className="text-sm text-gray-dark min-h-5">{!fields.value && mandatoryFields}</div>
      </div>

      {selectedTelco && tariff.id === "7" ? (
        <>
          <DesiredDeliveryDate />
          <TelephoneInstallationRequest />
          <EVNForm />
          <SubscribeListForm />
          <InhaberForm />
        </>
      ) : (
        <>
          <ReasonToChangeBox options={reasonToChange.options} />
          {values.reasonToChange === "E03" && <NewSupplierForm />}
          {values.reasonToChange === "tarifwechsel" && <ExchangeNewTariffForm />}
          {values.reasonToChange === "E01" && <RelocationForm />}
          <CounterData />
        </>
      )}
      {!disableFooterButtons && (
        <div className="mt-auto">
          <Offermode position={3} />
          <FooterButtons
            onSubmitValidation={async () => {
              let isMaloValid: boolean | undefined = true;

              if (values.maLoID && values.maLoID.length <= 10 && values.maLoID.length > 0) {
                setFieldError("maLoID", "Die Marktlokationsnummer ist zu kurz");
                isMaloValid = false;
              } else {
                if (values.maLoID.length > 0) {
                  const malo = maloService();
                  isMaloValid = malo?.isValid;
                }
                if (!isMaloValid) setFieldError("maLoID", maLoIDNotValid);
              }

              const awaited = await mutateAsync(values);
              if (isMaloValid)
                checkFormValidationAndRedirect(awaited, NEXT_STEP, setActivatedPanels, setFieldError, setFormName);
            }}
            onSubmit={() => {
              setFormName(NEXT_STEP);
              setActivatedPanels((prevState) => uniqueArr([...prevState, NEXT_STEP]));
            }}
            onBack={() => setFormName(PREV_STEP)}
          />
        </div>
      )}
    </>
  );
};

export default PreSupplierData;
