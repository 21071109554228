import React from "react"

export const OnePerson = ({ color, strokeWidth=1.5 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="24" viewBox="0 0 45 24" fill="none" className="mb-1 inline">
      <path d="M25.6235 4.68482C25.6235 6.44375 24.1977 7.86964 22.4387 7.86964C20.6798 7.86964 19.2539 6.44375 19.2539 4.68482C19.2539 2.92589 20.6798 1.5 22.4387 1.5C24.1977 1.5 25.6235 2.92589 25.6235 4.68482Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M16.5 16.0954C16.5 12.8153 19.1591 10.1562 22.4392 10.1562C25.7193 10.1562 28.3784 12.8153 28.3784 16.0954V21.9214C28.3784 21.984 28.3277 22.0346 28.2652 22.0346H16.6132C16.5507 22.0346 16.5 21.984 16.5 21.9214V16.0954Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
    </svg>
  );
}

export const TwoPersons = ({ color, strokeWidth=1.5 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="24" viewBox="0 0 46 24" fill="none" className="mb-1 inline">
      <path d="M21.9836 3.93443C21.9836 5.69314 20.5579 7.11886 18.7992 7.11886C17.0405 7.11886 15.6147 5.69314 15.6147 3.93443C15.6147 2.17572 17.0405 0.75 18.7992 0.75C20.5579 0.75 21.9836 2.17572 21.9836 3.93443Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M12.8594 15.3448C12.8594 12.065 15.5181 9.40625 18.7979 9.40625C22.0777 9.40625 24.7364 12.065 24.7364 15.3448V21.1702C24.7364 21.2327 24.6858 21.2833 24.6233 21.2833H12.9725C12.91 21.2833 12.8594 21.2327 12.8594 21.1702V15.3448Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M31.4006 5.90318C31.4006 7.66189 29.9749 9.08761 28.2162 9.08761C26.4575 9.08761 25.0317 7.66189 25.0317 5.90318C25.0317 4.14447 26.4575 2.71875 28.2162 2.71875C29.9749 2.71875 31.4006 4.14447 31.4006 5.90318Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M22.2764 17.3135C22.2764 14.0338 24.9351 11.375 28.2149 11.375C31.4947 11.375 34.1534 14.0338 34.1534 17.3135V23.139C34.1534 23.2014 34.1028 23.2521 34.0403 23.2521H22.3895C22.327 23.2521 22.2764 23.2014 22.2764 23.1389V17.3135Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
    </svg>
  );
}

export const ThreePersons = ({ color, strokeWidth=1.5 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="24" viewBox="0 0 46 24" fill="none" className="mb-1 inline">
      <path d="M16.5993 3.93442C16.5993 5.69312 15.1736 7.11884 13.4149 7.11884C11.6562 7.11884 10.2305 5.69312 10.2305 3.93442C10.2305 2.17571 11.6562 0.75 13.4149 0.75C15.1736 0.75 16.5993 2.17571 16.5993 3.93442Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M7.46484 15.3448C7.46484 12.065 10.1236 9.40625 13.4034 9.40625C16.6831 9.40625 19.3419 12.065 19.3419 15.3448V21.1702C19.3419 21.2326 19.2912 21.2833 19.2288 21.2833H7.57796C7.51549 21.2833 7.46484 21.2326 7.46484 21.1702V15.3448Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M35.4196 3.93442C35.4196 5.69312 33.9939 7.11884 32.2352 7.11884C30.4765 7.11884 29.0508 5.69312 29.0508 3.93442C29.0508 2.17571 30.4765 0.75 32.2352 0.75C33.9939 0.75 35.4196 2.17571 35.4196 3.93442Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M26.2852 15.3448C26.2852 12.065 28.9439 9.40625 32.2237 9.40625C35.5034 9.40625 38.1622 12.065 38.1622 15.3448V21.1702C38.1622 21.2326 38.1115 21.2833 38.0491 21.2833H26.3983C26.3358 21.2833 26.2852 21.2326 26.2852 21.1702V15.3448Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M26.0212 5.90317C26.0212 7.66187 24.5955 9.08759 22.8368 9.08759C21.0781 9.08759 19.6523 7.66187 19.6523 5.90317C19.6523 4.14446 21.0781 2.71875 22.8368 2.71875C24.5955 2.71875 26.0212 4.14446 26.0212 5.90317Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M16.8867 17.3135C16.8867 14.0338 19.5455 11.375 22.8252 11.375C26.105 11.375 28.7637 14.0338 28.7637 17.3135V23.1389C28.7637 23.2014 28.7131 23.252 28.6506 23.252H16.9998C16.9374 23.252 16.8867 23.2014 16.8867 23.1389V17.3135Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
    </svg>
  );
}

export const FourPersons = ({ color, strokeWidth=1.5 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="24" viewBox="0 0 45 24" fill="none" className="mb-1 inline">
      <circle cx="8.28453" cy="3.93443" r="3.18443" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M2.34473 15.3448C2.34473 12.065 5.0035 9.40625 8.28326 9.40625C11.563 9.40625 14.2218 12.065 14.2218 15.3448V21.1702C14.2218 21.2327 14.1711 21.2833 14.1087 21.2833H2.45784C2.39537 21.2833 2.34473 21.2327 2.34473 21.1702V15.3448Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <circle cx="36.933" cy="3.93443" r="3.18443" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M30.9932 15.3448C30.9932 12.065 33.6519 9.40625 36.9317 9.40625C40.2115 9.40625 42.8702 12.065 42.8702 15.3448V21.1702C42.8702 21.2327 42.8196 21.2833 42.7571 21.2833H31.1063C31.0438 21.2833 30.9932 21.2327 30.9932 21.1702V15.3448Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <circle cx="27.513" cy="5.90318" r="3.18443" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M21.5732 17.3135C21.5732 14.0338 24.232 11.375 27.5118 11.375C30.7915 11.375 33.4503 14.0338 33.4503 17.3135V23.139C33.4503 23.2014 33.3997 23.2521 33.3372 23.2521H21.6864C21.6239 23.2521 21.5732 23.2014 21.5732 23.1389V17.3135Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <circle cx="17.7025" cy="5.90318" r="3.18443" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
      <path d="M11.7627 17.3135C11.7627 14.0338 14.4215 11.375 17.7012 11.375C20.981 11.375 23.6398 14.0338 23.6398 17.3135V23.139C23.6398 23.2014 23.5891 23.2521 23.5266 23.2521H11.8758C11.8133 23.2521 11.7627 23.2014 11.7627 23.1389V17.3135Z" fill={color} stroke="var(--default-color-primary)" strokeWidth={strokeWidth}/>
    </svg>
  );
}