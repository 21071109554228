import { useFormikContext } from "formik";
import React from "react";

import EditIcon from "../../../../../components/icons/editIcon";
import { FormValues } from "../../../../../types/FormValues";
import { useEditDataModalContext } from "../../../context/EditDataModalContext";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";

const BankDataInfo = () => {
  const { values } = useFormikContext<FormValues>();
  const { bankTitle, accountData, paymentMethod } = useFeaturesCC();
  const { setShowEditDataModal } = useEditDataModalContext();

  return (
    <section className="grid grid-cols-2 gap-y-2 gap-x-4">
      <Headline>
        {bankTitle}
        <span onClick={() => setShowEditDataModal("bankForm")} id="editBankForm" className="float-right cursor-pointer">
          <EditIcon />
        </span>
      </Headline>
      <section>
        <div className="text-xs text-gray-dark">{bankTitle}</div>
        <div>
          {values.paymentOptions === "directDebit"
            ? paymentMethod.options.find((option) => option.value === "directDebit")?.label
            : (paymentMethod.options.find((option) => option.value === "bankTransfer")?.label ?? "Überweisung")}
        </div>
      </section>
      {values.paymentOptions === "directDebit" && (
        <section>
          <div className="text-xs text-gray-dark">{accountData}</div>
          <div>{`${values.firstNameAccountHolder} ${values.lastNameAccountHolder}`}</div>
          <div>{values.iban && values.iban} </div>
          <div>{values.bic && values.bic} </div>
          <div>{values.bankName && values.bankName}</div>
        </section>
      )}
    </section>
  );
};

export default BankDataInfo;
