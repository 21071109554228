/**
 * Convert a Localized Number in string format to a number object
 *
 * @example
 * // returns 12345.67
 * convertLocalizedStringToNumber("12.345,67");
 *
 * @param {string} string - the string to convert
 * @returns {number} - the converted number
 */

export default function convertLocalizedStringToNumber(string: string) {
 try {
   return Number(string.replaceAll(".", "").replace(",", "."));
 }catch(e){
 return Number(string);
 }
}
