import { useField, useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Media from "../../../../components/View/Media";
import { useTariff } from "../../../../context";
import { FormValues } from "../../../../types/FormValues";
import scrollToTop from "../../../../utils/scrollToTop";
import { useContractConclusionFormName } from "../../context";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useFieldValidation from "../../hooks/useFieldValidation";
import CreateOffer from "../CreateOffer";
import WarningDialog from "../WarningDialog";
import { useWarningDialogContext } from "../../context/WarningDialogContext";

const FooterButtons = ({
  onSubmitValidation,
  onSubmit,
  onBack,
}: {
  onSubmitValidation: () => void;
  onSubmit: () => void;
  onBack: () => void;
}) => {
  const tariff = useTariff();
  const { setShowWarningDialog } = useWarningDialogContext();
  const [{ value: isOffer }] = useField("asOffer");
  const { values } = useFormikContext<FormValues>();
  const { formName } = useContractConclusionFormName();

  const { offerMode, btnBestellenLabel, btnBestellenTelesalesLabel, telesalesSignature, createOffer } = useFeaturesCC();

  const disabled = useFieldValidation(formName);

  const handleClick = React.useCallback(async () => {
    if (isOffer) {
      onSubmit();
    } else {
      try {
        if (formName === "summaryForm") {
          await onSubmit();
        } else {
          await onSubmitValidation();
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    scrollToTop();
  }, [formName, isOffer, onSubmit, onSubmitValidation]);

  return (
    <>
      <Media desktop={true} tablet={true}>
        <div className="flex justify-between gap-y-2 gap-x-4 mt-6 bottom-0 relative">
          <Button
            type="submit"
            variant="secondary"
            className="p-2"
            onClick={() => {
              if ((formName === "contactForm" && tariff.id !== "7") || formName === "options") {
                setShowWarningDialog(true);
              } else {
                onBack();
              }
              scrollToTop();
            }}
          >
            Zurück
          </Button>
          <div className="flex gap-y-2 gap-x-4">
            {offerMode ? <CreateOffer formName={formName}>{createOffer}</CreateOffer> : <div />}
            {telesalesSignature && values?.isRemoteSignature === true ? (
              <Button type="submit" className="p-2" variant="primary" disabled={!disabled} onClick={handleClick}>
                {formName === "summaryForm" ? btnBestellenTelesalesLabel : "Weiter"}
              </Button>
            ) : (
              <Button type="submit" className="p-2" variant="primary" disabled={!disabled} onClick={handleClick}>
                {formName === "summaryForm" ? btnBestellenLabel : "Weiter"}
              </Button>
            )}
          </div>
        </div>
      </Media>
      <Media mobile={true}>
        <div className="grid gap-y-2 gap-x-4 mt-6 mb-4 relative">
          <div>{offerMode ? <CreateOffer formName={formName}>{createOffer}</CreateOffer> : <div />}</div>
          <div className="flex justify-between gap-y-2 gap-x-4">
            <Button
              type="submit"
              variant="secondary"
              className="p-2"
              onClick={() => {
                if (formName === "contactForm") {
                  setShowWarningDialog(true);
                } else {
                  onBack();
                }
                scrollToTop();
              }}
            >
              Zurück
            </Button>
            {telesalesSignature && values?.isRemoteSignature ? (
              <Button type="submit" className="p-2" variant="primary" disabled={!disabled} onClick={handleClick}>
                {formName === "summaryForm" ? btnBestellenTelesalesLabel : "Weiter"}
              </Button>
            ) : (
              <Button type="submit" className="p-2" variant="primary" disabled={!disabled} onClick={handleClick}>
                {formName === "summaryForm" ? btnBestellenLabel : "Weiter"}
              </Button>
            )}
          </div>
        </div>
      </Media>
    </>
  );
};

export default FooterButtons;
