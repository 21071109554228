import * as React from "react";

import { useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useTRInputContext } from "../../../../context/FormInputData";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import formatDecimal from "../../../../utils/formatDecimal";
import RowItem from "../../../TCResults/components/ResultsList/RowItem";
import useFeaturesResult from "../../../TCResults/hooks/useFeaturesResult";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const PersonalDetails = () => {
  const trInputContext = useTRInputContext();
  const { calcParams } = useCalcParams();
  const tariff = useTariff();

  const {
    unitUsage,
    tariffType,
    customerType,
    business,
    private: privateLabel,
    usage,
    tarifftypeName,
    waermepumpe,
    speicherheizung,
    oneTariff,
    twoTariffs,
    gemeinsameMessung,
    getrennteMessung,
  } = useFeaturesResult();
  const { city, measurementHeader } = useFeaturesCC();
  const { HTLabel, NTLabel } = useGeneralLabel();

  return (
    <div className="mt-6 grid gap-1">
      <RowItem
        label={city}
        value={trInputContext?.values?.zipCode + " " + trInputContext?.values?.city?.label}
        invert={true}
      />
      <RowItem label={customerType} value={calcParams.kundenart ? business : privateLabel} />

      {tariff.id === "3" && (
        <RowItem
          label={tarifftypeName}
          value={trInputContext?.values?.tariffType === "3" ? waermepumpe : speicherheizung}
        />
      )}

      {tariff.id === "4" && (
        <RowItem
          label={measurementHeader}
          value={trInputContext?.values?.measurementType === "0" ? gemeinsameMessung : getrennteMessung}
        />
      )}
      {tariff.id !== "2" && (
        <RowItem label={tariffType} value={trInputContext?.values?.meterType === "1,2" ? oneTariff : twoTariffs} />
      )}
      {tariff.id !== "2" && (
        <RowItem
          label={trInputContext?.values?.meterType === "3,4" ? usage + " " + HTLabel : usage}
          value={formatDecimal(calcParams.verbrauchHT, 0) + " " + unitUsage}
        />
      )}
      {tariff.id == "2" && <RowItem label={usage} value={formatDecimal(calcParams.verbrauch, 0) + " " + unitUsage} />}
      {tariff.id !== "2" && trInputContext?.values?.meterType === "3,4" && (
        <RowItem
          label={usage + " " + NTLabel}
          value={formatDecimal(calcParams.verbrauchNT ?? 0, 0) + " " + unitUsage}
        />
      )}
    </div>
  );
};

export default PersonalDetails;
