import { useField, useFormikContext } from "formik";
import * as React from "react";

import { useTRInputContext } from "../../../context/FormInputData";
import { useSelectTariff } from "../../../context/Tariff";
import { useSelectTelco } from "../../../context/Telco";
import type { FormValues } from "../../../types/FormValues";
import validBirthday from "../../../utils/birthdayValid";
import validCancellationDate from "../../../utils/cancellationDateValid";
import validDesiredDelivery from "../../../utils/desiredDeliveryValid";
import { isValidDateAsString } from "../../../utils/isValidDate";
import isValidEmail from "../../../utils/isValidEmail";
import validMoveInDate from "../../../utils/moveInDateValid";
import validReadingDate from "../../../utils/readingDateValid";
import { useMandatoryfields } from "../context/Mandatoryfields";
import handleGEEValidation from "../utils/handleValidation/accept";
import useFeaturesCC from "./useFeaturesCC";

const useFieldValidation = (formName: string) => {
  const {
    mailRepetition,
    digitalSignature,
    prePrint,
    moveInDateDaysInPast,
    moveInDateMonthsInFuture,
    counsellingProtocol,
    sepaSignature,
    requestedDesiredDateDaysMax,
    requestedDesiredDateDaysInAdvanceEnergy,
    showPassword,
    showFax,
    telesalesSignature,
    reasonToChangeMoveExistingCustomer,
  } = useFeaturesCC();
  const ibanError = useField("iban")[1].error;
  const [{ value: isOffer }] = useField("asOffer");
  const mandatoryFieldsCRM = useMandatoryfields();
  const inputContext = useTRInputContext();
  const { selectedTelco } = useSelectTelco();
  const { selectedTariff } = useSelectTariff();
  const { values } = useFormikContext<FormValues>();

  const isTelco = React.useMemo(() => !selectedTariff && selectedTelco, [selectedTariff, selectedTelco]);
  const trInputContext = useTRInputContext();

  return React.useMemo(() => {
    const evaluateField = (
      field: string | boolean,
      isVisible: boolean,
      crmKey: string,
      mandatory: boolean,
      condition = true
    ) => {
      if (isVisible) {
        if (mandatoryFieldsCRM?.includes(crmKey) || mandatory) {
          return (condition && field !== "" && field !== false && field !== undefined) || (condition && field);
        }
        return (
          (field !== "" && field !== false && condition) || (condition && field) || field === "" || field === false
        );
      }
      return true;
    };

    const { isMinReadingDate, isMaxReadingDate } = validReadingDate(values.readingDate, 6, 0);
    const { isOldEnough, isYoungEnough } = validBirthday(values.birthday, 18, 120);
    const { isMoveInDate, isMaxMoveInDate } = validMoveInDate(
      values.moveInDate,
      moveInDateDaysInPast,
      moveInDateMonthsInFuture
    );
    const { isDesiredDeliveryDate, isMaxDesiredDeliveryDate } = validDesiredDelivery(
      values.desiredDeliveryDate,
      requestedDesiredDateDaysInAdvanceEnergy,
      requestedDesiredDateDaysMax
    );
    const { isMinCancellationDate, isMaxCancellationDate } = validCancellationDate(values.cancellationDate, -6, 6);

    const contactFormValidation =
      evaluateField(values.salutation.value, true, "la_anrede", false) &&
      evaluateField(values.title.value, true, "", false) &&
      evaluateField(values.email, true, "la_email", false, isValidEmail(values.email)) &&
      evaluateField(
        values.confirmationEmail,
        mailRepetition,
        "la_email",
        false,
        isValidEmail(values.email) && values.confirmationEmail === values.email
      ) &&
      evaluateField(values.firstName, true, "la_vorname", true) &&
      evaluateField(values.lastName, true, "la_nachname", true) &&
      evaluateField(values.companyName, inputContext?.values?.customerType !== "0", "la_firma", true) &&
      evaluateField(
        values.birthday,
        true,
        "la_geburtsdatum",
        false,
        isValidDateAsString(values.birthday) && isOldEnough() && isYoungEnough()
      ) &&
      evaluateField(values.phoneNumber, true, "la_telefonnummer", false, values.phoneNumber.length > 5) &&
      evaluateField(
        values.safetyIndex,
        showPassword,
        "la_sicherheitskennzahl",
        false,
        values.safetyIndex.length === 4
      ) &&
      evaluateField(values.mobileNumber, true, "la_mobilfunknummer", false, values.mobileNumber.length > 5) &&
      evaluateField(values.faxNumber, showFax, "", false, values.faxNumber.length > 5);

    const addressFormValidation =
      evaluateField(values.houseNumber, true, "la_housenummer", true) &&
      evaluateField(values.city.value, true, "la_ort", true) &&
      evaluateField(values.zipCode, true, "la_plz", true, values.zipCode.length === 5) &&
      evaluateField(values.street.value, true, "la_strasse", true) &&
      evaluateField(values.additionalAddress, true, "", false);

    const billingAddressFormValidation =
      evaluateField(values.billingCompanyName, !values.deliveryIsSameBillingAddress, "", false) &&
      evaluateField(values.billingSalutation.value, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingTitle.value, !values.deliveryIsSameBillingAddress, "", false) &&
      evaluateField(values.billingFirstName, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingLastName, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingLastName, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingZipCode, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingCity.value, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingCity.value, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingStreet.value, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingHouseNumber, !values.deliveryIsSameBillingAddress, "", true) &&
      evaluateField(values.billingAdditionalAddress, !values.deliveryIsSameBillingAddress, "", false);

    const preSupplierFormValidation =
      evaluateField(
        values.desiredDeliveryDate,
        values.reasonToChange === "E03" || values.reasonToChange === "tarifwechsel",
        "terminwunsch",
        false,
        isValidDateAsString(values.desiredDeliveryDate) &&
          isDesiredDeliveryDate() &&
          isMaxDesiredDeliveryDate() &&
          (values.desiredDeliveryDate?.length == 0 ||
            (isValidDateAsString(values.desiredDeliveryDate) && values.desiredDeliveryDate?.length == 10))
      ) &&
      evaluateField(
        values.cancellationDate,
        !!values.hasCanceledPrevSupplier && values.reasonToChange === "E03",
        "kuendigung_datum",
        false,
        isValidDateAsString(values.cancellationDate) &&
          isMinCancellationDate() &&
          isMaxCancellationDate() &&
          (values.cancellationDate?.length == 0 ||
            (isValidDateAsString(values.cancellationDate) && values.cancellationDate?.length == 10))
      ) &&
      evaluateField(
        values.prevSupplierName.value,
        values.reasonToChange === "E03" || values.reasonToChange === "tarifwechsel",
        "vorlieferant_name",
        false
      ) &&
      evaluateField(
        values.customerNumber,
        values.reasonToChange === "E03" || values.reasonToChange === "tarifwechsel",
        "vorlieferant_kundennummer",
        false
      ) &&
      evaluateField(values.counterNumber, true, "zaehlernummer", false) &&
      evaluateField(
        values.readingDate,
        true,
        "zaehlerstandDatum",
        false,
        isValidDateAsString(values.readingDate) &&
          isMinReadingDate() &&
          isMaxReadingDate() &&
          (values.readingDate == undefined ||
            values.readingDate?.length == 0 ||
            (isValidDateAsString(values.readingDate) && values.readingDate?.length == 10))
      ) &&
      evaluateField(values.actualCounterReading, true, "zaehlerstand", false) &&
      evaluateField(values.actualCounterNTReading, trInputContext.values.meterType === "3,4", "zaehlerstand", false) &&
      evaluateField(values.maLoID, true, "zaehlermaloid", false) &&
      evaluateField(
        values.moveInDate,
        values.reasonToChange === "E01",
        "",
        true,
        isValidDateAsString(values.moveInDate) &&
          isMoveInDate() &&
          isMaxMoveInDate() &&
          (values.moveInDate?.length == 0 ||
            (isValidDateAsString(values.moveInDate) && values.moveInDate?.length == 10))
      ) &&
      evaluateField(
        values.customerNumber,
        values.reasonToChange === "E01" && reasonToChangeMoveExistingCustomer && values.isExistingCustomer,
        "vorlieferant_kundennummer",
        true
      );

    const bankDataFormValidation =
      evaluateField(values.iban, values.paymentOptions !== "bankTransfer", "iban", true, !ibanError) &&
      evaluateField(
        values.bic,
        values.paymentOptions !== "bankTransfer",
        "bic",
        false,
        values.bic.length >= 8 && values.bic.length <= 11
      ) &&
      evaluateField(values.bankName, values.paymentOptions !== "bankTransfer", "geldinstitut", false) &&
      evaluateField(values.firstNameAccountHolder, values.paymentOptions !== "bankTransfer", "", true) &&
      evaluateField(values.lastNameAccountHolder, values.paymentOptions !== "bankTransfer", "", true) &&
      evaluateField(
        values.isAgreeToSEPA,
        values.paymentOptions !== "bankTransfer" && !sepaSignature,
        "sepa_erteilt",
        true
      ) &&
      evaluateField(
        values.isSepaRemoteSignature,
        values.paymentOptions !== "bankTransfer" && sepaSignature && telesalesSignature,
        "sepa_erteilt",
        !values.sepa_digitalSignature
      ) &&
      evaluateField(
        values.sepa_digitalSignature,
        values.paymentOptions !== "bankTransfer" &&
          sepaSignature &&
          (!telesalesSignature || !values.isSepaRemoteSignature),
        "sepa_erteilt",
        !values.isSepaRemoteSignature
      );
    const summaryFormValidation =
      evaluateField(values.isAGBCheck, true, "", true) &&
      evaluateField(values.isCancellationCheck, true, "", true) &&
      evaluateField(values.isPrivacyPolicyCheck, true, "", true) &&
      evaluateField(values.invoiceDeliveryBy, true, "", true) &&
      evaluateField(values.acceptCounsellingProtocoll, counsellingProtocol, "", true) &&
      evaluateField(values.pdfUrl, prePrint, "", true) &&
      evaluateField(values.signature, digitalSignature && (!telesalesSignature || !values.isRemoteSignature), "", true);

    if (!isOffer) {
      switch (formName) {
        case "options":
          return true;
        case "contactForm":
          return contactFormValidation;
        case "addressForm":
          return addressFormValidation && billingAddressFormValidation;
        case "deliveryAddress":
          return addressFormValidation;
        case "billingAddress":
          return billingAddressFormValidation;
        case "gee":
          return handleGEEValidation(values, digitalSignature);
        case "preSupplierForm":
          return preSupplierFormValidation;
        case "bankForm":
          return bankDataFormValidation;
        case "summaryForm":
          return (
            bankDataFormValidation &&
            addressFormValidation &&
            preSupplierFormValidation &&
            bankDataFormValidation &&
            summaryFormValidation &&
            (!isTelco || handleGEEValidation(values, digitalSignature))
          );
        default:
          return false;
      }
    }
    if (formName === "summaryForm") {
      return false;
    }
    return true;
  }, [
    formName,
    digitalSignature,
    isOffer,
    prePrint,
    mailRepetition,
    isTelco,
    values,
    ibanError,
    moveInDateDaysInPast,
    moveInDateMonthsInFuture,
    counsellingProtocol,
    sepaSignature,
    requestedDesiredDateDaysMax,
    requestedDesiredDateDaysInAdvanceEnergy,
    mandatoryFieldsCRM,
    showPassword,
    inputContext,
    showFax,
    trInputContext.values.meterType,
    telesalesSignature,
    reasonToChangeMoveExistingCustomer,
  ]);
};

export default useFieldValidation;
