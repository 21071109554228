import { useMutation } from "react-query";

import { useParameter, useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useCalcResult } from "../../../../context/CalculationResult";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSupplierInfo } from "../../../../context/SupplierInfo";
import ProxyInstance from "../../../../services/proxy";
import queryParamSimplifier from "../../../TCInputs/utils/queryParamSimplifier";
import { InputFormModalFormik } from "../../types/InputFormModalFormik";
import { Supplier } from "../../types/Supplier";
import { TariffComparisonModalFormik } from "../../types/TariffComparisonModalFormik";
import useFeaturesResult from "../useFeaturesResult";

const useSubmitTariffResult = (timeframe: boolean) => {
  const { config } = useConfig();
  const tariff = useTariff();
  const { setSupplierInformation } = useSupplierInfo();
  const { kundenart, tariftyp, style, ...restParams } = useParameter();
  const { setCalcResult, calcResult } = useCalcResult();
  const { setCalcParams } = useCalcParams();

  const { paging } = useFeaturesResult();
  const { values: inputContextData } = useTRInputContext();

  return useMutation(
    ["SUBMIT_TARIFF_RESULT"],
    async (
      values: (TariffComparisonModalFormik | InputFormModalFormik) & { baseSupplier?: string; networkOperator?: string }
    ) => {
      const queryParameter = queryParamSimplifier(
        {
          ...restParams,
          // ...calcParams,
          ...inputContextData,
          ...values,
          calculatorType: tariff.id,
          tariftyp: tariff.tariftyp,
          // TODO: fix later? what is missing?
          // grundversorger: calcParams?.grundversorger || undefined,
          // netzbetreiber: calcParams?.netzbetreiber || undefined,
          paging: paging,
        },
        timeframe
      );

      const baseUrl =
        tariff.id === "7"
          ? `/prima/calculation/telco/tr/v1/calculate?${queryParameter}`
          : `/simplifier/calc/${tariff.tariffType}/?${queryParameter}`;

      try {
        const { data } = await ProxyInstance(baseUrl, {
          apikey: config.apikey || "",
        });

        const response = data?.data?.data;

        const vergleichsData = response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis.filter(
          (supplier: Supplier) => {
            return supplier.isVergleichsTarif === true;
          }
        );
        const supplierInfo =
          "compareType" in values && values.compareType === "1"
            ? { compareType: "1" }
            : response?.calculationResult.ergebnisContainer?.[0].versorgerInformation?.[0];

        const suppliertariffName = vergleichsData[0]?.tarifName;
        const suppliertariffGesamtpreisBrutto = vergleichsData[0]?.gesamtpreisBrutto;
        const suppliertariffGrundpreisBrutto = vergleichsData[0]?.grundpreisBrutto;
        const suppliertariffArbeitspreisHTCtKWhBrutto = vergleichsData[0]?.arbeitspreisHTCtKWhBrutto
          ? vergleichsData[0]?.arbeitspreisHTCtKWhBrutto
          : vergleichsData[0]?.arbeitspreisCtKWhBrutto;
        const suppliertariffArbeitspreisNTCtKWhBrutto = vergleichsData[0]?.arbeitspreisNTCtKWhBrutto;
        const suppliertariffGesamtpreisNetto = vergleichsData[0]?.gesamtpreisNetto;
        const suppliertariffGrundpreisNetto = vergleichsData[0]?.grundpreisNetto;
        const suppliertariffArbeitspreisHTCtKWhNetto = vergleichsData[0]?.arbeitspreisHTCtKWhNetto
          ? vergleichsData[0]?.arbeitspreisHTCtKWhNetto
          : vergleichsData[0]?.arbeitspreisCtKWhNetto;
        const suppliertariffArbeitspreisNTCtKWhNetto = vergleichsData[0]?.arbeitspreisNTCtKWhNetto;
        const versorgerName = vergleichsData[0]?.versorgerName;
        const suppliertariff = vergleichsData[0];

        if (supplierInfo) {
          setSupplierInformation({
            ...supplierInfo,
            versorgerName,
            suppliertariffName,
            suppliertariffGesamtpreisBrutto,
            suppliertariffGrundpreisBrutto,
            suppliertariffArbeitspreisHTCtKWhBrutto,
            suppliertariffArbeitspreisNTCtKWhBrutto,
            suppliertariffGesamtpreisNetto,
            suppliertariffGrundpreisNetto,
            suppliertariffArbeitspreisHTCtKWhNetto,
            suppliertariffArbeitspreisNTCtKWhNetto,
            suppliertariff,
          });
        }

        return data;
      } catch (e) {
        return {
          results: [],
          objectId: "",
          parameters: {},
          type: "energie",
        };
      }
    },
    {
      onSuccess: (data) => {
        setCalcResult({
          ...calcResult,
          results: data?.data?.data?.calculationResult?.ergebnisContainer?.[0]?.ergebnis,
          type: tariff.id !== "7" ? "energie" : "telco",
          ergebnisseGesamtAnzahl: data?.data?.data?.calculationResult?.ergebnisseGesamtAnzahl,
        });

        setCalcParams({
          ...data?.data?.data?.calculationParameter,
          dynamischeAngaben: data?.data?.data?.calculationParameter.dynamischeAngaben ?? [
            { dateObj: [], doubleObj: [], intObj: [], stringObj: [] },
          ],
        });
      },
    }
  );
};

export default useSubmitTariffResult;
