import React from "react";

import Button from "../../../../components/Button";
import { useParameter, useStepChange } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import useCreateOffer from "../../hooks/useCreateOffer";

const CreateOffer = ({ formName, children }: { formName: string; children: React.ReactNode }) => {
  const { vppSummaryUrl } = useParameter();
  const { mutateAsync } = useCreateOffer(formName, true, vppSummaryUrl ? "_self" : "_blank");
  const { calcParams } = useCalcParams();
  const { setStep } = useStepChange();

  const handleCreateOffer = React.useCallback(async () => {
    try {
      await mutateAsync(calcParams);
      if (!vppSummaryUrl) {
        setStep(4);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [calcParams, mutateAsync, setStep, vppSummaryUrl]);

  return (
    <Button type="button" variant="secondary" onClick={handleCreateOffer}>
      {children}
    </Button>
  );
};

export default CreateOffer;
