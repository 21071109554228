import { useQuery } from "react-query";

import { useTariff } from "../../../context";
import { useConfig } from "../../../context/Config";
import { useSelectTariff } from "../../../context/Tariff";
import { useSelectTelco } from "../../../context/Telco";
import ProxyInstance from "../../../services/proxy";

const useGetPolicyDocument = () => {
  const { config } = useConfig();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const tariff = useTariff();

  return useQuery(
    `GET_POLICY_DOCUMENT_${selectedTariff?.versorgerID || selectedTelco?.providerId}_${selectedTariff?.tarifRootID || selectedTelco?.rootId}`,
    async () => {
      if (selectedTariff || selectedTelco)
        try {
          const { data } = await ProxyInstance(
            `/documents/urls?calculatorType=${tariff.id}&supplierId=${
              selectedTariff?.versorgerID || selectedTelco?.providerId
            }&tariffRootId=${
              selectedTariff?.tarifRootID || selectedTelco?.rootId
            } `,
            { apikey: config.apikey || "" }
          );

          return data;
        } catch (e) {
          return undefined;
          // console.error(e);
          // throw e;
        }
      else {
        try {
          const { data } = await ProxyInstance(
            `/documents/urls?calculatorType=${tariff.id}`,
            { apikey: config.apikey || "" }
          );

          return data;
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    },
    {
      enabled: !!selectedTariff || !!selectedTelco || !!tariff?.id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );
};

export default useGetPolicyDocument;
