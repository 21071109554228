import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import Tariff from "../../types/Tariff";
import TelcoGridConnection from "../../types/TelcoGridConnection";

export interface calcResult {
  ergebnisseGesamtAnzahl: number;
  results: Tariff[];
  type: "energie" | "telco";
  telcoGridConnection: TelcoGridConnection | undefined;
}

interface calcResultContext {
  calcResult: calcResult;
  setCalcResult: React.Dispatch<React.SetStateAction<calcResult>>;
}

const calculationResult = createContext({} as calcResultContext);

const CalculationResultProvider = ({ children }: { children: React.ReactNode }) => {
  const [calcResult, setCalcResult] = React.useState({} as calcResult);

  return <calculationResult.Provider value={{ calcResult, setCalcResult }}>{children}</calculationResult.Provider>;
};

export default CalculationResultProvider;
export const useCalcResult = () => useContextSelector(calculationResult, (c) => c);
