import ProxyInstance from "../../../services/proxy";

const sendMail = async (tariffId, apikey, authToken, s3FilePath, body, type) =>
  await ProxyInstance(
    type === "customer"
      ? `/mail/sendMailCustomer?calculatorType=${tariffId}&s3FilePath=${s3FilePath}`
      : `/mail/sendMailDistribution?calculatorType=${tariffId}&s3FilePath=${s3FilePath}`,
    {
      apikey: apikey || "",
      authToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    }
  );

export default sendMail;
