import { useFormikContext } from "formik";
import React from "react";

import { useCalcParams } from "../../../../context/CalculationParameter";
import { FormValues } from "../../../../types/FormValues";
import { uniqueArr } from "../../../../utils/uniqueArr";
import { bankDataValidateKeys } from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import { checkFormValidationAndRedirect } from "../../hooks/useCheckFormValidationAndRedirect";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import Offermode from "../Offermode";
import BankDataForm from "./BankDataForm";
import PaymentOptions from "./PaymentOptions";

const NEXT_STEP = "summaryForm";
const PREV_STEP = "preSupplierForm";

const BankData = () => {
  const { values, setFieldError } = useFormikContext<FormValues>();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { calcParams } = useCalcParams();

  const { mutateAsync } = useServerValidationForm("bankForm", {
    tarifParameter: calcParams,
    keysToValidate: bankDataValidateKeys,
  });

  useSetFieldWithVPPData("bankForm");

  return (
    <>
      <PaymentOptions />
      <BankDataForm />
      <div className="mt-auto">
        <Offermode position={4} />
        <FooterButtons
          onSubmitValidation={async () => {
            const awaited = await mutateAsync(values);
            checkFormValidationAndRedirect(awaited, NEXT_STEP, setActivatedPanels, setFieldError, setFormName);
          }}
          onSubmit={() => {
            setFormName(NEXT_STEP);
            setActivatedPanels((prevState) => uniqueArr([...prevState, NEXT_STEP]));
          }}
          onBack={() => setFormName(PREV_STEP)}
        />
      </div>
    </>
  );
};

export default BankData;
