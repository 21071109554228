import { date } from "../types/Tariff";

const formatValidDate = (date: date) => {
  let formatDate;
  let month = null;
  let day = null;

  if (date) {
    if (date?.dayOfMonth) {
      if (date.dayOfMonth.toString().length < 2) {
        day = `0${date.dayOfMonth}`;
      } else {
        day = `${date.dayOfMonth}`;
      }
    }

    if (date?.monthValue) {
      if (date.monthValue.toString().length < 2) {
        month = `0${date.monthValue}`;
      } else {
        month = `${date.monthValue}`;
      }
    }

    const isNotValidFormat = `${date?.year}-${month}-${day}`.includes("undefined");

    if (date && isNotValidFormat) {
      formatDate = "";
    } else {
      formatDate = `${date?.year}-${month}-${day}`;
    }
  } else {
    return "";
  }

  return formatDate;
};

export default formatValidDate;
