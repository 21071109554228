import { useQuery } from "react-query";

import { TYPE } from "../../../Constant";
import { useTariff } from "../../../context";
import { useConfig } from "../../../context/Config";
import ProxyInstance from "../../../services/proxy";

interface supplierItem {
  markenID: number;
  versorgerID: number;
  markenName?: string;
  versorgerName: string;
  dynamischeAngaben: {
    stringObj: {
      name: string;
      wert: string;
    }[];
    dateObj: null;
  }[];
}

const getVerbandsNummer = (item: supplierItem) => {
  let vnb = "";

  item.dynamischeAngaben.forEach((f) => {
    if (f.stringObj) {
      f.stringObj.forEach((d) => {
        if (d.name === "verbandsnummer_lieferant") vnb = d.wert;
      });
    }
  });

  return vnb;
};
const useGetSupplier = () => {
  const tariff = useTariff();
  const { config } = useConfig();

  return useQuery(
    `GET_SUPPLIER_${tariff.tariffType}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/ws/tariff/${tariff.tariffType && tariff.tariffType !== "telco" && TYPE[tariff.tariffType]}/getVersorger`,
          {
            apikey: config.apikey || "",
            ...(tariff.tariffType && tariff.tariffType !== "telco" && TYPE[tariff.tariffType] === "gas"
              ? {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              : { method: "GET" }),
            ...(tariff.tariffType && tariff.tariffType !== "telco" && TYPE[tariff.tariffType] === "gas"
              ? { body: JSON.stringify({}) }
              : {}),
          }
        );

        return data.data.versorger
          .map((item: supplierItem) => {
            return {
              label: item?.markenName ? item.markenName + " eine Marke von " + item.versorgerName : item.versorgerName,
              value: item?.markenName ? item.markenName + " eine Marke von " + item.versorgerName : item.versorgerName,
              key: getVerbandsNummer(item),
            };
          })
          .sort();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetSupplier;
