import { useFormikContext } from "formik";
import React from "react";

import EditIcon from "../../../../../components/icons/editIcon";
import { FormValues } from "../../../../../types/FormValues";
import { useEditDataModalContext } from "../../../context/EditDataModalContext";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";

const AnschlussInfo = () => {
  const { values } = useFormikContext<FormValues>();
  const {
    anschlussdaten,
    reason,
    reasonChangeSupplier,
    reasonRelocation,
    zaehlernummer,
    zaehlerstand,
    readingDate,
    prevProviderTitle,
    nextPossibleDateText,
    deliveryFrom,
    cancelation,
    dateOfCancellation,
    selfCancelation,
    oldCounterNumber,
    moveInDate,
    maLoID,
  } = useFeaturesCC();
  const { setShowEditDataModal } = useEditDataModalContext();

  const reasonToChange = React.useMemo(() => {
    switch (values.reasonToChange) {
      case "E03":
        return reasonChangeSupplier;
      case "tarifwechsel":
        return "Tarifwechsel";
      case "E01":
        return reasonRelocation;
      default:
        return "";
    }
  }, [values.reasonToChange, reasonChangeSupplier, reasonRelocation]);

  return (
    <div>
      <div className="w-full grid grid-cols-2 gap-y-2 gap-x-4">
        <Headline>
          {anschlussdaten}
          <span
            onClick={() => setShowEditDataModal("preSupplierForm")}
            id="editPreSupplierForm"
            className="float-right cursor-pointer"
          >
            <EditIcon />
          </span>
        </Headline>
        {values.reasonToChange ? (
          <div>
            <div className="text-xs text-gray-dark">{reason}</div>
            <div>{reasonToChange}</div>
          </div>
        ) : (
          <div></div>
        )}
        {values.reasonToChange === "E03" ? (
          <div>
            <div className="text-xs text-gray-dark">{deliveryFrom}</div>
            <div>{values?.desiredDeliveryDate ? values?.desiredDeliveryDate : nextPossibleDateText}</div>
          </div>
        ) : (
          <div></div>
        )}
        {values.reasonToChange === "E03" && (
          <>
            {values.prevSupplierName?.value ? (
              <div>
                <div className="text-xs text-gray-dark">{prevProviderTitle}</div>
                <div>{values.prevSupplierName?.value}</div>
              </div>
            ) : (
              <div></div>
            )}
            {values.hasCanceledPrevSupplier === "true" ? (
              <div>
                <div className="text-xs text-gray-dark">{cancelation}</div>
                <div>{selfCancelation}</div>
              </div>
            ) : (
              <div></div>
            )}
            {values.hasCanceledPrevSupplier === "true" && values.cancellationDate ? (
              <div>
                <div className="text-xs text-gray-dark">{dateOfCancellation}</div>
                <div>{values.cancellationDate}</div>
              </div>
            ) : (
              <div></div>
            )}
          </>
        )}
        {values.customerNumber ? (
          <>
            <br />
            <div>
              <div className="text-xs text-gray-dark">{oldCounterNumber}</div>
              <div>{values.customerNumber}</div>
            </div>
          </>
        ) : (
          <div></div>
        )}
      </div>
      {(values.counterNumber ||
        values.actualCounterReading ||
        values.readingDate ||
        values.moveInDate ||
        values.maLoID) && (
        <div className="w-full grid grid-cols-2 gap-y-2 gap-x-4 mt-4">
          {values.counterNumber && (
            <div>
              <div className="text-xs text-gray-dark">{zaehlernummer}</div>
              <div>{values.counterNumber}</div>
            </div>
          )}
          <br />
          {values.actualCounterReading && (
            <div>
              <div className="text-xs text-gray-dark">{zaehlerstand}</div>
              <div>{values.actualCounterReading}</div>
            </div>
          )}
          {values.actualCounterNTReading && (
            <div>
              <div className="text-xs text-gray-dark">{zaehlerstand} NT</div>
              <div>{values.actualCounterNTReading}</div>
            </div>
          )}
          <br />
          {values.readingDate && (
            <div>
              <div className="text-xs text-gray-dark">{readingDate}</div>
              <div>{values.readingDate}</div>
            </div>
          )}
          {values.reasonToChange === "E01" && values.moveInDate && (
            <div>
              <div className="text-xs text-gray-dark">{moveInDate}</div>
              <div>{values.moveInDate}</div>
            </div>
          )}
          {values.maLoID && (
            <div>
              <div className="text-xs text-gray-dark">{maLoID}</div>
              <div>{values.maLoID}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AnschlussInfo;
