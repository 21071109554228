import { formatISO } from "date-fns";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { useMutation } from "react-query";

import toast from "../../../components/Toast";
import { tariffOptionTypes, useParameter, useTariff } from "../../../context";
import { calcParams } from "../../../context/CalculationParameter";
import { config, useConfig } from "../../../context/Config";
import { useTRInputContext } from "../../../context/FormInputData";
import { useSupplierInfo } from "../../../context/SupplierInfo";
import { useSelectTariff } from "../../../context/Tariff";
import { useSelectTelco } from "../../../context/Telco";
import { useSelectTelcoGridConnection } from "../../../context/TelcoGridConnection";

import { Abschlussdatenobjekt } from "../../../types/Abschlussdatenobjekt";
import { FormValues } from "../../../types/FormValues";
import { information, PreisgarantieBis } from "../../TCInputs/types/SimplifierResponse";
import useFeaturesResult from "../../TCResults/hooks/useFeaturesResult";
import handleTariffForms from "../utils/handleTariffForms";
import handleTelcoForms from "../utils/handleTelcoForms";
import sendData from "../utils/sendData";
import sendMailPreSigned from "../utils/sendMailPreSigned";
import vppMappings from "../utils/vppMappings";
import useFeaturesCC from "./useFeaturesCC";
import useGetKeyCloakToken from "./useGetKeyCloakToken";

const useCreateOffer = (formName: string, offer = false, target = "_blank") => {
  const { config } = useConfig();
  const tariff = useTariff();
  const inputContext = useTRInputContext();
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { authToken, vppSummaryUrl, vppCustomerId, externalOfferID } = useParameter();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const { selectedTelcoGridConnection } = useSelectTelcoGridConnection();
  const { supplierInformation } = useSupplierInfo();
  const { prePrintSendMail } = useFeaturesCC();
  const { hideComparisonSelect} = useFeaturesResult();
  const { showNetGross, pricesNetGross } = useFeaturesResult();

  const nettoOrBrutto = useMemo(() => {
    if (showNetGross) {
      return Number(selectedTariff?.priceType === "0");
    }
    if (pricesNetGross === "net") {
      return 0;
    }
    if (pricesNetGross === "gross") {
      return 1;
    }
    return Number(inputContext?.values?.customerType === "0");
  }, [selectedTariff?.priceType, inputContext?.values?.customerType, pricesNetGross, showNetGross]);

  const totalUsage = useMemo(
    () =>
      inputContext.values?.meterType === "3,4"
        ? Number.parseInt(inputContext.values.oneTariff?.replace(".", "")) +
          Number.parseInt(inputContext.values.twoTariff?.replace(".", ""))
        : Number.parseInt(inputContext.values?.oneTariff?.replace(".", "")),
    [inputContext.values?.oneTariff, inputContext.values?.twoTariff, inputContext.values?.meterType]
  );
  return useMutation(
    `CREATE_OFFER_${formName}_${selectedTelco ? "Telco" : "Tariff"}`,
    async (calcParams: calcParams) => {
      try {
        const aktAuthToken: string | undefined = await useGetKeyCloakToken(authToken, config.apikey, vppSummaryUrl);
        if (selectedTariff && tariff.id !== "7") {
          let tempInfoBAI: (Omit<information, "preisgarantieBis"> & { preisgarantieBis: string })[] = [];
          selectedTariff.informationen.forEach((i) => {
            const temp = {
              ...i,
              preisgarantieBis: formatDatePreisgarantieBis(i.preisgarantieBis) as string,
            };
            tempInfoBAI.push(temp);
          });
          const formData = {
            portalDaten: handleTariffForms(
              {
                ...selectedTariff,
                ...values,
                zipCode: values.zipCode || inputContext.values.zipCode,
                city: values.city.value?.split("|")?.[0]?.trim()
                  ? {
                      value: values.city.value?.split("|")?.[0]?.trim(),
                      label: values.city.value?.split("|")?.[0]?.trim(),
                    }
                  : inputContext.values.city,
                authToken,
                tariffId: tariff.id || "1",
                usage: calcParams?.verbrauchHT,
                verbandsnummer: supplierInformation?.marktrollen?.[0]?.verbandsnummer || "",
                medium: tariff.medium === "gas" ? 1 : 0,
                ...(aktAuthToken ? { authToken: aktAuthToken } : {}),
                ...(externalOfferID ? { externalOfferID: externalOfferID } : {}),
                ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
                abschlussstatus: "angebot",
              },
              { bindingOffer: offer ? !!vppSummaryUrl : false }
            ),
           vergleichsErgebnis: !hideComparisonSelect?supplierInformation.suppliertariff:{},
            tarifErgebnis: {
              ...selectedTariff,
              informationen: tempInfoBAI,
            },
            tarifParameter: { ...calcParams, netzbetreiberName: supplierInformation?.netzbetreibername },
            vertriebspartnerportal:
              selectedTariff &&
              vppMappings({
                value: {
                  ...selectedTariff,
                  tariftyp: tariff.tariftyp || "",
                  verbrauchGesamt: totalUsage,
                  anzeigeNettoBrutto: nettoOrBrutto,
                  asOffer: values.asOffer,
                  measurementType: inputContext.values.measurementType,
                },
              }),
          };
          return handleMutation({
            formData,
            tariffId: tariff.id,
            authToken: aktAuthToken,
            vppSummaryUrl,
            config,
            offer,
            prePrintSendMail,
          });
        }

        if (selectedTelco && tariff.id === "7") {
          const formData = {
            portalDaten: handleTelcoForms(
              {
                ...values,
                ...selectedTelco,
                tariffId: tariff.id || "1",
                verbandsnummer: "",
                objectId: selectedTelco.objectId || "",
                authToken,
                abschlussstatus: "angebot",
              },
              { bindingOffer: false }
            ),
            tarifErgebnis: selectedTelco,
            telcoGridConnection: selectedTelcoGridConnection,
            tarifParameter: {},
            vertriebspartnerportal: {
              bestelldatumZeit: values.asOffer ? "" : formatISO(new Date()),
              VParbeitspreisCtKWhNetto: 0,
              VParbeitspreisCtKWhBrutto: 0,
              VParbeitspreisHTCtKWhNetto: 0,
              VParbeitspreisHTCtKWhBrutto: 0,
              VPgrundpreisNetto: 0,
              VPgrundpreisBrutto: 0,
              VPgrundpreisMonatNetto: 0,
              VPgrundpreisMonatBrutto: 0,
              VPgesamtpreisOhneBoniNetto: 0,
              VPgesamtpreisOhneBoniBrutto: 0,
              VPgesamtpreisNetto: 0,
              VPgesamtpreisBrutto: 0,
              VPgesamtpreisMonatNetto: 0,
              VPgesamtpreisMonatBrutto: 0,
              anzeigeNettoBrutto: 1,
              // auftragsstatus: 1,
              lasttyp: "slp",
              priceType: 0,
              verbrauchGesamt: 20000,
              boni: [],
              VPgesamtpreisMonatOhneBoniNetto: 0,
              VPgesamtpreisMonatOhneBoniBrutto: 0,
              VParbeitspreisNTCtKWhNetto: 0,
              VParbeitspreisNTCtKWhBrutto: 0,
              aufschlaege: [],
              auftragsstatus: values.asOffer ? 1 : 11,
              id_person: undefined,
              ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
            },
          };

          return handleMutation({
            formData,
            tariffId: tariff.id,
            authToken: aktAuthToken,
            vppSummaryUrl,
            config,
            offer,
            prePrintSendMail,
          });
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      onSuccess: (url) => {
        setFieldValue("pdfUrl", url);

        if (url && typeof window !== "undefined") {
          window.open(url, target);
        }
      },
      onError: (error) => {
        if (error instanceof Error) {
          toast.error(error.message || "Bitte versuchen Sie nochmal!");
        }
      },
    }
  );
};

const formatDatePreisgarantieBis = (datum: PreisgarantieBis | null) => {
  let formatDate: string | PreisgarantieBis;
  let month: string | null = null;
  let day: string | null = null;

  if (datum) {
    if (datum?.dayOfMonth) {
      if (datum.dayOfMonth.toString().length < 2) {
        day = `0${datum.dayOfMonth}`;
      } else {
        day = `${datum.dayOfMonth}`;
      }
    }

    if (datum?.monthValue) {
      if (datum.monthValue.toString().length < 2) {
        month = `0${datum.monthValue}`;
      } else {
        month = `${datum.monthValue}`;
      }
    }

    const isNotValidFormat = `${datum?.year}-${month}-${day}`.includes("undefined");

    if (datum && isNotValidFormat) {
      formatDate = datum;
    } else {
      formatDate = `${datum?.year}-${month}-${day}`;
    }
  } else return "";

  return formatDate;
};
async function handleMutation({
  formData,
  tariffId,
  config,
  authToken,
  vppSummaryUrl,
  offer,
  prePrintSendMail,
}: {
  formData: Abschlussdatenobjekt;
  tariffId: tariffOptionTypes;
  config: config;
  authToken: string | undefined;
  vppSummaryUrl: string;
  offer: boolean;
  prePrintSendMail: boolean;
}) {
  const abschlussDaten = [formData];
  const body = { jsonDaten: { abschlussDaten } };

  const { data } = await sendData(tariffId, config.apikey, authToken, body);

  const response = data;

  if (response?.error) {
    throw new Error(response?.error?.message);
  } else {
    if (!offer) {
      const s3FilePathPreSigned = response?.data?.s3FilePathPresigned;

      let externFileList = "";
      const formattedData = {
        ...formData,
        portalDaten: {
          ...formData.portalDaten,
          attachments: formData.portalDaten.attachments.map(
            (v: string | number | boolean) => (externFileList += encodeURIComponent(v).concat(";"))
          ),
        },
      };

      if (
        formattedData.portalDaten.la_email &&
        formattedData.portalDaten.la_email.length > 0 &&
        prePrintSendMail &&
        s3FilePathPreSigned
      ) {
        const resMailCustomer = await sendMailPreSigned(
          tariffId,
          config.apikey,
          authToken,
          s3FilePathPreSigned,
          "Vertragszusammenfassung.pdf",
          externFileList,
          body,
          "customer"
        );

        if (resMailCustomer) {
          console.info("Mail versand");
        }
      }
    }
  }
  if (response?.data?.cim?.id_templatedata && vppSummaryUrl) {
    if (typeof window !== "undefined") {
      return `${vppSummaryUrl}${response?.data?.cim?.id_templatedata.toString()}`;
    }
  } else if (!response?.data?.presignedDownloadUrl) {
    toast.error("Fehler beim PDF erstellen");

    return undefined;
  }
  return response?.data?.presignedDownloadUrl;
}

export default useCreateOffer;
