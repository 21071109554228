import React from "react";

import useExternalConfigs from "../../../hooks/useExternalConfigs";

const useCancellationFeatures = () => {
  const { data: config } = useExternalConfigs("contractconclusion");
  const cancellationForm = config?.data?.data?.cancellationForm;

  const startOfCancellationLabels = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.startOfCancellationLabels || {
        label: "Zu welchem Termin wolle Sie kündigen?",
        options: [
          {
            label: "zum nächstmöglichem Termin",
            value: "nextPossibleDate",
          },
          {
            label: "zu meinem Wunschtermin",
            value: "desiredDate",
          },
        ],
      }
    );
  }, [config?.data?.data?.functionscontractConclusion?.startOfCancellationLabels]);
  const internalTariffChange = React.useMemo(
    () => cancellationForm?.internalTariffChange ?? true,
    [cancellationForm?.internalTariffChange]
  );
  const desiredDateLabel = React.useMemo(
    () => cancellationForm?.internalTariffChange || "Wunschtermin",
    [cancellationForm?.internalTariffChange]
  );

  return {
    startOfCancellationLabels,
    internalTariffChange,
    desiredDateLabel,
  };
};
export default useCancellationFeatures;
