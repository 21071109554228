import * as React from "react";

import ErrorMessage from "../components/ErrorMessage";
import LoadingOverlay from "../components/LoadingOverlay";
import TerminationFormInputDataProvider from "../context/CancellationData";
import useAPIKey from "../hooks/useAPIKey";
import useFrontendConfig from "../hooks/useFrontendConfig";
import useStep from "../hooks/useStep";
import { getTariffType } from "../utils/getTariffType";
import { StepFormNameProvider } from "../widgets/TCContractConclusion/context/provider";
import CalculationParameter from "./CalculationParameter";
import CalculationResult from "./CalculationResult";
import ExternLocationIdProvider from "./ExternLocationId";
import FormInputDataProvider from "./FormInputData";
import GridConnectionStatusProvider from "./GridConnectionStatus";
import {
  configContext,
  parameterContext,
  stepChangeContext,
  tariffIdContext,
} from "./index";
import SupplierInfo from "./SupplierInfo";
import TariffProvider from "./Tariff";
import TelcoProvider from "./Telco";

const checkTRTypeId = (id) => {
  switch (id) {
    case 2:
      return {
        tariffCalculatorTypeId: id,
        tariftyp: "",
        medium: "gas",
      };

    case 7:
      return {
        tariffCalculatorTypeId: id,
        tariftyp: "7",
        medium: "telco",
      };

    case 3:
      return {
        tariffCalculatorTypeId: id,
        tariftyp: "3",
        medium: "heating",
      };

    case 4:
      return {
        tariffCalculatorTypeId: id,
        tariftyp: "1",
        medium: "autostrom",
      };

    default:
      return {
        tariffCalculatorTypeId: id,
        tariftyp: "1,2",
        medium: "electricity",
      };
  }
};

const RootProvider = ({
  children,
  boxWidth,
  media,
  tariffCalculatorTypeId,
  ...props
}) => {
  const [tariff, setTariffId] = React.useState(
    checkTRTypeId(tariffCalculatorTypeId)
  );
  const [step, setStep] = useStep();
  const { data: frontendConfig, isLoading } = useFrontendConfig();
  const { data: apikey } = useAPIKey();

  const tariffType = React.useMemo(
    () => getTariffType(tariff?.tariffCalculatorTypeId),
    [tariff?.tariffCalculatorTypeId]
  );

  if (isLoading && !tariff) {
    return <LoadingOverlay />;
  }

  if (frontendConfig) {
    if (
      frontendConfig?.calculatorTypeIDs.length === 0 ||
      !frontendConfig?.calculatorTypeIDs.includes(`${tariffCalculatorTypeId}`)
    ) {
      return (
        <ErrorMessage
          text={`Fehler: tariffCalculatorTypeId ${tariffCalculatorTypeId} ist nicht verfügbar!`}
        />
      );
    }
  }

  return (
    <configContext.Provider value={{ apikey, frontendConfig, media, boxWidth }}>
      <FormInputDataProvider>
        <TerminationFormInputDataProvider>
          <ExternLocationIdProvider>
            <SupplierInfo>
              <GridConnectionStatusProvider>
                <CalculationParameter>
                  <CalculationResult>
                    <TariffProvider>
                      <TelcoProvider>
                        <tariffIdContext.Provider
                          value={{
                            id: tariff?.tariffCalculatorTypeId,
                            tariffType,
                            tariftyp: tariff?.tariftyp,
                            medium: tariff?.medium,
                            setTariffId,
                          }}
                        >
                          <parameterContext.Provider
                            value={{
                              tariffCalculatorTypeId:
                                tariff?.tariffCalculatorTypeId,
                              ...props,
                            }}
                          >
                            <stepChangeContext.Provider
                              value={{ step, setStep }}
                            >
                              <StepFormNameProvider>
                                {children}
                              </StepFormNameProvider>
                            </stepChangeContext.Provider>
                          </parameterContext.Provider>
                        </tariffIdContext.Provider>
                      </TelcoProvider>
                    </TariffProvider>
                  </CalculationResult>
                </CalculationParameter>
              </GridConnectionStatusProvider>
            </SupplierInfo>
          </ExternLocationIdProvider>
        </TerminationFormInputDataProvider>
      </FormInputDataProvider>
    </configContext.Provider>
  );
};

export default RootProvider;
