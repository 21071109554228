import { format } from "date-fns";
import React from "react";

import Divider from "../../../../../components/Divider";
import { useTariff } from "../../../../../context";
import { useSelectTelco } from "../../../../../context/Telco";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";
import AddressInfo from "./AddressInfo";
import AnschlussInfo from "./AnschlussInfo";
import BankDataInfo from "./BankDataInfo";
import PersonalDataInfo from "./PersonalDataInfo";
import TelcoInfo from "./TelcoInfo";

const OrderConfirmation = () => {
  const { selectedTelco } = useSelectTelco();
  const tariff = useTariff();
  const { summaryTitle } = useFeaturesCC();
  return (
    <div className="grid gap-6 mb-6">
      <div>
        <Headline>{summaryTitle}</Headline>
        <div className="text-sm text-gray-dark">{format(new Date(), "dd.MM.yyyy")}</div>
      </div>
      <Divider />
      <PersonalDataInfo />
      <Divider />
      <AddressInfo />
      <Divider />
      <AnschlussInfo />
      <Divider />
      {selectedTelco && tariff.id === "7" && <TelcoInfo />}
      <BankDataInfo />
    </div>
  );
};

export default OrderConfirmation;
