import { useField, useFormikContext } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import { SALUTATION_LIST, TITLE_LIST } from "../../../../Constant";
import { useCalcParams } from "../../../../context/CalculationParameter";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import { FormValues } from "../../../../types/FormValues";
import {
  addressDataValidateKeys,
  gee_acceptPrivacyPolicy,
  kenntnisnahme_ausfuehrung_vor_widerrufsfrist,
} from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import { checkFormValidationAndRedirect } from "../../hooks/useCheckFormValidationAndRedirect";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useGetPolicyDocument from "../../hooks/useGetPolicyDocument";
import useQueryCityByZipCode from "../../hooks/useQueryCityByZipCode";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import DigitalSignature from "../DigitalSignature";
import FooterButtons from "../FooterButtons";
import Headline from "../Headline";

const NEXT_STEP = "preSupplierForm";
const PREV_STEP = "addressForm";

const GeeData = () => {
  const { values, setFieldError } = useFormikContext<FormValues>();
  const { setFormName } = useContractConclusionFormName();
  const { calcParams } = useCalcParams();
  const [{ value }] = useField("ownerSameAsCustomer");
  const [gee_zipCode] = useField("gee_zipCode");
  const [gee_street] = useField("gee_street");
  const [gee_city] = useField("gee_city");
  const { setActivatedPanels } = useActivatedPanels();
  const mandatoryFieldsCRM = useMandatoryfields();
  const { responsiveClass, responsiveColSpan } = useResponsiveClass();

  const { data: geeListStreet } = useQueryCityByZipCode({
    zipCode: "gee_zipCode",
    city: "gee_city",
    street: "gee_street",
    houseNumber: "gee_houseNumber",
  });

  const { mutateAsync } = useServerValidationForm("addressForm", {
    tarifParameter: calcParams,
    keysToValidate: addressDataValidateKeys,
  });

  const {
    salutationOptions,
    salutation,
    title,
    firstName,
    lastName,
    companyName,
    birthday,
    zipCode,
    city,
    street,
    houseNumber,
    addition,
    mandatoryFields,
    email,
    mailRepetition,
    emailValidate,
    mobileNumber,
    phoneNumber,
    showFax,
    faxNumber,
  } = useFeaturesCC();
  const { mandatoryLabel } = useGeneralLabel();
  const [fields] = useField("asOffer");
  const { data: res } = useGetPolicyDocument();

  const einwilligung = React.useMemo(() => {
    return (
      <>
        Ich gestatte mittels <a href={res?.data?.privacyUrl}>Gestattungserklärung</a> gegenüber UGG die notwendigen
        Maßnahmen zur Errichtung und dauerhaftem Betrieb der erforderlichen Netzanlagen.
        {!fields.value ? mandatoryLabel : ""}
      </>
    );
  }, [res?.data?.privacyUrl]);

  return (
    <section className="grid grid-cols-1 gap-y-2">
      <div>
        <Headline>GEE</Headline>
        <div className="text-sm text-gray-dark min-h-5">{!fields.value && mandatoryFields}</div>
      </div>
      <Checkbox name="ownerSameAsCustomer" label="Der Eigentümer ist identisch mit dem Kunden" />

      {!value && (
        <div className={responsiveClass("grid gap-y-2 gap-x-4", "grid-cols-1", "grid-cols-2", "grid-cols-4")}>
          <div className="col-span-full">
            <Headline>Kontaktdaten</Headline>
            <div className="text-sm text-gray-dark min-h-5">Nur durch den Grundstückseigentümer auszufüllen</div>
          </div>
          <div className={responsiveColSpan("full", "2", "2")}>
            <Input name="gee_companyName" label={companyName} placeholder="Firma" inputType="firma" />
          </div>
          <GetSelect
            name="gee_salutation"
            label={salutation}
            options={salutationOptions || SALUTATION_LIST}
            className=""
          />
          <GetSelect name="gee_title" options={TITLE_LIST} label={title} />
          <div className={responsiveColSpan("full", "1", "2")}>
            <Input name="gee_firstName" label={firstName} placeholder="Vorname" inputType="name" />
          </div>
          <div className={responsiveColSpan("full", "1", "2")}>
            <Input name="gee_lastName" label={lastName} placeholder="Nachname" inputType="name" />
          </div>
          <Input type="datepicker" name="gee_birthday" className="w-full" label={birthday} />
          <div className="col-start-1">
            <Input
              name="gee_email"
              label={email + (!fields?.value && mandatoryFieldsCRM?.includes("la_email") ? mandatoryLabel : "")}
              placeholder="E-Mail"
            />
          </div>
          {mailRepetition && (
            <div className={responsiveColSpan("full", "2", "2")}>
              <Input
                name="gee_confirmationEmail"
                label={
                  emailValidate + (!fields?.value && mandatoryFieldsCRM?.includes("la_email") ? mandatoryLabel : "")
                }
                placeholder="E-Mail-Adresse bestätigen"
              />
            </div>
          )}
          <div>
            <Input
              name="gee_mobileNumber"
              label={
                mobileNumber +
                (!fields?.value && mandatoryFieldsCRM?.includes("la_mobilfunknummer") ? mandatoryLabel : "")
              }
              placeholder="Mobilnummer"
              onlyNumber
              maxLength={16}
            />
          </div>
          <div>
            <Input
              name="gee_phoneNumber"
              label={
                phoneNumber + (!fields?.value && mandatoryFieldsCRM?.includes("la_telefonnummer") ? mandatoryLabel : "")
              }
              placeholder="Telefonnummer"
              onlyNumber
              maxLength={16}
            />
          </div>
          {showFax && (
            <div>
              <Input name="gee_faxNumber" label={faxNumber} placeholder="Faxnummer" onlyNumber maxLength={16} />
            </div>
          )}

          <Headline>Anschrift des Grundstückseigentümers</Headline>
          <Input name="gee_zipCode" label={zipCode} placeholder="PLZ" />
          <div className={responsiveColSpan("full", "1", "3")}>
            <GetSelect
              name="gee_city"
              label={city}
              options={geeListStreet?.ortList ?? []}
              disabled={
                geeListStreet?.ortList === undefined ||
                geeListStreet?.ortList.length === 0 ||
                gee_zipCode.value.length !== 5
              }
              defaultOpen={
                gee_zipCode.value.length === 5 &&
                geeListStreet?.ortList &&
                geeListStreet?.ortList.length >= 1 &&
                gee_city?.value === ""
              }
            />
          </div>
          <div className={responsiveColSpan("full", "2", "2")}>
            <GetSelect
              label={street}
              name="gee_street"
              options={geeListStreet?.street || []}
              isSearchable
              disabled={geeListStreet?.street === undefined || geeListStreet?.street.length === 0}
              defaultOpen={
                geeListStreet?.street &&
                gee_city?.value !== "" &&
                gee_street?.value === "" &&
                gee_zipCode.value.length === 5
              }
            />
          </div>
          <Input name="gee_houseNumber" label={houseNumber} placeholder="Hausnummer" inputType="numberGreater0" />
          <Input name="gee_additionalAddress" label={addition} placeholder="Adresszusatz" inputType="address" />

          <Headline>Wohneinheiten der Installationsadresse</Headline>
          <Input name="gee_wohneinheiten" label="Anzahl Wohneinheiten" inputType="numberGreater0" />
        </div>
      )}
      <div>
        <Headline>Optins des Grundstückseigentümers</Headline>
        <div className="grid my-4 gap-y-4">
          <Checkbox name="kenntnisnahme_gee" labelComponent={einwilligung} />
          <Checkbox
            name="kenntnisnahme_ausfuehrung_vor_widerrufsfrist"
            label={kenntnisnahme_ausfuehrung_vor_widerrufsfrist + (!fields.value ? mandatoryLabel : "")}
          />
          <Checkbox
            name="gee_acceptPrivacyPolicy"
            label={gee_acceptPrivacyPolicy + (!fields.value ? mandatoryLabel : "")}
          />
        </div>
      </div>

      <DigitalSignature name="gee_digitalSignature" label={"Unterschrift" + (!fields.value ? mandatoryLabel : "")} />
      <div className="mt-auto">
        <FooterButtons
          onSubmitValidation={async () => {
            const awaited = await mutateAsync(values);
            checkFormValidationAndRedirect(awaited, NEXT_STEP, setActivatedPanels, setFieldError, setFormName);
          }}
          onSubmit={() => {
            setFormName(NEXT_STEP);
          }}
          onBack={() => setFormName(PREV_STEP)}
        />
      </div>
    </section>
  );
};

export default GeeData;
