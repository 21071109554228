import format from "date-fns/format";

import { uuid } from "./uuid";

const formatDate = (val) => val.split(".").reverse().join("-");

const convertKeys = (values, to) => {
  const commonObj = {
    bestelldatum: values.asOffer ? "" : format(new Date(), "yyyy-MM-dd"),
    vertriebspartner_id: values.supplierId,
    tariffCalculatorTypeId: `${values.calculatorType}`,
    get_vorgangs_id: uuid(),
    vertragsnummer: uuid(),
  };

  const contactObj = Object.entries(values).reduce((acc, [key, val]) => {
    switch (key) {
      case "birthday":
        return { ...acc, la_geburtsdatum: formatDate(val) };
      case "companyName":
        return { ...acc, la_firma: val || null };
      case "email":
        return { ...acc, la_email: val };
      case "firstName":
        return { ...acc, la_vorname: val };
      case "salutation":
        return { ...acc, la_anrede: val.value };
      case "lastName":
        return { ...acc, la_nachname: val };
      case "phoneNumber":
        return { ...acc, la_telefonnummer: val };
      case "safetyIndex":
        return { ...acc, la_sicherheitskennzahl: val };

      default:
        return acc;
    }
  }, {});

  const addressObj = Object.entries(values).reduce((acc, [key, val]) => {
    switch (key) {
      case "zipCode":
        return { ...acc, la_plz: val };
      case "city":
        return { ...acc, la_ort: val };
      case "street":
        return { ...acc, la_strasse: val.value || val };
      case "houseNumber":
        return { ...acc, la_hausnummer: val };
      default:
        return acc;
    }
  }, {});

  const preSupplierObj = Object.entries(values).reduce((acc, [key, val]) => {
    if (values.calculatorType === 7) {
      switch (key) {
        case "joinSubscribeList":
          return {
            ...acc,
            teilnehmerverzeichnis: val === "false" ? "Nein" : "Ja",
          };

        case "telephoneInstallationRequest":
          return {
            ...acc,
            rufnummeruebernahme: val === "false" ? "old" : "new",
          };

        case "desiredDeliveryDate":
          return {
            ...acc,
            terminwunsch: val ? formatDate(val) : "",
          };

        default:
          return acc;
      }
    } else {
      switch (key) {
        case "prevSupplierName":
          return {
            ...acc,
            vorlieferant_name: val.value ?? "",
          };
        case "counterNumber":
          return {
            ...acc,
            zaehlernummer: val ?? null,
          };
        case "customerNumber":
          return { ...acc, vorlieferant_kundennummer: val ?? null };
        case "cancellationDate":
          return {
            ...acc,
            kuendigung_datum: val ? formatDate(val) : "",
          };
        case "desiredDeliveryDate":
          return {
            ...acc,
            terminwunsch: val ? formatDate(val) : "",
          };
        case "hasCanceledPrevSupplier":
          return { ...acc, kuendigung_vorversorger: val === "true" };
        case "reasonToChange":
          return { ...acc, einzug_umzug: val };
        default:
          return acc;
      }
    }
  }, {});

  const bankDataObj = Object.entries(values).reduce((acc, [key, val]) => {
    switch (key) {
      case "bic":
        return { ...acc, bic: val ?? null };
      case "iban":
        return { ...acc, iban: val ?? null };
      case "bankName":
        return { ...acc, geldinstitut: val ?? null };
      case "isAgreeToSEPA":
        return { ...acc, sepa_erteilt: val };
      default:
        return acc;
    }
  }, {});

  const summaryDataObj = Object.entries(values).reduce((acc, [key, val]) => {
    switch (key) {
      case "customerRecruitment":
        return { ...acc, werber_identnummer: val };

      default:
        return acc;
    }
  }, {});

  if (to === "contactForm") {
    return { ...contactObj, ...commonObj };
  }

  if (to === "addressForm" || to === "gee") {
    return { ...contactObj, ...addressObj, ...commonObj };
  }

  if (to === "preSupplierForm") {
    return {
      ...contactObj,
      ...addressObj,
      ...preSupplierObj,
      ...commonObj,
    };
  }

  if (to === "bankForm") {
    return {
      ...contactObj,
      ...addressObj,
      ...preSupplierObj,
      ...bankDataObj,
      ...commonObj,
    };
  }

  if (to === "summaryForm") {
    return {
      ...contactObj,
      ...addressObj,
      ...preSupplierObj,
      ...bankDataObj,
      ...summaryDataObj,
      ...commonObj,
    };
  }
};

export default convertKeys;
