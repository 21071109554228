export const contactDataValidateKeys = [
  "la_anrede",
  "la_email",
  "la_vorname",
  "la_nachname",
  "la_firma",
  "la_geburtsdatum",
  "la_telefonnummer",
  "la_sicherheitskennzahl",
];

export const addressDataValidateKeys = [
  "la_hausnummer",
  "la_ort",
  "la_plz",
  "la_strasse",
];

export const preSupplierValidateKeysEnergy = [
  "einzug_umzug",
  "kuendigung_datum",
  "kuendigung_vorversorger",
  "terminwunsch",
  "vorlieferant_kundennummer",
  "vorlieferant_name",
  "zaehlernummer",
];

export const preSupplierValidateKeysTelco = [
  "teilnehmerverzeichnis",
  "rufnummeruebernahme",
  "terminwunsch",
];

export const bankDataValidateKeys = [
  "bic",
  "geldinstitut",
  "iban",
  "sepa_erteilt",
];

export const summaryDataValidateKeys = ["werber_identnummer"];

export const contactDataInitialValues = {
  birthday: "",
  companyName: "",
  confirmationEmail: "",
  email: "",
  faxNumber: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  phoneNumber: "",
  salutation: "",
  title: "",
  safetyIndex: "",
  showPassword: false,
};

export const addressDataInitialValues = {
  additionalAddress: "",
  billingAdditionalAddress: "",
  billingCity: "",
  billingCompanyName: "",
  billingFirstName: "",
  billingHouseNumber: "",
  billingLastName: "",
  billingMailbox: "",
  billingSalutation: "",
  billingStreet: "",
  billingTitle: "",
  billingZipCode: "",
  city: "",
  deliveryIsSameBillingAddress: true,
  houseNumber: "",
  street: "",
  zipCode: "",
};

export const geeDataInitialValues = {
  ownerSameAsCustomer: true,
  gee_companyName: "",
  gee_salutation: "",
  gee_title: "",
  gee_firstName: "",
  gee_lastName: "",
  gee_birthday: "",
  gee_zipCode: "",
  gee_city: "",
  gee_street: "",
  gee_houseNumber: "",
  gee_additionalAddress: "",
  kenntnisnahme_gee: false,
  kenntnisnahme_ausfuehrung_vor_widerrufsfrist: false,
  gee_acceptPrivacyPolicy: false,
  werbeeinwilligung_fax: false,
  werbeeinwilligung_telefon: false,
  werbeeinwilligung_email: false,
};

export const addressDataInstallationInitialValues = {
  installationAdditionalAddress: "",
  installationCity: "",
  installationCompanyName: "",
  installationFirstName: "",
  installationHouseNumber: "",
  installationLastName: "",
  additionalAddress: "",
  installationSalutation: "",
  installationStreet: "",
  installationTitle: "",
  installationZipCode: "",
  deliveryIsSameInstallationAddress: true,
};

export const preSupplierDataInitialValues = {
  actualCounterReading: "",
  cancellationPreSupplier: "",
  counterNumber: "",
  customerNumber: "",
  deliveryDate: "",
  maLoID: "",
  moveInDate: "",
  newCounterNumber: "",
  prevSupplierName: "",
  reasonToChange: "E03",
  cancellationDate: "",
  hasCanceledPrevSupplier: "false",
  desiredDeliveryDate: "",
  hasDesiredDeliveryDate: "false",
  inhaberFirstName: "",
  inhaberLastName: "",
  joinSubscribeList: "false",
  telephoneInstallationRequest: "true",
  itemizedBill: "false",
  currentInstalledTelephoneNumber: "",
  previousSubscriber_firstname: "",
  previousSubscriber_lastname: "",
  previousSubscriber_company: "",
  isNewCustomer: true,
  newKundennummer: "",
};

export const bankDataInitialValues = {
  bankName: "",
  bic: "",
  firstNameAccountHolder: "",
  iban: "",
  lastNameAccountHolder: "",
  paymentOptions: "directDebit",
  isDifferentAccountHolder: false,
  isAgreeToSEPA: false,
};

export const summaryInitialValues = {
  isPreisListCheck: false,
  isServiceDescriptionCheck: false,
  isAGBCheck: false,
  isCancellationCheck: false,
  isPrivacyPolicyCheck: false,
  invoiceDeliveryBy: "post",
  promotionalConsentByMail: false,
  promotionalConsentByPhone: false,
  promotionalConsentByPost: false,
  customerSatisfactionSurveyByMail: false,
  customerSatisfactionSurveyByPhone: false,
  customerSatisfactionSurveyByPost: false,
  customerRecruitment: "",
  signature: "",
  attachment: [],
};

export const sepaButtonText = "SEPA-Lastschriftmandat erteilen *";
export const sepaDescription =
  "SEPA-Lastschrift-Mandat: Der Kontoinhaber ermächtigt den Anbieter, fällige Zahlungs- und Rechnungsbeträge mittels Lastschrift einzuziehen. Das kontoführende Kreditinstitut wird hiermit vom Kontoinhaber angewiesen, die auf dieses Konto gezogenen Lastschriften einzulösen. Hinweis: Innerhalb von 8 Wochen kann der Kontoinhaber, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die Bedingungen, die der Kontoinhaber mit seinem Kreditinstitut vereinbart hat.";
export const kenntnisnahme_ausfuehrung_vor_widerrufsfrist =
  "Ich bin damit einverstanden und verlange ausdrücklich, dass UGG vor Ende der Widerrufsfrist mit der Ausführung der beauftragten Dienstleistung beginnt. Mir ist bekannt, dass ich bei vollständiger Vertragserfüllung durch UGG mein Widerrufsrecht verliere. *";
export const gee_acceptPrivacyPolicy =
  "Ich habe die Datenschutzerklärung mit den Informationen zur Verarbeitung meiner personenbezogenen Daten des Versorgers Unsere Grüne Glasfaser GmbH & Co. KG zur Kenntnis genommen.  *";
