import { FormValues } from "../../../../types/FormValues";

const handleGEEValidation = (values: FormValues, isEnabledDigitalSignature: boolean) => {
  return (
    (!!values.gee_acceptPrivacyPolicy &&
      !!values.kenntnisnahme_gee &&
      !!values.kenntnisnahme_ausfuehrung_vor_widerrufsfrist &&
      !isEnabledDigitalSignature) ||
    !!values?.gee_digitalSignature
  );
};

export default handleGEEValidation;
