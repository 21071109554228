import { useFormikContext } from "formik";
import * as React from "react";

import { FormValues } from "../../../../../types/FormValues";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Subsection from "../Subsection";

const TelcoInfo = () => {
  const { values } = useFormikContext<FormValues>();
  const { startOfDelivery, nextPossibleDateText, prevProviderTitle, shortEvn, fullEvn, noEvn, prevTenant, evnTitle } =
    useFeaturesCC();
  const itemizedBill = React.useMemo(() => {
    switch (values.itemizedBill) {
      case "true":
        return shortEvn;
      case "false":
        return noEvn;
      case "full":
        return fullEvn;
      default:
        return "";
    }
  }, [values.itemizedBill]);
  return (
    <>
      <Subsection
        text={startOfDelivery.label}
        value={values.hasDesiredDeliveryDate === true ? values.desiredDeliveryDate : nextPossibleDateText}
      />
      <Subsection
        text="Rufnummer(n)"
        value={
          values.telephoneInstallationRequest === "false"
            ? "Bestehende Rufnummer(n)"
            : "neue Rufnummer (Sie erhalten die von Ihnen gewünschte Anzahl an Rufnummern)"
        }
      />
      <div className="mb-2">
        {values.telephoneInstallationRequest === "false" && (
          <>
            <div className="mb-2">
              {values.currentInstalledTelephoneNumber.split(",").map((tel) => {
                return (
                  <ul key={tel}>
                    <li>{tel}</li>
                  </ul>
                );
              })}
            </div>
            <div className="mb-2">
              {prevProviderTitle}:
              {values.prevSupplierName && (values.prevSupplierName?.value ?? values.prevSupplierName)}
            </div>
            <Subsection
              text="Anschlussinhaber"
              value={`${values.previousSubscriber_company} ${values.previousSubscriber_firstname} ${values.previousSubscriber_lastname}`}
            />
          </>
        )}
      </div>
      <Subsection text={evnTitle} value={itemizedBill} />
      <Subsection
        text="Eintrag Teilnehmerverzeichnis"
        value={
          values.joinSubscribeList === "false"
            ? "Nein, ich wünsche keinen Eintrag"
            : "Ja, ich wünsche einen Eintrag ins Teilnehmerverzeichnis"
        }
      />
      {values.inhaberFirstName && values.inhaberLastName && (
        <Subsection text={prevTenant} value={`${values.inhaberFirstName} ${values.inhaberLastName}`} />
      )}
    </>
  );
};

export default TelcoInfo;
