import { Formik } from "formik";
import React from "react";

import Form from "./components/Form";
import useSendContactForm from "./hooks/useSendContactForm";
import { RequestBody } from "./types/body";
import schema from "./validation/schema";

const TCContactForm = () => {
  const { mutateAsync } = useSendContactForm();

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        questionForm: { label: "", value: "" },
        customerType: { label: "", value: "" },
        zipCode: "",
        street: { label: "", value: "" },
        city: { label: "", value: "" },
        houseNumber: { label: "", value: "" },
        message: "",
        phone: "",
        email: "",
        firstName: "",
        lastName: "",
        isAcceptPrivacyPolicy: false,
      }}
      onSubmit={async (values) => {
        if (typeof window !== "undefined") {
          const body: RequestBody = {
            Datenschutzbestimmungen: values.isAcceptPrivacyPolicy
              ? "Ich habe die aktuellen Datenschutzbestimmungen gelesen und akzeptiere diese."
              : "",
            Email: values.email,
            Frage: values.questionForm.value,
            Hausnummer: values.houseNumber.value,
            Kundenart: values.customerType.value,
            Nachricht: values.message,
            Name: `${values.firstName} ${values.lastName}`,
            Ort: values.city?.value ?? values.city,
            PLZ: values.zipCode,
            Strasse: values.street.value,
            Telefon: values.phone,
            Webseite: window.location.hostname,
          };

          await mutateAsync(body);
        }
      }}
      component={Form}
    />
  );
};

export default TCContactForm;
