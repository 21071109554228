import { useField, useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Checkbox";
import Modal from "../../../../components/Modal";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSelectTariff } from "../../../../context/Tariff";
import { useSelectTelco } from "../../../../context/Telco";
import scrollToTop from "../../../../utils/scrollToTop";
import { useContractConclusionFormName } from "../../context";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import handleGEEValidation from "../../utils/handleValidation/accept";
import handleAddressValidation from "../../utils/handleValidation/address";
import handleBankValidation from "../../utils/handleValidation/bank";
import handleContactValidation from "../../utils/handleValidation/contact";
import handlePreSupplierValidation from "../../utils/handleValidation/preSupplier";
import handleSummaryValidation from "../../utils/handleValidation/summary";
import CreateOffer from "../CreateOffer";

const FooterButtons = ({ onSubmitValidation, onSubmit, onBack }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [{ value: isOffer }] = useField("asOffer");
  const { values } = useFormikContext();
  const { formName } = useContractConclusionFormName();
  const { selectedTelco } = useSelectTelco();
  const { selectedTariff } = useSelectTariff();
  const { setValues } = useTRInputContext();
  const ibanError = useField("iban")[1].error;


  const {
    isEnabledOfferMode,
    isRepeatMail,
    isEnabledDigitalSignature,
    isPrePrint,
    phoneLabel,
    alertLabel,
    returnToTarifBtnTextLabel,
    dialogTextLabel,
    reasonToChangeMoveExistingCustomer,
  } = useFeaturesCC();

  const isTelco = React.useMemo(
    () => !selectedTariff && selectedTelco,
    [selectedTariff, selectedTelco]
  );

  const disabled = React.useMemo(() => {
    if (isOffer) {
      if (formName === "summaryForm") {
        return handleSummaryValidation(values);
      }

      return true;
    }
    if (formName === "contactForm") {
      return handleContactValidation(values, { isRepeatMail, phoneLabel });
    }

    if (formName === "addressForm") {
      return handleAddressValidation(values, { isTelco });
    }

    if (formName === "gee") {
      return handleGEEValidation(values, isEnabledDigitalSignature);
    }

    if (formName === "preSupplierForm") {
      return handlePreSupplierValidation(values, {
        isTelco,
        reasonToChangeMoveExistingCustomer,
      });
    }
    if (formName === "bankForm") {
      return handleBankValidation(values, !!ibanError);
    }

    if (formName === "summaryForm") {
      return handleSummaryValidation(values, {
        isPrePrint,
        isEnabledDigitalSignature,
        isTelco,
        isRepeatMail,
      }); 
    }

    return false;
  }, [
    formName,
    isEnabledDigitalSignature,
    isOffer,
    isPrePrint,
    isRepeatMail,
    isTelco,
    phoneLabel,
    reasonToChangeMoveExistingCustomer,
    values,
    ibanError
  ]);

  const handleClick = React.useCallback(async () => {
    if (isOffer) {
      onSubmit();
    } else {
      try {
        if (formName === "summaryForm") {
          await onSubmit();
        } else {
          onSubmitValidation();
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    }

    scrollToTop();
  }, [formName, isOffer, onSubmit, onSubmitValidation]);

  return (
    <>
      <Modal showDialog={modalIsOpen} ariaLabel="Hinweis">
        <div className="m-6">
          <div className="my-4 font-bold">{alertLabel}</div>
          <div className="my-4">{dialogTextLabel}</div>
          <div className="flex justify-between items-center">
            <Button
              variant="primary"
              className="p-2"
              onClick={() => setModalIsOpen(false)}
            >
              Mit Dateieingabe fortfahren
            </Button>
            <Button
              className="p-2"
              onClick={() => {
                onBack();
                setModalIsOpen(false);

                setValues((prevState) => ({
                  ...prevState,
                  contractConclusions: values,
                }));
              }}
            >
              {returnToTarifBtnTextLabel}
            </Button>
          </div>
        </div>
      </Modal>
      {values.paymentOptions !== "bankTransfer" &&
        <small className="mt-6">
          Pflichtfelder sind mit einem Stern * gekennzeichnet.
        </small>
      }

      <div className="grid grid-cols-3 gap-4 mt-4">
        <Button
          type="submit"
          variant="primary"
          className="p-2"
          onClick={() => {
            if (formName === "contactForm") {
              setModalIsOpen(true);
            } else {
              onBack();
            }
            scrollToTop();
          }}
        >
          Zurück
        </Button>

        {isEnabledOfferMode ? (
          <CreateOffer formName={formName}>Angebot erstellen</CreateOffer>
        ) : (
          <div />
        )}

        <Button
          type="submit"
          className="p-2"
          variant="primary"
          disabled={!disabled}
          onClick={handleClick}
        >
          {formName === "summaryForm" ? "Zahlungpflichtig bestellen" : "Weiter"}
        </Button>
      </div>

      {isEnabledOfferMode && (
        <div className="mt-4">
          <Checkbox name="asOffer" label="Weiter als Angebot" />
        </div>
      )}
    </>
  );
};

export default FooterButtons;
