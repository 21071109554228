import * as React from "react";

const HappyHippo = () => {
  return (
    <>
      <svg width="420" height="577" viewBox="0 0 420 577" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M221.086 158.522C221.086 158.522 247.196 141.536 238.764 128.723C230.333 115.91 221.086 158.522 221.086 158.522Z"
          fill="#2F4D70"
        />
        <path
          d="M227.877 153.451C227.877 153.451 244.878 140.451 237.377 134.451C229.877 128.451 227.877 153.451 227.877 153.451Z"
          fill="#13202E"
        />
        <ellipse
          cx="5.5"
          cy="9"
          rx="5.5"
          ry="9"
          transform="matrix(-0.956611 0.291369 0.291369 0.956611 160.295 189.611)"
          fill="#13202E"
        />
        <path
          d="M184.379 157.451C184.379 157.451 198.872 129.559 218.379 144.451C264.878 179.951 291.878 241.451 302.378 306.951C312.878 372.451 305.133 409.031 260.879 441.951C216.228 475.166 148.51 493.61 113.378 450.451C84.7792 415.319 89.8779 350.951 113.378 322.451C136.878 293.951 176.903 300.961 179.378 265.951C181.043 242.396 169.878 226.451 160.378 209.451C150.878 192.451 141.94 165.961 160.378 157.451C168.888 153.523 184.379 157.451 184.379 157.451Z"
          fill="#2F4D70"
        />
        <ellipse cx="3.5" cy="6" rx="3.5" ry="6" transform="matrix(-1 0 0 1 300.378 348.451)" fill="#13202E" />
        <path
          d="M331.378 182.397C322.978 225.597 283.211 256.063 264.378 265.897L287.878 338.396C351.878 302.796 368.878 230.23 369.378 198.397C357.378 180.397 339.045 180.23 331.378 182.397Z"
          fill="#2F4D70"
        />
        <path
          d="M104.378 287.396C137.178 301.396 169.711 299.23 181.878 296.396L127.878 342.896C104.278 337.296 87.7113 316.563 82.3779 306.896C87.9779 294.496 99.3779 288.73 104.378 287.396Z"
          fill="#2F4D70"
        />
        <path
          d="M105.378 287.451C105.378 287.451 107.886 289.314 106.378 291.451C103.962 294.876 97.8774 290.525 97.8774 290.525C97.8774 290.525 101.436 296.469 97.8774 298.951C94.3989 301.377 89.3774 297.398 89.3774 297.398C89.3774 297.398 92.953 302.111 89.8779 305.451C87.5639 307.965 83.4131 307.023 83.4131 307.023"
          stroke="#13202E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M264.378 265.896C283.211 256.063 322.978 226.651 331.378 183.451"
          stroke="#4F82BD"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M138.378 555.951C129.978 530.751 110.211 460.785 101.378 428.951L187.378 463.451C192.578 491.051 191.878 536.618 190.878 555.951C177.278 565.951 150.211 560.118 138.378 555.951Z"
          fill="#2F4D70"
        />
        <path
          d="M184.379 157.451C184.379 157.451 168.888 153.523 160.378 157.451C141.94 165.961 150.878 192.451 160.378 209.451C169.878 226.451 181.043 242.396 179.378 265.951C176.903 300.961 136.878 293.951 113.378 322.451C89.8777 350.951 103.779 377.819 132.377 412.951"
          stroke="#4F82BD"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M313.663 553.431C264.31 531.107 185.757 474.079 152.65 448.355L303.025 419.853C292.818 445.199 337.837 502.003 355.033 528.277C344.703 543.99 323.149 551.594 313.663 553.431Z"
          fill="#2F4D70"
        />
        <path
          d="M94.8775 407.951C97.5016 440.753 147.74 451.834 187.877 474.451C225.877 502.951 285.896 540.871 313.664 553.432"
          stroke="#4F82BD"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M222.378 310.951C246.155 294.154 225.876 237.951 225.876 237.951L184.877 248.951C184.877 248.951 185.846 268.43 181.878 280.951C178.586 291.338 163.877 301.451 163.877 301.451C168.877 311.951 207.63 321.37 222.378 310.951Z"
          fill="#13202E"
        />
        <path
          d="M159.878 272.451L157.378 259.451L148.378 262.451L151.878 274.951L159.878 272.451Z"
          fill="#ECECEC"
          stroke="#ECECEC"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M190.094 266.861L188.822 253.684L198.303 254.021L198.474 267.001L190.094 266.861Z"
          fill="white"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.633 273.735C234.799 288.332 232.912 303.509 222.378 310.951C212.754 317.75 192.906 316.101 178.888 311.217C189.901 263.435 204.53 270.995 212.878 277.951C219.246 267.563 228.163 270.019 233.633 273.735Z"
          fill="#FF58CF"
        />
        <path
          d="M233.378 273.95C228.711 269.45 214.878 266.451 209.378 286.45"
          stroke="#B80062"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209.449 315.183C212.925 314.807 216.14 314.062 218.886 312.881L219.02 302.71L210.64 302.849L209.449 315.183Z"
          fill="white"
        />
        <path
          d="M209.449 315.183L209.503 315.68C209.354 315.696 209.205 315.644 209.098 315.539C208.991 315.433 208.937 315.285 208.952 315.135L209.449 315.183ZM218.886 312.881L219.386 312.887C219.383 313.085 219.265 313.262 219.084 313.34L218.886 312.881ZM219.02 302.71L219.012 302.21C219.147 302.208 219.277 302.26 219.373 302.356C219.469 302.451 219.522 302.581 219.52 302.716L219.02 302.71ZM210.64 302.849L210.142 302.801C210.167 302.548 210.377 302.354 210.632 302.349L210.64 302.849ZM209.396 314.686C212.836 314.314 216 313.578 218.688 312.421L219.084 313.34C216.28 314.546 213.013 315.3 209.503 315.68L209.396 314.686ZM218.386 312.874L218.52 302.703L219.52 302.716L219.386 312.887L218.386 312.874ZM219.029 303.21L210.648 303.349L210.632 302.349L219.012 302.21L219.029 303.21ZM211.138 302.897L209.947 315.231L208.952 315.135L210.142 302.801L211.138 302.897Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.089 311.847C178.671 311.114 175.55 310.04 172.941 308.582L173.858 298.451L182.179 299.456L182.089 311.847Z"
          fill="white"
        />
        <path
          d="M182.089 311.847L181.984 312.336C182.131 312.367 182.285 312.331 182.402 312.237C182.519 312.143 182.588 312.001 182.589 311.85L182.089 311.847ZM172.941 308.582L172.443 308.536C172.425 308.733 172.525 308.922 172.697 309.018L172.941 308.582ZM173.858 298.451L173.918 297.955C173.784 297.939 173.649 297.977 173.544 298.062C173.439 298.147 173.373 298.271 173.36 298.406L173.858 298.451ZM182.179 299.456L182.679 299.46C182.681 299.205 182.492 298.99 182.239 298.96L182.179 299.456ZM182.194 311.358C178.81 310.632 175.739 309.573 173.185 308.145L172.697 309.018C175.361 310.508 178.532 311.595 181.984 312.336L182.194 311.358ZM173.439 308.627L174.356 298.496L173.36 298.406L172.443 308.536L173.439 308.627ZM173.798 298.948L182.12 299.952L182.239 298.96L173.918 297.955L173.798 298.948ZM181.679 299.452L181.589 311.843L182.589 311.85L182.679 299.46L181.679 299.452Z"
          fill="white"
        />
        <path
          d="M161.378 223.951C169.778 193.151 188.544 208.118 196.878 219.451C201.378 216.951 210.578 178.551 229.378 202.951C248.178 227.351 223.544 249.451 208.878 257.451C177.678 251.051 152.211 262.118 143.378 268.451C130.578 249.251 129.378 237.118 130.378 233.451C136.378 203.051 153.544 214.451 161.378 223.951Z"
          fill="#2F4D70"
        />
        <path
          d="M196.878 219.451C188.545 208.118 169.778 193.151 161.378 223.951"
          stroke="#4F82BD"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M143.378 268.451C152.211 262.118 177.678 251.051 208.878 257.451C223.544 249.451 248.178 227.351 229.378 202.951C233.378 204.451 244.478 205.651 244.878 194.451"
          stroke="#13202E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M155.378 170.182C155.378 170.182 125.878 160.182 130.878 145.682C135.878 131.182 155.378 170.182 155.378 170.182Z"
          fill="#2F4D70"
        />
        <path
          d="M194.379 337.951C177.604 307.951 142.046 304.284 126.879 310.451C94.3779 328.451 88.3783 397.451 96.3781 414.951C104.378 432.451 116.378 441.451 144.879 453.451C173.379 465.451 220.38 384.451 194.379 337.951Z"
          fill="url(#paint0_linear_64_170)"
        />
        <path
          d="M193.379 405.95C106.579 386.35 84.5446 351.618 90.3779 342.451C75.7113 336.118 64.8784 352.951 74.8784 381.45C88.5604 420.444 141.541 434.766 189.378 444.95C238.581 455.426 306.235 460.952 321.878 428.95C332.877 406.45 318.878 373.451 303.379 398.45C303.046 408.784 280.179 425.55 193.379 405.95Z"
          fill="#EF7D00"
        />
        <path
          d="M193.379 392.951C106.579 373.351 97.0452 349.118 102.879 339.951C88.2118 333.618 64.8777 339.951 74.8777 368.451C88.5597 407.444 141.541 421.766 189.378 431.951C238.58 442.426 306.234 447.952 321.878 415.951C332.877 393.451 308.377 379.285 302.377 385.451C298.877 393.951 280.179 412.551 193.379 392.951Z"
          fill="#FFAB00"
        />
        <path
          d="M258.509 340.031C283.958 327.455 295.373 349.791 299.709 361.306C301.794 367.224 301.851 379.884 285.399 383.181C288.099 385.631 283.878 395.951 299.709 397.951L232.427 409.113C232.427 409.113 254.916 397.374 248.897 383.181C242.878 368.987 239.778 349.287 258.509 340.031Z"
          fill="#FFAB00"
        />
        <path
          d="M278.963 369.005C289.308 372 297.285 364.678 299.378 360.451C306.897 372.1 321.987 380.688 315.378 382.951C308.769 385.214 286.475 387.354 277.609 380.696C281.751 377.874 280.237 371.726 278.963 369.005Z"
          fill="#EF7D00"
        />
        <path
          d="M292.51 396.447L292.014 395.579L293.75 394.587L294.246 395.455L292.51 396.447ZM249.716 414.438C249.171 414.35 248.8 413.837 248.888 413.292C248.977 412.746 249.49 412.376 250.035 412.464L249.716 414.438ZM294.246 395.455C294.824 396.467 295.653 398.682 295.736 401.312C295.821 403.971 295.141 407.114 292.61 409.844C290.091 412.56 285.847 414.749 279.036 415.742C272.226 416.736 262.772 416.547 249.716 414.438L250.035 412.464C262.98 414.555 272.213 414.717 278.747 413.763C285.281 412.81 289.037 410.755 291.144 408.484C293.238 406.226 293.809 403.638 293.737 401.375C293.665 399.083 292.932 397.186 292.51 396.447L294.246 395.455Z"
          fill="#EF7D00"
        />
        <ellipse cx="3.5" cy="6" rx="3.5" ry="6" transform="matrix(-1 0 0 1 278.378 356.451)" fill="#13202E" />
        <ellipse
          cx="5.5"
          cy="9"
          rx="5.5"
          ry="9"
          transform="matrix(-0.956611 0.291369 0.291369 0.956611 215.133 174.451)"
          fill="#13202E"
        />
        <path
          d="M368.878 198.451C368.878 198.451 364.883 202.508 361.878 201.951C357.063 201.059 360.878 189.451 360.878 189.451C360.878 189.451 353.695 196.763 349.378 194.451C345.158 192.191 346.878 182.451 346.878 182.451C346.878 182.451 341.904 192.983 336.878 191.451C333.096 190.298 331.378 182.951 331.378 182.951"
          stroke="#13202E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M314.878 552.951C314.878 552.951 313.758 545.653 315.878 543.451C319.276 539.924 328.285 547.986 328.285 547.986C328.285 547.986 324.571 538.28 328.879 535.951C333.089 533.674 342.379 540.951 342.379 540.951C342.379 540.951 336.831 528.801 340.879 525.451C343.924 522.93 354.879 527.951 354.879 527.951"
          stroke="#13202E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M138.378 556.15C138.378 556.15 141.927 550.405 144.878 549.611C149.607 548.338 155.264 559.62 155.264 559.62C155.264 559.62 157.081 549.388 161.973 549.611C166.755 549.829 170.967 560.852 170.967 560.852C170.967 560.852 175.183 550.397 180.377 549.611C184.287 549.019 190.377 556.15 190.377 556.15"
          stroke="#13202E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M147.184 230.578C148.49 230.705 148.804 232.664 147.007 235.267C139.848 233.69 140.336 231.484 141.475 230.578C142.614 229.672 145.291 231.926 145.291 231.926C145.291 231.926 145.878 230.451 147.184 230.578Z"
          fill="#13202E"
        />
        <path
          d="M181.343 222.209C183.699 223.426 180.269 226.429 177.444 227.853C171.915 223.04 174.378 222.951 175.378 222.951C176.378 222.951 177.538 224.099 177.538 224.099C177.538 224.099 178.987 220.993 181.343 222.209Z"
          fill="#13202E"
        />
        <path
          d="M209.878 132.451C205.545 130.451 194.478 130.151 184.878 144.951"
          stroke="#13202E"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M235.663 74.451C235.663 74.451 239.731 81.3343 238.163 85.451C237.129 88.1637 233.951 88.1574 233.163 90.951C232.223 94.281 235.663 99.451 235.663 99.451"
          stroke="#B80062"
          strokeWidth="5"
        />
        <path
          d="M398.049 255.795C398.049 255.795 390.757 259.073 389.664 263.341C388.945 266.153 391.545 267.98 390.579 270.718C389.427 273.98 383.635 276.221 383.635 276.221"
          stroke="#4F82BD"
          strokeWidth="5"
        />
        <path
          d="M139.75 95.0998C139.75 95.0998 132.458 98.3783 131.366 102.646C130.646 105.458 133.246 107.286 132.28 110.023C131.128 113.285 125.337 115.526 125.337 115.526"
          stroke="#B80062"
          strokeWidth="5"
        />
        <path
          d="M383.75 52.0998C383.75 52.0998 376.458 55.3783 375.366 59.6461C374.646 62.4582 377.246 64.2855 376.28 67.0227C375.128 70.2853 369.337 72.5263 369.337 72.5263"
          stroke="#13202E"
          strokeWidth="5"
        />
        <path
          d="M86.4281 231.414C86.4281 231.414 86.5099 237.025 89.9438 239.785C92.2065 241.603 94.8324 242.376 96.9247 244.388C99.4186 246.786 99.0636 252.986 99.0636 252.986"
          stroke="#22C55E"
          strokeWidth="5"
        />
        <path
          d="M32.6626 143.451C32.6626 143.451 35.4281 148.334 33.8599 152.451C32.8265 155.164 30.951 157.157 30.1626 159.951C29.2229 163.281 32.6626 168.451 32.6626 168.451"
          stroke="#13202E"
          strokeWidth="5"
        />
        <path
          d="M307.011 50.451C307.011 50.451 303.284 56.9488 303.511 61.451C303.736 65.9066 304.696 67.8208 309.011 68.951C313.56 70.1425 318.088 68.5286 317.011 63.951C315.011 55.451 295.571 62.4089 293.011 71.951C290.681 80.6345 297.021 90.951 306.011 90.951C312.011 90.951 315.041 85.2643 313.011 80.951C309.011 72.451 288.605 76.9283 288.011 86.951C287.701 92.1813 294.011 98.951 294.011 98.951"
          stroke="#FF58CF"
          strokeWidth="5"
        />
        <path
          d="M65.1608 103.451C65.1608 103.451 68.8878 109.949 68.6608 114.451C68.4362 118.907 67.4763 120.821 63.1606 121.951C58.6115 123.142 54.0838 121.529 55.1608 116.951C57.1606 108.451 76.6007 115.409 79.1608 124.951C81.4905 133.635 75.1514 143.951 66.1608 143.951C60.1606 143.951 57.1311 138.264 59.1608 133.951C63.1606 125.451 83.5669 129.928 84.1608 139.951C84.4707 145.181 78.1608 151.951 78.1608 151.951"
          stroke="#EF7D00"
          strokeWidth="5"
        />
        <path
          d="M406 156.451C406 156.451 401 164.451 403 171.951C404.105 176.093 408.513 179.026 412.5 177.451C416.348 175.931 418.823 170.476 416 167.451C409 159.951 394 174.451 394 174.451"
          stroke="#FFAB00"
          strokeWidth="5"
        />
        <path
          d="M60.43 223.012C60.43 223.012 60.7601 213.584 55.278 208.089C52.2504 205.054 46.9666 204.718 44.3008 208.075C41.7278 211.316 42.3124 217.278 46.2697 218.486C56.0819 221.481 61.8223 201.424 61.8223 201.424"
          stroke="#4F82BD"
          strokeWidth="5"
        />
        <path
          d="M280 146.951C281 144.451 285.3 138.851 294.5 136.451M335 112.451C339.833 113.451 347.2 117.851 338 127.451M309.5 144.451V156.451"
          stroke="#22C55E"
          strokeWidth="5"
        />
        <path
          d="M52.6597 340.433C54.5511 338.517 57.9639 332.336 56.4841 322.944M62.2832 276.225C59.4336 272.195 52.4545 267.203 47.3353 279.475M43.1526 312.395L32.1542 317.195"
          stroke="#FF58CF"
          strokeWidth="5"
        />
        <path
          d="M141.255 41.2005C142.102 38.6447 146.057 32.7955 155.095 29.845M194.074 3.44578C198.959 4.15241 206.577 8.10005 197.973 18.2375M170.55 36.9256L171.274 48.9038"
          stroke="#B80062"
          strokeWidth="5"
        />
        <circle cx="27.5" cy="119.951" r="3.5" fill="#22C55E" />
        <circle cx="197.5" cy="102.951" r="3.5" fill="#22C55E" />
        <circle cx="3.5" cy="265.951" r="3.5" fill="#B80062" />
        <circle cx="393.5" cy="310.951" r="3.5" fill="#22C55E" />
        <circle cx="390.5" cy="112.951" r="3.5" fill="#13202E" />
        <circle cx="90.5" cy="83.951" r="3.5" fill="#4F82BD" />
        <circle cx="263.5" cy="40.951" r="3.5" fill="#4F82BD" />
        <circle cx="93.5" cy="190.951" r="3.5" fill="#B80062" />
        <defs>
          <linearGradient
            id="paint0_linear_64_170"
            x1="93.3779"
            y1="376.451"
            x2="243.878"
            y2="334.451"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4F82BD" />
            <stop offset="1" stopColor="#4F82BD" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default HappyHippo;
