import React from "react";

import Card from "../../../../components/Card";
import Radio from "../../../../components/Radio";

const EVNForm = () => {
  return (
    <Card className="mb-4">
      <div className="font-bold text-lg">Einzelverbindungsnachweis (EVN)</div>
      <div className="my-2">
        <Radio
          value="false"
          name="itemizedBill"
          label="Nein, ich wünsche keinen EVN"
        />
      </div>
      <div className="mb-2">
        <Radio
          value="true"
          name="itemizedBill"
          label="Ja, ich wünsche einen EVN gekürzt um 3 Stellen"
        />
      </div>
      <Radio
        value="full"
        name="itemizedBill"
        label="Ja, ich wünsche einen EVN ungekürzt"
      />
    </Card>
  );
};

export default EVNForm;
