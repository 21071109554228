import React from "react";

import formatCurrency from "../../../../../utils/formatCurrency";
import { bonus } from "../../../../TCInputs/types/SimplifierResponse";
import RowItem from "../RowItem";

const Bonus = ({ boni, isBrutto = true }: { boni: bonus[]; isBrutto?: boolean }) => {
  const bonus = React.useMemo(() => {
    return Array.from({ length: 2 }).map((_, index) => {
      if (boni[index]) {
        return boni[index];
      }
      return null;
    });
  }, [boni]);
  return (
    <section id="bonus" className="my-5 grid gap-1">
      {boni && (
        <>
          {bonus.map((bonus) => {
            if (!bonus) {
              return;
            }
            return (
              <RowItem
                key={bonus?.id}
                label={bonus?.art + " " + bonus?.bedingung}
                value={formatCurrency(isBrutto ? bonus?.calcWertBrutto : bonus?.calcWertNetto || 0)}
              />
            );
          })}
        </>
      )}
    </section>
  );
};

export default Bonus;
