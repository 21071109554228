export const baseURL =
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_PROXY_BASE_URL_TEST
    : process.env.REACT_APP_MODE === "stage"
    ? process.env.REACT_APP_PROXY_BASE_URL_STAGE
    : process.env.REACT_APP_MODE === "production"
    ? process.env.REACT_APP_PROXY_BASE_URL_PROD
    : "https://test.tr4-proxy.getag-cloud.com/api/v2";

export const VPPBaseUrl =
  process.env.REACT_APP_MODE === "development"
    ? process.env.REACT_APP_VPP_BASE_URL_TEST
    : process.env.REACT_APP_MODE === "stage"
    ? process.env.REACT_APP_VPP_BASE_URL_STAGE
    : process.env.REACT_APP_MODE === "production"
    ? process.env.REACT_APP_PROXY_BASE_URL_PROD
    : "https://test.api.vpp.get-ag.com/api/vpp2/rest/v1";

export const xOrigin = process.env.REACT_APP_DOMAIN_CONFIG
  ? process.env.REACT_APP_DOMAIN_CONFIG
  : process.env.REACT_APP_MODE === "development"
  ? "test.tr5.getag-cloud.com"
  : process.env.REACT_APP_MODE === "stage"
  ? "staging.tr5.getag-cloud.com"
  : process.env.REACT_APP_MODE === "production"
  ? "prod.tr5.getag-cloud.com"
  : "test.tr5.getag-cloud.com";

export const USAGE = [
  { value: "1000", label: "1000" },
  { value: "2000", label: "2000" },
  { value: "3000", label: "3000" },
  { value: "4000", label: "4000" },
  { value: "5000", label: "5000" },
  { value: "6000", label: "6000" },
];

export const TARIF_LABELS = {
  1: "Strom",
  2: "Gas",
  3: "Heizstrom",
  4: "Autostrom",
  7: "Internet",
  8: "THG Prämie",
};

export const TYPE = { energy: "energie", gas: "gas" };

export const SALUTATION_LIST = [
  { label: "Herr", value: "Herr" },
  { label: "Frau", value: "Frau" },
  { label: "Divers", value: "Divers" },
];

export const TITLE_LIST = [
  { label: "", value: "" },
  { label: "Dr.", value: "Dr." },
  { label: "Prof.", value: "Prof." },
  { label: "Prof. Dr.", value: "Prof. Dr." },
];

export const CUSTOMER_TYPE = [
  { label: "Privat", value: "Privat" },
  { label: "Gewerbe", value: "Gewerbe" },
];
