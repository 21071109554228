import { useField, useFormikContext } from "formik";
import React from "react";

import Divider from "../../../../../components/Divider";
import EditIcon from "../../../../../components/icons/editIcon";
import Mobile from "../../../../../components/View/Mobile";
import { useConfig } from "../../../../../context/Config";
import { FormValues } from "../../../../../types/FormValues";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";
import { useEditDataModalContext } from "../../../context/EditDataModalContext";

const AddressInfo = () => {
  const { values } = useFormikContext<FormValues>();
  const { delieveryAddress, isEnableInvoiceDelivery, address, billingAddress, sendBill, byPost, byMail } =
    useFeaturesCC();
  const [{ value: deliveryIsSameBillingAddress }] = useField("deliveryIsSameBillingAddress");
  const { config } = useConfig();
  const { setShowEditDataModal } = useEditDataModalContext();

  return (
    <section
      className={
        deliveryIsSameBillingAddress === false && config.media !== "mobile"
          ? "grid grid-cols-2 gap-y-2 gap-x-4"
          : "grid gap-y-2 gap-x-4"
      }
    >
      <section>
        <Headline>
          {delieveryAddress}
          <span
            onClick={() => setShowEditDataModal("deliveryAddress")}
            id="editDeliveryAddress"
            className="float-right cursor-pointer"
          >
            <EditIcon />
          </span>
        </Headline>
        <div className="text-xs text-gray-dark">{address}</div>
        {values.companyName && <div>{values.companyName}</div>}
        {(values?.firstName || values?.lastName || values?.salutation?.value || values?.title?.value) && (
          <div>
            {typeof values.salutation !== "string" && values.salutation?.value + " "}
            {values.title?.value && values.title?.value + " "}
            {values.firstName && values.firstName + " "}
            {values.lastName}
          </div>
        )}
        {(values?.street || values?.street?.value || values?.houseNumber || values?.additionalAddress) && (
          <div>
            {typeof values.street === "string" ? values.street : values.street?.value} {values.houseNumber}{" "}
            {values.additionalAddress}
          </div>
        )}
        <div>
          {values.zipCode} {values.city?.value}
        </div>
      </section>
      {deliveryIsSameBillingAddress === false && (
        <section>
          <Mobile>
            <Divider />
          </Mobile>
          <Headline>
            {billingAddress}
            <span
              onClick={() => setShowEditDataModal("billingAddress")}
              id="editBillingAddress"
              className="float-right cursor-pointer"
            >
              <EditIcon />
            </span>
          </Headline>
          <section className="grid grid-cols-2 gap-y-2 gap-x-4">
            <section>
              <div className="text-xs text-gray-dark">Anschrift</div>
              <div>{values.billingCompanyName}</div>
              <div>
                {typeof values.billingSalutation !== "string" && values.billingSalutation?.value + " "}
                {values?.billingTitle?.value && values?.billingTitle?.value + " "}
                {values.billingFirstName && values.billingFirstName + " "}
                {values.billingLastName}
              </div>
              <div>
                {typeof values.billingStreet !== "string" && values.billingStreet?.value} {values.billingHouseNumber}{" "}
                {values?.billingAdditionalAddress}
              </div>
              <div>{values.billingCity?.value ? values.billingZipCode + " " + values.billingCity?.value : ""}</div>
            </section>
            {isEnableInvoiceDelivery && (
              <section>
                <div className="text-xs text-gray-dark">{sendBill}</div>
                <div>{values.invoiceDeliveryBy === "post" ? byPost : byMail}</div>
              </section>
            )}
          </section>
        </section>
      )}
    </section>
  );
};

export default AddressInfo;
