import React from "react";

import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { useTariff } from "../../../../context";
import useDebounce from "../../../../hooks/useDebounce";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import CustomerTypeRadio from "../../../TCInputs/components/Radio/CustomerType";
import MeasurementType from "../../../TCInputs/components/Radio/MeasurementType";
import MeterType from "../../../TCInputs/components/Radio/MeterType";
import TariffType from "../../../TCInputs/components/Radio/TariffType";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import TRAddressResultList from "../../components/ResultsList/Address";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import useSimplifierResultPage from "../../hooks/useSimplifier";

const InputFormModal = ({ values, setIsShowInputTariff, handleSubmit }) => {
  const debounceValue = useDebounce(values, 600);
  const { data, ...rest } = useSimplifierResultPage(debounceValue);
  const { headerSubLabel } = useFeaturesResult();
  const { consumptionLabel, usageLabel } = useFeatureInputPage();
  const { HTLabel, NTLabel } = useGeneralLabel();
  const tariff = useTariff();
  const enabled = data?.unique && values.city !="";

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-8 m-4">
        <div className="text-lg mb-4">{headerSubLabel}</div>
        <TRAddressResultList data={data} {...rest} />
        <div className="flex gap-8 items-center">
          <CustomerTypeRadio />
          {tariff.id !== 2 && tariff.id !== 7 && <MeterType />}
          {tariff.id === 3 && (
            <div>
              <TariffType />
            </div>
          )}

          {tariff.id === 4 && (
            <div>
              <MeasurementType />
            </div>
          )}
        </div>
        <div className="flex flex-row gap-10 w-full lg:w-2/3">
          <div className="w-full">
            <Input
              name="oneTariff"
              placeholder="Verbrauch (HT)"
              label={values.meterType === "3,4" ? consumptionLabel + " " + HTLabel : consumptionLabel}
              onlyNumber
              maxLength={10}
              usage={usageLabel}
            />
          </div>
          <div className={`${values.meterType === "3,4" ? "visible" : "invisible"} w-full`}>
            <Input name="twoTariff" label={consumptionLabel + " " + NTLabel} onlyNumber maxLength={10} usage={usageLabel} />
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <Button className="p-2 w-fit" variant="primary" onClick={() => setIsShowInputTariff(false)}>
            <span className="font-bold">Abbrechen</span>
          </Button>
          <Button variant="primary" type="submit" className="p-2" disabled={!enabled} onClick={() => setIsShowInputTariff(false)}>
            Übernehmen
          </Button>
        </div>
      </div>
    </form>
  );
};

export default InputFormModal;
