import { createContext, useContextSelector } from "use-context-selector";

const tariffOptions = [1,2,3,4,7,8] as const; //@TODO?: fetch this from the config
type tariffOptionTypes = typeof tariffOptions[number];

export const configContext = createContext(null);
export const tariffIdContext = createContext<{id: tariffOptionTypes}>({id: tariffOptions[0]});
export const parameterContext = createContext(null);
export const stepChangeContext = createContext(null);
 
export const useConfig = () => useContextSelector(configContext, (c) => c);
export const useTariff = () => useContextSelector(tariffIdContext, (c) => c);
export const useParameter = () =>
  useContextSelector(parameterContext, (c) => c);
export const useStepChange = () =>
  useContextSelector(stepChangeContext, (c) => c);