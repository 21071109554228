import ProxyInstance from "../../../services/proxy";

const sendMailPreSigned = async (
  tariffId,
  apikey,
  authToken,
  s3PreSignedDownloadFilePath,
  s3PreSignedDownloadFileName,
  externFileList,
  body,
  type
) =>
  await ProxyInstance(
    type === "customer"
      ? `/mail/sendMailCustomer?calculatorType=${tariffId}&s3PresignedDownloadFilePath=${s3PreSignedDownloadFilePath}&s3PresignedDownloadFileName=${s3PreSignedDownloadFileName}&externFileList=${externFileList}`
      : `/mail/sendMailDistribution?calculatorType=${tariffId}&s3PresignedDownloadFilePath=${s3PreSignedDownloadFilePath}&s3PresignedDownloadFileName=${s3PreSignedDownloadFileName}&externFileList=${externFileList}`,
    {
      apikey: apikey || "",
      authToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    }
  );

export default sendMailPreSigned;
