import * as React from "react";
import { useSelectTariff } from "../../../../context/Tariff";
import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesSummary = () => {
  const { selectedTariff } = useSelectTariff();
  const { data: config } = useExternalConfigs("contractconclusion");
  const { data: tariffConfig } = useExternalConfigs("contractconclusion", selectedTariff?.versorgerID);
  const contractConclusion = config?.data?.data?.contractConclusion;
  const tariffContractConclusion = tariffConfig?.data?.data?.contractConclusion;

  const resolveContractConclusion = React.useCallback(
    (key: string) => {
      return tariffContractConclusion?.[key] ?? contractConclusion?.[key];
    },
    [tariffContractConclusion, contractConclusion]
  );

  const defaultLabel = {
    summary: "Abschluss",
    prePrintInfoText: "Bitte laden Sie die Vertragszusammenfassung herunter, um mit der Bestellung fortzufahren.",
    prePrintLabelBtn: "Zusammenfassung herunterladen",
    summaryTitle: "Auftragsbestätigung",
    anschlussdaten: "Ihre Anschlussdaten",
    reason: "Grund für den Wechsel",
    reasonChangeSupplier: "Lieferantenwechsel",
    reasonRelocation: "Umzug",
    zaehlernummer: "bisherige Zählernummer",
    zaehlerstand: "Zählerstand",
    headerAdvertisingConsent: "Marketing-Einwilligung",
    advertisingConsentPhone: "per Phone",
    customerSatisfactionSurveyPost: "per Post",
    customerSatisfactionSurveyPhone: "per Phone",
    customerSatisfactionSurveyEmail: "per E-Mail",
    byPost: "per Post",
    byPhone: "per Telefon",
    byMail: "per E-Mail",
    subAdvertisingConsent:
      "Ja, ich stimme zu, dass mich %versorgername% zu weiteren Produktangeboten kontaktieren darf. Diese Einwilligung kann ich jederzeit widerrufen.",
    advertisingConsentDigitalMessages: "sonstiger elektronischer Nachrichten",
    footerAdvertisingConsent: "",
    acceptCounsellingProtocollText:
      'Das <a href="%url%" target="_blank">Beratungsprotokoll</a> habe ich zur Kenntnis genommen.*',
    acceptCancellationPolicy:
      'Ich habe die <a href="%url%" target="_blank">Widerrufsbelehrung</a> des Anbieters %versorgername% zur Kenntnis genommen und akzeptiere diese.*',
    acceptGeneralTnc:
      'Ich habe die Allgemeinen Geschäftsbedingungen <a href="%url%" target="_blank">(AGB)</a> des Anbieters %versorgername% zur Kenntnis genommen und akzeptiere diese. Der Anbieter darf ggf. meinen derzeitigen Stromvertrag kündigen und die nötigen Verträge mit dem zuständigen Netzbetreiber schließen.*',
    acceptPrivacyPolicy:
      'Ich habe die <a href="%url%" target="_blank">Datenschutzhinweise</a> mit den Informationen zur Verarbeitung meiner personenbezogenen Daten des Anbieters %versorgername% zur Kenntnis genommen.*',
    acceptCounsellingProtocoll:
      'Das <a href="%url%" target="_blank">Beratungsprotokoll</a> habe ich zur Kenntnis genommen.*',
    serviceDescription: 'Die <a href=%url% target="_blank">Leistungsbeschreibung</a> habe ich zur Kenntnis genommen.*',
    preisList: "Die <a href=%url% >Preisliste</a> habe ich zur Kenntnis genommen.*",
    digitalSignatureBerater: "Unterschrift des Vermittlers zur Kundenbelehrung",
    digitalSignatureBeraterSubHeader:
      "Hiermit bestätige ich, dass ich die Kundenbelehrung Strom/Gas zum Zwecke der Qualitätssicherung durchgeführt habe",
    werberIdentnummer: "Kundennummer",
    customersRecruitmentText: "Kunden werben Kunden",
    digitalSignatureCustomer: "Unterschrift",
    contractConclusion: "Vertragszusammenfassung",
    accountData: "Kontoangaben",
    deliveryFrom: "Lieferung ab",
    cancelation: "Kündigung",
    selfCancelation: "Selbst gekündigt",
    oldCounterNumber: "Bisherige Kundennummer",
    moveInDate: "Einzugsdatum",
    address: "Anschrift",
    billingAddress: "Abweichende Rechnungsanschrift",
    sendBill: "Rechnungsversand",
  };

  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveContractConclusion(key) || defaultValue])
  ) as Label;

  return labels;
};
export default useFeaturesSummary;
