import React from "react";

const Seedling = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className="inline mr-1 align-top">
      <mask id="mask0_726_12959" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.666016" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_726_12959)">
        <path
          d="M7.99935 14V8.68333C7.28824 8.68333 6.6049 8.54722 5.94935 8.275C5.29379 8.00278 4.71602 7.61667 4.21602 7.11667C3.71602 6.61667 3.33268 6.03889 3.06602 5.38333C2.79935 4.72778 2.66602 4.04444 2.66602 3.33333V2H3.99935C4.69935 2 5.37713 2.13611 6.03268 2.40833C6.68824 2.68056 7.26602 3.06667 7.76602 3.56667C8.11046 3.91111 8.39657 4.28889 8.62435 4.7C8.85213 5.11111 9.02713 5.55 9.14935 6.01667C9.20491 5.93889 9.26602 5.86389 9.33268 5.79167C9.39935 5.71944 9.47157 5.64444 9.54935 5.56667C10.0493 5.06667 10.6271 4.68056 11.2827 4.40833C11.9382 4.13611 12.6216 4 13.3327 4H14.666V5.33333C14.666 6.04444 14.5299 6.72778 14.2577 7.38333C13.9855 8.03889 13.5993 8.61667 13.0993 9.11667C12.5993 9.61667 12.0243 10 11.3743 10.2667C10.7243 10.5333 10.0438 10.6667 9.33268 10.6667V14H7.99935ZM7.99935 7.33333C7.99935 6.8 7.89657 6.29167 7.69102 5.80833C7.48546 5.325 7.19379 4.89444 6.81602 4.51667C6.43824 4.13889 6.00768 3.84722 5.52435 3.64167C5.04102 3.43611 4.53268 3.33333 3.99935 3.33333C3.99935 3.86667 4.09935 4.37778 4.29935 4.86667C4.49935 5.35556 4.78824 5.78889 5.16602 6.16667C5.54379 6.54444 5.97713 6.83333 6.46602 7.03333C6.95491 7.23333 7.46602 7.33333 7.99935 7.33333ZM9.33268 9.33333C9.86602 9.33333 10.3743 9.23333 10.8577 9.03333C11.341 8.83333 11.7716 8.54444 12.1493 8.16667C12.5271 7.78889 12.8188 7.35556 13.0243 6.86667C13.2299 6.37778 13.3327 5.86667 13.3327 5.33333C12.7993 5.33333 12.2882 5.43611 11.7993 5.64167C11.3105 5.84722 10.8771 6.13889 10.4993 6.51667C10.1216 6.89444 9.83268 7.325 9.63268 7.80833C9.43268 8.29167 9.33268 8.8 9.33268 9.33333Z"
          fill="#15803D"
        />
      </g>
    </svg>
  );
};
export default Seedling;