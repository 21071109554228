import { useField } from "formik";
import React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useTariff } from "../../../../context";
import { useSelectTelco } from "../../../../context/Telco";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import Headline from "../Headline";

const PaymentOptions = () => {
  const { selectedTelco } = useSelectTelco();
  const { paymentMethod, bankTitle, mandatoryFields } = useFeaturesCC();
  const [fields] = useField("asOffer");
  const { responsiveClass } = useResponsiveClass();
  const tariff = useTariff();
  if (paymentMethod.options.length > 1) {
    return (
      <div>
        <Headline>{bankTitle}</Headline>
        <div className="text-sm text-gray-dark min-h-5">{!fields.value && mandatoryFields}</div>
        <div className={responsiveClass("gap-y-2 gap-x-4 my-2", "grid", "flex", "flex")}>
          {selectedTelco && tariff.id === "7" ? (
            <>
              <FormikRadio
                key={paymentMethod.options[0].value}
                value={paymentMethod.options[0].value}
                label={paymentMethod.options[0].label}
                name="paymentOptions"
              />
            </>
          ) : (
            <>
              {paymentMethod.options.map((option: { value: string; label: string }) => (
                <FormikRadio key={option.value} value={option.value} label={option.label} name="paymentOptions" />
              ))}
            </>
          )}
        </div>
      </div>
    );
  }
  return null;
};

export default PaymentOptions;
