import * as React from "react";

const ArrowRight = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="inline">
      <path d="M8 2L6.9425 3.0575L11.1275 7.25H2V8.75H11.1275L6.9425 12.9425L8 14L14 8L8 2Z" fill="#FFAB00" />
    </svg>
  );
};

export default ArrowRight;
