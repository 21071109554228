import formatISO from "date-fns/formatISO";
import Cookies from "js-cookie";
import { useMemo } from "react";
import { useMutation } from "react-query";

import toast from "../components/Toast";
import { useConfig, useParameter, useTariff } from "../context";
import { useCalcParams } from "../context/CalculationParameter";
import { useTRInputContext } from "../context/FormInputData";
import { useSelectTariff } from "../context/Tariff";
import { useSelectTelco } from "../context/Telco";
import useFeaturesCC from "../widgets/TCContractConclusion/hooks/useFeaturesCC";
import sendData from "../widgets/TCContractConclusion/utils/sendData";
import sendMail from "../widgets/TCContractConclusion/utils/sendMail";
import sendMailPreSigned from "../widgets/TCContractConclusion/utils/sendMailPreSigned";
import vppMappings from "../widgets/TCContractConclusion/utils/vppMappings";

const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);

const useSubmitFormData = () => {
  const config = useConfig();
  const tariff = useTariff();
  const { calcParams } = useCalcParams();
  const inputContext = useTRInputContext();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const { authToken, vppSummaryUrl } = useParameter();
  const { isPrePrintSendMail } = useFeaturesCC();

  let isTemplatePathUsed = false;
  let pdfUrl = "";

  const totalUsage = useMemo(
    () =>
      inputContext?.values?.meterType === "3,4"
        ? Number(inputContext?.values?.oneTariff?.replaceAll(".", "")) +
          Number(inputContext?.values?.twoTariff?.replaceAll(".", ""))
        : Number(inputContext?.values?.oneTariff?.replaceAll(".", "")),
    [
      inputContext?.values?.oneTariff,
      inputContext?.values?.twoTariff,
      inputContext?.values?.meterType,
    ]
  );

  const nettoOrBrutto = useMemo(
    () => (selectedTariff && Number(selectedTariff?.priceType)) || 0,
    [selectedTariff]
  );

  return useMutation(
    "SUBMIT_FORM_DATA",
    async ({ formikValues, formattedData }) => {
      // create form data
      let formData = {
        portalDaten: {},
        tarifErgebnis: {},
        tarifParameter: {},
        vertriebspartnerportal: {},
      };

      if (selectedTariff) {
        // binding offer true
        if (formattedData.verbindlicher_abschluss) {
          formData = {
            portalDaten: {
              ...formattedData,
              verbindlicher_abschluss: true,
              PDF_Vorabdruck: "Vielen Dank für Ihren Auftrag!",
              abschlussstatus: "abschluss",
            },
            tarifErgebnis: selectedTariff,
            tarifParameter: calcParams,
            vertriebspartnerportal:
              selectedTariff &&
              vppMappings({
                value: {
                  ...selectedTariff,
                  tariftyp: tariff.tariftyp || "",
                  verbrauchGesamt: totalUsage,
                  anzeigeNettoBrutto: nettoOrBrutto,
                  asOffer: formikValues.asOffer,
                  idPerson: formikValues?.idPerson,
                  abschlussstatus: "abschluss",
                  // auftragsstatus: undefined,
                },
              }),
          };
        } else {
          formData = {
            portalDaten: {
              ...formattedData,
              verbindlicher_abschluss: false,
              PDF_Vorabdruck: "Zusammenfassung Ihres Angebotes",
              abschlussstatus: "",
            },
            tarifErgebnis: selectedTariff,
            tarifParameter: calcParams,
            vertriebspartnerportal:
              selectedTariff &&
              vppMappings({
                value: {
                  ...selectedTariff,
                  tariftyp: tariff.tariftyp || "",
                  verbrauchGesamt: totalUsage,
                  anzeigeNettoBrutto: nettoOrBrutto,
                  asOffer: formikValues.asOffer,
                  idPerson: formikValues?.idPerson,
                  abschlussstatus: "",
                  // auftragsstatus: undefined,
                },
              }),
          };
        }
      }

      if (selectedTelco) {
        if (formattedData.verbindlicher_abschluss) {
          formData = {
            portalDaten: {
              ...formattedData,
              verbindlicher_abschluss: true,
              PDF_Vorabdruck: "Vielen Dank für Ihren Auftrag!",
              abschlussstatus: "abschluss",
            },
            tarifErgebnis: selectedTelco,
            tarifParameter: {},
            vertriebspartnerportal: {
              bestelldatumZeit: formikValues.asOffer
                ? ""
                : formatISO(new Date()),
              VParbeitspreisCtKWhNetto: 0,
              VParbeitspreisCtKWhBrutto: 0,
              VParbeitspreisHTCtKWhNetto: 0,
              VParbeitspreisHTCtKWhBrutto: 0,
              VPgrundpreisNetto: 0,
              VPgrundpreisBrutto: 0,
              VPgrundpreisMonatNetto: 0,
              VPgrundpreisMonatBrutto: 0,
              VPgesamtpreisOhneBoniNetto: 0,
              VPgesamtpreisOhneBoniBrutto: 0,
              VPgesamtpreisNetto: 0,
              VPgesamtpreisBrutto: 0,
              VPgesamtpreisMonatNetto: 0,
              VPgesamtpreisMonatBrutto: 0,
              anzeigeNettoBrutto: 1,
              // auftragsstatus: 11,
              abschlussstatus: "abschluss",
              lasttyp: "slp",
              messtyp: "gemeinsam",
              priceType: 0,
              verbrauchGesamt: 20000,
              id_person: formikValues?.idPerson,
            },
          };
        }
        // todo: telco in not binding offer
      }

      const data = [formData];
      const body = { jsonDaten: { abschlussDaten: data } };

      try {
        // binding offer true
        if (formattedData.verbindlicher_abschluss) {
          // send data
          const { data } = await sendData(
            tariff.id,
            config.apikey,
            authToken,
            body
          );

          const response = data;

          // error
          if (response?.error) {
            throw new Error(response?.error?.message);
          } else {
            // generate pdf url
            if (response?.data?.presignedDownloadUrl) {
              pdfUrl = response.data.presignedDownloadUrl;
            }

            // CRM
            if (response?.data?.crm?.response?.abschluesse_OK) {
              // toast send success
              toast.success("Erfolgreich gesendet");

              // used template
              if (response?.additionalData?.usedTemplatePath) {
                isTemplatePathUsed = true;
              }

              // timestamp
              if (response?.timestamp) {
                Cookies.set("allowed", JSON.stringify(true), {
                  expires: inFiveMinutes,
                });
              }

              // send email to customer and distributor
              if (formattedData.la_email && formattedData.la_email.length > 0) {
                const { s3FilePathDistribution, s3FilePathCustomer } =
                  response.data.crm.response;

                const isS3FilePathCustomerAvailable =
                  response.data.crm.response.s3FilePathCustomer;

                const isS3FilePathDistribution =
                  response.data.crm.response.s3FilePathDistribution;
                // customer mail
                let responseMailCustomer;
                if (isS3FilePathCustomerAvailable) {
                  try {
                    const { data } = await sendMail(
                      tariff.id,
                      config.apikey,
                      authToken,
                      s3FilePathCustomer,
                      body,
                      "customer"
                    );

                    responseMailCustomer = data;
                  } catch (e) {
                    throw new Error("Error Sending Email to Customer");
                  }
                }

                // distributor customer
                let responseMailDistributor;
                if (isS3FilePathDistribution) {
                  try {
                    const { data } = await sendMail(
                      tariff.id,
                      config.apikey,
                      authToken,
                      s3FilePathDistribution,
                      body,
                      "distributor"
                    );

                    responseMailDistributor = data;
                  } catch (e) {
                    throw new Error("Error Sending Email to Distributor");
                  }
                }

                if (responseMailCustomer && responseMailDistributor) {
                  toast.success("Mail erfolgreich gesendet");
                }
              }
            }

            // CIM
            if (response?.data?.cim?.abschluss_OK) {
              toast.success("Erfolgreich gesendet");

              if (response?.data?.cim?.id_templatedata && vppSummaryUrl) {
                window.open(
                  `${vppSummaryUrl}${response?.data?.cim?.id_templatedata.toString()}`,
                  "_self"
                );
              }
            }
          }
        } else {
          // NO BINDING OFFER
          const { data } = sendData(tariff.id, config.apikey, authToken, body);
          const res = data;

          if (res?.error) {
            throw new Error(res?.error?.message);
          } else {
            if (res?.data?.presignedDownloadUrl) {
              pdfUrl = res?.data?.presignedDownloadUrl;
            }

            const isS3FilePathPreSignedAvailable =
              res?.data?.s3FilePathPresigned;

            if (
              formattedData.la_email &&
              formattedData.la_email.length > 0 &&
              isPrePrintSendMail &&
              isS3FilePathPreSignedAvailable
            ) {
              let externFileList = "";
              const s3FilePathPreSigned = res?.data?.s3FilePathPresigned;
              // map
              formData = {
                ...formData,
                portalDaten: {
                  ...formData.portalDaten,
                  attachments: formData.portalDaten.attachments.map(
                    (v) => (externFileList += encodeURIComponent(v).concat(";"))
                  ),
                },
              };

              const resMailCustomer = await sendMailPreSigned(
                tariff.id,
                config.apikey,
                authToken,
                s3FilePathPreSigned,
                "Vertragszusammenfassung.pdf",
                externFileList,
                body,
                "customer"
              );

              if (resMailCustomer) {
                console.info("Mail versand");
              }
            }
          }
        }

        // data that required for confirmation page
        return {
          ...formData,
          isTemplatePathUsed,
          pdfUrl,
        };
      } catch (e) {
        console.error("Error send data to Proxy", e);
        throw e;
      }
    },
    {
      onError: (e) => {
        if (e instanceof Error) {
          toast.error(e.message);

          throw e;
        }
        toast.error(e);
      },
    }
  );
};

export default useSubmitFormData;
