import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --get-c-primary: inherit;
    --get-c-primary-hover: inherit;
    --get-c-primary-text: inherit;

    --get-c-secondary: inherit;
    --get-c-secondary-light: inherit;
    --get-c-secondary-hover: inherit;
    --get-c-secondary-text: inherit;

    --get-c-content-bg: inherit;
    --get-c-content-text: inherit;
    --get-c-content-gray: inherit;
    --get-c-content-gray-light: inherit;
    --get-c-content-gray-dark: inherit;

    --get-c-statistics-1: inherit;
    --get-c-statistics-2: inherit;
    --get-c-statistics-3: inherit;

    --get-v-font: var(--gc-v-font, Roboto);
  }
`;
export default myStyle;
