import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import { Option } from "../../components/GetSelect/types";

interface FormInputData {
  city: Option;
  street?: Option;
  zipCode: string;
  houseNumber?: string | Option;
  meterType: string;
  customerType: string;
  oneTariff: string;
  twoTariff: string;
  isEcoTariff: boolean;
  tariffType: string;
  measurementType: string;
  isAutoSubmitingDisabled: boolean;
  stichtag: string;
}

interface FormInputDataContext {
  values: FormInputData;
  setValues: React.Dispatch<React.SetStateAction<FormInputData>>;
}

const formInputData = createContext<FormInputDataContext>({ values: {} as FormInputData, setValues: () => {} });

const FormInputDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [values, setValues] = React.useState({} as FormInputData);
  return <formInputData.Provider value={{ values, setValues }}>{children}</formInputData.Provider>;
};

export default FormInputDataProvider;
export const useTRInputContext = () => useContextSelector(formInputData, (c) => c);
