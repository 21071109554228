import React from "react";

import FormRow from "../../../../components/FormRow";
import Input from "../../../../components/Input";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const InhaberForm = () => {
  const { firstName, lastName, prevTenant } = useFeaturesCC();
  return (
    <>
      <div className="font-medium text-lg">{prevTenant}</div>
      <FormRow addClassName="flex flex-col sm:flex-row">
        <div className="mb-4 sm:mb-0 mr-0 sm:mr-4 sm:w-1/2 w-full">
          <Input name="inhaberFirstName" label={firstName} placeholder="Vorname" inputType="name" />
        </div>
        <div className="sm:w-1/2 w-full">
          <Input name="inhaberLastName" label={lastName} placeholder="Nachname" inputType="name" />
        </div>
      </FormRow>
    </>
  );
};

export default InhaberForm;
