import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useRadioClassName from "../../hooks/useRadioClassName";

const MeasurementType: React.FC = () => {
  const { gemeinsameMessung, getrennteMessung, measurementHeader } = useFeatureInputPage();
  const radioClassName = useRadioClassName();

  return (
    <div>
      <div className="mb-1 text-xs w-full">{measurementHeader}</div>
      <div className={radioClassName}>
        <FormikRadio label={gemeinsameMessung} name="measurementType" value="0" className="m-0 accent-primary" />
        <FormikRadio label={getrennteMessung} name="measurementType" value="1" className="m-0 accent-primary" />
      </div>
    </div>
  );
};

export default MeasurementType;
