import { useFormikContext } from "formik";
import qs from "query-string";
import { useMemo } from "react";

import { useTariff } from "../../../../context";
import convertDate from "../../../../utils/convertDate";
import { isValidDateAsString } from "../../../../utils/isValidDate";
import { InputFormModalFormik } from "../../types/InputFormModalFormik";
import useFeaturesResult from "../useFeaturesResult";

const useQueryParametersResult = (setPageParam = true) => {
  const { values } = useFormikContext<InputFormModalFormik>();
  const { paging } = useFeaturesResult();
  const tariff = useTariff();

  return useMemo(() => {
    return qs.stringify({
      calculatorType: tariff.id,
      customerType: values.customerType,
      zipCode: values.zipCode,
      ...(values.city?.value ? { city: values.city?.value } : {}),
      ...(values.street?.value ? { street: values.street.value } : {}),
      ...(values.houseNumber
        ? {
            hnr: (typeof values.houseNumber !== "string" && values.houseNumber.value) || values.houseNumber,
          }
        : {}),
      primeTimeConsumption: tariff.id === "7" ? 20000 : parseInt(values.oneTariff?.replace(".", "")),
      ...(values.meterType === "3,4" ? { secondaryTimeConsumption: values.twoTariff?.replace(".", "") } : {}),
      ...(setPageParam ? { page: 1 } : {}),
      gridConnectionType: 0,
      pageSize: paging ?? 20,
      ...(tariff.id === "3" ? { vergleichsRichtlinie: 10 } : {}),
      ...(values.twoTariff && tariff.id === "1" ? { zaehlerart: "3,4" } : {}),
      ...(values.twoTariff && tariff.id === "3" ? { zaehlerart: "3,4" } : {}),
      ...(values.twoTariff && tariff.id === "4" ? { zaehlerart: "3,4" } : {}),
      ...(!values.twoTariff && tariff.id === "3" ? { zaehlerart: "1,2" } : {}),
      ...(!values.twoTariff && tariff.id === "4" ? { zaehlerart: "1,2" } : {}),
      ...(values.twoTariff && tariff.id === "1" ? { tariftyp: "1,2" } : {}),
      ...(!values.twoTariff && tariff.id === "4" && values.measurementType === "0" ? { tariftyp: "1" } : {}),
      ...(values.twoTariff && tariff.id === "4" && values.measurementType === "0" ? { tariftyp: "1,2" } : {}),
      ...(tariff.id === "4" && values.measurementType === "1" ? { tariftyp: "5" } : {}),
      ...(tariff.id === "3" ? { tariftyp: values.tariffType } : {}),
      ...(isValidDateAsString(values.stichtag) ? { stichtag: convertDate(values.stichtag) } : {}),
      ...(tariff.id === "7" && values.houseNumber
        ? {
            houseNumberComplete: typeof values.houseNumber === "string" ? values.houseNumber : values.houseNumber.value,
          }
        : {}),
    });
  }, [
    tariff.id,
    values.city,
    values.customerType,
    values.houseNumber,
    values.oneTariff,
    values.street,
    values.twoTariff,
    values.zipCode,
    values.tariffType,
    paging,
    setPageParam,
    values.meterType,
    values.measurementType,
    values.stichtag,
  ]);
};

export default useQueryParametersResult;
