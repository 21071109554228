import React from "react";

import { PreisgarantieBis } from "../../../../TCInputs/types/SimplifierResponse";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import RowItem from "../RowItem";

interface PriceGuaranteeProps {
  priceguarantee: number | undefined;
  priceguaranteeDate: undefined | PreisgarantieBis;
  priceguaranteeComponents: string | undefined;
  vertragslaufzeit: string;
}

const PriceGuarantee = ({
  priceguarantee,
  priceguaranteeDate,
  priceguaranteeComponents,
  vertragslaufzeit,
}: PriceGuaranteeProps) => {
  const {
    contractperiod,
    priceguarantee: priceguaranteeLabel,
    oneMonth,
    multipleMonths,
    notSpezified,
  } = useFeaturesResult();
  const guarantee =
    typeof priceguarantee === "number" && priceguarantee !== 0
      ? priceguarantee === 1
        ? `${String(priceguarantee)} ${oneMonth}`
        : `${String(priceguarantee)} ${multipleMonths}`
      : typeof priceguaranteeDate === "object" && priceguaranteeDate
        ? `bis ${new Intl.DateTimeFormat("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" }).format(
            new Date(priceguaranteeDate.year, priceguaranteeDate.monthValue - 1, priceguaranteeDate.dayOfMonth)
          )}`
        : "keine";

  return (
    <section id="price_guarantee">
      <div className="flex flex-col gap-1">
        <RowItem
          label={contractperiod}
          value={vertragslaufzeit === "-" ? notSpezified : vertragslaufzeit}
          invert={true}
        />
        <div>
          <RowItem label={priceguaranteeLabel} value={guarantee} />
          {priceguaranteeComponents && <RowItem label={""} value={priceguaranteeComponents} invert={true} />}
        </div>
      </div>
    </section>
  );
};

export default PriceGuarantee;
