import { useField } from "formik";
import React, { useState } from "react";

const TextSwitch = ({ left, right, name, values }) => {
  const [field, , helpers] = useField(name);
  const [state, setState] = useState(Number.parseInt(field?.value));
  const { setValue } = helpers;
  const handleClick = () => {
    setState(!state);
    state ? setValue(values[0]) : setValue(values[1]);
  };
  const active = "bg-[#fff] transition ease-in duration-500";
  const inactive = "bg-button-primary-bg transition ease-out duration-500";
  return (
    <>
      <div onClick={() => handleClick()} className="grid grid-cols-2 bg-button-primary-bg rounded-full p-0.5 cursor-pointer w-full text-center">
        <div className={(!state ? active : inactive) + " rounded-full py-1 px-2 leading-5"}>
          {left}
        </div>
        <div className={(state ? active : inactive) + " rounded-full py-1 px-2 leading-5"}>
          {right}
        </div>
      </div>
    </>
  );
};
export default TextSwitch;
