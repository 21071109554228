import React from "react";

import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import TRButtonGroups from "../ButtonGroups";

const TextHeader = () => {
  const { headerLabel } = useFeatureInputPage();
  return <div className="text-3xl">{headerLabel}</div>;
};

const Header = () => {
  return (
    <>
      <Mobile>
        <div>
          <TextHeader />
          <TRButtonGroups />
        </div>{" "}
      </Mobile>
      <Tablet>
        <div className="flex flex-col">
          <TextHeader />
          <TRButtonGroups />
        </div>
      </Tablet>
      <Desktop>
        <div className="flex flex-col">
          <TextHeader />
          <TRButtonGroups />
        </div>
      </Desktop>
    </>
  );
};

export default Header;
