import React from "react";

import useExternalConfigs from "../../../hooks/useExternalConfigs";

const useFeatureConfirmationPage = () => {
  const { data: config } = useExternalConfigs("confirmationPage");
  const confirmationPage = config?.data?.data?.confirmationPage;

  const confirmMessage = React.useMemo(() => {
    return confirmationPage?.confirmMessage || "Ihr Auftrag wurde erfolgreich erfasst.";
  }, [confirmationPage?.confirmMessage]);
  const confirmText = React.useMemo(() => {
    return confirmationPage?.confirmText || "Ihr Auftrag wird nun bearbeitet.";
  }, [confirmationPage?.confirmText]);
  const confirmHeader = React.useMemo(() => {
    return confirmationPage?.confirmHeader || "Vielen Dank für Ihre Bestellung";
  }, [confirmationPage?.confirmHeader]);
  const backToHomeLabel = React.useMemo(() => {
    return confirmationPage?.backToHome || "Weiteren Vertrag abschließen";
  }, [confirmationPage?.backToHome]);
  return {
    confirmMessage,
    confirmText,
    confirmHeader,
    backToHomeLabel,
  };
};

export default useFeatureConfirmationPage;
