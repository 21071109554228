import React from "react";
const Calculator = ({ fill = "var(--get-c-breadcrumb)" }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="inline align-text-top"
    >
      <mask id="mask0_775_16159" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_775_16159)">
        <path
          d="M5.83333 12H6.83333V10.6667H8.16667V9.66667H6.83333V8.33333H5.83333V9.66667H4.5V10.6667H5.83333V12ZM9.16667 11.5H12.5V10.5H9.16667V11.5ZM9.16667 9.83333H12.5V8.83333H9.16667V9.83333ZM9.9 7.3L10.8333 6.36667L11.7667 7.3L12.4667 6.6L11.5333 5.63333L12.4667 4.7L11.7667 4L10.8333 4.93333L9.9 4L9.2 4.7L10.1333 5.63333L9.2 6.6L9.9 7.3ZM4.66667 6.13333H8V5.13333H4.66667V6.13333ZM3.83333 14C3.46667 14 3.15278 13.8694 2.89167 13.6083C2.63056 13.3472 2.5 13.0333 2.5 12.6667V3.33333C2.5 2.96667 2.63056 2.65278 2.89167 2.39167C3.15278 2.13056 3.46667 2 3.83333 2H13.1667C13.5333 2 13.8472 2.13056 14.1083 2.39167C14.3694 2.65278 14.5 2.96667 14.5 3.33333V12.6667C14.5 13.0333 14.3694 13.3472 14.1083 13.6083C13.8472 13.8694 13.5333 14 13.1667 14H3.83333ZM3.83333 12.6667H13.1667V3.33333H3.83333V12.6667Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Calculator;
