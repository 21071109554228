import React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useSelectTelco } from "../../../../context/Telco";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const PaymentOptions = () => {
  const { selectedTelco } = useSelectTelco();
  const { paymentMethodLabels, bankTitleLabel } = useFeaturesCC();

  return (
    <div className="flex flex-col gap-5">
      <div className="font-bold text-lg">{bankTitleLabel}</div>

      <div className="grid lg:grid-cols-3">
        {selectedTelco ? (
          <>
            <FormikRadio
              key={paymentMethodLabels[0].value}
              value={paymentMethodLabels[0].value}
              label={paymentMethodLabels[0].label}
              name="paymentOptions"
            />
          </>
        ) : (
          <>
            {paymentMethodLabels.map((option) => (
              <FormikRadio key={option.value} value={option.value} label={option.label} name="paymentOptions" />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentOptions;
