import * as React from "react";

const Cart = ({ fill = "var(--get-c-breadcrumb)" }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="inline align-text-top"
    >
      <mask id="mask0_775_16201" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_775_16201)">
        <path
          d="M5.16699 14.6667C4.80033 14.6667 4.48644 14.5362 4.22533 14.275C3.96421 14.0139 3.83366 13.7 3.83366 13.3334C3.83366 12.9667 3.96421 12.6528 4.22533 12.3917C4.48644 12.1306 4.80033 12 5.16699 12C5.53366 12 5.84755 12.1306 6.10866 12.3917C6.36977 12.6528 6.50033 12.9667 6.50033 13.3334C6.50033 13.7 6.36977 14.0139 6.10866 14.275C5.84755 14.5362 5.53366 14.6667 5.16699 14.6667ZM11.8337 14.6667C11.467 14.6667 11.1531 14.5362 10.892 14.275C10.6309 14.0139 10.5003 13.7 10.5003 13.3334C10.5003 12.9667 10.6309 12.6528 10.892 12.3917C11.1531 12.1306 11.467 12 11.8337 12C12.2003 12 12.5142 12.1306 12.7753 12.3917C13.0364 12.6528 13.167 12.9667 13.167 13.3334C13.167 13.7 13.0364 14.0139 12.7753 14.275C12.5142 14.5362 12.2003 14.6667 11.8337 14.6667ZM4.60033 4.00004L6.20033 7.33337H10.867L12.7003 4.00004H4.60033ZM3.96699 2.66671H13.8003C14.0559 2.66671 14.2503 2.7806 14.3837 3.00837C14.517 3.23615 14.5225 3.46671 14.4003 3.70004L12.0337 7.96671C11.9114 8.18893 11.7475 8.36115 11.542 8.48337C11.3364 8.6056 11.1114 8.66671 10.867 8.66671H5.90033L5.16699 10H13.167V11.3334H5.16699C4.66699 11.3334 4.28921 11.1139 4.03366 10.675C3.7781 10.2362 3.76699 9.80004 4.00033 9.36671L4.90033 7.73337L2.50033 2.66671H1.16699V1.33337H3.33366L3.96699 2.66671Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Cart;
