import { useFormikContext } from "formik";
import React from "react";

import EditIcon from "../../../../../components/icons/editIcon";
import useResponsiveClass from "../../../../../hooks/useResponsiveClass";
import { FormValues } from "../../../../../types/FormValues";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";
import { useEditDataModalContext } from "../../../context/EditDataModalContext";

const PersonalDataInfo = () => {
  const { values } = useFormikContext<FormValues>();
  const { contactTitle, title, phoneNumber, mobileNumber, salutation, firstName, lastName, birthday, email } =
    useFeaturesCC();
  const { responsiveClass } = useResponsiveClass();
  const { setShowEditDataModal } = useEditDataModalContext();

  return (
    <div
      className={responsiveClass(
        "w-full grid gap-y-2 gap-x-4 break-words",
        "grid-cols-2",
        "grid-cols-2",
        "grid-cols-4"
      )}
    >
      <Headline>
        {contactTitle}
        <span
          onClick={() => setShowEditDataModal("contactForm")}
          id="editContactForm"
          className="float-right cursor-pointer"
        >
          <EditIcon />
        </span>
      </Headline>
      <div>
        <div className="text-xs text-gray-dark">{salutation}</div>
        <div>{typeof values.salutation !== "string" && values.salutation?.value ? values.salutation?.value : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{title}</div>
        <div>{typeof values.title !== "string" && values.title?.value ? values.title?.value : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{firstName}</div>
        <div>{values.firstName !== "" ? values.firstName : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{lastName}</div>
        <div>{values.lastName !== "" ? values.lastName : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{birthday}</div>
        <div>{values.birthday !== "" ? values.birthday : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{phoneNumber}</div>
        <div>{values.phoneNumber ? values.phoneNumber : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{mobileNumber}</div>
        <div>{values.mobileNumber ? values.mobileNumber : "-"}</div>
      </div>
      <div>
        <div className="text-xs text-gray-dark">{email}</div>
        <div>{values.email ? values.email : "-"}</div>
      </div>
    </div>
  );
};

export default PersonalDataInfo;
