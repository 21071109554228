import { useFormikContext } from "formik";
import * as React from "react";

import GetSelect from "../../../../components/GetSelect";
import WarningIcon from "../../../../components/icons/warning";
import Input from "../../../../components/Input";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { useParameter, useTariff } from "../../../../context";
import usePrimaService from "../../../../hooks/usePrimaService";
import useVPPCustomerService from "../../../../hooks/useVPPCustomerService";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const TRAddress = ({ isError, data, isLoading, children }) => {
  const { values, setFieldValue } = useFormikContext();
  const { plz, ort, strasse } = useParameter();
  const { data: vppData } = useVPPCustomerService();
  const { data: primaData } = usePrimaService();
  const tariff = useTariff();
  const {
    zipCodeLabel,
    cityLabel,
    streetLabel,
    houseNumberLabel,
    tariffNotFoundLabel,
  } = useFeatureInputPage();

  React.useEffect(() => {
    if (data?.cityOptions?.length === 1) {
      setFieldValue("city", data?.cityOptions[0]);
    }
  }, [data?.cityOptions, setFieldValue]);

  React.useEffect(() => {
    if (data?.streetOptions?.length === 1) {
      setFieldValue("street", data?.streetOptions);
    }
  }, [data?.streetOptions, setFieldValue]);

  React.useEffect(() => {
    if (data?.houseNumberOptions?.length === 1) {
      setFieldValue("houseNumber", data?.houseNumberOptions);
    }
  }, [data?.houseNumberOptions, setFieldValue]);

  const disabledCity = React.useMemo(() => {
    return data?.cityOptions?.length === 0;
  }, [data?.cityOptions?.length]);

  const isCityDefaultOpen = React.useMemo(() => {
    if (vppData) {
      return !!vppData.data?.object?.person?.addresses.find(
        (val) => val?.address_type === "SHIPPING"
      )?.city;
    }

    return !values.city && data?.cityOptions?.length > 1;
  }, [data?.cityOptions?.length, values.city, vppData]);

  const isStreetDefaultOpen = React.useMemo(() => {
    return values.city && !values.street && data?.streetOptions?.length > 1;
  }, [data?.streetOptions?.length, values.city, values.street]);

  const isHouseNumberDefaultOpen = React.useMemo(() => {
    return (
      values.city &&
      values.street &&
      !values.houseNumber &&
      data?.houseNumberOptions?.length > 1
    );
  }, [
    data?.houseNumberOptions?.length,
    values.city,
    values.houseNumber,
    values.street,
  ]);

  // NOTHING TO DO
  React.useEffect(() => {
    if (values.zipCode.length < 5) {
      setFieldValue("city", "");
      setFieldValue("street", "");
      setFieldValue("houseNumber", "");
    }
  }, [setFieldValue, values.zipCode.length]);

  React.useEffect(() => {
    if (vppData) {
      const shippingAddress = vppData?.data?.object?.person?.addresses?.find(
        (val) => val.address_type === "SHIPPING"
      );

      if (shippingAddress) {
        setFieldValue("zipCode", shippingAddress.zipcode);
        setFieldValue("city", {
          label: shippingAddress.city,
          value: shippingAddress.city,
        });
      }
    }
  }, [setFieldValue, vppData]);

  React.useEffect(() => {
    if (primaData && tariff.id === 7) {
      if (primaData.zipCode) {
        setFieldValue("zipCode", primaData.zipCode);
        setFieldValue("city", { label: primaData.city, value: primaData.city });
        setFieldValue("street", {
          label: primaData.street,
          value: primaData.street,
        });
        setFieldValue("houseNumber", {
          label: primaData.houseNumber,
          value: primaData.houseNumber,
        });
      }
    }

    if (plz) {
      setFieldValue("zipCode", `${plz}`);
    }
  }, [plz, primaData, setFieldValue, tariff.id]);

  React.useEffect(() => {
    if (ort) {
      setFieldValue("city", { label: ort, value: ort });
    }
  }, [ort, setFieldValue]);

  React.useEffect(() => {
    if (strasse) {
      setFieldValue("street", { label: strasse, value: strasse });
    }
  }, [strasse, setFieldValue]);

  return (
    <>
    <Desktop>
      <div className="grid grid-cols-10 gap-2 lg:gap-4">
        <div className="w-full col-span-2">
          <Input
            label={zipCodeLabel}
            name="zipCode"
            onlyNumber
            maxLength={5}
            tabIndex={1}
            isLoading={isLoading}
          />
        </div>
        <div className="w-full col-span-5">
          <GetSelect
            label={cityLabel}
            name="city"
            isLoading={isLoading}
            disabled={disabledCity}
            defaultOpen={isCityDefaultOpen}
            options={data?.cityOptions || []}
            tabIndex={2}
          />
        </div>
        <div className="w-full col-span-3">
          {children}
        </div>
      </div>
    </Desktop>
    <Tablet>
      <div className="grid grid-cols-10 gap-2 lg:gap-4">
        <div className="w-full col-span-2">
          <Input
            label={zipCodeLabel}
            name="zipCode"
            onlyNumber
            maxLength={5}
            tabIndex={1}
            isLoading={isLoading}
          />
        </div>
        <div className="w-full col-span-5">
          <GetSelect
            label={cityLabel}
            name="city"
            isLoading={isLoading}
            disabled={disabledCity}
            defaultOpen={isCityDefaultOpen}
            options={data?.cityOptions || []}
            tabIndex={2}
          />
        </div>
        <div className="w-full col-span-3">
          {children}
        </div>
      </div>
    </Tablet>

    <Mobile>
      <div className="grid grid-cols-2 gap-2">
        <div className="w-full">
          <Input
            label={zipCodeLabel}
            name="zipCode"
            onlyNumber
            maxLength={5}
            tabIndex={1}
            isLoading={isLoading}
          />
        </div>
        <div className="w-full">
          {children}
        </div>
        <div className="w-full col-span-2">
          <GetSelect
            label={cityLabel}
            name="city"
            isLoading={isLoading}
            disabled={disabledCity}
            defaultOpen={isCityDefaultOpen}
            options={data?.cityOptions || []}
            tabIndex={2}
          />
        </div>
      </div>
    </Mobile>

      {data?.streetOptions && (
        <div className="grid grid-cols-10 gap-2 lg:gap-4">
            <div className="w-full col-span-7">
                <GetSelect
                  label={streetLabel}
                  name="street"
                  className="z-50"
                  defaultOpen={isStreetDefaultOpen}
                  disabled={data?.streetOptions?.length === 0}
                  isSearchable
                  isLoading={isLoading}
                  options={data?.streetOptions || []}
                />
            </div>
          <div className="w-full col-span-3">
            {data?.houseNumberOptions?.length === 0 &&
              !primaData?.houseNumber && (
                <Input
                  label={houseNumberLabel}
                  name="houseNumber"
                  inputType="number"
                />
              )}
            {((primaData?.houseNumber && tariff.id === 7) ||
              data?.houseNumberOptions?.length >= 1) && (
              <GetSelect
                label={houseNumberLabel}
                name="houseNumber"
                isSearchable
                defaultOpen={isHouseNumberDefaultOpen}
                options={data?.houseNumberOptions}
              />
            )}
          </div>
        </div>
      )}
      {isError && values.zipCode.length === 5 && (
        <div className="flex flex-row items-center mt-2 w-full">
          <WarningIcon className="mr-3" fill="red" />
          <small className="text-red-500">{tariffNotFoundLabel}</small>
        </div>
      )}
    </>
  );
};

export default TRAddress;
