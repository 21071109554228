import React from "react";

const EditIcon = () => {
  return (
    <span className="size-4 inline-block p-auto text-center py-1">
      <svg
        width="16"
        height="16"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-primary"
      >
        <path d="M6.03 3.51L6.49 3.97L1.96 8.5H1.5V8.04L6.03 3.51ZM7.83 0.5C7.705 0.5 7.575 0.55 7.48 0.645L6.565 1.56L8.44 3.435L9.355 2.52C9.55 2.325 9.55 2.01 9.355 1.815L8.185 0.645C8.085 0.545 7.96 0.5 7.83 0.5ZM6.03 2.095L0.5 7.625V9.5H2.375L7.905 3.97L6.03 2.095Z" />
      </svg>
    </span>
  );
};
export default EditIcon;
