import { Formik } from "formik";
import pick from "lodash/pick";
import React from "react";

import { useStepChange } from "../../context";
import { useTRInputContext } from "../../context/CancellationData";
import useExternalConfigs from "../../hooks/useExternalConfigs";
import InputForm from "./inputForm";
import { FormularFormik } from "./types/FormularFormik";

const TCFormular = ({
  submit,
}: {
  submit: ({ formikValues, konfigValues }: { formikValues: FormularFormik; konfigValues: any }) => void;
}) => {
  const { setStep } = useStepChange();
  const { data } = useExternalConfigs("cancellationform");
  const konfigValue = data?.data?.data?.functionscancellationform?.anbieterauswahl[0];

  const { values: formData, setValues } = useTRInputContext();

  return (
    <Formik
      component={InputForm}
      // validationSchema={validationSchema}
      initialValues={{
        vorname: formData?.vorname || "",
        name: formData?.name || "",
        birthday: formData?.birthday || "",
        strasse: formData?.strasse || "",
        hausnummer: formData?.hausnummer || "",
        ort: formData?.ort || "",
        plz: formData?.plz || "",
        versorger: formData?.versorger || { value: "", label: "" },
        kundennummer: formData?.kundennummer || "",
        marktlokationsID: formData?.marktlokationsID || "",
        vertragsnummer: formData?.vertragsnummer || "",
        zaehlernummer: formData?.zaehlernummer || "",
        grund: formData?.grund || { value: "", label: "" },
        hasDesiredCancellationDate: formData?.hasDesiredCancellationDate || false,
        desiredCancellationDate: formData?.desiredCancellationDate || "",
        desiredDeliveryDate: formData.desiredDeliveryDate || "",
        hasDesiredDeliveryDate: formData.hasDesiredDeliveryDate || false,
        vertragsende: formData?.vertragsende || "",
        perMail: formData?.perMail || false,
        perPost: formData?.perPost || false,
        emailadresse: formData?.emailadresse || "",
        isPrivacyPolicyCheck: formData?.isPrivacyPolicyCheck || false,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        values.vertragsende = !values.hasDesiredDeliveryDate
          ? "zum nächstmöglicher Termin"
          : values.desiredDeliveryDate;

        if (setValues) {
          setValues(values);
        }

        try {
          if (konfigValue) {
            submit({
              formikValues: values,
              konfigValues: pick(konfigValue, ["backupmail", "betreff", "sendermail", "template", "urlId", "zielmail"]),
            });
            setStep(6);
          }
        } catch (e) {
          console.error(e);
          throw e;
        }

        setSubmitting(false);
      }}
    />
  );
};

export default TCFormular;
