import { Formik } from "formik";
import React from "react";

import { useCalcParams } from "../../context/CalculationParameter";
import { useTRInputContext } from "../../context/FormInputData";
import { useSupplierInfo } from "../../context/SupplierInfo";
import convertLocalizedStringToNumber from "../../utils/convertLocalizedStringToNumber"
import ResultList from "./components/ResultsList";
import TelcoResults from "./components/TelcoResults";
import useSubmitTariffResult from "./hooks/useSubmitTariff";

const ResultPage = ({ type, data }) => {
  return (
    <>
      {type === "energie" && <ResultList data={data} />}
      {type === "telco" && <TelcoResults data={data} />}
    </>
  );
};

const TariffResult = ({ type, data }) => {
  const {
    mutateAsync: submitTariff,
  } = useSubmitTariffResult();
  const formData = useTRInputContext();
  const { calcParams } = useCalcParams();
  const supplier = useSupplierInfo();

  return (
    <Formik
      component={() => (
        <ResultPage
          type={type || "energie"}
          data={data}
        />
      )}
      enableReinitialize
      initialValues={{
        priceType: "0",
        compareType: 0,
        supplier: supplier.supplierInformation?.versorgerName,
        tariffComparison: supplier.supplierInformation?.suppliertariffName,
        zipCode: formData.values?.zipCode || "",
        city: formData.values?.city || "",
        street: formData.values?.street || "",
        houseNumber: formData.values?.houseNumber || "",
        oneTariff: formData.values?.oneTariff || "",
        twoTariff: formData.values?.twoTariff || "",
        calculatorType: formData.values?.customerType || "0",
        eco: formData.values?.isEcoTariff || false,
        customerType: formData.values?.customerType || "0",
        isEcoTariff: formData.values?.isEcoTariff || false,
        meterType: formData.values?.meterType || "1,2",
        measurementType: formData.values?.measurementType || "0",
        basePriceBrutto: "",
        workingPriceBrutto: "",
        workingPriceNTBrutto: "",
        basePriceNetto: "",
        workingPriceNetto: "",
        workingPriceNTNetto: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await submitTariff({
          ...values,
          basePriceBrutto: convertLocalizedStringToNumber(values?.basePriceBrutto),
          basePriceNetto: convertLocalizedStringToNumber(values?.basePriceNetto),
          workingPriceBrutto: convertLocalizedStringToNumber(values?.workingPriceBrutto),
          workingPriceNTBrutto: convertLocalizedStringToNumber(values?.workingPriceNTBrutto),
          workingPriceNTNetto: convertLocalizedStringToNumber(values?.workingPriceNTNetto),
          workingPriceNetto: convertLocalizedStringToNumber(values?.workingPriceNetto),
          baseSupplier: calcParams?.netzbetreiber,
          networkOperator: calcParams?.grundversorger,
        });
        setSubmitting(false);
      }}
    />
  );
};

export default TariffResult;
