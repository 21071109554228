import { Formik } from "formik";
import qs from "query-string";
import React from "react";

import Button from "../../components/Button";
import { useParameter, useStepChange, useTariff } from "../../context";
import { calcResult, useCalcResult } from "../../context/CalculationResult";
import { useConfig } from "../../context/Config";
import { useTRInputContext } from "../../context/FormInputData";
import { useGridConnectionStatus } from "../../context/GridConnectionStatus";
import { useSelectTariff } from "../../context/Tariff";
import { Telco, useSelectTelco } from "../../context/Telco";
import { useSelectTelcoGridConnection } from "../../context/TelcoGridConnection";
import Tariff from "../../types/Tariff";
import TelcoGridConnection from "../../types/TelcoGridConnection";
import TariffInputForm from "./components/InputForms";
import TariffInputFormWidget from "./components/InputFormWidget";
import useFeatureInputPage from "./hooks/useFeatureInputPage";
import getValidationSchema from "./validation/schema";

const TariffInputs = ({ submit }: { submit: React.Dispatch<React.SetStateAction<calcResult>> }) => {
  const { values, setValues } = useTRInputContext();
  const { setStep } = useStepChange();
  const { gridConnectionStatus } = useGridConnectionStatus();
  const tariff = useTariff();
  const { config } = useConfig();
  const { calcResult } = useCalcResult();
  const inputContext = useTRInputContext();
  const { setSelectedTelco } = useSelectTelco();
  const { setSelectedTelcoGridConnection } = useSelectTelcoGridConnection();
  const { setSelectTariff } = useSelectTariff();
  const { showCancelationForm, zipCodeInvalidMessage, zipCodeValidationText, cancellationFormButton } =
    useFeatureInputPage();

  const {
    onlyInputTarget,
    kundenart,
    oeko,
    handlingTarife,
    tarifcode,
    stichtag,
    baiextendedWhiteList,
    zaehlerart,
    tarifRootIDsMindestfilter,
    tarifRootIDs,
    markenIDs,
    bonus,
    versorgerIDs,
    plz,
    ort,
    oneTariff
  } = useParameter();

  React.useEffect(() => {
    setSelectedTelco({} as Telco);
    setSelectTariff({} as Tariff);
    setSelectedTelcoGridConnection({} as TelcoGridConnection);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = getValidationSchema(zipCodeInvalidMessage, zipCodeValidationText);

  return (
    <>
      <Formik
        component={onlyInputTarget ? TariffInputFormWidget : TariffInputForm}
        validationSchema={validationSchema}
        initialValues={{
          isAutoSubmiting:
            plz !== undefined &&
            ort !== undefined &&
            plz?.length > 0 &&
            ort?.length > 0 &&
            !inputContext.values.isAutoSubmitingDisabled,
          networkOperator: "",
          city: values?.city || { value: "", label: "" },
          street: { value: "", label: "" },
          zipCode: values?.zipCode || "",
          houseNumber: "",
          meterType: "1,2",
          customerType: "0",
          oneTariff:  oneTariff && oneTariff.length>0?oneTariff: tariff?.id === "2" ? "20.000" : "3.200",
          twoTariff: "",
          isEcoTariff: false,
          tariffType: "3",
          measurementType: "0",
          baseSupplier: "",
          stichtag: stichtag ?? "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          if (setValues) {
            setValues((prevState) => {
              return {
                ...prevState,
                ...values,
              };
            });
          }

          if (onlyInputTarget) {
            if (typeof window !== "undefined") {
              const queryString = qs.stringify({
                tariffCalculatorTypeId: tariff.id,
                [tariff.id === "2" ? "verbrauchGas" : "verbrauchStrom"]: values.oneTariff,
                plz: values.zipCode,
                ...(values.city
                  ? {
                      ort: typeof values.city !== "string" ? values.city.value : values.city,
                    }
                  : {}),
                ...(values.street
                  ? {
                      strasse: typeof values.street !== "string" ? values.street.value : values.street,
                    }
                  : {}),
                ...(kundenart ? { kundenart } : {}),
                ...(oeko ? { oeko } : {}),
                ...(handlingTarife ? { handlingTarife } : {}),
                ...(tarifcode ? { tarifcode } : {}),
                ...(stichtag ? { stichtag } : {}),
                ...(baiextendedWhiteList ? { baiextendedWhiteList } : {}),
                ...(zaehlerart ? { zaehlerart } : {}),
                ...(tarifRootIDs ? { tarifRootIDs } : {}),
                ...(markenIDs ? { markenIDs } : {}),
                ...(bonus ? { bonus } : {}),
                ...(versorgerIDs ? { versorgerIDs } : {}),
                ...(tarifRootIDsMindestfilter ? { tarifRootIDsMindestfilter } : {}),
              });
              window.open(`${onlyInputTarget}?${queryString}`, "_self");
            }
          } else {
            if (tariff.id === "7") {
              const processMapping = config.frontendConfig?.processmapping.find((v) => v.mapping);

              if (processMapping?.mapping) {
                const data = processMapping.mapping.find((value) => value.status_extern === gridConnectionStatus);

                if (data) {
                  if (data.status_extern === 0 && data.pid_intern === 0) {
                    try {
                      if (calcResult === null) {
                        submit((oldValues) => {
                          return { ...oldValues, ...values };
                        });
                      }
                      setStep(2);
                    } catch (e) {
                      console.error(e);
                      throw e;
                    }
                  } else if (data.status_extern === 2 && data.pid_intern === 2) {
                    // TODO: go to contact form
                    setStep(7);
                  } else {
                    try {
                      if (calcResult === null) {
                        submit((oldValues) => {
                          return { ...oldValues, ...values };
                        });
                      }
                      setStep(2);
                    } catch (e) {
                      console.error(e);
                      throw e;
                    }
                  }
                } else {
                  if (calcResult === null) {
                    await submit((oldValues) => {
                      return { ...oldValues, ...values };
                    });
                  }
                  setStep(2);
                }
              } else {
                setStep(7);
              }
            } else {
              try {
                if (calcResult === null) {
                  await submit((oldValues) => {
                    return { ...oldValues, ...values };
                  });
                }
                setStep(2);
              } catch (e) {
                console.error(e);
                throw e;
              }
            }
          }
        }}
      />

      {showCancelationForm && (
        <div className="max-w-screen-sm mx-auto p-3">
          <Button onClick={() => setStep(5)} variant="primary" type="submit" className="py-1 px-5">
            {cancellationFormButton}
          </Button>
        </div>
      )}
    </>
  );
};

export default TariffInputs;
