import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import Tariff from "../../types/Tariff";
interface selectedTariffContext {
  selectedTariff: Tariff;
  setSelectTariff: React.Dispatch<React.SetStateAction<Tariff>>;
}

const TariffContext = createContext<selectedTariffContext>({} as selectedTariffContext);

const TariffProvider = ({ children }: {children: React.ReactNode}) => {
  const [selectedTariff, setSelectTariff] = React.useState({} as Tariff);

  return (
    <TariffContext.Provider value={{ selectedTariff, setSelectTariff }}>
      {children}
    </TariffContext.Provider>
  );
};

export default TariffProvider;
export const useSelectTariff = () =>
  useContextSelector(TariffContext, (c) => c);
