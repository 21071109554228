import { Globe } from "lucide-react";
import React from "react";
import { useCallback, useState } from "react";
import { SlQuestion } from "react-icons/sl";

import SingleAccordion from "../../../../components/Accordion";
import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import ReactHTMLParser from "../../../../components/HTMLParser";
import { useParameter } from "../../../../context";
import formatCurrency from "../../../../utils/formatCurrency";
import scrollToTop from "../../../../utils/scrollToTop";
import useFeaturesResult from "../../hooks/useFeaturesResult";

const List= ({ list, onSelect }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const {
    contractperiodLabel,
    contractextensionLabel,
    internetSpeedUnitLabel,
    optionInfoLabel,
    tariffDetailsLabel,
    productDescriptionLabel,
    missingProductDescriptionLabel,
    infinityPeriodLabel,
  } = useFeaturesResult();

  const { productView } = useParameter();

  const handleTooltip = useCallback(() => {
    setIsTooltipOpen((state) => !state);
  }, []);

  const typeIndexes = React.useMemo(() => {
    const typeIndexes = {};

    list.productDocuments.forEach((item, key) => {
      if (item.uRL?.endsWith(".jpg")) typeIndexes.jpg = key;
      if (item.uRL?.endsWith(".pdf")) typeIndexes.pdf = key;
    });

    return typeIndexes;
  }, [list.productDocuments]);

  return (
    <Card
      key={list.id}
      className="flex-row"
      style={{ minWidth: productView === "carousel" ? "350px" : "inherit" }}
    >
      <div className="text-xl font-bold">{list.productName}</div>
      <div>{list.providerName}</div>
      <div className="grid grid-cols-3 text-center my-6 items-center">
        <span className="text-xl font-bold">Download</span>
        <span className="text-5xl font-bold w-32">
          {list.landlineInternetDownloadSpeed || 0}
        </span>
        <span className="text-xl font-bold">{internetSpeedUnitLabel}</span>
      </div>
      <div className="grid grid-cols-3 text-center mb-6 items-center">
        <span className="text-xl font-bold">Upload</span>
        <span className="text-5xl font-bold">
          {list.landlineInternetUploadSpeed || 0}
        </span>
        <span className="text-xl font-bold">{internetSpeedUnitLabel}</span>
      </div>
      <div className="mb-4">
        <SingleAccordion title={productDescriptionLabel}>
          <Card className="my-4">
            <ReactHTMLParser>
              {list?.productDescription || missingProductDescriptionLabel}
            </ReactHTMLParser>
          </Card>
        </SingleAccordion>
      </div>

      <div className="w-full">
        <div className="flex items-center justify-between ">
          <div className="flex">
            <div>
              <Globe size={26} />
            </div>
            <div className="flex-row space-x-2">
              <span className=" self-center text-xl font-medium pl-1">
                INTERNET
              </span>
              <div
                onMouseEnter={handleTooltip}
                onMouseLeave={handleTooltip}
                className={`${
                  isTooltipOpen ? "tooltip-open" : ""
                } tooltip tooltip-primary `}
                style={{ whiteSpace: "pre-line" }}
                data-tip={`Download:
                Min: ${list?.landlineInternetDownloadSpeedMinimal}
                Normal: ${list?.landlineInternetDownloadSpeedNormal}
                Max: ${list?.landlineInternetDownloadSpeed}
                Upload:
                Min: ${list?.landlineInternetUploadSpeedMinimal}
                Normal: ${list?.landlineInternetUploadSpeedNormal}
                Max: ${list?.landlineInternetUploadSpeed}`}
              >
                <div className=" flex h-5 w-5 rounded-md text-black cursor-pointer items-center justify-center">
                  <SlQuestion className="h-5 w-5" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pl-8">
          inkl. Internet-Flatrate <br />
          inkl. FRITZ!Box <br />
          garantierte Bandbreite
        </div>
      </div>

      <div className="h-[120px]">
        {typeIndexes.jpg !== undefined &&
          list.productDocuments[typeIndexes.jpg].uRL && (
            <img
              src={list.productDocuments[typeIndexes.jpg].uRL}
              alt={list.productDocuments[typeIndexes.jpg].fileName}
              width="100%"
              height="120px"
              style={{ objectFit: "cover" }}
            />
          )}
      </div>

      <div className="text-center" style={{ minHeight: "142px" }}>
        {list.monthlyPrices.map((item) => {
          return (
            <>
              <div>
                <span className={"text-3xl mb-2 font-bold"}>
                  {formatCurrency(item.pricePerMonth)}
                </span>

                <span>monatlich</span>
              </div>

              <div className="mb-4">
                {item.to
                  ? `${item.from}. - ${item.to}. Monat(e)`
                  : `ab ${item.from}. Monat(e)`}
              </div>
            </>
          );
        })}
        {list.monthlyPrices.length === 0 && (
          <div>
            <span className="text-3xl mb-2 font-bold">
              {formatCurrency(0.0)}
            </span>
            <span>monatlich</span>
          </div>
        )}
      </div>
      <div className="h-2.5">
        {typeIndexes.pdf !== undefined &&
          list.productDocuments[typeIndexes.pdf].uRL && (
            <a
              rel="noreferrer"
              href={list.productDocuments[typeIndexes.pdf].uRL}
              target="_blank"
            >
              {optionInfoLabel}
            </a>
          )}
      </div>
      <div className="my-6">
        <div className="text-xl font-bold">{tariffDetailsLabel}</div>
        <div className="mb-2">
          {contractperiodLabel}{" "}
          {list.contractPeriodMonths
            ? list.contractPeriodMonths + " Monat(e)"
            : infinityPeriodLabel}
        </div>

        <div className="mb-2">
          {contractextensionLabel}{" "}
          {list.contractExtensionMonths
            ? list.contractExtensionMonths + " Monat(e)"
            : infinityPeriodLabel}
        </div>

        <div className="mb-2">
          Kündigungsfrist {list.contractPeriodDate || infinityPeriodLabel}
        </div>
      </div>
      <Button
        className="w-full p-2"
        variant="primary"
        onClick={() => {
          onSelect(list);
          scrollToTop();
        }}
      >
        Jetzt bestellen
      </Button>
    </Card>
  );
};

export default List;
