import toast from "../../../../components/Toast";

const handleError = (error: Error) => {
  if (error.message === "Error getting data") {
    toast.error(
      "Zur gewählten Örtlichkeit konnten leider keine Tarife gefunden werden!"
    );
  }
};

export default handleError;
