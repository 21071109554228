import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../../components/Button";
import { useCalcParams } from "../../../../../context/CalculationParameter";
import useCreateOffer from "../../../hooks/useCreateOffer";
import useFeaturesCC from "../../../hooks/useFeaturesCC";

const PrePrint = () => {
  const { calcParams } = useCalcParams();
  const { mutateAsync } = useCreateOffer("summaryForm");
  const { values } = useFormikContext();
  const { prePrintLabelBtn, prePrintInfoText } = useFeaturesCC();

  const handleDownloadSummary = React.useCallback(async () => {
    if (values.pdfUrl) {
      if (typeof window !== "undefined") {
        window.open(values.pdfUrl, "_blank");
      }
    } else {
      try {
        await mutateAsync(calcParams || {});
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }, [calcParams, mutateAsync, values.pdfUrl]);

  return (
    <>
      <div className="mt-4">{prePrintInfoText}</div>
      <Button
        className="my-4 p-2"
        variant="primary"
        onClick={handleDownloadSummary}
      >
        {prePrintLabelBtn}
      </Button>
    </>
  );
};

export default PrePrint;
