import React from "react";
import ReactDOM from "react-dom/client";
import App from "./direflow-components/tariff-calculator-component/App";

class TariffCalculator5 extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  }
  connectedCallback() {
    if (!this.shadowRoot) {
      throw new Error("Shadow DOM wurde nicht korrekt initialisiert.");
    }
    const container = document.createElement("div");
    const props = this.getPropsFromAttributes<any>();
    this.shadowRoot.appendChild(container);

    const root = ReactDOM.createRoot(container);
    root.render(<App {...props} />);
  }
  private getPropsFromAttributes<T>(): T {
    const normalizeAttribute = (attribute: string) => {
      return attribute.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
    };
    const props: Record<string, string> = {};

    for (let index = 0; index < this.attributes.length; index++) {
      const attribute = this.attributes[index];
      props[normalizeAttribute(attribute.name)] = attribute.value;
    }

    return props as T;
  }

  disconnectedCallback() {}
}

if (!customElements.get("tariff-calculator-5")) {
  customElements.define("tariff-calculator-5", TariffCalculator5);
}
