import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    /* Colors */
    --gc-c-light: inherit;
    --gc-c-grey: inherit;
    --gc-c-grey-light: inherit;
    --gc-c-grey-middle: inherit;
    --gc-c-grey-strong: inherit;
    --gc-c-text-normal: inherit;
    --gc-c-primary-normal: inherit;
    --gc-c-primary-hover: inherit;

    /* other Vars */
    --gc-v-font: inherit;
    --gc-v-rounded-default: inherit;
    --gc-v-rounded-input: inherit;
    --gc-v-rounded-button: inherit;

    /* assignment */
    --gc-content-bg: inherit;
    --gc-content-border: inherit;
    --gc-content-radius: inherit;
    --gc-content-borderWidth: inherit;
    --gc-input-border: inherit;
    --gc-input-color: inherit;
    --gc-input-bg: inherit;
    --gc-input-label: inherit;
    --gc-button-primary-color: inherit;
    --gc-button-primary-bg: inherit;
    --gc-button-primary-bgHover: inherit;
    --gc-button-primary-borderColor: inherit;
    --gc-button-primary-borderColorHover: inherit;
  }
`;
export default myStyle;
