import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { Option } from "../../../components/GetSelect/types";
import toast from "../../../components/Toast";
import { DELEAY_AMOUNTCHANGE } from "../../../Constant";
import { useTariff } from "../../../context";
import { useCalcParams } from "../../../context/CalculationParameter";
import { useCalcResult } from "../../../context/CalculationResult";
import { useConfig } from "../../../context/Config";
import { useGridConnectionStatus } from "../../../context/GridConnectionStatus";
import { SupplierInfo, useSupplierInfo } from "../../../context/SupplierInfo";
import useDebounce from "../../../hooks/useDebounce";
import ProxyInstance from "../../../services/proxy";
import resolveSuggestion from "../../../utils/resolveSuggestion";
import uniqueArrObj from "../../../utils/uniqueArrObj";
import { InputPageFormik } from "../types/InputPageFormik";
import { Ergebnis, SimplifierResponse, VersorgerInformation } from "../types/SimplifierResponse";
import handleError from "../utils/handleError";
import useFeatureInputPage from "./useFeatureInputPage";
import useQueryParameters from "./useQueryParameters";

const useSimplifier = () => {
  const [cityOptionsList, setCityOptionsList] = useState<Option[]>();
  const [streetOptionsList, setStreetOptionsList] = useState<Option[]>();
  const [houseNumberList, setHouseNumberList] = useState<Option[]>();
  const { setCalcParams } = useCalcParams();
  const { tariffNotFound } = useFeatureInputPage();
  const { values, setFieldValue } = useFormikContext<InputPageFormik>();
  const { setGridConnectionStatus } = useGridConnectionStatus();
  const { config } = useConfig();
  const tariff = useTariff();
  const queryParameter = useQueryParameters();
  const { setCalcResult } = useCalcResult();
  const { setSupplierInformation } = useSupplierInfo();
  const debounceHouseNumber = useDebounce(values?.houseNumber, values?.houseNumber?.length > 1 ? 900 : 300);
  const debounceOneTariff = useDebounce(values?.oneTariff, DELEAY_AMOUNTCHANGE);
  const debounceTwoTariff = useDebounce(values?.twoTariff, DELEAY_AMOUNTCHANGE);
  useEffect(() => {
    if (values.meterType === "1,2") {
      setFieldValue("twoTariff", "");
    }
  }, [setFieldValue, values.meterType]);

  const baseUrl = useMemo(() => {
    return tariff.id === "7"
      ? `/prima/calculation/telco/tr/v1/calculate?${queryParameter}`
      : `/simplifier/calc/${tariff.tariffType}/?${queryParameter}`;
  }, [queryParameter, tariff.id, tariff.tariffType]);

  return useQuery(
    [
      `GET_SIMPLIFIER_${tariff.id}`,
      values.meterType,
      values.zipCode,
      values.city,
      values.street,
      debounceHouseNumber,
      debounceOneTariff,
      debounceTwoTariff,
      values.customerType,
      tariff.tariffType,
      values.tariffType,
      values.stichtag,
      values.measurementType,
    ],
    async () => {
      try {
        const { data } = await ProxyInstance(baseUrl, {
          apikey: config.apikey || "",
        });

        if (data?.error) {
          throw new Error("Keine Ergebnisse vom Simplifire");
        }

        if (data?.data?.data?.gridConnectionStatus) {
          setGridConnectionStatus(data?.data?.data?.gridConnectionStatus);
        }

        if (data?.data?.data?.calculationParameter) {
          setCalcParams({
            ...data?.data?.data?.calculationParameter,
            dynamischeAngaben: data?.data?.data?.calculationParameter?.dynamischeAngaben ?? [
              { dateObj: [], doubleObj: [], intObj: [], stringObj: [] },
            ],
          });
        }
        const response: SimplifierResponse = data?.data?.data;
        const resolveSuggestLocation = resolveSuggestion(response.suggestLokation ?? undefined);

        const suggestions = response?.suggestLokation?.map((cityName: string) => ({
          value: cityName,
          label: cityName,
        }));

        const cityOptions =
          response.suggestLocationType === "CITY" && suggestions
            ? suggestions.length > 1
              ? uniqueArrObj([...resolveSuggestLocation, ...suggestions])
              : suggestions
            : null;
        const streetOptions = response.suggestLocationType === "STREET" ? suggestions : null;
        const houseNumberOptions =
          response.suggestLocationType === "HNR" && suggestions
            ? suggestions.sort((a: Option, b: Option) => a.label.localeCompare(b.label, "de", { numeric: true }))
            : null;

        if (response) {
          if (response.unique) {
            setCalcResult({
              results:
                tariff.id !== "7"
                  ? response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis
                  : response?.calculationTelcoResult?.products,
              type: tariff.id !== "7" ? "energie" : "telco",
              ergebnisseGesamtAnzahl:
                tariff.id !== "7"
                  ? response?.calculationResult?.ergebnisseGesamtAnzahl
                  : response?.calculationTelcoResult?.ergebnisseGesamtAnzahl,
              telcoGridConnection: response?.telcoGridConnection,
            });
            setSupplierInfo(response, setSupplierInformation);
          }
          if (cityOptions) {
            await setFieldValue("city", { value: "", label: "" });
            setCityOptionsList(cityOptions);

            return {
              zipCode: values.zipCode,
              cityOptions,
              unique: response.unique,
            };
          }

          if (streetOptions) {
            await setFieldValue("street", { value: "", label: "" });
            setStreetOptionsList(streetOptions);

            return {
              zipCode: values.zipCode,
              city: values.city?.value,
              cityOptions: cityOptionsList,
              streetOptions,
              unique: response.unique,
              networkOperator: data?.data?.data?.calculationParameter?.netzbetreiber,
              baseSupplier: data?.data?.data?.calculationParameter?.grundversorger,
            };
          }

          if (houseNumberOptions) {
            await setFieldValue("houseNumber", "");
            setHouseNumberList(houseNumberOptions);

            return {
              zipCode: values.zipCode,
              city: values.city?.value,
              cityOptions: cityOptionsList,
              street: values.street?.value,
              streetOptions: streetOptionsList,
              houseNumberOptions,
              unique: response.unique,
              networkOperator: data?.data?.data?.calculationParameter?.netzbetreiber,
              baseSupplier: data?.data?.data?.calculationParameter?.grundversorger,
            };
          }

          return {
            zipCode: values.zipCode,
            city: values.city?.value,
            cityOptions: cityOptionsList,
            street: values.street?.value,
            ...(values.street?.value ? { streetOptions: streetOptionsList } : { streetOptions: [] }),
            ...(values.houseNumber ? { houseNumber: values.houseNumber?.values } : {}),
            ...(values.houseNumber ? { houseNumberOptions: houseNumberList } : {}),
            unique: response.unique,
          };
        }
      } catch (e) {
        if (e instanceof Error) {
          e.message === "Error getting data" ? toast.error(tariffNotFound) : null;
          throw e;
        }
      }
    },
    {
      enabled: !!config.apikey && values.zipCode.length === 5,
      refetchOnWindowFocus: false,
      retryDelay: 5,
      onError: handleError,
    }
  );
};

const setSupplierInfo = (
  response: SimplifierResponse,
  setSupplierInformation: React.Dispatch<React.SetStateAction<SupplierInfo>>
) => {
  const vergleichsData = response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis.filter((supplier: Ergebnis) => {
    return supplier.isVergleichsTarif === true;
  });

  const supplierInfo = response?.calculationResult?.ergebnisContainer?.[0].versorgerInformation?.[0];
  const netzbetreibername = response?.calculationResult?.ergebnisContainer?.[0].versorgerInformation.filter(
    (f: VersorgerInformation) => {
      return f.marktrollen[0].verbandsnummer == response?.calculationParameter.netzbetreiber;
    }
  );

  if (supplierInfo && vergleichsData) {
    const suppliertariffName = vergleichsData[0]?.tarifName;

    const suppliertariffGesamtpreisBrutto = vergleichsData[0]?.gesamtpreisBrutto;

    const suppliertariffGrundpreisBrutto = vergleichsData[0]?.grundpreisBrutto;

    const suppliertariffGesamtpreisNetto = vergleichsData[0]?.gesamtpreisNetto;

    const suppliertariffGrundpreisNetto = vergleichsData[0]?.grundpreisNetto;
    const suppliertariffArbeitspreisHTCtKWhBrutto =
      vergleichsData[0]?.arbeitspreisHTCtKWhBrutto ?? vergleichsData[0].arbeitspreisCtKWhBrutto;

    const suppliertariffArbeitspreisNTCtKWhBrutto = vergleichsData[0]?.arbeitspreisNTCtKWhBrutto;

    const suppliertariffArbeitspreisHTCtKWhNetto =
      vergleichsData[0]?.arbeitspreisHTCtKWhNetto ?? vergleichsData[0].arbeitspreisCtKWhBrutto;

    const suppliertariffArbeitspreisNTCtKWhNetto = vergleichsData[0]?.arbeitspreisNTCtKWhNetto;

    const versorgerName = vergleichsData[0]?.versorgerName;
    const suppliertariff = vergleichsData[0];
    setSupplierInformation({
      ...supplierInfo,
      versorgerName,
      suppliertariffName,
      suppliertariffGesamtpreisBrutto,
      suppliertariffGesamtpreisNetto,
      suppliertariffGrundpreisBrutto,
      suppliertariffGrundpreisNetto,
      suppliertariffArbeitspreisHTCtKWhBrutto,
      suppliertariffArbeitspreisNTCtKWhBrutto,
      suppliertariffArbeitspreisHTCtKWhNetto,
      suppliertariffArbeitspreisNTCtKWhNetto,
      suppliertariff,
      netzbetreibername: netzbetreibername[0].versorgerName,
    });
  }
};

export default useSimplifier;
