import * as React from "react";

const House = ({ fill = "var(--get-c-breadcrumb)" }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="inline align-text-top"
    >
      <mask id="mask0_775_16183" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_775_16183)">
        <path
          d="M4.50033 12.6667H6.50033V8.66667H10.5003V12.6667H12.5003V6.66667L8.50033 3.66667L4.50033 6.66667V12.6667ZM3.16699 14V6L8.50033 2L13.8337 6V14H9.16699V10H7.83366V14H3.16699Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default House;
