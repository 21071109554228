import { useQuery } from "react-query";

import { useConfig } from "../../../context";
import ProxyInstance from "../../../services/proxy";
import handleConvertCity from "../utils/handleConvertCity";

const useQueryStreet = (
  value,
  depends
) => {
  const config = useConfig();

  return useQuery(
    `GET_STREET_FROM_${value.plz}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          "/ws/locationinfo/getStrassenByPlzOrt",
          {
            method: "POST",
            body: JSON.stringify({
              plz: value.plz,
              ort: handleConvertCity(value.ort),
            }),
            apikey: config.apikey || "",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        const {
          data: { strasse },
        } = data;

        return (
          strasse?.map((item) => ({ label: item.name, value: item.name })) || []
        );
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: Array.isArray(depends) && depends.length >= 1,
      refetchOnWindowFocus: false,
    }
  );
};

export default useQueryStreet;
