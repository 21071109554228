interface handleBankValidationProps {
  [key: string]: any
}

const handleBankValidation = (values: handleBankValidationProps, ibanError: boolean) => {
  if (values.paymentOptions === "bankTransfer") return true;

  return (
    !!values.firstNameAccountHolder &&
    !!values.lastNameAccountHolder &&
    !!values.iban &&
    !!values.bic &&
    !!values.bankName &&
    values.isAgreeToSEPA &&
    values.bic.length >= 8 &&
    values.bic.length <=11 &&
    !ibanError
  );
};

export default handleBankValidation;
