import * as React from "react";

import ErrorMessage from "../components/ErrorMessage";
import LoadingOverlay from "../components/LoadingOverlay";
import useAPIKey from "../hooks/useAPIKey";
import useFrontendConfig from "../hooks/useFrontendConfig";
import useKeycloak from "../hooks/useKeycloak";
import useStep from "../hooks/useStep";
import { getTariffType } from "../utils/getTariffType";
import { StepFormNameProvider } from "../widgets/TCContractConclusion/context/provider";
import CalculationParameter from "./CalculationParameter";
import CalculationResult from "./CalculationResult";
import TerminationFormInputDataProvider from "./CancellationData";
import ConfigContextProvider from "./Config";
import ExternLocationIdProvider from "./ExternLocationId";
import FormInputDataProvider from "./FormInputData";
import GridConnectionStatusProvider from "./GridConnectionStatus";
import { parameterContext, stepChangeContext, tariffIdContext, tariffOptionTypes } from "./index";
import { keycloakContext } from "./Keycloak";
import SupplierInfo from "./SupplierInfo";
import TariffProvider from "./Tariff";
import TelcoProvider from "./Telco";
import TelcoGridConnectionProvider from "./TelcoGridConnection";

interface RootProviderAttributes extends parameterContext {
  children: React.ReactNode;
  boxWidth: number;
  media: "tablet" | "desktop" | "mobile";
  tariffCalculatorTypeId: tariffOptionTypes;
  reference: React.MutableRefObject<HTMLElement | null>;
}
const checkTRTypeId = (id: tariffOptionTypes) => {
  switch (id) {
    case "2":
      return {
        id: id,
        tariftyp: "",
        medium: "gas",
      };

    case "7":
      return {
        id: id,
        tariftyp: "7",
        medium: "telco",
      };

    case "3":
      return {
        id: id,
        tariftyp: "3",
        medium: "heating",
      };

    case "4":
      return {
        id: id,
        tariftyp: "1",
        medium: "autostrom",
      };

    default:
      return {
        id: id,
        tariftyp: "1,2",
        medium: "electricity",
      };
  }
};

const RootProvider = ({
  children,
  boxWidth,
  media,
  tariffCalculatorTypeId,
  reference,
  ...props
}: RootProviderAttributes) => {
  const [tariff, setTariffId] = React.useState(checkTRTypeId(tariffCalculatorTypeId));
  const [step, setStep] = useStep(1);
  const { data: frontendConfig, isLoading } = useFrontendConfig();
  const { data: keycloakData } = useKeycloak();
  const { data: apikey, getAPIKey: getAPIKey, isLoading: isLoadingAPIKey } = useAPIKey();

  const tariffType = React.useMemo(() => getTariffType(tariff?.id), [tariff?.id]);

  if (isLoading || !tariff || isLoadingAPIKey) {
    return <LoadingOverlay />;
  }

  if (frontendConfig) {
    if (
      frontendConfig?.calculatorTypeIDs.length > 0 &&
      !frontendConfig?.calculatorTypeIDs.includes(`${tariffCalculatorTypeId}`)
    ) {
      // voreinstellte ID nicht configuriert, nimm die erste
      tariff.id = frontendConfig.calculatorTypeIDs[0];
    } else if (
      frontendConfig?.calculatorTypeIDs.length === 0 ||
      !frontendConfig?.calculatorTypeIDs.includes(`${tariffCalculatorTypeId}`)
    ) {
      return <ErrorMessage text={`Fehler: tariffCalculatorTypeId ${tariffCalculatorTypeId} ist nicht verfügbar!`} />;
    }
  }
  return (
    <ConfigContextProvider value={{ apikey, getAPIKey, frontendConfig, media, boxWidth, reference }}>
      <keycloakContext.Provider value={keycloakData}></keycloakContext.Provider>
      <FormInputDataProvider>
        <TerminationFormInputDataProvider>
          <ExternLocationIdProvider>
            <SupplierInfo>
              <GridConnectionStatusProvider>
                <CalculationParameter>
                  <TelcoGridConnectionProvider>
                    <CalculationResult>
                      <TariffProvider>
                        <TelcoProvider>
                          <tariffIdContext.Provider
                            value={{
                              id: tariff?.id,
                              tariffType,
                              tariftyp: tariff?.tariftyp,
                              medium: tariff?.medium,
                              setTariffId,
                            }}
                          >
                            <parameterContext.Provider
                              value={{
                                tariffCalculatorTypeId: tariff?.id,
                                ...props,
                              }}
                            >
                              <stepChangeContext.Provider value={{ step, setStep }}>
                                <StepFormNameProvider>{children}</StepFormNameProvider>
                              </stepChangeContext.Provider>
                            </parameterContext.Provider>
                          </tariffIdContext.Provider>
                        </TelcoProvider>
                      </TariffProvider>
                    </CalculationResult>
                  </TelcoGridConnectionProvider>
                </CalculationParameter>
              </GridConnectionStatusProvider>
            </SupplierInfo>
          </ExternLocationIdProvider>
        </TerminationFormInputDataProvider>
      </FormInputDataProvider>
    </ConfigContextProvider>
  );
};

export default RootProvider;
