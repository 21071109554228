import ProxyInstance from "../../../services/proxy";

const sendData = async (tariffId, apikey, authToken, body) =>
  await ProxyInstance(`/crm/sendData?calculatorType=${tariffId}`, {
    apikey: apikey || "",
    authToken,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });

export default sendData;
