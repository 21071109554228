import React, { useMemo } from "react";

import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { useTariff } from "../../../../context";
import { useTRInputContext } from "../../../../context/FormInputData";
import useFeaturesResult from "../../hooks/useFeaturesResult";

const TRInputData = () => {
  const inputContext = useTRInputContext();
  const tariff = useTariff();
  const { 
    unitUsageLabel, 
    headerSubLabel,
    townLabel,
    consumptionLabel,
    consumptionNTLabel,
    zipCodeLabel,
   } = useFeaturesResult();
  const isTelco = useMemo(() => tariff.id === 7, [tariff.id]);

  return (
    <div>
      <div className="text-lg mb-4">{headerSubLabel}</div>
      {!isTelco ? (
        <>
          <Desktop>
            <div className="flex justify-between flex-row items-center">
              <div>
                {inputContext?.values?.zipCode}{" "}
                {typeof inputContext.values?.city !== "string" && inputContext.values?.city?.value}
              </div>
              <div className="flex flex-col">
                <div>
                  {inputContext?.values?.oneTariff} {unitUsageLabel}
                </div>
                {inputContext?.values?.twoTariff !== "0" && (
                  <div>
                    {inputContext?.values?.twoTariff} {unitUsageLabel}
                  </div>
                )}
              </div>
            </div>
          </Desktop>
          <Tablet>
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div>{townLabel}</div>
                {inputContext?.values?.zipCode}{" "}
                {typeof inputContext.values?.city !== "string" && inputContext.values?.city?.value}
              </div>
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <div>{consumptionLabel}</div>
                  {inputContext?.values?.oneTariff} {unitUsageLabel}
                </div>
                {inputContext?.values?.twoTariff !== "0" && (
                  <div className="flex flex-col">
                    <div>{consumptionNTLabel}</div>
                    {inputContext?.values?.twoTariff} {unitUsageLabel}
                  </div>
                )}
              </div>
            </div>
          </Tablet>
          <Mobile>
            <div className="flex justify-between flex-col">
              <div className="flex flex-col">{zipCodeLabel} {inputContext?.values?.zipCode} </div>
              <div className="flex flex-col">
                <div>
                  {consumptionLabel} {inputContext?.values?.oneTariff} {unitUsageLabel}
                </div>
                {inputContext?.values?.twoTariff !== "0" && (
                  <div className="flex flex-col">
                    {consumptionNTLabel} {inputContext?.values?.twoTariff} {unitUsageLabel}
                  </div>
                )}
              </div>
            </div>
          </Mobile>
        </>
      ) : (
        <div className="flex basis-1/3 flex-col gap-2">
          <div className="flex w-1/2">
            {typeof inputContext.values?.street !== "string" && inputContext?.values?.street?.value}{" "}
            {typeof inputContext.values?.houseNumber !== "string" && inputContext?.values?.houseNumber?.value}
          </div>
          <div className="flex w-1/2">
            {inputContext?.values?.zipCode}{" "}
            {typeof inputContext.values?.city !== "string" && inputContext?.values?.city?.value}
          </div>
        </div>
      )}
    </div>
  );
};

export default TRInputData;
