import qs from "query-string";

const queryParamSimplifier = (values) => {
  const dynamischeAngaben = {
    doubleObj: [],
    intObj: [],
    stringObj: [],
    dateObj: [],
  };

  const allBruttoPricesAvailable =
    values.workingPriceBrutto &&
    values.basePriceBrutto &&
    (values.meterType === "1,2" || values.workingPriceNTBrutto);

  if (allBruttoPricesAvailable) {
    const workingPriceBrutto = {
      name: "vergleichAPBrutto",
      wert: Number(values.workingPriceBrutto),
    };

    const workingPriceNTBrutto = {
      name: "vergleichNTBrutto",
      wert: Number(values.workingPriceNTBrutto),
    };

    const basePriceBrutto = {
      name: "vergleichGrundpreisJahrBrutto",
      wert: Number(values.basePriceBrutto),
    };

    dynamischeAngaben.doubleObj.push(workingPriceBrutto);
    dynamischeAngaben.doubleObj.push(workingPriceNTBrutto);
    dynamischeAngaben.doubleObj.push(basePriceBrutto);
  }

  const allNettoPricesAvailable =
    values.workingPriceNetto &&
    values.basePriceNetto &&
    (values.meterType === "1,2" || values.workingPriceNTNetto);

  if (allNettoPricesAvailable) {
    const workingPriceNetto = {
      name: "vergleichAPNetto",
      wert: Number(values.workingPriceNetto),
    };

    const workingPriceNTNetto = {
      name: "vergleichNTNetto",
      wert: Number(values.workingPriceNetto),
    };

    const basePriceNetto = {
      name: "vergleichGrundpreisJahrNetto",
      wert: Number(values.basePriceNetto),
    };

    dynamischeAngaben.doubleObj.push(workingPriceNetto);
    dynamischeAngaben.doubleObj.push(workingPriceNTNetto);
    dynamischeAngaben.doubleObj.push(basePriceNetto);
  }
  const isGasTariff = values.calculatorType === 2;

  return qs.stringify({
    calculatorType: values.calculatorType,
    zipCode: values.plz || values.zipCode,
    zaehlerart: !isGasTariff ? values.meterType : undefined,
    customerType: values.customerType,
    ...(values.city ? { city: values.city.value } : {}),
    ...(values.street ? { street: values.street.value } : {}),
    ...(values.houseNumber
      ? {
          hnr:
            (typeof values.houseNumber === "string" && values.houseNumber) ||
            values.houseNumber.value,
        }
      : {}),
    ...(values.calculatorType === 7
      ? { houseNumberComplete: values.houseNumber.value }
      : {}),
    primeTimeConsumption:
      values.calculatorType === 7
        ? 20000
        : parseInt(values.oneTariff.replace(".", "")),
    ...(values.twoTariff !== "0"
      ? {
          secondaryTimeConsumption: parseInt(
            values.twoTariff?.replace(".", "") || "0"
          ),
        }
      : {}),
    // eco: 3,
    page: 1,
    gridConnectionType: 0,
    pageSize: 20,
    ...(values.bonus ? { bonus: values.bonus } : {}),
    ...(values.markenIDs ? { markenIDs: values.markenIDs } : {}),
    ...(values.tarifRootIDs ? { tarifRootIDs: values.tarifRootIDs } : {}),
    ...(values.tarifRootIDsMindestfilter
      ? { tarifRootIDsMindestfilter: values.tarifRootIDsMindestfilter }
      : {}),
    ...(values.zaehlerart ? { zaehlerart: values.zaehlerart } : {}),
    ...(values.baiextendedWhiteList
      ? { baiextendedWhiteList: values.baiextendedWhiteList }
      : {}),
    ...(values.stichtag ? { stichtag: values.stichtag } : {}),
    ...(values.tarifcode ? { tarifcode: values.tarifcode } : {}),
    ...(values.handlingTarife ? { handlingTarife: values.handlingTarife } : {}),
    ...(values.versorgerIDs ? { versorgerIDs: values.versorgerIDs } : {}),
    // @ts-ignore
    ...(values.tariffComparison?.tarifID
      ? {
          // @ts-ignore
          vergleichsTarifID: values.tariffComparison?.tarifID || "",
        }
      : {}),

    // strom && 1 tariff 1,2
    ...(values.meterType === "1,2" && values.calculatorType === 1
      ? { tariftyp: "1" }
      : {}),

    // strom && 2 tariff
    ...(values.meterType === "3,4" && values.calculatorType === 1
      ? { tariftyp: "1,2" }
      : {}),

    // autostrom && 2 tariff
    ...(values.meterType === "3,4" &&
    values.calculatorType === 4 &&
    values.tariftyp === "1"
      ? { tariftyp: "1,2" }
      : {}),

    // autostrom && 1 tariff
    ...(values.meterType === "1,2" &&
    values.calculatorType === 4 &&
    values.tariftyp === "1"
      ? { tariftyp: "1" }
      : {}),

    ...(values.calculatorType === 4 && values.tariftyp === "5"
      ? { tariftyp: "5" }
      : {}),
    ...(values.calculatorType === 3 ? { tariftyp: values.tariftyp } : {}),
    ...(values.calculatorType === 3 ? { vergleichsRichtlinie: 10 } : {}),
    ...(values.baseSupplier ? { grundversorger: values.baseSupplier } : {}),
    ...(values.networkOperator
      ? { netzbetreiber: values.networkOperator }
      : {}),
    dynamischeAngaben: JSON.stringify(dynamischeAngaben),
    primeTimeConsumptionNT: values.twoTariff?.replace(".", "") || 0,
  });
};

export default queryParamSimplifier;
