import { useField } from "formik";
import React from "react";

import ErrorMessage from "../ErrorMessage";

const Checkbox = ({ name, label, className, labelComponent, ...props }) => {
  const [fields, meta, { setValue }] = useField({ name, type: "checkbox" });

  const handleChange = React.useCallback(
    (e) => {
      if (props.value) {
        setValue(props.value);
      } else {
        setValue(e.target.checked);
      }
    },
    [props.value, setValue]
  );

  return (
    <div className={`${className || ""} w-fit`}>
      <label className="grid grid-cols-[1rem_auto] items-center">
        <div className="box-content border-solid border-button-primary-bg border-1 rounded w-4 h-4 inline-block ">
          <input
            {...fields}
            {...props}
            name={name}
            className={`${className || ""} cursor-pointer appearance-none`.trim()}
            onChange={handleChange}
            type="checkbox"
            checked={typeof fields.value === "boolean" ? fields.value : props.value === fields.value}
          />
          {fields.value && (
            <span className="absolute bg-button-primary-bg w-4 h-4">
              <svg
                className="size-3 m-[2px]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
          )}
        </div>

        <span className="ml-2 cursor-pointer grow">{(label && label) || (labelComponent && labelComponent)}</span>
      </label>
      {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
    </div>
  );
};

export default Checkbox;
