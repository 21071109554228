import { useField } from "formik";
import React from "react";

const Radio = ({ name, label, value, className, ...props }) => {
  const [field] = useField({ name });

  return (
    <label
      className={`${className || ""} flex items-center cursor-pointer`.trim()}
    >
      <input
        {...field}
        {...props}
        value={value}
        type="radio"
        className="cursor-pointer"
        checked={field.value === value}
      />
      <span className="ml-2">{label}</span>
    </label>
  );
};

export default Radio;
