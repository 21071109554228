import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const Modal = ({ children, showDialog, ariaLabel, header, ...props }) => {
  return (
    <AnimatePresence
      exitBeforeEnter
      initial={false}
      onExitComplete={() => null}
    >
      {showDialog && (
        <motion.div
          className="modalContainer"
          style={{
            position: "fixed",
            zIndex: 99,
            left: "0",
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            background: "rgba(61, 61, 61, 0.73)",
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <motion.div
            style={{
              zIndex: 100,
              background: "#fff",
              borderRadius: "6px",
              maxWidth: "984px",
              width: "100%",
              minHeight: "auto",
              margin: "10vh auto",
              minWidth: "320px",
              // overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            aria-label={ariaLabel}
            {...props}
          >
            <div className="w-full">
              {header ? (
                <div
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <div className="my-2 font-bold">{header}</div>
                </div>
              ) : null}
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
