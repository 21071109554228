import React from "react";

const Trashcan = ({ className }) => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className={className}>
      <g filter="url(#filter0_d_1330_17490)">
        <circle cx="15.7773" cy="11.7778" r="11" fill="#C94545"/>
        <path d="M14.45 6L13.875 6.575H11V7.725H11.575V16.35C11.575 16.6503 11.685 16.9564 11.9018 17.1732C12.1186 17.39 12.4247 17.5 12.725 17.5H18.475C18.7753 17.5 19.0814 17.39 19.2982 17.1732C19.515 16.9564 19.625 16.6503 19.625 16.35V7.725H20.2V6.575H17.325L16.75 6H14.45ZM12.725 7.725H18.475V16.35H12.725V7.725ZM13.875 8.875V15.2H15.025V8.875H13.875ZM16.175 8.875V15.2H17.325V8.875H16.175Z" fill="white"/>
      </g>
    </svg>
  );
};
export default Trashcan;