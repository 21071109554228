import React from "react";

import useExternalConfigs from "../../../hooks/useExternalConfigs";

const useFeatureInputPage = () => {
  const { data: config } = useExternalConfigs("inputpage");
  const inputPage = config?.data?.data?.inputPage;
  const resolveLabelInputPage = React.useCallback(
    (key: string) => {
      return inputPage?.[key];
    },
    [inputPage]
  );

  const defaultLabel = {
    autostrom: "Autostrom",
    business: "Gewerbe",
    cancellationFormButton: "zum Kündigungsformular",
    city: "Ort",
    consumption: "Verbrauch",
    customerType: "Kundenart",
    deadlineActivated: false,
    deadlineMaxDaysInFuture: 0,
    deadlineMinDaysInPast: 5,
    electricity: "Strom",
    gas: "Gas",
    gemeinsameMessung: "Gemeinsam",
    getrennteMessung: "Getrennt",
    header: "Angebotsrechner",
    heizstrom: "Heizstrom",
    houseNumber: "Hausnummer",
    isHtNt: true,
    labelHeaderPersonNumber: "Personen im Haushalt",
    measurementHeader: "Messung",
    oneTariff: "Eintarif",
    onlyEcoElectricity: "",
    onlyEcoGas: "",
    private: "Privat",
    search: "Produktsuche",
    selectTariff: "Zählerart",
    showCancelationForm: false,
    speicherheizung: "Speicherheizung",
    street: "Straße",
    tariffNotFound: "Zu den angegebenen Daten konnten keine Produkte gefunden werden.",
    tarifTyp: "TarifTyp",
    telco: "Internet",
    thg: "THG Prämie",
    tooltippVerbrauch: "",
    tooltippVerbrauchHT: "",
    tooltippVerbrauchNT: "",
    tooltippZaehlerart: "",
    twoTariffs: "Zweitarif",
    usage: "kWh/Jahr",
    usageRuler: false,
    usageRulerValues: null, // Gas: [13000, 20000, 27000] Strom: [1700, 2500, 3200, 4000]
    waermepumpe: "Wärmepumpe",
    zipCode: "Postleitzahl",
    zipCodeInvalidMessage: "Eingabe ungültig",
    zipCodeValidationText: "Eingabe ungültig",
    stichtag: "Stichtag",
  };

  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveLabelInputPage(key) || defaultValue])
  ) as Label;

  return { ...labels };
};

export default useFeatureInputPage;
