import { format } from "date-fns";

import { FormValues } from "../../../types/FormValues";
import Tariff from "../../../types/Tariff";
import convertDate from "../../../utils/convertDate";
import { uuid } from "./uuid";

export type HandleTariffFormsReturn = ReturnType<typeof handleTariffForms>;

const handleTariffForms = (
  val: Tariff &
    FormValues & {
      tariffId: string;
      usage: number;
      medium: number;
      authToken: string;
      externalOfferID?: string;
      idPerson?: string;
      abschlussstatus?: string;
    },
  opt: { bindingOffer: boolean }
) => {
  const isMovingIn = val.reasonToChange === "E01"; // umzug
  const isChangeSupplier = val.reasonToChange === "E03"; // versorger wechseln

  let terminwunsch = "";

  if (isChangeSupplier) {
    if (val.desiredDeliveryDate) {
      terminwunsch = convertDate(val.desiredDeliveryDate);
    } else {
      terminwunsch = "";
    }
  }

  // format date
  let cancellationDate = "";
  if (val.cancellationDate) {
    cancellationDate = convertDate(val.cancellationDate);
  }
  let temp_sepa_erteilt = val.paymentOptions === "directDebit" ? val.isAgreeToSEPA : "";
  if (val.isSepaRemoteSignature) temp_sepa_erteilt = false;
  if (val.isSepaRemoteSignature === false && val.sepa_digitalSignature) temp_sepa_erteilt = true;
  return {
    teilnehmerverzeichnis: "Nein",
    la_anrede: typeof val.salutation !== "string" ? val.salutation.value : val.salutation,
    la_email: val.email,
    ...(val.companyName !== "" ? { la_firma: val.companyName } : {}),
    la_geburtsdatum: val.birthday.split(".").reverse().join("-"),
    la_hausnummer: val.houseNumber,
    la_hausnummerzusatz: val.additionalAddress,
    la_nachname: val.lastName,
    la_ort: typeof val.city != "string" && val.city.value ? val?.city.value?.split("|")?.[0]?.trim() : val.city,
    la_plz: val.zipCode,
    la_titel: (typeof val.title != "string" && val?.title?.value) || undefined,
    la_strasse: typeof val.street !== "string" ? val.street.value : val.street,
    la_vorname: val.firstName,
    la_installationsort: val.installationLocation || "",
    la_telefonnummer: val.phoneNumber,
    la_mobilnummer: val.mobileNumber,
    la_faxnummer: val.faxNumber,
    la_sicherheitskennzahl: val.safetyIndex,
    ...(!val.deliveryIsSameBillingAddress
      ? {
          ra_anrede: (typeof val.billingSalutation != "string" && val?.billingSalutation?.value) || undefined,
          ra_titel: val.billingTitle?.value || undefined,
          ra_firma: val.billingCompanyName || undefined,
          ra_hausnummer: val.billingHouseNumber || undefined,
          ra_hausnummerzusatz: val.billingAdditionalAddress || undefined,
          ra_nachname: val.billingLastName || undefined,
          ra_ort: (typeof val.billingCity != "string" && val?.billingCity?.value) || undefined,
          ra_plz: val.billingZipCode || undefined,
          ra_strasse: (typeof val.billingStreet != "string" && val?.billingStreet?.value) || undefined,
          ra_vorname: val.billingFirstName || undefined,
        }
      : {}),
    abw_lieferungsadresse_anrede:
      (typeof val.installationSalutation !== "string" && val?.installationSalutation?.value) || undefined,
    abw_lieferungsadresse_hausnummer: val.installationHouseNumber || undefined,
    abw_lieferungsadresse_nachname: val.installationLastName || undefined,
    abw_lieferungsadresse_ort: val.installationCity || undefined,
    abw_lieferungsadresse_plz: val.installationZipCode || undefined,
    abw_lieferungsadresse_strasse: val.installationStreet || undefined,
    abw_lieferungsadresse_vorname: val.installationFirstName || undefined,
    sepa_erteilt: temp_sepa_erteilt,
    iban: val.paymentOptions === "directDebit" ? val.iban.replaceAll(" ", "") : "",
    bic: val.paymentOptions === "directDebit" ? val.bic : "",
    abwkonto_vorname: val.isDifferentAccountHolder ? val.firstNameAccountHolder : undefined,
    abwkonto_nachname: val.isDifferentAccountHolder ? val.lastNameAccountHolder : undefined,
    geldinstitut: val.paymentOptions === "directDebit" ? val.bankName : "",
    einzug_umzug: val.reasonToChange,
    ...(val?.externalOfferID ? { externalOfferID: val.externalOfferID } : {}),
    zaehlerstand: parseInt(val.actualCounterReading),
    zaehlerstandNT: parseInt(val.actualCounterNTReading),
    datum_einzug_umzug: isMovingIn ? val.moveInDate.split(".").reverse().join("-") : "",
    vorlieferant_kundennummer: val.customerNumber ?? "",
    zaehlernummer: val.counterNumber ? val.counterNumber : "",
    ablese_datum: val.readingDate ? val.readingDate.split(".").reverse().join("-") : "",
    maLoID: val.maLoID ? val.maLoID : null,
    agb_akzeptiert: val.isAGBCheck,
    kenntnisnahme_widerrufsrecht: val.isCancellationCheck,
    kenntnisnahme_datenschutz: val.isPrivacyPolicyCheck,
    kenntnisnahme_Kundenbelehrung: val.acceptCounsellingProtocoll || undefined,
    kundenPortalAnlegen: false,
    versandart_rechnung: val.invoiceDeliveryBy ?? "",
    // gutscheincode: val.couponCode || null,
    werber_identnummer: val.customerRecruitment || null,
    digitalSignature: val.signature || null,
    counselling_digitalSignature: val.signatureBerater || null,
    sepa_digitalSignature: val.sepa_digitalSignature || null,
    sepa_telesalesSignature: val.isSepaRemoteSignature || false,
    medium: val.medium,
    tariffCalculatorTypeId: val.tariffId.toString(),
    vorlieferant_verbandsnummer: val.prevSupplierName.key || null,
    vorlieferant_name: isChangeSupplier
      ? (typeof val.prevSupplierName !== "string" && val.prevSupplierName?.value) || ""
      : val?.prevSupplierName || "",
    bestelldatum: format(new Date(), "yyyy-MM-dd"),
    vertriebspartner_id: val.versorgerID,
    kuendigung_datum: cancellationDate || null,
    terminwunsch,
    kuendigung_vorversorger: isChangeSupplier ? val.hasCanceledPrevSupplier === "true" : null,
    get_vorgangs_id: uuid(),
    vertragsnummer: uuid(),
    id_person: val.idPerson,
    verbindlicher_abschluss: opt.bindingOffer,
    maloid: isChangeSupplier && val.maLoID && val.maLoID.length > 0 ? val.maLoID : null,
    rufnummeruebernahme: "new",
    zu_portierende_telefonnummer: null,
    einzelverbindungsnachweis: "new",
    attachments: val.attachment || [],
    paymentMethod: val.paymentOptions === "directDebit" ? "directDebit" : "bankTransfer",
    billingSameAsDelivery: val.deliveryIsSameBillingAddress,
    secondDeliverySameAsDelivery: true,
    authToken: val.authToken, // CIM Export
    ...(val.promotionalConsentByMail ? { werbeeinwilligung_email: val.promotionalConsentByMail } : {}),
    ...(val.promotionalConsentByPost ? { werbeeinwilligung_post: val.promotionalConsentByPost } : {}),
    ...(val.promotionalConsentByPhone ? { werbeeinwilligung_telefon: val.promotionalConsentByPhone } : {}),
    ...(val.promotionalConsentDigitalMessages
      ? { werbeeinwilligung_messages: val.promotionalConsentDigitalMessages }
      : {}),

    ...(val.customerSatisfactionSurveyByMail
      ? { kundenzufriedenheit_email: val.customerSatisfactionSurveyByMail }
      : {}),
    ...(val.customerSatisfactionSurveyByPost ? { kundenzufriedenheit_post: val.customerSatisfactionSurveyByPost } : {}),
    ...(val.customerSatisfactionSurveyByPhone
      ? { kundenzufriedenheit_telefon: val.customerSatisfactionSurveyByPhone }
      : {}),
    abschlussstatus: val.abschlussstatus ?? "",
  };
};

export default handleTariffForms;
