import { useMemo } from "react";

import { useConfig } from "../context";

const useMedia = () => {
  const config = useConfig();

  return useMemo(() => {
    if (config.media === "mobile") return "grid grid-cols-2 gap-4";
    if (config.media === "tablet") return "grid grid-cols-3 gap-4";

    return "grid grid-cols-4 gap-4";
  }, [config.media]);
};

export default useMedia;
