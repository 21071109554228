import { format } from "date-fns";
import { Formik, useField, useFormikContext } from "formik";
import React, {useState} from "react";

import Card from "../../../../../components/Card";
import EditIcon from "../../../../../components/icons/editIcon";
import Modal from '../../../../../components/Modal';
import Mobile from "../../../../../components/View/Mobile";
import { useConfig } from "../../../../../context";
import { useSelectTariff } from "../../../../../context/Tariff";
import { useSelectTelco } from "../../../../../context/Telco";
import Subsection from "../../../../TCConfirmation/subsection";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import EditDataModal from "../../EditDataModal";

const OrderConfirmation = () => {
  const { setValues, values } = useFormikContext();
  const { selectedTelco } = useSelectTelco();
  const { selectedTariff } = useSelectTariff();
  const {
    summaryTitleLabel,
    delieveryAddressLabel,
    anschlussdatenLabel,
    reasonLabel,
    reasonChangeSupplierLabel,
    reasonRelocationLabel,
    zaehlernummerLabel,
    startOfDeliveryLabel,
    nextPossibleDateTextLabel,
    bankTitleLabel,
    contactTitleLabel,
    titleLabel,
    phoneLabel,
    mobileLabel,
    ibanLabel,
    creditInstitutionLabel,
  } = useFeaturesCC();
  const config = useConfig();
  const [modalVisible, setModalVisible] = useState(false)

  const [{ value: deliveryIsSameBillingAddress }] = useField("deliveryIsSameBillingAddress");

  const itemizedBill = React.useMemo(() => {
    switch (values.itemizedBill) {
      case "true":
        return "Ja, ich wünsche einen EVN gekürzt um 3 Stellen";
      case "false":
        return "Nein, ich wünsche keinen EVN";
      case "full":
        return "Ja, ich wünsche einen EVN ungekürzt";
      default:
        return "";
    }
  }, [values.itemizedBill]);

  const reasonToChange = React.useMemo(() => {
    switch (values.reasonToChange) {
      case "E03":
        return reasonChangeSupplierLabel;
      case "tarifwechsel":
        return "Tarifwechsel";
      case "E01":
        return reasonRelocationLabel;
      default:
        return "";
    }
  }, [values.reasonToChange, reasonChangeSupplierLabel, reasonRelocationLabel]);
  const setOuterFormikValues= (values) => {
    setValues(values);
  }
  return (
    <Card className="rounded-xl">
      <Modal showDialog={modalVisible} ariaLabel="Daten anpassen">
        <Formik 
          initialValues = {values}
          component={()=>(
            <EditDataModal modalVisible={modalVisible} setModalVisible={setModalVisible} />)
          }
          onSubmit={async(values)=>{
            setOuterFormikValues(values);         
          }
          }
        />
      </Modal>
      <Mobile>
        <div className="flex justify-between">
          <div className="text-lg font-bold">{summaryTitleLabel}</div>
          <div>{format(new Date(), "dd.MM.yyyy")}</div>
        </div>
        <section className="mt-4 grid grid-cols-1">
          <section className="text-sm">
            <div className="mb-2">{delieveryAddressLabel}</div>

            <div className="font-bold text-sm my-2">Addressdaten<span onClick={()=>setModalVisible("deliveryAddress")} className="float-right"><EditIcon /></span></div>
            <div>{values.companyName}</div>
            <div>
              {typeof values.salutation !== "string" && values.salutation?.value}{" "}
              {typeof values.title !== "string" && values.title?.value} {values.firstName} {values.lastName}
            </div>
            <div>
              {typeof values.street === "string" ? values.street : values.street?.value} {values.houseNumber} {values?.additionalAddress}
              {values.additionalAddress}
            </div>
            <div>
              {values.zipCode} {values.city}
            </div>

            <hr />
            <div className="font-bold mt-4 mb-2">Persönliche Daten<span onClick={()=>setModalVisible("personalData")} className="float-right"><EditIcon /></span></div>
            {values.birthday && <div>Geburtsdatum: {values.birthday}</div>}
            {values.email && <div>E-Mail: {values.email}</div>}
            {values.mobileNumber && <div>Mobilfunknummer: {values.mobileNumber}</div>}
            {values.phoneNumber && <div>Telefonnummer: {values.phoneNumber}</div>}
          </section>

          <section className="text-sm">
            <hr />

            <div className="font-bold mb-2 mt-6">{anschlussdatenLabel}<span onClick={()=>setModalVisible("preSupplierData")} className="float-right"><EditIcon /></span></div>

            {selectedTariff && (
              <>
                {values.reasonToChange && <Subsection text={reasonLabel} value={reasonToChange} />}

                {values.reasonToChange === "E01" && <Subsection text="Einzugsdatum" value={values.moveInDate} />}

                {values.reasonToChange === "E03" && (
                  <>
                    {values.hasDesiredDeliveryDate && (
                      <Subsection
                        text="Gewünschter Lieferbeginn"
                        value={
                          values.hasDesiredDeliveryDate === "false"
                            ? "zum nächstmöglicher Termin"
                            : values.desiredDeliveryDate
                        }
                      />
                    )}

                    {typeof values.prevSupplierName !== "string" && (
                      <Subsection text="bisheriger Anbieter" value={values.prevSupplierName?.value} />
                    )}

                    {values.customerNumber && <Subsection text="Kundennummer" value={values.customerNumber} />}
                    {values.counterNumber && <Subsection text={zaehlernummerLabel} value={values.counterNumber} />}
                    {values.hasCanceledPrevSupplier === "true" && <div className="mb-2">bereits gekündigt</div>}
                    {values.cancellationDate && <Subsection text="Kündigungsdatum" value={values.cancellationDate} />}
                    {values.actualCounterReading && (
                      <Subsection text="Zählerstand" value={values.actualCounterReading} />
                    )}
                    {values.maLoID && <Subsection text="Marktlokation" value={values.maLoID} />}
                  </>
                )}
              </>
            )}

            {selectedTelco && (
              <>
                <Subsection
                  text={startOfDeliveryLabel}
                  value={
                    values.hasDesiredDeliveryDate === "true" ? values.desiredDeliveryDate : nextPossibleDateTextLabel
                  }
                />
                <Subsection
                  text="Rufnummer(n)"
                  value={
                    values.telephoneInstallationRequest === "false"
                      ? "Bestehende Rufnummer(n)"
                      : "neue Rufnummer (Sie erhalten die von Ihnen gewünschte Anzahl an Rufnummern)"
                  }
                />
                <div className="mb-2">
                  {values.telephoneInstallationRequest === "false" && (
                    <>
                      <div className="mb-2">
                        {values.currentInstalledTelephoneNumber.split(",").map((tel) => {
                          return (
                            <ul key={tel}>
                              <li>{tel}</li>
                            </ul>
                          );
                        })}
                      </div>
                      <div className="mb-2">
                        bisheriger Anbieter:{" "}
                        {typeof values.prevSupplierName !== "string" && values.prevSupplierName?.value}
                      </div>
                      <Subsection
                        text="Anschlussinhaber"
                        value={`${values.previousSubscriber_company} ${values.previousSubscriber_firstname} ${values.previousSubscriber_lastname}`}
                      />
                    </>
                  )}
                </div>
                <Subsection text="Einzelverbindungsnachweis" value={itemizedBill} />
                <Subsection
                  text="Eintrag Teilnehmerverzeichnis"
                  value={
                    values.joinSubscribeList === "false"
                      ? "Nein, ich wünsche keinen Eintrag"
                      : "Ja, ich wünsche einen Eintrag ins Teilnehmerverzeichnis"
                  }
                />
                {values.inhaberFirstName && values.inhaberLastName && (
                  <Subsection text="Vormieter" value={`${values.inhaberFirstName} ${values.inhaberLastName}`} />
                )}
              </>
            )}

            <hr />

            <div className="font-bold mb-2 mt-6">{bankTitleLabel}<span onClick={()=>setModalVisible("bankData")} className="float-right"><EditIcon /></span></div>

            <div className="mb-2">
              {values.paymentOptions === "directDebit" ? "Bankeinzug (SEPA-Lastschrift)" : "Überweisung"}
            </div>
            {values.firstNameAccountHolder && values.lastNameAccountHolder && (
              <Subsection
                text="Kontoinhaber"
                value={`${values.firstNameAccountHolder} ${values.lastNameAccountHolder}`}
              />
            )}
            {values.iban && <Subsection text="IBAN" value={values.iban} />}
            {values.bic && <Subsection text="BIC" value={values.bic} />}
            {values.bankName && <Subsection text="Kreditinstitut" value={values.bankName} />}
            <Subsection text="SEPA-Lastschriftmandat erteilt" value={values.isAgreeToSEPA === true ? "Ja" : "Nein"} />
          </section>
        </section>
      </Mobile>

      {config.media !== "mobile" && (
        <>
          <div className="flex justify-between">
            <div className="text-lg font-bold">{summaryTitleLabel}</div>
            <div>Datum: {format(new Date(), "dd.MM.yyyy")}</div>
          </div>
          <section className="mt-8 grid grid-cols-1 gap-5">
            <div className="font-bold">{contactTitleLabel}<span onClick={()=>setModalVisible("personalData")} className="float-right"><EditIcon /></span></div>
            <table className="w-full">
              <thead className="text-left">
                <tr>
                  <th>Anrede</th>
                  <th>{titleLabel}</th>
                  <th>Vorname</th>
                  <th>Nachname</th>
                  <th>Geburtsdatum</th>
                  <th>{phoneLabel}</th>
                  <th>{mobileLabel}</th>
                  <th>E-Mail-Adresse</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{typeof values.salutation !== "string" && values.salutation?.value}</td>
                  <td>{typeof values.title !== "string" && values.title?.value}</td>
                  <td>{values.firstName}</td>
                  <td>{values.lastName}</td>
                  <td>{values.birthday}</td>
                  <td>{values.phoneNumber}</td>
                  <td>{values.mobileNumber}</td>
                  <td>{values.email}</td>
                </tr>
              </tbody>
            </table>

            <hr className="w-full" />

            <div className="font-bold">{delieveryAddressLabel}<span onClick={()=>setModalVisible("deliveryAddress")} className="float-right"><EditIcon /></span></div>
            <table className="w-full">
              <thead className="text-left">
                <tr>
                  <th>Straße</th>
                  <th>Ort</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{typeof values.street === "string" ? values.street : values.street?.value} {values.houseNumber} {values?.additionalAddress}</td>
                  <td>
                    {values.zipCode} {values.city}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr className="w-full" />
            {deliveryIsSameBillingAddress===false && (
              <>
                <div className="font-bold">Rechnungsadresse<span onClick={()=>setModalVisible("billingAddress")} className="float-right"><EditIcon /></span></div>
                <table className="w-full">
                  <thead className="text-left">
                    <tr>
                      <th>Straße</th>
                      <th>Ort</th>
                      <th>Vorname</th>
                      <th>Nachname</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                      { typeof values.billingStreet !== "string" && values.billingStreet?.value} { values.billingHouseNumber} {values?.billingAdditionalAddress}
                      </td>
                      <td>
                        {values.billingCity?.value ? values.billingZipCode + " " + values.billingCity?.value : ""}
                      </td>
                      <td>{values.billingFirstName}</td>
                      <td>{values.billingLastName}</td>
                    </tr>
                  </tbody>
                </table>
                <hr className="w-full" />
              </>
            )}

            <div className="font-bold mb-2">{anschlussdatenLabel}<span onClick={()=>setModalVisible("preSupplierData")} className="float-right"><EditIcon /></span></div>
            <table className="w-full">
              <thead className="text-left">
                <tr>
                  {values.reasonToChange && <th> {reasonLabel}</th>}
                  {values.counterNumber && <th>{zaehlernummerLabel}</th>}
                  {values.reasonToChange === "E01" && <th>Einzugsdatum</th>}
                  {values.reasonToChange === "E03" && (
                    <>
                      {values.prevSupplierName && <th>Bisheriger Anbieter</th>}
                      {values.hasCanceledPrevSupplier && <th>Kündigung</th>}
                      {values.cancellationDate && <th>Kündigungsdatum</th>}
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {values.reasonToChange && <td> {reasonToChange}</td>}
                  {values.counterNumber && <td>{values.counterNumber}</td>}
                  {values.reasonToChange === "E01" && <td>{values.moveInDate}</td>}
                  {values.reasonToChange === "E03" && (
                    <>
                      {typeof values.prevSupplierName !== "string" && <td>{values.prevSupplierName?.value}</td>}

                      {values.hasCanceledPrevSupplier && <td>Selbst gekündigt</td>}
                      {values.cancellationDate && <td>{values.cancellationDate}</td>}
                    </>
                  )}
                </tr>
              </tbody>
            </table>
            <hr className="w-full" />

            {selectedTelco && (
              <>
                <Subsection
                  text={startOfDeliveryLabel}
                  value={
                    values.hasDesiredDeliveryDate === "true" ? values.desiredDeliveryDate : nextPossibleDateTextLabel
                  }
                />
                <Subsection
                  text="Rufnummer(n)"
                  value={
                    values.telephoneInstallationRequest === "false"
                      ? "Bestehende Rufnummer(n)"
                      : "neue Rufnummer (Sie erhalten die von Ihnen gewünschte Anzahl an Rufnummern)"
                  }
                />
                <div className="mb-2">
                  {values.telephoneInstallationRequest === "false" && (
                    <>
                      <div className="mb-2">
                        {values.currentInstalledTelephoneNumber.split(",").map((tel) => {
                          return (
                            <ul key={tel}>
                              <li>{tel}</li>
                            </ul>
                          );
                        })}
                      </div>
                      <div className="mb-2">
                        bisheriger Anbieter:{" "}
                        {typeof values.prevSupplierName !== "string" && values.prevSupplierName?.value}
                      </div>
                      <Subsection
                        text="Anschlussinhaber"
                        value={`${values.previousSubscriber_company} ${values.previousSubscriber_firstname} ${values.previousSubscriber_lastname}`}
                      />
                    </>
                  )}
                </div>
                <Subsection text="Einzelverbindungsnachweis" value={itemizedBill} />
                <Subsection
                  text="Eintrag Teilnehmerverzeichnis"
                  value={
                    values.joinSubscribeList === "false"
                      ? "Nein, ich wünsche keinen Eintrag"
                      : "Ja, ich wünsche einen Eintrag ins Teilnehmerverzeichnis"
                  }
                />
                {values.inhaberFirstName && values.inhaberLastName && (
                  <Subsection text="Vormieter" value={`${values.inhaberFirstName} ${values.inhaberLastName}`} />
                )}
              </>
            )}
            <div className="font-bold">{bankTitleLabel}<span onClick={()=>setModalVisible("bankData")} className="float-right"><EditIcon /></span></div>
            <table className="w-full">
              <thead className="text-left">
                <tr>
                  <th>Zahlungsart</th>
                  <th>Kontoinhaber</th>
                  <th>{values.iban && ibanLabel}</th>
                  <th>{values.bankName && creditInstitutionLabel}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{values.paymentOptions === "directDebit" ? "Bankeinzug (SEPA-Lastschrift)" : "Überweisung"}</td>
                  <td>{`${values.firstNameAccountHolder} ${values.lastNameAccountHolder}`}</td>
                  <td>{values.iban && values.iban}</td>
                  <td>{values.bankName && values.bankName}</td>
                </tr>
              </tbody>
            </table>
          </section>
        </>
      )}
    </Card>
  );
};

export default OrderConfirmation;
