import React from "react";

import Button from "../../components/Button";
import Card from "../../components/Card";
import { useStepChange } from "../../context";

const SuccessForm = ({ data }: { data?: { pdfUrl: string } }) => {
  const { setStep } = useStepChange();

  const handleOpenPdf = React.useCallback(() => {
    if (typeof window !== "undefined") {
      window.open(data?.pdfUrl, "_blank");
    }
  }, [data?.pdfUrl]);

  return (
    <Card>
      <div className="text-xl text-center">Vielen Dank!</div>
      <div className="text-lg text-center my-6">
        Ihre Kündigungsanfrage wurde erfolgreich an Ihren Versorger übermittelt.
        Dieser wird sich umgehend mit Ihnen in Verbindung setzen!
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          className="mt-4"
          variant="primary"
          type="submit"
          onClick={() => setStep(5)}
        >
          Zurück
        </Button>
        <Button className="mt-4" variant="primary" onClick={handleOpenPdf}>
          Download
        </Button>
      </div>
    </Card>
  );
};

export default SuccessForm;
