import React from "react";

import { useStepChange } from "../../context";
import { useContractConclusionFormName } from "../../widgets/TCContractConclusion/context";
import  CheckMark from "../icons/checkmark"



const ActiveStep = ({children}) => {
  return (
    <li className="relative">
      <span className="flex items-center justify-center size-6 bg-button-primary-bg rounded-full dark:bg-gray-700 shrink-0" />
      {children}
    </li>
  );
};

const FinishedStep = ({handleClick, children}) => {
  return (
    <li onClick={handleClick} className="relative cursor-pointer">
      <span className="flex items-center justify-center size-6 bg-button-primary-bg rounded-full dark:bg-gray-700 shrink-0">
        <CheckMark />
      </span>
      {children}
    </li>
  );
};

const UnfinishedStep = ({children}) => {
  return(
    <li className="relative">
      <span className="flex items-center justify-center size-6 bg-button-primary-bg-light rounded-full dark:bg-gray-700 shrink-0" />
      {children}
    </li>
  )
};
const Step = ({step, stepID, text, last=false, formName=null}) => {
  const { setFormName } = useContractConclusionFormName();

  const { setStep } = useStepChange();
  const handleClick = () =>{
    if(formName){
      setFormName(formName);
    } else {
      setStep(stepID);
      setFormName('contactForm');
    }

  }
  const textfield = <div className="absolute left-1/2 -translate-x-1/2 transform text-center text-[10px]">{text}</div>
  if(step<stepID){
    return(
      <>
        <UnfinishedStep>{textfield}</UnfinishedStep>
        {!last?<Connector />:""}
      </>

    )
  }
  if(step===stepID){
    return(
      <>
        <ActiveStep>{textfield}</ActiveStep>
        {!last?<Connector />:""}
      </>
    )
  } 
  if(step>stepID){
    return(
      <>
        <FinishedStep handleClick={handleClick}>{textfield}</FinishedStep>
        {!last?<Connector activated={true} />:""}
      </>
    )
  }
}

const Connector = ({activated=false}) => {
  return(
    <div className={`block w-full h-1 ${activated?"bg-button-primary-bg":"bg-button-primary-bg-light"}`} />
  )
}

const Breadcrumb = ({step}) => {
  const { formName } = useContractConclusionFormName();
  let formNameAddition;
  switch(formName){
    case 'addressForm': formNameAddition=1;
      break;
    case 'preSupplierForm': formNameAddition=2;
      break;
    case 'bankForm': formNameAddition=3;
      break;
    case 'summaryForm': formNameAddition=4;
      break;
    default: formNameAddition=0;
      break;
  }

  return (
    <ol className="flex items-center w-full p-0 max-w-screen-sm mx-auto mb-24 mt-12">
      <Step step={step+formNameAddition} stepID={1} text="Suche"/>
      <Step step={step+formNameAddition} stepID={2} text="Ergebnisse"/>
      <Step step={step+formNameAddition} stepID={3} formName="contactForm" text="Personendaten"/>
      <Step step={step+formNameAddition} stepID={4} formName="addressForm" text="Adressdaten"/>
      <Step step={step+formNameAddition} stepID={5} formName="preSupplierForm" text="Anschlussdaten"/>
      <Step step={step+formNameAddition} stepID={6} formName="bankForm" text="Zahlungdaten"/>
      <Step step={step+formNameAddition} stepID={7} formName="summaryForm" text="Auftragsbestätigung" last={true}/>
    </ol>
  );
};

export default Breadcrumb;
