import { useField, useFormikContext } from "formik";
import { useQuery } from "react-query";

import { useConfig } from "../../../context/Config";
import ProxyInstance from "../../../services/proxy";

export const useValidateMaLo = () => {
  const { config } = useConfig();
  const [{ value: maLoID }] = useField("maLoID");
  const { setFieldValue } = useFormikContext();

  return useQuery(`VALIDATE_MALO_${maLoID}`, async () => {
      try {
        const { data } = await ProxyInstance(`/malo/validate?maloid=${maLoID}`, {
          apikey: config.apikey || "",
        });

        const {
          data: {
            data: { result },
          },
        } = data;

  /*     if (!result.isValid) {
          throw new Error();
        }
*/
        return result;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!maLoID && maLoID.length === 11,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err instanceof Error) {
          setFieldValue("maloIDError", false);
        }
      },
      onSuccess: () => {
        setFieldValue("maloIDError", true);
      },
    }
  );
};

export const useValidateMaLoService = (maLoID:string) => {
  const { config } = useConfig();

  const { setFieldValue } = useFormikContext();

  return useQuery(`VALIDATE_MALO_${maLoID}`, async () => {
        try {
          const { data } = await ProxyInstance(`/malo/validate?maloid=${maLoID}`, {
            apikey: config.apikey || "",
          });

          const {
            data: {
              data: { result },
            },
          } = data;

          /*     if (!result.isValid) {
                  throw new Error();
                }
        */
          setFieldValue("maloIDError",result?.isValid)
          return result;
        } catch (e) {
          console.error(e);
          throw e;
        }
      },{
        enabled: !!maLoID && maLoID.length === 11,
        refetchOnWindowFocus: false,
        retry: false

      }
  );
};


