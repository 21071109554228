import { Formik } from "formik";
import React from "react";

import LoadingOverlay from "../../components/LoadingOverlay";
import { useParameter, useStepChange, useTariff } from "../../context";
import { useCalcParams } from "../../context/CalculationParameter";
import { useConfig } from "../../context/Config";
import { useSelectTariff } from "../../context/Tariff";
import { Telco, useSelectTelco } from "../../context/Telco";
import useTarifCart from "../../hooks/useTarifCart";
import useTarifCartGas from "../../hooks/useTarifCartGas";
import { FormValues } from "../../types/FormValues";
import SelectedTelcoTariff from "../TCResults/components/TelcoResults/SelectedTariff";
import ContractConclusionForm from "./components/ContractConclusionForm";
import SelectedTariff from "./components/SelectedTariff";
import {
  addressDataInitialValues,
  addressDataInstallationInitialValues,
  bankDataInitialValues,
  contactDataInitialValues,
  geeDataInitialValues,
  preSupplierDataInitialValues,
  summaryInitialValues,
} from "./Constant";
import EditDataModalProvider from "./context/EditDataModalContext";
import WarningDialogContextProvider from "./context/WarningDialogContext";
import useFeaturesCC from "./hooks/useFeaturesCC";
import handleTariffForms, { HandleTariffFormsReturn } from "./utils/handleTariffForms";
import handleTelcoForms, { HandleTelcoFormsReturn } from "./utils/handleTelcoForms";
import { useValidation } from "./validation/validation";

const ContractConclusion = ({
  submit,
}: {
  submit: ({
    formikValues,
    formattedData,
  }: {
    formikValues: FormValues;
    formattedData: HandleTariffFormsReturn | HandleTelcoFormsReturn;
  }) => void;
}) => {
  const tariff = useTariff();
  const [startTime, setStartTime] = React.useState<number>();
  const [cardMinHeight, setCardMinHeight] = React.useState<number>(0);
  const { calcParams } = useCalcParams();
  const { selectedTariff } = useSelectTariff();
  const { refetch } = useTarifCart(selectedTariff.tarifRootID, selectedTariff.variantenID, true);
  const { refetch: refetchGas } = useTarifCartGas(selectedTariff.tarifRootID, selectedTariff.variantenID, true);
  const tariffCardRef = React.useRef<HTMLDivElement>(null);
  const { selectedTelco } = useSelectTelco();
  const { setStep } = useStepChange();
  const { vppCustomerId, vppSummaryUrl, authToken, externalOfferID } = useParameter();
  const {
    moveInDateDaysInPast,
    moveInDateMonthsInFuture,
    requestedDesiredDateDaysInAdvanceEnergy,
    requestedDesiredDateDaysMax,
    switchAccountLandHolder,
  } = useFeaturesCC();
  const { config } = useConfig();
  React.useEffect(() => {
    setStartTime(Date.now());
  }, []);
  React.useEffect(() => {
    if (cardMinHeight === 0 && tariffCardRef?.current?.offsetHeight && tariffCardRef.current?.offsetHeight !== 0) {
      setCardMinHeight(tariffCardRef.current?.offsetHeight);
    }
  }, [tariffCardRef.current?.offsetHeight]);
  React.useEffect(() => {
    if (tariff.id !== "2") {
      refetch();
    } else {
      refetchGas();
    }
  }, []);

  const initialValues = React.useMemo(() => {
    return {
      ...contactDataInitialValues,
      ...addressDataInitialValues,
      ...geeDataInitialValues,
      ...addressDataInstallationInitialValues,
      ...preSupplierDataInitialValues,
      ...bankDataInitialValues,
      ...summaryInitialValues,
      showDetails: false,
      setShowSelectedTariff: false,
      asOffer: false,
      IBANError: true,
      offermodePosition: null,
      actualCounterNTReading: "",
      installationLocation: "",
      pdfUrl: "",
    };
  }, []);

  return (
    <Formik
      validationSchema={useValidation(
        moveInDateDaysInPast,
        moveInDateMonthsInFuture,

        requestedDesiredDateDaysInAdvanceEnergy,
        requestedDesiredDateDaysMax,
        switchAccountLandHolder
      )}
      component={({ isSubmitting }) => (
        <WarningDialogContextProvider>
          <EditDataModalProvider>
            {isSubmitting && <LoadingOverlay progressBarEnabled={true} startTime={startTime} />}
            {!isSubmitting && (
              <section
                className={
                  config.media === "desktop"
                    ? "grid gap-y-2 gap-x-4 grid-cols-3 relative"
                    : "grid grid-cols-1 gap-y-2 gap-x-4"
                }
              >
                <div className={`${config.media === "desktop" ? "col-span-2 " : ""}`}>
                  <ContractConclusionForm cardMinHeight={cardMinHeight} />
                </div>
                <div>
                  <div className="sticky top-4" ref={tariffCardRef}>
                    {selectedTariff && tariff.id !== "7" && <SelectedTariff />}
                    {selectedTelco && tariff.id === "7" && (
                      <SelectedTelcoTariff
                        options={selectedTelco.selectedOption || []}
                        hardwares={selectedTelco.selectedHardware || []}
                        product={selectedTelco || ({} as Telco)}
                      />
                    )}
                  </div>
                </div>
              </section>
            )}
          </EditDataModalProvider>
        </WarningDialogContextProvider>
      )}
      initialValues={initialValues}
      onSubmit={async (formikValues, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (selectedTariff && formikValues) {
          const formattedData = handleTariffForms(
            {
              ...selectedTariff,
              ...formikValues,
              tariffId: tariff.id || "1",
              usage: calcParams.verbrauchHT,
              medium: tariff.medium === "gas" ? 1 : 0,
              authToken,
              ...(externalOfferID ? { externalOfferID: externalOfferID } : {}),
              ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
            },
            { bindingOffer: true }
          );

          try {
            await submit({ formikValues, formattedData });
            if (!vppSummaryUrl) {
              setStep(4);
            }
          } catch (e) {
            console.error(e);

            throw e;
          }
        }

        if (selectedTelco && tariff.id === "7") {
          const formattedData = handleTelcoForms(
            {
              ...formikValues,
              ...selectedTelco,
              tariffId: tariff.id || "1",
              verbandsnummer: "",
              objectId: selectedTelco.objectId || "",
              authToken,
              ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
            },
            { bindingOffer: true }
          );

          try {
            await submit({ formikValues, formattedData });
            setStep(4);
          } catch (e) {
            console.error(e);
            throw e;
          }
        }

        setSubmitting(false);
        resetForm();
      }}
    />
  );
};

export default ContractConclusion;
