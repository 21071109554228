import { useQuery } from "react-query";

import toast from "../components/Toast";
import { tariffOptionTypes } from "../context";
import ConfigInstance from "../services/config";

const QUERY_KEY = "GET_FRONTEND_KEY";
export interface FeConfig {
  calculatorTypeIDs: tariffOptionTypes[];
  processmapping: { calculatorTypeID: tariffOptionTypes; mapping?: { status_extern: number; pid_intern: number }[] }[];
}

const useFrontendConfig = () => {
  return useQuery(
    QUERY_KEY,
    async () => {
      try {
        const {
          data,
        }: {
          data: {
            data: FeConfig;
          };
        } = await ConfigInstance("/config/feconfig");

        return data.data;
      } catch (e) {
        toast.error("Config: Konfigurationsdatei fehlerhaft");
      }
    },
    { refetchOnWindowFocus: false }
  );
};

export default useFrontendConfig;
