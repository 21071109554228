import React from "react";

const Caret = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21.25 17.5L15 11.25L8.75 17.5H21.25Z" fill="black" />
    </svg>
  );
};

export default Caret;
