import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --gc-c-primary-normal: #f0f;
    --gc-c-primary-hover: green;
    --gc-c-text-normal: blue;
    --gc-v-rounded-button: 0.5em;
  }
`;
export default myStyle;
