import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Divider from "../../../../components/Divider";
import GetSelect from "../../../../components/GetSelect";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useConfig } from "../../../../context/Config";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import useVariantSupplier from "../../hooks/useVariantSupplier";
import { TariffComparisonModalFormik } from "../../types/TariffComparisonModalFormik";
import SelfCompareTariff from "../SelfComparedInput";

const TariffComparison = ({
  setModalOpen,
  timeframeYear,
  isBrutto,
  submitForm,
}: {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  timeframeYear: boolean;
  isBrutto: boolean;
  submitForm: (e?: React.FormEvent<HTMLFormElement>) => void;
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [width, setWidth] = React.useState(ref?.current?.offsetWidth ?? 400);
  const { config } = useConfig();
  const { values } = useFormikContext<TariffComparisonModalFormik>();
  const { calcParams } = useCalcParams();
  const { TCS_Btn, pricesInclTax, pricesExclTax, compare, individual, supplier, tariff } = useFeaturesResult();
  const { data } = useVariantSupplier(calcParams);

  React.useEffect(() => {
    if (ref?.current) {
      setWidth(ref?.current?.offsetWidth);
    }
  }, [ref?.current?.offsetWidth]);

  const handleChangeNewTariff = React.useCallback(async () => {
    await submitForm();
  }, [submitForm]);
  const tarife =
    data
      ?.find((el) =>
        typeof values.supplier !== "string"
          ? el.versorgerName === values.supplier?.value
          : el.versorgerName === values.supplier
      )
      ?.tarife?.map((el) => {
        return { label: el.tarifName, value: el.tarifName, tarifID: el.tarifID };
      })
      .filter((v, i, a) => a.findIndex((d) => d.label === v.label) === i) ??
    data
      ?.find((el) =>
        typeof values.supplier !== "string"
          ? el.markenName === values.supplier?.value
          : el.markenName === values.supplier
      )
      ?.tarife?.map((el) => {
        return { label: el.tarifName, value: el.tarifName, tarifID: el.tarifID };
      })
      .filter((v, i, a) => a.findIndex((d) => d.label === v.label) === i);

  return (
    <div ref={ref} className="mt-4">
      <Divider />
      <div className={`flex items-center my-4 ${config.media === "desktop" ? "" : " justify-between"}`}>
        <section id="compare-tariff-selection">
          <div className="items-center rounded-md grid grid-cols-2 gap-4" role="group">
            <FormikRadio label={compare} name="compareType" value="0" />
            <FormikRadio label={individual} name="compareType" value="1" />
          </div>
        </section>
      </div>
      <div className="mt-4">
        {values.compareType === "0" ? (
          <>
            <div className="my-2">
              {data && (
                <GetSelect
                  name="supplier"
                  label={supplier}
                  isSearchable={true}
                  options={data?.map((el) => {
                    return { label: el.versorgerName, value: el.versorgerName };
                  })}
                />
              )}
            </div>
            <div className="my-2">
              <GetSelect name="tariffComparison" label={tariff} isSearchable={true} options={tarife || []} />
            </div>
          </>
        ) : (
          <SelfCompareTariff timeframe={timeframeYear} isBrutto={isBrutto} />
        )}
        <div className="flex mt-4 gap-4 justify-between">
          <div>
            <span className="text-xs text-gray-dark">{width > 500 && (isBrutto ? pricesInclTax : pricesExclTax)}</span>
          </div>
          <div className="flex gap-4 flex-wrap justify-end">
            <Button variant="secondary" onClick={() => setModalOpen(false)} id="tariffComparison_abort">
              Abbrechen
            </Button>
            <Button
              disabled={
                values.compareType === "1"
                  ? !(
                      (values.workingPriceBrutto && values.basePriceBrutto) ||
                      (values.workingPriceNetto && values.basePriceNetto)
                    )
                  : !values.tariffComparison
              }
              onClick={handleChangeNewTariff}
              className="p-2 w-fit"
              variant="primary"
              id="tariffComparison_submit"
            >
              {TCS_Btn}
            </Button>
          </div>
        </div>
        {(width <= 500 || width === undefined) && (
          <div className="flex justify-end mt-4">
            <span className="text-xs text-gray-dark">{isBrutto ? pricesInclTax : pricesExclTax}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TariffComparison;
