import {useField} from "formik";
import {useValidateMaLoService} from "./useValidateMaLo";


export interface MaloIDCheck {

    isValid: boolean;

}

const useCheckMaloID=(maLoID:string)=>{
    const res :MaloIDCheck={isValid:true};

    const maloResult=   useValidateMaLoService((maLoID)?maLoID:'0');

   return ()=>{

            res.isValid=maloResult?.data?.isValid;
     return res;
   };

  };

  export default useCheckMaloID;