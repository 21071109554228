import { useField } from "formik";
import React from "react";

import FormRow from "../../../../components/FormRow";
import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const telkoPreProvider = [
  { label: "Custom", value: " Nicht in Liste enthalten" },
  { label: "DEU.1UND1", value: "1 & 1, Tiscali, freenet" },
  { label: "DEU.BORNET", value: "BORnet GmbH" },
  { label: "DEU.BRANDL", value: "Brandl Services GmbH" },
  { label: "DEU.D012", value: "BT Ignite" },
  { label: "DEU.BUDI", value: "Buchholz Digital" },
  { label: "DEU.COLTDE", value: "COLT TELECOM" },
  { label: "DEU.COMIN", value: "COM-IN" },
  { label: "DEU.CONG", value: "Congstar (T-Online)" },
  { label: "DEU.DACOR", value: "Dacor GmbH" },
  { label: "DEU.DCC", value: "DCC GmbH" },
  { label: "DEU.DETEST", value: "Deu.Tel.Standard" },
  { label: "DEU.DGH", value: "Deutsche Glasfaser Home GmbH" },
  { label: "DEU.DGW", value: "Deutsche Glasfaser Wholesale GmbH" },
  { label: "DEU.DTAG", value: "Deutsche Telekom, T-Mobile" },
  { label: "DEU.DNSNET", value: "DNS:NET Internet Service GmbH" },
  { label: "DEU.DOKOM", value: "DOKOM" },
  { label: "DEU.DUSNET", value: "dus.net GmbH" },
  { label: "DEU.ENET", value: "E.Net GmbH und Co.KG" },
  { label: "DEU.EASYBL", value: "easybell GmbH" },
  { label: "DEU.ECOTEL", value: "Ecotel" },
  { label: "DEU.EWG", value: "Elektrizitätswerk Goldbach-Hösbach" },
  { label: "DEU.ELINE", value: "encoLine GmbH, Thüringer Netkom" },
  { label: "DEU.ETEL", value: "envia.tel, Chemnitz" },
  { label: "DEU.DE1EPC", value: "epcan GmbH" },
  { label: "DEU.EQUADA", value: "Equada GmbH" },
  { label: "DEU.EWE", value: "EWETel" },
  { label: "DEU.FLINK", value: "FLINK GmbH" },
  { label: "DEU.GWHTEL", value: "GWHtel GmbH und Co.KG" },
  { label: "DEU.HMN", value: "HEAG MediaNet, Darmstadt" },
  { label: "DEU.HELI01", value: "HeLi NET GmbH" },
  { label: "DEU.HERZO", value: "HerzoMedia" },
  { label: "DEU.HFO", value: "HFO Telecom" },
  { label: "DEU.HTP", value: "HTP, Hannover" },
  { label: "DEU.INEXIO", value: "inexio IT und TK KGaA" },
  { label: "DEU.RWEFN", value: "innogy TelNet GmbH (RWE)" },
  { label: "DEU.INOPLA", value: "inopla GmbH" },
  { label: "DEU.KBW", value: "Kabel BW" },
  { label: "DEU.KDVS", value: "Kabel Deutschland Vertrieb" },
  { label: "DEU.KNET", value: "K-Net Telekommunikation GmbH" },
  { label: "DEU.KOMRO", value: "komro GmbH" },
  { label: "DEU.D115", value: "MDCC, Magdeburg" },
  { label: "DEU.MNET", value: "M-net, AugustaKom, NEFKom" },
  { label: "DEU.MD8000", value: "mobilcom-debitel GmbH" },
  { label: "DEU.NETAC", value: "NETAACHEN GmbH" },
  { label: "DEU.NETCO", value: "NETCOLOGNE" },
  { label: "DEU.NCKS", value: "Netcom, Kassel" },
  { label: "DEU.SWB", value: "NordCom, Bremen" },
  { label: "DEU.D061", value: "o2 Mobile / o2 Homezone (Festnetznummer)" },
  { label: "DEU.ORNET", value: "OR Network GmbH" },
  { label: "DEU.OSNATL", value: "Osnatel, Osnabrück" },
  { label: "DEU.PICODE", value: "pinnau.com GmbH und Co.KG" },
  { label: "DEU.FLTC", value: "Pyur (Tele Columbus/HL-Komm)" },
  { label: "DEU.QSC", value: "QSC, Broadnet Mediascape, Ventelo" },
  { label: "DEU.QUIX", value: "Quix Breitband GmbH" },
  { label: "DEU.REVTIX", value: "Reventix GmbH" },
  { label: "DEU.RKOM", value: "R-KOM, Regensburg" },
  { label: "DEU.SG", value: "Sipgate Wireless, netzquadrat" },
  { label: "DEU.SNBG", value: "Stadtnetze Barmstedt GmbH" },
  { label: "DEU.SWATEL", value: "Stadtwerke Ahrensburg GmbH" },
  { label: "DEU.BITEL", value: "Stadtwerke Bielefeld GmbH" },
  { label: "DEU.SWENG", value: "Stadtwerke Engen" },
  { label: "DEU.SWEUDI", value: "Stadtwerke Eutin GmbH" },
  { label: "DEU.SWFL", value: "Stadtwerke Flensburg GmbH" },
  { label: "DEU.SWKN", value: "Stadtwerke Konstanz" },
  { label: "DEU.SWNNET", value: "Stadtwerke Neumünster GmbH" },
  { label: "DEU.DEUNOR", value: "Stadtwerke Nortel" },
  { label: "DEU.STERN", value: "Stern Kom GmbH" },
  { label: "DEU.SIS", value: "Stiegeler Internet Services GmbH Co.KG" },
  { label: "DEU.TRILAN", value: "SWT trilan GmbH " },
  { label: "DEU.SYMBOX", value: "symbox" },
  { label: "DEU.TQUICK", value: "tel.quick GmbH und Co. KG" },
  { label: "DEU.TEL2DE", value: "Tele 2, Düsseldorf" },
  { label: "DEU.TDGMBH", value: "TeleData GmbH" },
  { label: "DEU.TELSA1", value: "Telsakom GmbH" },
  { label: "DEU.TNG", value: "TNG Stadtnetz" },
  { label: "DEU.UMNRW", value: "Unitymedia GmbH" },
  { label: "DEU.UMHESS", value: "Unitymedia Hessen GmbH & Co.KG" },
  { label: "DEU.VSM", value: "Vereinigte Stadtwerke Media GmbH" },
  { label: "DEU.MCI", value: "Verizon Deutschland GmbH" },
  { label: "DEU.VTEL", value: "Versatel, TROPOLYS, CNE, KielNet" },
  { label: "DEU.VBBGFA", value: "Versorgungsbetriebe Bordesholm GmbH" },
  { label: "DEU.VFDE", value: "Vodafone, ISIS, WüCom" },
  { label: "DEU.VOICEW", value: "Voiceworks" },
  { label: "DEU.VSENET", value: "VSE Net, Saarbrücken" },
  { label: "DEU.WTNET", value: "wilhelm.tel" },
  { label: "DEU.WTHAM", value: "willy.tel GmbH" },
  { label: "DEU.WISOTL", value: "Wisotel" },
  { label: "DEU.WOBCOM", value: "WOBCOM GmbH, Wolfsburg" },
  { label: "DEU.ZDGMBH", value: "zollernalb-data GmbH" },
];

const TelephoneInstallationRequest = () => {
  const [{ value }] = useField("telephoneInstallationRequest");
  const [{ value: supplier }] = useField("prevSupplierName");
  const { firstName, lastName, companyName, prevProviderTitle } = useFeaturesCC();
  return (
    <>
      <div className="font-medium text-lg">Rufnummer(n)</div>
      <FormikRadio
        value="true"
        name="telephoneInstallationRequest"
        label="neue Rufnummer (Sie erhalten die von Ihnen gewünschte Anzahl an Rufnummern)"
      />
      <FormikRadio
        value="false"
        name="telephoneInstallationRequest"
        label="Bestehende Rufnummer(n) portieren. Bitte tragen Sie Ihre bestehenden Rufnummern mit Vorwahl hier ein. Mehrere Rufnummern bitte mit Komma getrennt eingeben."
      />

      {value === "false" && (
        <>
          <Input
            label="Bestehende Rufnummer(n): z.B 06142-123456, 06142-123456"
            name="currentInstalledTelephoneNumber"
          />
          <div className="my-4">
            <GetSelect name="prevSupplierName" label={prevProviderTitle} options={telkoPreProvider} />
          </div>
          {supplier.label === "Custom" && <Input label={prevProviderTitle} name="prevSupplierNameInput" />}

          <div className="my-4">Anschlussinhaber</div>
          <FormRow addClassName="flex flex-col sm:flex-row">
            <div className="mb-4 sm:mb-0 mr-0 sm:mr-4 sm:w-1/2 w-full">
              <Input name="previousSubscriber_firstname" label={firstName} placeholder="Vorname" inputType="name" />
            </div>
            <div className="sm:w-1/2 w-full">
              <Input name="previousSubscriber_lastname" label={lastName} placeholder="Nachname" inputType="name" />
            </div>
          </FormRow>
          <div className="grid grid-cols-2 gap-y-2 gap-x-4 my-4">
            <Input name="previousSubscriber_company" label={companyName} placeholder="Firma" inputType="firma" />
          </div>
        </>
      )}
    </>
  );
};

export default TelephoneInstallationRequest;
