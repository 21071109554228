import { VPPBaseUrl } from "../Constant";

const controller = new AbortController();

const VPPInstance = async (url, config) => {
  let response;
  try {
    if (!VPPBaseUrl) {
      throw new Error("VPPBaseUrl is missing");
    }
    if (!url) {
      throw new Error("Invalid URL provided");
    }
    if (!config.apikey) {
      throw new Error("api key is missing in the config object");
    }

    const options = {
      headers: {
        ...config?.headers,
        "X-API-KEY": config.apikey,
        ...(config?.authToken ? { Authorization: config.authToken } : {}),
      },
      signal: controller.signal,
      method: config.method || "GET",
      ...(config.method === "POST" && config.body ? { body: config.body } : {}),
    };

    response = await fetch(`${VPPBaseUrl}${url}`, options);

    if (!response.ok) {
      throw new Error(`Error ${response.status}`);
    }

    const data = await response.json();
    return { data, status: response.status, headers: response.headers };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default VPPInstance;
