import { addDays, addYears, format } from "date-fns";
import { useField } from "formik";
import { CalendarDays } from "lucide-react";
import React, { ReactNode } from "react";
import ReactCalendar from "react-calendar";
import { Value } from "react-widgets/esm/types";

import { useConfig } from "../../context/Config";
import validBirthday from "../../utils/birthdayValid";
import validCancellationDate from "../../utils/cancellationDateValid";
import validDesiredDelivery from "../../utils/desiredDeliveryValid";
import validMoveInDate from "../../utils/moveInDateValid";
import validReadingDate from "../../utils/readingDateValid";
import useFeaturesCC from "../../widgets/TCContractConclusion/hooks/useFeaturesCC";
import useFeatureInputPage from "../../widgets/TCInputs/hooks/useFeatureInputPage";
import Button from "../Button";
import ErrorMessage from "../ErrorMessage";
interface DatePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  bgColor?: string;
  className?: string;
  openTop?: boolean;
}

const DatePicker = ({
  name,
  label,
  disabled = false,
  bgColor,
  className,
  openTop = false,
  ...props
}: DatePickerProps) => {
  const {
    calendarWidget,
    moveInDateDaysInPast,
    moveInDateMonthsInFuture,
    requestedDesiredDateDaysInAdvanceEnergy,
    requestedDesiredDateDaysMax,
  } = useFeaturesCC();
  const { deadlineMaxDaysInFuture, deadlineMinDaysInPast } = useFeatureInputPage();

  const [showCalendar, setShowCalendar] = React.useState(false);
  const [fields, meta, { setValue, setTouched }] = useField({ name });
  const inputRef = React.useRef<HTMLDivElement>(null);
  const { config } = useConfig();
  const datePickerRef = React.useRef<HTMLElement>(null);

  const dateValue = React.useMemo(() => {
    if (fields.value && fields.value?.length === 10) {
      const [day, month, year] = fields.value.split(".");

      if (!year || year?.length !== 4 || !month || !day) return new Date(1900, 0, 1);

      return new Date(Number(year), Number(month) - 1, Number(day));
    }
    return undefined;
  }, [fields.value]);

  React.useEffect(() => {
    config.reference.current != null
      ? config.reference.current.addEventListener("mousedown", (event: MouseEvent) => handleClick(event))
      : () => {};
    return config.reference.current != null
      ? config.reference.current.removeEventListener("mousedown", (event: MouseEvent) => handleClick(event))
      : () => {};
  }, [config.reference]);

  const handleClick = (event: MouseEvent) => {
    if (
      datePickerRef.current != null &&
      !datePickerRef.current.contains(event.target as Node) &&
      datePickerRef.current != null &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setShowCalendar(false);
    }
  };

  const handleBlur = React.useCallback(async () => {
    await setTouched(true);
  }, [setTouched]);

  const handleDateChangePicker = React.useCallback(
    async (e: Value) => {
      if (e instanceof Date) {
        const convertDateValue = format(new Date(e), "dd.MM.yyyy");
        await setValue(convertDateValue);
        setShowCalendar((prevState) => !prevState);
      } else {
        console.error(`Der übergebene Wert ${e} ist kein Datum!`);
      }
    },
    [setValue]
  );
  const handleDateChangeInput = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = e.target.value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
      if (regex) {
        const [, day, month, year] = regex;
        const value = !month ? day : `${day}.${month}${`${year ? `.${year}` : ""}`}`;
        if (Number(day) <= 31 && Number(month) <= 12 && Number(year) <= new Date().getFullYear() + 1) {
          await setValue(value);
        }
      }
    },
    [setValue]
  );

  const today = new Date(new Date().setHours(0, 0, 0, 0));
  let minDate = addYears(today, -1);
  let maxDate = addYears(today, 1);
  let defaultActiveStartDate = dateValue ? dateValue : today;
  const { getMinAge, getMaxAge } = validBirthday("", 18, 124);
  const { minMovingInDate, maxMovingInDate } = validMoveInDate("", moveInDateDaysInPast, moveInDateMonthsInFuture);
  const { minDesiredDeliveryDate, maxDesiredDeliveryDate } = validDesiredDelivery(
    "",
    requestedDesiredDateDaysInAdvanceEnergy,
    requestedDesiredDateDaysMax
  );
  const { minReadingDate } = validReadingDate("", 6, 0);
  const { minCancellationDate, maxCancellationDate } = validCancellationDate("", -6, 0);
  switch (name) {
    case "moveInDate":
      minDate = minMovingInDate();
      maxDate = maxMovingInDate();
      break;
    case "desiredDeliveryDate":
      minDate = minDesiredDeliveryDate();
      maxDate = maxDesiredDeliveryDate();
      defaultActiveStartDate = dateValue ? dateValue : addDays(today, requestedDesiredDateDaysInAdvanceEnergy);
      break;
    case "readingDate":
      minDate = minReadingDate();
      maxDate = today;
      break;
    case "birthday":
      minDate = getMaxAge();
      maxDate = getMinAge();
      defaultActiveStartDate = dateValue ? dateValue : addYears(today, -30);
      break;
    case "cancellationDate":
      minDate = minCancellationDate();
      maxDate = maxCancellationDate();
      break;
    case "stichtag":
      minDate = addDays(today, -deadlineMinDaysInPast);
      maxDate = addDays(today, deadlineMaxDaysInFuture);
      break;
  }
  return (
    <div className="flex relative w-full flex-col">
      <label htmlFor={name} className="text-xs leading-4 pb-1 w-full">
        {label}
      </label>
      <div
        id="date-picker"
        className={`border border-solid rounded-full border-primary mb-1 ${disabled ? "bg-gray-light" : ""}`}
      >
        <div className="flex relative w-full h-8" ref={inputRef}>
          <input
            {...fields}
            disabled={disabled}
            type="text"
            className={`appearance-none pr-4 pl-4 outline-none placeholder-transparent border-none ${
              bgColor || "bg-transparent"
            } invalid:color-red ${className || ""}`.trim()}
            name={name}
            onChange={handleDateChangeInput}
            onBlur={handleBlur}
            maxLength={10}
            data-testid={`test-${name}`}
            placeholder=" "
            {...props}
            id={name}
          />

          {calendarWidget && (
            <Button
              variant="unstyled"
              disabled={false}
              className="rounded h-8 bg-transparent pr-3"
              onClick={() => setShowCalendar((prevState) => !prevState && !disabled)}
            >
              <CalendarDays />
            </Button>
          )}
        </div>

        {showCalendar && (
          <section
            className={`absolute z-30 ${openTop ? "bottom-[76px]" : ""}`}
            style={{ maxWidth: config.media === "mobile" ? inputRef?.current?.offsetWidth : 350 }}
            ref={datePickerRef}
          >
            <ReactCalendar
              locale="de"
              defaultActiveStartDate={defaultActiveStartDate}
              minDate={minDate}
              maxDate={maxDate}
              value={fields.value?.length === 10 ? dateValue : undefined}
              defaultView="month"
              onChange={(value) => handleDateChangePicker(value)}
            />
          </section>
        )}
      </div>
      <span className="h-4">{meta.touched && meta.error && <ErrorMessage text={meta.error} />}</span>
    </div>
  );
};

export default DatePicker;
