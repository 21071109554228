import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import useSelectedTariff from "../../hooks/useSelectTariff";



const TariffContext = createContext(null);

const TariffProvider = ({ children }) => {
  const [selectedTariff, setSelectTariff] = useSelectedTariff();

  return (
    <TariffContext.Provider value={{ selectedTariff, setSelectTariff }}>
      {children}
    </TariffContext.Provider>
  );
};

export default TariffProvider;
export const useSelectTariff = () =>
  useContextSelector(TariffContext, (c) => c);
