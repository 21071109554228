function resolveSuggestion(data: string[] = []) {
  if (!data) return [];

  const result: string[] = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].includes(" | ")) {
      const cityName = data[i].split(" | ")[0];
      if (!result.includes(cityName.trim())) {
        result.push(cityName);
      }
    }
  }

  return result.map((v) => ({ label: v, value: v }));
}
export default resolveSuggestion;
