import { useField } from "formik";
import * as React from "react";

import Checkbox from "../../../../components/Checkbox";
import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import useGetPolicyDocument from "../../../TCContractConclusion/hooks/useGetPolicyDocument";
import DesiredCancellationDate from "../DesiredCancellationDate";

const CancellationData = () => {
  const [{ value: perMail }] = useField("perMail");
  const [{ value: versorger }] = useField("versorger");
  const { data } = useGetPolicyDocument();

  const Versorger_LIST = [
    `Ich möchte ${
      versorger.label === "Halberstadtwerke" ? "" : "von " + versorger.label
    } wechseln`,
    "Ich ziehe um",
    "Aufgrund einer Preisanpassung möchte ich mein Sonderkündigungsrecht in Anspruch nehmen",
    "Sonstiges",
  ].map((item) => ({ label: item, value: item }));

  const privacyPolicyText = React.useMemo(() => {
    return (
      (data?.data?.privacyUrl && (
        <>
          Ich habe die aktuellen{" "}
          <a
            rel="noreferrer"
            href={data?.data?.privacyUrl || ""}
            target="_blank"
          >
            Datenschutzbestimmungen
          </a>{" "}
          gelesen und akzeptiere diese.*
        </>
      )) ||
      undefined
    );
  }, [data?.data?.privacyUrl]);

  return (
    <>
      <div className="font-bold text-xl mt-6">Grund Ihrer Kündigung *</div>
      <GetSelect
        className="w-full mb-4"
        label="Wählen Sie den Grund Ihrer Kündigung"
        name="grund"
        options={versorger ? Versorger_LIST : []}
      />
      <DesiredCancellationDate />
      <div className="font-bold text-xl my-6">
        Kündigungsbestätigung an (bitte mindestens eine Auswahl treffen)
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "2rem",
        }}
      >
        <Checkbox name="perMail" label="E-Mail-Adresse" />
        <Checkbox name="perPost" label="Postanschrift" />
      </div>

      <div className="mt-4">
        <Input
          name="emailadresse"
          label="Geben Sie hier Ihre E-Mail-Adresse ein"
          disabled={!perMail}
        />
      </div>

      <div className="my-4">
        <Checkbox
          name="isPrivacyPolicyCheck"
          labelComponent={privacyPolicyText}
        />
      </div>
    </>
  );
};

export default CancellationData;
