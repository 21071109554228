import ProxyInstance from "../../../services/proxy";
import { Abschlussdatenobjekt } from "../../../types/Abschlussdatenobjekt";

const sendMailPreSigned = async (
  tariffId: string,
  apikey: string,
  authToken: string|undefined,
  s3PreSignedDownloadFilePath: string,
  s3PreSignedDownloadFileName: string,
  externFileList: string,
  body: { jsonDaten: { abschlussDaten: Abschlussdatenobjekt[] } },
  type: string
) =>
  await ProxyInstance(
    type === "customer"
      ? `/mail/sendMailCustomer?calculatorType=${tariffId}&s3PresignedDownloadFilePath=${s3PreSignedDownloadFilePath}&s3PresignedDownloadFileName=${s3PreSignedDownloadFileName}&externFileList=${externFileList}`
      : `/mail/sendMailDistribution?calculatorType=${tariffId}&s3PresignedDownloadFilePath=${s3PreSignedDownloadFilePath}&s3PresignedDownloadFileName=${s3PreSignedDownloadFileName}&externFileList=${externFileList}`,
    {
      apikey: apikey || "",
      authToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    }
  );

export default sendMailPreSigned;
