import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const formInputData = createContext(null);

const CancellationFormInputDataProvider = ({ children }) => {
  const [values, setValues] = React.useState(null);

  return (
    <formInputData.Provider value={{ values, setValues }}>
      {children}
    </formInputData.Provider>
  );
};

export default CancellationFormInputDataProvider;
export const useTRInputContext = () =>
  useContextSelector(formInputData, (c) => c);
