import { useQuery } from "react-query";

import { useConfig, useParameter, useTariff } from "../../../../context";
import { useTRInputContext } from "../../../../context/FormInputData";
import ProxyInstance from "../../../../services/proxy";
import useQueryParameters from "../useQueryParameters";

const useVariantSupplier = (calcParams) => {
  const tariff = useTariff();
  const config = useConfig();
  const { authToken } = useParameter();
  const inputContext = useTRInputContext();
  const body = {
    plz: inputContext?.values?.zipCode,
    kundenart: inputContext?.values?.customerType,
    verbrauch: inputContext?.values?.oneTariff?.replace(".", ""),
    netzbetreiber: calcParams?.netzbetreiber || "",
    grundversorger: calcParams?.grundversorger || "",
    tariftyp: tariff.tariftyp,
  };

  const queryParams = useQueryParameters({
    netzbetreiber: calcParams?.netzbetreiber || "",
    grundversorger: calcParams?.grundversorger || "",
    tariftyp: tariff.tariftyp,
  });

  return useQuery(
    [
      "GET_SUPPLIER_VARIANT",
      calcParams?.netzbetreiber,
      calcParams?.grundversorger,
    ],
    async () => {
      try {
        let res;

        if (tariff.tariffType !== "gas") {
          res = await ProxyInstance(
            `/ws/tariff/energie/getVersorgerTarifvarianten?${queryParams}`,
            {
              apikey: config.apikey || "",
            }
          );
        } else {
          res = await ProxyInstance(
            `/ws/tariff/gas/getVersorgerTarifvarianten`,
            {
              apikey: config.apikey || "",
              method: "POST",
              authToken,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
        }

        const data = res?.data?.data?.versorger ?? [];

        return data
          .map((item) => {
            return {
              ...item,
              versorgerName: item.versorgerName ? item.versorgerName : "",
            };
          })
          .filter((v) => v.versorgerName)
          .filter(
            (v, i, a) =>
              a.findIndex((d) => d.versorgerName === v.versorgerName) === i
          )
          .sort((a, b) => a.versorgerName.localeCompare(b.versorgerName));
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {}
  );
};

export default useVariantSupplier;
