import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const supplierInfo = createContext(null);

const SupplierInfo = ({ children }) => {
  const [supplierInformation, setSupplierInformation] = React.useState(null);

  return (
    <supplierInfo.Provider
      value={{ supplierInformation, setSupplierInformation }}
    >
      {children}
    </supplierInfo.Provider>
  );
};

export default SupplierInfo;
export const useSupplierInfo = () => useContextSelector(supplierInfo, (c) => c);
