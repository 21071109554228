import { Formik } from "formik";
import pick from "lodash/pick";
import React from "react";

import { useStepChange } from "../../context";
import { useTRInputContext } from "../../context/CancellationData";
import useExternalConfigs from "../../hooks/useExternalConfigs";
import InputForm from "./inputForm";
import validationSchema from "./validation";

const TCFormular = ({ submit }) => {
  const { setStep } = useStepChange();
  const { data } = useExternalConfigs("cancellationform");
  const konfigValue =
    data?.data?.data?.functionscancellationform?.anbieterauswahl[0];

  const { values: formData, setValues } = useTRInputContext();

  return (
    <>
      <Formik
        component={InputForm}
        validationSchema={validationSchema}
        initialValues={{
          Vorname: formData?.Vorname || "",
          Name: formData?.Name || "",
          birthday: formData?.birthday || "",
          Strasse: formData?.Strasse || "",
          Hausnummer: formData?.Hausnummer || "",
          Ort: formData?.Ort || "",
          PLZ: formData?.PLZ || "",
          Versorger: formData?.Versorger || "",
          Kundennummer: formData?.Kundennummer || "",
          MarktlokationsID: formData?.MarktlokationsID || "",
          Vertragsnummer: formData?.Vertragsnummer || "",
          Zaehlernummer: formData?.Zaehlernummer || "",
          Grund: formData?.Grund?.label || "",
          hasDesiredCancellationDate: formData?.hasDesiredCancellationyDate || "false",
          desiredCancellationDate: formData?.desiredCancellationDate || "",
          Vertragsende: formData?.Vertragsende || "",
          perMail: formData?.perMail || false,
          perPost: formData?.perPost || false,
          Emailadresse: formData?.Emailadresse || "",
          isPrivacyPolicyCheck: formData?.isPrivacyPolicyCheck || false,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          values.Vertragsende =
            values.hasDesiredDeliveryDate === "false"
              ? "zum nächstmöglicher Termin"
              : values.desiredDeliveryDate;

          if (setValues) {
            setValues(values);
          }

          try {
            if (konfigValue) {
              await submit({
                formikValues: values,
                konfigValues: pick(konfigValue, [
                  "backupmail",
                  "betreff",
                  "sendermail",
                  "template",
                  "urlId",
                  "zielmail",
                ]),
              });
              setStep(6);
            }
          } catch (e) {
            console.error(e);
            throw e;
          }

          setSubmitting(false);
        }}
      />
    </>
  );
};

export default TCFormular;
