import qs from "query-string";

import { useTRInputContext } from "../../../../context/FormInputData";

const useQueryParameters = (additionalData: { [key: string]: string }) => {
  const inputContext = useTRInputContext();

  return qs.stringify({
    plz: inputContext?.values?.zipCode,
    kundenart: inputContext?.values?.customerType,
    verbrauch: inputContext?.values?.oneTariff?.replace(".", ""),
    ...additionalData,
  });
};

export default useQueryParameters;
