import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import toast from "../components/Toast";
import { useConfig } from "../context/Config";
import ConfigInstance from "../services/config";

const setAPIKeyCookie = (key: string, ttl?: number) => {
  const inFourMinutes = new Date(new Date().getTime() + 4 * 60 * 1000);
  Cookies.set("apikey", key, { expires: ttl || inFourMinutes });
};

const getAPIKeyCookie = () => {
  return Cookies.get("apikey");
};

const useAPIKey = () => {
  const [apikey, setAPIKey] = useState(getAPIKeyCookie() ?? "");
  const { config, setConfig } = useConfig();

  // override api key to avoid error in testing
  const fetchAPIKey = async () => {
    try {
      const { data } = await ConfigInstance("/adserv");
      setAPIKey(data.data.apikey);
      setAPIKeyCookie(data.data.apikey);
      return data.data.apikey;
    } catch (e) {
      toast.error("API: Konfigurationsdatei fehlerhaft");
      return undefined;
    }
  };
  useEffect(() => {
    if (getAPIKeyCookie() === undefined) {
      fetchAPIKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const revalidateAPIKey = async () => {
    if (getAPIKeyCookie() === undefined) {
      const APIkey = await fetchAPIKey();
      setConfig({ ...config, apikey: APIkey });
    }
  };
  const getAPIKey = async () => {
    let APIkey = getAPIKeyCookie();
    if (APIkey === undefined) {
      APIkey = await fetchAPIKey();
      if (APIkey !== undefined) {
        try {
          setConfig({ ...config, apikey: APIkey });
        } catch {
          console.error("unable to set apikey to config");
        }
      }
    }
    return APIkey ?? "";
  };
  return { data: apikey, getAPIKey: getAPIKey, revalidateAPIKey: revalidateAPIKey, isLoading: !apikey };
};

export default useAPIKey;
