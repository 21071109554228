import { useCallback, useEffect, useRef, useState } from "react";

function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const callback = useCallback(() => setDebouncedValue(value), [value]);

  useEffect(() => {
    timerRef.current = setTimeout(callback, delay);
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [callback, delay]);

  return debouncedValue;
}

export default useDebounce;
