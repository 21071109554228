import { Formik } from "formik";
import React from "react";
import { object, string } from "yup";

import LoadingOverlay from "../../components/LoadingOverlay";
import Desktop from "../../components/View/Desktop";
import Mobile from "../../components/View/Mobile";
import Tablet from "../../components/View/Tablet";
import { useParameter, useStepChange, useTariff } from "../../context";
import { useCalcParams } from "../../context/CalculationParameter";
import { useTRInputContext } from "../../context/FormInputData";
import { useSelectTariff } from "../../context/Tariff";
import { useSelectTelco } from "../../context/Telco";
import SelectedTelcoTariff from "../TCResults/components/TelcoResults/SelectedTariff";
import ContractConclusionForm from "./components/ContractConclusionForm";
import SelectedTariff from "./components/SelectedTariff";
import {
  addressDataInitialValues,
  addressDataInstallationInitialValues,
  bankDataInitialValues,
  contactDataInitialValues,
  geeDataInitialValues,
  preSupplierDataInitialValues,
  summaryInitialValues,
} from "./Constant";
import useFeaturesCC from "./hooks/useFeaturesCC";
import handleTariffForms from "./utils/handleTariffForms";
import handleTelcoForms from "./utils/handleTelcoForms";

const ContractConclusion = ({ submit }) => {
  const tariff = useTariff();
  const { calcParams } = useCalcParams();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const { authToken } = useParameter();
  const { setStep } = useStepChange();
  const { vppCustomerId } = useParameter();
  const { isShowPassword } = useFeaturesCC();
  const { values } = useTRInputContext();

  const initialValues = React.useMemo(
    () =>
      values?.contractConclusions
        ? values?.contractConclusions
        : {
            ...contactDataInitialValues,
            ...addressDataInitialValues,
            ...geeDataInitialValues,
            ...addressDataInstallationInitialValues,
            ...preSupplierDataInitialValues,
            ...bankDataInitialValues,
            ...summaryInitialValues,
            asOffer: false,
            IBANError: true,
          },
    [values?.contractConclusions]
  );

  return (
    <Formik
      validationSchema={object().shape({
        iban: string()
        .test({
          name: "max",
          exclusive: false,
          message: "Ihre IBAN ist zu lang" ,
          test: (value) => {
            if (value?.startsWith("DE")) {
              return value.length <= 22;
            }
            return true;
          },
        })
        .test({
          name: "min",
          exclusive: false,
          message: "Ihre IBAN ist zu kurz" ,
          test: (value) => {
            if (value?.startsWith("DE")) {
              return value.length >= 22;
            }
            return true;
          },
        })
        .test({
          name: "wrong",
          exclusive: false,
          message: "Falsche IBAN " ,
          test: (_, context) => context?.parent?.IBANError,
        }),
        safetyIndex: string().when([], {
          is: () => isShowPassword,
          then: string().required(
            "Bitte geben Sie eine vierstellige Zahl ein."
          ),
          otherwise: string().notRequired(),
        }),
        bic: string().min(8,"Ihre BIC ist zu kurz").max(11, "Ihre BIC ist zu lang"),
        phoneNumber: string().min(6, "Telefonnummer unvollständig"),
        mobileNumber: string().min(6, "Mobilfunknummer unvollständig"),
        faxNumber: string().min(6, "Faxnummer unvollständig"),
      })}
      component={({isSubmitting}) => (
        <>
          {isSubmitting && <LoadingOverlay progressBarEnabled={true}/>}
          {!isSubmitting && (
            <>
              <Mobile>
                <section className="mb-8">
                <div className="mb-4">
                  {selectedTariff && <SelectedTariff />}
                  {selectedTelco && (
                    <SelectedTelcoTariff
                      options={selectedTelco.selectedOption || []}
                      hardwares={selectedTelco.selectedHardware || []}
                      product={selectedTelco || {}}
                    />
                  )}
                </div>
                <ContractConclusionForm />
                </section>
              </Mobile>

              <Tablet>
                <section className="mb-8">
                  <div className="mb-4">
                    {selectedTariff && <SelectedTariff />}
                    {selectedTelco && (
                      <SelectedTelcoTariff
                      options={selectedTelco.selectedOption || []}
                      hardwares={selectedTelco.selectedHardware || []}
                      product={selectedTelco || {}}
                      />
                      )}
                  </div>
                  <ContractConclusionForm />
                </section>
              </Tablet>

              <Desktop>
                <section className="grid grid-cols-1 gap-4 md:grid-cols-3 mb-8">
                  <ContractConclusionForm />
                  {selectedTariff && <SelectedTariff />}
                  {selectedTelco && (
                    <SelectedTelcoTariff
                      options={selectedTelco.selectedOption || []}
                      hardwares={selectedTelco.selectedHardware || []}
                      product={selectedTelco || {}}
                    />
                  )}
                </section>
              </Desktop>
                          </>
          )}
        </>
      )}
      initialValues={initialValues}
      onSubmit={async (formikValues, { setSubmitting, resetForm }) => {
                setSubmitting(true);

        if (selectedTariff && formikValues) {
          const formattedData = handleTariffForms(
            {
              ...selectedTariff,
              ...formikValues,
              tariffId: tariff.id || 1,
              usage: calcParams.verbrauchHT,
              medium: tariff.medium === "gas" ? 1 : 0,
              authToken,
              ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
            },
            { bindingOffer: true }
          );

          try {
            await submit({ formikValues, formattedData });
            setStep(4);
          } catch (e) {
            console.error(e);

            throw e;
          }
        }

        if (selectedTelco) {
          const formattedData = handleTelcoForms(
            {
              ...formikValues,
              ...selectedTelco,
              tariffId: tariff.id || 1,
              verbandsnummer: "",
              objectId: selectedTelco.objectId || "",
              authToken,
              ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
            },
            { bindingOffer: true }
          );

          try {
            await submit({ formikValues, formattedData });
            setStep(4);
          } catch (e) {
            console.error(e);
            throw e;
          }
        }

        setSubmitting(false);
        resetForm();
      }}
    />
  );
};

export default ContractConclusion;
