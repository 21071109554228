import React, { useMemo } from "react";

import EditIcon from "../../../../components/icons/editIcon";
import { useTariff } from "../../../../context";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useFeaturesResult from "../../hooks/useFeaturesResult";

const TRInputData = ({ isShowInputTariff, minHeight = 0 }: { isShowInputTariff: boolean; minHeight?: number }) => {
  const inputContext = useTRInputContext();
  const tariff = useTariff();
  const { config } = useConfig();
  const { unitUsage, headerSub } = useFeaturesResult();
  const isTelco = useMemo(() => tariff.id === "7", [tariff.id]);
  const { HTLabel, NTLabel } = useGeneralLabel();
  const city =
    typeof inputContext.values?.city !== "string" ? inputContext.values?.city?.value : inputContext.values?.city;

  return (
    <div
      className="flex flex-col justify-between h-full"
      style={config?.media !== "mobile" ? { minHeight: minHeight ?? 0 } : {}}
    >
      <div className="flex justify-between mb-3 font-medium">
        {headerSub} {!isShowInputTariff && <EditIcon />}
      </div>
      {!isTelco ? (
        <>
          <div className={"flex justify-between flex-row " + (config?.media !== "desktop" ? "text-sm" : "")}>
            <div className="flex flex-col">
              <span>
                {inputContext?.values?.zipCode} {city?.split("|")?.[0]?.trim() ?? city}
              </span>
              {city?.split("|")?.[1] && <span>{city?.split("|")[1]?.trim()}</span>}
              {inputContext.values?.street?.value && (
                <span>
                  {typeof inputContext.values?.street === "string"
                    ? inputContext.values?.street + " "
                    : inputContext.values?.street?.value + " "}
                  {typeof inputContext.values?.houseNumber === "string"
                    ? inputContext.values?.houseNumber
                    : inputContext.values?.houseNumber?.value}
                </span>
              )}
            </div>
            <div className="flex flex-col text-left justify-end">
              <div>
                {inputContext?.values?.meterType === "3,4" ? (
                  <span className="text-gray-dark mr-2">{HTLabel}</span>
                ) : (
                  ""
                )}
                <span className="float-right">
                  {inputContext?.values?.oneTariff} {unitUsage}
                </span>
              </div>
              {inputContext?.values?.meterType === "3,4" && (
                <div>
                  <span className="text-gray-dark mr-2">{NTLabel}</span>
                  <span className="float-right">
                    {inputContext?.values?.twoTariff} {unitUsage}
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex basis-1/3 flex-col gap-2">
          <div className="flex w-1/2">
            {typeof inputContext.values?.street !== "string"
              ? inputContext?.values?.street?.value
              : inputContext?.values?.street}{" "}
            {typeof inputContext.values?.houseNumber !== "string"
              ? inputContext?.values?.houseNumber?.value
              : inputContext?.values?.houseNumber}
          </div>
          <div className="flex w-1/2">
            {inputContext?.values?.zipCode}{" "}
            {typeof inputContext.values?.city !== "string" && inputContext?.values?.city?.value}
          </div>
        </div>
      )}
    </div>
  );
};

export default TRInputData;
