import React from "react";

function Spinner() {
  return (
    <div role="status" className="flex">
      <div className="border-primary border-solid h-3 w-3 animate-spin rounded-full border-2 border-t-primary-hover" />
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default Spinner;
