import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --gc-c-light: #f7f7f7;
    --gc-c-dark: #222222;
    --gc-c-gray: #e0e6ed;
    --gc-c-gray-light: #f7f7f7;
    --gc-c-gray-middle: #858585;
    --gc-c-gray-strong: #4e4e57;
    --gc-c-text-normal: #f7f7f7;
    --gc-c-primary-normal: #ff0000;
    --gc-c-primary-hover: #ff3333;
    --gc-v-font: Verdana;
    --gc-v-rounded-default: 0.5;
    --gc-v-rounded-input: 0.5em;
    --gc-v-rounded-button: 0.5em;
    --gc-content-bg: #222;
    --get-gc-c-input-border: #f7f7f7;
    --get-gc-c-input-color: #333;
    --get-gc-c-input-bg: #f9f9f9;
    --get-gc-c-input-label: #f0f;
  }
`;
export default myStyle;
