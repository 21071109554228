import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useRadioClassName from "../../hooks/useRadioClassName";
const TariffType: React.FC = () => {
  const { tarifTypLabel, waermepumpeLabel, speicherheizungLabel } = useFeatureInputPage();
  const radioClassName = useRadioClassName();

  return (
    <>
      <div className="mb-3 text-md w-full">{tarifTypLabel}</div>
      <div className={radioClassName}>
        <FormikRadio label={waermepumpeLabel} name="tariffType" value="0" className="m-0 accent-button-primary-bg" />
        <FormikRadio
          label={speicherheizungLabel}
          name="tariffType"
          value="1"
          className="m-0 accent-button-primary-bg"
        />
      </div>
    </>
  );
};

export default TariffType;
