import { Field, useField } from "formik";
import * as React from "react";

const FormikRadio = ({ label, ...props }: { label: string; value: string; className?: string; name: string }) => {
  const svgChecked = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="size-6 stroke-primary fill-primary">
      <circle cx="12" cy="12" r="8" strokeWidth="2" fill="none" />
      <circle cx="12" cy="12" r="5" />
    </svg>
  );
  const svgUnchecked = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="size-6 stroke-primary">
      <circle cx="12" cy="12" r="8" strokeWidth="2" />
    </svg>
  );
  const [field] = useField(props);

  return (
    <label className="flex items-center mb-2">
      <div className="relative flex items-center cursor-pointer size-6">
        <Field
          type="radio"
          {...props}
          className={`${props.className ?? ""} opacity-0`}
          id={props.name + "_" + props.value}
        />
        <div className="absolute left-0">{field.value === props.value ? svgChecked : svgUnchecked}</div>
      </div>
      <div className="flex text-sm pl-2">{label}</div>
    </label>
  );
};

export default FormikRadio;
