import React from "react";

import Desktop from "../../../../../components/View/Desktop";
import Mobile from "../../../../../components/View/Mobile";
import Tablet from "../../../../../components/View/Tablet";

const NameAndTariff= ({
  versorgerName,
  tarifName,
  className = "text-center"
}) => {
  return (<>
    <Mobile>
      <section id="tariff_name" className={className}>
        <div>{versorgerName}</div>
        <div className="text-xl font-bold">{tarifName}</div>
      </section>
    </Mobile>
    <Tablet>
      <section id="tariff_name" className={className}>
        <div>{versorgerName}</div>
        <div className="text-xl font-bold">{tarifName}</div>
      </section>
    </Tablet>
    <Desktop>
      <section id="tariff_name" className={className}>
        <div>{versorgerName}</div>
        <div className="text-3xl font-bold">{tarifName}</div>
      </section>
    </Desktop>
  </>
  );
};

export default NameAndTariff;
