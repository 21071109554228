import { useFormikContext } from "formik";
import React, { useMemo } from "react";

import Button from "../../../../components/Button";
import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import DatePicker from "../../../../components/Input/DatePicker";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import RangeSlider from "../../../../components/Slider";
import TRTooltip from "../../../../components/TRTooltip";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { DELEAY_AMOUNTCHANGE } from "../../../../Constant";
import { useTariff } from "../../../../context";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import useDebounce from "../../../../hooks/useDebounce";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import usePrimaService from "../../../../hooks/usePrimaService";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useSimplifier from "../../hooks/useSimplifier";
import { InputPageFormik } from "../../types/InputPageFormik";
import TRAddress from "../Address";
import TRButtonGroups from "../ButtonGroups";
import Header from "../Header";
import CustomerTypeRadio from "../Radio/CustomerType";
import MeasurementType from "../Radio/MeasurementType";
import TariffType from "../Radio/TariffType";

const TariffInputForm = ({
  handleSubmit,
  values,
  isSubmitting,
}: {
  handleSubmit: () => void;
  values: InputPageFormik;
  isSubmitting: boolean;
}) => {
  const {
    search,
    consumption,
    usage,
    oneTariff,
    twoTariffs,
    selectTariff,
    usageRuler,
    usageRulerValues,
    tooltippZaehlerart,
    tooltippVerbrauch,
    tooltippVerbrauchHT,
    tooltippVerbrauchNT,
    isHtNt,
    deadlineActivated,
    stichtag,

  } = useFeatureInputPage();
  const { responsiveClass } = useResponsiveClass();
  const { HTLabel, NTLabel } = useGeneralLabel();
  const tariff = useTariff();
  const { data } = useSimplifier();
  const inputContext = useTRInputContext();
  const { data: primaData } = usePrimaService();
  const { config } = useConfig();
  const { values: fields, setFieldValue } = useFormikContext<InputPageFormik>();
  const debounceOneTariff = useDebounce(values?.oneTariff, fields.isAutoSubmiting=== true?0:DELEAY_AMOUNTCHANGE);
  const debounceTwoTariff = useDebounce(values?.twoTariff, fields.isAutoSubmiting=== true?0:DELEAY_AMOUNTCHANGE);
  const sliderValues = useMemo(
    () => (usageRulerValues ? usageRulerValues : tariff.id === "2" ? [13000, 20000, 27000] : [1700, 2500, 3200, 4000]),
    [tariff.id, usageRulerValues]
  );
  React.useEffect(() => {
    // only strom and gas
    if (fields.isAutoSubmiting === true && data?.unique) {
      const timer = setTimeout(() => {
        handleSubmit();
        inputContext.values.isAutoSubmitingDisabled = true;
      }, 100);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [fields.isAutoSubmiting, inputContext, handleSubmit, data]);

  React.useEffect(() => {
    // only strom and gas
    if (tariff.id === "1" || tariff.id === "2") {
      setFieldValue("networkOperator", data?.networkOperator);
      setFieldValue("baseSupplier", data?.baseSupplier);
    }
  }, [data?.baseSupplier, data?.networkOperator, setFieldValue, tariff.id]);

  React.useEffect(() => {
    if (primaData?.zipCode && primaData?.city && primaData?.street && primaData?.houseNumber && tariff.id === "7") {
      const timer = setTimeout(() => {
        handleSubmit();
      }, 2000);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [primaData, tariff.id, handleSubmit]);
  const disabled = useMemo(
    () =>
      (data?.unique &&
        values.city.value?.length > 0 &&
        parseInt(values.oneTariff.replace(".", "")) === parseInt(debounceOneTariff?.replace(".", "")) &&
          (parseInt(values.twoTariff.replace(".", "")) === parseInt(debounceTwoTariff?.replace(".", ""))|| values.meterType !== "3,4") &&
        parseInt(values.oneTariff.replace(".", "")) > 0 &&
        (parseInt(values.twoTariff.replace(".", "")) > 0 || values.meterType !== "3,4")) ||
      (primaData?.zipCode && primaData?.city && primaData?.street && primaData?.houseNumber && tariff.id === "7"),
    [data, primaData, tariff.id, values]
  );

  return (
    <div className="max-w-[736px] mx-auto">
      <TRButtonGroups />
      <div className="max-w-[736px] mx-auto rounded-bottom-xl bg-content-bg text-content-text mb-1 p-6 rounded-3xl rounded-tl-none shadow-card">
        <div className={`grid gap-4 ${config.media === "mobile" ? "" : "grid-cols-2"}`}>
          <Header />
          <CustomerTypeRadio />
        </div>
        {config?.frontendConfig?.calculatorTypeIDs ? (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <div className="flex flex-col gap-4 w-full mt-6">
                <TRAddress />
                <div className="grid grid-cols-2 gap-4">
                  {tariff.id === "3" && (
                    <div className="w-max">
                      <TariffType />
                    </div>
                  )}
                  {tariff.id === "4" && (
                    <div className="w-max">
                      <MeasurementType />
                    </div>
                  )}

                  {tariff.id !== "7" && (
                    <>
                      {tariff.id !== "2" && (
                        <>
                          {isHtNt && (
                            <div className={`${config.media === "mobile" ? "col-span-2" : ""}`}>
                              <div className="text-xs mb-1">
                                {selectTariff}
                                {tooltippZaehlerart !== "" ? <TRTooltip text={tooltippZaehlerart} /> : ""}
                              </div>

                              <div className="flex gap-4">
                                <FormikRadio name="meterType" value="1,2" label={oneTariff} />
                                <FormikRadio name="meterType" value="3,4" label={twoTariffs} />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                      <div className="col-span-2 grid grid-cols-4 gap-4 w-full">
                        <Desktop>
                          <div className="w-full col-span-2">
                            <Input
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                              tooltip={values.meterType === "3,4" ? tooltippVerbrauchHT : tooltippVerbrauch}
                              onlyNumber
                              maxLength={7}
                              usage={usage}
                            />
                          </div>
                          {fields.customerType === "0" && usageRuler && fields.meterType === "1,2" && (
                            <div className="w-full mt-4 col-span-2">
                              <RangeSlider name="oneTariff" allowedValues={sliderValues} />
                            </div>
                          )}
                          {fields.meterType === "3,4" && (
                            <div className="w-full col-span-2">
                              <Input
                                name="twoTariff"
                                label={consumption + " " + NTLabel}
                                tooltip={tooltippVerbrauchNT}
                                onlyNumber
                                maxLength={7}
                                usage={usage}
                              />
                            </div>
                          )}
                        </Desktop>
                        <Tablet>
                          <div className="w-full col-span-2">
                            <Input
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                              tooltip={values.meterType === "3,4" ? tooltippVerbrauchHT : tooltippVerbrauch}
                              onlyNumber
                              maxLength={7}
                              usage={usage}
                            />
                          </div>
                          {fields.customerType === "0" && usageRuler && fields.meterType === "1,2" && (
                            <div className="w-full mt-4 col-span-2">
                              <RangeSlider name="oneTariff" allowedValues={sliderValues} />
                            </div>
                          )}
                          {fields.meterType === "3,4" && (
                            <div className="w-full col-span-2">
                              <Input
                                name="twoTariff"
                                label={consumption + " " + NTLabel}
                                tooltip={tooltippVerbrauchNT}
                                onlyNumber
                                maxLength={7}
                                usage={usage}
                              />
                            </div>
                          )}
                        </Tablet>
                        <Mobile>
                          <div className="w-full col-span-4">
                            <Input
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                              tooltip={values.meterType === "3,4" ? tooltippVerbrauchHT : tooltippVerbrauch}
                              onlyNumber
                              maxLength={7}
                              usage={usage}
                            />
                          </div>
                          {fields.customerType === "0" && usageRuler && fields.meterType === "1,2" && (
                            <div className="w-full col-span-4">
                              <RangeSlider name="oneTariff" allowedValues={sliderValues} />
                            </div>
                          )}
                          {fields.meterType === "3,4" && (
                            <div className="w-full col-span-4">
                              <Input
                                name="twoTariff"
                                label={consumption + " " + NTLabel}
                                tooltip={tooltippVerbrauchNT}
                                onlyNumber
                                maxLength={7}
                                usage={usage}
                              />
                            </div>
                          )}
                        </Mobile>
                      </div>
                    </>
                  )}
                </div>
                {deadlineActivated && (
                  <div className={responsiveClass("grid w-full", "grid-cols-1", "grid-cols-2", "grid-cols-4")}>
                    <DatePicker name="stichtag" label={stichtag} className="w-full" />
                  </div>
                )}
                <div className="flex justify-end w-full">
                  {isSubmitting ? (
                    <LoadingOverlay text="Bitte warten..." />
                  ) : (
                    <>
                      <Button
                        disabled={!disabled}
                        variant="primary"
                        type="submit"
                        className="py-1 px-5"
                        id="submitInputPage"
                      >
                        {search}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </FormControl>
          </form>
        ) : null}
      </div>
    </div>
  );
};

export default TariffInputForm;
