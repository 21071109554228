import { useField, useFormikContext } from "formik";
import React from "react";

import ErrorMessage from "../ErrorMessage";
import Spinner from "../icons/spinner";

const Icon = () => {
  return (
    <div className="pt-1">
      <svg height="20" width="20" viewBox="0 0 20 20">
        <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
      </svg>
    </div>
  );
};

const CloseIcon = () => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  );
};

const GetSelect = ({
  defaultOpen,
  label,
  name,
  options,
  isSearchable,
  isMulti,
  className,
  disabled,
  isLoading,
}) => {
  const [, meta, { setValue, setTouched }] = useField({ name });
  const [showMenu, setShowMenu] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(isMulti ? [] : null);
  const formik = useFormikContext();
  const [searchValue, setSearchValue] = React.useState("");
  const searchRef = React.useRef(null);

  const onChange = React.useCallback(
    async (selectedValue) => {
      if (selectedValue.name) {
        await setValue(selectedValue.name);
      } else await setValue(selectedValue);
    },
    [setValue]
  );

  const handleInputClick = React.useCallback(async () => {
    if (name === "city") {
      await formik.setFieldValue("street", "");
      await formik.setFieldValue("houseNumber", "");
    }

    if (name === "street") {
      await formik.setFieldValue("street", "");
      await formik.setFieldValue("houseNumber", "");
    }

    setShowMenu(!showMenu);
  }, [formik, name, showMenu]);

  const removeOption = React.useCallback(
    (option) => selectedValue?.filter((o) => o.value !== option.value),
    [selectedValue]
  );

  const onTagRemove = React.useCallback(
    async (e, option) => {
      e.stopPropagation();

      const newValue = removeOption(option);

      if (newValue) setSelectedValue(newValue);

      await onChange(newValue);
    },
    [onChange, removeOption]
  );

  const onItemClick = React.useCallback(
    async (option) => {
      let newValue;

      if (isMulti) {
        if (selectedValue)
          if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
            newValue = removeOption(option);
          } else {
            newValue = [...selectedValue, option];
          }
      } else {
        newValue = option;
        setShowMenu(false);
      }

      setSelectedValue(newValue);
      await onChange(newValue);
    },
    [isMulti, onChange, removeOption, selectedValue]
  );

  const handleBlur = React.useCallback(async () => {
    await setTouched(true);
  }, [setTouched]);

  const isSelected = React.useCallback(
    (option) => {
      if (isMulti) {
        if (selectedValue)
          return (
            selectedValue.filter((o) => o.value === option.value).length > 0
          );
      }

      if (!selectedValue) {
        return false;
      }

      return selectedValue?.value === option.value;
    },
    [isMulti, selectedValue]
  );

  const onSearch = React.useCallback((e) => {
    setSearchValue(e.target.value);
  }, []);

  const getDisplay = React.useMemo(() => {
    if (!isMulti) {
      return (
        <div className="text-sm">
          {formik?.values[name]?.label ? formik?.values[name]?.label : formik?.values[name]}
        </div>
      );
    }
    if (isMulti) {
      return (
        <div className="dropdown-tags">
          {selectedValue?.map((option) => (
            <div key={option.value} className="dropdown-tag-item">
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className="dropdown-tag-close"
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
  }, [formik?.values, isMulti, name, onTagRemove, selectedValue]);

  const getOptions = React.useMemo(() => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  }, [options, searchValue]);

  React.useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  React.useEffect(() => {
    if (defaultOpen) {
      setShowMenu(true);
    }
  }, [defaultOpen, disabled]);

  return (
    <div className="flex flex-col w-full">
      <label className="text-xs leading-4 pb-1">{label}</label>
      <div className={`relative w-full ${className}`}>
        <div
          onClick={handleInputClick}
          onBlur={handleBlur}
          className={`${
            disabled ? "bg-gray-light cursor-not-allowed" : ""
          } rounded-full px-2.5 py-2 text-gray-900 bg-input-bg border border-solid border-borderInput flex items-center justify-between appearance-none text-base w-full box-border h-8 border-primary-normal`.trim()}
        >
          <div>{getDisplay}</div>
          <div className="dropdown-tools">
            <div className="dropdown-tool z-100">
              {isLoading ? <Spinner /> : <Icon />}
            </div>
          </div>
        </div>

        {!disabled && showMenu && getOptions.length !==0 && (
          <div
            className={`overflow-auto max-h-[270px] mt-1 w-full border border-solid border-borderInput z-100 bg-input-bg rounded-input absolute top-8 bg-white`}
          >
            {isSearchable && (
              <div className="search-box z-100">
                <input
                  onChange={onSearch}
                  value={searchValue}
                  ref={searchRef}
                />
              </div>
            )}
            {getOptions.map((option) => (
              <div
                onClick={() => onItemClick(option)}
                key={option.value}
                className={`cursor-pointer p-2 hover:bg-button-primary-bg-light ${isSelected(option) && "bg-button-primary-bg text-white"}`}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
        {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
      </div>
    </div>
  );
};

export default GetSelect;
