import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useRadioClassName from "../../hooks/useRadioClassName";
const MeterType: React.FC = () => {
  const { oneTariffLabel, twoTariffsLabel, selectTariffLabel } = useFeatureInputPage();
  const radioClassName = useRadioClassName();

  return (
    <div>
      <div className="mb-2 text-md w-full">{selectTariffLabel}</div>
      <div className={radioClassName}>
        <FormikRadio label={oneTariffLabel} name="meterType" value="1,2" className="m-0 accent-button-primary-bg" />
        <FormikRadio label={twoTariffsLabel} name="meterType" value="3,4" className="m-0 accent-button-primary-bg" />
      </div>
    </div>
  );
};

export default MeterType;
