import { useQuery } from "react-query";

import toast from "../components/Toast";
import ConfigInstance from "../services/config";

const QUERY_KEY = "GET_FRONTEND_KEY";

const useFrontendConfig = () => {
  return useQuery(
    QUERY_KEY,
    async () => {
      try {
        const { data } = await ConfigInstance("/config/feconfig");

        return data.data;
      } catch (e) {
        toast.error("Config: Konfigurationsdatei fehlerhaft");
      }
    },
    { refetchOnWindowFocus: false }
  );
};

export default useFrontendConfig;
