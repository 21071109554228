import { useQuery } from "react-query";

import { useConfig } from "../context/Config";
import ProxyInstance from "../services/proxy";
import { ValidPhoneProxy } from "../types/ValidationPhone";

const usePhoneNumberService = (phoneNumber?: string | null) => {
  const { config } = useConfig();

  return useQuery(`GET_VALID_PHONE_NUMBER_${phoneNumber}`, async () => {
    try {
      if (phoneNumber == null) return null;

      const data = (await ProxyInstance(`/validation/phone/valid?value=${phoneNumber}`, {
        apikey: config.apikey || "",
      })) as ValidPhoneProxy;

      return data.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  });
};

export default usePhoneNumberService;
