import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import TelcoGridConnection from "../../types/TelcoGridConnection";


interface selectedTelcoGridConnectionContext {
    selectedTelcoGridConnection: TelcoGridConnection;
    setSelectedTelcoGridConnection: React.Dispatch<React.SetStateAction<TelcoGridConnection>>;
}

const TelcoGridConnectionContext = createContext<selectedTelcoGridConnectionContext>({} as selectedTelcoGridConnectionContext);

const TelcoGridConnectionProvider = ({ children }: {children: React.ReactNode}) => {
    const [selectedTelcoGridConnection, setSelectedTelcoGridConnection] = React.useState({} as TelcoGridConnection);

    return (
        <TelcoGridConnectionContext.Provider value={{ selectedTelcoGridConnection, setSelectedTelcoGridConnection }}>
            {children}
        </TelcoGridConnectionContext.Provider>
    );
};

export default TelcoGridConnectionProvider;
export const useSelectTelcoGridConnection = () =>
    useContextSelector(TelcoGridConnectionContext, (c) => c);
