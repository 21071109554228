import { isAfter } from "date-fns";
import differenceInYears from "date-fns/fp/differenceInYears";
// import subYears from "date-fns/subYears";
import { boolean, object, string } from "yup";


const validationSchema = object({
  PLZ: string()
    .min(5, "PLZ muss mindestens 5 Stelle sein!")
    .required("PLZ ist ungültig"),
  Vorname: string().required("Bitte geben Sie Ihr Vorname ein!"),
  Name: string().required("Bitte geben Sie Ihr Name ein!"),
  Strasse: string().required("Bitte geben Sie Ihre Strasse ein!"),
  Hausnummer: string().required("Bitte geben Sie Ihre Hausnummer ein!"),
  Kundennummer: string().required("Bitte geben Sie Ihre Kundennummer ein!"),
  Grund:     object()
    .shape({
      label: string().required(),
      value: string().required(),
    })
    .required("Bitte wählen Sie Ihren Grund aus!"),
  Versorger: object()
    .shape({
      label: string().required(),
      value: string().required(),
    })
    .required(),
  Ort: string().required("Bitte geben Sie Ihren Ort ein!"),
  birthday: string()
    .test("Geburtsdatum", "bitte geben Sie Ihr Geburtsdatum ein!", (value) => {
      return Boolean(value);
    })
    .test("Geburtsdatum", "Sie müssen volljährig sein!", (value) => {
      if (!value) return false;
      const [day, month, year] = value.split('.').map(Number);
      return differenceInYears(new Date(year, month-1, day), new Date()) >= 18;

    }),
  isPrivacyPolicyCheck: boolean().oneOf(
    [true],
    "bitte bestätigen Sie unsere Datenschutzsbestimungen!"
  ),
  desiredCancellationDate: string().test(
    "desiredDeliveryDate",
    "Bitte geben Sie ein zukünftiges Datum ein!",
    (value) => {
      if (!value) return false;

      const currentDate = new Date(value.split(".").reverse().join("-"));

      const today = new Date();

      return isAfter(currentDate, today);
    }
  ),
});

export default validationSchema;
