import { useField } from "formik";
import * as React from "react";
import Combobox from "react-widgets/Combobox";
import DropdownList from "react-widgets/DropdownList";

import ErrorMessage from "../ErrorMessage";
import LabelForm from "../LabelForm";
const Select = ({ label, name, onChange, onSelect, autoComplete, data, hideEmptyPopup, ...restProps }) => {
  const [fields, meta, { setValue }] = useField({ name });
  const [open, setOpen] = React.useState(true);
  const handleEvent = React.useCallback(
    (value) => {
      if (value.name) {
        setValue(value.name);
      } else setValue(value);
    },
    [setValue]
  );

  const handleSelect = React.useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  const handleToggle = React.useCallback(() => {
    setOpen(true);
  }, []);

  React.useEffect(() => {
    if (data?.length === 1) {
      setValue(data[0]);
      setOpen(false);
    } else {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <LabelForm label={label}>
      {label}
      {autoComplete ? (
        <Combobox
          {...fields}
          {...restProps}
          data={data}
          open={open}
          focusFirstItem
          hideEmptyPopup
          filter={(item, searchTerm) => {
            if (typeof item === "string") return item.toLowerCase().includes(searchTerm);
            return false;
          }}
          onSelect={onSelect || handleSelect}
          onChange={onChange || handleEvent}
          onToggle={handleToggle}
        />
      ) : (
        <DropdownList
          {...fields}
          {...restProps}
          data={data}
          filter="contains"
          placeholder=""
          containerClassName="!rounded-full !border-borderInput"
          onSelect={onSelect || handleSelect}
          onChange={onChange || handleEvent}
          onToggle={handleToggle}
        />
      )}

      {meta.error && <ErrorMessage text={meta.error} />}
    </LabelForm>
  );
};

export default Select;
