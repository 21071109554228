import { Formik, useFormikContext } from "formik";
import React from "react";
import { createContext, useContextSelector } from "use-context-selector";
import Modal from "../../../../components/Modal";
import { FormValues } from "../../../../types/FormValues";
import EditDataModal from "../../components/EditDataModal";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import { useValidation } from "../../validation/validation";

const showEditDataModalContext = createContext(
  {} as {
    showEditDataModal: string | false;
    setShowEditDataModal: React.Dispatch<React.SetStateAction<string | false>>;
  }
);

const EditDataModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [showEditDataModal, setShowEditDataModal] = React.useState<string | false>(false);
  const { setValues, values } = useFormikContext<FormValues>();
  const setOuterFormikValues = (values: FormValues) => {
    setValues(values);
  };
  const {
    moveInDateDaysInPast,
    moveInDateMonthsInFuture,
    requestedDesiredDateDaysInAdvanceEnergy,
    requestedDesiredDateDaysMax,
    switchAccountLandHolder,
  } = useFeaturesCC();
  return (
    <showEditDataModalContext.Provider value={{ showEditDataModal, setShowEditDataModal }}>
      <Modal visible={showEditDataModal} setVisible={setShowEditDataModal} showCancelIcon={true}>
        <Formik
          initialValues={values}
          validationSchema={useValidation(
            moveInDateDaysInPast,
            moveInDateMonthsInFuture,
            requestedDesiredDateDaysInAdvanceEnergy,
            requestedDesiredDateDaysMax,
            switchAccountLandHolder
          )}
          component={() => <EditDataModal modalVisible={showEditDataModal} setModalVisible={setShowEditDataModal} />}
          onSubmit={async (values) => {
            setOuterFormikValues(values);
          }}
        />
      </Modal>
      {children}
    </showEditDataModalContext.Provider>
  );
};

export default EditDataModalProvider;
export const useEditDataModalContext = () => useContextSelector(showEditDataModalContext, (c) => c);
