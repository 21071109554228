import { motion } from "framer-motion";
import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import NoResult from "../../../../components/NoResult";
import { useParameter } from "../../../../context";
import useMedia from "../../../../hooks/useMedia";
import usePaginate from "../../../../hooks/usePaginate";
import List from "../TelcoResults/List";

const ProductView = ({ data, handleSelectTariff }) => {
  const { paginate, hasPrev, hasNext, carouselRef } = usePaginate(data.length);
  const className = useMedia();
  const { productView } = useParameter();

  return (
    <section className="py-4">
      {data.length === 0 ? (
        <NoResult />
      ) : (
        <>
          {productView === "carousel" ? (
            <div className="flex gap-4">
              <div className="flex items-center justify-center overflow-hidden">
                {data.length <= 3 ? null : (
                  <div
                    onClick={() => paginate("prev")}
                    className="w-[40px] h-[40px] cursor-pointer rounded-lg"
                    onMouseDown={() => paginate("prev")}
                    role="button"
                    tabIndex={0}
                  >
                    <FiChevronLeft
                      className="w-[30px] h-[30px] rounded-lg bg-gray-300"
                      stroke={hasPrev ? "black" : "black"}
                    />
                  </div>
                )}
              </div>

              <motion.div
                ref={carouselRef}
                className="hide-scrollbar w-full overflow-x-scroll"
              >
                <motion.div ref={carouselRef} className="flex w-full gap-4">
                  {data.map((list) => (
                    <List
                      key={list.id}
                      onSelect={handleSelectTariff}
                      list={list}
                    />
                  ))}
                </motion.div>
              </motion.div>

              <div className="flex items-center justify-center overflow-hidden">
                {data.length <= 3 ? null : (
                  <div
                    onClick={() => paginate("next")}
                    onMouseDown={() => paginate("next")}
                    className="w-[40px] h-[40px] cursor-pointer rounded-lg"
                    role="button"
                    tabIndex={0}
                  >
                    <FiChevronRight
                      className="w-[30px] h-[30px] rounded-lg bg-gray-300"
                      stroke={hasNext ? "black" : "black"}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={className}>
              {data.map((list) => (
                <List key={list.id} onSelect={handleSelectTariff} list={list} />
              ))}
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default ProductView;
