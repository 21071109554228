import { useField, useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Checkbox from "../../../../components/Checkbox";
import FormControl from "../../../../components/FormControl";
import FormRow from "../../../../components/FormRow";
import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import { SALUTATION_LIST, TITLE_LIST } from "../../../../Constant";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useTRInputContext } from "../../../../context/FormInputData";
import {
  addressDataValidateKeys,
  gee_acceptPrivacyPolicy,
  kenntnisnahme_ausfuehrung_vor_widerrufsfrist,
} from "../../Constant";
import { useContractConclusionFormName } from "../../context";
import useCheckFormValidationAndRedirect from "../../hooks/useCheckFormValidationAndRedirect";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useGetPolicyDocument from "../../hooks/useGetPolicyDocument";
import useQueryStreet from "../../hooks/useQueryStreet";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import Contact from "../ContactData/Contact";
import DigitalSignature from "../DigitalSignature";
import FooterButtons from "../FooterButtons";

const NEXT_STEP = "preSupplierForm";
const PREV_STEP = "addressForm";

const GeeData = () => {
  const { values } = useFormikContext();
  const { setFormName } = useContractConclusionFormName();
  const { calcParams } = useCalcParams();
  const [{ value }] = useField("ownerSameAsCustomer");
  const inputContext = useTRInputContext();

  const { mutateAsync, data } = useServerValidationForm("addressForm", {
    tarifParameter: calcParams,
    keysToValidate: addressDataValidateKeys,
  });

  const {
    salutationOptions,
    salutationLabel,
    titleLabel,
    firstNameLabel,
    lastNameLabel,
    companyNameLabel,
    birthdayLabel,
    contactTitleLabel,
    zipCodeLabel,
    cityLabel,
    streetLabel,
    houseNumberLabel,
    additionLabel,
  } = useFeaturesCC();
  const { data: res } = useGetPolicyDocument();
  const { data: listStreet } = useQueryStreet(
    {
      plz: inputContext.values?.zipCode || "",
      ort: inputContext.values?.city.value || "",
    },
    [inputContext.values?.street.value || ""]
  );

  const einwilligung = React.useMemo(() => {
    return (
      <>
        Ich gestatte mittels{" "}
        <a href={res?.data?.privacyUrl}>Gestattungserklärung</a> gegenüber UGG
        die notwendigen Maßnahmen zur Errichtung und dauerhaftem Betrieb der
        erforderlichen Netzanlagen. *
      </>
    );
  }, [res?.data?.privacyUrl]);

  useCheckFormValidationAndRedirect(data, NEXT_STEP);

  return (
    <FormControl>
      <div className="grid grid-cols-3 gap-4 mb-4 items-center">
        <Checkbox
          name="ownerSameAsCustomer"
          label="Eigentümer identisch mit Kunde"
        />
        <Button
          type="submit"
          className="col-start-3"
          name="geeSkipButton"
          onClick={() => setFormName(NEXT_STEP)}
        >
          weiter ohne GEE
        </Button>
      </div>

      {!value && (
        <>
          <div className="text-lg font-bold">{contactTitleLabel}</div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <Input
              name="gee_companyName"
              label={companyNameLabel}
              placeholder="Firma"
              inputType="firma"
            />
          </div>
          <FormRow addClassName="flex flex-col sm:flex-row gap-4">
            <GetSelect
              name="gee_salutation"
              label={salutationLabel}
              placeHolder="Anrede"
              options={salutationOptions || SALUTATION_LIST}
              className=""
            />
            <GetSelect
              name="gee_title"
              options={TITLE_LIST}
              label={titleLabel}
              placeHolder="Titel"
            />
          </FormRow>
          <FormRow addClassName="flex flex-col sm:flex-row">
            <div className="mb-4 sm:mb-0 mr-0 sm:mr-4 sm:w-1/2 w-full">
              <Input
                name="gee_firstName"
                label={firstNameLabel}
                placeholder="Vorname"
                inputType="name"
              />
            </div>
            <div className="sm:w-1/2 w-full">
              <Input
                name="gee_lastName"
                label={lastNameLabel}
                placeholder="Nachname"
                inputType="name"
              />
            </div>
          </FormRow>
          <h4 className="mb-2 font-bold text-lg">Geburtsdatum *</h4>
          <div className="grid grid-cols-2 gap-4 my-4">
            <Input
              type="datepicker"
              name="gee_birthday"
              className="w-full"
              label={birthdayLabel}
            />
          </div>

          <div className="text-lg font-bold mb-4">Anschrift</div>
          <FormRow addClassName="flex flex-col sm:flex-row gap-4">
            <div className=" sm:w-1/4 w-full">
              <Input
                name="gee_zipCode"
                label={zipCodeLabel}
                placeholder="PLZ"
                disabled
              />
            </div>
            <div className="sm:w-3/4 w-full">
              <Input
                name="gee_city"
                label={cityLabel}
                placeholder="Ort"
                disabled
              />
            </div>
          </FormRow>
          <div className="my-4 grid sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="w-full">
              {inputContext.values?.street ? (
                <Input
                  name="gee_street"
                  label={streetLabel}
                  placeholder="Straße"
                  disabled
                />
              ) : (
                <GetSelect
                  label={streetLabel}
                  name="gee_street"
                  options={listStreet || []}
                  isSearchable
                />
              )}
            </div>
            <div className="flex w-full gap-4">
              <div className="sm:w-1/2 w-full">
                <Input
                  name="gee_houseNumber"
                  label={houseNumberLabel}
                  placeholder="Hausnummer"
                  disabled={!!inputContext.values?.houseNumber}
                  inputType="number"
                />
              </div>
              <div className="sm:w-1/2 w-full">
                <Input
                  name="gee_additionalAddress"
                  label={additionLabel}
                  placeholder="Adresszusatz"
                  inputType="address"
                />
              </div>
            </div>
          </div>

          <Contact />
        </>
      )}
      <Card className="mb-4 p-">
        <div className="font-bold	text-lg">Optins</div>
        <div className="grid my-4 gap-y-4">
          <Checkbox name="kenntnisnahme_gee" labelComponent={einwilligung} />
          <Checkbox
            name="kenntnisnahme_ausfuehrung_vor_widerrufsfrist"
            label={kenntnisnahme_ausfuehrung_vor_widerrufsfrist}
          />
          <Checkbox
            name="gee_acceptPrivacyPolicy"
            label={gee_acceptPrivacyPolicy}
          />
        </div>
        <div className="my-2">
          Ich bin mit der Kontaktaufnahme widerruflich einverstanden, um
          werbliche Informationen zu einem Glasfaseranschluss, zu
          Internetdiensten und zu diesbezüglichen Veranstaltungen und
          Dienstleistungen von Unsere Grüne Glasfaser GmbH & Co. KG und ihren
          Internetdienste-Partnern zu erhalten. Zu diesem Zweck darf Unsere
          Grüne Glasfaser mich über die von mir gewählten Kommunikationsmittel
          (bitte ankreuzen) kontaktieren:
        </div>
        <div className="grid gap-y-4">
          <Checkbox name="werbeeinwilligung_fax" label="per Fax" />
          <Checkbox name="werbeeinwilligung_telefon" label="per Telefon" />
          <Checkbox name="werbeeinwilligung_email" label="per E-Mail" />
        </div>
      </Card>

      <Card className="mb-4 p-">
        <div className="font-bold	text-lg">Ihre Unterschrift *</div>
        <DigitalSignature name="gee_digitalSignature" />
      </Card>

      <FooterButtons
        onSubmitValidation={async () => {
          await mutateAsync(values);
        }}
        onSubmit={() => {
          setFormName(NEXT_STEP);
        }}
        onBack={() => setFormName(PREV_STEP)}
      />
    </FormControl>
  );
};

export default GeeData;
