import { useQuery } from "react-query";

import { useConfig, useTariff } from "../context";
import ProxyInstance from "../services/proxy";

const useExternalConfigs = (pageParam) => {
  const config = useConfig();
  const tariff = useTariff();

  return useQuery(
    `GET_EXTERNAL_LABELS_${tariff.id}_${pageParam}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/view?calculatorType=${tariff.id}&page=${pageParam}`,
          { apikey: config.apikey || "" }
        );

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!config.apikey,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useExternalConfigs;
