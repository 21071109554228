import { useField, useFormikContext } from "formik";
import React from "react";

const TRRadio = ({ name, label, value, ...props }: { name: string; label: string; value: string }) => {
  const [fields, , { setValue }] = useField({ name });
  const { setFieldValue } = useFormikContext();

  const handleOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // clear all fields if value change
      const nameFields = [
        "workingPriceBrutto",
        "workingPriceNTBrutto",
        "basePriceBrutto",
        "workingPriceNetto",
        "workingPriceNTNetto",
        "basePriceNetto",
      ];

      nameFields.forEach((field) => {
        setFieldValue(field, "");
      });

      if (fields.name === "compareType") {
        const nameFields = ["supplier", "tariffComparison"];
        nameFields.forEach((field) => {
          setFieldValue(field, "");
        });
      }

      setValue(e.target.value);
    },
    [fields.name, setFieldValue, setValue]
  );

  return (
    <label className="flex align-middle">
      <input
        {...props}
        value={value}
        type="radio"
        className="accent-primary cursor-pointer"
        onChange={handleOnChange}
        checked={value === fields.value}
      />
      <span className="mr-2 cursor-pointer">{label}</span>
    </label>
  );
};

export default TRRadio;
