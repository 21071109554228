import React, { useEffect, useState } from "react";

import HappyHippo from "../icons/HappyHippo";

const LoadingOverlay = ({
  text,
  startTime,
  progressBarEnabled = false,
}: {
  text?: string;
  startTime?: number;
  progressBarEnabled?: boolean;
}) => {
  const [progressBar, setProgressBar] = useState(true && progressBarEnabled);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (progressBar) {
      timeout = setTimeout(() => {
        setProgressBar(false);
      }, 5000);
    }
    return () => (progressBar ? clearTimeout(timeout) : undefined);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (progressBar) {
    return (
      <div className="w-full h-full absolute top-0 left-0 bg-content-bg text-content-text opacity-75 z-50">
        <div className="flex justify-center flex-col w-full items-center h-screen gap-2">
          {startTime && Date.now() - startTime < 30000 && (
            <>
              <HappyHippo />
              <div className="text-primary font-medium text-xl">
                {Math.round((Date.now() - startTime) / 1000)} Sekunden!
              </div>
              <div>
                Herzlichen Glückwunsch: Du hast die Abschlussstrecke in {Math.round((Date.now() - startTime) / 1000)}{" "}
                Sekunden abgeschlossen!
              </div>
            </>
          )}
          <div className="bg-secondary rounded-2xl">
            <div className="w-[70vw] h-[50px] border-solid border-primary animate-progress bg-[length:0%] bg-[linear-gradient(var(--get-c-primary)_0px,var(--get-c-primary))] bg-no-repeat rounded-2xl"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full absolute top-0 left-0 bg-content-bg text-content-text opacity-75 z-50">
        <div className="flex justify-center items-center mt-[50vh]">
          <div className="border-primary border-solid h-5 w-5 animate-spin rounded-full border-4 border-t-primary-hover" />
          <div className="ml-2">{text}</div>
        </div>
      </div>
    );
  }
};

export default LoadingOverlay;
