import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --get-c-primary: #ffb74d;
    --get-c-primary-hover: #ff9800;
    --get-c-primary-text: #ffffff;

    --get-c-secondary: #4d4d4d;
    --get-c-secondary-light: #666666;
    --get-c-secondary-hover: #333333;
    --get-c-secondary-text: #ffffff;

    --get-c-content-bg: #121212;
    --get-c-content-text: #ffffff;
    --get-c-content-gray: #b3b3b3;
    --get-c-content-gray-light: #333333;
    --get-c-content-gray-dark: #666666;

    --get-c-statistics-1: #3966aa;
    --get-c-statistics-2: #f18307;
    --get-c-statistics-3: #ffab00;
  }
`;
export default myStyle;
