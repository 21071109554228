import * as React from "react";

const Info = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path
          d="M13 6.5C12.6317 6.5 12.3229 6.62458 12.0738 6.87375C11.8246 7.12292 11.7 7.43167 11.7 7.8C11.7 8.16833 11.8246 8.47708 12.0738 8.72625C12.3229 8.97541 12.6317 9.1 13 9.1C13.3683 9.1 13.6771 8.97542 13.9263 8.72625C14.1754 8.47708 14.3 8.16833 14.3 7.8C14.3 7.43167 14.1754 7.12292 13.9263 6.87375C13.6771 6.62458 13.3683 6.5 13 6.5ZM14.3 11.7L11.7 11.7L11.7 19.5L14.3 19.5L14.3 11.7ZM13 -1.1365e-06C14.7983 -9.79281e-07 16.4883 0.341249 18.07 1.02375C19.6517 1.70625 21.0275 2.6325 22.1975 3.8025C23.3675 4.9725 24.2938 6.34833 24.9763 7.93C25.6588 9.51167 26 11.2017 26 13C26 14.7983 25.6588 16.4883 24.9763 18.07C24.2938 19.6517 23.3675 21.0275 22.1975 22.1975C21.0275 23.3675 19.6517 24.2937 18.07 24.9762C16.4883 25.6587 14.7983 26 13 26C11.2017 26 9.51167 25.6587 7.93 24.9762C6.34833 24.2937 4.9725 23.3675 3.8025 22.1975C2.6325 21.0275 1.70625 19.6517 1.02375 18.07C0.34125 16.4883 9.79281e-07 14.7983 1.1365e-06 13C1.29371e-06 11.2017 0.341251 9.51167 1.02375 7.93C1.70625 6.34833 2.6325 4.9725 3.8025 3.8025C4.9725 2.6325 6.34834 1.70625 7.93 1.02375C9.51167 0.341248 11.2017 -1.29371e-06 13 -1.1365e-06Z"
          fill="#3B82F6"
        />
      </svg>
    </>
  );
};

export default Info;
