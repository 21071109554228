import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useRadioClassName from "../../hooks/useRadioClassName";
const TariffType: React.FC = () => {
  const { tarifTyp, waermepumpe, speicherheizung } = useFeatureInputPage();
  const radioClassName = useRadioClassName();

  return (
    <div>
      <div className="mb-1 text-xs w-full">{tarifTyp}</div>
      <div className={radioClassName}>
        <FormikRadio label={waermepumpe} name="tariffType" value="3" className="m-0 accent-primary" />
        <FormikRadio label={speicherheizung} name="tariffType" value="4" className="m-0 accent-primary" />
      </div>
    </div>
  );
};

export default TariffType;
