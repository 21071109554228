import { useFormikContext } from "formik";
import React from "react";

import FormRow from "../../../../components/FormRow";
import WarningIcon from "../../../../components/icons/warning";
import Input from "../../../../components/Input";
import { FormValues } from "../../../../types/FormValues";
import isValidEmail from "../../../../utils/isValidEmail";
import useFeaturesCC from "../../hooks/useFeaturesCC";



const Contact = () => {
  const { isRepeatMail, showFax, emailValidateLabel, emailLabel, mobileLabel, faxLabel, phoneLabel, emailIsIncorrect, emailRepeatIsIncorrect } =
    useFeaturesCC();
  const { values } = useFormikContext<FormValues>();

  return (
    <>
      <div className="mt-6 mb-7 text-lg font-bold">Kontakt</div>
      <FormRow>
        <Input name="email" label={emailLabel} placeholder="E-Mail" />
      </FormRow>
      {!isValidEmail(values.email) && values.email.length>0 && <div className="flex flex-row items-center mb-2 w-full"><WarningIcon fill={"red"} className="mr-3"/><small className="text-red-500">{emailIsIncorrect}</small></div>}
      {isRepeatMail && (
        <FormRow>
          <Input name="confirmationEmail" label={emailValidateLabel} placeholder="E-Mail-Adresse bestätigen" />
        </FormRow>
      )}
      {values.email !== values.confirmationEmail && values.confirmationEmail.length>0 && <div className="flex flex-row items-center mb-2 w-full"><WarningIcon fill={"red"} className="mr-3"/><small className="text-red-500">{emailRepeatIsIncorrect}</small></div>}
      <FormRow addClassName="flex gap-4">
        <Input name="mobileNumber" label={mobileLabel} placeholder="Mobilfunknummer" onlyNumber maxLength={16} />
        <Input name="phoneNumber" label={phoneLabel} placeholder="Telefonnummer" onlyNumber maxLength={16} />
      </FormRow>
      {showFax && (
        <FormRow>
          <Input name="faxNumber" label={faxLabel} placeholder="Faxnummer" onlyNumber maxLength={16} />
        </FormRow>
      )}
    </>
  );
};

export default Contact;
