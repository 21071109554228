import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useRadioClassName from "../../hooks/useRadioClassName";

const MeasurementType: React.FC = () => {
  const { gemeinsameMessungLabel, getrennteMessungLabel, measurementLabel } = useFeatureInputPage();
  const radioClassName = useRadioClassName();

  return (
    <div>
      <div className="mb-3 text-md w-full">{measurementLabel}</div>
      <div className={radioClassName}>
        <FormikRadio
          label={gemeinsameMessungLabel}
          name="measurementType"
          value="0"
          className="m-0 accent-button-primary-bg"
        />
        <FormikRadio
          label={getrennteMessungLabel}
          name="measurementType"
          value="1"
          className="m-0 accent-button-primary-bg"
        />
      </div>
    </div>
  );
};

export default MeasurementType;
