import { FormikProps, useField, useFormikContext } from "formik";
import React, { memo, useState } from "react";

import { useConfig } from "../../context/Config";
import { FormValues } from "../../types/FormValues";
import ErrorMessage from "../ErrorMessage";
import Chevron from "../icons/Chevron";
import Spinner from "../icons/spinner";
import ScrollBox from "../ScrollBox";
import { GetSelectAttributes, Option } from "./types";

const GetSelect = memo(function GetSelect({
  defaultOpen = false,
  label,
  name,
  options = [],
  isSearchable = false,
  className,
  disabled,
  isLoading

}: GetSelectAttributes) {
  const [field, meta, { setValue, setTouched }] = useField({ name });
  const [showMenu, setShowMenu] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<Option | null>(null);
  const { setFieldValue } = useFormikContext<FormikProps<FormValues>>();
  const [searchValue, setSearchValue] = React.useState("");
  const searchRef = React.useRef<HTMLInputElement>(null);
  const { config } = useConfig();
  const getSelectRef = React.useRef<HTMLDivElement>(null);
  const optionsRef = React.useRef<HTMLDivElement>(null);
  const [openTop, setOpenTop] = useState(false);
  React.useEffect(() => {
    if (getSelectRef?.current) {
      let neededSpace = 400;
      if (options?.length) {
        neededSpace = options?.length * 40 + 60 > 400 ? 400 : options?.length * 40 + 60;
      }
      if (document.documentElement.scrollHeight < getSelectRef.current?.offsetTop + neededSpace !== openTop) {
        setOpenTop(document.documentElement.scrollHeight < getSelectRef.current?.offsetTop + neededSpace);
      }

    }
  }, [options, openTop]);

  const handleInputClick = React.useCallback(async () => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const onItemClick = React.useCallback(
    async (option: Option) => {
      if (name === "city") {
        await setFieldValue("street", { value: "", label: "" });
        await setFieldValue("houseNumber", "");
      }
      if (name === "billingCity") {
        await setFieldValue("billingStreet", { value: "", label: "" });
        await setFieldValue("billingHouseNumber", "");
      }
      if (name === "street") {
        await setFieldValue("houseNumber", "");
      }
      if (name === "billingStreet") {
        await setFieldValue("billingHouseNumber", "");
      }
      if (name === "supplier") {
        await setFieldValue("tariffComparison", { value: "", label: "" });
      }
      if (name === "tariffComparison") {
        setFieldValue("tariffComparisonID", option?.tarifID);
      }

      setShowMenu(false);
      setSelectedValue(option);
      await setValue(option);
    },
    [setValue, setShowMenu, setFieldValue, name]
  );

  const handleBlur = React.useCallback(async () => {
    await setTouched(true);
  }, [setTouched]);

  const isSelected = React.useCallback(
    (option: Option) => {
      if (!selectedValue) {
        return false;
      }

      return selectedValue?.value === option.value;
    },
    [selectedValue]
  );

  const onSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  }, []);

  const getDisplay = React.useMemo(() => {
    return <div>{field.value?.label ?? field.value}</div>;
  }, [field.value]);

  const getOptions = React.useMemo(() => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) => option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
  }, [options, searchValue]);

  const handleClick = (event: MouseEvent) => {
    if (
      getSelectRef.current != null &&
      !getSelectRef.current.contains(event.target as Node) &&
      optionsRef.current != null &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setShowMenu(false);
    }
  };

  React.useEffect(() => {
    config.reference.current != null
      ? config.reference.current.addEventListener("mousedown", (event: MouseEvent) => handleClick(event))
      : () => {};
    return config.reference.current != null
      ? config.reference.current.removeEventListener("mousedown", (event: MouseEvent) => handleClick(event))
      : () => {};
  }, [config.reference]);

  React.useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  React.useEffect(() => {
    if (defaultOpen && options.length > 1) {
      setShowMenu(true);
    }
  }, [defaultOpen, disabled, options.length]);

  React.useEffect(() => {
    if (options?.length === 1 && JSON.stringify(field.value) !== JSON.stringify(options[0])) {
      setSelectedValue(options[0]);
      setValue(options[0]);
      //GETTR-2532
      if (name === "tariffComparison") {
        setFieldValue("tariffComparisonID", options[0].tarifID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <div className="flex flex-col w-full" ref={getSelectRef}>
      <label className="text-xs leading-4 pb-1">{label}</label>
      <div
        className={`relative w-full ${className ?? ""} ${!disabled && getOptions.length !== 0 ? "cursor-pointer" : ""}`}
      >
        <div
          onClick={handleInputClick}
          onBlur={handleBlur}
          className={`${disabled ? "bg-gray-light cursor-not-allowed" : ""} ${
            !disabled && showMenu ? "z-50" : ""
          } bg-content-bg rounded-full px-2.5 py-2 text-content-text outline-1 outline outline-primary flex items-center justify-between appearance-none text-base w-full box-border h-8 border-primary mb-1 relative`.trim()}
          id={name}
        >
          <div className="overflow-hidden whitespace-nowrap">{getDisplay}</div>
          <div className="dropdown-tools">
            <div className="dropdown-tool z-100">
              {isLoading ? <Spinner /> : <Chevron rotate={showMenu && !disabled && getOptions.length !== 0} />}
            </div>
          </div>
        </div>

        {!disabled && showMenu && (getOptions.length !== 0 || searchValue) && (
          <div
            className={`overflow-auto bg-content-bg text-content-text py-4 w-full z-40 absolute rounded-2xl shadow-dropDown hide-scrollbar max-h-[302px] ${
              openTop ? "bottom-9 rounded-b-none" : "top-4 rounded-t-none"
            }`}
            ref={optionsRef}
            id={"listbox_" + name}
          >
            <ScrollBox maxHeight={270}>
              <div className="bg-content-bg text-content-text">
                {isSearchable && options.length >= 10 && (
                  <div className="p-[5px] z-100 mr-3">
                    <input
                      onChange={onSearch}
                      value={searchValue}
                      ref={searchRef}
                      className="w-full box-border p-1 outline-1 outline outline-gray-dark rounded-[5px]"
                      id={"search_" + name}
                    />
                  </div>
                )}
                {getOptions.length !== 0 &&
                  getOptions
                    .sort((a: Option, b: Option) => a.value.localeCompare(b.value,'de',{ numeric: true }))
                    .map((option,index) => (
                      <div
                        onClick={() => onItemClick(option)}
                        key={index}

                        className={`cursor-pointer p-2 min-h-10 ${
                          isSelected(option) ? "bg-secondary hover:bg-secondary" : "hover:bg-secondary-light"
                        }`}
                        id={`${name}_${
                            option?.value !== "" ? option?.value.replace(/[.,#]/g, "").toLowerCase() : "empty"
                        }`}
                      >
                      {option.label}
                      </div>
                    ))}
                {getOptions.length === 0 && searchValue && (
                  <div className="p-2 min-h-10">&bdquo;{searchValue}&rdquo; ergab leider keine Treffer.</div>
                )}
              </div>
            </ScrollBox>
          </div>
        )}
        <div className="h-4">{meta.touched && meta.error && <ErrorMessage text={meta.error} />}</div>
      </div>
    </div>
  );
});

export default GetSelect;
