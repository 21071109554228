import { isValid, parse } from "date-fns";

function isValidDesiredDate(day: string, month: string, year: string) {
  const parsed = parse(`${day}/${month}/${year}`, "dd/MM/yyyy", new Date());

  if (parsed <= new Date()) {
    return false;
  }

  return isValid(parsed) && parsed > new Date();
}

export default isValidDesiredDate;
