import * as React from "react";

const Person = ({ fill = "var(--get-c-breadcrumb)" }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="inline align-text-top"
    >
      <mask id="mask0_775_16175" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_775_16175)">
        <path
          d="M8.50033 7.99984C7.76699 7.99984 7.13921 7.73873 6.61699 7.2165C6.09477 6.69428 5.83366 6.0665 5.83366 5.33317C5.83366 4.59984 6.09477 3.97206 6.61699 3.44984C7.13921 2.92762 7.76699 2.6665 8.50033 2.6665C9.23366 2.6665 9.86144 2.92762 10.3837 3.44984C10.9059 3.97206 11.167 4.59984 11.167 5.33317C11.167 6.0665 10.9059 6.69428 10.3837 7.2165C9.86144 7.73873 9.23366 7.99984 8.50033 7.99984ZM3.16699 13.3332V11.4665C3.16699 11.0887 3.26421 10.7415 3.45866 10.4248C3.6531 10.1082 3.91144 9.8665 4.23366 9.69984C4.92255 9.35539 5.62255 9.09706 6.33366 8.92484C7.04477 8.75261 7.76699 8.6665 8.50033 8.6665C9.23366 8.6665 9.95588 8.75261 10.667 8.92484C11.3781 9.09706 12.0781 9.35539 12.767 9.69984C13.0892 9.8665 13.3475 10.1082 13.542 10.4248C13.7364 10.7415 13.8337 11.0887 13.8337 11.4665V13.3332H3.16699ZM4.50033 11.9998H12.5003V11.4665C12.5003 11.3443 12.4698 11.2332 12.4087 11.1332C12.3475 11.0332 12.267 10.9554 12.167 10.8998C11.567 10.5998 10.9614 10.3748 10.3503 10.2248C9.73921 10.0748 9.12255 9.99984 8.50033 9.99984C7.8781 9.99984 7.26144 10.0748 6.65033 10.2248C6.03921 10.3748 5.43366 10.5998 4.83366 10.8998C4.73366 10.9554 4.6531 11.0332 4.59199 11.1332C4.53088 11.2332 4.50033 11.3443 4.50033 11.4665V11.9998ZM8.50033 6.6665C8.86699 6.6665 9.18088 6.53595 9.44199 6.27484C9.7031 6.01373 9.83366 5.69984 9.83366 5.33317C9.83366 4.9665 9.7031 4.65261 9.44199 4.3915C9.18088 4.13039 8.86699 3.99984 8.50033 3.99984C8.13366 3.99984 7.81977 4.13039 7.55866 4.3915C7.29755 4.65261 7.16699 4.9665 7.16699 5.33317C7.16699 5.69984 7.29755 6.01373 7.55866 6.27484C7.81977 6.53595 8.13366 6.6665 8.50033 6.6665Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Person;
