import React from "react";

import useFeaturesResult from "../../widgets/TCResults/hooks/useFeaturesResult";
import Card from "../Card";

const NoResult = () => {
  const { tryAgainLabel, noListProductLabel } = useFeaturesResult();
  return (
    <Card className="text-center">
      <div className="text-lg font-bold">{noListProductLabel}</div>
      <div>{tryAgainLabel}</div>
    </Card>
  );
};

export default NoResult;
