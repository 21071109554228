import { tariffOptionTypes } from "../context";

export function getTariffType(tariffCalculatorTypeId: tariffOptionTypes) {
  switch (tariffCalculatorTypeId) {
    case "1":
      return "energy";
    case "2":
      return "gas";
    // TODO: add more case
    case "7":
      return "telco";
    default:
      return "energy";
  }
}
