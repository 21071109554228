import * as React from "react";

const TimeFrameSwitch = ({
  timeframeYear,
  setTimeframeYear,
}: {
  timeframeYear: boolean;
  setTimeframeYear: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [animation, setAnimation] = React.useState("");
  return (
    <div className="items-center rounded-md inline-block mt-4" role="group">
      <div
        onClick={() => {
          setAnimation(!timeframeYear ? "animate-toggleRight" : "animate-toggleLeft");
          setTimeframeYear(!timeframeYear);
        }}
        className="grid grid-cols-2 bg-primary rounded-full p-0.5 cursor-pointer text-center relative"
        id="timeFrameSwitch"
      >
        <div className="relative rounded-full py-1 px-2 leading-5">
          <span className={(timeframeYear ? "text-content-text" : "text-primary-text") + " relative z-20 w-[62px]"}>
            Jahr
          </span>
          <div
            className={
              animation + " absolute z-10 h-full w-full bg-content-bg text-content-text top-0 left-0 rounded-full"
            }
          ></div>
        </div>
        <div
          className={
            (!timeframeYear ? "text-content-text" : "text-primary-text") +
            " rounded-full py-1 px-2 leading-5 z-20 w-[62px]"
          }
        >
          Monat
        </div>
      </div>
    </div>
  );
};

export default TimeFrameSwitch;
