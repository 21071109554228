import React from "react";

import OptIns from "./OptIns";
import OrderConfirmation from "./OrderConfirmation";

const Component = () => {
  return (
    <div className="h-full">
      <OrderConfirmation />
      <OptIns />
    </div>
  );
};

const Summary = React.memo(Component);

export default Summary;
