import { useField } from "formik";
import React from "react";

import ErrorMessage from "../ErrorMessage";

interface Checkbox extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  className?: string;
  labelComponent?: undefined | React.ReactNode;
  disabled?: boolean;
  value?: string;
}

const Checkbox = ({
  name,
  label,
  className = "",
  labelComponent = undefined,
  disabled = false,
  value,
  ...props
}: Checkbox) => {
  const [fields, meta, { setValue }] = useField({ name, type: "checkbox" });

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (value) {
        setValue(value);
      } else {
        setValue(e.target.checked);
      }
    },
    [value, setValue]
  );

  return (
    <div className={`${className || ""} w-fit`}>
      <label className="grid grid-cols-[auto_auto] items-start">
        <div
          className={`box-content outline outline-2 outline-primary rounded-sm size-4 inline-block m-px cursor-pointer`}
        >
          <input
            {...fields}
            {...props}
            name={name}
            className={`${className || ""} cursor-pointer appearance-none`.trim()}
            onChange={handleChange}
            type="checkbox"
            checked={typeof fields.value === "boolean" ? fields.value : value === fields.value}
            disabled={disabled ? true : false}
            id={"checkbox_" + name}
          />
          {fields.value && (
            <span className={`absolute bg-primary size-4`}>
              <svg
                className="size-3 m-[2px]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12"
              >
                <path
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            </span>
          )}
        </div>

        <span className={`ml-2 text-sm ${disabled ? "" : "cursor-pointer"} grow`}>
          {(label && label) || (labelComponent && labelComponent)}
        </span>
      </label>
      {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
    </div>
  );
};

export default Checkbox;
