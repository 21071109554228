import * as React from "react";

import Breadcrumb from "../components/Breadcrumb";
import Container from "../components/Container";
import { useStepChange } from "../context";
import { useCalcResult } from "../context/CalculationResult";
import useSubmitFormData from "../hooks/useSubmitFormData";
import TCConfirmation from "./TCConfirmation";
import TCContactForm from "./TCContactForm";
import TCContractConclusion from "./TCContractConclusion";
import TCFormular from "./TCFormular";
import useSubmitCancellationData from "./TCFormular/hooks/useSubmitCancellationData";
import SuccessForm from "./TCFormular/success";
import TCInputs from "./TCInputs";
import TCResults from "./TCResults";

const Index = () => {
  const calcResult = useCalcResult();
  const { mutateAsync: submitForm } = useSubmitFormData();
  const { mutateAsync: sendForm, data: terminationFormData } = useSubmitCancellationData();
  const { step } = useStepChange();

  return (
    <Container>
      {(step === 2 || step === 3) && <Breadcrumb step={step} />}
      {step === 1 && <TCInputs submit={calcResult?.setCalcResult} />}
      {step === 2 && (
        <TCResults
          type={calcResult?.calcResult?.type}
          data={calcResult?.calcResult?.results}
          additionalData={calcResult?.calcResult?.telcoGridConnection}
        />
      )}
      {step === 3 && <TCContractConclusion submit={submitForm} />}
      {step === 4 && <TCConfirmation />}
      {step === 7 && <TCContactForm />}
      {step === 5 && <TCFormular submit={sendForm} />}
      {step === 6 && <SuccessForm data={terminationFormData} />}
    </Container>
  );
};

export default Index;
