function resolveSuggestion(data = []) {
  if (!data) return undefined;

  const result = [];

  for (let i = 0; i < data.length; i++) {
    const cityName = data[i].split(" | ")[0];
    if (!result.includes(cityName)) {
      result.push(cityName);
    }
  }

  return result.map((v) => ({ label: v, value: v }));
}
export default resolveSuggestion;
