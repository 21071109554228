import React from "react";

import DesiredDeliveryDate from "./DesiredDeliveryDate";
import PrevSupplierData from "./PrevSupplierData";

const ExchangeNewTariffForm = () => {
  return (
    <>
      <DesiredDeliveryDate />
      <PrevSupplierData />
    </>
  );
};

export default ExchangeNewTariffForm;
