import { useField, useFormikContext } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import FormControl from "../../../../components/FormControl";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useSelectTelco } from "../../../../context/Telco";
import { uniqueArr } from "../../../../utils/uniqueArr";
import { addressDataValidateKeys } from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import useCheckFormValidationAndRedirect from "../../hooks/useCheckFormValidationAndRedirect";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import FormData from "./FormData";

const AddressData = ({disableFooterButtons = false}) => {
  const { values } = useFormikContext();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { calcParams } = useCalcParams();
  const { selectedTelco } = useSelectTelco();
  const NEXT_STEP = selectedTelco ? "gee" : "preSupplierForm";
  const PREV_STEP = "contactForm";

  const {
    isEnabledBillingDelivery,
    isEnabledInstallationDelivery,
    isEnableInvoiceDelivery,
    isEnableInvoicePost,
    isEnableInvoiceMail,
    advertisingConsentPost,
    advertisingConsentEmail,
    billingSameAsDeliveryLabel,
  } = useFeaturesCC();

  const { mutateAsync, data } = useServerValidationForm("addressForm", {
    tarifParameter: calcParams,
    keysToValidate: addressDataValidateKeys,
  });

  const tariff = useTariff();
  const [{ value }] = useField("deliveryIsSameBillingAddress");
  const [{ value: value2 }] = useField("deliveryIsSameInstallationAddress");

  useSetFieldWithVPPData("addressForm");
  useCheckFormValidationAndRedirect(data, NEXT_STEP);

  return (
    <FormControl>
      <FormData type="deliveryAddress" />

      {isEnabledInstallationDelivery && tariff.id === 7 && (
        <Checkbox
          className="my-4"
          name="deliveryIsSameInstallationAddress"
          label="Lieferanschrift (für FRITZ!Box-Zustellung) identisch mit Installationsanschrift"
        />
      )}

      {isEnabledInstallationDelivery && !value2 && <FormData type="installationAddress" />}

      <Checkbox
        className="my-4"
        name="deliveryIsSameBillingAddress"
        label={billingSameAsDeliveryLabel}
      />

      {!value && <FormData type="billingAddress" />}

      {isEnabledBillingDelivery && (
        <Checkbox className="my-4" name="deliveryIsSameBillingAddress" label={billingSameAsDeliveryLabel} />
      )}

      {isEnabledBillingDelivery && !value && <FormData type="billingAddress" />}

      {isEnableInvoiceDelivery && (
        <div className="flex flex-row gap-5 mb-4">
          <div>Zustellung der Rechnung</div>

          {isEnableInvoicePost && (
            <>
              {typeof advertisingConsentPost === "string" ? (
                <FormikRadio
                  name="invoiceDeliveryBy"
                  label={advertisingConsentPost}
                  value="post"
                />
              ) : (
                <FormikRadio
                  name="invoiceDeliveryBy"
                  label={advertisingConsentPost.label}
                  value={advertisingConsentPost.value}
                />
              )}
            </>
          )}

          {isEnableInvoiceMail && (
            <>
              {typeof advertisingConsentEmail === "string" ? (
                <FormikRadio
                  name="invoiceDeliveryBy"
                  label={advertisingConsentEmail}
                  value="mail"
                />
              ) : (
                <FormikRadio
                  name="invoiceDeliveryBy"
                  label={advertisingConsentEmail.label}
                  value={advertisingConsentEmail.value}
                />
              )}
            </>
          )}
        </div>
      )}

      {!disableFooterButtons && <FooterButtons
        onSubmitValidation={async () => {
          await mutateAsync(values);
        }}
        onSubmit={() => {
          setFormName(NEXT_STEP);
          setActivatedPanels((prevState) => uniqueArr([...prevState, NEXT_STEP]));
        }}
        onBack={() => setFormName(PREV_STEP)}
      />}
    </FormControl>
  );
};

export default AddressData;
