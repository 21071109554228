import { useEffect, useRef, useState } from "react";

const usePaginate = (length) => {
  const carouselRef = useRef(null);
  const [hasNext, setHasNext] = useState(true);
  const [hasPrev, setHasPrev] = useState(false);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const handleScroll = () => {
      if (!carousel) return;

      if (carousel.scrollLeft === 0) {
        setHasPrev(false);
      } else {
        setHasPrev(true);
      }

      if (
        Math.round(carousel.scrollWidth - carousel.offsetWidth || 0) ===
        Math.round(carousel.scrollLeft || 0)
      ) {
        setHasNext(false);
      } else {
        setHasNext(true);
      }
    };

    carousel.addEventListener("scroll", handleScroll);

    return () => {
      carousel.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const paginate = (direction) => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const scrollWidth = (carousel.scrollWidth || 0) / length - 1;

    if (direction === "next") {
      carousel.scrollBy({ left: scrollWidth, behavior: "smooth" });
    } else {
      carousel.scrollBy({ left: -scrollWidth, behavior: "smooth" });
    }
  };

  return { paginate, hasNext, hasPrev, carouselRef };
};

export default usePaginate;
