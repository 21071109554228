import { useQuery } from "react-query";

import { TYPE } from "../../../Constant";
import { useConfig, useTariff } from "../../../context";
import ProxyInstance from "../../../services/proxy";

const getVerbandsNummer = (item) => {
  let vnb = "";

  item.dynamischeAngaben.forEach((f) => {
    if (f.stringObj) {
      f.stringObj.forEach((d) => {
        if (d.name === "verbandsnummer_lieferant") vnb = d.wert;
      });
    }
  });

  return vnb;
};
const useGetSupplier = () => {
  const tariff = useTariff();
  const config = useConfig();

  return useQuery(
    `GET_SUPPLIER_${tariff.tariffType}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/ws/tariff/${
            tariff.tariffType &&
            tariff.tariffType !== "telco" &&
            TYPE[tariff.tariffType]
          }/getVersorger`,
          {
            apikey: config.apikey || "",
            ...(tariff.tariffType &&
            tariff.tariffType !== "telco" &&
            TYPE[tariff.tariffType] === "gas"
              ? {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              : { method: "GET" }),
            ...(tariff.tariffType &&
            tariff.tariffType !== "telco" &&
            TYPE[tariff.tariffType] === "gas"
              ? { body: JSON.stringify({}) }
              : {}),
          }
        );

        const {
          data: { versorger },
        } = data;

        return versorger
          .map((list) => {
            return {
              ...list,
              versorgerName: list.markenName
                ? list.markenName
                : list.versorgerName,
            };
          })
          .sort((a, b) => a.versorgerName.localeCompare(b.versorgerName))
          .map((item) => {
            return {
              label: item.versorgerName,
              value: item.versorgerName,
              key: getVerbandsNummer(item),
            };
          });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetSupplier;
