import * as React from "react";

const FormRow = ({ addClassName, className, children }: {addClassName?: string, className?: string, children: React.ReactNode}) => {
  return (
    <div
      className={
        className
          ? className
          : `w-full mb-4 ${addClassName ? addClassName : ""}`
      }
    >
      {children}
    </div>
  );
};

export default FormRow;
