import React from "react";

import useExternalConfigs from "../../../hooks/useExternalConfigs";

const useFeatureInputPage = () => {
  const { data: config } = useExternalConfigs("inputpage");
  const inputPage = config?.data?.data?.inputPage;

  const showCancelFormBtn = React.useMemo(() => {
    return config?.data?.data?.inputPage?.showCancelationForm;
  }, [config?.data?.data?.inputPage?.showCancelationForm]);

  const usageRuler = React.useMemo(() => {
    return inputPage?.usageRuler ?? false;
  }, [inputPage?.usageRuler]);

  const headerLabel = React.useMemo(() => {
    return config?.data?.data?.inputPage?.header || "Tarifrechner";
  }, [config?.data?.data?.inputPage?.header]);

  const privateLabel = React.useMemo(() => {
    return inputPage?.private || "Privat";
  }, [inputPage?.private]);

  const businessLabel = React.useMemo(() => {
    return inputPage?.business || "Gewerbe";
  }, [inputPage?.business]);

  const oneTariffLabel = React.useMemo(() => {
    return inputPage?.oneTariff || "Eintarif";
  }, [inputPage?.oneTariff]);
  const twoTariffsLabel = React.useMemo(() => {
    return inputPage?.twoTariffs || "Zweitarif";
  }, [inputPage?.twoTariffs]);

  const zipCodeLabel = React.useMemo(
    () => inputPage?.zipCode || "Postleitzahl",
    [inputPage?.zipCode]
  );
  const cityLabel = React.useMemo(
    () => inputPage?.city || "Ort",
    [inputPage?.city]
  );
  const streetLabel = React.useMemo(
    () => inputPage?.street || "Straße",
    [inputPage?.street]
  );
  const houseNumberLabel = React.useMemo(
    () => inputPage?.houseNumber || "Hausnummer",
    [inputPage?.houseNumber]
  );
  const usageLabel = React.useMemo(() => {
    return inputPage?.usage || "kWh/Jahr";
  }, [inputPage?.usage]);

  const onlyEcoElectricityLabel = React.useMemo(() => {
    return inputPage?.onlyEcoElectricity || "";
  }, [inputPage?.onlyEcoElectricity]);

  const onlyEcoGasLabel = React.useMemo(() => {
    return inputPage?.onlyEcoGas || "";
  }, [inputPage?.onlyEcoGas]);

  const searchLabel = React.useMemo(() => {
    return config?.data?.data?.inputPage?.search || "";
  }, [config?.data?.data?.inputPage?.search]);

  const mainTariffLabel = React.useMemo(() => {
    return config?.data?.data?.inputPage?.labelHT;
  }, [config?.data?.data?.inputPage?.labelHT]);

  const tariffNotFoundLabel = React.useMemo(() => {
    return (
      inputPage?.tariffNotFound ||
      "Zur gewählten Örtlichkeit konnten leider keine Tarife für Sie gefunden werden!"
    );
  }, [inputPage?.tariffNotFound]);

  const tarifTypLabel = React.useMemo(() => {
    return inputPage?.tarifTyp || "Tariftyp";
  }, [inputPage?.tarifTyp]);

  const selectTariffLabel = React.useMemo(() => {
    return inputPage?.selectTariff || "Zählerart";
  }, [inputPage?.selectTariff]);

  const measurementLabel = React.useMemo(() => {
    return inputPage?.measurementHeader || "Messung";
  }, [inputPage?.measurementHeader]);

  const customerTypeLabel = React.useMemo(() => {
    return inputPage?.customerType || "Kundenart";
  }, [inputPage?.customerType]);

  const usageInvalidMessageLabel = React.useMemo(() => {
    return inputPage?.usageInvalidMessage || "dieser Wert ist nicht erlaubt";
  }, [inputPage?.usageInvalidMessage]);

  const zipCodeInvalidMessageLabel = React.useMemo(() => {
    return inputPage?.zipCodeInvalidMessage || "Postleitzahl ist ungültig";
  }, [inputPage?.zipCodeInvalidMessage]);

  const zipCodeValidationLabel = React.useMemo(() => {
    return (
      inputPage?.zipCodeValidationText || "PLZ muss mindestens 5 Stellen haben"
    );
  }, [inputPage?.zipCodeValidationText]);

  const labelHeaderPersonNumberLabel = React.useMemo(() => {
    return inputPage?.labelHeaderPersonNumber || "Personen im Haushalt";
  }, [inputPage?.labelHeaderPersonNumber]);

  const electricityLabel = React.useMemo(() => {
    return inputPage?.electricity || "Strom";
  }, [inputPage?.electricity]);

  const gasLabel = React.useMemo(() => {
    return inputPage?.gas || "Gas";
  }, [inputPage?.gas]);

  const waermepumpeLabel = React.useMemo(() => {
    return inputPage?.waermepumpe || "Wärmepumpe";
  }, [inputPage?.waermepumpe]);

  const speicherheizungLabel = React.useMemo(() => {
    return inputPage?.speicherheizung || "Speicherheizung";
  }, [inputPage?.speicherheizung]);

  const heizstromLabel = React.useMemo(() => {
    return inputPage?.heizstrom || "Heizstrom";
  }, [inputPage?.heizstrom]);

  const autostromLabel = React.useMemo(() => {
    return inputPage?.autostrom || "Autostrom";
  }, [inputPage?.autostrom]);

  const telcoLabel = React.useMemo(() => {
    return inputPage?.telco || "Internet";
  }, [inputPage?.telco]);

  const thgLabel = React.useMemo(() => {
    return inputPage?.thg || "THG Prämie";
  }, [inputPage?.thg]);

  const gemeinsameMessungLabel = React.useMemo(() => {
    return inputPage?.gemeinsameMessung || "gemeinsame Messung";
  }, [inputPage?.gemeinsameMessung]);

  const getrennteMessungLabel = React.useMemo(() => {
    return inputPage?.getrennteMessung || "getrennte Messung";
  }, [inputPage?.getrennteMessung]);

  const cancellationFormButtonLabel = React.useMemo(() => {
    return inputPage?.cancellationFormButton || "zum Kündigungsformular";
  }, [inputPage?.cancellationFormButton]);

  const gemeinsameMessungToolTipLabel = React.useMemo(() => {
    return (
      inputPage?.gemeinsameMessungToolTip ||
      "Ihre Wallbox ist am Haushaltszähler angeschlossen oder Sie laden mit der Schuko Steckdose ohne separaten Zähler."
    );
  }, [inputPage?.gemeinsameMessungToolTip]);

  const getrennteMessungToolTipLabel = React.useMemo(() => {
    return (
      inputPage?.getrennteMessungToolTip ||
      "Sie laden Ihr Auto über einen separaten Zähler, zusätzlich zum Haushaltsstromzähler."
    );
  }, [inputPage?.getrennteMessungToolTip]);

  const consumptionLabel = React.useMemo(() => {
    return inputPage?.consumption || "Verbrauch";
  }, [inputPage?.consumption]);

  const tooltippZaehlerartLabel = React.useMemo(() => {
    return (
      inputPage?.tooltippZaehlerart ||
      "Bei einem Eintarifzähler geben Sie bitte nur den Verbrauch (HT) an. Ist bei Ihnen ein Zweitarifzähler verbaut, wird der Verbrauch zu bestimmten Zeiten differenziert erfasst, getrennt nach Tag (Hauptzeit - HT) und Nacht (Nebenzeit - NT)."
    );
  }, [inputPage?.tooltippZaehlerart]);

  const tooltippVerbrauchLabel = React.useMemo(() => {
    return (
      inputPage?.tooltippVerbrauch ||
      "Bei einem Eintarifzähler geben Sie bitte nur den Verbrauch (HT) an. Ist bei Ihnen ein Zweitarifzähler verbaut, wird der Verbrauch zu bestimmten Zeiten differenziert erfasst, getrennt nach Tag  (Hauptzeit - HT) und Nacht (Nebenzeit - NT)."
    );
  }, [inputPage?.tooltippVerbrauch]);

  return {
    headerLabel,
    searchLabel,
    mainTariffLabel,
    tariffNotFoundLabel,
    twoTariffsLabel,
    oneTariffLabel,
    businessLabel,
    privateLabel,
    zipCodeLabel,
    cityLabel,
    houseNumberLabel,
    streetLabel,
    usageLabel,
    onlyEcoElectricityLabel,
    onlyEcoGasLabel,
    cancellationFormButtonLabel,
    measurementLabel,
    zipCodeValidationLabel,
    tarifTypLabel,
    selectTariffLabel,
    customerTypeLabel,
    usageInvalidMessageLabel,
    zipCodeInvalidMessageLabel,
    labelHeaderPersonNumberLabel,
    electricityLabel,
    gasLabel,
    waermepumpeLabel,
    speicherheizungLabel,
    heizstromLabel,
    autostromLabel,
    telcoLabel,
    thgLabel,
    gemeinsameMessungLabel,
    getrennteMessungLabel,
    gemeinsameMessungToolTipLabel,
    getrennteMessungToolTipLabel,
    consumptionLabel,
    tooltippZaehlerartLabel,
    tooltippVerbrauchLabel,
    showCancelFormBtn,
    usageRuler,
  };
};

export default useFeatureInputPage;
