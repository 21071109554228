import { useField } from "formik";
import React from "react";

import Input from "../../../../components/Input";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const DesiredDeliveryDate = () => {
  const [{ value: desiredDeliveryDateValue }] = useField("hasDesiredDeliveryDate");

  const {
    startOfDeliveryLabels,
    desiredDateASAP,
    desiredDateLabel,
  } = useFeaturesCC();

  return (
      <div className="mb-7">
        <div className="font-bold text-lg mb-5">
          {startOfDeliveryLabels?.label || "Gewünschter Bereitstellungstermin (unverbindlich)"}
        </div>

        <>
          {startOfDeliveryLabels?.options ? (
            <div className="grid lg:grid-cols-3 items-start">
              {desiredDateASAP && startOfDeliveryLabels.options.map((opt:{label: string, value: string}) => {
                const value = opt.value === "desiredDate" ? "true" : "false";

                return <FormikRadio key={opt.label} value={value} name="hasDesiredDeliveryDate" label={opt.label} />;
              })}

              {(desiredDeliveryDateValue === "true" || !desiredDateASAP) && (
                <div>
                  <Input name="desiredDeliveryDate" className="w-full" type="datepicker" />
                  <small className="mt-6">
                    Ist die Bereitstellung zum Wunschtermin nicht möglich, dann erfolgt sie zum nächstmöglichen
                    Zeitpunkt nach dem Wunschtermin.
                  </small>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="mb-2">
                <FormikRadio value="false" name="hasDesiredDeliveryDate" label="nächstmöglicher Termin" />
              </div>
              <FormikRadio value="true" name="hasDesiredDeliveryDate" label={desiredDateLabel} className="mb-4" />
            </>
          )}
        </>
      </div>
  );
};

export default DesiredDeliveryDate;
