import { useField } from "formik";
import { useQuery } from "react-query";

import toast from "../../../components/Toast";
import { useConfig } from "../../../context";
import ProxyInstance from "../../../services/proxy";
import useFeaturesCC from "./useFeaturesCC";

const useValidateMaLo = () => {
  const config = useConfig();

  const { MaLoIDNotValidLabel } = useFeaturesCC();

  const [{ value: maLoID }, , { setError }] = useField("maLoID");

  return useQuery(
    `VALIDATE_MALO_${maLoID}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/malo/validate?maloid=${maLoID}`,
          {
            apikey: config.apikey || "",
          }
        );

        const {
          data: {
            data: { result },
          },
        } = data;

        if (!result.isValid) {
          throw new Error(MaLoIDNotValidLabel);
        }

        return result;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!maLoID && maLoID.length === 11,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (err) => {
        if (err instanceof Error) {
          toast.error(`❌ ${err.message}`);
          setError(err.message);
        }
      },
      onSuccess: () => {
        toast.success("✅ Marktlokationsnummer ist gültig");
      },
    }
  );
};

export default useValidateMaLo;
