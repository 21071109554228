import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import GetSelect from "../../../components/GetSelect";
import Input from "../../../components/Input";
import TextArea from "../../../components/TextArea";
import { CUSTOMER_TYPE } from "../../../Constant";
import { useStepChange } from "../../../context";
import { useTRInputContext } from "../../../context/FormInputData";
import useGetPolicyDocument from "../../TCContractConclusion/hooks/useGetPolicyDocument";
import useGetFormData from "./hooks/useGetFormData";

const Form = () => {
  const { data } = useGetFormData();
  const { setStep } = useStepChange();
  const { setFieldValue, handleSubmit } = useFormikContext();
  const { values } = useTRInputContext();
  const { data: res } = useGetPolicyDocument();

  const label = React.useMemo(() => {
    return (
      <>
        Ich habe die aktuellen{" "}
        <a href={res?.data?.privacyUrl}>Datenschutzbestimmungen</a> gelesen und
        akzeptiere diese. *
      </>
    );
  }, [res?.data?.privacyUrl]);

  React.useEffect(() => {
    if (data?.length === 1) {
      setFieldValue("questionForm", {
        label: data[0].label,
        value: data[0].value,
      });
    }
  }, [data, setFieldValue]);

  React.useEffect(() => {
    if (values) {
      if (values.zipCode) {
        setFieldValue("zipCode", values.zipCode);
      }
      if (values.city) {
        setFieldValue("city", values.city);
      }
      if (values.street) {
        setFieldValue("street", values.street);
      }
      if (values.houseNumber) {
        setFieldValue("houseNumber", values.houseNumber);
      }
    }
  }, [setFieldValue, values]);

  return (
    <form onSubmit={handleSubmit}>
      <section className="flex justify-center">
        <div className="container">
          <section className="flex justify-between items-center">
            <div className="text-2xl font-bold">Kontaktformular</div>
            <Button type="button" onClick={() => setStep(1)}>
              X
            </Button>
          </section>
          <section>
            <div className="my-4">
              <GetSelect
                label="Frage *"
                name="questionForm"
                options={data || []}
              />
            </div>
            <div className="my-4">
              <GetSelect
                label="Kundenart *"
                name="customerType"
                options={CUSTOMER_TYPE}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 my-4">
              <Input name="firstName" label="Vorname *" inputType="name"/>
              <Input name="lastName" label="Nachname *" inputType="name"/>
            </div>
            <div className="grid grid-cols-6 gap-4 my-4">
              <Input name="zipCode" disabled label="PLZ *" />
              <div className="col-span-2">
                <GetSelect options={[]} disabled name="city" label="Ort *" />
              </div>
              <div className="col-span-2">
                <GetSelect
                  options={[]}
                  disabled
                  name="street"
                  label="Straße *"
                  isSearchable={true}
                  isMulti={false}
                />
              </div>
              <GetSelect
                options={[]}
                disabled
                name="houseNumber"
                label="Hausnummer *"
              />
            </div>

            <div className="my-4">
              <Input name="phone" label="Telefonnummer *" onlyNumber maxLength={16}/>
            </div>
            <div className="my-4">
              <Input name="email" label="E-Mail *" />
            </div>
            <div className="my-4">
              <TextArea name="message" label="Nachricht *" />
            </div>
          </section>

          <Checkbox name="isAcceptPrivacyPolicy" labelComponent={label} />

          <div className="my-4">
            <small>* Pflichtfelder</small>
          </div>

          <div className="my-4">
            <Button className="w-full" type="submit">
              Anfrage senden
            </Button>
          </div>
        </div>
      </section>
    </form>
  );
};

export default Form;
