import handleGEEValidation from "./accept";
import handleAddressValidation from "./address";
import handleBankValidation from "./bank";
import handleContactValidation from "./contact";
import handlePreSupplierValidation from "./preSupplier";

const handleSummaryValidation = (
  values,
  additionalCondition
) => {
  const contact = handleContactValidation(values, {
    isRepeatMail: additionalCondition?.isRepeatMail,
  });

  const address = handleAddressValidation(values, {
    isTelco: additionalCondition?.isTelco,
  });

  const gee = handleGEEValidation(values, additionalCondition?.isEnabledDigitalSignature);

  const preSupplier = handlePreSupplierValidation(values, {
    isTelco: additionalCondition?.isTelco,
  });

  const bank = handleBankValidation(values);

  const restValidation = contact && address && preSupplier && bank && (!additionalCondition?.isTelco || gee);
  const isSummaryFormValid =
    values.isAGBCheck &&
    values.isCancellationCheck &&
    values.isPrivacyPolicyCheck &&
    !!values.invoiceDeliveryBy;

  if (
    additionalCondition?.isPrePrint &&
    !additionalCondition.isEnabledDigitalSignature
  ) {
    return (restValidation && isSummaryFormValid && !!values.pdfUrl);
  }

  if (
    additionalCondition?.isEnabledDigitalSignature &&
    !additionalCondition?.isPrePrint
  ) {
    return (restValidation &&
      isSummaryFormValid &&
      !!values.signature);
  }

  if (
    additionalCondition?.isPrePrint &&
    additionalCondition?.isEnabledDigitalSignature
  ) {
    return (restValidation &&
      isSummaryFormValid &&
      !!values.signature &&
      !!values.pdfUrl);
  }

  return (restValidation && isSummaryFormValid);
};

export default handleSummaryValidation;
