import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

export interface Telco {
  fullPriceMonthly: string;
  objectId: string;
  oneTimePrice: string;
  productDescriptionPlane: string;
  productDocuments: { fileName: string; uRL: string }[];
  productTypeName: string;
  providerId: string;
  reducedMonthlyPrice: string;
  rootId: string;
  selectedHardware: ProductEntry[];
  selectedOption: ProductEntry[];
  setupCost: number;
  shippingCost: number;
  hardwareGroups: ProductHardware[];
  optionGroups: TelcoProductOption[];
  productName: string;
  productDescription?: string;
  providerName: string;
  contractPeriodDate: string;
  contractExtensionDate: string;
  monthlyPrices: { pricePerMonth: number; to: number; from: number }[];
  landlineInternetDownloadSpeed: string;
  landlineInternetUploadSpeed: string;
  landlineInternetDownloadSpeedMinimal: string;
  landlineInternetDownloadSpeedNormal: string;
  landlineInternetUploadSpeedMinimal: string;
  landlineInternetUploadSpeedNormal: string;
  landlineDataVolumeFlat: boolean;
  landlineDataVolumeLimit: number;
  landlineTelephone: boolean;
  landlineTelephoneCostMinutes: number;
  landlineTelephoneFlatLandline: boolean;
  landlineTelephoneNumbers: number;
  contractPeriodMonths: string;
  contractExtensionMonths: string;
  id: number;
  successorId: number | null;
  version: number;
  productTypeId: number;
  companyId: number;
  companyName: string;
  productCode: string;
  productCodeExtern: string | null;
  validFrom: string;
  validTo: string | null;
  creationUserId: number;
  lastUpdateUserId: number;
  creationDate: string | null;
  lastUpdateDate: string;
  status: {
    id: number;
    name: string;
  };
  statusName: string;
  distributor: null | string;
  productLocation: [];
  customerGroupPrivate: boolean;
  customerGroupCommercial: boolean;
  customerGroupYoungPeople: boolean;
  gridConnectionType: number;
  connectionTypes: string[];
  contractPeriodType: number;
  noticePeriodMonths: number;
  contractExtensionType: number;
  productDescriptionPlain: string;
}
export interface ProductHardware {
  name: string;
  refRootId: number;
  type: number;
  entries: ProductEntry[];
}
export interface ProductEntry {
  refName: string;
  preselected: boolean;
  readonly: boolean;
  refRootId: number;
  code: string;
  description: string;
  documentName: string;
  documentUrl: string;
  imageUrl: string;
  imageName: string;
  monthlyPrices: { pricePerMonth: number; to: number; from: number }[];
  priceOneTime: number;
  shippingCosts: number;
}
export interface TelcoProductOption {
  name: string;
  refRootId: number;
  entries: ProductEntry[];
  type: number;
}

interface TelcoContext {
  selectedTelco: Telco | null;
  setSelectedTelco: React.Dispatch<React.SetStateAction<Telco | null>>;
}

const TelcoContext = createContext<TelcoContext>({} as TelcoContext);

const TelcoProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedTelco, setSelectedTelco] = React.useState<Telco | null>(null);

  return <TelcoContext.Provider value={{ selectedTelco, setSelectedTelco }}>{children}</TelcoContext.Provider>;
};

export default TelcoProvider;
export const useSelectTelco = () => useContextSelector(TelcoContext, (c) => c);
