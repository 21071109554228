import isValidDate from "../../../../utils/isValidDate";
import isValidEmail from "../../../../utils/isValidEmail";

const handleContactValidation = (values, additionalCondition) => {
  const [dd, mm, yyyy] = values.birthday.split(".");
  const validDoB = values.birthday.length === 10 && isValidDate(dd, mm, yyyy);
  const validPassword =
    !values.showPassword || values?.safetyIndex?.length === 4;
  const isPhoneValid =
    (!values.phoneNumber || values.phoneNumber.length > 5) &&
    (!values.mobileNumber || values.mobileNumber.length > 5) &&
    (!values.faxNumber || values.faxNumber.length > 5);
  const isEmailValid = isValidEmail(values.email);
  const isRequiredPhoneField = additionalCondition?.phoneLabel?.includes("*");

  if (additionalCondition?.isRepeatMail) {
    const isSameRepeatEmail =
      values.email === values.confirmationEmail &&
      isValidEmail(values.email) &&
      isValidEmail(values.confirmationEmail);

    if (isRequiredPhoneField) {
      return (
        isEmailValid &&
        isSameRepeatEmail &&
        isPhoneValid &&
        !!values.salutation &&
        !!values.firstName &&
        !!values.lastName &&
        !!values.phoneNumber &&
        validPassword &&
        validDoB
      );
    }

    return (
      isEmailValid &&
      isSameRepeatEmail &&
      isPhoneValid &&
      !!values.salutation &&
      !!values.firstName &&
      !!values.lastName &&
      validPassword &&
      validDoB
    );
  }

  if (isRequiredPhoneField) {
    return (
      isEmailValid &&
      isPhoneValid &&
      !!values.salutation &&
      !!values.firstName &&
      !!values.lastName &&
      !!values.phoneNumber &&
      validPassword &&
      validDoB
    );
  }

  return (
    isEmailValid &&
    isPhoneValid &&
    !!values.salutation &&
    !!values.firstName &&
    !!values.lastName &&
    validPassword &&
    validDoB
  );
};

export default handleContactValidation;
