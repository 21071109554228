import { useMutation } from "react-query";

import toast from "../../../components/Toast";
import { useConfig, useParameter } from "../../../context";
import ProxyInstance from "../../../services/proxy";

const useSubmitCancellationData = () => {
  const config = useConfig();
  const { authToken } = useParameter();
  return useMutation(
    "SUBMIT_CANCELLATION_FORM_DATA",
    async ({ formikValues, konfigValues }) => {
      const body = { body: formikValues, konfig: konfigValues };

      try {
        const { data } = await ProxyInstance(`/termination`, {
          apikey: config.apikey || "",
          authToken,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(body),
        });
        const pdfUrl = data.data.pdfurl;

        return {
          ...formikValues,
          pdfUrl,
        };
      } catch (e) {
        console.error("Error send data to Proxy", e);
        throw e;
      }
    },
    {
      onError: (e) => {
        if (e instanceof Error) {
          toast.error(`Fehler beim Versenden`);
          throw e;
        }
      },
    }
  );
};

export default useSubmitCancellationData;
