import { formatISO } from "date-fns";

import Tariff from "../../../types/Tariff";
import basePriceCalculation from "./basePriceCalculation";

interface vppMappingsAttributes {
  value: Tariff & {
    tariftyp: string;
    verbrauchGesamt: number;
    anzeigeNettoBrutto: number;
    asOffer: boolean;
    idPerson?: string;
    measurementType: string;
  };
}
export type vppMappingsReturn = ReturnType<typeof vppMappings>;

const vppMappings = ({ value }: vppMappingsAttributes) => {
  return {
    bestelldatumZeit: formatISO(new Date()),
    VParbeitspreisCtKWhNetto: value.arbeitspreisCtKWhNetto,
    VParbeitspreisCtKWhBrutto: value.arbeitspreisCtKWhBrutto,
    VParbeitspreisHTCtKWhNetto: value.arbeitspreisHTCtKWhNetto,
    VParbeitspreisHTCtKWhBrutto: value.arbeitspreisHTCtKWhBrutto,
    VParbeitspreisNTCtKWhNetto: value.arbeitspreisNTCtKWhNetto ? value.arbeitspreisNTCtKWhNetto : undefined,
    VParbeitspreisNTCtKWhBrutto: value.arbeitspreisNTCtKWhBrutto ? value.arbeitspreisNTCtKWhBrutto : undefined,
    ...(value.arbeitspreisEnergiepreisHTCtKWhNetto
      ? {
          VParbeitspreisEnergiepreisHTCtKWhNetto: value.arbeitspreisEnergiepreisHTCtKWhNetto,
        }
      : {}),
    ...(value.arbeitspreisEnergiepreisHTCtKWhBrutto
      ? {
          VParbeitspreisEnergiepreisHTCtKWhBrutto: value.arbeitspreisEnergiepreisHTCtKWhBrutto,
        }
      : {}),
    ...(value.arbeitspreisEnergiepreisNTCtKWhNetto
      ? {
          VParbeitspreisEnergiepreisNTCtKWhNetto: value.arbeitspreisEnergiepreisNTCtKWhNetto,
        }
      : {}),
    ...(value.arbeitspreisEnergiepreisNTCtKWhBrutto
      ? {
          VParbeitspreisEnergiepreisNTCtKWhBrutto: value.arbeitspreisEnergiepreisNTCtKWhBrutto,
        }
      : {}),
    VPgrundpreisNetto: value.grundpreisNetto,
    VPgrundpreisBrutto: value.grundpreisBrutto,
    VPgrundpreisMonatNetto: basePriceCalculation(value, "grundpreisMonatNetto"),
    VPgrundpreisMonatBrutto: basePriceCalculation(value, "grundpreisMonatBrutto"),
    VPgesamtpreisOhneBoniNetto: value.gesamtpreisOhneBoniNetto,
    VPgesamtpreisOhneBoniBrutto: value.gesamtpreisOhneBoniBrutto,
    VPgesamtpreisMonatOhneBoniNetto: basePriceCalculation(value, "gesamtpreisMonatOhneBoniNetto"),
    VPgesamtpreisMonatOhneBoniBrutto: basePriceCalculation(value, "gesamtpreisMonatOhneBoniBrutto"),
    VPgesamtpreisNetto: value.gesamtpreisNetto,
    VPgesamtpreisBrutto: value.gesamtpreisBrutto,
    VPgesamtpreisMonatNetto: basePriceCalculation(value, "gesamtpreisMonatNetto"),
    VPgesamtpreisMonatBrutto: basePriceCalculation(value, "gesamtpreisMonatBrutto"),
    boni: value.boni ?? null,
    aufschlaege: value.aufschlaege || null,
    anzeigeNettoBrutto: value.anzeigeNettoBrutto ? value.anzeigeNettoBrutto : 0,
    auftragsstatus: value.asOffer ? 1 : 11,
    lasttyp: "slp",
    messtyp:
      value.tariftyp === "2" || value.tariftyp === "7"
        ? null
        : value.measurementType === "0"
          ? "gemeinsam"
          : "getrennt",
    priceType: 0,
    verbrauchGesamt: value.verbrauchGesamt,
    id_person: value?.idPerson,
  };
};

export default vppMappings;
