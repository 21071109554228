import { useField, useFormikContext } from "formik";
import React from "react";

import Select from "../../../../components/Select";

const SelectTariff = ({ name, label, options, disabled, isLoading, ...props }) => {
  const [fields, , { setValue }] = useField({ name });
  const { setFieldValue } = useFormikContext();

  const handleEvent = React.useCallback(
    (value) => {
      if (fields.name === "supplier") {
        setFieldValue("tariffComparison", "");
        setValue(value);
      } else setValue(value);
    },
    [fields.name, setFieldValue, setValue]
  );

  return (
    <Select
      {...fields}
      {...props}
      label={label}
      busy={isLoading}
      disabled={disabled}
      onSelect={handleEvent}
      hideEmptyPopup
      onChange={handleEvent}
      data={options}
    />
  );
};

export default SelectTariff;
