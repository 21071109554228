import { Formik } from "formik";
import React from "react";

import { useCalcParams } from "../../context/CalculationParameter";
import { useTRInputContext } from "../../context/FormInputData";
import { useSupplierInfo } from "../../context/SupplierInfo";
import { Telco } from "../../context/Telco";
import useAPIKey from "../../hooks/useAPIKey";
import Tariff from "../../types/Tariff";
import TelcoGridConnection from "../../types/TelcoGridConnection";
import convertLocalizedStringToNumber from "../../utils/convertLocalizedStringToNumber";
import ResultList from "./components/ResultsList";
import TelcoResults from "./components/TelcoResults";
import useSubmitTariffResult from "./hooks/useSubmitTariff";

const ResultPage = ({
  type,
  data,
  additionalData,
}: {
  type: string;
  data: Tariff[] | Telco[];
  additionalData?: TelcoGridConnection;
}) => {
  return (
    <>
      {type === "energie" && <ResultList data={data as Tariff[]} />}
      {type === "telco" && (
        <TelcoResults data={data as Telco[]} additionalData={additionalData ?? ({} as TelcoGridConnection)} />
      )}
    </>
  );
};

const TariffResult = ({
  type,
  data,
  additionalData,
}: {
  type: string;
  data: Tariff[];
  additionalData?: TelcoGridConnection;
}) => {
  const { mutateAsync: submitTariff } = useSubmitTariffResult(true);
  const formData = useTRInputContext();
  const { calcParams } = useCalcParams();
  const supplier = useSupplierInfo();
  const { revalidateAPIKey } = useAPIKey();

  React.useEffect(() => {
    revalidateAPIKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Formik
      component={() => <ResultPage type={type || "energie"} data={data} additionalData={additionalData} />}
      enableReinitialize
      initialValues={{
        priceType: "0",
        compareType: "0",
        supplier: supplier.supplierInformation?.versorgerName,
        tariffComparison: supplier.supplierInformation?.suppliertariffName,
        zipCode: formData.values?.zipCode || "",
        city: formData.values?.city || undefined,
        street: formData.values?.street || undefined,
        houseNumber: formData.values?.houseNumber || "",
        oneTariff: formData.values?.oneTariff || "",
        twoTariff: formData.values?.twoTariff || "",
        calculatorType: formData.values?.customerType || "0",
        eco: formData.values?.isEcoTariff || false,
        customerType: formData.values?.customerType || "0",
        isEcoTariff: formData.values?.isEcoTariff || false,
        meterType: formData.values?.meterType || "1,2",
        measurementType: formData.values?.measurementType || "0",
        basePriceBrutto:
          supplier.supplierInformation?.suppliertariffName === "Vergleichstarif"
            ? supplier.supplierInformation?.suppliertariffGrundpreisBrutto
            : "",
        workingPriceBrutto:
          supplier.supplierInformation?.suppliertariffName === "Vergleichstarif"
            ? supplier.supplierInformation?.suppliertariffArbeitspreisHTCtKWhBrutto
            : "",
        workingPriceNTBrutto:
          supplier.supplierInformation?.suppliertariffName === "Vergleichstarif"
            ? supplier.supplierInformation?.suppliertariffArbeitspreisNTCtKWhNetto
            : "",
        basePriceNetto:
          supplier.supplierInformation?.suppliertariffName === "Vergleichstarif"
            ? supplier.supplierInformation?.suppliertariffGrundpreisNetto
            : "",
        workingPriceNetto:
          supplier.supplierInformation?.suppliertariffName === "Vergleichstarif"
            ? (supplier.supplierInformation?.suppliertariffArbeitspreisHTCtKWhNetto ?? "")
            : "",
        workingPriceNTNetto:
          supplier.supplierInformation?.suppliertariffName === "Vergleichstarif"
            ? (supplier.supplierInformation?.suppliertariffArbeitspreisNTCtKWhNetto ?? "")
            : "",
        tariffComparisonID: "",
        stichtag: formData.values.stichtag ?? "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await submitTariff({
          ...values,
          basePriceBrutto: `${convertLocalizedStringToNumber(values?.basePriceBrutto)}`,
          basePriceNetto: `${convertLocalizedStringToNumber(values?.basePriceNetto)}`,
          workingPriceBrutto: `${convertLocalizedStringToNumber(values?.workingPriceBrutto)}`,
          // workingPriceNTBrutto: convertLocalizedStringToNumber(values?.workingPriceNTBrutto),
          // workingPriceNTNetto: convertLocalizedStringToNumber(values?.workingPriceNTNetto),
          workingPriceNetto: `${convertLocalizedStringToNumber(values?.workingPriceNetto)}`,
          baseSupplier: calcParams?.netzbetreiber,
          networkOperator: calcParams?.grundversorger,
          tariffType: calcParams?.tariftyp,
        });
        setSubmitting(false);
      }}
    />
  );
};

export default TariffResult;
