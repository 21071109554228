import { useCallback, useEffect, useRef, useState } from "react";

function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const timerRef = useRef(null);
  const callback = useCallback(() => setDebouncedValue(value), [value]);

  useEffect(() => {
    timerRef.current = setTimeout(callback, delay);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clearTimeout(timerRef.current);
    };
  }, [callback, delay]);

  return debouncedValue;
}

export default useDebounce;
