import React from "react";

import useFeaturesResult from "../../widgets/TCResults/hooks/useFeaturesResult";
import Card from "../Card";

const NoResult = () => {
  const { tryAgain, noListProduct } = useFeaturesResult();
  return (
    <Card className="text-center">
      <div className="text-lg font-bold">{noListProduct}</div>
      <div>{tryAgain}</div>
    </Card>
  );
};

export default NoResult;
