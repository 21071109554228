import DOMPurify from "dompurify";
import parse from "html-react-parser";
import React from "react";

const htmlFrom = (htmlString: string) => {
  const cleanHtmlString = DOMPurify.sanitize(htmlString, {
    USE_PROFILES: { html: true },
    ADD_ATTR: ["target"],
  });
  return parse(cleanHtmlString);
};

const ReactHTMLParser = ({ children }: { children: React.ReactNode }) => {
  if (typeof children === "string") return <>{htmlFrom(children)}</>;

  // if (typeof children === "object") {
  //   if (typeof children.props === "object") {
  //     return <>{htmlFrom(children.props.children)}</>;
  //   }
  //   return <>{htmlFrom(children.props)}</>;
  // }

  return <div />;
};

export default ReactHTMLParser;
