import { baseURL } from "../Constant";

const abortController = new AbortController();

const isLocalhost = typeof window !== "undefined" && window.location.hostname === "localhost";

const ConfigInstance = async (url: string) => {
  if (!baseURL) {
    throw new Error("Env baseURL is missing");
  }

  const response = await fetch(`${baseURL}${url}`, {
    headers: {
      ...(process.env.NODE_ENV === "development" || isLocalhost
        ? {
            "x-origin": "localhost-TR5",
          }
        : {}),
    },
    signal: abortController.signal,
  });

  const data = await response.json();

  if (!response.ok && data.error) {
    throw new Error(data.error);
  }

  return { data };
};

export default ConfigInstance;
