import React from "react";

import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import Chevron from "../../../../../components/icons/Chevron";
import Download from "../../../../../components/icons/download";
import Modal from "../../../../../components/Modal";
import { Telco } from "../../../../../context/Telco";
import useResponsiveClass from "../../../../../hooks/useResponsiveClass";
import formatCurrency from "../../../../../utils/formatCurrency";
import scrollToTop from "../../../../../utils/scrollToTop";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import Badges from "../../ResultsList/Item/Badges";
import RowItem from "../../ResultsList/RowItem";

const TelcoItem = ({ list, onSelect }: { list: Telco; onSelect: (product: Telco) => void }) => {
  const [showTariffDetails, setShowTariffDetails] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { responsiveClass } = useResponsiveClass();

  const {
    showTariffDetail,
    contractperiod,
    contractextension,
    internetSpeedUnit,
    optionInfo,
    pricesInclTax,
    infinityPeriod,
  } = useFeaturesResult();

  const typeIndexes = React.useMemo(() => {
    const typeIndexes: { jpg?: number; pdf?: number } = {};

    list.productDocuments.forEach((item, key) => {
      if (item.uRL?.endsWith(".jpg")) typeIndexes.jpg = key;
      if (item.uRL?.endsWith(".pdf")) typeIndexes.pdf = key;
    });

    return typeIndexes;
  }, [list.productDocuments]);

  return (
    <div className="grid" ref={ref}>
      <Modal
        visible={showTariffDetails}
        setVisible={setShowTariffDetails}
        showChildrenDefault={true}
        position={{
          left: ref?.current?.offsetLeft ?? 200,
          right: ref?.current?.offsetTop ?? 200,
          width: ref?.current?.offsetWidth ?? 400,
        }}
      >
        <Card key={list.rootId} className="flex flex-col justify-between gap-6">
          <div className="grid gap-6 w-full">
            <div>
              {/* //TODO: adjust Badges */}
              <Badges hasPhone={list.landlineTelephone} />
              <div className="text-center">{list.providerName}</div>
              <div className="text-2xl font-medium text-center min-h-16">{list.productName}</div>
            </div>
            <div className="grid gap-1">
              <RowItem
                className="!text-2xl !text-medium"
                label="Download"
                value={list.landlineInternetDownloadSpeed + " " + internetSpeedUnit}
                invert={true}
              />
              <RowItem
                label="Upload"
                value={list.landlineInternetUploadSpeed + " " + internetSpeedUnit}
                invert={true}
              />
              <RowItem
                label={contractperiod}
                value={list.contractPeriodMonths ? list.contractPeriodMonths + " Monat(e)" : infinityPeriod}
              />
            </div>
            <div className="grid gap-1">
              {list.monthlyPrices.map((item, index) => {
                return (
                  <RowItem
                    className={index === 0 ? "text-primary !text-2xl !text-medium" : ""}
                    label={
                      index === 0
                        ? "monatlich ab"
                        : item.to
                          ? `${item.from}. - ${item.to}. Monat`
                          : `ab dem ${item.from}. Monat`
                    }
                    value={formatCurrency(item.pricePerMonth)}
                  />
                );
              })}
              {list.monthlyPrices.length === 0 && <div>Leider sind keine Preise verfügbar.</div>}
            </div>
            <div className="flex justify-between">
              <Button
                onClick={() => setShowTariffDetails(!showTariffDetails)}
                variant="unstyled"
                className="w-full px-0"
              >
                <div className={responsiveClass("flex items-center justify-between", "text-sm", "text-sm")}>
                  {showTariffDetail}
                  <Chevron rotate={showTariffDetails} />
                </div>
              </Button>
            </div>
            {showTariffDetails && (
              <>
                <div className="grid gap-1">
                  <RowItem
                    label={contractextension}
                    value={list.contractExtensionMonths ? list.contractExtensionMonths + " Monat(e)" : infinityPeriod}
                    invert={true}
                  />
                  <RowItem label="Kündigungsfrist" value={list.contractPeriodDate ?? infinityPeriod} />
                  <RowItem
                    label="min. Download"
                    value={list?.landlineInternetDownloadSpeedMinimal + " " + internetSpeedUnit}
                  />
                  <RowItem
                    label="min. Upload"
                    value={list?.landlineInternetUploadSpeedMinimal + " " + internetSpeedUnit}
                  />
                  {list?.landlineTelephoneNumbers > 0 && (
                    <RowItem label="Rufnummern enthalten" value={list?.landlineTelephoneNumbers} />
                  )}
                </div>
                {typeIndexes.jpg !== undefined && list.productDocuments[typeIndexes.jpg].uRL && (
                  <img
                    src={list.productDocuments[typeIndexes.jpg].uRL}
                    alt={list.productDocuments[typeIndexes.jpg].fileName}
                    width="100%"
                    height="120px"
                    style={{ objectFit: "cover" }}
                  />
                )}
                <div className={`text-sm text-gray-dark`}>
                  {typeIndexes.pdf !== undefined && list.productDocuments[typeIndexes.pdf].uRL && (
                    <a rel="noreferrer" href={list.productDocuments[typeIndexes.pdf].uRL} target="_blank">
                      {optionInfo}
                      <Download className="ml-2" variant={1} />
                    </a>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="text-center w-full">
            <Button
              variant="primary"
              onClick={() => {
                onSelect(list);
                scrollToTop();
              }}
            >
              Auswählen
            </Button>
            <div className="my-2 text-xs text-center text-gray-dark">{pricesInclTax}</div>
          </div>
        </Card>
      </Modal>
    </div>
  );
};

export default TelcoItem;
