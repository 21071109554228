import { useField, useFormikContext } from "formik";
import React, { useState } from "react";

import Card from "../../../components/Card";
import LoadingOverlay from "../../../components/LoadingOverlay";
import useAPIKey from "../../../hooks/useAPIKey";
import { useContractConclusionFormName } from "../context";
import MandatoryFields from "../context/Mandatoryfields";
import useFeaturesCC from "../hooks/useFeaturesCC";
import AddressData from "./AddressData";
import BankData from "./BankData";
import ContactData from "./ContactData";
import GeeData from "./GeeData";
import OfferBatch from "./OfferBatch";
import PreSupplierData from "./PreSupplierData";
import ProductOptions from "./ProductOptions";
import Summary from "./Summary";

const ContractConclusionForm = ({ cardMinHeight }: { cardMinHeight: number }) => {
  const { formName } = useContractConclusionFormName();
  const { missingCRM } = useFeaturesCC();
  const [oldFormName, setOldFormName] = useState(formName);
  const { isSubmitting, errors } = useFormikContext();
  const { revalidateAPIKey } = useAPIKey();
  const [fields] = useField("asOffer");
  JSON.stringify(errors) != "{}" && process.env.REACT_APP_MODE === "development " ? console.error(errors) : "";

  React.useEffect(() => {
    if (formName !== oldFormName) {
      //avoid unattended refetching
      revalidateAPIKey();
    }
    setOldFormName(formName);
  }, [formName, oldFormName, revalidateAPIKey]);
  return (
    <MandatoryFields>
      <Card
        style={{
          minHeight: cardMinHeight,
        }}
        className="sticky top-4 flex flex-col justify-between"
      >
        {(missingCRM || fields.value) && <OfferBatch alternativetext={missingCRM} isOffer={fields.value} />}
        {isSubmitting && <LoadingOverlay text="Bitte warten..." />}
        {formName === "options" && <ProductOptions />}
        {formName === "contactForm" && <ContactData />}
        {formName === "addressForm" && <AddressData />}
        {formName === "gee" && <GeeData />}
        {formName === "preSupplierForm" && <PreSupplierData />}
        {formName === "bankForm" && <BankData />}
        {formName === "summaryForm" && <Summary />}
      </Card>
    </MandatoryFields>
  );
};

export default ContractConclusionForm;
