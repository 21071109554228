import React, { useEffect, useState } from "react";

const LoadingOverlay = ({ text, progressBarEnabled = false }) => {
  const [progressBar, setProgressBar] = useState(true && progressBarEnabled);
  useEffect(() => {
    let timeout;
    if(progressBar){
      timeout = setTimeout(() => {
        setProgressBar(false);
      }, 5000);
    }
    return () => progressBar ? clearTimeout(timeout): "";
  });
  if (progressBar) {
    return (
      <div className="w-full h-full fixed top-0 left-0 bg-content-bg opacity-75 z-50">
        <div className="flex justify-center w-full items-center mt-[50vh]">
          <div className="w-[70vw] h-[50px] border-solid border-button-primary-bg animate-progress bg-[length:0%] bg-[linear-gradient(var(--get-button-primary-bg)_0px,var(--get-button-primary-bg))] bg-no-repeat"></div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full fixed top-0 left-0 bg-content-bg opacity-75 z-50">
        <div className="flex justify-center items-center mt-[50vh]">
          <div className="border-borderInput border-solid h-5 w-5 animate-spin rounded-full border-4 border-t-button-primary-bgHover" />
          <div className="ml-2 text-primary">{text}</div>
        </div>
      </div>
    );
  }
};

export default LoadingOverlay;
