import * as React from "react";

const ErrorMessage = ({ text }) => {
  if (typeof text === "string")
    return <small className="text-attention mt-2">{text}</small>;

  return <small className="text-attention mt-2">{text.value}</small>;
};

export default ErrorMessage;
