import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const calculationResult = createContext({});

const CalculationResultProvider = ({ children }) => {
  const [calcResult, setCalcResult] = React.useState(null);

  return <calculationResult.Provider value={{ calcResult, setCalcResult }}>{children}</calculationResult.Provider>;
};

export default CalculationResultProvider;
export const useCalcResult = () => useContextSelector(calculationResult, (c) => c);
