import { Leaf } from "lucide-react";
import React from "react";

const Eco= ({ eco, ecoTarifLabel }) => {
  return (
    <section id="eco">
      {eco.isOekostromTarif || eco.isOekogasTarif ? (
        <div className="flex justify-center items-center my-2">
          <Leaf color="#4caf50" />
          <div className="text-green-500">{ecoTarifLabel}</div>
        </div>
      ) : (
        <div className="pb-10" />
      )}
    </section>
  );
};

export default Eco;
