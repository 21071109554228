import * as React from "react";
import WarningIcon from "../../../../components/icons/warning";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const OfferBatch = ({ alternativetext = "", isOffer }: { alternativetext?: string; isOffer: boolean }) => {
  const { offer } = useFeaturesCC();
  return (
    <div className="absolute flex right-4 gap-2">
      {isOffer && (
        <div className="px-2.5 py-0.5 flex bg-red-100 rounded-full gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <mask id="mask0_1728_22673" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
              <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1728_22673)">
              <path
                d="M14.2654 9.50016L9.4987 14.2668C9.36536 14.4002 9.21536 14.5002 9.0487 14.5668C8.88203 14.6335 8.71536 14.6668 8.5487 14.6668C8.38203 14.6668 8.21536 14.6335 8.0487 14.5668C7.88203 14.5002 7.73203 14.4002 7.5987 14.2668L1.71536 8.3835C1.59314 8.26127 1.4987 8.11961 1.43203 7.9585C1.36536 7.79738 1.33203 7.62794 1.33203 7.45016V2.66683C1.33203 2.30016 1.46259 1.98627 1.7237 1.72516C1.98481 1.46405 2.2987 1.3335 2.66536 1.3335H7.4487C7.62648 1.3335 7.7987 1.36961 7.96536 1.44183C8.13203 1.51405 8.27648 1.61127 8.3987 1.7335L14.2654 7.61683C14.3987 7.75016 14.4959 7.90016 14.557 8.06683C14.6181 8.2335 14.6487 8.40016 14.6487 8.56683C14.6487 8.7335 14.6181 8.89739 14.557 9.0585C14.4959 9.21961 14.3987 9.36683 14.2654 9.50016ZM8.5487 13.3335L13.3154 8.56683L7.43203 2.66683H2.66536V7.4335L8.5487 13.3335ZM4.33203 5.3335C4.60981 5.3335 4.84592 5.23627 5.04036 5.04183C5.23481 4.84738 5.33203 4.61127 5.33203 4.3335C5.33203 4.05572 5.23481 3.81961 5.04036 3.62516C4.84592 3.43072 4.60981 3.3335 4.33203 3.3335C4.05425 3.3335 3.81814 3.43072 3.6237 3.62516C3.42925 3.81961 3.33203 4.05572 3.33203 4.3335C3.33203 4.61127 3.42925 4.84738 3.6237 5.04183C3.81814 5.23627 4.05425 5.3335 4.33203 5.3335Z"
                fill="#DC2626"
              />
            </g>
          </svg>
          <span className="text-xs text-red-600">{offer}</span>
        </div>
      )}
      {alternativetext && (
        <div className="px-2.5 py-0.5 flex bg-red-100 rounded-full gap-1">
          <WarningIcon className="size-4" fill="#dc2626" />
          <span className="text-xs text-red-600">{alternativetext}</span>
        </div>
      )}
    </div>
  );
};

export default OfferBatch;
