import * as React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import { useCalcParams } from "../../../context/CalculationParameter";
import { FormValues } from "../../../types/FormValues";
import useServerValidationForm from "../hooks/useServerValidationForm";

const mandatoryFieldsContext = createContext<string[]>([]);

const MandatoryFields = ({ children }: { children: React.ReactNode }) => {
  const [mandatoryFields, setMandatoryFields] = React.useState([]);
  const { calcParams } = useCalcParams();
  const { mutateAsync } = useServerValidationForm("context", {
    tarifParameter: calcParams,
    keysToValidate: ["medium"],
  });
  React.useEffect(() => {
    const fetchMandatoryFields = async () => {
      const data = await mutateAsync({} as FormValues);
      setMandatoryFields(data.data?.pflichtfelder);
    };
    fetchMandatoryFields();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <mandatoryFieldsContext.Provider value={mandatoryFields}>{children}</mandatoryFieldsContext.Provider>;
};

export default MandatoryFields;
export const useMandatoryfields = () => useContextSelector(mandatoryFieldsContext, (c) => c);
