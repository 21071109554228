import { createContext, useContextSelector } from "use-context-selector";

export const stepperContractConclusion = createContext(null);

export const useContractConclusionFormName = () =>
  useContextSelector(stepperContractConclusion, (c) => c);

export const activatedPanelsContext = createContext({
  activatedPanels: [],
  setActivatedPanels: () => {},
});

export const useActivatedPanels = () =>
  useContextSelector(activatedPanelsContext, (c) => c);
