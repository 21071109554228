import { format } from "date-fns";

const convertDate = (date = "") => {
  if (!date) throw new Error(`Date is ${date}`);
  const [day, month, year] = date.split(".");

  return format(new Date(Number.parseInt(year), Number.parseInt(month, 10) - 1, Number.parseInt(day)), "yyyy-MM-dd");
};

export default convertDate;
