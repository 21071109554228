import * as React from "react";
import { Doughnut } from "react-chartjs-2";

import { useConfig } from "../../../../../context/Config";
import useGeneralLabel from "../../../../../hooks/useGeneralLabel";
import useResponsiveClass from "../../../../../hooks/useResponsiveClass";
import formatCurrency from "../../../../../utils/formatCurrency";
import formatDecimal from "../../../../../utils/formatDecimal";
import { DynamischeAngaben } from "../../../../TCInputs/types/SimplifierResponse";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import RowItem from "../RowItem";

interface TariffDetails {
  tarifInfo: string;
  strommixLight: {
    dynamischeAngaben: DynamischeAngaben;
    co2: number;
    erneuerbare: number;
    fossile: number;
    kernkraft: number;
    radioaktiv: number;
    stand: number;
  };
  tableBody: (Preisstaffel & { arbeitspreisNTNetto: number; arbeitspreisNTBrutto: number })[];
  arbeitspreisNTCtKWhBrutto: number | null;
  arbeitspreisNTCtKWhNetto: number | null;
  isBrutto: boolean;
  vertragsverlaengerung: string;
  kuendigungsfrist: string;
}
export interface Preisstaffel {
  id: number;
  bezeichnung: string;
  tarifcode: string;
  verbrauchVon: number;
  verbrauchBis: number;
  leistungVon: number;
  leistungBis: number;
  isZonenTarif: boolean;
  arbeitspreisBrutto: number;
  grundpreisBrutto: number;
  leistungspreisBrutto: number;
  arbeitspreisNetto: number;
  grundpreisNetto: number;
  leistungspreisNetto: number;
}

const TariffDetails = (props: TariffDetails) => {
  const {
    workingprice,
    nuclear,
    renewable,
    fossil,
    radioactiv,
    co2,
    co2unit,
    renewableunit,
    nuclearunit,
    fossilunit,
    stand,
    strommix,
    tieredPricing,
    consumptionLabel,
    baseprice,
    contractextension,
    cancellationperiod,
    notSpezified,
    workingpriceunit,
  } = useFeaturesResult();
  const { HTLabel, NTLabel } = useGeneralLabel();
  const [showFullPriceGraduation, setShowFullPriceGraduation] = React.useState(false);
  const { responsiveClass } = useResponsiveClass();
  const { config } = useConfig();

  const isChartDataAvailable = React.useMemo(() => {
    return props.strommixLight?.erneuerbare || props.strommixLight?.fossile || props.strommixLight?.kernkraft || false;
  }, [props.strommixLight?.erneuerbare, props.strommixLight?.fossile, props.strommixLight?.kernkraft]);

  const primaryBackgroundColor = React.useMemo(
    () =>
      config.reference?.current
        ? getComputedStyle(config.reference?.current).getPropertyValue("--get-c-statistics-1").trim()
        : "#3966aa",
    [config?.reference]
  );
  const secondaryBackgroundColor = React.useMemo(
    () =>
      config.reference?.current
        ? getComputedStyle(config.reference?.current).getPropertyValue("--get-c-statistics-2").trim()
        : "#f18307",
    [config?.reference]
  );
  const tertiaryBackgroundColor = React.useMemo(
    () =>
      config.reference?.current
        ? getComputedStyle(config.reference?.current).getPropertyValue("--get-c-statistics-3").trim()
        : "#ffab00",
    [config?.reference]
  );

  const chartData = props.strommixLight && {
    labels: Object.keys(props.strommixLight)
      .filter((v) => v === "erneuerbare" || v === "fossile" || v === "kernkraft")
      .map((key) => {
        if (key === "erneuerbare") {
          return renewable;
        }
        if (key === "fossile") {
          return fossil;
        }
        if (key === "kernkraft") {
          return nuclear;
        }
      }),
    datasets: [
      {
        data: Object.entries(props.strommixLight)
          .filter(([key]) => key === "erneuerbare" || key === "fossile" || key === "kernkraft")
          .map(([key, value]) => {
            if (key === "erneuerbare") {
              return value;
            }
            if (key === "fossile") {
              return value;
            }
            if (key === "kernkraft") {
              return value;
            }
          }),
        backgroundColor: Object.keys(props.strommixLight)
          .filter((v) => v === "erneuerbare" || v === "fossile" || v === "kernkraft")
          .map((key) => {
            if (key === "erneuerbare") {
              return primaryBackgroundColor;
            }
            if (key === "fossile") {
              return secondaryBackgroundColor;
            }
            if (key === "kernkraft") {
              return tertiaryBackgroundColor;
            }
          }),
      },
    ],
  };
  return (
    <section
      id="tariff-info"
      className="mt-6 grid gap-6 *:after:h-px *:after:w-full *:after:block *:after:bg-gray *:after:rounded-full *:after:mt-6 last:*:after:hidden"
    >
      {(props.vertragsverlaengerung || props.kuendigungsfrist) && (
        <div>
          <RowItem
            label={cancellationperiod}
            value={props.kuendigungsfrist === "-" ? notSpezified : props.kuendigungsfrist}
            invert={true}
          />
          <RowItem
            label={contractextension}
            value={props.vertragsverlaengerung === "-" ? notSpezified : props.vertragsverlaengerung}
          />
        </div>
      )}
      {props.tarifInfo && (
        <div className={responsiveClass("text-gray-dark mt-4", "text-sm", "text-sm")}>{props.tarifInfo}</div>
      )}
      {isChartDataAvailable && (
        <div>
          {props.strommixLight && (
            <div className="mb-4">
              {props.strommixLight?.co2 !== undefined ? (
                <RowItem label={co2} value={props.strommixLight?.co2 + " " + co2unit} />
              ) : null}

              {props.strommixLight?.radioaktiv !== undefined ? (
                <RowItem label={radioactiv} value={props.strommixLight?.radioaktiv + " " + co2unit} />
              ) : null}
            </div>
          )}
          {props.strommixLight?.stand ? (
            <div className={responsiveClass("mb-4", "text-sm", "text-sm")}>
              {strommix} ({stand} {props.strommixLight?.stand})
            </div>
          ) : null}
          <section
            id="chart"
            className={responsiveClass("flex gap-6 items-center", "gap-4 h-24", "gap-6 h-32", "gap-6 h-32")}
          >
            <div className={responsiveClass("overflow-visible", "size-24", "size-32", "size-32")}>
              {/* @ts-ignore */}
              <Doughnut
                data={chartData}
                datasetIdKey="label"
                options={{
                  plugins: {
                    tooltip: {
                      enabled: false,
                    },
                  },
                }}
              />
            </div>

            <div>
              {props.strommixLight?.erneuerbare ? (
                <div className="grid grid-cols-chart gap-2 text-sm mb-2">
                  <div className="bg-statistics-1 inline-block size-3 rounded-full mt-0.5" />
                  <div className="inline">
                    {renewable}: {props.strommixLight?.erneuerbare} {renewableunit}
                  </div>
                </div>
              ) : null}

              {props.strommixLight?.fossile ? (
                <div className="grid grid-cols-chart gap-2 text-sm mb-2">
                  <div className="bg-statistics-2 mr-2 inline-block size-3 rounded-full mt-0.5" />
                  {fossil}: {props.strommixLight?.fossile} {fossilunit}
                </div>
              ) : null}

              {props.strommixLight?.kernkraft ? (
                <div className="grid grid-cols-chart gap-2 text-sm mb-2">
                  <div className="bg-statistics-3 size-3 rounded-full mt-0.5 inline-block mr-2" />
                  <div className="inline">
                    {nuclear}: {props.strommixLight?.kernkraft} {nuclearunit}
                  </div>
                </div>
              ) : null}
            </div>
          </section>
        </div>
      )}
      {props.tableBody ? (
        <section id="bonus" className={responsiveClass("mb-6", "text-xs", "text-xs")}>
          <div className={responsiveClass("flex w-full mb-2", "text-sm", "text-sm")}>{tieredPricing}</div>
          <table className="table-auto border-collapse w-full">
            <thead>
              <tr>
                <th className="text-left pr-2 pb-2 font-medium">{consumptionLabel}</th>
                <th className="text-right pr-2 pb-2 font-medium">{baseprice} p.a.</th>
                <th className="text-right pb-2 font-medium">
                  {workingprice}
                  {props.arbeitspreisNTCtKWhBrutto || props.arbeitspreisNTCtKWhNetto
                    ? " " + HTLabel + " / " + NTLabel
                    : ""}
                </th>
              </tr>
            </thead>
            <tbody>
              {props.tableBody
                ?.slice(0, showFullPriceGraduation ? undefined : 4)
                ?.map((preisItem: Preisstaffel & { arbeitspreisNTNetto: number; arbeitspreisNTBrutto: number }) => (
                  <tr key={preisItem.id} className="border-solid border-x-0 border-y-1 border-gray">
                    <td className="text-left pr-2">
                      {preisItem.verbrauchBis === 0 && "ab "}
                      {formatDecimal(preisItem.verbrauchVon, 0)}
                      {preisItem.verbrauchBis !== 0 && " - "}
                      {preisItem.verbrauchBis !== 0 && formatDecimal(preisItem.verbrauchBis, 0)} kWh
                    </td>
                    <td className="text-right pr-2">
                      {formatCurrency(props.isBrutto ? preisItem.grundpreisBrutto : preisItem.grundpreisNetto)}
                    </td>
                    <td className="text-right">
                      {formatDecimal(props.isBrutto ? preisItem.arbeitspreisBrutto : preisItem.arbeitspreisNetto)}
                      {props.arbeitspreisNTCtKWhBrutto || props.arbeitspreisNTCtKWhNetto
                        ? " / " +
                          formatDecimal(props.isBrutto ? preisItem.arbeitspreisNTBrutto : preisItem.arbeitspreisNTNetto)
                        : ""}
                      &nbsp;{workingpriceunit}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {props.tableBody?.length > 4 && (
            <div
              onClick={() => setShowFullPriceGraduation((v) => !v)}
              className={responsiveClass("text-primary mt-6 cursor-pointer", "text-sm", "text-sm")}
            >
              {showFullPriceGraduation ? "weniger anzeigen" : "mehr anzeigen"}
            </div>
          )}
        </section>
      ) : null}
    </section>
  );
};
export default TariffDetails;
