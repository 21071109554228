import qs from "query-string";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { useTariff } from "../../../../context";
import { useConfig } from "../../../../context/Config";
import ProxyInstance from "../../../../services/proxy";
import { ResponseFormDataConfig } from "../types/ResponseFormDataConfig";

const useGetFormData = () => {
  const tariff = useTariff();
  const { config } = useConfig();
  const queryString = useMemo(() => qs.stringify({ calculatorType: tariff.id }), [tariff.id]);

  return useQuery(
    `GET_FORM_DATA_${tariff.id}`,
    async () => {
      try {
        const { data: res } = await ProxyInstance(`/config/contactform?${queryString}`, {
          apikey: config.apikey || "",
        });

        const { data }: ResponseFormDataConfig = res;

        return data.map((val) => ({ label: val.text, value: val.text }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    { enabled: tariff.id === "7" }
  );
};

export default useGetFormData;
