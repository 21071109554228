import React from "react";

import Input from "../../../../components/Input";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeaturesResult from "../../hooks/useFeaturesResult";

const SelfCompareTariff = ({
  children,
  timeframe,
  isBrutto,
}: {
  children?: React.ReactNode;
  timeframe: boolean;
  isBrutto: boolean;
}) => {
  const { baseprice, workingprice, workingpriceunit } = useFeaturesResult();
  const { responsiveClass } = useResponsiveClass();

  return (
    <section className="my-4">
      <div className={responsiveClass("grid gap-y-2 gap-x-4", "grid-cols-1", "grid-cols-1", "grid-cols-2")}>
        <Input
          label={`${baseprice}`}
          name={isBrutto ? "basePriceBrutto" : "basePriceNetto"}
          usage={timeframe ? "€/Jahr" : "€/Monat"}
          inputType="decimalNumber"
        />
        <Input
          label={`${workingprice}`}
          name={isBrutto ? "workingPriceBrutto" : "workingPriceNetto"}
          usage={workingpriceunit}
          inputType="decimalNumber"
        />
        {/* temporaly removed because of GETTR-2044 */}
        {/* {inputContext.values.meterType === "3,4" && (
            <div className="my-2">
              <Input
                label={`${workingprice} ${NTLabel}`}
                name={isBrutto ? "workingPriceNTBrutto" : "workingPriceNTNetto"}
                usage={workingpriceunit}
                inputType="decimalNumber"
              />
            </div>
          )} */}
      </div>

      {children && <div className="mt-4">{children}</div>}
    </section>
  );
};

export default SelfCompareTariff;
