import { useField } from "formik";
import * as React from "react";

import GetSelect from "../../../../components/GetSelect";
import { Option } from "../../../../components/GetSelect/types";
import Input from "../../../../components/Input";
import { useConfig } from "../../../../context/Config";
import { useSelectTariff } from "../../../../context/Tariff";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useGetSupplier from "../../hooks/useGetSupplier";
import Headline from "../Headline";

const PrevSupplierData = () => {
  const { data, isLoading } = useGetSupplier();
  const { prevSupplier, prevCustomerNum, prevProviderTitle } = useFeaturesCC();
  const { selectedTariff } = useSelectTariff();
  const { mandatoryLabel } = useGeneralLabel();
  const [fields] = useField("asOffer");
  const gridCols = { desktop: "grid-cols-2", tablet: "grid-cols-2", mobile: "grid-cols-1" };
  const { config } = useConfig();
  const mandatoryFieldsCRM = useMandatoryfields();
  const [reasonToChange] = useField("reasonToChange");
  const [prevSupplierName, , { setValue: setPrevSupplierName }] = useField("prevSupplierName");

  React.useEffect(() => {
    if (reasonToChange.value !== "tarifwechsel" && selectedTariff.versorgerName === prevSupplierName.value.value) {
      setPrevSupplierName("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonToChange.value]);
  return (
    <div className={`grid gap-y-2 gap-x-4 col-span-full ${gridCols[config.media ?? "desktop"]}`}>
      <Headline>{prevProviderTitle}</Headline>
      <GetSelect
        label={
          prevSupplier + (!fields?.value && mandatoryFieldsCRM?.includes("vorlieferant_name") ? mandatoryLabel : "")
        }
        disabled={reasonToChange.value !== "tarifwechsel" ? isLoading : true}
        isLoading={isLoading}
        isSearchable={true}
        name="prevSupplierName"
        options={
          reasonToChange.value !== "tarifwechsel"
            ? data
                ?.filter((item: Option) => item.value !== undefined)
                .filter((item: Option) => item.value !== selectedTariff.versorgerName)
            : [{ value: selectedTariff?.versorgerName, label: selectedTariff?.versorgerName }]
        }
      />
      <Input
        name="customerNumber"
        maxLength={50}
        label={
          prevCustomerNum +
          (!fields?.value && mandatoryFieldsCRM?.includes("vorlieferant_kundennummer") ? mandatoryLabel : "")
        }
        inputType="otherNumber"
      />
    </div>
  );
};

export default PrevSupplierData;
