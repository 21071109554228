import { useField } from "formik";
import React from "react";

import Input from "../../../../components/Input";
import { useConfig } from "../../../../context/Config";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import Headline from "../Headline";

const Contact = () => {
  const { mailRepetition, showFax, emailValidate, email, mobileNumber, faxNumber, phoneNumber, contactData } =
    useFeaturesCC();
  const { config } = useConfig();
  const gridCols = { desktop: "grid-cols-4", tablet: "grid-cols-2", mobile: "grid-cols-1" };
  const [fields] = useField("asOffer");
  const mandatoryFieldsCRM = useMandatoryfields();
  const { mandatoryLabel } = useGeneralLabel();

  return (
    <div className={`grid gap-x-4 gap-y-2 ${gridCols[config.media ?? "desktop"]}`}>
      <Headline>{contactData}</Headline>
      <div className="col-span-full">
        <Input
          name="email"
          label={email + (!fields?.value && mandatoryFieldsCRM?.includes("la_email") ? mandatoryLabel : "")}
          placeholder="E-Mail"
        />
      </div>
      {mailRepetition && (
        <div className="col-span-full">
          <Input
            name="confirmationEmail"
            label={emailValidate + (!fields?.value && mandatoryFieldsCRM?.includes("la_email") ? mandatoryLabel : "")}
            placeholder="E-Mail-Adresse bestätigen"
          />
        </div>
      )}
      <div>
        <Input
          name="mobileNumber"
          label={
            mobileNumber + (!fields?.value && mandatoryFieldsCRM?.includes("la_mobilfunknummer") ? mandatoryLabel : "")
          }
          placeholder="Mobilnummer"
          onlyNumber
          maxLength={16}
        />
      </div>
      <div>
        <Input
          name="phoneNumber"
          label={
            phoneNumber + (!fields?.value && mandatoryFieldsCRM?.includes("la_telefonnummer") ? mandatoryLabel : "")
          }
          placeholder="Telefonnummer"
          onlyNumber
          maxLength={16}
        />
      </div>
      {showFax && (
        <div>
          <Input name="faxNumber" label={faxNumber} placeholder="Faxnummer" onlyNumber maxLength={16} />
        </div>
      )}
    </div>
  );
};

export default Contact;
