import React from "react";

const Subsection = ({ text, value }: { text: string; value: string }) => {
  return (
    <>
      <section className="flex justify-between items-center my-4">
        <section>{text}</section>
        <section className="flex text-right">{value}</section>
      </section>
    </>
  );
};

export default Subsection;
