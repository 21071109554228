import { UseQueryResult } from "react-query";

import usePhoneNumberService from "../../../hooks/usePhoneNumberService";
import { Data } from "../../../types/ValidationPhone";
import useFeaturesCC from "./useFeaturesCC";

export interface PhoneValidCheck {
  formattedValue: string;
  isOk: boolean;
  errorText: string;
}

const useCheckPhoneNumber = (value: string, areaCodeSwitch: number, isMobile: boolean) => {
  const res: PhoneValidCheck = { isOk: true, errorText: "", formattedValue: "" };
  const { invalidTelNumber,invalidMobileNumber,telNumberToShort, telNumberToLarge } = useFeaturesCC();
  const phoneCheck: UseQueryResult<Data | null, unknown> = usePhoneNumberService(
    value.length > 10 && areaCodeSwitch > 0 ? value : null
  );
  return () => {
    if (phoneCheck.data != null && areaCodeSwitch > 0) {
      if (
        (areaCodeSwitch === 2 && !phoneCheck?.data?.data?.data?.isValid) ||
        (areaCodeSwitch === 1 && isMobile === false && !phoneCheck?.data?.data?.data?.isFixedLineNumber) ||
        (areaCodeSwitch === 1 && isMobile === true && !phoneCheck?.data?.data?.data?.isMobileNumber)
      ) {
        res.errorText = isMobile ? invalidMobileNumber:invalidTelNumber;
        res.isOk = false;
      } else {
        if (phoneCheck?.data?.data.data?.phoneNumber) res.formattedValue = phoneCheck?.data?.data.data?.phoneNumber;

        if (
          phoneCheck?.data?.data?.data?.phoneLineNumber &&
          phoneCheck?.data?.data?.data?.phoneLineNumber?.length < 4
        ) {
          res.errorText = telNumberToShort;
          res.isOk = false;
        }
        if (
          phoneCheck?.data?.data?.data?.phoneLineNumber &&
          phoneCheck?.data?.data?.data?.phoneLineNumber?.length > 10
        ) {
          res.errorText = telNumberToLarge;
          res.isOk = false;
        }
      }
    }

    return res;
  };
};

export default useCheckPhoneNumber;
