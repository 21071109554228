import { useField } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import Input from "../../../../components/Input";
import { useConfig } from "../../../../context/Config";
import { useSelectTariff } from "../../../../context/Tariff";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import Headline from "../Headline";

const RelocationForm = () => {
  const { dateMoveIn, reasonToChangeMoveExistingCustomer, prevCustomerNum } = useFeaturesCC();
  const [{ value }] = useField("isExistingCustomer");
  const { selectedTariff } = useSelectTariff();
  const { config } = useConfig();
  const gridCols = { desktop: "grid-cols-4", tablet: "grid-cols-2", mobile: "grid-cols-1" };
  const { mandatoryLabel } = useGeneralLabel();
  const [fields] = useField("asOffer");
  const { responsiveColSpan } = useResponsiveClass();

  return (
    <section className={`col-span-full grid gap-x-4 gap-y-2 ${gridCols[config.media ?? "desktop"]}`}>
      <Headline>Umzug</Headline>
      {reasonToChangeMoveExistingCustomer && (
        <Checkbox
          name="isExistingCustomer"
          label={`Bestandskunde bei ${selectedTariff?.versorgerName}`}
          className="col-span-full"
        />
      )}

      <Input
        name="moveInDate"
        className="w-full"
        type="datepicker"
        label={dateMoveIn + (fields?.value ? "" : mandatoryLabel)}
      />
      {value && reasonToChangeMoveExistingCustomer && (
        <div className={responsiveColSpan("full", "1", "2")}>
          <Input
            className="w-full"
            name="customerNumber"
            label={prevCustomerNum + (fields?.value ? "" : mandatoryLabel)}
            inputType="otherNumber"
            maxLength={50}
          />
        </div>
      )}
    </section>
  );
};

export default RelocationForm;
