import { Formik, useField } from "formik";
import React, { useState } from "react";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Modal from "../../../../components/Modal";
import NoResult from "../../../../components/NoResult";
import { useConfig, useStepChange } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useCalcResult } from "../../../../context/CalculationResult";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSelectTariff } from "../../../../context/Tariff";
import scrollToTop from "../../../../utils/scrollToTop";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import useSubmitTariffResult from "../../hooks/useSubmitTariff";
import getValidationSchema from "../../validation/schema";
import AddressUsageInformation from "../AddressUsageInfo";
import CompareTariffInfo from "../CompareTariffInfo";
import InputFormModal from "../InputFormModal";
import ShowPriceType from "../ShowPriceType";
import TariffComparison from "../TariffComparison";
import Item from "./Item";

const ResultList = ({ data }) => {
  const [isShowInputTariff, setIsShowInputTariff] = useState(false);
  const [isShowChangeCompareTariff, setIsShowChangeCompareTariff] = useState(false);
  const [timeframeYear, setTimeframeYear] = useState(true);
  const [compare] = useField("compareType");
  const [compareType, setCompareType] = useState(compare);
  const [{ value: priceType }] = useField("priceType");
  const [{ value: customerType }] = useField("customerType");
  const { setStep } = useStepChange();
  const { setSelectTariff } = useSelectTariff();
  const { pricesNetGross, isShowNetGross } = useFeaturesResult();
  const { zipCodeInvalidMessageLabel, zipCodeValidationLabel } = useFeatureInputPage();
  const config = useConfig();
  const { mutateAsync: submitTariff } = useSubmitTariffResult();

  const { values: inputValues, setValues } = useTRInputContext();
  const validationSchema = getValidationSchema(zipCodeInvalidMessageLabel, zipCodeValidationLabel);
  const { setCalcParams } = useCalcParams();
  const { calcResult, setCalcResult } = useCalcResult();

  const isBrutto = React.useMemo(() => {
    if (isShowNetGross) {
      return priceType === "0";
    }
    if (pricesNetGross === "net") {
      return false;
    }
    if (pricesNetGross === "gross") {
      return true;
    }
    return customerType === "0";
  }, [priceType, customerType, pricesNetGross, isShowNetGross]);

  const handleChooseTariff = React.useCallback(
    (selectedTariff, strommixLight) => {
      setSelectTariff({ isBrutto, strommixLight, ...selectedTariff, boni: selectedTariff.boni ?? [] });
      setStep(3);
      scrollToTop();
    },
    [setSelectTariff, setStep, isBrutto]
  );
  const handleSubmit = async (values) => {
    const tariffData = await submitTariff(values);
    setCalcResult({ ...calcResult, results: tariffData?.data?.data?.calculationResult?.ergebnisContainer[0]?.ergebnis});
    if (tariffData?.data?.data?.calculationParameter) {
      setCalcParams({...tariffData?.data?.data?.calculationParameter, dynamischeAngaben: tariffData?.data?.data?.calculationParameter?? []});
    }
  };

  return (
    <>
      <div className={`flex gap-5 items-center ${config.media === "desktop" ? "flex-row" : "flex-col-reverse"}`}>
        <div className="grid grid-cols-4 gap-4 mb-8 items-stretch grow w-full">
          <Card
            id="info-data"
            className="rounded-xl col-span-2 cursor-pointer shadow-md"
            onClick={() => setIsShowInputTariff((prevState) => !prevState)}
          >
            <AddressUsageInformation />
          </Card>

          <Modal showDialog={isShowInputTariff} ariaLabel="modal-input-tariff">
            <Formik
              component={(props) => (
                <InputFormModal
                  values={props.values}
                  handleSubmit={props.handleSubmit}
                  setIsShowInputTariff={setIsShowInputTariff}
                />
              )}
              validationSchema={validationSchema}
              enableReinitialize
              initialValues={{
                city: inputValues?.city || "",
                street: inputValues?.street || "",
                zipCode: inputValues?.zipCode || "",
                houseNumber: inputValues?.houseNumber || "",
                meterType: inputValues?.meterType || "1,2",
                customerType: inputValues?.customerType || "0",
                oneTariff: inputValues?.oneTariff || "3.200",
                twoTariff: inputValues?.twoTariff || "0",
                tariffType: inputValues?.tariffType || "0",
                measurementType: inputValues?.measurementType || "0",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                setValues(values);
                await handleSubmit(values);
                setStep(2);
                setSubmitting(false);
              }}
            />
          </Modal>

          <Card
            className="rounded-xl col-span-2 cursor-pointer shadow-md"
            onClick={() => setIsShowChangeCompareTariff((prevState) => !prevState)}
          >
            <CompareTariffInfo timeframeYear={timeframeYear} compareType={compareType} />
          </Card>

          <Modal showDialog={isShowChangeCompareTariff} ariaLabel="modal-input-tariff">
            <TariffComparison
              setModalOpen={setIsShowChangeCompareTariff}
              timeframeYear={timeframeYear}
              compareType={compareType}
              setCompareType={setCompareType}
            />
          </Modal>
        </div>

        <div className="items-center rounded-md" role="group">
          <Button
            type="button"
            variant={timeframeYear ? "primary" : "unstyled"}
            className="px-4 py-2"
            onClick={() => setTimeframeYear(true)}
          >
            Jahr
          </Button>
          <Button
            type="button"
            variant={!timeframeYear ? "primary" : "unstyled"}
            className="px-4 py-2"
            onClick={() => setTimeframeYear(false)}
          >
            Monat
          </Button>
        </div>
      </div>

      <>
        {(data && data.length === 0) || !isShowNetGross ? null : (
          <div className={"flex lg:justify-end justify-center lg:-mt-16 mt-10"}>
            <ShowPriceType />
          </div>
        )}

        <div
          className={`grid gap-4 ${
            config.media === "desktop" ? "grid-cols-3" : config.media === "tablet" ? "grid-cols-2" : "grid-cols-1"
          }`}
        >
          {calcResult ? (
            calcResult.results
              .slice(0, -1)
              .map(
                (
                  {
                    gueltigVon,
                    versorgerName,
                    arbeitspreisBrutto,
                    arbeitspreisHTCtKWhNetto,
                    arbeitspreisHTCtKWhBrutto,
                    arbeitspreisNetto,
                    aufschlaege,
                    boni,
                    dynamischeAngaben,
                    ersparnisBrutto,
                    ersparnisNetto,
                    productDescription,
                    informationen,
                    gesamtpreisBrutto,
                    gesamtpreisNetto,
                    gesamtpreisOhneBoniBrutto,
                    gesamtpreisOhneBoniNetto,
                    isGrundversorgungsTarif,
                    isOekostromTarif,
                    isPaketTarif,
                    markenID,
                    vertragsverlaengerungID,
                    tarifCode,
                    tarifInfo,
                    tarifName,
                    vertragslaufzeitID,
                    versorgerID,
                    vertragslaufzeit,
                    tarifID,
                    kuendigungsfrist,
                    isVorkasseTarif,
                    kuendigungsfristID,
                    isVergleichsTarif,
                    vertragsverlaengerung,
                    variantenID,
                    isOekogasTarif,
                    tarifRootID,
                    grundpreisNetto,
                    grundpreisBrutto,
                    arbeitspreisNTCtKWhBrutto,
                    arbeitspreisNTCtKWhNetto,
                    arbeitspreisCtKWhBrutto,
                    arbeitspreisCtKWhNetto,
                    strommixLight,
                  },
                  index
                ) => {
                  return (
                    <Item
                      key={index}
                      gueltigVon={gueltigVon}
                      versorgerName={versorgerName}
                      arbeitspreisBrutto={arbeitspreisBrutto}
                      arbeitspreisHTCtKWhNetto={arbeitspreisHTCtKWhNetto}
                      arbeitspreisHTCtKWhBrutto={arbeitspreisHTCtKWhBrutto}
                      arbeitspreisCtKWhBrutto={arbeitspreisCtKWhBrutto}
                      arbeitspreisCtKWhNetto={arbeitspreisCtKWhNetto}
                      arbeitspreisNetto={arbeitspreisNetto}
                      aufschlaege={aufschlaege}
                      boni={boni}
                      timeframeYear={timeframeYear}
                      dynamischeAngaben={dynamischeAngaben}
                      ersparnisBrutto={ersparnisBrutto}
                      ersparnisNetto={ersparnisNetto}
                      productDescription={productDescription}
                      informationen={informationen}
                      gesamtpreisBrutto={gesamtpreisBrutto}
                      gesamtpreisNetto={gesamtpreisNetto}
                      gesamtpreisOhneBoniBrutto={gesamtpreisOhneBoniBrutto}
                      gesamtpreisOhneBoniNetto={gesamtpreisOhneBoniNetto}
                      isGrundversorgungsTarif={isGrundversorgungsTarif}
                      isOekostromTarif={isOekostromTarif}
                      isPaketTarif={isPaketTarif}
                      markenID={markenID}
                      vertragsverlaengerungID={vertragsverlaengerungID}
                      tarifCode={tarifCode}
                      tarifInfo={tarifInfo}
                      tarifName={tarifName}
                      vertragslaufzeitID={vertragslaufzeitID}
                      versorgerID={versorgerID}
                      vertragslaufzeit={vertragslaufzeit}
                      tarifID={tarifID}
                      kuendigungsfrist={kuendigungsfrist}
                      isVorkasseTarif={isVorkasseTarif}
                      kuendigungsfristID={kuendigungsfristID}
                      isVergleichsTarif={isVergleichsTarif}
                      vertragsverlaengerung={vertragsverlaengerung}
                      variantenID={variantenID}
                      isOekogasTarif={isOekogasTarif}
                      tarifRootID={tarifRootID}
                      grundpreisNetto={grundpreisNetto}
                      grundpreisBrutto={grundpreisBrutto}
                      arbeitspreisNTCtKWhBrutto={arbeitspreisNTCtKWhBrutto}
                      arbeitspreisNTCtKWhNetto={arbeitspreisNTCtKWhNetto}
                      strommixLight={strommixLight}
                      handleChooseTariff={(props) => handleChooseTariff(props, strommixLight)}
                    />
                  );
                }
              )
          ) : (
            <NoResult />
          )}
        </div>
      </>
    </>
  );
};

export default ResultList;
