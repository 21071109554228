import * as React from "react";

const OptionIcon = ({ fill = "var(--get-c-breadcrumb)" }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className="inline">
      <path
        d="M7.83333 14V10H9.16667V11.3333H14.5V12.6667H9.16667V14H7.83333ZM2.5 12.6667V11.3333H6.5V12.6667H2.5ZM5.16667 10V8.66667H2.5V7.33333H5.16667V6H6.5V10H5.16667ZM7.83333 8.66667V7.33333H14.5V8.66667H7.83333ZM10.5 6V2H11.8333V3.33333H14.5V4.66667H11.8333V6H10.5ZM2.5 4.66667V3.33333H9.16667V4.66667H2.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default OptionIcon;
