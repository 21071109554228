import * as React from "react";
import { useSelectTariff } from "../../../../context/Tariff";
import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesContactData = () => {
  const { selectedTariff } = useSelectTariff();
  const { data: config } = useExternalConfigs("contractconclusion");
  const { data: tariffConfig } = useExternalConfigs("contractconclusion", selectedTariff?.versorgerID);
  const contractConclusion = config?.data?.data?.contractConclusion;
  const tariffContractConclusion = tariffConfig?.data?.data?.contractConclusion;

  const resolveContractConclusion = React.useCallback(
    (key: string) => {
      return tariffContractConclusion?.[key] ?? contractConclusion?.[key];
    },
    [tariffContractConclusion, contractConclusion]
  );

  const defaultLabel = {
    birthday: "Geburtsdatum",
    companyName: "Firma",
    contactData: "Kontaktdaten",
    contactTitle: "Persönliche Daten",
    email: "E-Mail",
    emailIsIncorrect: "E-Mail-Format ungültig",
    emailRepeatIsIncorrect: "E-Mail-Adressen stimmen nicht überein",
    emailValidate: "E-Mail-Adresse bestätigen",
    faxNumber: "Faxnummer",
    firstName: "Vorname",
    invalidMobileNumber: "Mobilnummer ungültig",
    invalidTelNumber: "Telefonnummer ungültig",
    lastName: "Nachname",
    mobileNumber: "Mobilnummer",
    phoneNumber: "Telefonnummer",
    salutation: "Anrede",
    telNumberToLarge: "Eine Telefonnummer kann insgesamt maximal 15 Ziffern enthalten.",
    telNumberToShort: "Da scheint noch mindestens eine Ziffer zu fehlen.",
    title: "Titel",
    safetyIndex: "Sicherheitskennzahl",
  };

  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveContractConclusion(key) || defaultValue])
  ) as Label;

  return labels;
};
export default useFeaturesContactData;
