import React from "react";

import Card from "../../../../components/Card";
import FormRow from "../../../../components/FormRow";
import Input from "../../../../components/Input";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const InhaberForm = () => {
    const {
        firstNameLabel,
        lastNameLabel,
    } = useFeaturesCC();
    return (
        <Card className="mb-4">
            <div className="font-bold text-lg">Vormieter</div>
            <FormRow addClassName="flex flex-col sm:flex-row">
                <div className="mb-4 sm:mb-0 mr-0 sm:mr-4 sm:w-1/2 w-full">
                    <Input
                        name="inhaberFirstName"
                        label={firstNameLabel}
                        placeholder="Vorname"
                        inputType="name"
                    />
                </div>
                <div className="sm:w-1/2 w-full">
                    <Input name="inhaberLastName" label={lastNameLabel} placeholder="Nachname" inputType="name" />
                </div>
            </FormRow>
        </Card>
    );
};

export default InhaberForm;
