import * as React from "react";

import useResponsiveClass from "../../hooks/useResponsiveClass";

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const Container = ({ children, className, ...props }: ContainerProps) => {
  const { responsiveClass } = useResponsiveClass();
  return (
    <div
      className={responsiveClass(
        `w-full font-trfont max-w-[1536px] mx-auto ${className ?? ""}`,
        "px-2",
        "px-4",
        "px-4"
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
