import * as React from "react";

const Download = ({ variant = 0, className = "" }: { variant?: number; className?: string }) => {
  if (variant === 0) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
      >
        <path
          d="M7 17H17V15H7V17ZM12 14L16 10L14.6 8.6L13 10.15V6H11V10.15L9.4 8.6L8 10L12 14ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
          fill="#313131"
        />
      </svg>
    );
  }
  if (variant === 1) {
    return (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" className={"inline " + className}>
        <path
          d="M8.00032 10.666L4.66699 7.33268L5.60032 6.36601L7.33366 8.09935V2.66602H8.66699V8.09935L10.4003 6.36601L11.3337 7.33268L8.00032 10.666ZM4.00033 13.3327C3.63366 13.3327 3.31977 13.2021 3.05866 12.941C2.79755 12.6799 2.66699 12.366 2.66699 11.9993V9.99935H4.00033V11.9993H12.0003V9.99935H13.3337V11.9993C13.3337 12.366 13.2031 12.6799 12.942 12.941C12.6809 13.2021 12.367 13.3327 12.0003 13.3327H4.00033Z"
          fill="#9999A3"
        />
      </svg>
    );
  }
};

export default Download;
