import * as React from "react";
import { useSelectTariff } from "../../../../context/Tariff";
import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesAddressData = () => {
  const { selectedTariff } = useSelectTariff();
  const { data: config } = useExternalConfigs("contractconclusion");
  const { data: tariffConfig } = useExternalConfigs("contractconclusion", selectedTariff?.versorgerID);
  const contractConclusion = config?.data?.data?.contractConclusion;
  const tariffContractConclusion = tariffConfig?.data?.data?.contractConclusion;

  const resolveContractConclusion = React.useCallback(
    (key: string) => {
      return tariffContractConclusion?.[key] ?? contractConclusion?.[key];
    },
    [tariffContractConclusion, contractConclusion]
  );

  const defaultLabel = {
    addressData: "Adressdaten",
    addition: "Adresszusatz",
    advertisingConsentEmail: "per E-Mail",
    advertisingConsentPost: "per Post",
    billingSameAsDelivery: "Rechnungsanschrift identisch mit Lieferanschrift",
    city: "Ort",
    cityNotFound: "Eingabe ungültig",
    delieveryAddress: "Lieferanschrift",
    houseNumber: "Hausnummer",
    invoiceDelivery: "Zustellung der Rechnung",
    mailboxBilling: "Postfach",
    street: "Straße",
    zipCode: "Postleitzahl",
    installationLocation: "Wohnungs-/Briefkastennummer, Installationsort-Stockwerk",
    billingAddress: "Rechnungsadresse",
  };

  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveContractConclusion(key) || defaultValue])
  ) as Label;

  return labels;
};
export default useFeaturesAddressData;
