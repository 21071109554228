const css = String.raw;

const defaultTheme = css`
  :host {
    --default-input-margin: 0.625rem;
    --default-input-padding: 0.625rem;
    --default-color-primary: #ffab00;
    --default-color-secondary: inherit;
    --default-color-hover: #f18307;
    --default-color-focus: inherit;

    --get-text-light: var(--get-c-grey-light);
    --get-c-light: var(--gc-c-light, #fff);
    --get-c-grey: var(--gc-c-grey, #e0e6ed);
    --get-c-grey-light: var(--gc-c-grey-light, #f7f7f7);
    --get-c-grey-middle: var(--gc-c-grey-middle, #858585);
    --get-c-grey-strong: var(--gc-c-grey-strong, #4e4e57);
    --get-c-text-normal: var(--gc-c-text-normal, #313131);
    --get-c-light-orange: #fff6e5;

    --get-c-primary-normal: var(
      --gc-c-primary-normal,
      var(--default-color-primary)
    );
    --get-c-primary-hover: var(
      --gc-c-primary-hover,
      var(--default-color-hover)
    );
    --get-c-primary-focus: var(
      --gc-c-primary-focus,
      var(--default-color-focus)
    );

    --get-c-secondary-normal: var(
      --gc-c-secondary-normal,
      var(--default-color-secondary)
    );
    --get-c-secondary-hover: var(
      --gc-c-secondary-normal,
      var(--default-color-hover)
    );
    --get-c-secondary-focus: var(--gc-c-secondary-focus, --default-color-focus);

    --get-v-font: var(--gc-v-font, Roboto);

    --get-v-rounded-default: var(--gc-v-rounded-default, 0.25em);
    --get-v-rounded-input: var(
      --gc-v-rounded-input,
      var(--gc-v-rounded-default, 0.25rem)
    );
    --get-v-rounded-button: var(
      --gc-v-rounded-button,
      var(--gc-v-rounded-default, 0.25rem)
    );

    --get-content-bg: var(--gc-content-bg, #fff);
    --get-content-border: var(--gc-content-border, var(--get-c-grey));
    --get-content-radius: var(
      --gc-content-radius,
      var(--get-v-rounded-default, 0.25rem)
    );
    --get-content-borderWidth: var(--gc-content-borderWidth, 1px);
    --get-content-boxShadow: var(
      --gc-content-boxShadow,
      0 3px 10px rgb(0 0 0 / 0.2)
    );

    --get-nav-bg: var(--gc-c-nav-bg, #fff);
    --get-nav-normal: var(--gc-c-nav-normal, var(--get-c-text-normal));
    --get-nav-active: var(--gc-nav-active, var(--get-c-primary-normal));

    --get-input-border: var(--gc-input-border, var(--get-c-grey-strong));
    --get-input-color: var(--gc-input-color, var(--get-c-text-normal));
    --get-input-bg: var(--gc-input-bg, var(--get-c-light));
    --get-input-label: var(--gc-input-label, var(--get-c-text-normal));
    --get-input-margin: var(--gc-input-margin, 0.625rem);
    --get-input-padding: var(--gc-input-padding, 0.625rem);

    --get-button-primary-color: var(
      --gc-button-primary-color,
      var(--get-c-light)
    );
    --get-button-primary-bg: var(
      --gc-button-primary-bg,
      var(--get-c-primary-normal)
    );
    --get-button-primary-bg-light: var(--gc-button-primary-bg-light, #ffeecc);
    --get-button-primary-bgHover: var(
      --gc-button-primary-bgHover,
      var(--get-c-primary-hover)
    );
    --get-button-primary-borderColor: var(
      --gc-button-primary-borderColor,
      var(--get-c-primary-normal)
    );
    --get-button-primary-borderColorHover: var(
      --gc-button-primary-borderColorHover,
      var(--get-c-primary-normal)
    );

    --get-stroke-color: var(--gc-stroke-color, var(--get-c-primary-normal));

      --tw-translate-x: 0;
      --tw-translate-y: 0;
      --tw-rotate: 0;
      --tw-skew-x: 0;
      --tw-skew-y: 0;
      --tw-scale-x: 1;
      --tw-scale-y: 1;
    
  }
`;
export default defaultTheme;
