import { FormikValues, useFormikContext } from "formik";
import qs from "query-string";
import { useMemo } from "react";

import { useParameter, useTariff } from "../../../context";
import convertDate from "../../../utils/convertDate";
import {isValidDateAsString} from "../../../utils/isValidDate";
import useFeaturesResult from "../../TCResults/hooks/useFeaturesResult";

const useQueryParameters = () => {
  const { values } = useFormikContext<FormikValues>();
  const { paging } = useFeaturesResult();
  const { plz, oeko } = useParameter();
  const tariff = useTariff() ;
  return useMemo(() => {
    return qs.stringify({
      calculatorType: tariff.id,
      customerType: values.customerType,
      zipCode: plz || values.zipCode,
      ...(values.city.value ? { city: values.city?.value } : {}),
      ...(values.street.value ? { street: values.street.value } : {}),
      ...(values.houseNumber
        ? {
            hnr: (typeof values.houseNumber !== "string" && values.houseNumber.value) || values.houseNumber,
          }
        : {}),
      primeTimeConsumption: tariff.id === "7" ? 20000 : parseInt(values.oneTariff.replace(".", "")),
      ...(oeko ? { eco: oeko } : {}),
      ...(values.meterType === "3,4" ? { secondaryTimeConsumption: values.twoTariff?.replace(".", "") } : {}),
      page: 1,
      gridConnectionType: 0,
      pageSize: paging ?? 20,
      ...(tariff.id !== "2" && tariff.id !== "7" ? { zaehlerart: values.meterType } : {}),
      ...(values.meterType === "3,4" ? { tariftyp: "1,2" } : {}),
      ...(tariff.id === "3" ? { tariftyp: values.tariffType } : {}),
      ...(isValidDateAsString(values.stichtag)  ? { stichtag: convertDate(values.stichtag) } : {}),
      ...(tariff.id === "4" && values.measurementType === "1" ? { tariftyp: "5" } : {}),
      // TELCO
      ...(tariff.id === "7" && values.houseNumber
        ? {
            houseNumberComplete: (typeof values.houseNumber === "string" && values.houseNumber) || values.houseNumber.value,
          }
        : {}),
    });
  }, [
    oeko,
    plz,
    tariff.id,
    values.city,
    values.customerType,
    values.houseNumber,
    values.oneTariff,
    values.street,
    values.twoTariff,
    values.zipCode,
    values.meterType,
    paging,
    values.tariffType,
    values.stichtag,
      values.measurementType
  ]);
};

export default useQueryParameters;
