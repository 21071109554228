const css = String.raw;

const defaultTheme = css`
  :host {
    --get-c-primary: #ffab00;
    --get-c-primary-hover: #f18307;
    --get-c-primary-text: #313131;

    --get-c-secondary: #ffeecc;
    --get-c-secondary-light: #fffaf0;
    --get-c-secondary-hover: #ffdd99;
    --get-c-secondary-text: #313131;

    --get-c-content-bg: #ffffff;
    --get-c-content-text: #313131;
    --get-c-content-gray: #d4d4d8;
    --get-c-content-gray-light: #fafafa;
    --get-c-content-gray-dark: #6b6b77;

    --get-c-statistics-1: #3966aa;
    --get-c-statistics-2: #f18307;
    --get-c-statistics-3: #ffab00;

    --get-c-breadcrumb: var(--get-c-content-text);
    --get-c-breadcrumb-inactive: var(--get-c-content-gray);

    --get-v-font: var(--gc-v-font, Roboto);

    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
`;
export default defaultTheme;
