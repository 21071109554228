import isValidDate from "../../../../utils/isValidDate";
import isValidDesiredDate from "../../../../utils/isValidDesiredDate";
import isValidEmail from "../../../../utils/isValidEmail";

const handleTerminateValidation = (values) => {
  let [dd, mm, yyyy] = values.birthday.split(".");
  const validDoB =
  values.birthday.length === 10 && isValidDate(dd, mm, yyyy);
  
  [dd, mm, yyyy] = values.desiredCancellationDate.split(".");
  const validDeliveryDate =
  values.desiredCancellationDate.length === 10 &&
  isValidDesiredDate(dd, mm, yyyy);
  
  const contact = !!values.Vorname && !!values.Name && validDoB;
  const validDeliveryAddress =
  !!values.PLZ && !!values.Ort && !!values.Strasse && !!values.Hausnummer;
  
  const vertrag = !!values.Versorger && !!values.Kundennummer;
  const isEmailValid = isValidEmail(values.Emailadresse) || false;
  
  const terminateValid =
  !!values.Grund &&
  (values.desiredCancellationDate === "true") === validDeliveryDate &&
  values.perMail === isEmailValid &&
  (values.perMail || values.perPost) &&
  values.isPrivacyPolicyCheck;
  
  return contact && validDeliveryAddress && vertrag && terminateValid;
};

export default handleTerminateValidation;
