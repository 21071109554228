import * as React from "react";

interface ButtonInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  variant?: "tabNav" | "unstyled" | "primary" | "secondary" | "rounded" | "householder" | undefined;
  children?: React.ReactNode;
}

const Button = ({ disabled = false, variant = undefined, className, type, children, ...props }: ButtonInterface) => {
  return (
    <>
      {variant === "tabNav" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled ? "bg-gray-light text-gray-dark" : "cursor-pointer"
          } border-0 rounded-full hover:bg-secondary px-5 py-1 ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}

      {variant === "unstyled" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled ? "bg-gray-light text-gray-dark" : "cursor-pointer bg-accent"
          } border-0 font-trfont rounded ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}

      {variant === "primary" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled
              ? "bg-gray-light text-gray-dark"
              : " cursor-pointer bg-primary text-primary-text hover:bg-primary-hover"
          } border-0 rounded-full font-trfont shadow-md px-5 py-1 ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}

      {variant === "secondary" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled ? "bg-gray-light text-gray-dark" : "cursor-pointer bg-secondary hover:bg-secondary-hover"
          } border-0 rounded-full px-5 py-1 shadow-md ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}

      {variant === "rounded" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled
              ? "bg-gray-light text-gray-dark"
              : "bg-secondary hover:bg-secondary-hover text-white cursor-pointer"
          } flex justify-center align-middle border-0 rounded-button font-trfont ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}

      {variant === "householder" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled
              ? "bg-gray-light text-gray-dark"
              : " cursor-pointer bg-secondary hover:bg-secondary-hover text-white"
          } border-0 rounded ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}

      {variant === undefined ? (
        <button
          {...props}
          disabled={disabled}
          className={`${
            disabled
              ? "bg-gray-light text-gray-dark"
              : "cursor-pointer bg-secondary hover:bg-secondary-hover text-white"
          } border-0 rounded-button ${className || ""}`.trim()}
          type={type || "button"}
        >
          {children}
        </button>
      ) : null}
    </>
  );
};
export default Button;
