import { useFormikContext } from "formik";
import * as React from "react";

import Button from "../../components/Button";
import Card from "../../components/Card";
import FormControl from "../../components/FormControl";
import { useStepChange } from "../../context";
import CancellationData from "./components/CancellationData";
import ContactData from "./components/ContactData";
import ContractInfo from "./components/ContractInfo";
import { FormularFormik } from "./types/FormularFormik";
import handleTerminateValidation from "./utils/handleValidation/handleTerminateValiation";

const InputForm = ({ values }: { values: FormularFormik }) => {
  const { submitForm } = useFormikContext<FormularFormik>();

  const disabled = React.useMemo(() => {
    return handleTerminateValidation(values);
  }, [values]);
  const { setStep } = useStepChange();

  return (
    <>
      <Card>
        <FormControl>
          <ContactData />
          <ContractInfo />
          <CancellationData />
        </FormControl>
        <small className="mt-6">
          Pflichtfelder sind mit einem Stern * gekennzeichnet.
        </small>
        <div>
          <Button
            className="mt-4 mr-4"
            variant="primary"
            onClick={() => setStep(1)}
          >
            Zurück
          </Button>
          <Button
            className="mt-4"
            variant="primary"
            // type="submit"
            disabled={!disabled}
            onClick={() => {
              submitForm();
            }}
          >
            Jetzt Kündigungsanfrage absenden
          </Button>
        </div>
      </Card>
    </>
  );
};

export default InputForm;
