import * as React from "react";

import CheckMark from "../icons/checkmark";
import ExclamationIcon from "../icons/exclamationMark";
import Info from "../icons/Info";
import WarningIcon from "../icons/warning";

const NotificationBox = ({
  text,
  variant = "info",
  className = "",
}: {
  text: string;
  variant: "info" | "success" | "warn" | "error";
  className?: string;
}) => {
  const variantClassName = {
    info: "outline-blue-400 bg-blue-50",
    success: "outline-green-400 bg-green-50",
    warn: "outline-yellow-400 bg-yellow-50",
    error: "outline-red-400 bg-red-50",
  };
  const variantIcon = {
    info: <Info />,
    success: <CheckMark variant={1} />,
    warn: <WarningIcon fill="#eab308" />,
    error: <ExclamationIcon />,
  };
  return (
    <div
      className={`flex flex-row items-center gap-4 p-4 outline outline-1 rounded-lg w-full ${variantClassName[variant]} ${className}`}
    >
      {variantIcon[variant]}
      <span>{text}</span>
    </div>
  );
};

export default NotificationBox;
