import { Formik, useField, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Modal from "../../../../components/Modal";
import NoResult from "../../../../components/NoResult";
import { useStepChange } from "../../../../context";
import { useCalcResult } from "../../../../context/CalculationResult";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSelectTariff } from "../../../../context/Tariff";
import Tariff from "../../../../types/Tariff";
import convertLocalizedStringToNumber from "../../../../utils/convertLocalizedStringToNumber";
import formatDecimal from "../../../../utils/formatDecimal";
import scrollToTop from "../../../../utils/scrollToTop";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import { stromMixLight } from "../../../TCInputs/types/SimplifierResponse";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import useGetNextResultPage from "../../hooks/useGetNextResultPage";
import useSubmitTariffResult from "../../hooks/useSubmitTariff";
import { ResultPageFormik } from "../../types/ResultPageFormik";
import { TariffComparisonModalFormik } from "../../types/TariffComparisonModalFormik";
import getValidationSchema from "../../validation/schema";
import AddressUsageInformation from "../AddressUsageInfo";
import CompareTariffInfo from "../CompareTariffInfo";
import InputFormModal from "../InputFormModal";
import ShowPriceType from "../ShowPriceType";
import TariffComparison from "../TariffComparison";
import Item from "./Item";
import TimeFrameSwitch from "./TimeFrameSwitch";

const ResultList = ({ data }: { data: Tariff[] }) => {
  const [loading, setLoading] = useState(false);
  const [isShowInputTariff, setIsShowInputTariff] = useState(false);
  const [isShowChangeCompareTariff, setIsShowChangeCompareTariff] = useState(false);
  const [timeframeYear, setTimeframeYear] = useState(true);
  const { setValues: setOuterFormikValues, values } = useFormikContext<ResultPageFormik>();
  const [{ value: priceType }] = useField("priceType");
  const { setStep } = useStepChange();
  const { setSelectTariff } = useSelectTariff();
  const { pricesNetGross, showNetGross } = useFeaturesResult();
  const { zipCodeInvalidMessage, zipCodeValidationText } = useFeatureInputPage();
  const { focusPoint, hideComparisonSelect, showMore } = useFeaturesResult();
  const { config } = useConfig();
  const { mutateAsync: submitTariff } = useSubmitTariffResult(timeframeYear);
  const { values: inputValues, setValues } = useTRInputContext();
  const validationSchema = getValidationSchema(zipCodeInvalidMessage, zipCodeValidationText);
  const { calcResult, setCalcResult } = useCalcResult();
  const { fetchNextPage } = useGetNextResultPage();
  const scrollRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (focusPoint && document.documentElement.scrollTop === 0 && scrollRef?.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBrutto = React.useMemo(() => {
    if (showNetGross) {
      return priceType === "0";
    }
    if (pricesNetGross === "net") {
      return false;
    }
    if (pricesNetGross === "gross") {
      return true;
    }
    return inputValues?.customerType === "0";
  }, [priceType, inputValues?.customerType, pricesNetGross, showNetGross]);

  const handleChooseTariff = React.useCallback(
    (selectedTariff: Tariff, strommixLight: stromMixLight) => {
      setSelectTariff({
        ...selectedTariff,
        isBrutto,
        strommixLight,
        boni: selectedTariff.boni ?? [],
      });
      setStep(3);
      scrollToTop();
    },
    [setSelectTariff, setStep, isBrutto]
  );

  const handleCompareTariffSubmit = async (compareValues: TariffComparisonModalFormik) => {
    setLoading(true);
    setIsShowChangeCompareTariff(false);
    await setOuterFormikValues({ ...values, ...compareValues });
    await submitTariff(compareValues);
    setLoading(false);
  };

  const inputTariffRef = useRef<HTMLDivElement>(null);
  const compareTariffRef = useRef<HTMLDivElement>(null);

  const loadNextPage = async () => {
    const { data } = await fetchNextPage();
    setCalcResult({
      ...calcResult,
      results: calcResult?.results.concat(
        data?.pages?.at(-1)?.data?.data?.calculationResult?.ergebnisContainer?.[0]?.ergebnis
      ),
      type: "energie",
      ergebnisseGesamtAnzahl: data?.pages?.at(-1)?.data?.data?.calculationResult?.ergebnisseGesamtAnzahl,
    });
  };

  const isLastRow = (index: number) => {
    const numberOfElements = calcResult.results?.filter((item) => item?.isVergleichsTarif === false).length;
    const rows = config.media === "desktop" ? 3 : config.media === "tablet" ? 2 : 1;
    const startOfLastLine = numberOfElements - (numberOfElements % rows || rows);
    return index >= startOfLastLine;
  };
  return (
    <>
      {loading && <LoadingOverlay />}
      <div className={`flex gap-5 items-center ${config.media === "desktop" ? "flex-row" : "flex-col-reverse"}`}>
        <div
          className={`${config.media === "desktop" ? "grid-cols-3" : ""} ${
            config.media === "tablet" ? "grid-cols-2" : ""
          } ${config.media === "mobile" ? "grid-cols-1" : ""} grid gap-4 items-stretch grow w-full`}
        >
          <div
            className="flex flex-col"
            ref={inputTariffRef}
            style={config.media !== "mobile" ? { minHeight: compareTariffRef?.current?.offsetHeight ?? 0 } : {}}
          >
            <Modal
              visible={isShowInputTariff}
              setVisible={setIsShowInputTariff}
              showChildrenDefault={true}
              position={{
                left: inputTariffRef?.current?.offsetLeft ?? 200,
                right: inputTariffRef?.current?.offsetTop ?? 200,
                width: inputTariffRef?.current?.offsetWidth ?? 400,
              }}
            >
              <Card
                id="addressUsageInfo"
                className={`h-full ${!isShowInputTariff ? "cursor-pointer" : ""} shadow-md`}
                onClick={() => {
                  !isShowInputTariff ? setIsShowInputTariff(true) : "";
                }}
              >
                <div className="h-full" ref={ref}>
                  <AddressUsageInformation
                    isShowInputTariff={isShowInputTariff}
                    minHeight={ref?.current?.offsetHeight}
                  />
                </div>
                <Formik
                  component={() =>
                    isShowInputTariff ? <InputFormModal setIsShowInputTariff={setIsShowInputTariff} /> : <></>
                  }
                  validationSchema={validationSchema}
                  enableReinitialize
                  initialValues={{
                    city: inputValues?.city,
                    street: inputValues?.street,
                    zipCode: inputValues?.zipCode || "",
                    houseNumber: inputValues?.houseNumber || "",
                    meterType: inputValues?.meterType || "1,2",
                    customerType: inputValues?.customerType || "0",
                    oneTariff: inputValues?.oneTariff || "3.200",
                    twoTariff: inputValues?.twoTariff || "",
                    tariffType: inputValues?.tariffType || "0",
                    measurementType: inputValues?.measurementType || "0",
                    stichtag: inputValues?.stichtag ?? "",
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    setValues({ ...inputValues, ...values });
                    await submitTariff(values);
                    setSubmitting(false);
                  }}
                />
              </Card>
            </Modal>
            {config.media === "tablet" && (
              <div>
                <TimeFrameSwitch timeframeYear={timeframeYear} setTimeframeYear={setTimeframeYear} />
              </div>
            )}
          </div>
          {hideComparisonSelect === false && (
            <div
              ref={compareTariffRef}
              className={`${config.media === "desktop" ? "col-span-2" : ""}`}
              style={config.media != "mobile" ? { minHeight: inputTariffRef?.current?.offsetHeight ?? 0 } : {}}
            >
              <Modal
                visible={isShowChangeCompareTariff}
                setVisible={setIsShowChangeCompareTariff}
                showChildrenDefault={true}
                position={{
                  left: compareTariffRef?.current?.offsetLeft ?? 200,
                  right: compareTariffRef?.current?.offsetTop ?? 200,
                  width: compareTariffRef?.current?.offsetWidth ?? 400,
                }}
              >
                <Card
                  className={`${!isShowChangeCompareTariff ? "cursor-pointer" : ""} h-full`}
                  onClick={() => {
                    !isShowChangeCompareTariff ? setIsShowChangeCompareTariff(true) : "";
                  }}
                  id="compareTariffInfo"
                >
                  <CompareTariffInfo
                    timeframeYear={timeframeYear}
                    isBrutto={isBrutto}
                    isShowChangeCompareTariff={isShowChangeCompareTariff}
                  />
                  <Formik
                    initialValues={{
                      compareType: values?.tariffComparison === "Vergleichstarif" ? "1" : "0",
                      supplier: values.supplier,
                      tariffComparison: values.tariffComparison,
                      workingPriceBrutto: values.workingPriceBrutto,
                      workingPriceNetto: values.workingPriceNetto,
                      // workingPriceNTBrutto: values.workingPriceBrutto,
                      // workingPriceNTNetto: values.workingPriceNetto,
                      basePriceBrutto: timeframeYear
                        ? values.basePriceBrutto
                        : formatDecimal(convertLocalizedStringToNumber(values.basePriceBrutto) / 12),
                      basePriceNetto: timeframeYear
                        ? values.basePriceNetto
                        : formatDecimal(convertLocalizedStringToNumber(values.basePriceNetto) / 12),
                      tariffComparisonID: values.tariffComparisonID,
                      city: inputValues?.city,
                      street: inputValues?.street,
                      zipCode: inputValues?.zipCode || "",
                      oneTariff: inputValues?.oneTariff || "3.200",
                      twoTariff: inputValues?.twoTariff || "",
                      tariffType: inputValues?.tariffType || "0",
                    }}
                    component={(props) =>
                      isShowChangeCompareTariff ? (
                        <TariffComparison
                          setModalOpen={setIsShowChangeCompareTariff}
                          timeframeYear={timeframeYear}
                          isBrutto={isBrutto}
                          submitForm={props.handleSubmit}
                        />
                      ) : (
                        <></>
                      )
                    }
                    onSubmit={async (values, { setSubmitting }) => {
                      setSubmitting(true);
                      await handleCompareTariffSubmit(values);
                      setSubmitting(false);
                    }}
                  />
                </Card>
              </Modal>
            </div>
          )}
        </div>
      </div>
      {config.media !== "tablet" && (
        <TimeFrameSwitch timeframeYear={timeframeYear} setTimeframeYear={setTimeframeYear} />
      )}
      <>
        {(data && data.length === 0) || !showNetGross ? null : (
          <div className={"flex lg:justify-end justify-center lg:-mt-16 mt-10"}>
            <ShowPriceType />
          </div>
        )}
        <div
          ref={scrollRef}
          className={`grid gap-4 mt-4 ${
            config.media === "desktop" ? "grid-cols-3" : config.media === "tablet" ? "grid-cols-2" : "grid-cols-1"
          }`}
        >
          {calcResult ? (
            calcResult.results
              ?.filter((item) => item?.isVergleichsTarif === false)
              .map(
                (
                  {
                    gueltigVon,
                    versorgerName,
                    arbeitspreisBrutto,
                    arbeitspreisHTCtKWhNetto,
                    arbeitspreisHTCtKWhBrutto,
                    arbeitspreisNetto,
                    aufschlaege,
                    boni,
                    dynamischeAngaben,
                    ersparnisBrutto,
                    ersparnisNetto,
                    productDescription,
                    informationen,
                    gesamtpreisBrutto,
                    gesamtpreisNetto,
                    gesamtpreisOhneBoniBrutto,
                    gesamtpreisOhneBoniNetto,
                    isGrundversorgungsTarif,
                    isOekostromTarif,
                    isPaketTarif,
                    markenID,
                    vertragsverlaengerungID,
                    tarifCode,
                    tarifInfo,
                    tarifName,
                    vertragslaufzeitID,
                    versorgerID,
                    vertragslaufzeit,
                    tarifID,
                    kuendigungsfrist,
                    isVorkasseTarif,
                    kuendigungsfristID,
                    isVergleichsTarif,
                    vertragsverlaengerung,
                    variantenID,
                    isOekogasTarif,
                    tarifRootID,
                    grundpreisNetto,
                    grundpreisBrutto,
                    arbeitspreisNTCtKWhBrutto,
                    arbeitspreisNTCtKWhNetto,
                    arbeitspreisCtKWhBrutto,
                    arbeitspreisCtKWhNetto,
                    strommixLight,
                  },
                  index
                ) => {
                  return (
                    <Item
                      key={index}
                      gueltigVon={gueltigVon}
                      versorgerName={versorgerName}
                      arbeitspreisBrutto={arbeitspreisBrutto}
                      arbeitspreisHTCtKWhNetto={arbeitspreisHTCtKWhNetto}
                      arbeitspreisHTCtKWhBrutto={arbeitspreisHTCtKWhBrutto}
                      arbeitspreisCtKWhBrutto={arbeitspreisCtKWhBrutto}
                      arbeitspreisCtKWhNetto={arbeitspreisCtKWhNetto}
                      arbeitspreisNetto={arbeitspreisNetto}
                      aufschlaege={aufschlaege}
                      boni={boni}
                      timeframeYear={timeframeYear}
                      dynamischeAngaben={dynamischeAngaben}
                      ersparnisBrutto={ersparnisBrutto}
                      ersparnisNetto={ersparnisNetto}
                      productDescription={productDescription}
                      informationen={informationen}
                      gesamtpreisBrutto={gesamtpreisBrutto}
                      gesamtpreisNetto={gesamtpreisNetto}
                      gesamtpreisOhneBoniBrutto={gesamtpreisOhneBoniBrutto}
                      gesamtpreisOhneBoniNetto={gesamtpreisOhneBoniNetto}
                      isGrundversorgungsTarif={isGrundversorgungsTarif}
                      isOekostromTarif={isOekostromTarif}
                      isPaketTarif={isPaketTarif}
                      markenID={markenID}
                      vertragsverlaengerungID={vertragsverlaengerungID}
                      tarifCode={tarifCode}
                      tarifInfo={tarifInfo}
                      tarifName={tarifName}
                      vertragslaufzeitID={vertragslaufzeitID}
                      versorgerID={versorgerID}
                      vertragslaufzeit={vertragslaufzeit}
                      tarifID={tarifID}
                      kuendigungsfrist={kuendigungsfrist}
                      isVorkasseTarif={isVorkasseTarif}
                      kuendigungsfristID={kuendigungsfristID}
                      isVergleichsTarif={isVergleichsTarif}
                      vertragsverlaengerung={vertragsverlaengerung}
                      variantenID={variantenID}
                      isOekogasTarif={isOekogasTarif}
                      tarifRootID={tarifRootID}
                      grundpreisNetto={grundpreisNetto}
                      grundpreisBrutto={grundpreisBrutto}
                      arbeitspreisNTCtKWhBrutto={arbeitspreisNTCtKWhBrutto}
                      arbeitspreisNTCtKWhNetto={arbeitspreisNTCtKWhNetto}
                      strommixLight={strommixLight}
                      handleChooseTariff={(props) => handleChooseTariff(props, strommixLight)}
                      lastRow={isLastRow(index)}
                    />
                  );
                }
              )
          ) : (
            <NoResult />
          )}
        </div>
        <div className="w-full mt-8 mb-8 text-center">
          {calcResult?.results?.filter((item) => item?.isVergleichsTarif === false)?.length <
            calcResult?.ergebnisseGesamtAnzahl - 1 && (
            <Button variant="secondary" onClick={() => loadNextPage()}>
              {showMore}
            </Button>
          )}
        </div>
      </>
    </>
  );
};

export default ResultList;
