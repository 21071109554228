import React from "react";

import FormControl from "../../../../components/FormControl";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import Headline from "../Headline";

interface ReasonToChangeBoxType {
  options: {
    label: string;
    value: string;
  }[];
}

const ReasonToChangeBox = ({ options }: ReasonToChangeBoxType) => {
  const { internalTariffChange, reasonToChangeHeadline } = useFeaturesCC();
  const { responsiveClass } = useResponsiveClass();

  if (!internalTariffChange) {
    options = options.filter((element) => element?.value !== "tarifwechsel");
  }
  return (
    <FormControl className="grid gap-2">
      <Headline>{reasonToChangeHeadline}</Headline>
      <div className={responsiveClass("gap-y-2 gap-x-4", "grid", "flex", "flex")}>
        {options.map((option) => (
          <FormikRadio key={option.value} label={option.label} name="reasonToChange" value={option.value} />
        ))}
      </div>
    </FormControl>
  );
};

export default ReasonToChangeBox;
