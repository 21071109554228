import * as React from "react";

import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";

import fontcss from "../../../public/index.css?inline";
import cssstyle from "./App.css?inline";
import ErrorBoundary from "./components/ErrorBoundary";

import Sun from "./components/icons/Sun";
import RootProvider from "./context/provider";
import { dark, itzehoe, newTheme, preisvergleich, swm, ugg } from "./theme";
import defaultTheme from "./theme/default";
import MainComponent from "./widgets";

const client = new QueryClient();

const ReactQueryProvider = ({ children, RQClient }: { children: React.ReactNode; RQClient: boolean }) =>
  RQClient ? <QueryClientProvider client={client}>{children}</QueryClientProvider> : <>{children}</>;

function App({
  theme = undefined,
  tariffcalculatortypeid = "1",
  tarifrootids = undefined,
  versorgerids = undefined,
  bonus = undefined,
  markenids = undefined,
  zaehlerart = undefined,
  baiextendedwhitelist = undefined,
  stichtag = undefined,
  tarifcode = undefined,
  handlingtarife = undefined,
  oeko = undefined,
  kundenart = undefined,
  tariftyp = undefined,
  tarifrootidsmindestfilter = undefined,
  style = undefined,
  authtoken = "",
  onlyinputtarget = undefined,
  plz = undefined,
  ort = undefined,
  strasse = undefined,
  hausnummer = undefined,
  vpptemplateid = undefined,
  vppcustomerid = undefined,
  vppsummaryurl = "",
  primaaddressid = undefined,
  cimurl = undefined,
  authtokenrefresh = undefined,
  vppauthtokenurl = undefined,
  externalofferid = undefined,
  verbrauchstrom = undefined,
  verbrauchgas = undefined
}) {
  const [media, setMedia] = React.useState<"desktop" | "tablet" | "mobile">("desktop");
  const [boxWidth, setBoxWidth] = React.useState(0);
  const sectionRef = React.useRef<HTMLElement>(null);
  const [customStyle, setCustomStyle] = React.useState("");
  const [inputSequence, setInputSequence] = React.useState("");

  const handleChangeLayout = React.useCallback(() => {
    if (sectionRef.current) {
      const clientWidth = sectionRef.current?.clientWidth;

      setBoxWidth(clientWidth);

      if (clientWidth && clientWidth >= 1280) {
        setMedia("desktop");
      }
      if (clientWidth && clientWidth <= 1280) {
        setMedia("tablet");
      }
      if (clientWidth && clientWidth <= 768) {
        setMedia("mobile");
      }
    }
  }, []);

  React.useEffect(() => {
    const htmlhead = document.querySelector("html>head");
    if (htmlhead) {
      htmlhead.insertAdjacentHTML("afterbegin", `<style>${fontcss}</style`);
    }
  }, []);

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(handleChangeLayout);
    if (sectionRef?.current) {
      resizeObserver.observe(sectionRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  });

  React.useEffect(() => {
    handleChangeLayout();
  }, [handleChangeLayout]);

  React.useEffect(() => {
    if (theme === "dark") setCustomStyle(dark);
    else if (theme === "ugg") setCustomStyle(ugg);
    else if (theme === "itzehoe") setCustomStyle(itzehoe);
    else if (theme === "newTheme") setCustomStyle(newTheme);
    else if (theme === "preisvergleich") setCustomStyle(preisvergleich);
    else if (theme === "swm") setCustomStyle(swm);
  }, [theme]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (
      event.key.length === 1 &&
      /^[a-zA-Z]$/.test(event.key) &&
      customStyle === "" &&
      (event.target as HTMLInputElement).name === "zipCode"
    ) {
      if (inputSequence.endsWith("darkmod") && event.key.toLowerCase() === "e") {
        if (!vppsummaryurl) {
          const htmlElement = document.getElementsByTagName("html")[0];
          htmlElement.setAttribute("style", "background-color: #10101f");
        }
        setCustomStyle(dark);
        setInputSequence("");
      }
      setInputSequence((value) => value + event.key.toLowerCase());
      if (inputSequence.length > 20) {
        setInputSequence((value) => value.slice(-20));
      }
    }
  };
  const resetCustomStyle = () => {
    if (!vppsummaryurl) {
      const htmlElement = document.getElementsByTagName("html")[0];
      htmlElement.setAttribute("style", "background-color: #faf5f0");
    }
    setCustomStyle("");
  };

  const canUseReactQuery = React.useMemo(
    () =>
      !!(
        typeof window !== "undefined" &&
        window.document &&
        window.document.getElementsByTagName("tariff-calculator-5")
      ),
    []
  );
  const mappedProps = {
    theme: theme,
    tariffCalculatorTypeId: tariffcalculatortypeid,
    tarifRootIDs: tarifrootids,
    versorgerIDs: versorgerids,
    bonus: bonus,
    markenIDs: markenids,
    zaehlerart: zaehlerart,
    baiextendedWhiteList: baiextendedwhitelist,
    stichtag: stichtag,
    tarifcode: tarifcode,
    handlingTarife: handlingtarife,
    oeko: oeko,
    kundenart: kundenart,
    tariftyp: tariftyp,
    tarifRootIDsMindestfilter: tarifrootidsmindestfilter,
    style: style,
    authToken: authtoken,
    onlyInputTarget: onlyinputtarget,
    plz: plz,
    ort: ort,
    strasse: strasse,
    hausnummer: hausnummer,
    vppTemplateId: vpptemplateid,
    vppCustomerId: vppcustomerid,
    vppSummaryUrl: vppsummaryurl,
    primaAddressId: primaaddressid,
    cimUrl: cimurl,
    authTokenRefresh: authtokenrefresh,
    vppAuthTokenUrl: vppauthtokenurl,
    externalOfferID: externalofferid,
    oneTariff:tariffcalculatortypeid==='2'?verbrauchgas:verbrauchstrom,

  };
  return (
    <section ref={sectionRef} onKeyDown={handleKeyDown}>
      <style>
        {defaultTheme}
        {customStyle}
        {cssstyle}
      </style>
      <ReactQueryProvider RQClient={canUseReactQuery}>
        <RootProvider {...mappedProps} boxWidth={boxWidth} media={media} reference={sectionRef}>
          <ErrorBoundary>
          <MainComponent />
          </ErrorBoundary>
          <Toaster position="bottom-right" />
        </RootProvider>
      </ReactQueryProvider>
      {customStyle === dark && theme !== "dark" && (
        <div className="absolute top-0 py-2" onClick={resetCustomStyle}>
          <Sun />
        </div>
      )}
    </section>
  );
}
export default App;
