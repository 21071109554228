import DOMPurify from "dompurify";
import parse from "html-react-parser";
import React from "react";

const htmlFrom = (htmlString) => {
  const cleanHtmlString = DOMPurify.sanitize(htmlString, {
    USE_PROFILES: { html: true },
  });
  return parse(cleanHtmlString);
};

const ReactHTMLParser = ({ children }) => {
  if (typeof children === "string") return <>{htmlFrom(children)}</>;

  if (typeof children === "object") {
    if (typeof children.props === "object") {
      return <>{htmlFrom(children.props.children)}</>;
    }
    return <>{htmlFrom(children.props)}</>;
  }

  return <div />;
};

export default ReactHTMLParser;
