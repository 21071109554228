import { useField, useFormikContext } from "formik";
import * as React from "react";
import { useQuery } from "react-query";

import { useConfig } from "../../../context/Config";
import useDebounce from "../../../hooks/useDebounce";
import ProxyInstance from "../../../services/proxy";

interface GetOrteByPlzResult {
  ortsname: string;
  gkz: string;
  ortsteil: string[];
  alort: string;
  ortsteilLength: number;
  plzLength: number;
}

const useQueryCityByZipCode = (fieldName: { city: string; houseNumber: string; street: string; zipCode: string }) => {
  const { setFieldValue } = useFormikContext();
  const { config } = useConfig();
  const [zipCode] = useField(fieldName.zipCode);
  const debounceValue = useDebounce(zipCode.value, 600);
  const [cityField, , { setValue: setCityValue }] = useField(fieldName?.city);
  const [, , { setValue: setStreetValue }] = useField(fieldName?.street);
  const [, , { setValue: setHouseNumberValue }] = useField(fieldName?.houseNumber);

  React.useEffect(() => {
    if (zipCode.value?.length < 5 && cityField.value !== "") {
      setCityValue("");
      setStreetValue("");
      setHouseNumberValue("");
    }
  }, [zipCode, cityField]);

  try {
    const { data: ortInfo } = useQuery(
      `GET_CITY_BY_${debounceValue}`,
      async () => {
        const { data } = await ProxyInstance(`/ws/locationinfo/getOrteByPlz?plz=${zipCode.value}`, {
          apikey: config.apikey || "",
        });

        const {
          data: { ort },
        }: {
          data: { ort: GetOrteByPlzResult[] | undefined };
        } = data;
        const [city] = ort && ort?.length >= 1 ? ort : [undefined];

        if (city === undefined) {
          setFieldValue(`zipCodeError_${fieldName.zipCode}`, false);
        } else {
          setFieldValue(`zipCodeError_${fieldName.zipCode}`, true);
        }
        return {
          ort: city?.ortsname,
          plz: zipCode.value,
          ortList:
            ort?.map((item: { ortsname: string }) => ({
              label: item.ortsname,
              value: item.ortsname,
            })) ?? [],
        };
      },
      {
        enabled: !!debounceValue && zipCode.value.length === 5,
        refetchOnWindowFocus: false,
      }
    );
    return useQuery(
      `GET_STREET_FROM_${fieldName.zipCode}_${ortInfo?.plz}_${cityField.value.value}`,
      async () => {
        try {
          const { data } = await ProxyInstance("/ws/locationinfo/getStrassenByPlzOrt", {
            method: "POST",
            body: JSON.stringify({
              plz: ortInfo?.plz,
              ort: cityField.value.value,
            }),
            apikey: config.apikey || "",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });

          const {
            data: { strasse },
          } = data;

          return {
            ortList: ortInfo?.ortList,
            plz: ortInfo?.plz,
            street:
              strasse?.map((item: { name: string }) => ({
                label: item.name,
                value: item.name,
              })) || [],
          };
        } catch (e) {
          console.error(e);
          throw e;
        }
      },
      {
        enabled: !!ortInfo && zipCode.value.length === 5,
        refetchOnWindowFocus: false,
      }
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default useQueryCityByZipCode;
