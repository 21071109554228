import React, { ReactNode } from "react";

const FormControl = ({ children, className = "", ...props }: {children?: ReactNode, className?: string, props?: any}) => {
  return (
    <section {...props} className={`form-control ${className || ""}`.trim()}>
      {children}
    </section>
  );
};

export default FormControl;
