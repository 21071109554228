import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { useField } from "formik";
import React from "react";
import { Doughnut } from "react-chartjs-2";

import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import CardExtend from "../../../../../components/CardExtend";
import Caret from "../../../../../components/icons/caret";
import { useTariff } from "../../../../../context";
import { useSupplierInfo } from "../../../../../context/SupplierInfo";
import useTarifCart from "../../../../../hooks/useTarifCart";
import useTarifCartGas from "../../../../../hooks/useTarifCartGas";
import useVPPCustomerService from "../../../../../hooks/useVPPCustomerService";
import formatCurrency from "../../../../../utils/formatCurrency";
import formatDecimal from "../../../../../utils/formatDecimal";
import formatValidDate from "../../../../../utils/formatValidDate";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import Bonus from "./Bonus";
import Eco from "./Eco";
import NameAndTariff from "./NameAndTariff";
import Price from "./Price";
import PriceGuarantee from "./PriceGuarantee";

ChartJS.register(ArcElement, Tooltip);

const Item = ({
  gueltigVon,
  versorgerName,
  arbeitspreisBrutto,
  arbeitspreisCtKWhBrutto,
  arbeitspreisCtKWhNetto,
  arbeitspreisHTCtKWhNetto,
  arbeitspreisHTCtKWhBrutto,
  arbeitspreisNetto,
  aufschlaege,
  boni,
  dynamischeAngaben,
  ersparnisBrutto,
  ersparnisNetto,
  productDescription,
  informationen,
  gesamtpreisBrutto,
  gesamtpreisNetto,
  gesamtpreisOhneBoniBrutto,
  gesamtpreisOhneBoniNetto,
  isGrundversorgungsTarif,
  isOekostromTarif,
  isPaketTarif,
  markenID,
  vertragsverlaengerungID,
  tarifCode,
  tarifInfo,
  tarifName,
  vertragslaufzeitID,
  versorgerID,
  vertragslaufzeit,
  tarifID,
  kuendigungsfrist,
  isVorkasseTarif,
  kuendigungsfristID,
  isVergleichsTarif,
  vertragsverlaengerung,
  variantenID,
  isOekogasTarif,
  tarifRootID,
  grundpreisNetto,
  grundpreisBrutto,
  arbeitspreisNTCtKWhBrutto,
  arbeitspreisNTCtKWhNetto,
  handleChooseTariff,
  strommixLight,
  timeframeYear,
}) => {
  const [{ value: priceType }] = useField("priceType");
  const [{ value: customerType }] = useField("customerType");
  const { supplierInformation } = useSupplierInfo();
  const primaryRef = React.useRef();
  const secondaryRef = React.useRef();
  const [showTariffDetails, setShowTariffDetails] = React.useState(false);
  const {
    workingpriceLabel,
    nuclearLabel,
    renewableLabel,
    fossilLabel,
    radioactivLabel,
    co2Label,
    co2unitLabel,
    renewableunitLabel,
    nuclearunitLabel,
    fossilunitLabel,
    ecoTarifLabel,
    showTariffDetailLabel,
    standLabel,
    strommixLabel,
    tieredPricingLabel,
    consumptionLabel,
    yearlyBasePriceLabel,
    orderNowLabel,
    pricesInclTaxLabel,
    pricesExclTaxLabel,
    pricesNetGross,
    isShowNetGross,
  } = useFeaturesResult();
  const isBrutto = React.useMemo(() => {
    if(isShowNetGross){
      return priceType === "0"
    }
    if(pricesNetGross === "net"){
      return false;
    }
    if(pricesNetGross === "gross"){
      return true;
    }
    return customerType === "0"
  }
  , [priceType, customerType, pricesNetGross, isShowNetGross]);

  const { data: vppData } = useVPPCustomerService();

  const handlingTariff = React.useMemo(() => {
    return dynamischeAngaben
      .find((v) => v.stringObj)
      ?.stringObj?.find((d) => d.name === "handling" && d.wert === "true");
  }, [dynamischeAngaben]);

  const discount = React.useMemo(() => {
    const res = dynamischeAngaben
      .map((v) => v.doubleObj)
      .filter(Boolean)
      .flat(Number.POSITIVE_INFINITY)
      .find((d) =>
        isBrutto
          ? d.name === "gesamtpreisMonatOhneBoniBrutto"
          : d.name === "gesamtpreisMonatOhneBoniNetto"
      );

    return res.wert.toFixed(0).replace(".", ",");
  }, [dynamischeAngaben, isBrutto]);

  const isChartDataAvailable = React.useMemo(() => {
    return (
      strommixLight?.erneuerbare ||
      strommixLight?.fossile ||
      strommixLight?.kernkraft ||
      false
    );
  }, [
    strommixLight?.erneuerbare,
    strommixLight?.fossile,
    strommixLight?.kernkraft,
  ]);

  const primaryBackgroundColor =
    primaryRef?.current &&
    getComputedStyle(primaryRef?.current).backgroundColor;

  const secondaryBackgroundColor =
    secondaryRef?.current &&
    getComputedStyle(secondaryRef?.current).backgroundColor;

  const chartData = strommixLight && {
    labels: Object.keys(strommixLight)
      .filter((v) => v !== "stand" && v !== "dynamischeAngaben")
      .map((v) => v[0].toUpperCase() + v.slice(1)),
    datasets: [
      {
        data: Object.entries(strommixLight).reduce((acc, [key, value]) => {
          if (value === null || key === "stand") return acc;

          return [...acc, { label: key, value }];
        }, []),
        backgroundColor: [
          secondaryBackgroundColor,
          primaryBackgroundColor,
        ].filter(Boolean),
      },
    ],
  };

  const associationNumberFromVPP = React.useMemo(() => {
    if (vppData) {
      if (vppData.data.object.person.templateData[0].data.orderdetails_gas) {
        return vppData.data.object.person.templateData[0].data.orderdetails_gas
          .netzbetreiber.verbandsnummer;
      }

      if (
        vppData.data.object.person.templateData[0].data.orderdetails_electricity
      ) {
        return vppData.data.object.person.templateData[0].data
          .orderdetails_electricity.netzbetreiber.verbandsnummer;
      }
    }

    return "";
  }, [vppData]);

  const tariff = useTariff();
  const { data, refetch } = useTarifCart(tarifRootID, variantenID);
  const { data: dataGas, refetch: refetchGas } = useTarifCartGas(
    tarifRootID,
    variantenID
  );

  const tableBody = React.useMemo(
    () =>
      tariff.tariffType === "gas"
        ? dataGas?.data?.tarifkarten[0]?.tarifkarte[0]?.preisStaffel
        : data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[0]?.preisStaffeln,
    [data?.data?.tarifkarten, dataGas?.data?.tarifkarten, tariff.tariffType]
  );
  const handleChange = () => {
    setShowTariffDetails((prevState) => !prevState);

    if (!showTariffDetails) {
      tariff.tariffType === "gas" ? refetchGas() : refetch();
    }
  };

  return (
    <Card className="mb-4 rounded-xl grid" key={tarifID}>
      <div>
        <div>
          <NameAndTariff versorgerName={versorgerName} tarifName={tarifName} />

          <Eco
            eco={{ isOekostromTarif, isOekogasTarif }}
            ecoTarifLabel={ecoTarifLabel}
          />

          <Price
            discount={discount}
            isBrutto={isBrutto}
            timeframeYear={timeframeYear}
            gesamtpreisBrutto={gesamtpreisBrutto}
            gesamtpreisNetto={gesamtpreisNetto}
            ersparnisBrutto={ersparnisBrutto}
            ersparnisNetto={ersparnisNetto}
            grundpreisBrutto={grundpreisBrutto}
            grundpreisNetto={grundpreisNetto}
            arbeitspreisHTCtKWhBrutto={arbeitspreisHTCtKWhBrutto}
            arbeitspreisCtKWhBrutto={arbeitspreisCtKWhBrutto}
            arbeitspreisHTCtKWhNetto={arbeitspreisHTCtKWhNetto}
            arbeitspreisCtKWhNetto={arbeitspreisCtKWhNetto}
          />

          <PriceGuarantee
            priceguarantee={
              informationen ? informationen[0]?.preisgarantieFuerMonate : ""
            }
            vertragsverlaengerung={vertragsverlaengerung}
            kuendigungsfrist={kuendigungsfrist}
            vertragslaufzeit={vertragslaufzeit}
          />

          {boni && <Bonus boni={boni} isBrutto={isBrutto}/>}

          {aufschlaege && <Bonus boni={aufschlaege} isBrutto={isBrutto} />}
        </div>
      </div>
      <div className="self-end">
        <div className="flex justify-between">
          <Button
            onClick={handleChange}
            variant="unstyled"
            className="w-full px-0"
          >
            <div className="flex items-center justify-between">
              {showTariffDetailLabel}
              {showTariffDetails ? (
                <div>
                  <Caret />
                </div>
              ) : (
                <div style={{ transform: "rotate(180deg)" }}>
                  <Caret />
                </div>
              )}
            </div>
          </Button>
        </div>

        <CardExtend extendOpen={showTariffDetails}>
          <>
            <section id="tariff-info">
              {/* <div className="flex justify-between">
              <div>{isprepaidtariffLabel}</div>
              <div>{isVorkasseTarif ? "ja" : "nein"}</div>
            </div>

            <div className="flex justify-between my-2">
              <div>{ispackagetariffLabel}</div>
              <div>{isPaketTarif ? "ja" : "nein"}</div>
            </div>*/}

              {/* <div className="flex justify-between my-2">
              <div>Gültig ab</div>
              <div>
                {gueltigVon.dayOfMonth}.{gueltigVon.monthValue}.{gueltigVon.year}
              </div>
            </div> */}

              {strommixLight?.co2 ? (
                <div className="flex justify-between my-2">
                  <div>{co2Label}</div>
                  <div>
                    {strommixLight?.co2} {co2unitLabel}
                  </div>
                </div>
              ) : null}

              {strommixLight?.radioaktiv ? (
                <div className="flex justify-between my-2">
                  <div>{radioactivLabel}</div>
                  <div>
                    {strommixLight?.radioaktiv} {co2unitLabel}
                  </div>
                </div>
              ) : null}

              {isChartDataAvailable && (
                <>
                  {strommixLight?.stand ? (
                    <div className="my-2">
                      {strommixLabel} ({standLabel} {strommixLight?.stand})
                    </div>
                  ) : null}
                  <section id="chart" className="grid grid-cols-2 items-center">
                    <div>
                      <Doughnut data={chartData} datasetIdKey="label" />
                    </div>

                    <div>
                      {strommixLight?.erneuerbare ? (
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <div
                              ref={primaryRef}
                              className="bg-borderInput mr-1 w-3 h-3 rounded"
                            />
                            {renewableLabel}
                          </div>
                          <div>
                            {strommixLight?.erneuerbare} {renewableunitLabel}
                          </div>
                        </div>
                      ) : null}

                      {strommixLight?.fossile ? (
                        <div className="flex justify-between my-2">
                          <div className="flex items-center">
                            <div
                              ref={secondaryRef}
                              className="bg-borderInput-hover mr-1 w-3 h-3 rounded"
                            />
                            {fossilLabel}
                          </div>
                          <div>
                            {strommixLight?.fossile} {fossilunitLabel}
                          </div>
                        </div>
                      ) : null}

                      {strommixLight?.kernkraft ? (
                        <div className="flex justify-between my-2">
                          <div>{nuclearLabel}</div>
                          <div>
                            {strommixLight?.kernkraft} {nuclearunitLabel}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </section>
                </>
              )}
            </section>

            <section id="bonus" className="mt-8">
              <div className="flex w-full mb-2">{tieredPricingLabel}</div>
              <table className="table-auto border-collapse w-full">
                <thead>
                  <tr>
                    <th className="text-left pr-2 pb-2">{consumptionLabel}</th>
                    <th className="text-right pr-2 pb-2">
                      {yearlyBasePriceLabel}
                    </th>
                    <th className="text-right pb-2">{workingpriceLabel}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableBody?.map((preisItem) => (
                    <tr
                      key={preisItem.rootId}
                      className="border-solid border-x-0 border-y-2 border-gray-300"
                    >
                      <td className="text-left pr-2">
                        {preisItem.verbrauchBis === 0 && "ab "}
                        {formatDecimal(preisItem.verbrauchVon, 0)}
                        {preisItem.verbrauchBis !== 0 && " - "}
                        {preisItem.verbrauchBis !== 0 && formatDecimal(preisItem.verbrauchBis, 0)} kWh
                      </td>
                      <td className="text-right pr-2">
                        {formatCurrency(isBrutto ? preisItem.grundpreisBrutto : preisItem.grundpreisNetto)}
                      </td>
                      <td className="text-right">
                        {formatDecimal(isBrutto ? preisItem.arbeitspreisBrutto : preisItem.arbeitspreisNetto)}
                        &nbsp;ct/kWh
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </>
        </CardExtend>

        {!!handlingTariff && (
          <div className="mt-6 text-center">
            <Button
              className="p-2 w-[200px]"
              onClick={() => {
                handleChooseTariff({
                  arbeitspreisCtKWhBrutto,
                  arbeitspreisCtKWhNetto,
                  grundpreisBrutto,
                  gesamtpreisBrutto,
                  arbeitspreisBrutto,
                  arbeitspreisHTCtKWhBrutto,
                  arbeitspreisNetto,
                  arbeitspreisNTCtKWhBrutto,
                  arbeitspreisNTCtKWhNetto,
                  ersparnisBrutto,
                  ersparnisNetto,
                  arbeitspreisHTCtKWhNetto,
                  gesamtpreisNetto,
                  gesamtpreisOhneBoniBrutto,
                  gesamtpreisOhneBoniNetto,
                  grundpreisNetto,
                  gridConnectionType: 0,
                  connectionTypes: [],
                  contractPeriodType: 0,
                  contractPeriodMonths: 0,
                  contractPeriodDate: undefined,
                  noticePeriodMonths: 0,
                  contractExtensionType: 0,
                  contractExtensionMonths: 0,
                  contractExtensionDate: undefined,
                  rootId: 0,
                  productName: "",
                  productTypeId: 0,
                  productTypeName: "",
                  companyId: 0,
                  companyName: "",
                  providerId: 0,
                  providerName: "",
                  productCode: "0",
                  validFrom: undefined,
                  validTo: undefined,
                  customerGroupPrivate: false,
                  customerGroupCommercial: false,
                  customerGroupYoungPeople: false,
                  basicPrice: 0,
                  installCost: 0,
                  landlineDataVolumeFlat: false,
                  landlineDataVolumeLimit: 0,
                  landlineInternetDownloadSpeed: 0,
                  landlineInternetDownloadSpeedMinimal: 0,
                  landlineInternetDownloadSpeedNormal: 0,
                  landlineInternetUploadSpeed: 0,
                  landlineInternetUploadSpeedMinimal: 0,
                  landlineInternetUploadSpeedNormal: 0,
                  landlineTelephone: false,
                  landlineTelephoneCostMinutes: 0,
                  landlineTelephoneFlatLandline: false,
                  landlineTelephoneNumbers: 0,
                  setupCost: 0,
                  shippingCost: 0,
                  shippingCosts: 0,
                  verbandsnummer:
                    associationNumberFromVPP ||
                    supplierInformation?.marktrollen?.[0]?.verbandsnummer ||
                    "",
                  aufschlaege: aufschlaege || [],
                  productDescription: productDescription || "",
                  gueltigVon: formatValidDate(gueltigVon),
                  boni,
                  dynamischeAngaben,
                  informationen:
                    informationen?.map((value) => {
                      return {
                        ...value,
                        preisgarantieBis: formatValidDate(
                          value.preisgarantieBis
                        ),
                        gueltigVon: formatValidDate(value.gueltigVon),
                        gueltigBis: formatValidDate(value.gueltigBis),
                      };
                    }) || [],
                  isGrundversorgungsTarif,
                  isOekostromTarif,
                  isOekogasTarif,
                  isPaketTarif,
                  isVergleichsTarif,
                  isVorkasseTarif,
                  kuendigungsfrist,
                  kuendigungsfristID,
                  markenID,
                  tarifCode: tarifCode || "",
                  tarifID,
                  tarifInfo: tarifInfo ?? "",
                  tarifName,
                  tarifRootID,
                  variantenID: variantenID ?? "",
                  versorgerID,
                  versorgerName,
                  vertragslaufzeit,
                  vertragslaufzeitID,
                  vertragsverlaengerung,
                  vertragsverlaengerungID,
                });
              }}
              variant="primary"
            >
              {orderNowLabel}
            </Button>
          </div>
        )}
        <div className="my-2 text-sm text-center">
          {isBrutto ? pricesInclTaxLabel : pricesExclTaxLabel}
        </div>
      </div>
    </Card>
  );
};

export default Item;
