export const handleErrKeys = (key) => {
  switch (key) {
    case "la_anrede":
      return "salutation";
    case "la_vorname":
      return "firstName";
    case "la_nachname":
      return "lastName";
    case "la_geburtsdatum":
      return "birthday";
    case "la_plz":
      return "zipCode";
    case "la_ort":
      return "city";
    case "la_strasse":
      return "street";
    case "la_hausnummer":
      return "houseNumber";
    default:
      return undefined;
  }
};
