import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ArrowRight from "../icons/ArrowRight";
import NotFound from "../icons/NotFound";
const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {

  return (
    <ReactErrorBoundary fallback={
        <div className="flex flex-col items-center justify-center mx-auto min-h-[65vh] gap-5 text-center">
            <NotFound />
            <div className="text-base text-dashboard-text"><p>Die gewünschte Seite konnte nicht gefunden werden.</p><p>Nutzen Sie unser Menü, um alle Inhalte zu finden.</p>
                <a href="/">
                <button type="button" aria-label="" className="rounded-full shadow-[0_4px_4px_0_rgba(0,0,0,0.1)] px-5 py-1.5 text-normal leading-none text-button-primary-color bg-button-primary-bg hover:bg-button-primary-bgHover disabled:bg-button-primary-disabled disabled:cursor-not-allowed disabled:text-button-primary-colorDisabled mt-4">
                    <div className="flex items-center justify-center gap-4">
                        Zur Startseite <ArrowRight/>
                    </div>
                </button>
                </a></div></div>
    }>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
