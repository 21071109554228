import { useFormikContext } from "formik";
import React from "react";

import Card from "../../../../../components/Card";
import Checkbox from "../../../../../components/Checkbox";
import FormControl from "../../../../../components/FormControl";
import ReactHTMLParser from "../../../../../components/HTMLParser";
import Input from "../../../../../components/Input";
import { useSelectTariff } from "../../../../../context/Tariff";
import { useSelectTelco } from "../../../../../context/Telco";
import { useContractConclusionFormName } from "../../../context";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import useGetPolicyDocument from "../../../hooks/useGetPolicyDocument";
import DigitalSignature from "../../DigitalSignature";
import FooterButtons from "../../FooterButtons";
import PrePrint from "../PrePrint";

const PREV_STEP = "bankForm";

const OptIns = () => {
  const { setFormName } = useContractConclusionFormName();
  const { submitForm } = useFormikContext();

  const { setFieldValue } = useFormikContext();
  const { data } = useGetPolicyDocument();
  const { selectedTelco } = useSelectTelco();
  const { selectedTariff } = useSelectTariff();
  const {
    isPrePrint,
    isEnabledCustomerRecruitment,
    isEnabledDigitalSignature,
    acceptGeneralTNC,
    acceptCancellationPolicy,
    acceptPrivacyPolicy,
    preisList,
    serviceDescription,
    isSurveyActive,
    advertisingConsentPost,
    advertisingConsentPhone,
    advertisingConsentEmail,
    customerSatisfactionSurveyPost,
    customerSatisfactionSurveyPhone,
    customerSatisfactionSurveyEmail,
    isAdvertisingConsent,
    customersRecruitmentLabel,
    digitalSignatureLabel,
  } = useFeaturesCC();

  const agbText = React.useMemo(() => {
    if (acceptGeneralTNC) {
      return (
        <ReactHTMLParser>
          {acceptGeneralTNC
            .replace("%url%", data?.data?.gtcUrl || "")
            .replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    } 
    return undefined;
  }, [acceptGeneralTNC, data?.data?.gtcUrl, selectedTariff?.versorgerName]);

  const cancellationText = React.useMemo(() => {
    if (acceptCancellationPolicy) {
      return (
        <ReactHTMLParser>
          {acceptCancellationPolicy
            .replace("%url%", data?.data?.cancellationPolicyUrl || "")
            .replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    }
    return undefined;
  }, [acceptCancellationPolicy, data?.data?.cancellationPolicyUrl, selectedTariff?.versorgerName]);

  const privacyPolicyText = React.useMemo(() => {
    if (acceptPrivacyPolicy) {
      return (
        <ReactHTMLParser>
          {acceptPrivacyPolicy
            .replace("%url%", data?.data?.privacyUrl || "")
            .replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    } 
    return undefined;
  }, [acceptPrivacyPolicy, data?.data?.privacyUrl, selectedTariff?.versorgerName]);

  const preisListText = React.useMemo(() => {
    const documentUrl = selectedTelco?.productDocuments.filter((document) =>
      document.fileName?.includes("Preisliste")
    )[0]?.uRL;
    if (preisList && documentUrl) {
      return <ReactHTMLParser>{preisList.replace("%url%", documentUrl || "")}</ReactHTMLParser>;
    } 
    return undefined;
  }, [selectedTelco?.productDocuments, preisList]);

  const serviceDescriptionText = React.useMemo(() => {
    const documentUrl = selectedTelco?.productDocuments.filter((document) =>
      document.fileName?.includes("Leistungsbeschreibung")
    )[0]?.uRL;
    if (serviceDescription && documentUrl) {
      return <ReactHTMLParser>{serviceDescription.replace("%url%", documentUrl || "")}</ReactHTMLParser>;
    } 
    return undefined;
  }, [serviceDescription, selectedTelco?.productDocuments]);

  React.useEffect(() => {
    if (data) {
      const attachment = Object.values(data.data)
        .map((url) => typeof url === "string" && url)
        .filter(Boolean);

      setFieldValue("attachment", attachment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Card className="rounded-xl mt-4">
      <section>
        <div className="text-lg font-bold">Bestellung</div>

        <FormControl className="mt-4" style={{ fontSize: "14px" }}>
          <div className="mb-4">
            Mit Klick auf "Zahlungspflichtig bestellen" beauftrage ich den Versorger mit der Belieferung an die
            angegebene Lieferanschrift und erteile ihm die Vollmacht, ggfs. meinen derzeit bestehenden Vertrag zu
            kündigen sowie die erforderlichen Verträge mit dem örtlichen Netzbetreiber abzuschließen
          </div>
          {selectedTelco && (
            <>
              {preisListText && <Checkbox name="isPreisListCheck" labelComponent={preisListText} />}
              {serviceDescriptionText && (
                <Checkbox name="isServiceDescriptionCheck" labelComponent={serviceDescriptionText} />
              )}
            </>
          )}
          <Checkbox name="isAGBCheck" labelComponent={agbText} className="mb-2"/>
          <Checkbox name="isCancellationCheck" labelComponent={cancellationText} className="mb-2"/>
          <Checkbox name="isPrivacyPolicyCheck" labelComponent={privacyPolicyText} />

          {isAdvertisingConsent && (
            <>
              <div className="font-bold text-lg mt-6">Werbeeinwilligung</div>
              <div className="my-4">
                Ja, ich stimme zu, dass mich der Versorger zu weiteren Produktangeboten kontaktieren darf. Diese
                Einwilligung kann ich jederzeit widerrufen.
              </div>
              {typeof advertisingConsentPhone === "string" ? (
                <Checkbox name="promotionalConsentByPhone" label={advertisingConsentPhone} />
              ) : (
                <Checkbox
                  name="promotionalConsentByPhone"
                  label={advertisingConsentPhone.label}
                  value={advertisingConsentPhone.value}
                />
              )}

              {typeof advertisingConsentPost === "string" ? (
                <Checkbox name="promotionalConsentByPost" label={advertisingConsentPost} />
              ) : (
                <Checkbox
                  name="promotionalConsentByPost"
                  label={advertisingConsentPost.label}
                  value={advertisingConsentPost.value}
                />
              )}

              {typeof advertisingConsentEmail === "string" ? (
                <Checkbox name="promotionalConsentByMail" label={advertisingConsentEmail} />
              ) : (
                <Checkbox
                  name="promotionalConsentByMail"
                  label={advertisingConsentEmail.label}
                  value={advertisingConsentEmail.value}
                />
              )}
            </>
          )}

          {isSurveyActive && (
            <>
              <div className="font-bold text-lg mt-6">Kundenzufriedenheit</div>

              <div className="my-4">
                Ja, ich stimme zu, dass mich der Versorger zu meiner Zufriedenheit mit den mir angebotenen und/oder
                vermittelten Services befragt werden darf. Diese Einwilligung kann ich jederzeit widerrufen.
              </div>

              {typeof customerSatisfactionSurveyPost === "string" ? (
                <Checkbox name="customerSatisfactionSurveyByPost" label={customerSatisfactionSurveyPost} />
              ) : (
                <Checkbox
                  name="customerSatisfactionSurveyByPost"
                  label={customerSatisfactionSurveyPost.label}
                  value={customerSatisfactionSurveyPost.value}
                />
              )}

              {typeof customerSatisfactionSurveyPhone === "string" ? (
                <Checkbox name="customerSatisfactionSurveyByPhone" label={customerSatisfactionSurveyPhone} />
              ) : (
                <Checkbox
                  name="customerSatisfactionSurveyByPhone"
                  label={customerSatisfactionSurveyPhone.label}
                  value={customerSatisfactionSurveyPhone.value}
                />
              )}

              {typeof customerSatisfactionSurveyEmail === "string" ? (
                <Checkbox name="customerSatisfactionSurveyByMail" label={customerSatisfactionSurveyEmail} />
              ) : (
                <Checkbox
                  name="customerSatisfactionSurveyByMail"
                  label={customerSatisfactionSurveyEmail.label}
                  value={customerSatisfactionSurveyEmail.value}
                />
              )}
            </>
          )}

          {isEnabledCustomerRecruitment && (
            <>
              <div className="font-bold text-lg mt-6">{customersRecruitmentLabel}</div>
              <div>Geben Sie bitte hier die Kundennummer des werbenden Kunden ein:</div>
              <Input name="customerRecruitment" placeholder="Kundennummer" />
            </>
          )}

          {isEnabledDigitalSignature && (
            <>
              <div className="mt-4">{digitalSignatureLabel}</div>
              <DigitalSignature name="signature" />
            </>
          )}

          {isPrePrint && <PrePrint />}

          <div className="my-6">
            Durch die Betätigung der Schaltfläche "Zahlungspflichtig bestellen" beantrage ich die Versorgung der oben
            genannten Abnahmestelle zu dem genannten oder zum nächstmöglichen Termin. Im Anschluss wird automatisch eine
            E-Mail als Auftragseingangsbestätigung an Ihre angegebene E-Mail-Adresse gesendet.
          </div>
        </FormControl>
      </section>
      <FooterButtons
        onSubmitValidation={() => {}}
        onSubmit={() => submitForm()}
        onBack={() => setFormName(PREV_STEP)}
      />
    </Card>
  );
};

export default OptIns;
