import { useConfig } from "../context/Config";

const useResponsiveClass = () => {
  const { config } = useConfig();

  const responsiveClass = (all = "", mobile = "", tablet = "", desktop = "") => {
    switch (config.media) {
      case "mobile":
        return all + " " + mobile;
      case "tablet":
        return all + " " + tablet;
      case "desktop":
        return all + " " + desktop;
      default:
        return all;
    }
  };
  const responsiveColSpan = (mobile = "full", tablet = "1", desktop = "1", additionalAllClass = "w-full") => {
    switch (config.media) {
      case "mobile":
        return additionalAllClass + " col-span-" + mobile;
      case "tablet":
        return additionalAllClass + " col-span-" + tablet;
      case "desktop":
        return additionalAllClass + " col-span-" + desktop;
      default:
        return additionalAllClass;
    }
  };
  return { responsiveClass, responsiveColSpan };
};

export default useResponsiveClass;
