import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

interface stepperContractConclusion {
  formName: string,
  setFormName: React.Dispatch<React.SetStateAction<string>>
}

export const stepperContractConclusion = createContext({} as stepperContractConclusion);

export const useContractConclusionFormName = () =>
  useContextSelector(stepperContractConclusion, (c) => c);

export const activatedPanelsContext = createContext({
  activatedPanels: [],
  setActivatedPanels: () => {},
} as { activatedPanels: string[],
  setActivatedPanels: React.Dispatch<React.SetStateAction<string[]>>,});

export const useActivatedPanels = () =>
  useContextSelector(activatedPanelsContext, (c) => c);
