import format from "date-fns/format";

import createPDFComponentData from "./createPDFComponentData";
import { uuid } from "./uuid";

const handleTelcoForms = (val, opt) => {
  const isE01 = val.reasonToChange === "E01"; // umzug
  const isE03 = val.reasonToChange === "E03"; // versorger wechseln

  const setupCost = {
    refName: "Aktivierungskosten",
    priceOneTime: val.setupCost,
    code: "",
    description: "",
    monthlyPrices: [],
    preselected: false,
    readonly: false,
    shippingCosts: 0,
  };

  const shippingCost = {
    refName: "Versandkosten",
    priceOneTime: val.shippingCost,
    monthlyPrice: undefined,
    code: "",
    description: "",
    monthlyPrices: [],
    preselected: false,
    readonly: false,
    shippingCosts: 0,
  };

  const basicPrice = {
    refName: "Grundpreis",
    priceOneTime: 0,
    monthlyPrice: 0,
    isSelected: true,
    code: "",
    description: "",
    monthlyPrices: val.monthlyPrices || [],
    preselected: false,
    readonly: false,
    shippingCosts: 0,
  };

  const PDF_GroupPriceComponent = [
    ...(val.setupCost ? [setupCost] : []),
    ...(val.shippingCost ? [shippingCost] : []),
    ...(val.monthlyPrices ? [basicPrice] : []),
    ...(val.selectedOption || []),
    ...(val.selectedHardware || []),
  ];

  const pdfComponent = createPDFComponentData(PDF_GroupPriceComponent);

  return {
    la_anrede: val.salutation,
    la_email: val.email,
    la_geburtsdatum: val.birthday.split(".").reverse().join("-"),
    la_hausnummer: val.houseNumber,
    la_hausnummerzusatz: val.additionalAddress,
    la_nachname: val.lastName,
    la_ort: val.city,
    la_plz: val.zipCode,
    la_strasse: val.street,
    la_vorname: val.firstName,
    la_installationsort: val.installationLocation || null,
    la_telefonnummer: val.phoneNumber,
    ra_anrede: val.billingSalutation || undefined,
    ra_hausnummer: val.billingHouseNumber || undefined,
    ra_nachname: val.billingLastName || undefined,
    ra_ort: val.billingCity || undefined,
    ra_plz: val.billingZipCode || undefined,
    ra_strasse: val.billingStreet || undefined,
    ra_vorname: val.billingFirstName || undefined,
    ra_hausnummerzusatz: val.billingAdditionalAddress || undefined,
    abw_lieferungsadresse_anrede:
      (typeof val.installationSalutation !== "string" &&
        val?.installationSalutation?.value) ||
      undefined,
    abw_lieferungsadresse_hausnummer: val.installationHouseNumber || undefined,
    abw_lieferungsadresse_nachname: val.installationLastName || undefined,
    abw_lieferungsadresse_ort: val.installationCity || undefined,
    abw_lieferungsadresse_plz: val.installationZipCode || undefined,
    abw_lieferungsadresse_strasse: val.installationStreet || undefined,
    abw_lieferungsadresse_vorname: val.installationFirstName || undefined,
    sepa_erteilt: val.paymentOptions === "directDebit" ? val.isAgreeToSEPA : "",
    iban: val.paymentOptions === "directDebit" ? val.iban : "",
    bic: val.paymentOptions === "directDebit" ? val.bic : "",
    geldinstitut: val.paymentOptions === "directDebit" ? val.bankName : "",
    agb_akzeptiert: val.isAGBCheck,
    kenntnisnahme_widerrufsrecht: val.isCancellationCheck,
    kenntnisnahme_datenschutz: val.isPrivacyPolicyCheck,
    kundenPortalAnlegen: false,
    versandart_rechnung:
      val.invoiceDeliveryBy === "post" ||
      (val.invoiceDeliveryBy === "mail" && val.invoiceDeliveryBy),
    gutscheincode: val.couponCode || null,
    werber_identnummer: val.customerRecruitment || null,
    digitalSignature: val.signature,
    medium: 0,
    tariffCalculatorTypeId: val.tariffId,
    vorlieferant_verbandsnummer: val.productTypeName,
    vorlieferant_name: isE03
      ? typeof val.prevSupplierName !== "string" && val.prevSupplierName.value
      : "",
    einzug_umzug: val.reasonToChange,
    bestelldatum: format(new Date(), "yyyy-MM-dd"),
    vertriebspartner_id: val.providerId,
    kuendigung_datum: val.cancellationDate || null,
    terminwunsch:
      isE03 || (!isE01 && val.desiredDeliveryDate)
        ? val.desiredDeliveryDate
        : "asap",
    kuendigung_vorversorger: isE03
      ? val.hasCanceledPrevSupplier === "true"
      : false,
    get_vorgangs_id: uuid(),
    vertragsnummer: uuid(),
    verbindlicher_abschluss: opt.bindingOffer,
    rufnummeruebernahme:
      val.telephoneInstallationRequest === "false" ? "old" : "new",
    zu_portierende_telefonnummer: val.currentInstalledTelephoneNumber || null,
    einzelverbindungsnachweis: val.itemizedBill,
    objekt_id: val.objectId || "", // TELCO
    attachments: val.attachment || [],
    paymentMethod:
      val.paymentOptions === "directDebit" ? "directDebit" : "bankTransfer",
    billingSameAsDelivery: val.deliveryIsSameBillingAddress,
    secondDeliverySameAsDelivery: val.deliveryIsSameBillingAddress,
    authToken: val.authToken, // CIM Export
    teilnehmerverzeichnis: val.joinSubscribeList === "false" ? "Nein" : "Ja",
    id_person: val.idPerson,

    // PDF
    PDF_sepa_erteilt_ja:
      val.paymentOptions === "directDebit" && val.isAgreeToSEPA ? "x" : "",
    PDF_sepa_erteilt_nein: val.paymentOptions === "bankTransfer" ? "x" : "",
    PDF_einzug_umzug_E01: isE01 ? "x" : "",
    PDF_einzug_umzug_E02: !isE01 && !isE03 ? "x" : "",
    PDF_einzug_umzug_E03: isE03 ? "x" : "",
    PDF_terminwunsch: isE03 || !isE01 ? val.desiredDeliveryDate : "",
    PDF_versandart_rechnung_mail: val.invoiceDeliveryBy === "mail" ? "x" : "",
    PDF_versandart_rechnung_post: val.invoiceDeliveryBy === "post" ? "x" : "",
    PDF_terminwunsch_sofort: !val.desiredDeliveryDate ? "x" : "",
    PDF_terminwunsch_datum: val.desiredDeliveryDate ? "x" : "",
    PDF_geburtsdatum: val.birthday,
    PDF_kuendigung_datum: val.cancellationDate,
    PDF_bestelldatum: format(new Date(), "dd.MM.yyy"),
    PDF_konto_vorname:
      val.paymentOptions === "directDebit" ? val.firstNameAccountHolder : "",
    PDF_konto_nachname:
      val.paymentOptions === "directDebit" ? val.lastNameAccountHolder : "",
    PDF_Vorabdruck: opt.bindingOffer
      ? "Vielen Dank für Ihren Auftrag!"
      : "Zusammenfassung Ihres Angebotes",
    PDF_Produktbeschreibung_Gesamt: val.productDescriptionPlane,
    PDF_Options: val.selectedOption,
    PDF_Hardware: val.selectedHardware,
    PDF_GroupPriceComponent,
    PDF_Summe_Monatspreis_reduziert: val.reducedMonthlyPrice,
    PDF_Summe_Montspreis_voll: val.fullPriceMonthly,
    PDF_Summe_Einmalpreis: val.oneTimePrice,
    ...pdfComponent,

    ...(typeof val.promotionalConsentByMail === "string"
      ? { werbeeinwilligung_email: val.promotionalConsentByMail }
      : {}),
    ...(typeof val.promotionalConsentByPost === "string"
      ? { werbeeinwilligung_post: val.promotionalConsentByPost }
      : {}),
    ...(typeof val.promotionalConsentByPhone === "string"
      ? { werbeeinwilligung_telefon: val.promotionalConsentByPhone }
      : {}),
    ...(typeof val.customerSatisfactionSurveyByMail === "string"
      ? { kundenzufriedenheit_email: val.customerSatisfactionSurveyByMail }
      : {}),
    ...(typeof val.customerSatisfactionSurveyByPost === "string"
      ? { kundenzufriedenheit_post: val.customerSatisfactionSurveyByPost }
      : {}),
    ...(typeof val.customerSatisfactionSurveyByPhone === "string"
      ? { kundenzufriedenheit_telefon: val.customerSatisfactionSurveyByPhone }
      : {}),
    abschlussstatus: "",
  };
};

export default handleTelcoForms;
