import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const ExternLocationIdContext = createContext(
  {} as { externLocationId: string; setExternLocationId: React.Dispatch<React.SetStateAction<string>> }
);
const ExternLocationIdProvider = ({ children }: { children: React.ReactNode }) => {
  const [externLocationId, setExternLocationId] = React.useState("");

  return (
    <ExternLocationIdContext.Provider value={{ externLocationId, setExternLocationId }}>
      {children}
    </ExternLocationIdContext.Provider>
  );
};

export default ExternLocationIdProvider;
export const useExternLocationId = () => useContextSelector(ExternLocationIdContext, (c) => c);
