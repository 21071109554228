import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import toast from "../components/Toast";
import ConfigInstance from "../services/config";

const inFourMinutes = new Date(new Date().getTime() + 4 * 60 * 1000);

const setAPIKeyCookie = (key, ttl) => {
  Cookies.set("apikey", key, { expires: ttl || inFourMinutes });
};

const getAPIKeyCookie = () => {
  return Cookies.get("apikey");
};

const useAPIKey = () => {
  const [apikey, setAPIKey] = useState(getAPIKeyCookie());

  useEffect(() => {
    // override api key to avoid error in testing
    const fetchAPIKey = async () => {
      try {
        const { data } = await ConfigInstance("/adserv");
        setAPIKey(data.data.apikey);
        setAPIKeyCookie(data.data.apikey);
      } catch (e) {
        toast.error("API: Konfigurationsdatei fehlerhaft");
      }
    };

    fetchAPIKey();
  }, []);

  return { data: apikey, isLoading: !apikey };
};

export default useAPIKey;
