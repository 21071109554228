import { useFormikContext } from "formik";
import React from "react";
import { useQuery } from "react-query";

import { useConfig } from "../../../context";
import ProxyInstance from "../../../services/proxy";
import formatIBAN from "../utils/formatIBAN";

const useCheckIBAN = () => {
  const config = useConfig();
  const { setFieldValue, values } = useFormikContext();

  const enabled = React.useMemo(() => {
    if (values.iban.startsWith("DE")) {
      return !!values.iban && values.iban.length === 22;
    }

    return !!values.iban;
  }, [values.iban]);

  return useQuery(
    `CHECK_IBAN_${values.iban}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/ws/bankdata/checkIBAN?iban=${formatIBAN(values.iban).replace(
            /\s/g,
            "+"
          )}`,
          { apikey: config.apikey || "" }
        );
        let isValidIBAN = false;
        isValidIBAN = data.data.isValidIBAN;
        if(values.iban.startsWith("DE")) {
          const { data : { bankname, bic}} = data;
          if (isValidIBAN) {
            setFieldValue("bankName", bankname);
            setFieldValue("bic", bic);
          }
        }
        if(isValidIBAN){
          setFieldValue("IBANError", true)
        } else {
          setFieldValue("IBANError", false)
        }
        
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};

export default useCheckIBAN;
