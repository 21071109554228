import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import { useConfig } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import useGetTariffVariant from "../../hooks/useGetTariffVariant";
import useVariantSupplier from "../../hooks/useVariantSupplier";
import SelectTariff from "../SelectTariff";
import SelfCompareTariff from "../SelfComparedInput";

const TariffComparison = ({ setModalOpen, timeframeYear }) => {
  const config = useConfig();
  const { submitForm, values, setFieldValue } = useFormikContext();
  const compareType = Number(values.compareType);
  const { calcParams } = useCalcParams();
  const { TCS_BtnLabel, comparisonTariffLabel } = useFeaturesResult();
  const { data } = useVariantSupplier(calcParams);
  const { data: tariff } = useGetTariffVariant(calcParams) ?? "";

  const handleChangeNewTariff = React.useCallback(async () => {
    await submitForm();
  }, [submitForm]);
  const changeCompareType = async (value) => {
    await setFieldValue("compareType", value);
  };

  return (
    <div className="m-4">
      <div
        className={`flex items-center ${
          config.media === "desktop" ? "" : " justify-between"
        }`}
      >
        <div className="font-bold mr-9">{comparisonTariffLabel}</div>
        <section id="compare-tariff-selection">
          <div
            className="items-center rounded-md grid grid-cols-2 gap-4"
            role="group"
          >
            <Button
              type="button"
              variant={!compareType ? "primary" : "unstyled"}
              className="px-4 py-2"
              onClick={() => changeCompareType(0)}
            >
              Vergleichen
            </Button>
            <Button
              type="button"
              variant={compareType ? "primary" : "unstyled"}
              className="px-4 py-2"
              onClick={() => changeCompareType(1)}
            >
              Individuell
            </Button>
          </div>
        </section>
      </div>
      <div className="mt-4">
        {compareType === 0 ? (
          <>
            <div className="my-2">
              {data && (
                <SelectTariff
                  name="supplier"
                  label="Versorger"
                  placeholder="Versorger"
                  dataKey="versorgerID"
                  textField="versorgerName"
                  options={data}
                />
              )}
            </div>
            <div className="my-2">
              <SelectTariff
                name="tariffComparison"
                label="Tarif"
                placeholder="Tarif"
                dataKey="tarifID"
                textField="tarifName"
                options={tariff || []}
              />
            </div>
          </>
        ) : (
          <SelfCompareTariff timeframe={timeframeYear} />
        )}
        <div className="flex justify-between mt-4">
          <Button
            className="p-2 w-fit"
            variant="primary"
            onClick={() => setModalOpen(false)}
          >
            <span className="font-bold">Abbrechen</span>
          </Button>
          <Button
            disabled={
              compareType
                ? !((values.workingPriceBrutto && values.basePriceBrutto) || (values.workingPriceNetto && values.basePriceNetto))
                : !values.tariffComparison
            }
            onClick={handleChangeNewTariff}
            className="p-2 w-fit"
            variant="primary"
          >
            {TCS_BtnLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TariffComparison;
