import React from "react";

const Card = ({ children, className, style, ...props }) => {
  return (
    <div
      className={`border-solid border-content bg-content-bg border-content-border p-4 lg:p-6 ${
        className ? className : null
      }`}
      style={{ ...style }}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
