import * as React from "react";

const Gee = ({ fill = "var(--get-c-breadcrumb)" }: { fill?: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none" className="inline">
      <path
        d="M3.83333 14C3.46667 14 3.15278 13.8695 2.89167 13.6084C2.63056 13.3472 2.5 13.0334 2.5 12.6667V3.33335C2.5 2.96669 2.63056 2.6528 2.89167 2.39169C3.15278 2.13058 3.46667 2.00002 3.83333 2.00002H6.63333C6.77778 1.60002 7.01944 1.2778 7.35833 1.03335C7.69722 0.788909 8.07778 0.666687 8.5 0.666687C8.92222 0.666687 9.30278 0.788909 9.64167 1.03335C9.98056 1.2778 10.2222 1.60002 10.3667 2.00002H13.1667C13.5333 2.00002 13.8472 2.13058 14.1083 2.39169C14.3694 2.6528 14.5 2.96669 14.5 3.33335V12.6667C14.5 13.0334 14.3694 13.3472 14.1083 13.6084C13.8472 13.8695 13.5333 14 13.1667 14H3.83333ZM3.83333 12.6667H13.1667V3.33335H3.83333V12.6667ZM5.16667 11.3334H9.83333V10H5.16667V11.3334ZM5.16667 8.66669H11.8333V7.33335H5.16667V8.66669ZM5.16667 6.00002H11.8333V4.66669H5.16667V6.00002ZM8.5 2.83335C8.64444 2.83335 8.76389 2.78613 8.85833 2.69169C8.95278 2.59724 9 2.4778 9 2.33335C9 2.18891 8.95278 2.06946 8.85833 1.97502C8.76389 1.88058 8.64444 1.83335 8.5 1.83335C8.35556 1.83335 8.23611 1.88058 8.14167 1.97502C8.04722 2.06946 8 2.18891 8 2.33335C8 2.4778 8.04722 2.59724 8.14167 2.69169C8.23611 2.78613 8.35556 2.83335 8.5 2.83335Z"
        fill={fill}
      />
    </svg>
  );
};

export default Gee;
