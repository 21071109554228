function createRange(obj: { from: number; to?: number }) {
  const { from, to } = obj;
  const range = [];

  if (to) {
    for (let i = from; i <= to; i++) {
      range.push(i);
    }
  } else {
    for (let i = from; i <= from + 1; i++) {
      range.push(i);
    }
  }

  return range;
}

export default createRange;
