import { useFormikContext } from "formik";
import React from "react";

import Card from "../../../components/Card";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { useContractConclusionFormName } from "../context";
import AddressData from "./AddressData";
import BankData from "./BankData";
import ContactData from "./ContactData";
import GeeData from "./GeeData";
import PreSupplierData from "./PreSupplierData";
import Summary from "./Summary";

const ContractConclusionForm = () => {
  const { formName } = useContractConclusionFormName();
  const { isSubmitting } = useFormikContext();

  return (
    <>
      <Card className={`col-span-2 rounded-xl ${formName === "summaryForm" ? "hidden" : ""}`}>
        {isSubmitting && <LoadingOverlay text="Bitte warten..." />}
        {formName === "contactForm" && <ContactData />}
        {formName === "addressForm" && <AddressData />}
        {formName === "gee" && <GeeData />}
        {formName === "preSupplierForm" && <PreSupplierData />}
        {formName === "bankForm" && <BankData />}
      </Card>
      {formName === "summaryForm" && <Summary />}
    </>
  );
};

export default ContractConclusionForm;
