import { useQuery } from "react-query";

import { useParameter } from "../context";
import { useConfig } from "../context/Config";
import VPPInstance from "../services/vpp";

const useKeycloak = () => {
  const { authToken } = useParameter();
  const { config } = useConfig();

  return useQuery(
    `GET_KEYCLOAK`,
    async () => {
      try {
        const { data } = await VPPInstance(`/user/self`, {
          apikey: config.apikey || "",
          authToken: `Bearer ${authToken}`,
        });

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!authToken && !!config.apikey,
    }
  );
};

export default useKeycloak;
