import { useField } from "formik";
import React, { useEffect, useState } from "react";
import ReactSlider from "react-slider";

import useFeaturesCC from "../../widgets/TCContractConclusion/hooks/useFeaturesCC";
import { FourPersons, OnePerson, ThreePersons, TwoPersons } from "./icons/Persons";

const RangeSlider = ({ name, allowedValues = [1700, 2500, 3200, 4000] }) => {
  const [fields, , { setValue }] = useField({ name });
  const [sliderValue, setSliderValue] = useState(
    allowedValues.indexOf(Number.parseInt(fields?.value.toString().replace(".", "")))
  );
  const [, setLastCorrectValue] = useState(3200);
  const { usageSlider } = useFeaturesCC();

  useEffect(() => {
    if (allowedValues.includes(Number.parseInt(fields?.value.toString().replace(".", "")))) {
      setLastCorrectValue(Number.parseInt(fields?.value.toString().replace(".", "")));
      setSliderValue(allowedValues.indexOf(Number.parseInt(fields?.value.toString().replace(".", ""))));
    }
  }, [setSliderValue, fields, allowedValues]);

  const changeCallback = (val) => {
    setValue(allowedValues[val].toString());
  };

  const decideColor = (val) => {
    if (Number.parseInt(fields?.value.toString().replace(".", "")) === val) {
      return "var(--default-color-primary)";
    }
    return "white";
  };

  return (
    <>
      {usageSlider && (
        <div className="w-auto mx-2">
          <div className="grid grid-cols-4 text-center -mx-[14%]">
            <span className="cursor-pointer" onClick={()=>changeCallback(0)} >
              <OnePerson color={decideColor(allowedValues[0])} />
            </span>
            <span className="cursor-pointer" onClick={()=>changeCallback(1)} >
              <TwoPersons color={decideColor(allowedValues[1])} />
            </span>
            <span className="cursor-pointer" onClick={()=>changeCallback(2)} >
              <ThreePersons color={decideColor(allowedValues[2])} />
            </span>
            <span className="cursor-pointer" onClick={()=>changeCallback(3)} >
              <FourPersons color={decideColor(allowedValues[3])} />
            </span>
          </div>
          <ReactSlider
            marks={allowedValues.map((el, index) => index)}
            min={0}
            max={allowedValues.length - 1}
            value={sliderValue}
            renderThumb={(props) => <div {...props} key={props?.key}></div>}
            onChange={(value) => changeCallback(value)}
            thumbClassName="size-3 rounded-full block cursor-pointer bg-button-primary-bg"
            trackClassName="bg-button-primary-bg-light h-1 mt-1"
            markClassName="border-solid border-2 size-2 border-button-primary-bg rounded-full"
          />
        </div>
      )}
    </>
  );
};
export default RangeSlider;
