import React from "react";

import Divider from "../../../../../components/Divider";
import formatCurrency from "../../../../../utils/formatCurrency";
import RowItem from "../RowItem";

const Bonus = ({ boni, isBrutto=true }:{boni: {[key: string]: any}, isBrutto?: boolean}) => {
  const bonus = React.useMemo(() => {
    return Array.from({ length: 2 }).map((_, index) => {
      if (boni[index]) {
        return boni[index];
      }
      return null;
    });
  }, [boni]);
  return (
    <section id="bonus" className="mt-5 mb-5">
      <Divider />
      {boni && (
        <>
          {bonus.map((bonus, index) => {
            if (!bonus) {
              return <div key={index} className="h-[20px]" />;
            }
            return (
              <RowItem
                key={bonus?.id}
                label={bonus?.art + " " + bonus?.bedingung}
                value={formatCurrency(isBrutto?bonus?.calcWertBrutto : bonus?.calcWertNetto|| 0)}
              />
            );
          })}
        </>
      )}
    </section>
  );
};

export default Bonus;
