import React from "react";

import Card from "../../../../../components/Card";
import ReactHTMLParser from "../../../../../components/HTMLParser";
import PDF from "../../../../../components/icons/Pdf";
import formatCurrency from "../../../../../utils/formatCurrency";
import removeSymbolAndAddSpace from "../../../../../utils/removeSymbolAndAddSpace";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import getFileType from "../../../utils/getFileType";

const Hardware = ({ hardwares, hardware, onSelect, onRemove }) => {
  React.useEffect(() => {
    if (hardware.entries.some((d) => d.preselected)) {
      const preSelectedList = hardware.entries.filter((v) => v.preselected);
      if (preSelectedList)
        onSelect((prevState) => [...prevState, ...preSelectedList]);
    }
  }, [hardware.entries, onSelect]);
  const { periodOnceLabel, shippingCostsLabel } = useFeaturesResult();

  const fileType = React.useCallback((data) => {
    const fileExt = getFileType(data);

    if (fileExt === "pdf") {
      return <PDF />;
    }

    return null;
  }, []);

  return (
    <Card className="mt-4">
      <div className="text-xl font-bold mb-2">{hardware.name}</div>
      {hardware.entries.map((data, index) => {
        return (
          <>
            <div className="flex justify-between">
              <div>
                {hardware.type === 2 ? (
                  <>
                    <input
                      id={`${data.refName}-${index + 1}`}
                      type="checkbox"
                      defaultChecked={data.preselected}
                      className="mr-2 accent-button-primary-bg"
                      disabled={data.readonly}
                      onChange={() => {
                        if (
                          hardwares.some((v) => v.refRootId === data.refRootId)
                        ) {
                          onRemove(data);
                        } else onSelect((prevState) => [...prevState, data]);
                      }}
                    />
                    <label htmlFor={`${data.refName}-${index + 1}`}>
                      {data.refName}
                    </label>
                  </>
                ) : (
                  <>
                    <input
                      type="radio"
                      name="radioHardware"
                      id={data.refName}
                      value={data.code}
                      defaultChecked={data.preselected}
                      className="mr-2 accent-button-primary-bg"
                      onChange={() => onSelect([data])}
                    />
                    <label htmlFor={data.refName}>{data.refName}</label>
                  </>
                )}

                <div className="mt-2">
                  {data?.description && (
                    <ReactHTMLParser>{data?.description || ""}</ReactHTMLParser>
                  )}
                </div>

                {data.documentName && data.documentUrl && (
                  <div className="mt-4 flex items-center">
                    <div className="mr-2">{fileType(data.documentName)}</div>
                    <small>
                      <a
                        href={data.documentUrl}
                        target="_blank"
                        className="text-blue-dark hover:underline"
                        rel="noreferrer"
                      >
                        {removeSymbolAndAddSpace(data.documentName)}
                      </a>
                    </small>
                  </div>
                )}
              </div>

              {data.imageUrl && (
                <div>
                  <img
                    src={data.imageUrl}
                    alt={data.imageName}
                    width="170px"
                    height="100px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
            </div>

            {data.monthlyPrices.map((price) => {
              return (
                <div
                  key={price.pricePerMonth}
                  className="flex justify-between items-center my-4"
                >
                  <span>
                    {price.to
                      ? `• ${price.from}. - ${price.to}. Monat(e)`
                      : `• ab ${price.from}. Monat(e)`}
                  </span>

                  <span>{formatCurrency(price.pricePerMonth)}</span>
                </div>
              );
            })}

            {data.priceOneTime ? (
              <div className="flex justify-between items-center my-4">
                <span>• {periodOnceLabel}</span>{" "}
                <span>{formatCurrency(data.priceOneTime)}</span>
              </div>
            ) : null}

            {data.shippingCosts ? (
              <div className="flex justify-between items-center my-4">
                <span>• {shippingCostsLabel}</span>
                <span>{formatCurrency(data.shippingCosts)}</span>
              </div>
            ) : null}
          </>
        );
      })}
    </Card>
  );
};

export default Hardware;
