import * as React from "react";
import { useSelectTariff } from "../../../../context/Tariff";
import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesPreSupplierData = () => {
  const { selectedTariff } = useSelectTariff();
  const { data: config } = useExternalConfigs("contractconclusion");
  const { data: tariffConfig } = useExternalConfigs("contractconclusion", selectedTariff?.versorgerID);
  const contractConclusion = config?.data?.data?.contractConclusion;
  const tariffContractConclusion = tariffConfig?.data?.data?.contractConclusion;

  const resolveContractConclusion = React.useCallback(
    (key: string) => {
      return tariffContractConclusion?.[key] ?? contractConclusion?.[key];
    },
    [tariffContractConclusion, contractConclusion]
  );

  const defaultLabel = {
    currentMeterReading: "Zählerstand",
    counterData: "Zählerdaten",
    dateMoveIn: "Datum des Einzugs",
    dateOfCancellation: "Kündigungsdatum",
    desiredDate: "Wunschtermin",
    desiredDateExplanation:
      "Ist die Bereitstellung zum Wunschtermin nicht möglich, dann erfolgt sie zum nächstmöglichen Zeitpunkt nach dem Wunschtermin.",
    maLoID: "Marktlokationsnummer",
    maLoIDNotValid: "Marktlokationsnummer ungültig",
    meterNumber: "Zählernummer",
    preSupplier: "Anschlussdaten",
    prevCustomerNum: "Kundennummer",
    prevProviderTitle: "Bisheriger Anbieter",
    prevSupplier: "Anbieter",
    readingDate: "Ablesedatum",
    reasonToChangeHeadline: "Wechselgrund",
    evnTitle: "Einzelverbindungsnachweis (EVN)",
    noEvn: "Nein, ich wünsche keinen EVN",
    shortEvn: "Ja, ich wünsche einen EVN gekürzt um 3 Stellen",
    fullEvn: "Ja, ich wünsche einen EVN ungekürzt",
    connectionData: "Anschlussdaten",
    prevTenant: "Vormieter",
    canceledPrevSupplier: "Der Vertrag ist bereits gekündigt",
    cancelationPermission: " ist zur Kündigung des bisherigen Liefervertrages bevollmächtigt", //Vorversorger ... ist ...
  };

  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveContractConclusion(key) || defaultValue])
  ) as Label;

  return labels;
};
export default useFeaturesPreSupplierData;
