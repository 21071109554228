import React from "react";

import { useConfig } from "../../../../context";
import { useSupplierInfo } from "../../../../context/SupplierInfo";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import formatCurrency from "../../../../utils/formatCurrency";
import formatDecimal from "../../../../utils/formatDecimal";
import useFeaturesResult from "../../hooks/useFeaturesResult";

export default function CompareTariffInfo ({timeframeYear}){
    const values = useSupplierInfo();
    const config = useConfig();

    const {
        unitPerYearLabel,
        totalpriceLabel,
        basepriceLabel,
        workingpriceLabel,
        workingpriceunitLabel,
    } = useFeaturesResult();

    const {HTLabel, NTLabel} = useGeneralLabel();

    const totalPrice = values?.supplierInformation?.suppliertariffGesamtpreisBrutto;
    const basePrice =  values?.supplierInformation?.suppliertariffGrundpreisBrutto;
    const versorgerName = values?.supplierInformation?.versorgerName;
    const suppliertariffName = values?.supplierInformation?.suppliertariffName;
    const suppliertariffArbeitspreisHTCtKWhBrutto = values?.supplierInformation?.suppliertariffArbeitspreisHTCtKWhBrutto;
    const suppliertariffArbeitspreisNTCtKWhBrutto = values?.supplierInformation?.suppliertariffArbeitspreisNTCtKWhBrutto;


    return(
        <>
            <div className="mb-4">
                <span className="font-bold">Vergleichstarif: </span>{versorgerName} {versorgerName ? "-": ""} {suppliertariffName}</div>
                {(config.media === "desktop" || config.media === "tablet") && (
                <section id="price" className="flex justify-between">
                    <div>
                    <div>{totalpriceLabel}</div>
                    <div className="font-bold">
                        {totalPrice &&
                        (timeframeYear
                            ? formatCurrency(totalPrice) + unitPerYearLabel
                            : formatCurrency(totalPrice / 12) + "/Monat")}
                    </div>
                    </div>

                    <div>
                    <div>{basepriceLabel}</div>
                    <div className="font-bold">
                        {basePrice &&
                        (timeframeYear
                            ? formatCurrency(basePrice) + unitPerYearLabel
                            : formatCurrency(basePrice / 12) + "/Monat")}
                    </div>
                    </div>
                    {suppliertariffArbeitspreisHTCtKWhBrutto ? (
                    <div>
                        {suppliertariffArbeitspreisNTCtKWhBrutto ? (
                        <div>{workingpriceLabel} {HTLabel}</div>
                        ) : (
                        <div>{workingpriceLabel}</div>
                        )}
                        <div className="font-bold">
                        {suppliertariffArbeitspreisHTCtKWhBrutto &&
                            formatDecimal(
                            suppliertariffArbeitspreisHTCtKWhBrutto
                            )}
                        &nbsp;{workingpriceunitLabel}
                        </div>
                    </div>
                    ) : null}

                    {suppliertariffArbeitspreisNTCtKWhBrutto ? (
                    <div>
                        <div>{workingpriceLabel} {NTLabel}</div>
                        <div>
                        {suppliertariffArbeitspreisNTCtKWhBrutto &&
                            formatDecimal(
                            suppliertariffArbeitspreisNTCtKWhBrutto
                            )}
                        &nbsp;{workingpriceunitLabel}
                        </div>
                    </div>
                    ) : null}
                </section>
                )}
        </>
    )
}