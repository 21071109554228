import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesResult = () => {
  const { data: config } = useExternalConfigs("resultpage");
  const resultPage = config?.data?.data?.resultPage;
  const functionPage = config?.data?.data?.functionsresultPage;

  const defaultLabel = {
    telcoLabel: "",
    unitUsage: "kWh/Jahr",
    strommix: "Strommix",
    onlyGasEco: "Nur Ökogas",
    onlyElectricityEco: "Nur Ökostrom",
    showTariffDetail: "Produktdetails anzeigen",
    unitPerYear: "/Jahr",
    tryAgain: "Über den Button 'Suchkriterien ändern' können Sie diese anpassen.",
    tariffType: "Zählerart",
    oneTariff: "Eintarif",
    twoTariffs: "Zweitarif",
    customerType: "Kundenart",
    private: "Privat",
    business: "Gewerbe",
    usageInvalidMessage: "dieser Wert ist nicht erlaubt",
    usage: "Verbrauch",
    header: "Ergebnisliste",
    headerOptions: "Produktoptionen",
    headerSub: "Ihre Suchkriterien",
    saving: "Ersparnis im 1. Jahr",
    savingTariff: "Ihr Vergleichstarif",
    cancellationperiod: "Kündigungfrist",
    priceInformation: "Preisinformation",
    priceguarantee: "Preisgarantie",
    contractperiod: "Mindestlaufzeit",
    co2unit: "g/kWh",
    renewable: "Erneuerbare Energien nach EEG",
    renewableunit: "%",
    fossil: "Fossile Energien",
    nuclear: "Kernkraft",
    radioactiv: "Radioaktiver Abfall",
    fossilunit: "%",
    nuclearunit: "%",
    contractextension: "Vertragsverlängerung",
    totalprice: "Gesamtpreis",
    baseprice: "Grundpreis",
    workingprice: "Arbeitspreis",
    buttontext: "Vertrag abschließen",
    workingpriceunit: "ct/kWh",
    tariffdescription: "Tarifbeschreibung",
    co2: "CO2-Emmision",
    radioactivunit: "%",
    stand: "Stand",
    isprepaidtariff: "Vorkassetarif",
    ispackagetariff: "Pakettarif",
    isprepaidtarifftrue: "ja",
    isprepaidtarifffalse: "nein",
    ispackagetarifftrue: "ja",
    ispackagetarifffalse: "nein",
    yourDiscount: "Abschlag (geschätzt)",
    ecoTarif: "Ökotarif",
    bonus: "Bonus",
    bonusChange: "Wechselbonus",
    backToSearchBtnText: "Suchkriterien ändern",
    noListProduct: "Zu den eingegebenen Daten konnten keine Produkte gefunden werden.",
    yes: "ja",
    no: "nein",
    irrelevant: "egal",
    waermepumpe: "Wärmepumpe",
    speicherheizung: "Speicherheizung",
    tarifftypeName: "Tariftyp",
    autoStromGemeinsameMessung: "Autostrom gemeinsame Messung",
    autoStromGetrennteMessung: "Autostrom getrennte Messung",
    gemeinsameMessung: "gemeinsame Messung",
    getrennteMessung: "getrennte Messung",
    internetSpeedUnit: "Mbit/s",
    priceMonthly: "Gesamt monatlich",
    priceOnce: "Gesamt einmalig",
    setupCost: "Aktivierungskosten",
    periodOnce: "einmalig",
    periodMonthly: "monatlich",
    periodOnceSummary: "Ihre einmaligen Kosten",
    periodMonthlySummary: "Ihre monatlichen Kosten",
    backToOptionsBtnText: "Zurück zur Tarifauswahl",
    optionInfo: "Produktinformationsblatt (PDF)",
    shippingCosts: "Versandkosten",
    tariffDetails: "Tarifdetails",
    productDescription: "Produktbeschreibung",
    missingProductDescription: "Dieses Produkt hat keine Beschreibung",
    infinityPeriod: "auf unbestimmte Zeit",
    TCS_Btn: "Übernehmen",
    townLabel: "Ort",
    consumptionLabel: "Verbrauch",
    consumptionNTLabel: "Verbrauch NT",
    zipCodeLabel: "PLZ",
    tieredPricing: "Preisstaffelung",
    yearlyBasePrice: "Jährlicher Grundpreis",
    orderNow: "Jetzt bestellen",
    pricesInclTax: "Alle angegebenen Preise sind inkl. USt.",
    pricesExclTax: "Alle angegebenen Preise sind zuzüglich USt.",
    months: "Monat(e)",
    oneMonth: "Monat",
    multipleMonths: "Monate",
    inclBonuses: "inkl. Bonus",
    yearlyInclBonuses: "Gesamtpreis inkl. Bonus",
    priceDisplaySelection: "Auswahl der Preisanzeige",
    comparisonTariff: "Vergleichstarif",
    noticePeriod: "Kündigungsfrist",
    activationPrice: "Aktivierungspreis",
    pleaseWait: "Bitte warten...",
    download: "Download",
    dynamicTariff: "Dynamischer Tarif",
    flexTariff: "Flex Tarif",
    phoneTariff: "Telefon",
    notSpezified: "keine Angabe",
    showMore: "Mehr anzeigen",
    compare: "Vergleichen",
    individual: "Individuell",
    supplier: "Versorger",
    tariff: "Tarif",
  };
  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resultPage?.[key] ?? defaultValue])
  ) as Label;

  const functiondefaults = {
    showMonthlyPaymentAmount: false,
    paging: 20,
    focusPoint: false,
    pricesNetGross: "default",
    hideComparisonSelect: false,
    showNetGross: false,
  };
  type Functions = typeof functiondefaults;
  const functions: Functions = Object.fromEntries(
    Object.entries(functiondefaults).map(([key, defaultValue]) => [key, functionPage?.[key] ?? defaultValue])
  ) as Functions;

  return { ...labels, ...functions };
};

export default useFeaturesResult;
