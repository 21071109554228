import { useFormikContext } from "formik";
import React from "react";

import { useCalcParams } from "../../../../context/CalculationParameter";
import { uniqueArr } from "../../../../utils/uniqueArr";
import { bankDataValidateKeys } from "../../Constant";
import {
  useActivatedPanels,
  useContractConclusionFormName,
} from "../../context";
import useCheckFormValidationAndRedirect from "../../hooks/useCheckFormValidationAndRedirect";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import BankDataForm from "./BankDataForm";
import PaymentOptions from "./PaymentOptions";

const NEXT_STEP = "summaryForm";
const PREV_STEP = "preSupplierForm";

const BankData = () => {
  const { values } = useFormikContext();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { calcParams } = useCalcParams();

  const { mutateAsync, data } = useServerValidationForm("bankForm", {
    tarifParameter: calcParams,
    keysToValidate: bankDataValidateKeys,
  });

  useSetFieldWithVPPData("bankForm");
  useCheckFormValidationAndRedirect(data, NEXT_STEP);

  return (
    <div>
      <PaymentOptions />
      <BankDataForm />

      <FooterButtons
        onSubmitValidation={async () => {
          await mutateAsync(values);
        }}
        onSubmit={() => {
          setFormName(NEXT_STEP);
          setActivatedPanels((prevState) =>
            uniqueArr([...prevState, NEXT_STEP])
          );
        }}
        onBack={() => setFormName(PREV_STEP)}
      />
    </div>
  );
};

export default BankData;
