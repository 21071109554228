import { useFormikContext } from "formik";
import React from "react";

import { useTRInputContext } from "../../../context/FormInputData";
import separateNumbersAndAlphabets from "../../../utils/separateNumbersAndAlphabets";
import handleConvertCity from "../utils/handleConvertCity";

const useSetFieldWithInputData = () => {
  const inputContext = useTRInputContext();
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (inputContext.values?.zipCode && inputContext.values.city) {
      setFieldValue("zipCode", inputContext.values.zipCode);
      setFieldValue(
        "city",
        handleConvertCity(
          inputContext.values.city.value
        )
      );
    }
    if (inputContext.values?.street) {
      setFieldValue(
        "street",
        typeof inputContext.values.street !== "string" &&
          inputContext.values.street.value
      );
    }
    if (inputContext.values?.street && inputContext.values.houseNumber) {
      if (typeof inputContext.values.houseNumber === "string") {
        setFieldValue("houseNumber", inputContext.values.houseNumber);
      } else {
        const { numbers, alphabets } = separateNumbersAndAlphabets(
          inputContext.values.houseNumber?.value || ""
        );

        setFieldValue("houseNumber", numbers);

        if (alphabets) {
          setFieldValue("additionalAddress", alphabets);
        }
      }
    }
  }, [
    inputContext.values?.city,
    inputContext.values?.houseNumber,
    inputContext.values?.street,
    inputContext.values?.zipCode,
    setFieldValue,
  ]);
};

export default useSetFieldWithInputData;
