import { FormikValues } from "formik";
import qs from "query-string";

import convertDate from "../../../utils/convertDate";
import convertLocalizedStringToNumber from "../../../utils/convertLocalizedStringToNumber";
import { isValidDateAsString } from "../../../utils/isValidDate";
import { DynamischeAngaben } from "../types/SimplifierResponse";

const queryParamSimplifier = (values: FormikValues, timeframe: boolean) => {
  const dynamischeAngaben: DynamischeAngaben = {
    doubleObj: [],
    intObj: [],
    stringObj: [],
    dateObj: [],
  };

  const allBruttoPricesAvailable = values.workingPriceBrutto && values.basePriceBrutto;
  // && (values?.meterType === "1,2" || values?.workingPriceNTBrutto);

  if (allBruttoPricesAvailable) {
    const workingPriceBrutto = {
      name: "vergleichAPBrutto",
      wert: convertLocalizedStringToNumber(values.workingPriceBrutto),
    };

    // const workingPriceNTBrutto = {
    //   name: "vergleichNTBrutto",
    //   wert: convertLocalizedStringToNumber(values.workingPriceNTBrutto),
    // };

    const basePriceBrutto = {
      name: timeframe ? "vergleichGrundpreisJahrBrutto" : "vergleichGrundpreisMonatBrutto",
      wert: convertLocalizedStringToNumber(values.basePriceBrutto),
    };

    dynamischeAngaben.doubleObj
      ? dynamischeAngaben.doubleObj.push(workingPriceBrutto)
      : console.error("doubleObj is null");
    // dynamischeAngaben.doubleObj.push(workingPriceNTBrutto);
    dynamischeAngaben.doubleObj
      ? dynamischeAngaben.doubleObj.push(basePriceBrutto)
      : console.error("doubleObj is null");
  }

  const allNettoPricesAvailable = values.workingPriceNetto && values.basePriceNetto;
  // && (values.meterType === "1,2" || values.tariftyp === "1,2" || values?.workingPriceNTNetto);
  if (allNettoPricesAvailable) {
    const workingPriceNetto = {
      name: "vergleichAPNetto",
      wert: convertLocalizedStringToNumber(values.workingPriceNetto),
    };

    // const workingPriceNTNetto = {
    //   name: "vergleichNTNetto",
    //   wert: convertLocalizedStringToNumber(values.workingPriceNTNetto),
    // };

    const basePriceNetto = {
      name: timeframe ? "vergleichGrundpreisJahrNetto" : "vergleichGrundpreisMonatNetto",
      wert: convertLocalizedStringToNumber(values.basePriceNetto),
    };

    dynamischeAngaben.doubleObj
      ? dynamischeAngaben.doubleObj.push(workingPriceNetto)
      : console.error("doubleObj is null"); // dynamischeAngaben.doubleObj.push(workingPriceNTNetto);
    dynamischeAngaben.doubleObj ? dynamischeAngaben.doubleObj.push(basePriceNetto) : console.error("doubleObj is null");
  }
  const isGasTariff = values.calculatorType === "2";

  return qs.stringify({
    calculatorType: values.calculatorType,
    zipCode: values.plz ?? values.zipCode,
    zaehlerart: !isGasTariff ? values.meterType : undefined,
    customerType: values.customerType,
    ...(values.city || values.ort ? { city: values.city?.value ?? values.ort } : {}),
    ...(values.street?.value ? { street: values.street.value } : {}),
    ...(values.houseNumber
      ? {
          hnr: (typeof values.houseNumber === "string" && values.houseNumber) || values.houseNumber.value,
        }
      : {}),
    ...(values.calculatorType === "7"
      ? {
          houseNumberComplete:
            (typeof values.houseNumber === "string" && values.houseNumber) || values.houseNumber.value,
        }
      : {}),
    primeTimeConsumption:
      values.calculatorType === "7" ? 20000 : parseInt(values.oneTariff?.replace(".", "") ?? values.verbrauchHT),
    ...((values.twoTariff !== "0" && values.twoTariff) || (values.verbrauchNT && values.verbrauchNT !== "0")
      ? {
          secondaryTimeConsumption: parseInt(values.twoTariff?.replace(".", "") ?? values.verbrauchNT),
        }
      : {}),
    // eco: 3,
    page: 1,
    gridConnectionType: 0,
    pageSize: values?.paging ?? 20,
    ...(values.bonus ? { bonus: values.bonus } : {}),
    ...(values.markenIDs ? { markenIDs: values.markenIDs } : {}),
    ...(values.tarifRootIDs ? { tarifRootIDs: values.tarifRootIDs } : {}),
    ...(values.tarifRootIDsMindestfilter ? { tarifRootIDsMindestfilter: values.tarifRootIDsMindestfilter } : {}),
    ...(values.zaehlerart ? { zaehlerart: values.zaehlerart } : {}),
    ...(values.baiextendedWhiteList ? { baiextendedWhiteList: values.baiextendedWhiteList } : {}),
    ...(isValidDateAsString(values.stichtag) ? { stichtag: convertDate(values.stichtag) } : {}),
    ...(values.tarifcode ? { tarifcode: values.tarifcode } : {}),
    ...(values.handlingTarife ? { handlingTarife: values.handlingTarife } : {}),
    ...(values.versorgerIDs ? { versorgerIDs: values.versorgerIDs } : {}),
    // @ts-ignore
    ...(values?.tariffComparisonID
      ? {
          // @ts-ignore
          vergleichsTarifID: values?.tariffComparisonID,
        }
      : {}),

    // strom && 1 tariff 1,2
    ...(values.meterType === "1,2" && values.calculatorType === "1" ? { tariftyp: "1" } : {}),

    // strom && 2 tariff
    ...(values.meterType === "3,4" && values.calculatorType === "1" ? { tariftyp: "1,2" } : {}),

    // autostrom && 2 tariff
    ...(values.meterType === "3,4" && values.calculatorType === "4" && values.measurementType === "0"
      ? { tariftyp: "1,2" }
      : {}),

    // autostrom && 1 tariff
    ...(values.meterType === "1,2" && values.calculatorType === "4" && values.measurementType === "0"
      ? { tariftyp: "1" }
      : {}),

    ...(values.calculatorType === "4" && values.measurementType === "1" ? { tariftyp: "5" } : {}),
    ...(values.calculatorType === "3" ? { tariftyp: values.tariffType } : {}),
    ...(values.calculatorType === "3" ? { vergleichsRichtlinie: 10 } : {}),
    ...(values.baseSupplier || values.grundversorger
      ? { grundversorger: values.baseSupplier ?? values.grundversorger }
      : {}),
    ...(values.networkOperator || values.netzbetreiber
      ? { netzbetreiber: values.networkOperator ?? values.netzbetreiber }
      : {}),
    dynamischeAngaben: values?.compareType === "1" ? JSON.stringify(dynamischeAngaben) : [],
  });
};

export default queryParamSimplifier;
