import React from "react";

import { useConfig } from "../../../../context/Config";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import Radio from "../Radio";

const ShowPriceType = () => {
  const { config } = useConfig();
  const { priceDisplaySelection } = useFeaturesResult();

  return (
    <div className={config.media === "mobile" ? "my-4" : "mb-4"}>
      <div className="text-center mb-2">{priceDisplaySelection}</div>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex items-center">
          <Radio label="Preis Brutto" name="priceType" value="0" />
        </div>
        <div className="flex items-center">
          <Radio label="Preis Netto" name="priceType" value="1" />
        </div>
      </div>
    </div>
  );
};

export default ShowPriceType;
