import { baseURL } from "../Constant";

const controller = new AbortController();

const ProxyInstance = async (url, config) => {
  if (!baseURL) {
    throw new Error("Env is missing");
  }
  if (!url) {
    throw new Error("Invalid URL provided");
  }
  if (!config.apikey) {
    throw new Error("api key is missing in the config object");
  }

  let response;

  try {
    const options = {
      headers: {
        ...config?.headers,
        "X-API-KEY": config.apikey,
        ...(config?.authToken
          ? { Authorization: `Bearer ${config.authToken}` }
          : {}),
      },
      signal: controller.signal,
      method: config.method || "GET",
      ...(config.method === "POST" && config.body ? { body: config.body } : {}),
    };

    response = await fetch(`${baseURL}${url}`, options);

    const data = await response.json();
    return { data, status: response.status, headers: response.headers };
  } catch (error) {
    console.error(response);
    throw error;
  }
};

export default ProxyInstance;
