import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { useFormikContext } from "formik";
import { useStepChange } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { ProductEntry, Telco, useSelectTelco } from "../../../../context/Telco";
import { useSelectTelcoGridConnection } from "../../../../context/TelcoGridConnection";
import { FormValues } from "../../../../types/FormValues";
import TelcoGridConnection from "../../../../types/TelcoGridConnection";
import scrollToTop from "../../../../utils/scrollToTop";
import Hardware from "../../../TCResults/components/TelcoResults/Hardware";
import Options from "../../../TCResults/components/TelcoResults/Options";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import { checkFormValidationAndRedirect } from "../../hooks/useCheckFormValidationAndRedirect";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import FooterButtons from "../FooterButtons";
import Offermode from "../Offermode";

const ProductOptions = () => {
  const NEXT_STEP = "contactForm";
  const { selectedTelco, setSelectedTelco } = useSelectTelco();
  const [selectedOption, setSelectedOption] = React.useState<ProductEntry[]>(selectedTelco?.selectedOption ?? []);
  const [selectedHardware, setSelectedHardware] = React.useState<ProductEntry[]>(selectedTelco?.selectedHardware ?? []);
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { values, setFieldError } = useFormikContext<FormValues>();

  const { calcParams } = useCalcParams();
  const { setSelectedTelcoGridConnection } = useSelectTelcoGridConnection();
  const { setStep } = useStepChange();
  const handleRemoveSelectedOptions = React.useCallback((entry: ProductEntry) => {
    setSelectedOption((prevState) => prevState.filter((v: ProductEntry) => v.refRootId !== entry.refRootId));
  }, []);

  const handleRemoveSelectedHardware = React.useCallback((entry: ProductEntry) => {
    setSelectedHardware((prevState) => prevState.filter((v: ProductEntry) => v.refRootId !== entry.refRootId));
  }, []);
  const { mutateAsync } = useServerValidationForm("options", {
    tarifParameter: calcParams,
    keysToValidate: [], //Telco: TODO add Keys to validate
  });

  const handleBackToTariff = React.useCallback(() => {
    setStep(2);
    setSelectedTelco({} as Telco);

    scrollToTop();
  }, []);
  if (selectedTelco) {
    return (
      <>
        <ErrorBoundary fallback={<div>Options is wrong</div>}>
          {selectedTelco.optionGroups?.map((option) => (
            <Options
              key={option.name}
              options={selectedOption.sort((a, b) => a.refRootId - b.refRootId)}
              onSelect={setSelectedOption}
              onRemove={handleRemoveSelectedOptions}
              option={option}
            />
          ))}
        </ErrorBoundary>

        <ErrorBoundary fallback={<div>Hardware is wrong</div>}>
          {selectedTelco.hardwareGroups &&
            selectedTelco.hardwareGroups?.map((hardware) => (
              <Hardware
                key={hardware.name}
                hardwares={selectedHardware.sort((a, b) => a.refRootId - b.refRootId)}
                onSelect={setSelectedHardware}
                onRemove={handleRemoveSelectedHardware}
                hardware={hardware}
              />
            ))}
        </ErrorBoundary>

        <div className="grid gap-4 mt-6">
          <Offermode position={0} />
          <FooterButtons
            onSubmitValidation={async () => {
              const awaited = await mutateAsync(values);
              checkFormValidationAndRedirect(awaited, NEXT_STEP, setActivatedPanels, setFieldError, setFormName);
            }}
            onSubmit={() => {
              setFormName(NEXT_STEP);
              setActivatedPanels((prevState) => [...prevState, NEXT_STEP]);
            }}
            onBack={() => {
              setStep(2);
              setSelectedOption([]);
              setSelectedHardware([]);
              setSelectedTelco({} as Telco);
              setSelectedTelcoGridConnection({} as TelcoGridConnection);
            }}
          />
        </div>
      </>
    );
  } else {
    return <>Keine Telco-Tarif ausgewählt</>;
  }
};

export default ProductOptions;
