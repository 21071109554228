import React from "react";

import useExternalConfigs from "./useExternalConfigs";

const useGeneralLabel = () => {
  const { data: config } = useExternalConfigs("generalLabel");
  const generalLabel = config?.data?.data?.generalLabel;

  const HTLabel = React.useMemo(() => {
    return generalLabel?.HTLabel ?? "HT";
  }, [generalLabel?.HTLabel]);
  
    const NTLabel = React.useMemo(() => {
      return generalLabel?.NTLabel ?? "NT";
    }, [generalLabel?.NTLabel]);
  
    const bruttoLabel = React.useMemo(() => {
      return generalLabel?.bruttoLabel ?? "Brutto";
    }, [generalLabel?.bruttoLabel]);
  
    const nettoLabel = React.useMemo(() => {
      return generalLabel?.nettoLabel ?? "Netto";
    }, [generalLabel?.nettoLabel]);

    const currencyLabel = React.useMemo(() => {
      return generalLabel?.currencyLabel ?? "€";
    }, [generalLabel?.currencyLabel]);

    const mandatoryLabel = React.useMemo(() => {
      return generalLabel?.mandatoryLabel ?? "*";
    }, [generalLabel?.mandatoryLabel]);
  
  return {
    HTLabel,
    NTLabel,
    bruttoLabel,
    nettoLabel,
    generalLabel,
    currencyLabel,
    mandatoryLabel,
  };
};
export default useGeneralLabel;