import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Formik } from "formik";
import Card from "../../../../components/Card";
import Modal from "../../../../components/Modal";
import { useStepChange } from "../../../../context";
import { useExternLocationId } from "../../../../context/ExternLocationId";
import { useTRInputContext } from "../../../../context/FormInputData";
import { Telco, useSelectTelco } from "../../../../context/Telco";
import { useSelectTelcoGridConnection } from "../../../../context/TelcoGridConnection";
import TelcoGridConnection from "../../../../types/TelcoGridConnection";
import scrollToTop from "../../../../utils/scrollToTop";
import { useContractConclusionFormName } from "../../../TCContractConclusion/context";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import getValidationSchema from "../../validation/schema";
import AddressUsageInformation from "../AddressUsageInfo";
import InputFormModal from "../InputFormModal";
import ProductView from "./ProductView";

const TelcoResults = ({ data, additionalData }: { data: Telco[]; additionalData: TelcoGridConnection }) => {
  const [isShowInputTariff, setIsShowInputTariff] = React.useState<boolean>(false);
  const { values: inputValues, setValues } = useTRInputContext();
  const { setFormName } = useContractConclusionFormName();
  const { setStep } = useStepChange();
  const { setSelectedTelco } = useSelectTelco();
  const { setSelectedTelcoGridConnection } = useSelectTelcoGridConnection();
  const { pleaseWait } = useFeaturesResult();
  const { zipCodeInvalidMessage, zipCodeValidationText } = useFeatureInputPage();
  const { externLocationId, setExternLocationId } = useExternLocationId();
  const inputTariffRef = React.useRef<HTMLDivElement>(null);
  const ref = React.useRef<HTMLDivElement>(null);
  const validationSchema = getValidationSchema(zipCodeInvalidMessage, zipCodeValidationText);

  // const handleSelectTariff = React.useCallback((product: Telco) => setSelectedProduct(product), []);

  React.useEffect(() => {
    setFormName("options");
  }, []);
  const handleGoToContractConclusion = React.useCallback(
    (product: Telco) => {
      setStep(3);

      if (typeof window !== "undefined") {
        const telcoProduct = {
          ...product,
          objectId: externLocationId,
        };

        scrollToTop();
        setSelectedTelco(telcoProduct);
        setSelectedTelcoGridConnection(additionalData);
        setExternLocationId("");
      }
    },
    [externLocationId, setExternLocationId, setSelectedTelco, setStep, additionalData, setSelectedTelcoGridConnection]
  );
  if (data) {
    return (
      <section>
        <div className="flex flex-col" ref={inputTariffRef}>
          <Modal
            visible={isShowInputTariff}
            setVisible={setIsShowInputTariff}
            showChildrenDefault={true}
            position={{
              left: inputTariffRef?.current?.offsetLeft ?? 200,
              right: inputTariffRef?.current?.offsetTop ?? 200,
              width: inputTariffRef?.current?.offsetWidth ?? 400,
            }}
          >
            <Card
              id="addressUsageInfo"
              className={`h-full ${!isShowInputTariff ? "cursor-pointer" : ""} shadow-md`}
              onClick={() => {
                !isShowInputTariff ? setIsShowInputTariff(true) : "";
              }}
            >
              <div className="h-full" ref={ref}>
                <AddressUsageInformation isShowInputTariff={isShowInputTariff} minHeight={ref?.current?.offsetHeight} />
              </div>
              <Formik
                component={() =>
                  isShowInputTariff ? <InputFormModal setIsShowInputTariff={setIsShowInputTariff} /> : <></>
                }
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={{
                  city: inputValues?.city,
                  street: inputValues?.street,
                  zipCode: inputValues?.zipCode || "",
                  houseNumber: inputValues?.houseNumber || "",
                  meterType: inputValues?.meterType || "1,2",
                  customerType: inputValues?.customerType || "0",
                  oneTariff: inputValues?.oneTariff || "3.200",
                  twoTariff: inputValues?.twoTariff || "",
                  tariffType: inputValues?.tariffType || "0",
                  measurementType: inputValues?.measurementType || "0",
                  stichtag: inputValues?.stichtag ?? "",
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  setValues({ ...inputValues, ...values });
                  // await submitTariff(values);
                  setSubmitting(false);
                }}
              />
            </Card>
          </Modal>
        </div>
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <ProductView data={data} handleSelectTariff={handleGoToContractConclusion} />
        </ErrorBoundary>
      </section>
    );
  } else {
    return <div>{pleaseWait}</div>;
  }
};

export default TelcoResults;
