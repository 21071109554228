import * as React from "react";

import { useConfig } from "../../../context";

const useRadioClassName = () => {
  const config = useConfig();

  return React.useMemo(() => {
    if (config.media === "tablet")
      return "gap-4 flex justify-between items-center";

    if (config.media === "mobile") {
      return "grid grid-cols-2 gap-4";
    }

    return "gap-4 flex items-center";
  }, [config.media]);
};

export default useRadioClassName;
