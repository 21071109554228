import { useFormikContext } from "formik";
import React from "react";

import useVPPCustomerService from "../../../hooks/useVPPCustomerService";

const useSetFieldWithVPPData = (formType: string) => {
  const { data: vppData } = useVPPCustomerService();
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (vppData) {
      const shippingAddress = vppData?.data.object?.person?.addresses.find(
          (val: { address_type: string }) => val.address_type === "SHIPPING"
      );

      const billingAddress = vppData?.data.object?.person.addresses.find(
          (val: { address_type: string }) => val.address_type === "BILLING"
      );

      const phone = vppData?.data.object?.person.contacts.find(
          (val: { contact_type: string }) => val.contact_type === "PHONE"
      );
      const mobilephone = vppData?.data.object?.person.contacts.find(
          (val: { contact_type: string }) => val.contact_type === "CELLPHONE"
      );
      const email = vppData?.data.object?.person.contacts.find(
          (val: { contact_type: string }) => val.contact_type === "EMAIL"
      );

      const dob = vppData?.data.object?.person.date_of_birth?.split("-").reverse().join(".");

      const payment = vppData?.data.object?.person.payments[0];

      if (formType === "contactForm") {
        if (shippingAddress) {
          if (shippingAddress.salutation) {
            setFieldValue("salutation", {
              label: shippingAddress.salutation,
              value: shippingAddress.salutation,
            });
          }

          if (shippingAddress.title) {
            setFieldValue("title", {
              label: shippingAddress.title,
              value: shippingAddress.title,
            });
          }

          if (shippingAddress.first_name) {
            setFieldValue("firstName", shippingAddress.first_name);
          }

          if (shippingAddress.last_name) {
            setFieldValue("lastName", shippingAddress.last_name);
          }

          if (shippingAddress.company) {
            setFieldValue("companyName", shippingAddress.company);
          }

          if (shippingAddress.id_person) {
            setFieldValue("idPerson", shippingAddress.id_person);
          }
        }

        if (email) {
          if (email.contact_value) {
            setFieldValue("email", email.contact_value);
            setFieldValue("confirmationEmail", email.contact_value);
          }
        }

        if (phone) {
          if (phone.contact_value) {
            setFieldValue("phoneNumber", phone.contact_value);
          }
        }
        if (mobilephone) {
          if (phone.contact_value) {
            setFieldValue("mobileNumber", mobilephone.contact_value);
          }
        }

        if (dob) {
          setFieldValue("birthday", dob);
        }
      }

      if (formType === "addressForm") {
        if (shippingAddress) {
          if (shippingAddress.zipcode) {
            setFieldValue("zipCode", shippingAddress.zipcode);
          }

          if (shippingAddress.city) {
            setFieldValue("city", shippingAddress.city);
          }

          if (shippingAddress.street) {
            setFieldValue("street", {
              label: shippingAddress.street,
              value: shippingAddress.street,
            });
          }

          if (shippingAddress.house_no) {
            setFieldValue("houseNumber", shippingAddress.house_no);
          }

          if (shippingAddress.house_no_add) {
            setFieldValue("additionalAddress", shippingAddress.house_no_add);
          }
        }

        if (billingAddress) {
          if (billingAddress.zipcode) {
            setFieldValue("billingZipCode", billingAddress.zipcode);
          }

          if (billingAddress.city) {
            setFieldValue("billingCity", billingAddress.city);
          }

          if (billingAddress.street) {
            setFieldValue("billingStreet", billingAddress.street);
          }

          if (billingAddress.house_no) {
            setFieldValue("billingHouseNumber", billingAddress.house_no);
          }

          if (billingAddress.house_no_add) {
            setFieldValue("billingAdditionalAddress", billingAddress.house_no_add);
          }

          if(billingAddress.title){
            setFieldValue('billingTitle', billingAddress.title);
          }

          if(billingAddress.salutation){
            setFieldValue('billingSalutation',billingAddress.salutation);
          }
          if(billingAddress.company){
            setFieldValue('billingCompanyName', billingAddress.company);
          }
          if(billingAddress.first_name){
            setFieldValue('billingFirstName',billingAddress.first_name);
          }
          if(billingAddress.last_name){
            setFieldValue('billingLastName', billingAddress.last_name);
          }
        }
      }

      if (formType === "bankForm") {
        if (payment) {
          if (payment.type === "SEPA_DIRECT_DEBIT") {
            setFieldValue("paymentOptions", "directDebit");
            setFieldValue("isAgreeToSEPA", true);
          }
          if (payment.type === "BANKTRANSFER") {
            setFieldValue("paymentOptions", "bankTransfer");
          }

          if (payment.account) {
            setFieldValue("iban", payment?.account?.replaceAll(" ", ""));
          }

          if(payment.first_name){
            setFieldValue('firstNameAccountHolder', payment.first_name);
          }
          if(payment.last_name){
            setFieldValue('lastNameAccountHolder',payment.last_name);
          }
        }
      }
    }
  }, [formType, setFieldValue, vppData]);

  return { data: vppData };
};

export default useSetFieldWithVPPData;
