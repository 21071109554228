import { useFormikContext } from "formik";
import * as React from "react";

import GetSelect from "../../../../../components/GetSelect";
import WarningIcon from "../../../../../components/icons/warning";
import Input from "../../../../../components/Input";
import useFeatureInputPage from "../../../../TCInputs/hooks/useFeatureInputPage";

const TRAddressResultList = ({ isError, data, isLoading }) => {
  const { values, setFieldValue } = useFormikContext();
  const { zipCodeLabel, cityLabel, tariffNotFoundLabel } = useFeatureInputPage();

  const disabledCity = React.useMemo(() => {
    return data?.citySuggestions?.length === 0;
  }, [data?.citySuggestions?.length]);

  React.useEffect(() => {
    if (values.zipCode?.length < 5) {
      setFieldValue("city", "");
      setFieldValue("street", "");
      setFieldValue("houseNumber", "");
    }
  }, [setFieldValue, values.zipCode]);

  return (
    <>
      <div className="flex flex-col gap-2 lg:flex-row lg:gap-10">
        <div className="w-1/2 lg:w-1/4">
          <Input label={zipCodeLabel} name="zipCode" onlyNumber maxLength={5} tabIndex={1} isLoading={isLoading} />
        </div>
        <div className="w-full">
          <GetSelect
            label={cityLabel}
            name="city"
            isLoading={isLoading}
            disabled={disabledCity}
            defaultOpen={values.zipCode.length===5}
            options={
              data?.citySuggestions.map((opt) => ({
                label: opt,
                value: opt,
              })) || []
            }
            tabIndex={2}
          />
        </div>
      </div>
      {isError && (
        <div className="flex flex-row items-center mt-2 w-full">
          <WarningIcon className="mr-3" fill="red" />
          <small className="text-red-500">{tariffNotFoundLabel}</small>
        </div>
      )}
    </>
  );
};

export default TRAddressResultList;
