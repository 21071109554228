import * as React from "react";

import Button from "../../../../components/Button";
import { useSelectTariff } from "../../../../context/Tariff";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import WarningDialog from "../WarningDialog";

const FooterButtonGroup = () => {
  const { selectedTariff } = useSelectTariff();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { changeSelection } = useFeaturesCC();

  return (
    <>
      <div className="text-center">
        <Button
          variant="primary"
          className="px-6 p-2 mb-2.5 mt-10"
          type="submit"
          onClick={() => setModalIsOpen(!modalIsOpen)}
        >
          {changeSelection}
        </Button>
        <div className="text-xs text-gray-dark">
          Alle angegebenen Preise sind {selectedTariff?.isBrutto ? "inkl." : "zuzüglich"} USt.
        </div>
      </div>
      <WarningDialog modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
    </>
  );
};

export default FooterButtonGroup;
