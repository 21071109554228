import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const calculationParameter = createContext({});

const CalculationParameterProvider = ({ children }) => {
  const [calcParams, setCalcParams] = React.useState(null);

  return (
    <calculationParameter.Provider value={{ calcParams, setCalcParams }}>
      {children}
    </calculationParameter.Provider>
  );
};

export default CalculationParameterProvider;
export const useCalcParams = () =>
  useContextSelector(calculationParameter, (c) => c);
