import { useQuery } from "react-query";

import { useConfig, useParameter } from "../context";
import VPPInstance from "../services/vpp";

const useVPPCustomerService = () => {
  const { vppCustomerId, authToken } = useParameter();
  const config = useConfig();

  return useQuery(
    `GET_VPP_CUSTOMER_ID_${vppCustomerId}`,
    async () => {
      try {
        const { data } = await VPPInstance(`/customer/get/${vppCustomerId}`, {
          apikey: config.apikey || "",
          authToken: `Bearer ${authToken}`,
        });

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!vppCustomerId && !!authToken && !!config.apikey,
    }
  );
};

export default useVPPCustomerService;
