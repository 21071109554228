const handleAddressValidation = (values, additionalCondition) => {
  const validDeliveryAddress =
    !!values.zipCode &&
    !!values.city &&
    !!values.street &&
    !!values.houseNumber;

  const validBillingAddress =
    !!values.billingSalutation &&
    !!values.billingFirstName &&
    !!values.billingLastName &&
    !!values.billingZipCode &&
    !!values.billingCity &&
    !!values.billingStreet &&
    !!values.billingHouseNumber;

  const validInstallationAddress =
    !!values.installationSalutation &&
    !!values.installationFirstName &&
    !!values.installationLastName &&
    !!values.installationZipCode &&
    !!values.installationCity &&
    !!values.installationStreet &&
    !!values.installationHouseNumber;

  if (!values.deliveryIsSameInstallationAddress) {
    return validDeliveryAddress && validInstallationAddress;
  }
  if (!values.deliveryIsSameBillingAddress) {
    return validDeliveryAddress && validBillingAddress;
  }

  if (additionalCondition?.isTelco) {
    if (!values.deliveryIsSameBillingAddress) {
      return (
        validDeliveryAddress &&
        validBillingAddress &&
        !!values.installationLocation
      );
    }

    return validDeliveryAddress && !!values.installationLocation;
  }
  return validDeliveryAddress;
};

export default handleAddressValidation;
