import * as React from "react";

import Checkbox from "../../../../../components/Checkbox";
import ReactHTMLParser from "../../../../../components/HTMLParser";
import { useTariff } from "../../../../../context";
import { useSelectTariff } from "../../../../../context/Tariff";
import { useSelectTelco } from "../../../../../context/Telco";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import useGetPolicyDocument from "../../../hooks/useGetPolicyDocument";
import Headline from "../../Headline";

const Consent = () => {
  const {
    counsellingProtocol,
    acceptCounsellingProtocollText,
    acceptGeneralTnc,
    acceptCancellationPolicy,
    acceptPrivacyPolicy,
    preisList,
    serviceDescription,
  } = useFeaturesCC();
  const { selectedTelco } = useSelectTelco();
  const { selectedTariff } = useSelectTariff();
  const { data } = useGetPolicyDocument();
  const tariff = useTariff();

  const agbText = React.useMemo(() => {
    if (acceptGeneralTnc) {
      return (
        <ReactHTMLParser>
          {acceptGeneralTnc
            .replace("%url%", data?.data?.gtcUrl || "")
            .replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    }
    return undefined;
  }, [acceptGeneralTnc, data?.data?.gtcUrl, selectedTariff?.versorgerName]);

  const cancellationText = React.useMemo(() => {
    if (acceptCancellationPolicy) {
      return (
        <ReactHTMLParser>
          {acceptCancellationPolicy
            .replace("%url%", data?.data?.cancellationPolicyUrl || "")
            .replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    }
    return undefined;
  }, [acceptCancellationPolicy, data?.data?.cancellationPolicyUrl, selectedTariff?.versorgerName]);

  const privacyPolicyText = React.useMemo(() => {
    if (acceptPrivacyPolicy) {
      return (
        <ReactHTMLParser>
          {acceptPrivacyPolicy
            .replace("%url%", data?.data?.privacyUrl || "")
            .replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    }
    return undefined;
  }, [acceptPrivacyPolicy, data?.data?.privacyUrl, selectedTariff?.versorgerName]);

  const acceptCounsellingProtocollHTMLText = React.useMemo(() => {
    if (acceptCounsellingProtocollText) {
      return (
        <ReactHTMLParser>
          {acceptCounsellingProtocollText.replace("%versorgername%", selectedTariff?.versorgerName || "")}
        </ReactHTMLParser>
      );
    }
    return undefined;
  }, [acceptCounsellingProtocollText, selectedTariff?.versorgerName]);

  const preisListText = React.useMemo(() => {
    if (selectedTelco && tariff.id === "7") {
      const documentUrl = selectedTelco?.productDocuments.filter((document) =>
        document.fileName?.includes("Preisliste")
      )[0]?.uRL;
      if (preisList && documentUrl) {
        return <ReactHTMLParser>{preisList.replace("%url%", documentUrl || "")}</ReactHTMLParser>;
      }
    }
    return undefined;
  }, [preisList, tariff, selectedTelco]);

  const serviceDescriptionText = React.useMemo(() => {
    if (selectedTelco && tariff.id === "7") {
      const documentUrl = selectedTelco?.productDocuments.filter((document) =>
        document.fileName?.includes("Leistungsbeschreibung")
      )[0]?.uRL;
      if (serviceDescription && documentUrl) {
        return <ReactHTMLParser>{serviceDescription.replace("%url%", documentUrl || "")}</ReactHTMLParser>;
      }
    }
    return undefined;
  }, [serviceDescription, selectedTelco, tariff]);

  return (
    <div>
      <Headline>Einwilligungserklärung</Headline>
      <div className="mb-4 text-sm">
        Mit Klick auf "Zahlungspflichtig bestellen" beauftrage ich den Versorger mit der Belieferung an die angegebene
        Lieferanschrift und erteile ihm die Vollmacht, ggfs. meinen derzeit bestehenden Vertrag zu kündigen sowie die
        erforderlichen Verträge mit dem örtlichen Netzbetreiber abzuschließen
      </div>
      {selectedTelco && tariff.id === "7" && (
        <>
          {preisListText && <Checkbox name="isPreisListCheck" labelComponent={preisListText} />}
          {serviceDescriptionText && (
            <Checkbox name="isServiceDescriptionCheck" labelComponent={serviceDescriptionText} />
          )}
        </>
      )}
      <Checkbox name="isAGBCheck" labelComponent={agbText} className="mb-2" />
      <Checkbox name="isCancellationCheck" labelComponent={cancellationText} className="mb-2" />
      <Checkbox name="isPrivacyPolicyCheck" labelComponent={privacyPolicyText} className="mb-2" />
      {counsellingProtocol && (
        <>
          <Checkbox name="acceptCounsellingProtocoll" labelComponent={acceptCounsellingProtocollHTMLText} />
        </>
      )}
    </div>
  );
};

export default Consent;
