import { useField } from "formik";
import * as React from "react";

import Checkbox from "../../../../components/Checkbox";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const Offermode = ({ position }: { position: number }) => {
  const { offerMode, forwardAsOffer } = useFeaturesCC();
  const [offermoderPosition, , { setValue: setOffermodeposition }] = useField("offermodePosition");
  const [{ value: asOffer }] = useField("asOffer");

  if (offerMode) {
    if (typeof offermoderPosition.value === "number" && offermoderPosition.value < position) {
      return <></>;
    }
    return (
      <div className="mt-4 p-4 bg-secondary-light border-1 border-dashed border-primary rounded-lg">
        <Checkbox
          name="asOffer"
          label={forwardAsOffer}
          onClick={() => setOffermodeposition(!asOffer ? position : null)}
        />
      </div>
    );
  }
  return null;
};

export default Offermode;
