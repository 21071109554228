import { useFormikContext } from "formik";
import React from "react";

import Input from "../../../../components/Input";
import Mobile from "../../../../components/View/Mobile";
import { useConfig, useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSelectTelco } from "../../../../context/Telco";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { uniqueArr } from "../../../../utils/uniqueArr";
import { preSupplierValidateKeysEnergy, preSupplierValidateKeysTelco } from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import useCheckFormValidationAndRedirect from "../../hooks/useCheckFormValidationAndRedirect";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import DesiredDeliveryDate from "./DesiredDeliveryDate";
import EVNForm from "./EVNForm";
import ExchangeNewTariffForm from "./ExchangeNewTariffForm";
import InhaberForm from "./InhaberForm";
import NewSupplierForm from "./NewSupplierForm";
import ReasonToChangeBox from "./ReasonToChangeBox";
import RelocationForm from "./RelocationForm";
import SubscribeListForm from "./SubscribeListForm";
import TelephoneInstallationRequest from "./TelephoneInstallationRequest";

const NEXT_STEP = "bankForm";
const PREV_STEP = "addressForm";

const PreSupplierData = ({ disableFooterButtons = false }) => {
  const { values } = useFormikContext();
  const { values: trInputContext } = useTRInputContext();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { selectedTelco } = useSelectTelco();
  const { calcParams } = useCalcParams();
  const tariff = useTariff();
  const config = useConfig();
  const {
    reasonToChange,
    maLoIDLabel,
    meterNumberLabel,
    currentMeterReadingLabel,
    isEnabledReadingDate,
  } = useFeaturesCC();
  const {
    HTLabel,
    NTLabel,
  } = useGeneralLabel;

  const { mutateAsync, data } = useServerValidationForm("preSupplierForm", {
    tarifParameter: calcParams,
    keysToValidate: tariff.id === 7 ? preSupplierValidateKeysTelco : preSupplierValidateKeysEnergy,
  });

  useSetFieldWithVPPData("preSupplierForm");
  useCheckFormValidationAndRedirect(data, NEXT_STEP);

  return (
    <>
      {selectedTelco ? null : <ReasonToChangeBox options={reasonToChange.options} />}

      {selectedTelco ? (
        <>
          <DesiredDeliveryDate />
          <TelephoneInstallationRequest />
          <EVNForm />
          <SubscribeListForm />
          <InhaberForm />
        </>
      ) : (
        <>
          {values.reasonToChange === "E03" && <NewSupplierForm />}
          {values.reasonToChange === "tarifwechsel" && <ExchangeNewTariffForm />}
          {values.reasonToChange === "E01" && <RelocationForm />}
          <Mobile>
            <div className="my-4">
              <Input name="counterNumber" label={meterNumberLabel} inputType="number" />
            </div>
            <div className="my-4">
              <Input name="maLoID" label={maLoIDLabel} onlyNumber maxLength={11} />
            </div>
            {values.reasonToChange === "E01" && (
              <div className="my-4">
                <Input
                  name="actualCounterReading"
                  label={trInputContext.meterType === "1,2" ? currentMeterReadingLabel : currentMeterReadingLabel + " " + HTLabel}
                />
              </div>
            )}
            {values.reasonToChange === "E01" && trInputContext.meterType === "3,4" && (
              <div className="my-4">
                <Input name="actualNTCounterReading" inputType="number" label={currentMeterReadingLabel + " " + NTLabel} />
              </div>
            )}
            {isEnabledReadingDate && (
              <div className="my-4">
                <Input name="readingDate" className="w-full" type="datepicker" label="Ablesedatum" />
              </div>
            )}
          </Mobile>
          {config.media !== "mobile" && (
            <>
              <div className="my-4 grid grid-cols-3 gap-4">
                <Input name="counterNumber" label={meterNumberLabel} inputType="number" />
                {values.reasonToChange === "E01" && (
                  <Input
                    name="actualCounterReading"
                    label={trInputContext.meterType === "1,2" ? currentMeterReadingLabel : currentMeterReadingLabel + " " + HTLabel}
                    inputType="number"
                  />
                )}
                {values.reasonToChange === "E01" && trInputContext.meterType === "3,4" && (
                  <Input name="actualNTCounterReading" inputType="number" label={currentMeterReadingLabel + " " + NTLabel} />
                )}
                {isEnabledReadingDate && (
                  <Input name="readingDate" className="w-full" type="datepicker" label="Ablesedatum" />
                )}
                <Input name="maLoID" label={maLoIDLabel} onlyNumber maxLength={11} />
              </div>
            </>
          )}
        </>
      )}

      {!disableFooterButtons && (
        <FooterButtons
          onSubmitValidation={async () => {
            await mutateAsync(values);
          }}
          onSubmit={() => {
            setFormName(NEXT_STEP);
            setActivatedPanels((prevState) => uniqueArr([...prevState, NEXT_STEP]));
          }}
          onBack={() => setFormName(PREV_STEP)}
        />
      )}
    </>
  );
};

export default PreSupplierData;
