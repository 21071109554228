import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "../../../../components/Button";
import Car from "../../../../components/icons/Car";
import Flame from "../../../../components/icons/Flame";
import Flash from "../../../../components/icons/Flash";
import Globe from "../../../../components/icons/Globe";
import Heat from "../../../../components/icons/Heat";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { tariffOptionTypes, useTariff } from "../../../../context";
import { calcResult, useCalcResult } from "../../../../context/CalculationResult";
import { useConfig } from "../../../../context/Config";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const TRButtonGroups = ({ onlyInputTarget = false }: { onlyInputTarget?: boolean }) => {
  const { setTariffId, id } = useTariff();
  const [active, setActive] = useState("1");
  const { setCalcResult } = useCalcResult();

  useEffect(() => {
    if (id) {
      setActive(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { config } = useConfig();
  const { electricity, gas, heizstrom, autostrom, telco, thg } = useFeatureInputPage();
  const { setFieldValue } = useFormikContext();

  const TARIF_LABELS: { [key: tariffOptionTypes]: string } = useMemo(
    () => ({
      "1": electricity,
      "2": gas,
      "3": heizstrom,
      "4": autostrom,
      "7": telco,
      "8": thg,
    }),
    [autostrom, electricity, gas, heizstrom, telco, thg]
  );

  const handleTariffType = useCallback((id: tariffOptionTypes, className?: string) => {
    switch (id) {
      case "1":
        return {
          tariftyp: "1",
          medium: "electricity",
          icon: <Flash className={className} />,
          tariffType: "energy",
        };
      case "2":
        return {
          tariftyp: "2",
          medium: "gas",
          icon: <Flame className={className} />,
          tariffType: "gas",
        };
      case "3":
        return {
          tariftyp: "3",
          medium: "heating",
          icon: <Heat className={className} />,
          tariffType: "energy",
        };
      case "4":
        return {
          tariftyp: "4",
          medium: "autostrom",
          icon: <Car className={className} />,
          tariffType: "energy",
        };
      case "7":
        return {
          tariftyp: "7",
          medium: "telco",
          icon: <Globe className={className} />,
          tariffType: "telco",
        };
      default:
        return {
          tariftyp: "1",
          medium: "electricity",
          icon: <Flash className={className} />,
          tariffType: "energy",
        };
    }
  }, []);
  const handleOnClick = useCallback(
    async (id: tariffOptionTypes) => {
      setCalcResult({} as calcResult);
      const { medium, tariftyp, tariffType } = handleTariffType(id);
      setTariffId({
        id: id,
        tariftyp,
        medium,
        tariffType,
      });

      setActive(id);
      setFieldValue("oneTariff", id === "2" ? "20.000" : "3.200");
      setFieldValue("city", { value: "", label: "" });
      setFieldValue("street", { value: "", label: "" });
      setFieldValue("houseNumber", "");
      if (id === "2") {
        setFieldValue("meterType", "1,2");
      }
    },
    [handleTariffType, setTariffId, setCalcResult, setFieldValue]
  );
  if (onlyInputTarget) {
    return (
      <div className="mb-2 flex flex-wrap gap-4">
        {config?.frontendConfig?.calculatorTypeIDs.map((id) => {
          return (
            <div key={id} onClick={() => handleOnClick(id)}>
              <Button variant={active !== id ? "tabNav" : "secondary"} className="!px-3">
                {handleTariffType(id, "fill-primary")?.icon}
                <span className="inline align-middle ml-2">{TARIF_LABELS[id]}</span>
              </Button>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="mt-10 mb-2">
      {config?.frontendConfig?.calculatorTypeIDs.map((id) => {
        return (
          <div
            key={id}
            onClick={() => handleOnClick(id)}
            className={`${
              active !== id ? "bg-secondary focus:text-primary" : "bg-content-bg text-content-text relative z-20"
            } ${
              config.boxWidth <= 400 ? "px-4" : "px-6"
            } py-3 rounded-t-[10px] shadow-tabs inline cursor-pointer box-border`}
          >
            <Desktop>
              {handleTariffType(id)?.icon}
              <span className="inline align-middle ml-2">{TARIF_LABELS[id]}</span>
            </Desktop>
            <Tablet>
              <span className="inline align-middle">{TARIF_LABELS[id]}</span>
            </Tablet>
            <Mobile>{handleTariffType(id)?.icon}</Mobile>
          </div>
        );
      })}
    </div>
  );
};

export default TRButtonGroups;
