import React, { ReactNode } from "react";

interface FormControlProps extends React.HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  className?: string;
}

const FormControl = ({ children, className = "", ...props }: FormControlProps) => {
  return (
    <section {...props} className={`form-control ${className || ""}`.trim()}>
      {children}
    </section>
  );
};

export default FormControl;
