import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --get-c-primary: #0065cc;
    --get-c-primary-hover: #007eff;
    --get-c-primary-text: #ffffff;

    --get-c-secondary: #92c6ff;
    --get-c-secondary-light: #eff7fe;
    --get-c-secondary-hover: #9db8d5;
    --get-c-secondary-text: #313131;

    --get-c-statistics-1: #75ad40;
    --get-c-statistics-2: #0065cc;
    --get-c-statistics-3: #92c6ff;
  }
`;
export default myStyle;
