import * as React from "react";

const ShowChart = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" className="inline">
      <g mask="url(#mask0_4_19827)">
        <path
          d="M2.33301 12.3332L1.33301 11.3332L6.33301 6.33317L8.99967 8.99984L13.733 3.6665L14.6663 4.59984L8.99967 10.9998L6.33301 8.33317L2.33301 12.3332Z"
          fill="#1E40AF"
        />
      </g>
    </svg>
  );
};

export default ShowChart;
