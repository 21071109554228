import React from "react";

import Media from "../../../../components/View/Media";

const RowItem = ({
  label,
  value,
  className = "text-right",
  invert = false,
}: {
  label: string;
  value: string | number;
  className?: string;
  invert?: boolean;
}) => {
  return (
    <div className="grid grid-cols-3 gap-4 items-baseline">
      <Media tablet={true} mobile={true}>
        <div className={`text-sm text-gray-dark ${invert ? "" : "col-span-2"}`}>{label}</div>
        <div className={`text-sm text-right ${className} ${!invert ? "" : "col-span-2"}`}>{value}</div>
      </Media>
      <Media desktop={true}>
        <div className={`text-gray-dark ${invert ? "" : "col-span-2"}`}>{label}</div>
        <div className={`text-right ${className} ${!invert ? "" : "col-span-2"}`}>{value}</div>
      </Media>
    </div>
  );
};

export default RowItem;
