import format from "date-fns/format";

import convertDate from "../../../utils/convertDate";
import { uuid } from "./uuid";

const handleTariffForms = (val, opt) => {
  const isMovingIn = val.reasonToChange === "E01"; // umzug
  const isChangeSupplier = val.reasonToChange === "E03"; // versorger wechseln

  let terminwunsch = "";

  if (isChangeSupplier) {
    if (val.desiredDeliveryDate) {
      terminwunsch = convertDate(val.desiredDeliveryDate);
    } else {
      terminwunsch = "";
    }
  }

  // format date
  let cancellationDate = "";
  if (val.cancellationDate) {
    cancellationDate = convertDate(val.cancellationDate);
  }

  return {
    teilnehmerverzeichnis: "Nein",
    la_anrede:
      typeof val.salutation !== "string"
        ? val.salutation.value
        : val.salutation,
    la_email: val.email,
    la_geburtsdatum: val.birthday.split(".").reverse().join("-"),
    la_hausnummer: val.houseNumber,
    la_nachname: val.lastName,
    la_ort: val.city,
    la_plz: val.zipCode,
    la_strasse: typeof val.street !== "string" ? val.street.value : val.street,
    la_vorname: val.firstName,
    la_installationsort: val.installationLocation || null,
    la_telefonnummer: val.phoneNumber,
    la_sicherheitskennzahl: val.safetyIndex,
    ra_anrede: val.billingSalutation || undefined,
    ra_hausnummer: val.billingHouseNumber || undefined,
    ra_nachname: val.billingLastName || undefined,
    ra_ort: val.billingCity || undefined,
    ra_plz: val.billingZipCode || undefined,
    ra_strasse: val.billingStreet || undefined,
    ra_vorname: val.billingFirstName || undefined,
    abw_lieferungsadresse_anrede:
      (typeof val.installationSalutation !== "string" &&
        val?.installationSalutation?.value) ||
      undefined,
    abw_lieferungsadresse_hausnummer: val.installationHouseNumber || undefined,
    abw_lieferungsadresse_nachname: val.installationLastName || undefined,
    abw_lieferungsadresse_ort: val.installationCity || undefined,
    abw_lieferungsadresse_plz: val.installationZipCode || undefined,
    abw_lieferungsadresse_strasse: val.installationStreet || undefined,
    abw_lieferungsadresse_vorname: val.installationFirstName || undefined,
    sepa_erteilt: val.paymentOptions === "directDebit" ? val.isAgreeToSEPA : "",
    iban: val.paymentOptions === "directDebit" ? val.iban : "",
    bic: val.paymentOptions === "directDebit" ? val.bic : "",
    geldinstitut: val.paymentOptions === "directDebit" ? val.bankName : "",
    einzug_umzug: val.reasonToChange,
    zaehlerstand: isMovingIn ? val.actualCounterReading : "",
    datum_einzug_umzug: isMovingIn
      ? val.moveInDate.split(".").reverse().join("-")
      : null,
    vorlieferant_kundennummer:
      isChangeSupplier || !isMovingIn ? val.customerNumber : "",
    zaehlernummer: val.counterNumber ? val.counterNumber : "",
    ablese_datum: val.readingDate ? val.readingDate : "",
    maLoID: val.maLoID ? val.maLoID : "",
    agb_akzeptiert: val.isAGBCheck,
    kenntnisnahme_widerrufsrecht: val.isCancellationCheck,
    kenntnisnahme_datenschutz: val.isPrivacyPolicyCheck,
    kundenPortalAnlegen: false,
    versandart_rechnung: val.invoiceDeliveryBy,
    gutscheincode: val.couponCode || null,
    werber_identnummer: val.customerRecruitment || null,
    digitalSignature: val.signature,
    medium: val.medium,
    tariffCalculatorTypeId: val.tariffId,
    vorlieferant_verbandsnummer: val.prevSupplierName.key || null,
    vorlieferant_name: isChangeSupplier
      ? typeof val.prevSupplierName !== "string" && val.prevSupplierName?.value
      : "",
    bestelldatum: format(new Date(), "yyyy-MM-dd"),
    vertriebspartner_id: val.versorgerID,
    kuendigung_datum: cancellationDate || null,
    terminwunsch,
    kuendigung_vorversorger: isChangeSupplier
      ? val.hasCanceledPrevSupplier === "true"
      : false,
    get_vorgangs_id: uuid(),
    vertragsnummer: uuid(),
    id_person: val.idPerson,
    verbindlicher_abschluss: opt.bindingOffer,
    maloid: isChangeSupplier && val.maLoID ? val.maLoID : "",
    rufnummeruebernahme: "new",
    zu_portierende_telefonnummer: null,
    einzelverbindungsnachweis: "new",
    attachments: val.attachment || [],
    paymentMethod:
      val.paymentOptions === "directDebit" ? "directDebit" : "bankTransfer",
    billingSameAsDelivery: val.deliveryIsSameBillingAddress,
    secondDeliverySameAsDelivery: val.deliveryIsSameBillingAddress,
    authToken: val.authToken, // CIM Export
    ...(val.promotionalConsentByMail
      ? { werbeeinwilligung_email: val.promotionalConsentByMail }
      : {}),
    ...(val.promotionalConsentByPost
      ? { werbeeinwilligung_post: val.promotionalConsentByPost }
      : {}),
    ...(val.promotionalConsentByPhone
      ? { werbeeinwilligung_telefon: val.promotionalConsentByPhone }
      : {}),
    ...(val.customerSatisfactionSurveyByMail
      ? { kundenzufriedenheit_email: val.customerSatisfactionSurveyByMail }
      : {}),
    ...(val.customerSatisfactionSurveyByPost
      ? { kundenzufriedenheit_post: val.customerSatisfactionSurveyByPost }
      : {}),
    ...(val.customerSatisfactionSurveyByPhone
      ? { kundenzufriedenheit_telefon: val.customerSatisfactionSurveyByPhone }
      : {}),
    abschlussstatus: "",
  };
};

export default handleTariffForms;
