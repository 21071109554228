import { useFormikContext } from "formik";
import React, { useMemo, useState } from "react";

import Button from "../../../../components/Button";
import FormControl from "../../../../components/FormControl";
import Caret from "../../../../components/icons/caret";
import Input from "../../../../components/Input";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import RangeSlider from "../../../../components/Slider";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { TARIF_LABELS } from "../../../../Constant";
import { useConfig, useTariff } from "../../../../context";
import { useTRInputContext } from "../../../../context/FormInputData";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import usePrimaService from "../../../../hooks/usePrimaService";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useSimplifier from "../../hooks/useSimplifier";
import TRAddress from "../Address";
import Header from "../Header";
import CustomerTypeRadio from "../Radio/CustomerType";
import MeasurementType from "../Radio/MeasurementType";
import TariffType from "../Radio/TariffType";

const TariffInputForm = ({ handleSubmit, values, isSubmitting }) => {
  const [openAccordion, setOpenAccordion] = useState(true);
  const { setValues } = useTRInputContext();
  const handleAccordion = () => {
      setOpenAccordion(!openAccordion);
      setFieldValue("meterType", openAccordion ? "3,4": "1,2");
      setValues((prevState) => {
        return {
          ...prevState,
          meterType: openAccordion ? "3,4" : "1,2",
        };
      });
  };
  const {
    searchLabel,
    consumptionLabel,
    usageLabel,
    oneTariffLabel,
    twoTariffsLabel,
    selectTariffLabel,
    usageRuler,
  } = useFeatureInputPage();
  const { HTLabel, NTLabel} = useGeneralLabel();
  const tariff = useTariff();
  const { data, ...rest } = useSimplifier();
  const { data: primaData } = usePrimaService();
  const config = useConfig();
  const buttonLabel = useMemo(() => `${TARIF_LABELS[`${tariff.id}`]}tarife vergleichen`, [tariff.id]);
  const { values: fields, setFieldValue } = useFormikContext();

  React.useEffect(() => {
    // only strom and gas
    if (tariff.id === 1 || tariff.id === 2) {
      setFieldValue("networkOperator", data?.networkOperator);
      setFieldValue("baseSupplier", data?.baseSupplier);
    }
  }, [data?.baseSupplier, data?.networkOperator, setFieldValue, tariff.id]);

  React.useEffect(() => {
    if (primaData?.zipCode && primaData?.city && primaData?.street && primaData?.houseNumber && tariff.id === 7) {
      const timer = setTimeout(() => {
        handleSubmit();
      }, 2000);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [primaData, tariff.id, handleSubmit]);

  const disabled = useMemo(
    () =>
      (data?.unique && values.city !== "") ||
      (primaData?.zipCode && primaData?.city && primaData?.street && primaData?.houseNumber && tariff.id === 7),
    [data, primaData, tariff.id, values]
  );

  return (
    <div className="max-w-[660px] mx-auto rounded-xl bg-[--get-content-bg] mb-1 p-8 border-solid border-content border-content-border shadow-md">
      <Header />

      {config?.frontendConfig?.calculatorTypeIDs ? (
        <form onSubmit={handleSubmit}>
          <FormControl>
            <div className="flex flex-col gap-2 lg:gap-6 w-full mt-6">
              <TRAddress data={data} {...rest}>
                <CustomerTypeRadio />
              </TRAddress>

              {tariff.id === 3 && (
                <div className="w-max">
                  <TariffType />
                </div>
              )}
              {tariff.id === 4 && (
                <div className="w-max">
                  <MeasurementType />
                </div>
              )}

              {tariff.id !== 7 && tariff.id !== 2 && (
                <>
                  <div className="text-xl">{selectTariffLabel}</div>
                  <div>
                    <div className="self-end">
                      <div className="flex justify-between">
                        <Button onClick={() => handleAccordion()} variant="unstyled" className="w-full px-0">
                          <div className="flex items-center justify-between">
                            <span className="text-base">{oneTariffLabel}</span>
                            {openAccordion ? (
                              <div>
                                <Caret />
                              </div>
                            ) : (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <Caret />
                              </div>
                            )}
                          </div>
                        </Button>
                      </div>
                    </div>
                    {openAccordion && (
                      <div className="grid grid-cols-4 gap-10 w-full">
                        <Desktop>
                          {(fields.customerType === "0" && usageRuler) && (
                            <div className="w-full my-4 col-span-2">
                              <RangeSlider name="oneTariff" allowedValues={[1700, 2500, 3200, 4000]} />
                            </div>
                          )}
                          <div className="w-full col-span-2">
                            <Input
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={values.meterType === "3,4" ? consumptionLabel+ " " + HTLabel : consumptionLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                        </Desktop>
                        <Tablet>
                          {fields.customerType === "0" && (
                            <div className="w-full my-4 col-span-2">
                              <RangeSlider name="oneTariff" allowedValues={[1700, 2500, 3200, 4000]} />
                            </div>
                          )}
                          <div className="w-full col-span-2">
                            <Input
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={values.meterType === "3,4" ? consumptionLabel+ " " + HTLabel : consumptionLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                        </Tablet>
                        <Mobile>
                          {fields.customerType === "0" && (
                            <div className="w-full my-4 col-span-4">
                              <RangeSlider name="oneTariff" allowedValues={[1700, 2500, 3200, 4000]} />
                            </div>
                          )}
                          <div className="w-full col-span-4 mb-4">
                            <Input
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={values.meterType === "3,4" ? consumptionLabel+ " "+ HTLabel : consumptionLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                        </Mobile>
                      </div>
                    )}
                  </div>
                  <div className="bg-background-orange rounded-xl p-2 -m-2">
                    <div className="self-end">
                      <div className="flex justify-between">
                        <Button onClick={() => handleAccordion()} variant="unstyled" className="w-full px-0">
                          <div className="flex items-center justify-between">
                            <span className="text-base">{twoTariffsLabel}</span>
                            {!openAccordion ? (
                              <div>
                                <Caret />
                              </div>
                            ) : (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <Caret />
                              </div>
                            )}
                          </div>
                        </Button>
                      </div>
                    </div>
                    {!openAccordion && (
                      <div className="grid grid-cols-4 gap-10 w-full">
                        <Mobile>
                          <div className="w-full col-span-4">
                            <Input
                              bgColor="bg-background-orange"
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={consumptionLabel + " " + HTLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                          <div className="w-full col-span-4">
                            <Input
                              bgColor="bg-background-orange"
                              name="twoTariff"
                              label={consumptionLabel + " " + NTLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                        </Mobile>
                        <Desktop>
                          <div className="w-full col-span-2">
                            <Input
                              bgColor="bg-background-orange"
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={consumptionLabel + " " + HTLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                          <div className="w-full col-span-2">
                            <Input
                              bgColor="bg-background-orange"
                              name="twoTariff"
                              label={consumptionLabel + " " + NTLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                        </Desktop>
                        <Tablet>
                          <div className="w-full col-span-2">
                            <Input
                              bgColor="bg-background-orange"
                              name="oneTariff"
                              placeholder="Verbrauch (HT)"
                              label={consumptionLabel + " " + HTLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                          <div className="w-full col-span-2">
                            <Input
                              bgColor="bg-background-orange"
                              name="twoTariff"
                              label={consumptionLabel + " " + NTLabel}
                              onlyNumber
                              maxLength={10}
                              usage={usageLabel}
                            />
                          </div>
                        </Tablet>
                      </div>
                    )}
                  </div>
                </>
              )}
              {tariff.id == 2 && (
                <div className="md:w-1/2">
                  <Input
                    name="oneTariff"
                    placeholder="Verbrauch (HT)"
                    label={values.meterType === "3,4" ? consumptionLabel + HTLabel : consumptionLabel}
                    onlyNumber
                    maxLength={10}
                    usage={usageLabel}
                  />
                </div>
              )}
            </div>
          </FormControl>

          <div className="flex justify-center	w-full">
            {isSubmitting ? (
              <LoadingOverlay text="Bitte warten..." />
            ) : (
              <>
                <Button disabled={!disabled} variant="primary" type="submit" className="p-2 mt-6 px-9">
                  {searchLabel || buttonLabel}
                </Button>
              </>
            )}
          </div>
        </form>
      ) : null}
    </div>
  );
};

export default TariffInputForm;
