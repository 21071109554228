import { useField } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const RelocationForm = () => {
  const { dateMoveInLabel, reasonToChangeMoveExistingCustomer } = useFeaturesCC();
  const [{ value }] = useField("isNewCustomer");

  return (
    <section className="mt-6">
      <FormControl>
        <div className="font-bold text-lg">Umzug</div>
        {reasonToChangeMoveExistingCustomer && (
          <>
            <div className="my-4">
              <Checkbox name="isNewCustomer" label="Ich bin Neukunde" />
            </div>

            {!value && (
              <div className="my-4 grid sm:grid-cols-2 grid-cols-1 gap-4">
                <Input className="w-full" name="newKundennummer" label="Kundennummer *" inputType="number" />
              </div>
            )}
          </>
        )}

          <div className="my-4 grid sm:grid-cols-2 grid-cols-1 gap-4">
            <label>
              <span className="text-xs">{dateMoveInLabel}</span>
              <Input name="moveInDate" className="w-full" type="datepicker" />
            </label>
          </div>
      </FormControl>
    </section>
  );
};

export default RelocationForm;
