import { useField, useFormikContext } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import useCheckIBAN from "../../hooks/useCheckIBAN";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const BankDataForm = () => {
  const [{ value }] = useField("paymentOptions");
  const [{ value: iban }] = useField("iban");
  const [{ value: isDifferentAccountHolder }] = useField("isDifferentAccountHolder");
  const [{ value: firstName }] = useField("firstName");
  const [{ value: lastName }] = useField("lastName");
  const { setFieldValue } = useFormikContext();
  const {
    deviateAccountHolderData,
    agreeSepaText,
    agreeSepaCheckboxLabel,
    firstNameLabel,
    lastNameLabel,
    ibanLabel,
    bicLabel,
    creditInstitutionLabel,
  } = useFeaturesCC();

  useCheckIBAN();

  const isGermanBankAccount = React.useMemo(() => !!iban.startsWith("DE"), [iban]);

  React.useEffect(() => {
    if (isDifferentAccountHolder === "true") {
      setFieldValue("firstNameAccountHolder", "");
      setFieldValue("lastNameAccountHolder", "");
    } else {
      setFieldValue("firstNameAccountHolder", firstName);
      setFieldValue("lastNameAccountHolder", lastName);
    }
  }, [firstName, isDifferentAccountHolder, lastName, setFieldValue]);

  React.useEffect(() => {
    if (iban.length !== 22 && iban.startsWith("DE")) {
      setFieldValue("bankName", "");
      setFieldValue("bic", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iban.length]);

  if (value === "bankTransfer") return null;

  return (
    <FormControl className="flex flex-col gap-5 mb-4">
      <div className="font-bold text-lg mt-6">Bankverbindungen</div>
      <Checkbox label={deviateAccountHolderData.options[1].label} name="isDifferentAccountHolder" />
      {isDifferentAccountHolder && 
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
          <Input
            name="firstNameAccountHolder"
            placeholder="Vorname"
            label={firstNameLabel}
            inputType="name"
            />
          <Input
            name="lastNameAccountHolder"
            placeholder="Nachname"
            label={lastNameLabel}
            inputType="name"
            />
        </div>
      }

      <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
        <Input name="iban" placeholder="IBAN" inputType="iban" label={ibanLabel} />
        <Input name="bic" inputType="BIC" label={bicLabel} placeholder="SWIFT-Code (BIC)" disabled={!iban || isGermanBankAccount} />
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
        <Input
          name="bankName"
          label={creditInstitutionLabel}
          placeholder="Kreditinstitute"
          disabled={!iban || isGermanBankAccount}
        />
      </div>

      <Checkbox name="isAgreeToSEPA" label={agreeSepaCheckboxLabel} />
      <div>{agreeSepaText}</div>
    </FormControl>
  );
};
export default BankDataForm;
