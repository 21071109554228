import { useFormikContext } from "formik";
import qs from "query-string";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { Option } from "../../../../components/GetSelect/types";
import toast from "../../../../components/Toast";
import { useTariff } from "../../../../context";
import { useConfig } from "../../../../context/Config";
import { useGridConnectionStatus } from "../../../../context/GridConnectionStatus";
import ProxyInstance from "../../../../services/proxy";
import Tariff from "../../../../types/Tariff";
import resolveSuggestion from "../../../../utils/resolveSuggestion";
import uniqueArrObj from "../../../../utils/uniqueArrObj";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import handleError from "../../../TCInputs/utils/handleError";
import { InputFormModalFormik } from "../../types/InputFormModalFormik";
import useFeaturesResult from "../useFeaturesResult";
import useQueryParametersResult from "../useQueryParametrs";

interface ResponseData {
  citySuggestions: Option[];
  streetSuggestions: string[];
  houseNumberSuggestions: string[] | null;
  unique: boolean;
  status: number;
  tariffList: Tariff[] | null;
  networkOperator: string;
  baseSupplier: string;
}

const useSimplifierResultPage = (values: InputFormModalFormik, name = "") => {
  const [tariffList, setTariffList] = useState(null);
  const [data, setData] = useState<ResponseData>({
    citySuggestions: [],
    streetSuggestions: [],
    houseNumberSuggestions: null,
    unique: false,
    status: 0,
    tariffList: [],
    networkOperator: "",
    baseSupplier: "",
  });
  const { tariffNotFound } = useFeatureInputPage();
  const { config } = useConfig();
  const { setFieldValue } = useFormikContext();
  const { setGridConnectionStatus } = useGridConnectionStatus();
  const tariff = useTariff();
  const { paging } = useFeaturesResult();

  useEffect(() => {
    if (values.zipCode?.length !== 5) {
      setData({
        citySuggestions: [],
        streetSuggestions: [],
        houseNumberSuggestions: null,
        unique: false,
        status: 0,
        tariffList: [],
        baseSupplier: "",
        networkOperator: "",
      });

      setGridConnectionStatus(0);
    }
  }, [setGridConnectionStatus, values.zipCode]);

  useEffect(() => {
    if (values)
      setData((prevState) => ({
        ...prevState,
        status: 0,
      }));
  }, [values]);

  useEffect(() => {
    if (values.meterType === "1,2") {
      setFieldValue("twoTariff", "");
    }
  }, [setFieldValue, values.meterType]);

  const urlParameterAll = useQueryParametersResult();
  const urlCity = qs.stringify({
    calculatorType: tariff.id,
    customerType: values.customerType,
    zipCode: values.zipCode,
    primeTimeConsumption: tariff.id === "7" ? 20000 : parseInt(values.oneTariff?.replace(".", "")),
    ...(values.meterType === "3,4" ? { secondaryTimeConsumption: values.twoTariff?.replace(".", "") } : {}),
    page: 1,
    gridConnectionType: 0,
    pageSize: paging ?? 20,
  });
  const urlStreet =
    urlCity +
    "&" +
    qs.stringify({
      ...{ city: typeof values.city !== "string" ? values.city?.value : values.city },
    });
  const urlHNR =
    urlStreet +
    "&" +
    qs.stringify({
      ...{ street: values.street?.value },
    });

  const baseUrl = useMemo(() => {
    let webserviceUrl = "/simplifier/calc/" + tariff.tariffType;
    if (tariff.id === "7") {
      webserviceUrl = "/prima/calculation/telco/tr/v1/calculate";
    }
    if (name === "city" && typeof values.city !== "string") {
      return `${webserviceUrl}/?${urlCity}`;
    }
    if (name === "street" && typeof values.street !== "string") {
      return `${webserviceUrl}/?${urlStreet}`;
    }
    if (name === "houseNumber" && typeof values.street !== "string") {
      return `${webserviceUrl}/?${urlHNR}`;
    }
    return `${webserviceUrl}/?${urlParameterAll}`;
  }, [urlParameterAll, tariff.tariffType, name, values.city, values.street, urlCity, urlStreet, urlHNR]);

  const params = name === "city" ? [] : name === "street" ? [values?.city] : [values?.city, values?.street];
  const query = useQuery(
    [
      `GET_SIMPLIFIER_RESULT_${tariff.id}_${name}`,
      values?.meterType,
      values?.zipCode,
      values?.houseNumber,
      values?.oneTariff,
      values?.twoTariff,
      values?.customerType,
      values?.stichtag,
      ...params,
    ],
    async () => {
      try {
        const { data } = await ProxyInstance(baseUrl, {
          apikey: config.apikey || "",
        });
        if (data?.error) {
          throw new Error("Bitte füllen Sie die Felder aus");
        }
        const response = data?.data?.data;

        if (response.suggestLocationType === "CITY") {
          const resolveSuggestLocation = resolveSuggestion(response.suggestLokation);
          response.suggestLokation.length > 1
            ? setData((prevState) => ({
                ...prevState,
                citySuggestions: uniqueArrObj([
                  ...resolveSuggestLocation,
                  ...response.suggestLokation.map((cityName: string) => ({
                    value: cityName,
                    label: cityName,
                  })),
                ]),
              }))
            : setData((prevState) => ({
                ...prevState,
                citySuggestions: response.suggestLokation
                  .map((cityName: string) => ({
                    value: cityName,
                    label: cityName,
                  }))
                  .sort((a: string, b: string) => a.localeCompare(b)),
              }));
        }
        if (name === "street") {
          setData((prevState) => ({
            ...prevState,
            streetSuggestions:
              response.suggestLocationType === "STREET"
                ? response.suggestLokation.sort((a: string, b: string) => a.localeCompare(b))
                : [],
          }));
        }
        if (name === "houseNumber") {
          setData((prevState) => ({
            ...prevState,
            houseNumberSuggestions: response.suggestLocationType === "HNR" ? response.suggestLokation : null,
          }));
        }

        if (!response?.suggestLocationType) {
          setData((prevState) => ({
            ...prevState,
            status: 1,
          }));

          setGridConnectionStatus(response?.gridConnectionStatus);
        }

        if (response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis) {
          setTariffList(response?.calculationResult.ergebnisContainer[0].ergebnis);
        }

        setData((prevState) => ({
          ...prevState,
          networkOperator: response?.calculationParameter?.netzbetreiber,
          baseSupplier: response?.calculationParameter?.grundversorger,
          unique: response.unique,
        }));

        return response;
      } catch (e) {
        if (e instanceof Error) {
          e.message === "Error getting data" ? toast.error(tariffNotFound) : null;
          setData((prevState) => ({
            ...prevState,
            citySuggestions: [],
          }));
          throw e;
        }
      }
    },
    {
      enabled: !!config.apikey && values?.zipCode?.length === 5,
      refetchOnWindowFocus: false,
      retry: false,
      onError: handleError,
    }
  );

  return {
    ...query,
    data: {
      citySuggestions: data.citySuggestions,
      streetSuggestions: data.streetSuggestions,
      houseNumberSuggestions: data.houseNumberSuggestions,
      unique: data.unique,
      status: data.status,
      tariffList,
      networkOperator: data.networkOperator,
      baseSupplier: data.baseSupplier,
    },
  };
};
export default useSimplifierResultPage;
