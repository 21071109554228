import { useField, useFormikContext } from "formik";
import React from "react";

import Download from "../../../../../components/icons/download";
import { useParameter } from "../../../../../context";
import { useCalcParams } from "../../../../../context/CalculationParameter";
import useGeneralLabel from "../../../../../hooks/useGeneralLabel";
import { FormValues } from "../../../../../types/FormValues";
import useCreateOffer from "../../../hooks/useCreateOffer";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";

const PrePrint = () => {
  const { calcParams } = useCalcParams();
  const { mutateAsync } = useCreateOffer("summaryForm");
  const { values } = useFormikContext<FormValues>();
  const { prePrintLabelBtn, prePrintInfoText, prePrint, contractConclusion } = useFeaturesCC();
  const { vppSummaryUrl } = useParameter();
  const { mandatoryLabel } = useGeneralLabel();
  const [field] = useField("asOffer");

  const handleDownloadSummary = React.useCallback(async () => {
    if (values.pdfUrl && !vppSummaryUrl) {
      if (typeof window !== "undefined") {
        window.open(values.pdfUrl, "_blank");
      }
    } else {
      try {
        await mutateAsync(calcParams || {});
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }, [calcParams, mutateAsync, values.pdfUrl, vppSummaryUrl]);

  return (
    <div>
      <Headline>{contractConclusion}</Headline>
      <div className="text-gray-dark text-sm">{prePrintInfoText}</div>
      <div className="flex mt-4 cursor-pointer" onClick={handleDownloadSummary}>
        <Download />
        <span className="ml-2 text-base underline">
          {prePrintLabelBtn}
          {prePrint && !field.value ? mandatoryLabel : null}
        </span>
      </div>
    </div>
  );
};

export default PrePrint;
