import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const gridConnectionStatusContext = createContext<{
  gridConnectionStatus: number;
  setGridConnectionStatus: React.Dispatch<React.SetStateAction<number>>;
}>({ gridConnectionStatus: 0, setGridConnectionStatus: () => {} });

const GridConnectionStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [gridConnectionStatus, setGridConnectionStatus] = React.useState(0);

  return (
    <gridConnectionStatusContext.Provider value={{ gridConnectionStatus, setGridConnectionStatus }}>
      {children}
    </gridConnectionStatusContext.Provider>
  );
};

export default GridConnectionStatusProvider;
export const useGridConnectionStatus = () => useContextSelector(gridConnectionStatusContext, (c) => c);
