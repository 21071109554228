import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

export interface calcParams {
  dynamischeAngaben: [];
  plz: string;
  ort: string;
  grundversorger: string;
  netzbetreiber: string;
  netzgebiet: string;
  verbrauch: number;
  verbrauchHT: number;
  verbrauchNT: number | null;
  kundenart: number;
  tariftyp: string;
  limit: number;
  grenzenBeiATignorierenBis: number;
  tarifeVonGrundversorgern: number;
  grundversorgungstarife: number;
  handlingTarife: 1;
  vergleichsTarifID?: string | null;
  vergleichsVariantenID: string | null;
  vergleichsRichtlinie: number;
  vergleichsVersorgerID: string | null;
  stichtag: string | null;
  oeko: number;
  fixpreis: number;
  vorkasse: number;
  paket: number;
  portalRichtlinie: number;
  bonus: number;
  sachbonus: null;
  preisgarantie: number;
  kaution: number;
  sonderabschlag: number;
  smartmeter: number;
  vertragslaufzeitBis: number;
  vertragsverlaengerung: string | null;
  kuendigungsfrist: number;
  bestandskundenTarif: number;
  preisgarantieDauer: number;
  versorgerIDs: string;
  zertifikate: string;
  seitenGroesse: number;
  seitenNr: 1;
  lastprofile: string;
  markenIDs: string;
  tarifcode: string;
  tarifRootIDsMindestfilter: string;
  tarifRootIDs: string;
  zaehlerart: string;
  vertriebskanalIDs: string;
  uid: string;
  regionalVersorgerUmkreis: unknown | null;
  kennzahl: number;
  details: 1; //2
  baiextendedBlackList: string;
  baiextendedWhiteList: string;
}

interface calcParamsContext {
  calcParams: calcParams;
  setCalcParams: React.Dispatch<React.SetStateAction<calcParams>>;
}

const calculationParameter = createContext<calcParamsContext>({
  calcParams: {} as calcParams,
  setCalcParams: () => {},
});

const CalculationParameterProvider = ({ children }: { children: React.ReactNode }) => {
  const [calcParams, setCalcParams] = React.useState({} as calcParams);

  return (
    <calculationParameter.Provider value={{ calcParams, setCalcParams }}>{children}</calculationParameter.Provider>
  );
};

export default CalculationParameterProvider;
export const useCalcParams = () => useContextSelector(calculationParameter, (c) => c);
