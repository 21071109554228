import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";

import { useTariff } from "../../../../context";
import { useConfig } from "../../../../context/Config";
import ProxyInstance from "../../../../services/proxy";
import useQueryParametersResult from "../useQueryParametrs";

const useGetNextResultPage = () => {
  const tariff = useTariff();
  const { config } = useConfig();
  const queryParameter = useQueryParametersResult(false);
  const baseUrl = useMemo(() => {
    return tariff.id === "7"
      ? `/prima/calculation/telco/tr/v1/calculate?${queryParameter}`
      : `/simplifier/calc/${tariff.tariffType}/?${queryParameter}`;
  }, [queryParameter, tariff.id, tariff.tariffType]);

  return useInfiniteQuery(
    ["GET_SIMPILIFIER_NEXT_RESULT_PAGE"],
    async ({ pageParam = 2 }) => {
      try {
        const { data } = await ProxyInstance(`${baseUrl}&page=${pageParam}`, {
          apikey: config.apikey,
        });
        return data;
      } catch (e) {
        console.error(e);
      }
    },
    {
      enabled: false,
      getNextPageParam: (el) => {
        return el?.data?.data?.calculationParameter?.seitenNr + 1;
      },
    }
  );
};

export default useGetNextResultPage;
