import { useField } from "formik";
import React from "react";

import ErrorMessage from "../ErrorMessage";

const TextArea = ({ name, label }) => {
  const [fields, meta] = useField({ name });

  function inputTypeHandle(e) {
    if (name === "message") {
      const reg = /[<>=/*;]/g;
      e.target.value = e.target.value.replace(reg, "");
    }
  }

  return (
    <>
      <div className="flex relative w-full">
        <label
          htmlFor="message"
          className=" absolute left-2 top-0.5 text-borderInput text-xs transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-normal peer-placeholder-shown:top-2.5 peer-focus:top-0.5 peer-focus:text-borderInput peer-focus:text-xs "
        >
          {label}
        </label>
        <textarea
          {...fields}
          style={{ height: "200px" }}
          id="message"
          onInput={(e) => inputTypeHandle(e)}
          placeholder="Write"
          className="appearance-none resize-y border border-solid border-borderInput disabled:text-text_light focus:outline-none placeholder-transparent h-8 text-base w-full block rounded px-2.5 pb-0.5 pt-4 w-full text-gray-900 bg-gray-50 peer"
        ></textarea>
      </div>
      {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
    </>
  );
};

export default TextArea;
