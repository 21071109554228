import { AnimatePresence, motion } from "framer-motion";
import React from "react";

const CardExtend = ({ extendOpen, children }) => {
  return (
    <AnimatePresence>
      {extendOpen ? (
        <motion.div
          initial={{ opacity: 0, y: "-10%" }}
          animate={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
            },
          }}
          exit={{
            opacity: 0,
            y: "-10%",
            transition: {
              duration: 0.5,
            },
          }}
        >
          {children}
        </motion.div>
      ) : (
        ""
      )}
    </AnimatePresence>
  );
};

export default CardExtend;
