import { useFormikContext } from "formik";
import qs from "query-string";
import { useMemo } from "react";

import { useTariff } from "../../../../context";

const useQueryParametersResult = () => {
  const { values } = useFormikContext();
  const tariff = useTariff();

  const isPrimeTimeConsumptionNT = useMemo(() => {
    if (
      values.meterType === "3,4" &&
      (values.zipCode ||
        (values.zipCode && values.city) ||
        (values.zipCode && values.city && values.street) ||
        (values.zipCode && values.city && values.street && values.houseNumber))
    ) {
      return undefined;
    }
  }, [values.city, values.houseNumber, values.meterType, values.street, values.zipCode]);

  return useMemo(() => {
    return qs.stringify({
      calculatorType: tariff.id,
      customerType: values.customerType,
      zipCode: values.zipCode,
      ...(values.city ? { city: typeof values.city !== "string" && values.city.value } : {}),
      ...(values.street ? { street: typeof values.street !== "string" && values.street.value } : {}),
      ...(values.houseNumber
        ? {
            hnr: (typeof values.houseNumber !== "string" && values.houseNumber.value) || values.houseNumber,
          }
        : {}),
      primeTimeConsumption: tariff.id === 7 ? 20000 || 1 : parseInt(values.oneTariff.replace(".", "")),
      ...(values.twoTariff !== "0" ? { secondaryTimeConsumption: values.twoTariff?.replace(".", "") } : {}),
      page: 1,
      gridConnectionType: 0,
      pageSize: 20,
      primeTimeConsumptionNT: isPrimeTimeConsumptionNT,
    });
  }, [
    isPrimeTimeConsumptionNT,
    tariff.id,
    values.city,
    values.customerType,
    values.houseNumber,
    values.oneTariff,
    values.street,
    values.twoTariff,
    values.zipCode,
  ]);
};

export default useQueryParametersResult;
