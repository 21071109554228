import * as React from "react";

import { useConfig } from "../../../context/Config";

const useRadioClassName = () => {
  const { config } = useConfig();

  return React.useMemo(() => {
    if (config.media === "tablet") return "gap-4 flex items-center";

    if (config.media === "mobile") {
      return "gap-4 flex items-center";
    }

    return "gap-4 flex items-center";
  }, [config.media]);
};

export default useRadioClassName;
