import { formatISO } from "date-fns";
import Cookies from "js-cookie";
import { useMemo } from "react";
import { useMutation } from "react-query";

import toast from "../components/Toast";
import { useParameter, useTariff } from "../context";
import { useCalcParams } from "../context/CalculationParameter";
import { useConfig } from "../context/Config";
import { useTRInputContext } from "../context/FormInputData";
import { useSupplierInfo } from "../context/SupplierInfo";
import { useSelectTariff } from "../context/Tariff";
import { useSelectTelco } from "../context/Telco";
import { useSelectTelcoGridConnection } from "../context/TelcoGridConnection";
import { Abschlussdatenobjekt } from "../types/Abschlussdatenobjekt";
import { FormValues } from "../types/FormValues";
import useGetKeyCloakToken from "../widgets/TCContractConclusion/hooks/useGetKeyCloakToken";
import { HandleTariffFormsReturn } from "../widgets/TCContractConclusion/utils/handleTariffForms";
import { HandleTelcoFormsReturn } from "../widgets/TCContractConclusion/utils/handleTelcoForms";
import sendData from "../widgets/TCContractConclusion/utils/sendData";
import sendMail from "../widgets/TCContractConclusion/utils/sendMail";
import vppMappings from "../widgets/TCContractConclusion/utils/vppMappings";
import { PreisgarantieBis } from "../widgets/TCInputs/types/SimplifierResponse";
import useFeaturesResult from "../widgets/TCResults/hooks/useFeaturesResult";

const inFiveMinutes = new Date(new Date().getTime() + 5 * 60 * 1000);

const useSubmitFormData = () => {
  const { config } = useConfig();
  const tariff = useTariff();
  const { calcParams } = useCalcParams();
  const inputContext = useTRInputContext();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const { supplierInformation } = useSupplierInfo();
  const { selectedTelcoGridConnection } = useSelectTelcoGridConnection();
  const { authToken, vppSummaryUrl } = useParameter();
  const { showNetGross, pricesNetGross } = useFeaturesResult();
  const { hideComparisonSelect } = useFeaturesResult();
  let isTemplatePathUsed = false;
  let pdfUrl = "";

  const totalUsage = useMemo(
    () =>
      inputContext?.values?.meterType === "3,4"
        ? Number(inputContext?.values?.oneTariff?.replaceAll(".", "")) +
          Number(inputContext?.values?.twoTariff?.replaceAll(".", ""))
        : Number(inputContext?.values?.oneTariff?.replaceAll(".", "")),
    [inputContext?.values?.oneTariff, inputContext?.values?.twoTariff, inputContext?.values?.meterType]
  );

  const nettoOrBrutto = useMemo(() => {
    if (showNetGross) {
      return Number(selectedTariff?.priceType === "0");
    }
    if (pricesNetGross === "net") {
      return 0;
    }
    if (pricesNetGross === "gross") {
      return 1;
    }
    return Number(inputContext?.values?.customerType === "0");
  }, [selectedTariff?.priceType, inputContext?.values?.customerType, pricesNetGross, showNetGross]);

  return useMutation(
    "SUBMIT_FORM_DATA",
    async ({
      formikValues,
      formattedData,
    }: {
      formikValues: FormValues;
      formattedData: HandleTariffFormsReturn | HandleTelcoFormsReturn;
    }) => {
      // create form data
      let formData: Abschlussdatenobjekt = {} as Abschlussdatenobjekt;
      const aktAuthToken: string | undefined = await useGetKeyCloakToken(authToken, config.apikey, vppSummaryUrl);
      if (selectedTariff) {
        let tempInfoBAI = [];
        selectedTariff.informationen.forEach((i) => {
          const temp = {
            ...i,
            preisgarantieBis: formatDatePreisgarantieBis(i.preisgarantieBis) as string,
          };
          tempInfoBAI.push(temp);
        });
        // binding offer true
        if (formattedData.verbindlicher_abschluss) {
          formData = {
            portalDaten: {
              ...formattedData,

              verbindlicher_abschluss: true,
              abschlussstatus: formikValues?.isRemoteSignature ? "telesales" : "abschluss",
              digitalSignature: formikValues?.isRemoteSignature ? null : formikValues.signature || null,
            },
            tarifErgebnis: { ...selectedTariff, informationen: tempInfoBAI },
            tarifParameter: { ...calcParams, netzbetreiberName: supplierInformation?.netzbetreibername },
            vergleichsErgebnis: !hideComparisonSelect ? supplierInformation.suppliertariff : {},
            vertriebspartnerportal:
              selectedTariff &&
              vppMappings({
                value: {
                  ...selectedTariff,
                  tariftyp: tariff.tariftyp || "",
                  verbrauchGesamt: totalUsage,
                  anzeigeNettoBrutto: nettoOrBrutto,
                  asOffer: formikValues.asOffer,
                  idPerson: formikValues?.idPerson,
                  measurementType: inputContext.values.measurementType,

                  // auftragsstatus: undefined,
                },
              }),
          };
        } else {
          formData = {
            portalDaten: {
              ...formattedData,
              verbindlicher_abschluss: false,
              abschlussstatus: "",
            },
            tarifErgebnis: selectedTariff,
            tarifParameter: { ...calcParams, netzbetreiberName: supplierInformation?.netzbetreibername },
            vertriebspartnerportal:
              selectedTariff &&
              vppMappings({
                value: {
                  ...selectedTariff,
                  tariftyp: tariff.tariftyp || "",
                  verbrauchGesamt: totalUsage,
                  anzeigeNettoBrutto: nettoOrBrutto,
                  asOffer: formikValues.asOffer,
                  idPerson: formikValues?.idPerson,
                  measurementType: inputContext.values.measurementType,

                  // auftragsstatus: undefined,
                },
              }),
          };
        }
      }

      if (selectedTelco && tariff.id === "7") {
        if (formattedData.verbindlicher_abschluss) {
          formData = {
            portalDaten: {
              ...formattedData,
              verbindlicher_abschluss: true,
              abschlussstatus: formikValues?.isRemoteSignature ? "telesales" : "abschluss",
              digitalSignature: formikValues?.isRemoteSignature ? null : formikValues.signature || null,
            },
            tarifErgebnis: selectedTelco,
            telcoGridConnection: selectedTelcoGridConnection,

            tarifParameter: {},
            vertriebspartnerportal: {
              bestelldatumZeit: formikValues.asOffer ? "" : formatISO(new Date()),
              VParbeitspreisCtKWhNetto: 0,
              VParbeitspreisCtKWhBrutto: 0,
              VParbeitspreisHTCtKWhNetto: 0,
              VParbeitspreisHTCtKWhBrutto: 0,
              VPgrundpreisNetto: 0,
              VPgrundpreisBrutto: 0,
              VPgrundpreisMonatNetto: 0,
              VPgrundpreisMonatBrutto: 0,
              VPgesamtpreisOhneBoniNetto: 0,
              VPgesamtpreisOhneBoniBrutto: 0,
              VPgesamtpreisNetto: 0,
              VPgesamtpreisBrutto: 0,
              VPgesamtpreisMonatNetto: 0,
              VPgesamtpreisMonatBrutto: 0,
              anzeigeNettoBrutto: 1,
              // auftragsstatus: 11,

              lasttyp: "slp",
              messtyp: inputContext.values.measurementType === "0" ? "gemeinsam" : "getrennt",
              priceType: 0,
              verbrauchGesamt: 20000,
              id_person: formikValues?.idPerson,
            },
          };
        }
        // todo: telco in not binding offer
      }

      const data = [formData];
      const body = { jsonDaten: { abschlussDaten: data } };

      try {
        // binding offer true
        if (formattedData.verbindlicher_abschluss) {
          // send data
          const { data } = await sendData(tariff.id, await config.getAPIKey(), aktAuthToken, body);

          const response = data;

          // error
          if (response?.error) {
            toast.error(response?.error);
            //  throw new Error(response?.error?.message);
          } else {
            // generate pdf url
            if (response?.data?.presignedDownloadUrl) {
              pdfUrl = response.data.presignedDownloadUrl;
            }

            // CRM
            if (response?.data?.crm?.response?.abschluesse_OK) {
              // toast send success
              toast.success("Erfolgreich gesendet");

              // used template
              if (response?.additionalData?.usedTemplatePath) {
                isTemplatePathUsed = true;
              }

              // timestamp
              if (response?.timestamp) {
                Cookies.set("allowed", JSON.stringify(true), {
                  expires: inFiveMinutes,
                });
              }

              // send email to customer and distributor
              if (formattedData.la_email && formattedData.la_email.length > 0) {
                const { s3FilePathDistribution, s3FilePathCustomer } = response.data.crm.response;

                const isS3FilePathCustomerAvailable = response.data.crm.response.s3FilePathCustomer;

                const isS3FilePathDistribution = response.data.crm.response.s3FilePathDistribution;
                // customer mail
                let responseMailCustomer;
                if (isS3FilePathCustomerAvailable) {
                  try {
                    const { data } = await sendMail(
                      tariff.id,
                      await config.getAPIKey(),
                      authToken,
                      s3FilePathCustomer,
                      body,
                      "customer"
                    );

                    responseMailCustomer = data;
                  } catch (e) {
                    throw new Error("Error Sending Email to Customer");
                  }
                }

                // distributor customer
                let responseMailDistributor;
                if (isS3FilePathDistribution) {
                  try {
                    const { data } = await sendMail(
                      tariff.id,
                      await config.getAPIKey(),
                      authToken,
                      s3FilePathDistribution,
                      body,
                      "distributor"
                    );

                    responseMailDistributor = data;
                  } catch (e) {
                    throw new Error("Error Sending Email to Distributor");
                  }
                }

                if (responseMailCustomer && responseMailDistributor) {
                  toast.success("Mail erfolgreich gesendet");
                }
              }
            }

            // CIM
            if (response?.data?.cim?.abschluss_OK) {
              toast.success("Erfolgreich gesendet");

              if (response?.data?.cim?.id_templatedata && vppSummaryUrl) {
                window.open(`${vppSummaryUrl}${response?.data?.cim?.id_templatedata.toString()}`, "_self");
              }
            }
          }
        } else {
          console.error("This code was assumed unreachable and was removed as part of GETTR-1387.");
        }

        // data that required for confirmation page
        return {
          ...formData,
          isTemplatePathUsed,
          pdfUrl,
        };
      } catch (e) {
        console.error("Error send data to Proxy", e);
        throw e;
      }
    },
    {
      onError: (e) => {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        throw e;
      },
    }
  );
};
const formatDatePreisgarantieBis = (datum: PreisgarantieBis | null) => {
  let formatDate: string | PreisgarantieBis;
  let month: string | null = null;
  let day: string | null = null;

  if (datum) {
    if (datum?.dayOfMonth) {
      if (datum.dayOfMonth.toString().length < 2) {
        day = `0${datum.dayOfMonth}`;
      } else {
        day = `${datum.dayOfMonth}`;
      }
    }

    if (datum?.monthValue) {
      if (datum.monthValue.toString().length < 2) {
        month = `0${datum.monthValue}`;
      } else {
        month = `${datum.monthValue}`;
      }
    }

    const isNotValidFormat = `${datum?.year}-${month}-${day}`.includes("undefined");

    if (datum && isNotValidFormat) {
      formatDate = datum;
    } else {
      formatDate = `${datum?.year}-${month}-${day}`;
    }
  } else return "";

  return formatDate;
};
export default useSubmitFormData;
