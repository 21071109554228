import { useFormikContext } from "formik";
import * as React from "react";

import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import NotificationBox from "../../../../components/NotificationBox";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { useParameter, useTariff } from "../../../../context";
import usePrimaService from "../../../../hooks/usePrimaService";
import useVPPCustomerService from "../../../../hooks/useVPPCustomerService";
import { FormValues } from "../../../../types/FormValues";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useSimplifier from "../../hooks/useSimplifier";

const TRAddress = ({ children }: { children?: React.ReactNode }) => {
  const { data, isError, isLoading } = useSimplifier();
  const [hasAdditionalAddress, setHasAdditionalAddress] = React.useState({
    street: false,
    houseNumber: false,
  });
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const { plz, ort, strasse } = useParameter();
  const { data: vppData } = useVPPCustomerService();
  const { data: primaData } = usePrimaService();
  const tariff = useTariff();
  const { zipCode, city, street, houseNumber, tariffNotFound } = useFeatureInputPage();
  const [oldTariff, setOldTariff] = React.useState("1");
  const [cityOptions, setCityOptions] = React.useState(data?.cityOptions ?? []);
  const [streetOptions, setStreetOptions] = React.useState(data?.streetOptions ?? []);

  React.useEffect(() => {
    if (data?.cityOptions) {
      setCityOptions(data.cityOptions);
    }
  }, [data?.cityOptions]);

  React.useEffect(() => {
    if (data?.streetOptions !== undefined) {
      setStreetOptions(data.streetOptions);
    }
  }, [data?.streetOptions]);

  React.useEffect(() => {
    if (tariff.id !== oldTariff) {
      setHasAdditionalAddress({ street: false, houseNumber: false });
      setOldTariff(tariff.id);
    }
    if (data?.streetOptions) {
      if (JSON.stringify(data?.streetOptions) === JSON.stringify([])) {
        setHasAdditionalAddress((prevState) => {
          return {
            ...prevState,
            street: false,
          };
        });
      } else {
        setHasAdditionalAddress((prevState) => {
          return {
            ...prevState,
            street: true,
          };
        });
      }
    }

    if (data?.houseNumberOptions) {
      setHasAdditionalAddress({ street: true, houseNumber: true });
    }

    if (values.zipCode.length < 5) {
      setHasAdditionalAddress({ street: false, houseNumber: false });
    }
  }, [data, values.zipCode, oldTariff, tariff.id]);

  const disabledCity = React.useMemo(() => {
    return cityOptions?.length === 0;
  }, [cityOptions?.length]);

  const isCityDefaultOpen = React.useMemo(() => {
    // if (vppData) {
    //   return !!vppData?.data?.object?.person?.addresses.find(
    //     (val: { address_type: string }) => val?.address_type === "SHIPPING"
    //   )?.city;
    // } //TODO: check why this was here
    return !values.city.value && data?.cityOptions && data?.cityOptions?.length > 1;
  }, [data?.cityOptions, values.city]);

  const isStreetDefaultOpen = React.useMemo(() => {
    return values.city && !values.street && data?.streetOptions && data?.streetOptions?.length > 1;
  }, [data?.streetOptions, values.city, values.street]);

  const isHouseNumberDefaultOpen = React.useMemo(() => {
    return (
      values.city &&
      values.street &&
      !values.houseNumber &&
      data?.houseNumberOptions &&
      data?.houseNumberOptions?.length > 1
    );
  }, [data?.houseNumberOptions, values.city, values.houseNumber, values.street]);

  // NOTHING TO DO
  React.useEffect(() => {
    if (values.zipCode.length < 5) {
      setFieldValue("city", { value: "", label: "" });
      setFieldValue("street", { value: "", label: "" });
      setFieldValue("houseNumber", "");
    }
  }, [setFieldValue, values.zipCode.length]);

  React.useEffect(() => {
    if (vppData) {
      const shippingAddress = vppData?.data?.object?.person?.addresses?.find(
        (val: { address_type: string }) => val.address_type === "SHIPPING"
      );

      if (shippingAddress) {
        setFieldValue("zipCode", shippingAddress.zipcode);
        setFieldValue("city", {
          label: shippingAddress.city,
          value: shippingAddress.city,
        });
      }
    }
  }, [setFieldValue, vppData]);

  React.useEffect(() => {
    if (primaData && tariff.id === "7") {
      if (primaData.zipCode) {
        setFieldValue("zipCode", primaData.zipCode);
        setFieldValue("city", { label: primaData.city, value: primaData.city });
        setFieldValue("street", {
          label: primaData.street,
          value: primaData.street,
        });
        setFieldValue("houseNumber", {
          label: primaData.houseNumberComplete,
          value: primaData.houseNumberComplete,
        });
      }
    }

    if (plz) {
      setFieldValue("zipCode", `${plz}`);
    }
  }, [plz, primaData, setFieldValue, tariff.id]);

  React.useEffect(() => {
    if (ort) {
      setFieldValue("city", { label: ort, value: ort });
    }
  }, [ort, setFieldValue]);

  React.useEffect(() => {
    if (strasse) {
      setFieldValue("street", { label: strasse, value: strasse });
    }
  }, [strasse, setFieldValue]);

  return (
    <>
      <Desktop>
        <div className="grid grid-cols-4 gap-4">
          <div className="w-full">
            <Input label={zipCode} name="zipCode" onlyNumber maxLength={5} tabIndex={1} isLoading={isLoading} />
          </div>
          <div className="w-full col-span-3">
            <GetSelect
              label={city}
              name="city"
              isLoading={isLoading}
              disabled={disabledCity}
              defaultOpen={isCityDefaultOpen}
              options={cityOptions || []}
            />
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div className="grid grid-cols-4 gap-4">
          <div className="w-full">
            <Input label={zipCode} name="zipCode" onlyNumber maxLength={5} tabIndex={1} isLoading={isLoading} />
          </div>
          <div className="w-full col-span-3">
            <GetSelect
              label={city}
              name="city"
              isLoading={isLoading}
              disabled={disabledCity}
              defaultOpen={isCityDefaultOpen}
              options={cityOptions || []}
            />
          </div>
          {children && <div className="w-full col-span-3">{children}</div>}
        </div>
      </Tablet>

      <Mobile>
        <div className="grid grid-cols-2 gap-2">
          <div className="w-full col-span-2">
            <Input label={zipCode} name="zipCode" onlyNumber maxLength={5} tabIndex={1} isLoading={isLoading} />
          </div>
          <div className="w-full col-span-2">{children}</div>
          <div className="w-full col-span-2">
            <GetSelect
              label={city}
              name="city"
              isLoading={isLoading}
              disabled={disabledCity}
              defaultOpen={isCityDefaultOpen}
              options={cityOptions || []}
            />
          </div>
        </div>
      </Mobile>

      {hasAdditionalAddress.street && (
        <div className="grid grid-cols-10 gap-2 lg:gap-4">
          <div className="w-full col-span-7">
            <GetSelect
              label={street}
              name="street"
              defaultOpen={isStreetDefaultOpen ? false : isStreetDefaultOpen}
              disabled={streetOptions?.length === 0}
              isSearchable
              isLoading={isLoading}
              options={streetOptions || []}
            />
          </div>
          <div className="w-full col-span-3">
            {hasAdditionalAddress.houseNumber ? (
              <>
                {data?.houseNumberOptions /*&& data?.houseNumberOptions?.length > 1*/ && tariff.id === "7" ? (
                  <GetSelect
                    label={houseNumber}
                    name="houseNumber"
                    isSearchable
                    isLoading={isLoading}
                    defaultOpen={isHouseNumberDefaultOpen ? false : isHouseNumberDefaultOpen}
                    options={data?.houseNumberOptions}
                  />
                ) : (
                  <Input isLoading={isLoading} label={houseNumber} name="houseNumber" inputType="otherNumber" />
                )}
              </>
            ) : null}
          </div>
        </div>
      )}
      {isError && values.zipCode.length === 5 && <NotificationBox text={tariffNotFound} variant="info" />}
    </>
  );
};

export default TRAddress;
