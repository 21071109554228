import React from "react";

const RowItem = ({ label, value }) => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <div className="text-sm text-[--get-c-grey-middle]">{label}</div>
      <div className="text-right text-sm">{value}</div>
    </div>
  );
};

export default RowItem;
