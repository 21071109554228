import React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";

const SubscribeListForm = () => {
  return (
    <>
      <div className="font-medium text-lg">Eintrag Teilnehmerverzeichnis</div>

      <FormikRadio value="false" name="joinSubscribeList" label="Nein, ich wünsche keinen Eintrag" />

      <FormikRadio
        value="true"
        name="joinSubscribeList"
        label="Ja, ich wünsche einen Eintrag ins Teilnehmerverzeichnis"
      />
    </>
  );
};

export default SubscribeListForm;
