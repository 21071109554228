import Slider from "@mui/material/Slider";
import { useField } from "formik";
import React, { useEffect, useState } from "react";

import { useTariff } from "../../context";
import { BigHouse, MediumHouse, SmallHouse } from "./icons/House";
import { FourPersons, OnePerson, ThreePersons, TwoPersons } from "./icons/Persons";

const RangeSlider = ({ name, allowedValues = [1700, 2500, 3200, 4000] }: { name: string; allowedValues: number[] }) => {
  const [fields, , { setValue }] = useField({ name });
  const [sliderValue, setSliderValue] = useState(
    allowedValues.indexOf(Number.parseInt(fields?.value.toString().replace(".", "")))
  );
  const [, setLastCorrectValue] = useState(3200);
  const tariff = useTariff();

  useEffect(() => {
    if (allowedValues.includes(Number.parseInt(fields?.value.toString().replace(".", "")))) {
      setLastCorrectValue(Number.parseInt(fields?.value.toString().replace(".", "")));
      setSliderValue(allowedValues.indexOf(Number.parseInt(fields?.value.toString().replace(".", ""))));
    }
  }, [setSliderValue, fields, allowedValues]);

  const changeCallback = (val: number) => {
    setValue(allowedValues[val].toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."));
  };

  const decideColor = (val: number) => {
    if (Number.parseInt(fields?.value.toString().replace(".", "")) === val) {
      return "var(--get-c-primary)";
    }
    return "var(--get-c-content-bg)";
  };
  return (
    <div className="w-full inline-block">
      {tariff.id === "2" ? (
        <div className="grid grid-cols-4 text-center">
          <span className="cursor-pointer" onClick={() => changeCallback(0)}>
            <SmallHouse fill={decideColor(allowedValues[0])} />
          </span>
          <span className="cursor-pointer col-span-2" onClick={() => changeCallback(1)}>
            <MediumHouse fill={decideColor(allowedValues[1])} />
          </span>
          <span className="cursor-pointer" onClick={() => changeCallback(2)}>
            <BigHouse fill={decideColor(allowedValues[2])} />
          </span>
        </div>
      ) : (
        <div className="grid grid-cols-4 text-center">
          <span className="cursor-pointer" onClick={() => changeCallback(0)}>
            <OnePerson color={decideColor(allowedValues[0])} />
          </span>
          <span className="cursor-pointer" onClick={() => changeCallback(1)}>
            <TwoPersons color={decideColor(allowedValues[1])} />
          </span>
          <span className="cursor-pointer" onClick={() => changeCallback(2)}>
            <ThreePersons color={decideColor(allowedValues[2])} />
          </span>
          <span className="cursor-pointer" onClick={() => changeCallback(3)}>
            <FourPersons color={decideColor(allowedValues[3])} />
          </span>
        </div>
      )}
      <div className="mr-[14.3%] ml-[10.7%]">
        <Slider
          marks={allowedValues.map((_: number, index: number) => {
            return { value: index };
          })}
          min={0}
          max={allowedValues.length - 1}
          value={sliderValue}
          slotProps={{
            mark: {
              className: "absolute inline-block border-solid border-2 size-3 border-primary rounded-full",
            },
            track: { className: "absolute inline-block bg-secondary !w-full h-1 mt-1 " },
            thumb: { className: "absolute size-3 rounded-full block cursor-pointer bg-primary" },
            root: { className: "relative w-full inline-block -top-4 left-0" },
          }}
          onChange={(value) => changeCallback(Number((value?.target as HTMLInputElement).value))}
        />
      </div>
    </div>
  );
};
export default RangeSlider;
