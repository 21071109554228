import { Field } from "formik";
import { useField } from "formik";
import * as React from "react";

const FormikRadio = ({ label, ...props }) => {
  const svgChecked = (
    <>
      <svg focusable="false" viewBox="0 0 24 24" className="w-4 h-4 fill-[--get-c-primary-normal]">
        <path d="M12,22a10,10 0 0 1 -10,-10a10,10 0 0 1 10,-10a10,10 0 0 1 10,10a10,10 0 0 1 -10,10m0,-22a12,12 0 0 0 -12,12a12,12 0 0 0 12,12a12,12 0 0 0 12,-12a12,12 0 0 0 -12,-12" />
        <path d="M12,6a6,6 0 0 0 -6,6a6,6 0 0 0 6,6a6,6 0 0 0 6,-6a6,6 0 0 0 -6,-6" />
      </svg>
    </>
  );
  const svgUnchecked = (
    <>
      <svg focusable="false" viewBox="0 0 24 24" className="w-4 h-4 fill-[--get-c-primary-normal]">
        <path d="M12,22a10,10 0 0 1 -10,-10a10,10 0 0 1 10,-10a10,10 0 0 1 10,10a10,10 0 0 1 -10,10m0,-22a12,12 0 0 0 -12,12a12,12 0 0 0 12,12a12,12 0 0 0 12,-12a12,12 0 0 0 -12,-12" />
      </svg>
    </>
  );
  const [field] = useField(props);

  return (
    <label className="flex items-center">
      <div className="relative flex items-center">
        <Field type="radio" {...props} className={`${props.className ?? ""} opacity-0`} />
        <div className="absolute left-0">{field.value === props.value ? svgChecked : svgUnchecked}</div>
      </div>
      <div className="flex text-sm ml-3">{label}</div>
    </label>
  );
};

export default FormikRadio;
