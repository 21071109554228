import { useQuery } from "react-query";

import { useConfig } from "../context";
import { useCalcParams } from "../context/CalculationParameter";
import { useTRInputContext } from "../context/FormInputData";
import ProxyInstance from "../services/proxy";

const useTarifCart = (tarifRootID, variantenID) => {
  const config = useConfig();
  const { calcParams } = useCalcParams();
  const inputContext = useTRInputContext();

  return useQuery(
    `GET_EXTERNAL_LABELS_${tarifRootID}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/ws/tariff/energie/getTarifkarte?rootID=${tarifRootID}&plz=${
            inputContext?.values?.zipCode
          }&ort=${
            typeof inputContext.values?.city !== "string"
              ? inputContext.values?.city?.value
              : undefined
          }&variantenID=${variantenID}&netzbetreiber=${
            calcParams.netzbetreiber
          }&grundversorger=${calcParams.grundversorger}&ausgabeKonfiguration=1`,
          { apikey: config.apikey || "" }
        );
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: false,
    }
  );
};

export default useTarifCart;
