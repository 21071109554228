import React from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary
      fallback={
        <div className="p-5 border-solid border-1 border-attention">Fehler</div>
      }
    >
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
