import { useField } from "formik";
import React from "react";

import FormRow from "../../../../components/FormRow";
import GetSelect from "../../../../components/GetSelect";
import WarningIcon from "../../../../components/icons/warning";
import Input from "../../../../components/Input";
import Desktop from "../../../../components/View/Desktop";
import { SALUTATION_LIST, TITLE_LIST } from "../../../../Constant";
import { useConfig, useTariff } from "../../../../context";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSelectTelco } from "../../../../context/Telco";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useQueryCityByZipCode from "../../hooks/useQueryCityByZipCode";
import useQueryStreet from "../../hooks/useQueryStreet";
import useSetFieldWithInputData from "../../hooks/useSetFieldWithInputData";

const FormData = ({ type }) => {
  const inputContext = useTRInputContext();
  const { selectedTelco } = useSelectTelco();
  const config = useConfig();
  const tariff = useTariff();
  const [billingZipCode] = useField("billingZipCode");
  const [billingCity] = useField("billingCity");
  const {
    zipCodeLabel,
    zipCodeBillingLabel,
    cityLabel,
    cityBillingLabel,
    streetLabel,
    streetBillingLabel,
    additionLabel,
    additionBillingLabel,
    houseNumberLabel,
    houseNumberBillingLabel,
    firstNameLabel,
    lastNameLabel,
    salutationLabel,
    titleLabel,
    companyNameLabel,
    mailboxBillingLabel,
    delieveryAddressLabel,
    cityNotFoundLabel
  } = useFeaturesCC();
  const {
    zipCodeValidationLabel
  } = useFeatureInputPage();

  const { data: listStreet } = useQueryStreet(
    {
      plz: inputContext.values?.zipCode || "",
      ort: inputContext.values?.city.value || "",
    },
    [inputContext.values?.street.value || ""]
  );

  useSetFieldWithInputData();

  const { data: billingListStreet } = useQueryCityByZipCode(
    {
      zipCode: "billingZipCode",
      city: "billingCity",
      street: "billingStreet",
      houseNumber: "billingHouseNumber",
    },
    []
  );

  const { data: installationListStreet } = useQueryCityByZipCode(
    {
      zipCode: "installationZipCode",
      city: "installationCity",
      street: "installationStreet",
      houseNumber: "installationHouseNumber",
    },
    []
  );

  return (
    <>
      {type === "deliveryAddress" && (
        <>
          <div className="font-bold text-lg">
            {tariff.id === 7 ? "Installationsanschrift" : "Lieferanschrift"}
          </div>
          <FormRow addClassName="grid grid-cols-4 gap-4">
            <div className="w-full">
              <Input
                name="zipCode"
                label={zipCodeLabel}
                placeholder="PLZ"
                disabled
              />
            </div>
            <div className="col-span-3 w-full">
              <Input name="city" label={cityLabel} placeholder="Ort" disabled />
            </div>
          </FormRow>

          {(config.media === "mobile" || config.media === "tablet") && (
            <section className="my-4">
              <div className="w-full grid grid-cols-4 gap-4">
                <div className="col-span-3">
                  {inputContext.values?.street ? (
                    <Input
                    name="street"
                    label={streetLabel}
                    placeholder="Straße"
                    disabled
                    />
                    ) : (
                      <GetSelect
                      label={streetLabel}
                      name="street"
                      options={listStreet || []}
                      isSearchable
                      />
                      )}
                </div>
                <Input
                  name="houseNumber"
                  label={houseNumberLabel}
                  placeholder="Hausnummer"
                  disabled={!!inputContext.values?.houseNumber}
                  inputType="number"
                />
              </div>
              <div className="w-full my-4">
              </div>
              <div className="w-full">
                <Input
                  name="additionalAddress"
                  label={additionLabel}
                  placeholder="Adresszusatz"
                  disabled={tariff.id === 7}
                />
              </div>
            </section>
          )}

          <Desktop>
            <div className="my-4 grid sm:grid-cols-2 grid-cols-1 gap-4">
              <div className="w-full">
                {inputContext.values?.street ? (
                  <Input
                    name="street"
                    label={streetLabel}
                    placeholder="Straße"
                    disabled
                  />
                ) : (
                  <GetSelect
                    label={streetLabel}
                    name="street"
                    options={listStreet || []}
                    isSearchable
                  />
                )}
              </div>
              <div className="grid grid-cols-3 w-full gap-4">
                <div className="w-full">
                  <Input
                    name="houseNumber"
                    label={houseNumberLabel}
                    placeholder="Hausnummer"
                    disabled={!!inputContext.values?.houseNumber}
                    inputType="number"
                  />
                </div>
                <div className="w-full">
                  <Input
                    name="additionalAddress"
                    label={additionLabel}
                    placeholder="Adresszusatz"
                    disabled={tariff.id === 7}
                  />
                </div>
              </div>
            </div>
          </Desktop>

          {selectedTelco && (
            <Input
              name="installationLocation"
              label="Wohnungs-/Briefkastennummer, Installationsort-Stockwerk*"
              placeholder="Wohnungs-/Briefkastennummer, Installationsort-Stockwerk*"
              inputType="firma"
            />
          )}
        </>
      )}

      {type === "billingAddress" && (
        <>
          <div className="font-bold text-lg">Rechnungsadresse</div>
          <div className="grid grid-cols-3 gap-4 my-4">
            <Input name="billingCompanyName" label={companyNameLabel} placeholder="Firma" inputType="firma" />
            <GetSelect name="billingSalutation" label={salutationLabel} options={SALUTATION_LIST} />
            <GetSelect name="billingTitle" options={TITLE_LIST} label={titleLabel} />
          </div>

          <div className="grid grid-cols-2 gap-4 my-4">
            <Input
              name="billingFirstName"
              label={firstNameLabel}
              placeholder="Vorname"
              inputType="name"
            />
            <Input
              name="billingLastName"
              label={lastNameLabel}
              placeholder="Nachname"
              inputType="name"
            />
          </div>

          <FormRow addClassName="flex flex-col sm:flex-row gap-4">
            <div className="sm:w-1/4 w-full">
              <Input
                name="billingZipCode"
                label={zipCodeBillingLabel}
                placeholder="PLZ"
                onlyNumber
                maxLength={5}
              />
            </div>
            <div className="sm:w-3/4 w-full">
              <div className="col-span-2">
                <GetSelect
                  name="billingCity"
                  label={cityBillingLabel}
                  options={billingListStreet?.ortList || []}
                  defaultOpen={billingListStreet?.ortList.length >=1 && billingCity?.value===""}
                  placeHolder="Ort"
                />
              </div>
            </div>
          </FormRow>
          {(billingZipCode.value?.length === 5 && billingListStreet?.ortList.length===undefined) &&
            <div className="flex flex-row items-center mt-2 w-full">
              <WarningIcon className="mr-3" fill="red" />
              <small className="text-red-500">{cityNotFoundLabel}</small>
            </div>
           }
          {(billingZipCode.value?.length > 0 && billingZipCode.value?.length < 5) &&
            <div className="flex flex-row items-center mt-2 w-full">
              <WarningIcon className="mr-3" fill="red" />
              <small className="text-red-500">{zipCodeValidationLabel}</small>
            </div>
           }
          <div className="my-4 grid grid-cols-4 gap-4">
            <div className="col-span-3">
              <GetSelect
                name="billingStreet"
                label={streetBillingLabel}
                options={billingListStreet?.street || []}
                />
            </div>
            <Input
              name="billingHouseNumber"
              label={houseNumberBillingLabel}
              placeholder="Hausnummer"
              inputType="number"
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Input
                name="billingAdditionalAddress"
                label={additionBillingLabel}
                placeholder={additionBillingLabel}
                inputType="address"
              />
              <Input
                name="billingMailbox"
                label={mailboxBillingLabel}
                placeholder="Postfach"
                onlyNumber
                maxLength={5}
              />
          </div>
        </>
      )}

      {type === "installationAddress" && (
        <>
          <div className="font-bold text-lg">{delieveryAddressLabel}</div>
          <div className="grid grid-cols-2 gap-4 my-4">
            <Input
              name="installationCompanyName"
              label={companyNameLabel}
              placeholder="Firma"
              inputType="firma"
            />
          </div>
          <div className="grid-cols-2 grid gap-4 my-4">
            <GetSelect
              name="installationSalutation"
              label={salutationLabel}
              options={SALUTATION_LIST}
            />

            <GetSelect
              name="installationTitle"
              options={TITLE_LIST}
              label={titleLabel}
            />
          </div>
          <div className="grid-cols-2 grid gap-4 my-4">
            <Input
              name="installationFirstName"
              label={firstNameLabel}
              placeholder="Vorname"
              inputType="name"
            />

            <Input
              name="installationLastName"
              label={lastNameLabel}
              placeholder="Nachname"
              inputType="name"
            />
          </div>

          <div className="font-bold text-lg">Adresse</div>

          <FormRow addClassName="flex flex-col sm:flex-row gap-4">
            <div className=" sm:w-1/4 w-full">
              <Input
                name="installationZipCode"
                label={zipCodeLabel}
                placeholder="PLZ"
                onlyNumber
                maxLength={5}
              />
            </div>
            <div className=" sm:w-3/4 w-full">
              <GetSelect
                name="installationCity"
                label={cityLabel}
                placeHolder="Ort"
                options={installationListStreet?.ortList || []}
              />
            </div>
          </FormRow>
          <div className="my-4 grid sm:grid-cols-2 grid-cols-1 gap-4">
            <div className="w-full">
              <GetSelect
                name="installationStreet"
                label={streetLabel}
                options={installationListStreet?.street || []}
              />
            </div>
            <div className="flex w-full gap-4">
              <div className="sm:w-1/2 w-full">
                <Input
                  name="installationHouseNumber"
                  label={houseNumberLabel}
                  placeholder="Hausnummer"
                  inputType="number"
                />
              </div>
              <div className="sm:w-1/2 w-full">
                <Input
                  name="installationAdditionalAddress"
                  label={additionLabel}
                  placeholder={additionLabel}
                  onlyNumber
                  inputType="address"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FormData;
