import React from "react";

import useFormName from "../hooks/useFormName";
import { activatedPanelsContext, stepperContractConclusion } from "./index";

export const StepFormNameProvider = ({ children }) => {
  const [formName, setFormName] = useFormName();

  return (
    <stepperContractConclusion.Provider value={{ formName, setFormName }}>
      {children}
    </stepperContractConclusion.Provider>
  );
};

export const ActivatedPanelsProviders = ({ children }) => {
  const [activatedPanels, setActivatedPanels] = React.useState(["contactForm"]);

  return (
    <activatedPanelsContext.Provider
      value={{ activatedPanels, setActivatedPanels }}
    >
      {children}
    </activatedPanelsContext.Provider>
  );
};
