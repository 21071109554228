import React from "react";

import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import DesiredDeliveryDate from "./DesiredDeliveryDate";

const ExchangeNewTariffForm = () => {
  const { dateOfCancellationLabel, prevCustomerNumLabel } = useFeaturesCC();

  return (
    <section className="mt-6">
      <FormControl>
        <DesiredDeliveryDate />
        <div className="font-bold text-lg">Zählerdaten</div>
        <div className="grid grid-cols-3 gap-4 my-4">
          <Input name="cancellationDate" className="w-full" label={dateOfCancellationLabel} type="datepicker" />
          <Input name="customerNumber" className="w-full" label={prevCustomerNumLabel} inputType="number"/>
        </div>
      </FormControl>
    </section>
  );
};

export default ExchangeNewTariffForm;
