import { useQuery } from "react-query";

import { useTariff } from "../context";
import { useConfig } from "../context/Config";
import ProxyInstance from "../services/proxy";

const useExternalConfigs = (pageParam: string, versorgerID?: number) => {
  const { config } = useConfig();
  const tariff = useTariff();
  return useQuery(
    `GET_EXTERNAL_LABELS_${tariff.id}_${pageParam}_${versorgerID}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/config/view?calculatorType=${tariff.id}&page=${pageParam}${
            versorgerID ? "&supplierId=" + versorgerID : ""
          }`,
          { apikey: config.apikey || "" }
        );

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!config.apikey,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
};

export default useExternalConfigs;
