import { useMutation } from "react-query";

import { useTariff } from "../../../context";
import { calcParams } from "../../../context/CalculationParameter";
import { useConfig } from "../../../context/Config";
import { useSelectTariff } from "../../../context/Tariff";
import ProxyInstance from "../../../services/proxy";
import { FormValues } from "../../../types/FormValues";
import convertKeys from "../utils/convertKeys";
import useQueryParameters from "./useQueryParameters";

const useServerValidationForm = (
  formName: string,
  configValidate: {
    tarifParameter: calcParams;
    keysToValidate: string[];
  }
) => {
  const { selectedTariff } = useSelectTariff();
  const tariff = useTariff();
  const { config } = useConfig();
  const param = useQueryParameters({
    calculatorType: tariff.id,
    supplierId: selectedTariff?.versorgerID,
  });

  return useMutation(`VALIDATE_FORM_${formName}`, async (values: FormValues) => {
    const portalData = convertKeys(
      {
        ...values,
        calculatorType: tariff.id,
        supplierId: selectedTariff?.versorgerID,
      },
      formName
    );

    const body = {
      portalDaten: portalData,
      schluessel: configValidate.keysToValidate,
      tarifParameter: configValidate.tarifParameter,
    };

    try {
      const { data } = await ProxyInstance(`/crm/validate?${param}`, {
        apikey: config.apikey || "",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(body),
      });

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  });
};

export default useServerValidationForm;
