const handlePreSupplierValidation = (values, additionalCondition) => {
  if (additionalCondition?.isTelco) {
    if (values.hasDesiredDeliveryDate === "true") {
      return !!values.desiredDeliveryDate;
    }
    return true;
  }

  if (values.reasonToChange === "E01") {

    if (additionalCondition.reasonToChangeMoveExistingCustomer) {
      return (
        (values.isNewCustomer || values.newKundennummer) &&
        values.counterNumber &&
        values.moveInDate
      );
    }

    return (
      values.isNewCustomer &&
      values.counterNumber &&
      values.moveInDate
    );
  }

  if (values.reasonToChange === "tarifwechsel") {
    return (
      values.cancellationDate && values.counterNumber
    );
  }

  return (
    !!values.prevSupplierName &&
    !!values.counterNumber &&
    (values.hasCanceledPrevSupplier === "false" || values.cancellationDate)
  );
};

export default handlePreSupplierValidation;
