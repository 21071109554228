import * as React from "react";

import Input from "../../../../components/Input";

const ContactData = () => {
  return (
    <>
      <div className="font-bold text-xl my-4">Ihre Kontaktdaten</div>
      <div
        className="form-control"
        style={{
          gap: "30px",
          width: "100%",
        }}
      >
        <div className="md:flex gap-4 mb-4">
          <div className="w-full my-4 md:my-0">
            <Input name="Vorname" className="w-full" label="Vorname *" inputType="name"/>
          </div>
          <div className="w-full my-4 md:my-0">
            <Input name="Name" className="w-full" label="Name *" inputType="name"/>
          </div>

          <div className="w-full my-4 md:my-0">
            <Input
              type="datepicker"
              name="birthday"
              className="w-full"
              label="Geburtsdatum *"
            />
          </div>
        </div>
        <div className="md:flex gap-4 mb-4">
          <div className="w-full my-4 md:my-0">
            <Input label="Straße *" name="Strasse" className="w-full" />
          </div>
          <div className="md:w-[20%] min-w-[130px] w-full my-4 md:my-0">
            <Input label="Hausnummer *" name="Hausnummer" className="w-full" inputType="number"/>
          </div>
        </div>
        <div className="md:flex gap-4 mb-4">
          <div className="md:w-[20%] my-4 md:my-0">
            <Input label="PLZ *" name="PLZ" onlyNumber maxLength={5} className="w-full" />
          </div>
          <div className="w-full my-4 md:my-0">
            <Input label="Ort *" name="Ort" className="w-full"  inputType="address"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactData;
