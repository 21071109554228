import React from "react";

import Media from "../../../../../components/View/Media";
import useGeneralLabel from "../../../../../hooks/useGeneralLabel";
import formatCurrency from "../../../../../utils/formatCurrency";
import formatDecimal from "../../../../../utils/formatDecimal";
import { DynamischeAngaben } from "../../../../TCInputs/types/SimplifierResponse";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import RowItem from "../RowItem";

interface PriceOverviewAttributes {
  isBrutto: boolean;
  timeframeYear: boolean;
  gesamtpreisBrutto: number;
  gesamtpreisNetto: number;
  gesamtpreisMonatBrutto: number;
  gesamtpreisMonatNetto: number;
}

interface PriceDetailAttributes {
  isBrutto: boolean;
  timeframeYear: boolean;
  ersparnisBrutto: number;
  ersparnisNetto: number;
  grundpreisBrutto: number;
  grundpreisNetto: number;
  arbeitspreisHTCtKWhBrutto: number;
  arbeitspreisNTCtKWhBrutto: number;
  arbeitspreisCtKWhBrutto: number;
  arbeitspreisHTCtKWhNetto: number;
  arbeitspreisNTCtKWhNetto: number;
  arbeitspreisCtKWhNetto: number;
  grundpreisMonatBrutto: number;
  grundpreisMonatNetto: number;
  dynamischeAngaben: DynamischeAngaben[];
}

function PriceOverview(props: PriceOverviewAttributes) {
  const { yearlyInclBonuses, inclBonuses } = useFeaturesResult();
  const price = formatCurrency(
    props.timeframeYear
      ? props.isBrutto
        ? props.gesamtpreisBrutto
        : props.gesamtpreisNetto
      : props.isBrutto
        ? props.gesamtpreisMonatBrutto
        : props.gesamtpreisMonatNetto
  );

  return (
    <>
      <Media desktop={true}>
        <RowItem label={yearlyInclBonuses} value={price} className="text-primary text-2xl text-right font-medium" />
      </Media>
      <Media tablet={true} mobile={true}>
        <RowItem label={yearlyInclBonuses} value={price} className="text-primary text-xl text-right font-medium" />
      </Media>
    </>
  );
}

export function PriceDetail(props: PriceDetailAttributes) {
  const {
    saving,
    baseprice,
    workingprice,
    workingpriceunit,
    showMonthlyPaymentAmount,
    yourDiscount,
    hideComparisonSelect,
  } = useFeaturesResult();

  const { HTLabel, NTLabel } = useGeneralLabel();
  const discount = React.useMemo(() => {
    if (props.dynamischeAngaben) {
      const res = props.dynamischeAngaben
        .map((v) => v.doubleObj)
        .filter(function isTruthy<T>(value: T): value is NonNullable<T> {
          return Boolean(value);
        })
        .flat()
        .find((d) =>
          props.isBrutto ? d.name === "gesamtpreisMonatOhneBoniBrutto" : d.name === "gesamtpreisMonatOhneBoniNetto"
        );
      if (!res) {
        return "0";
      }

      return res.wert.toFixed(0).replace(".", ",");
    } else return "0";
  }, [props.dynamischeAngaben, props.isBrutto]);
  return (
    <>
      <div className="flex flex-col gap-1">
        {hideComparisonSelect === false && (
          <div>
            <RowItem
              label={saving}
              value={formatDecimal(props.isBrutto ? props.ersparnisBrutto : props.ersparnisNetto) + " €"}
              className="text-primary text-right"
            />
            {showMonthlyPaymentAmount && <RowItem label={yourDiscount} value={`${discount} €`} />}
          </div>
        )}

        <RowItem
          label={baseprice}
          value={
            (props.timeframeYear
              ? formatDecimal(props.isBrutto ? props.grundpreisBrutto : props.grundpreisNetto)
              : formatDecimal(props.isBrutto ? props.grundpreisMonatBrutto : props.grundpreisNetto)) + " €"
          }
        />
        <RowItem
          label={`${workingprice} ${
            (props.isBrutto && props.arbeitspreisNTCtKWhBrutto) || (!props.isBrutto && props.arbeitspreisNTCtKWhNetto)
              ? HTLabel
              : ""
          }`}
          value={
            formatDecimal(
              props.isBrutto
                ? props.arbeitspreisHTCtKWhBrutto || props.arbeitspreisCtKWhBrutto
                : props.arbeitspreisHTCtKWhNetto || props.arbeitspreisCtKWhNetto
            ) +
            " " +
            workingpriceunit
          }
        />
        {((props.isBrutto && props.arbeitspreisNTCtKWhBrutto) ||
          (!props.isBrutto && props.arbeitspreisNTCtKWhNetto)) && (
          <RowItem
            label={`${workingprice} ${NTLabel}`}
            value={
              formatDecimal(props.isBrutto ? props.arbeitspreisNTCtKWhBrutto : props.arbeitspreisNTCtKWhNetto) +
              " " +
              workingpriceunit
            }
          />
        )}
      </div>
    </>
  );
}

const Price = ({
  isBrutto,
  timeframeYear,
  gesamtpreisBrutto,
  gesamtpreisNetto,
  ersparnisBrutto,
  ersparnisNetto,
  grundpreisBrutto,
  grundpreisNetto,
  arbeitspreisHTCtKWhBrutto,
  arbeitspreisNTCtKWhBrutto,
  arbeitspreisCtKWhBrutto,
  arbeitspreisHTCtKWhNetto,
  arbeitspreisNTCtKWhNetto,
  arbeitspreisCtKWhNetto,
  gesamtpreisMonatBrutto,
  gesamtpreisMonatNetto,
  grundpreisMonatBrutto,
  grundpreisMonatNetto,
  dynamischeAngaben,
}: PriceDetailAttributes & PriceOverviewAttributes) => {
  return (
    <section id="price" className="mb-6">
      <PriceOverview
        isBrutto={isBrutto}
        timeframeYear={timeframeYear}
        gesamtpreisBrutto={gesamtpreisBrutto}
        gesamtpreisNetto={gesamtpreisNetto}
        gesamtpreisMonatBrutto={gesamtpreisMonatBrutto}
        gesamtpreisMonatNetto={gesamtpreisMonatNetto}
      />
      <PriceDetail
        isBrutto={isBrutto}
        timeframeYear={timeframeYear}
        ersparnisBrutto={ersparnisBrutto}
        ersparnisNetto={ersparnisNetto}
        grundpreisBrutto={grundpreisBrutto}
        grundpreisNetto={grundpreisNetto}
        arbeitspreisHTCtKWhBrutto={arbeitspreisHTCtKWhBrutto}
        arbeitspreisNTCtKWhBrutto={arbeitspreisNTCtKWhBrutto}
        arbeitspreisCtKWhBrutto={arbeitspreisCtKWhBrutto}
        arbeitspreisHTCtKWhNetto={arbeitspreisHTCtKWhNetto}
        arbeitspreisNTCtKWhNetto={arbeitspreisNTCtKWhNetto}
        arbeitspreisCtKWhNetto={arbeitspreisCtKWhNetto}
        grundpreisMonatBrutto={grundpreisMonatBrutto}
        grundpreisMonatNetto={grundpreisMonatNetto}
        dynamischeAngaben={dynamischeAngaben}
      />
    </section>
  );
};

export default Price;
