import React from "react";

import FormControl from "../../../../components/FormControl";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeaturesCC from "../../hooks/useFeaturesCC";

interface ReasonToChangeBoxType {
  options: {
    label: string,
    value: string,
  }[]
}

const ReasonToChangeBox = ({ options }:ReasonToChangeBoxType) => {
  const {
    internalTariffChange, reasonToChangeHeadline
  } = useFeaturesCC();

  if(!internalTariffChange){
    options = options.filter(element => element?.value!=="tarifwechsel");
  }
  return (
    <FormControl>
      <div className="font-bold text-lg mb-5">{reasonToChangeHeadline}</div>
      <div className="grid lg:grid-cols-3">
        {options.map((option) => (
          <FormikRadio key={option.value} label={option.label} name="reasonToChange" value={option.value} />
        ))}
      </div>
    </FormControl>
  );
};

export default ReasonToChangeBox;
