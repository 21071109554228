import { useQuery } from "react-query";

import { useCalcParams } from "../context/CalculationParameter";
import { useConfig } from "../context/Config";
import { useTRInputContext } from "../context/FormInputData";
import { useSelectTariff } from "../context/Tariff";
import ProxyInstance from "../services/proxy";

const useTarifCart = (tarifRootID: number, variantenID: string, setContext = false) => {
  const { config } = useConfig();
  const { calcParams } = useCalcParams();
  const inputContext = useTRInputContext();
  const { selectedTariff, setSelectTariff } = useSelectTariff();

  return useQuery(
    `GET_EXTERNAL_LABELS_${tarifRootID}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/ws/tariff/energie/getTarifkarte?rootID=${tarifRootID}&plz=${inputContext?.values?.zipCode}&ort=${
            typeof inputContext.values?.city !== "string" ? inputContext.values?.city?.value : undefined
          }&variantenID=${variantenID}&netzbetreiber=${calcParams.netzbetreiber}&grundversorger=${
            calcParams.grundversorger
          }&ausgabeKonfiguration=1`,
          { apikey: config.apikey || "" }
        );
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (setContext) {
          setSelectTariff({
            ...selectedTariff,
            preisStaffeln: data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[0]?.preisStaffeln,
          });
        }
      },
    }
  );
};

export default useTarifCart;
