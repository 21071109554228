import React from "react";

import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { TARIF_LABELS } from "../../../../Constant";
import { useTariff } from "../../../../context";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const TextHeader = ({ className }: { className?: string }) => {
  const { header } = useFeatureInputPage();
  return <div className={className}>{header}</div>;
};

const Header = () => {
  const tariff = useTariff();
  return (
    <>
      <Mobile>
        <div className="col-span-2">
          <TextHeader className="text-xl inline" /> <span className="text-xl">- {TARIF_LABELS[tariff.id]}</span>
        </div>{" "}
      </Mobile>
      <Tablet>
        <div className="flex flex-col">
          <TextHeader className="text-3xl" />
        </div>
      </Tablet>
      <Desktop>
        <div className="flex flex-col">
          <TextHeader className="text-3xl" />
        </div>
      </Desktop>
    </>
  );
};

export default Header;
