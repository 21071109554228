import { useField } from "formik";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { TYPE } from "../../../../Constant";
import { useConfig, useParameter, useTariff } from "../../../../context";
import { useTRInputContext } from "../../../../context/FormInputData";
import ProxyInstance from "../../../../services/proxy";
import useQueryParameters from "../useQueryParameters";

const useGetTariffVariant = (calcParams) => {
  const tariff = useTariff();
  const config = useConfig();
  const [{ value }] = useField("supplier");
  const { authToken } = useParameter();
  const inputContext = useTRInputContext();

  const body = {
    plz: inputContext?.values?.zipCode,
    kundenart: inputContext?.values?.customerType,
    verbrauch: inputContext?.values?.oneTariff?.replace(".", ""),
    netzbetreiber: calcParams?.netzbetreiber || "",
    grundversorger: calcParams?.grundversorger || "",
    tariftyp: tariff.tariftyp,
    versorgerID: value?.versorgerID||"",
  };

  const tariffType = useMemo(
    () =>
      tariff.tariffType &&
      tariff.tariffType !== "telco" &&
      TYPE[tariff.tariffType],
    [tariff.tariffType]
  );

  const queryParams = useQueryParameters({
    netzbetreiber: calcParams?.netzbetreiber || "",
    grundversorger: calcParams?.grundversorger || "",
    tariftyp: tariff.tariftyp,
    versorgerID: value?.versorgerID||"",
  });

  return useQuery(
    [`GET_TARIFF_VARIANT_${value?.versorgerID||""}`],
    async () => {
      try {
        let res;
        if (tariff.tariffType !== "gas") {
          res = await ProxyInstance(
            `/ws/tariff/${tariffType}/getVersorgerTarifvarianten?${queryParams}`,
            {
              apikey: config.apikey || "",
            }
          );
        } else {
          res = await ProxyInstance(
            `/ws/tariff/${tariffType}/getVersorgerTarifvarianten`,
            {
              apikey: config.apikey || "",
              method: "POST",
              authToken,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            }
          );
        }
        const data = res?.data?.data;
        const [supplier] = data.versorger;

        return supplier.tarife;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!(value?.versorgerID || ""),
    }
  );
};

export default useGetTariffVariant;
