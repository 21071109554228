import React, { HTMLAttributes } from "react";

interface CardAttributes extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Card = ({ children, className, style, ...props }: CardAttributes) => {
  return (
    <div
      className={`bg-content-bg text-content-text p-4 shadow-md rounded-2xl ${className ? className : ""}`}
      style={{ ...style }}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
