import { Formik } from "formik";
import qs from "query-string";
import React from "react";

import Button from "../../components/Button";
import {
  useConfig,
  useParameter,
  useStepChange,
  useTariff,
} from "../../context";
import { useCalcResult } from "../../context/CalculationResult";
import { useTRInputContext } from "../../context/FormInputData";
import { useGridConnectionStatus } from "../../context/GridConnectionStatus";
import TariffInputForm from "./components/InputForms";
import useFeatureInputPage from "./hooks/useFeatureInputPage";
import getValidationSchema from "./validation/schema";

const TariffInputs = ({ submit }) => {
  const { values, setValues } = useTRInputContext();
  const { setStep } = useStepChange();
  const { gridConnectionStatus } = useGridConnectionStatus();
  const tariff = useTariff();
  const config = useConfig();
  const {calcResult, } = useCalcResult();
  const {
    showCancelFormBtn,
    zipCodeInvalidMessageLabel,
    zipCodeValidationLabel,
    cancellationFormButtonLabel,
  } = useFeatureInputPage();

  const {
    onlyInputTarget,
    kundenart,
    oeko,
    handlingTarife,
    tarifcode,
    stichtag,
    baiextendedWhiteList,
    zaehlerart,
    tarifRootIDsMindestfilter,
    tarifRootIDs,
    markenIDs,
    bonus,
    versorgerIDs,
  } = useParameter();

  const validationSchema = getValidationSchema(
    zipCodeInvalidMessageLabel,
    zipCodeValidationLabel
  );

  return (
    <>
      <Formik
        component={TariffInputForm}
        validationSchema={validationSchema}
        initialValues={{
          networkOperator: "",
          city: values?.city || "",
          street: "",
          zipCode: values?.zipCode || "",
          houseNumber: "",
          meterType: "1,2",
          customerType: "0",
          oneTariff: "3.200",
          twoTariff: "0",
          isEcoTariff: false,
          tariffType: "0",
          measurementType: "0",
          baseSupplier: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          if (setValues) {
            setValues((prevState) => {
              return {
                ...prevState,
                ...values,
              };
            });
          }

          if (onlyInputTarget) {
            if (typeof window !== "undefined") {
              const queryString = qs.stringify({
                tariffCalculatorTypeId: tariff.id,
                [tariff.id === 2 ? "verbrauchGas" : "verbrauchStrom"]:
                  values.oneTariff,
                plz: values.zipCode,
                ...(values.city
                  ? {
                      ort:
                        typeof values.city !== "string"
                          ? values.city.value
                          : values.city,
                    }
                  : {}),
                ...(values.street
                  ? {
                      strasse:
                        typeof values.street !== "string"
                          ? values.street.value
                          : values.street,
                    }
                  : {}),
                ...(kundenart ? { kundenart } : {}),
                ...(oeko ? { oeko } : {}),
                ...(handlingTarife ? { handlingTarife } : {}),
                ...(tarifcode ? { tarifcode } : {}),
                ...(stichtag ? { stichtag } : {}),
                ...(baiextendedWhiteList ? { baiextendedWhiteList } : {}),
                ...(zaehlerart ? { zaehlerart } : {}),
                ...(tarifRootIDs ? { tarifRootIDs } : {}),
                ...(markenIDs ? { markenIDs } : {}),
                ...(bonus ? { bonus } : {}),
                ...(versorgerIDs ? { versorgerIDs } : {}),
                ...(tarifRootIDsMindestfilter
                  ? { tarifRootIDsMindestfilter }
                  : {}),
              });

              window.open(`${onlyInputTarget}?${queryString}`);
            }
          } else {
            if (tariff.id === 7) {
              const processMapping = config.frontendConfig?.processmapping.find(
                (v) => v.mapping
              );

              if (processMapping?.mapping) {
                const data = processMapping.mapping.find(
                  (value) => value.status_extern === gridConnectionStatus
                );

                if (data) {
                  if (data.status_extern === 0 && data.pid_intern === 0) {
                    try {
                      if(calcResult === null){
                        await submit(values);
                      }
                      setStep(2);
                    } catch (e) {
                      console.error(e);
                      throw e;
                    }
                  } else if (
                    data.status_extern === 2 &&
                    data.pid_intern === 2
                  ) {
                    // TODO: go to contact form
                    setStep(7);
                  } else {
                    try {
                      if(calcResult === null){
                        await submit(values);
                      }
                      setStep(2);
                    } catch (e) {
                      console.error(e);
                      throw e;
                    }
                  }
                } else {
                  if(calcResult === null){
                    await submit(values);
                  }
                  setStep(2);
                }
              } else {
                setStep(7);
              }
            } else {
              try {
                if(calcResult === null){
                  await submit(values);
                }
                setStep(2);
              } catch (e) {
                console.error(e);
                throw e;
              }
            }
          }
        }}
      />

      {showCancelFormBtn && (
        <div className="max-w-screen-sm mx-auto rounded-3xl bg-[--get-content-bg] p-3">
          <Button
            onClick={() => setStep(5)}
            variant="primary"
            type="submit"
            className="p-2"
          >
            {cancellationFormButtonLabel}
          </Button>
        </div>
      )}
    </>
  );
};

export default TariffInputs;
