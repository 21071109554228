import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const gridConnectionStatusContext = createContext(null);

const GridConnectionStatusProvider = ({ children }) => {
  const [gridConnectionStatus, setGridConnectionStatus] = React.useState(0);

  return (
    <gridConnectionStatusContext.Provider
      value={{ gridConnectionStatus, setGridConnectionStatus }}
    >
      {children}
    </gridConnectionStatusContext.Provider>
  );
};

export default GridConnectionStatusProvider;
export const useGridConnectionStatus = () =>
  useContextSelector(gridConnectionStatusContext, (c) => c);
