import { useField, useFormikContext } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import FormControl from "../../../../components/FormControl";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useSelectTelco } from "../../../../context/Telco";
import { FormValues } from "../../../../types/FormValues";
import { uniqueArr } from "../../../../utils/uniqueArr";
import { addressDataValidateKeys } from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import { checkFormValidationAndRedirect } from "../../hooks/useCheckFormValidationAndRedirect";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import Offermode from "../Offermode";
import FormData from "./FormData";

const AddressData = ({ disableFooterButtons = false }) => {
  const { values, setFieldError } = useFormikContext<FormValues>();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();
  const { calcParams } = useCalcParams();
  const { selectedTelco } = useSelectTelco();
  const tariff = useTariff();
  const NEXT_STEP = selectedTelco && tariff.id === "7" ? "gee" : "preSupplierForm";
  const PREV_STEP = "contactForm";

  const {
    showInstallationDelivery,
    showBillingDelivery,
    isEnableInvoicePost,
    isEnableInvoiceMail,
    advertisingConsentPost,
    advertisingConsentEmail,
    billingSameAsDelivery,
    invoiceDelivery,
  } = useFeaturesCC();

  const { mutateAsync } = useServerValidationForm("addressForm", {
    tarifParameter: calcParams,
    keysToValidate: addressDataValidateKeys,
  });

  const [{ value }] = useField("deliveryIsSameBillingAddress");
  const [{ value: value2 }] = useField("deliveryIsSameInstallationAddress");

  useSetFieldWithVPPData("addressForm");

  return (
    <>
      <FormData type="deliveryAddress" />

      {showInstallationDelivery && tariff.id === "7" && (
        <Checkbox
          className="my-4"
          name="deliveryIsSameInstallationAddress"
          label="Lieferanschrift (für FRITZ!Box-Zustellung) identisch mit Installationsanschrift"
        />
      )}

      {showInstallationDelivery && !value2 && <FormData type="installationAddress" />}

      <Checkbox className="mb-4" name="deliveryIsSameBillingAddress" label={billingSameAsDelivery} />

      {!value && <FormData type="billingAddress" />}

      {showBillingDelivery && (
        <div className="flex flex-row gap-5 mb-4">
          <div>{invoiceDelivery}</div>

          {isEnableInvoicePost && <FormikRadio name="invoiceDeliveryBy" label={advertisingConsentPost} value="post" />}

          {isEnableInvoiceMail && <FormikRadio name="invoiceDeliveryBy" label={advertisingConsentEmail} value="mail" />}
        </div>
      )}
      <div className="mt-auto">
        <Offermode position={2} />

        {!disableFooterButtons && (
          <FooterButtons
            onSubmitValidation={async () => {
              const awaited = await mutateAsync(values);
              checkFormValidationAndRedirect(awaited, NEXT_STEP, setActivatedPanels, setFieldError, setFormName);
            }}
            onSubmit={() => {
              setFormName(NEXT_STEP);
              setActivatedPanels((prevState: string[]) => uniqueArr([...prevState, NEXT_STEP]));
            }}
            onBack={() => setFormName(PREV_STEP)}
          />
        )}
      </div>
    </>
  );
};

export default AddressData;
