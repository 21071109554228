const convertRangeTimeStringToArray = (rangeTimeStr) => {
  const arr = rangeTimeStr
    .replace(/\.|-|\bMonate\b/g, "")
    .split(" ")
    .filter(Boolean);

  const start = parseInt(arr[0]);
  const end = parseInt(arr[1]);
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
};

export default convertRangeTimeStringToArray;
