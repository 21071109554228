import { useFormikContext } from "formik";
import * as React from "react";

import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import { useTariff } from "../../../../context";

const ContractInfo = () => {
  const tariff = useTariff();
  const { setFieldValue } = useFormikContext();

  const Versorger_LIST = [
    "Zehrer + Petersen GmbH & Co. KG",
    "Halberstadtwerke",
  ].map((item) => ({
    label: item,
    value: item,
  }));

  React.useEffect(
    () => {
      if (tariff.id !== "1" && tariff.id !== "7") {
        setFieldValue("versorger", Versorger_LIST[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tariff.id]
  );

  return (
    <>
      <div className="font-bold text-xl mt-6">Angaben zu Ihrem Vertrag</div>
      <GetSelect
        className="w-full mb-4"
        label="Ihr Versorger *"
        name="versorger"
        options={Versorger_LIST}
        disabled={tariff.id !== "1" && tariff.id !== "7"}
      />
      <div
        className="form-control "
        style={{
          gap: "30px",
        }}
      >
        <div className="md:flex gap-4 mb-4">
          <div className="w-full">
            <Input
              className="w-full"
              name="kundennummer"
              label="Kundennummer *"
              inputType="number"
            />
          </div>
          <div className="w-full">
            <Input
              className="w-full"
              name="marktlokationsID"
              label="MarktlokationsID"
              inputType="number"
            />
          </div>
        </div>
        <div className="md:flex gap-4 mb-4">
          <div className="w-full">
            <Input
              className="w-full mb-4 md:mb-0"
              label="Vertragsnummer"
              name="vertragsnummer"
              inputType="number"
            />
          </div>
          <div className="w-full">
            <Input
              className="w-full"
              label="Zählernummer Strom"
              name="zaehlernummer"
              inputType="number"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContractInfo;
