import {addWeeks} from "date-fns";



const validReadingDate=(value: string,min:number,max:number)=> {

    function isMinReadingDate(){
        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addWeeks(today, -min);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const readingDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return readingDate >= minDate;
        }
        return true
    }
    function isMaxReadingDate(){
        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addWeeks(today, -max);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const readingDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return readingDate <= minDate;
        }
        return true
    }


    function minReadingDate(){
        const today = new Date( new Date().setHours(0,0,0,0));
        return  addWeeks(today, -min)
    }
    function maxReadingDate(){
        const today = new Date( new Date().setHours(0,0,0,0));
        return today;
    }
    return {minReadingDate,maxReadingDate,isMinReadingDate,isMaxReadingDate}
}
export default validReadingDate