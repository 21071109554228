import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import useSelectedTelco from "../../hooks/useSelectTelco";

const TelcoContext = createContext(null);

const TelcoProvider = ({ children }) => {
  const [selectedTelco, setSelectedTelco] = useSelectedTelco();

  return (
    <TelcoContext.Provider value={{ selectedTelco, setSelectedTelco }}>
      {children}
    </TelcoContext.Provider>
  );
};

export default TelcoProvider;
export const useSelectTelco = () => useContextSelector(TelcoContext, (c) => c);
