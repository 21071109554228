import React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeaturesPreSupplierData from "../../hooks/useFeaturesCC/useFeaturesPreSupplierData";

const EVNForm = () => {
  const { evnTitle, noEvn, shortEvn, fullEvn } = useFeaturesPreSupplierData();
  return (
    <>
      <div className="font-medium text-lg">{evnTitle}</div>
      <FormikRadio value="false" name="itemizedBill" label={noEvn} />
      <FormikRadio value="true" name="itemizedBill" label={shortEvn} />
      <FormikRadio value="full" name="itemizedBill" label={fullEvn} />
    </>
  );
};

export default EVNForm;
