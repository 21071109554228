import Tooltip from "@mui/material/Tooltip";
import React from "react";

import QuestionMark from "../icons/QuestionMark";

const TRTooltip = ({ text }: { text: string }) => {
  if (text) {
    return (
      <Tooltip title={text} arrow slotProps={{ popper: { className: "bg-[#313131]" } }}>
        <div className="pl-1 inline">
          <QuestionMark />
        </div>
      </Tooltip>
    );
  }
  return <></>;
};
export default TRTooltip;
