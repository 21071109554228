import { useFormikContext } from "formik";
import React, { useMemo } from "react";

import Button from "../../../../components/Button";
import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import {DELEAY_AMOUNTCHANGE, TARIF_LABELS} from "../../../../Constant";
import { useTariff } from "../../../../context";
import { useConfig } from "../../../../context/Config";
import useDebounce from "../../../../hooks/useDebounce";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import usePrimaService from "../../../../hooks/usePrimaService";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useSimplifier from "../../hooks/useSimplifier";
import { InputPageFormik } from "../../types/InputPageFormik";
import TRAddress from "../Address";
import TRButtonGroups from "../ButtonGroups";

const TariffInputFormWidget = ({
  handleSubmit,
  values,
  isSubmitting,
}: {
  handleSubmit: () => void;
  values: InputPageFormik;
  isSubmitting: boolean;
}) => {
  const { search, consumption, usage, tooltippVerbrauch, tooltippVerbrauchHT, tooltippVerbrauchNT } =
    useFeatureInputPage();
  const { HTLabel, NTLabel } = useGeneralLabel();
  const tariff = useTariff();
  const { data } = useSimplifier();
  const { data: primaData } = usePrimaService();
  const { config } = useConfig();
  const buttonLabel = useMemo(() => `${TARIF_LABELS[`${tariff.id}`]}tarife vergleichen`, [tariff.id]);
  const { values: fields, setFieldValue } = useFormikContext<InputPageFormik>();
  const debounceOneTariff = useDebounce(values?.oneTariff, DELEAY_AMOUNTCHANGE);
  const debounceTwoTariff = useDebounce(values?.twoTariff, DELEAY_AMOUNTCHANGE);
  const { responsiveClass } = useResponsiveClass();

  React.useEffect(() => {
    // only strom and gas
    if (tariff.id === "1" || tariff.id === "2") {
      setFieldValue("networkOperator", data?.networkOperator);
      setFieldValue("baseSupplier", data?.baseSupplier);
    }
  }, [data?.baseSupplier, data?.networkOperator, setFieldValue, tariff.id]);

  React.useEffect(() => {
    if (primaData?.zipCode && primaData?.city && primaData?.street && primaData?.houseNumber && tariff.id === "7") {
      const timer = setTimeout(() => {
        handleSubmit();
      }, 2000);
      return () => clearTimeout(timer);
    }
    return () => null;
  }, [primaData, tariff.id, handleSubmit]);

  const disabled = useMemo(
    () =>
      (data?.unique &&
        values.city.value?.length > 0 &&
          parseInt(values.oneTariff.replace(".", "")) === parseInt(debounceOneTariff?.replace(".", "")) &&
          (parseInt(values.twoTariff.replace(".", "")) === parseInt(debounceTwoTariff?.replace(".", ""))|| values.meterType !== "3,4") &&
        parseInt(values.oneTariff.replace(".", "")) > 0 &&
        (parseInt(values.twoTariff.replace(".", "")) > 0 || values.meterType !== "3,4")) ||
      (primaData?.zipCode && primaData?.city && primaData?.street && primaData?.houseNumber && tariff.id === "7"),
    [data, primaData, tariff.id, values]
  );

  return (
    <div className={responsiveClass("mt-4", "-mx-2", "-mx-4", "-mx-4")}>
      <TRButtonGroups onlyInputTarget={true} />
      {config?.frontendConfig?.calculatorTypeIDs ? (
        <form onSubmit={handleSubmit}>
          <FormControl>
            <div className="flex flex-col gap-4 w-full mt-6">
              <TRAddress />
              <div className="grid grid-cols-2 gap-4">
                {tariff.id !== "7" && (
                  <>
                    <div className="col-span-2 grid grid-cols-4 gap-4 w-full">
                      <Desktop>
                        <div className="w-full col-span-2">
                          <Input
                            name="oneTariff"
                            placeholder="Verbrauch (HT)"
                            label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                            tooltip={values.meterType === "3,4" ? tooltippVerbrauchHT : tooltippVerbrauch}
                            onlyNumber
                            maxLength={7}
                            usage={usage}
                          />
                        </div>

                        {fields.meterType === "3,4" && (
                          <div className="w-full col-span-2">
                            <Input
                              name="twoTariff"
                              label={consumption + " " + NTLabel}
                              tooltip={tooltippVerbrauchNT}
                              onlyNumber
                              maxLength={7}
                              usage={usage}
                            />
                          </div>
                        )}
                      </Desktop>
                      <Tablet>
                        <div className="w-full col-span-2">
                          <Input
                            name="oneTariff"
                            placeholder="Verbrauch (HT)"
                            label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                            tooltip={values.meterType === "3,4" ? tooltippVerbrauchHT : tooltippVerbrauch}
                            onlyNumber
                            maxLength={7}
                            usage={usage}
                          />
                        </div>

                        {fields.meterType === "3,4" && (
                          <div className="w-full col-span-2">
                            <Input
                              name="twoTariff"
                              label={consumption + " " + NTLabel}
                              tooltip={tooltippVerbrauchNT}
                              onlyNumber
                              maxLength={7}
                              usage={usage}
                            />
                          </div>
                        )}
                      </Tablet>
                      <Mobile>
                        <div className="w-full col-span-4 mb-4">
                          <Input
                            name="oneTariff"
                            placeholder="Verbrauch (HT)"
                            label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                            tooltip={values.meterType === "3,4" ? tooltippVerbrauchHT : tooltippVerbrauch}
                            onlyNumber
                            maxLength={7}
                            usage={usage}
                          />
                        </div>

                        {fields.meterType === "3,4" && (
                          <div className="w-full col-span-4">
                            <Input
                              name="twoTariff"
                              label={consumption + " " + NTLabel}
                              tooltip={tooltippVerbrauchNT}
                              onlyNumber
                              maxLength={7}
                              usage={usage}
                            />
                          </div>
                        )}
                      </Mobile>
                    </div>
                  </>
                )}
              </div>
            </div>
          </FormControl>

          <div className="flex justify-end	w-full">
            {isSubmitting ? (
              <LoadingOverlay text="Bitte warten..." />
            ) : (
              <>
                <Button disabled={!disabled} variant="primary" type="submit" className="py-1 ">
                  {search || buttonLabel}
                </Button>
              </>
            )}
          </div>
        </form>
      ) : null}
    </div>
  );
};

export default TariffInputFormWidget;
