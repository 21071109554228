import { addDays, addMonths, addYears } from "date-fns";
import format from "date-fns/format";
import { useField } from "formik";
import { CalendarDays } from "lucide-react";
import React, { ReactNode } from "react";
import ReactCalendar from "react-calendar";
import { Value } from "react-widgets/esm/types";

import useFeaturesCC from "../../widgets/TCContractConclusion/hooks/useFeaturesCC";
import Button from "../Button";
import ErrorMessage from "../ErrorMessage";
import ExclamationIcon from "../icons/exclamationMark";

interface DatePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  bgColor: string | undefined;
  className: string | undefined;
}

const DatePicker = ({ name, label, disabled = false, bgColor, className, ...props }: DatePickerProps) => {
  const { isDatePicker, moveInDateDaysInPast, moveInDateMonthsInFuture, requestedDesiredDateDaysInAdvanceEnergy } = useFeaturesCC();
  
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [fields, meta, { setValue, setTouched }] = useField({ name });

  const dateValue = React.useMemo(() => {
      if (fields.value) {
        const [day, month, year] = fields.value.split(".");

        if (!year || year?.length !== 4 || !month || !day) return new Date(1900, 0, 1);

        return new Date(Number(year), Number(month) - 1, Number(day));
      }
    return undefined;
  }, [fields.value]);

  const handleBlur = React.useCallback(async () => {
    await setTouched(true);
  }, [setTouched]);

  const handleDateChangePicker = React.useCallback(
    async (e: Value) => {
      if (e instanceof Date) {
        const convertDateValue = format(new Date(e), "dd.MM.yyyy");
        await setValue(convertDateValue);
        setShowCalendar((prevState) => !prevState);
      } else {
        console.error(`Der übergebene Wert ${e} ist kein Datum!`);
      }
    },
    [setValue]
  );
  const handleDateChangeInput = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = e.target.value.replace(/\D/g, "").match(/(\d{0,2})(\d{0,2})(\d{0,4})/);
      if (regex) {
        const [, day, month, year] = regex;
        const value = !month ? day : `${day}.${month}${`${year ? `.${year}` : ""}`}`;
        if (Number(day) <= 31 && Number(month) <= 12 && Number(year) <= new Date().getFullYear() + 1) {
          await setValue(value);
        }
      }
    },
    [setValue]
  );

  const today = new Date();
  let minDate = addYears(today, -1);
  let maxDate = addYears(today, 1);
  let defaultActiveStartDate = dateValue ? dateValue : today;

  switch (name) {
    case "moveInDate":
      minDate = addDays(today, -moveInDateDaysInPast);
      maxDate = addMonths(today, moveInDateMonthsInFuture);
      break;
    case "desiredDeliveryDate":
      minDate = today;
      maxDate = addDays(today, requestedDesiredDateDaysInAdvanceEnergy);
      break;
    case "readingDate":
      minDate = addMonths(today, -1)
      maxDate = today;
      break;
    case "birthday":
      minDate = addYears(today, -120);
      maxDate = addYears(today, -18);
      defaultActiveStartDate = dateValue ? dateValue : addYears(today, -18);
      break;
    case "cancellationDate":
      minDate = addMonths(today, -6)
      maxDate = addMonths(today, 6)
      break;
  }

  return (
    <div className="flex relative w-full flex-col">
      <label htmlFor={name} className="text-xs leading-4 pb-1 w-full">
        {label}
      </label>
      <div id="date-picker" className="border border-solid rounded-full border-borderInput mb-5">
        <div className="flex relative w-full h-8">
          <input
            {...fields}
            disabled={disabled}
            type="text"
            className={`appearance-none pr-4 pl-4 outline-none placeholder-transparent border-none ${
              bgColor || "bg-transparent"
            } invalid:color-red ${className || ""}`.trim()}
            name={name}
            onChange={handleDateChangeInput}
            onBlur={handleBlur}
            maxLength={10}
            data-testid={`test-${name}`}
            placeholder=" "
            {...props}
          />

          {meta.touched && meta.error && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingRight: "10px",
              }}
            >
              <ExclamationIcon />
            </div>
          )}

          {isDatePicker && (
            <Button
              variant="unstyled"
              disabled={false}
              className="rounded h-8 bg-transparent pr-3"
              onClick={() => setShowCalendar((prevState) => !prevState)}
            >
              <CalendarDays />
            </Button>
          )}
        </div>

        {showCalendar && (
          <section className="absolute z-30">
            <ReactCalendar
              locale="de"
              defaultActiveStartDate={defaultActiveStartDate}
              minDate={minDate}
              maxDate={maxDate}
              value={dateValue}
              defaultView="month"
              onChange={(value) => handleDateChangePicker(value)}
            />
          </section>
        )}
      </div>
      <span className="-mt-5">{meta.touched && meta.error && <ErrorMessage text={meta.error} />}</span>
    </div>
  );
};

export default DatePicker;
