import { useField } from "formik";
import * as React from "react";

import formatDecimal from "../../utils/formatDecimal";
import isValidEmail from "../../utils/isValidEmail";
import useFeaturesCC from "../../widgets/TCContractConclusion/hooks/useFeaturesCC";
import ErrorMessage from "../ErrorMessage";
import Spinner from "../icons/spinner";
import LabelForm from "../LabelForm";
import TRTooltip from "../TRTooltip";
import DatePicker from "./DatePicker";

interface InputPropTypes extends React.InputHTMLAttributes<HTMLInputElement> {
  bgColor?: string | undefined;
  className?: string;
  name: string;
  label?: React.ReactNode;
  type?: string;
  onlyNumber?: boolean;
  onlyNumberAndPunkt?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  inputType?: string;
  maxLength?: number;
  useIcon?: React.ReactNode;
  isLoading?: boolean;
  usage?: string;
  tooltip?: string;
}

const Input = ({
  bgColor,
  className,
  name,
  label,
  type,
  onlyNumber,
  onlyNumberAndPunkt,
  disabled,
  tabIndex,
  inputType,
  maxLength,
  useIcon,
  isLoading,
  usage,
  tooltip,
  ...props
}: InputPropTypes) => {
  const [fields, meta, { setValue, setTouched }] = useField({ name });
  const [lengthOfInput, setLengthOfInput] = React.useState(254);
  const { emailIsIncorrect, emailRepeatIsIncorrect } = useFeaturesCC();
  const [email] = useField("email");
  const handleBlur = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value.trim());
      await setTouched(true);
    },
    [setTouched, setValue]
  );

  const handleChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const emojiRegex =
        /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}\u{1F900}-\u{1F9FF}\u{1F004}-\u{1F0CF}\u{1F170}-\u{1F251}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}]/u;
      if (emojiRegex.test(e.target.value)) return;

      if (name === "iban") {
        await setValue(e.target.value);
      }

      if (onlyNumber) {
        const val = e.target.value.replace(/[^0-9]/g, "");
        if (name === "oneTariff" || name === "twoTariff") {
          await setValue(val.replace(/\B(?=(\d{3})+(?!\d))/g, "."));
        } else await setValue(val);
      } else if (inputType) {
        switch (inputType) {
          case "name": {
            const reg = /[^A-Za-zÄÖÜäöüß[\].\-\s]/g;
            setLengthOfInput(254);
            await setValue(e.target.value.replace(reg, "").trimStart());
            break;
          }
          case "address":
          case "firma": {
            const reg = /[^A-Za-zÄÖÜäöüß0-9[\].\-\s,&+@]/g;
            setLengthOfInput(254);
            await setValue(e.target.value.replace(reg, "").trimStart());
            break;
          }
          case "otherNumber": {
            const reg = /[^0-9A-Za-z\\.-\s]/g;
            setLengthOfInput(maxLength ?? 254);
            await setValue(e.target.value.replace(reg, "").trimStart());
            break;
          }
          case "number": {
            const reg = /[^0-9,]/g;
            setLengthOfInput(50);
            const val = e.target.value.replace(reg, "").trimStart();
            if (name === "workingPriceBrutto" || name === "workingPriceNetto") {
              const number = val.split(",");
              let fractionPart = "";
              if (val.search(",") !== -1) {
                fractionPart = "," + number[1];
              }
              await setValue(number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + fractionPart);
            } else {
              const reg = /[^0-9]/g;
              setLengthOfInput(50);
              await setValue(e.target.value.replace(reg, "").trimStart());
            }
            break;
          }
          case "iban": {
            const input = e.target.value.replaceAll(" ", "");
            const reg1 = /[^A-Za-z]/g;
            const reg2 = /[^0-9]/g;
            const reg3 = /(\w{4})(?=\w)/g;

            if (input.startsWith("DE")) {
              setLengthOfInput(27);
            } else if (input.startsWith("AT")) {
              setLengthOfInput(24);
            } else if (input.startsWith("CH")) {
              setLengthOfInput(26);
            } else {
              setLengthOfInput(43);
            }

            await setValue(
              input
                .slice(0, 2)
                .replace(reg1, "")
                .toUpperCase()
                .concat(input.slice(2).replace(reg2, ""))
                .replace(reg3, "$1 ")
            );
            break;
          }
          case "decimalNumber": {
            const reg = /[^0-9,]/g;
            setLengthOfInput(50);
            const val = e.target.value.replace(reg, "").trimStart();
            const number = val.split(",");
            let fractionPart = "";
            if (val.search(",") !== -1) {
              fractionPart = "," + number[1];
            }
            await setValue(number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") + fractionPart);
            break;
          }
          case "BIC": {
            const reg = /[^A-Za-zÄÖÜäöüß0-9]/g;
            setLengthOfInput(11);
            await setValue(e.target.value.replace(reg, "").trimStart().toUpperCase());
            break;
          }
          case "numberGreater0": {
            const reg1 = /[^1-9]/g;
            const reg2 = /[^0-9]/g;
            setLengthOfInput(50);
            await setValue(
              e.target.value.slice(0, 1).replace(reg1, "").concat(e.target.value.slice(1).replace(reg2, ""))
            );
            break;
          }
        }
      } else {
        await setValue(e.target.value);
      }
    },
    [inputType, name, onlyNumber, setValue]
  );

  const element = React.useMemo(() => {
    return (
      <section className="flex flex-col w-full">
        <label htmlFor={name} className="text-xs leading-4 pb-1">
          {label}
          {tooltip ? <TRTooltip text={tooltip} /> : ""}
        </label>
        <div className="relative">
          <input
            {...fields}
            value={
              inputType === "decimalNumber" && typeof fields.value === "number"
                ? formatDecimal(fields.value)
                : fields.value
            }
            maxLength={maxLength ?? lengthOfInput}
            disabled={disabled}
            tabIndex={tabIndex}
            type={onlyNumberAndPunkt ? "number" : type || "text"}
            name={name}
            id={name}
            onChange={handleChange}
            onBlur={handleBlur}
            data-testid={`test-${name}`}
            className={`${
              disabled ? "bg-gray-light cursor-not-allowed" : ""
            } appearance-none border box-border w-full border-solid border-primary disabled:text-text_light focus:outline-none placeholder-transparent h-8 p-2.5 py-2 mb-1 ${
              bgColor || "bg-content-bg text-content-text"
            } border-normal rounded-full ${className ? className : ""}`.trim()}
            placeholder=" "
            {...props}
          />

          {useIcon && (
            <div className="absolute cursor-help right-2 top-2.5 justify-center items-center flex h-5 w-5 rounded-md bg-neutral-400 text-white">
              {useIcon}
            </div>
          )}

          {isLoading && (
            <div className="absolute top-2.5 right-2.5">
              <Spinner />
            </div>
          )}
          {usage && <div className="absolute top-2 right-2 text-sm text-gray-dark leading-4">{usage}</div>}
        </div>
        <span className="h-4">
          {meta.touched && meta.error && <ErrorMessage text={meta.error} />}
          {(typeof fields.value === "string" ? fields.value.length > 0 && !isValidEmail(fields.value) : false) &&
          name === "email" ? (
            <ErrorMessage text={emailIsIncorrect} />
          ) : (
            <></>
          )}
          {name === "confirmationEmail" && email.value !== fields.value && fields.value.length > 0 ? (
            <ErrorMessage text={emailRepeatIsIncorrect} />
          ) : (
            <></>
          )}
        </span>
      </section>
    );
  }, [
    fields,
    disabled,
    tabIndex,
    onlyNumberAndPunkt,
    type,
    name,
    handleChange,
    handleBlur,
    className,
    props,
    usage,
    useIcon,
    maxLength,
    isLoading,
    label,
    meta.touched,
    meta.error,
    bgColor,
    tooltip,
    lengthOfInput,
    emailIsIncorrect,
    emailRepeatIsIncorrect,
    email,
    inputType,
  ]);

  if (type === "datepicker") {
    return (
      <DatePicker name={name} label={label} disabled={disabled} bgColor={bgColor} className={className} {...props} />
    );
  }

  return <LabelForm>{element}</LabelForm>;
};

export default Input;
