import * as React from "react";

import Checkbox from "../../../../../components/Checkbox";
import { useSelectTariff } from "../../../../../context/Tariff";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";

const AdvertisingConsent = () => {
  const {
    headerAdvertisingConsent,
    advertisingConsentPhone,
    subAdvertisingConsent,
    isShowAdvertisingConsentPost,
    advertisingConsentPost,
    advertisingConsentEmail,
    advertisingConsentDigitalMessages,
    footerAdvertisingConsent,
  } = useFeaturesCC();
  const { selectedTariff } = useSelectTariff();
  return (
    <div>
      <Headline>{headerAdvertisingConsent}</Headline>
      <div className="mb-4 text-sm">
        {subAdvertisingConsent.replace("%versorgername%", selectedTariff?.versorgerName || "")}
      </div>
      <div className="flex-col flex gap-2">
        <Checkbox name="promotionalConsentByPhone" label={advertisingConsentPhone} />
        {isShowAdvertisingConsentPost && <Checkbox name="promotionalConsentByPost" label={advertisingConsentPost} />}
        <Checkbox name="promotionalConsentByMail" label={advertisingConsentEmail} />
        <Checkbox name="promotionalConsentDigitalMessages" label={advertisingConsentDigitalMessages} />
        {footerAdvertisingConsent.length > 0 && <div className="my-4">{footerAdvertisingConsent}</div>}
      </div>
    </div>
  );
};

export default AdvertisingConsent;
