import React from "react";

import FormRow from "../../../../components/FormRow";
import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import { SALUTATION_LIST, TITLE_LIST } from "../../../../Constant";
import { useTRInputContext } from "../../../../context/FormInputData";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const PersonalData = () => {
  const {
    salutationOptions,
    salutationLabel,
    titleLabel,
    firstNameLabel,
    lastNameLabel,
    companyNameLabel,
    contactTitleLabel,
  } = useFeaturesCC();
  const inputContext = useTRInputContext();

  return (
    <>
      <div className="text-lg font-bold mb-7">{contactTitleLabel}</div>
      {inputContext?.values?.customerType !== "0" ? (
        <div className="grid grid-cols-2 gap-4 my-4">
          <Input name="companyName" label={companyNameLabel} placeholder="Firma" inputType="firma" />
        </div>
      ) : null}
      <div className="flex flex-col sm:flex-row gap-4 w-2/3 mb-4">
        <GetSelect
          name="salutation"
          label={salutationLabel}
          placeHolder="Anrede"
          options={salutationOptions || SALUTATION_LIST}
          className=""
        />
        <GetSelect name="title" options={TITLE_LIST} label={titleLabel} placeHolder="Titel" />
      </div>
      <FormRow addClassName="flex flex-col sm:flex-row">
        <div className="mb-4 sm:mb-0 mr-0 sm:mr-4 sm:w-1/2 w-full">
          <Input name="firstName" label={firstNameLabel} placeholder="Vorname" inputType="name" />
        </div>
        <div className="sm:w-1/2 w-full">
          <Input name="lastName" label={lastNameLabel} placeholder="Nachname" inputType="name" />
        </div>
      </FormRow>
      <div className="grid grid-cols-3 gap-4 my-4">
        <Input type="datepicker" name="birthday" className="w-full" label="Geburtsdatum *" />
      </div>
    </>
  );
};

export default PersonalData;
