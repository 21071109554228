import { createContext } from "react";
import { useContextSelector } from "use-context-selector";

export interface Keycloak {
  id: string;
  createdTimestamp: number;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  attributes: {
    salesAgentIdIntern: string;
    salesAgentIdExtern: string;
    dashboard: string;
  };
  requiredActions: [];
  roles: {
    id: string;
    name: string;
  }[];
  defaultGroup: {
    id: string;
    name: string;
  };
  otherGroups: {
    id: string;
    name: string;
  }[];
  access: {
    manageGroupMembership: boolean;
    view: boolean;
    mapRoles: boolean;
    impersonate: boolean;
    manage: boolean;
  };
  enabled: boolean;
  emailVerified: boolean;
}

export const keycloakContext = createContext<Keycloak>({} as Keycloak);

export const useKeycloakContext = () => useContextSelector(keycloakContext, (c) => c);
