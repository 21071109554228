import React from "react";

import formatCurrency from "../../../../../utils/formatCurrency";
import formatDecimal from "../../../../../utils/formatDecimal";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import RowItem from "../RowItem";

export function PriceOverview(props) {
  const { periodMonthlyLabel, yearlyInclBonusesLabel, inclBonusesLabel } = useFeaturesResult();
  return (
    <div className="font-bold text-4xl mb-4">
      {props.timeframeYear ? (
        <>
          {formatCurrency(props.isBrutto ? props.gesamtpreisBrutto : props.gesamtpreisNetto)}{" "}
          <span className="text-sm font-normal text-[--get-c-grey-middle]">{yearlyInclBonusesLabel}</span>
        </>
      ) : (
        <>
          {formatCurrency(props.isBrutto ? props.gesamtpreisBrutto / 12 : props.gesamtpreisNetto / 12)}{" "}
          <span className="text-sm font-normal text-[--get-c-grey-middle]">{periodMonthlyLabel} {inclBonusesLabel}</span>
        </>
      )}
    </div>
  );
}

export function PriceDetail(props) {
  const { savingLabel, basepriceLabel, workingpriceLabel, workingpriceunitLabel, showMonthlyPaymentAmount, yourDiscountLabel } =
    useFeaturesResult();
  return (
    <>
      {showMonthlyPaymentAmount && <RowItem label={yourDiscountLabel} value={`${props.discount} €`} />}
      <div className="flex flex-col gap-2">
        <RowItem
          label={savingLabel}
          value={formatCurrency(props.isBrutto ? props.ersparnisBrutto : props.ersparnisNetto)}
        />
        <RowItem
          label={basepriceLabel}
          value={
            props.timeframeYear
              ? formatCurrency(props.isBrutto ? props.grundpreisBrutto : props.grundpreisNetto)
              : formatCurrency(props.isBrutto ? props.grundpreisBrutto / 12 : props.grundpreisNetto / 12)
          }
        />
        <RowItem
          label={workingpriceLabel}
          value={
            formatDecimal(
              props.isBrutto
                ? props.arbeitspreisHTCtKWhBrutto || props.arbeitspreisCtKWhBrutto
                : props.arbeitspreisHTCtKWhNetto || props.arbeitspreisCtKWhNetto
            ) + " " +workingpriceunitLabel
          }
        />
      </div>
    </>
  );
}

const Price = ({
  discount,
  isBrutto,
  timeframeYear,
  gesamtpreisBrutto,
  gesamtpreisNetto,
  ersparnisBrutto,
  ersparnisNetto,
  grundpreisBrutto,
  grundpreisNetto,
  arbeitspreisHTCtKWhBrutto,
  arbeitspreisCtKWhBrutto,
  arbeitspreisHTCtKWhNetto,
  arbeitspreisCtKWhNetto,
}) => {
  return (
    <section id="price" className="mb-5">
      <PriceOverview
        isBrutto={isBrutto}
        timeframeYear={timeframeYear}
        gesamtpreisBrutto={gesamtpreisBrutto}
        gesamtpreisNetto={gesamtpreisNetto}
      />
      <PriceDetail
        discount={discount}
        isBrutto={isBrutto}
        timeframeYear={timeframeYear}
        ersparnisBrutto={ersparnisBrutto}
        ersparnisNetto={ersparnisNetto}
        grundpreisBrutto={grundpreisBrutto}
        grundpreisNetto={grundpreisNetto}
        arbeitspreisHTCtKWhBrutto={arbeitspreisHTCtKWhBrutto}
        arbeitspreisCtKWhBrutto={arbeitspreisCtKWhBrutto}
        arbeitspreisHTCtKWhNetto={arbeitspreisHTCtKWhNetto}
        arbeitspreisCtKWhNetto={arbeitspreisCtKWhNetto}
      />
    </section>
  );
};

export default Price;
