import * as React from "react";

import Monitoring from "../../../../../components/icons/Monitoring";
import Phone from "../../../../../components/icons/Phone";
import Seedling from "../../../../../components/icons/Seedling";
import ShowChart from "../../../../../components/icons/ShowCart";
import useFeaturesResult from "../../../hooks/useFeaturesResult";

const Badges = ({
  isOekostromTarif = false,
  isOekogasTarif = false,
  isDynamischerTarif = false,
  isFlexTarif = false,
  hasPhone = false,
}: {
  isOekostromTarif?: boolean;
  isOekogasTarif?: boolean;
  isDynamischerTarif?: boolean;
  isFlexTarif?: boolean;
  hasPhone?: boolean;
}) => {
  const { ecoTarif, dynamicTariff, flexTariff, phoneTariff } = useFeaturesResult();

  return (
    <div className="flex h-5 mb-4 items-center w-full gap-2 justify-end">
      {isDynamischerTarif && !isFlexTarif && (
        <div className="px-2.5 py-0.5 bg-[#BACCE8] text-[#3966AA] rounded-full inline text-xs">
          <Monitoring />
          <span className="align-bottom">{dynamicTariff}</span>
        </div>
      )}
      {isFlexTarif && (
        <div className="px-2.5 py-0.5 bg-[#BACCE8] text-[#3966AA] rounded-full inline text-xs">
          <ShowChart />
          <span className="align-bottom">{flexTariff}</span>
        </div>
      )}

      {(isOekostromTarif || isOekogasTarif) && (
        <div className="px-2.5 py-0.5 bg-green-100 text-green-700 rounded-full inline text-xs">
          <Seedling />
          <span className="align-bottom">{ecoTarif}</span>
        </div>
      )}

      {hasPhone && (
        <div className="px-2.5 py-0.5 bg-red-100 text-red-700 rounded-full inline text-xs">
          <Phone />
          <span className="align-bottom ml-2">{phoneTariff}</span>
        </div>
      )}
    </div>
  );
};

export default Badges;
