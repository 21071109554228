import React from "react";

import EditIcon from "../../../../components/icons/editIcon";
import Media from "../../../../components/View/Media";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSupplierInfo } from "../../../../context/SupplierInfo";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import formatCurrency from "../../../../utils/formatCurrency";
import formatDecimal from "../../../../utils/formatDecimal";
import useFeaturesResult from "../../hooks/useFeaturesResult";

export default function CompareTariffInfo({
  timeframeYear,
  isBrutto,
  isShowChangeCompareTariff,
}: {
  timeframeYear: boolean;
  isBrutto: boolean;
  isShowChangeCompareTariff: boolean;
}) {
  const values = useSupplierInfo();
  const inputContext = useTRInputContext();
  const { config } = useConfig();

  const { unitPerYear, totalprice, baseprice, workingprice, workingpriceunit } = useFeaturesResult();

  const { HTLabel, NTLabel } = useGeneralLabel();

  const totalPrice = React.useMemo(() => {
    if (inputContext.values.customerType === "1") {
      return values?.supplierInformation.suppliertariffGesamtpreisNetto;
    }

    return isBrutto
      ? values?.supplierInformation?.suppliertariffGesamtpreisBrutto
      : values?.supplierInformation?.suppliertariffGesamtpreisNetto;
  }, [values, isBrutto, inputContext.values.customerType]);

  const basePrice = React.useMemo(() => {
    if (inputContext.values.customerType === "1") {
      return values?.supplierInformation?.suppliertariffGrundpreisNetto;
    }

    return isBrutto
      ? values?.supplierInformation?.suppliertariffGrundpreisBrutto
      : values?.supplierInformation?.suppliertariffGrundpreisNetto;
  }, [values, isBrutto, inputContext.values.customerType]);

  const versorgerName = values?.supplierInformation?.versorgerName;
  const suppliertariffName = values?.supplierInformation?.suppliertariffName;

  const suppliertariffArbeitspreisHTCtKWhBrutto = React.useMemo(() => {
    if (inputContext.values.customerType === "1") {
      return values?.supplierInformation?.suppliertariffArbeitspreisHTCtKWhNetto;
    }

    return isBrutto
      ? values?.supplierInformation?.suppliertariffArbeitspreisHTCtKWhBrutto
      : values?.supplierInformation?.suppliertariffArbeitspreisHTCtKWhNetto;
  }, [values, isBrutto, inputContext.values.customerType]);

  const suppliertariffArbeitspreisNTCtKWhBrutto = React.useMemo(() => {
    if (inputContext.values.customerType === "1") {
      return values?.supplierInformation?.suppliertariffArbeitspreisNTCtKWhNetto;
    }

    return isBrutto
      ? values?.supplierInformation?.suppliertariffArbeitspreisNTCtKWhBrutto
      : values?.supplierInformation?.suppliertariffArbeitspreisNTCtKWhNetto;
  }, [isBrutto, values, inputContext.values.customerType]);

  const workingPrice = (
    <>
      {suppliertariffArbeitspreisHTCtKWhBrutto ? (
        <div>
          <div className="text-gray-dark text-sm">
            {workingprice} {suppliertariffArbeitspreisNTCtKWhBrutto ? HTLabel : ""}
          </div>
          <div className={config?.media !== "desktop" ? "text-sm" : ""}>
            {suppliertariffArbeitspreisHTCtKWhBrutto &&
              formatDecimal(parseFloat(suppliertariffArbeitspreisHTCtKWhBrutto))}
            &nbsp;{workingpriceunit}
          </div>
        </div>
      ) : null}
      {suppliertariffArbeitspreisNTCtKWhBrutto ? (
        <div>
          <div className="text-gray-dark w-28 text-sm">
            {workingprice} {NTLabel}
          </div>
          <div className={config?.media !== "desktop" ? "text-sm" : ""}>
            {suppliertariffArbeitspreisNTCtKWhBrutto &&
              formatDecimal(parseFloat(suppliertariffArbeitspreisNTCtKWhBrutto))}
            &nbsp;{workingpriceunit}
          </div>
        </div>
      ) : null}
    </>
  );

  return (
    <div className="grid h-full">
      <div className="mb-4">
        <Media desktop={true}>
          <div className="text-sm flex justify-between self-end">
            <span>
              Vergleichstarif:
              <span className="font-medium text-base">
                {" "}
                {versorgerName} {versorgerName ? "-" : ""}{" "}
                {suppliertariffName === "Vergleichstarif" && versorgerName === null
                  ? "individuell"
                  : suppliertariffName}
              </span>
            </span>
            {!isShowChangeCompareTariff && <EditIcon />}
          </div>
        </Media>
        <Media tablet={true} mobile={true}>
          <div className="text-sm flex justify-between self-end">
            <span>Vergleichstarif:</span>
            {!isShowChangeCompareTariff && <EditIcon />}
          </div>
          <div className="font-medium text-base">
            {versorgerName} {versorgerName ? "-" : ""}{" "}
            {suppliertariffName === "Vergleichstarif" && versorgerName === null ? "individuell" : suppliertariffName}
          </div>
        </Media>
      </div>
      <section id="price" className="flex justify-between self-end">
        <div>
          <div className="text-gray-dark text-sm">{totalprice}</div>
          <div className={config?.media !== "desktop" ? "text-sm" : ""}>
            {totalPrice &&
              (timeframeYear
                ? formatCurrency(parseFloat(totalPrice)) + unitPerYear
                : formatCurrency(parseFloat(totalPrice) / 12) + "/Monat")}
          </div>
        </div>

        <div>
          <div className="text-gray-dark w-28 text-sm">{baseprice}</div>
          <div className={config?.media !== "desktop" ? "text-sm" : ""}>
            {basePrice &&
              (timeframeYear
                ? formatCurrency(parseFloat(basePrice)) + unitPerYear
                : formatCurrency(parseFloat(basePrice) / 12) + "/Monat")}
          </div>
        </div>
        <Media desktop={true}>{workingPrice}</Media>
      </section>
      <Media tablet={true} mobile={true}>
        <div className="flex justify-between self-end mt-2">{workingPrice}</div>
      </Media>
    </div>
  );
}
