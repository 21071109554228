import { useFormikContext } from "formik";
import React from "react";

import { handleErrKeys } from "../../../utils/handleErrKeys";
import { uniqueArr } from "../../../utils/uniqueArr";
import { useActivatedPanels, useContractConclusionFormName } from "../context";

interface ValidationData {
  timestamp: string;
  data: {
    ok: boolean;
    fehler: { id: number; meldung: string; schluessel: string; grund: string }[];
    pflichtfelder: string[];
  };
  additionalData: {
    usedDomainConfig: string;
    usedCrmConfigPath: string;
    usedSupplierId: string;
  };
}

const useCheckFormValidationAndRedirect = (data: ValidationData, nextStep: string) => {
  const { setFieldError } = useFormikContext();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();

  React.useEffect(() => {
    checkFormValidationAndRedirect(data, nextStep, setActivatedPanels, setFieldError, setFormName);
  }, [data, nextStep, setActivatedPanels, setFieldError, setFormName]);
};

export const checkFormValidationAndRedirect = (
  data: ValidationData,
  nextStep: string,
  setActivatedPanels: React.Dispatch<React.SetStateAction<string[]>>,
  setFieldError: (field: string, message: string | undefined) => void,
  setFormName: React.Dispatch<React.SetStateAction<string>>
) => {
  if (data) {
    if (!data.data?.ok && data.data?.fehler.length >= 1) {
      data.data.fehler.forEach((err: { schluessel: string; grund: string }) => {
        const keys = handleErrKeys(err.schluessel);
        if (keys) {
          setFieldError(keys, err.grund);
        }
      });
    } else {
      setFormName(nextStep);
      setActivatedPanels((prevState) => uniqueArr([...prevState, nextStep]));
    }
  }
};

export default useCheckFormValidationAndRedirect;
