import { ArcElement, Chart as ChartJS, Tooltip } from "chart.js";
import { useField } from "formik";
import React, { useRef } from "react";

import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import Divider from "../../../../../components/Divider";
import Chevron from "../../../../../components/icons/Chevron";
import Modal from "../../../../../components/Modal";
import { useTariff } from "../../../../../context";
import { useConfig } from "../../../../../context/Config";
import { useSupplierInfo } from "../../../../../context/SupplierInfo";
import useResponsiveClass from "../../../../../hooks/useResponsiveClass";
import useTarifCart from "../../../../../hooks/useTarifCart";
import useTarifCartGas from "../../../../../hooks/useTarifCartGas";
import useVPPCustomerService from "../../../../../hooks/useVPPCustomerService";
import Tariff from "../../../../../types/Tariff";
import formatValidDate from "../../../../../utils/formatValidDate";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import { ItemAttributes } from "../../../types/ItemAttributes";
import Badges from "./Badges";
import Bonus from "./Bonus";
import NameAndTariff from "./NameAndTariff";
import Price from "./Price";
import PriceGuarantee from "./PriceGuarantee";
import TariffDetails from "./TariffDetails";

ChartJS.register(ArcElement, Tooltip);

const Item = ({
  gueltigVon,
  versorgerName,
  arbeitspreisBrutto,
  arbeitspreisCtKWhBrutto,
  arbeitspreisCtKWhNetto,
  arbeitspreisHTCtKWhNetto,
  arbeitspreisHTCtKWhBrutto,
  arbeitspreisNetto,
  aufschlaege,
  boni,
  dynamischeAngaben,
  ersparnisBrutto,
  ersparnisNetto,
  productDescription,
  informationen,
  gesamtpreisBrutto,
  gesamtpreisNetto,
  gesamtpreisOhneBoniBrutto,
  gesamtpreisOhneBoniNetto,
  isGrundversorgungsTarif,
  isOekostromTarif,
  isPaketTarif,
  markenID,
  vertragsverlaengerungID,
  tarifCode,
  tarifInfo,
  tarifName,
  vertragslaufzeitID,
  versorgerID,
  vertragslaufzeit,
  tarifID,
  kuendigungsfrist,
  isVorkasseTarif,
  kuendigungsfristID,
  isVergleichsTarif,
  vertragsverlaengerung,
  variantenID,
  isOekogasTarif,
  tarifRootID,
  grundpreisNetto,
  grundpreisBrutto,
  arbeitspreisNTCtKWhBrutto,
  arbeitspreisNTCtKWhNetto,
  handleChooseTariff,
  strommixLight,
  timeframeYear,
  lastRow,
}: ItemAttributes & {
  handleChooseTariff: (itemAttributes: Tariff) => void;
}) => {
  const [{ value: priceType }] = useField("priceType");
  const [{ value: customerType }] = useField("customerType");
  const ref = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const { supplierInformation } = useSupplierInfo();
  const [showTariffDetails, setShowTariffDetails] = React.useState(false);
  const [contentHeight, setContentHeight] = React.useState(cardRef?.current?.offsetHeight ?? 0);
  const { config } = useConfig();
  const { responsiveClass } = useResponsiveClass();
  const { showTariffDetail, orderNow, pricesInclTax, pricesExclTax, pricesNetGross, showNetGross } =
    useFeaturesResult();
  const isBrutto = React.useMemo(() => {
    if (showNetGross) {
      return priceType === "0";
    }
    if (pricesNetGross === "net") {
      return false;
    }
    if (pricesNetGross === "gross") {
      return true;
    }
    return customerType === "0";
  }, [priceType, customerType, pricesNetGross, showNetGross]);

  const { data: vppData } = useVPPCustomerService();

  const handlingTariff = React.useMemo(() => {
    return dynamischeAngaben
      .find((v) => v.stringObj)
      ?.stringObj?.find((d) => d.name === "handling" && d.wert === "true");
  }, [dynamischeAngaben]);

  const monthlyPrices = React.useMemo(() => {
    const res = dynamischeAngaben?.find((d) => d?.doubleObj?.find((n) => n.name === "grundpreisMonatNetto"));
    if (!res || !res.doubleObj) {
      return {};
    }
    return Object.assign(
      {},
      ...res.doubleObj.map((el) => {
        return { [el.name]: el.wert };
      })
    );
  }, [dynamischeAngaben]);

  const associationNumberFromVPP = React.useMemo(() => {
    try {
      if (vppData) {
        if (vppData?.data.object?.person.templateData[0].data.orderdetails_gas) {
          return vppData?.data.object?.person.templateData[0].data.orderdetails_gas.netzbetreiber.verbandsnummer;
        }

        if (vppData?.data.object?.person.templateData[0].data.orderdetails_electricity) {
          return vppData?.data.object?.person.templateData[0].data.orderdetails_electricity.netzbetreiber
            .verbandsnummer;
        }
      }
    } catch (e) {
      console.error("Fehler beim laden der Verbandsnummer bei VPP Kunden");
    }
    return "";
  }, [vppData]);

  const tariff = useTariff();
  const { data, refetch } = useTarifCart(tarifRootID, variantenID);
  const { data: dataGas, refetch: refetchGas } = useTarifCartGas(tarifRootID, variantenID);

  const tableBody = React.useMemo(
    () =>
      tariff.tariffType === "gas"
        ? dataGas?.data?.tarifkarten[0]?.tarifkarte[0]?.preisStaffel
        : data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[0]?.preisStaffeln.map((item: any, index: number) => {
            return {
              ...item,
              arbeitspreisNTNetto:
                data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[1]?.preisStaffeln[index]?.arbeitspreisNetto ?? 0,
              arbeitspreisNTBrutto:
                data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[1]?.preisStaffeln[index]?.arbeitspreisBrutto ?? 0,
            };
          }),
    [data?.data?.tarifkarten, dataGas?.data?.tarifkarten, tariff.tariffType]
  );

  const handleChange = () => {
    if (cardRef?.current) {
      setShowTariffDetails((prevState) => !prevState);
    }

    if (!showTariffDetails) {
      tariff.tariffType === "gas" ? refetchGas() : refetch();
    }
  };
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentRect.height !== 0) setContentHeight(entry.contentRect.height);
      }
    });
    if (cardRef?.current) {
      resizeObserver.observe(cardRef?.current);
    }
  });

  const priceguarantee = informationen?.find((el) => el.bedingung === "Preisgarantie");
  const BAI_BEDINGUNGID_FLEXTARIF = 209;
  const BAI_BEDINGUNGID_DYNTARIF = 208;

  return (
    <>
      <div className="grid" ref={ref}>
        <Modal
          visible={showTariffDetails}
          setVisible={setShowTariffDetails}
          showChildrenDefault={true}
          position={{
            left: ref?.current?.offsetLeft ?? 200,
            right: ref?.current?.offsetTop ?? 200,
            width: ref?.current?.offsetWidth ?? 400,
          }}
        >
          <Card key={tarifID}>
            <div ref={cardRef} className={`grid h-full`}>
              <div>
                <div className="mb-6">
                  <Badges
                    isOekogasTarif={isOekogasTarif}
                    isOekostromTarif={isOekostromTarif}
                    isDynamischerTarif={informationen?.find((el) => {
                      return el.bedingungID === BAI_BEDINGUNGID_DYNTARIF;
                    })}
                    isFlexTarif={informationen?.find((el) => {
                      return el.bedingungID === BAI_BEDINGUNGID_FLEXTARIF;
                    })}
                  />
                  <NameAndTariff versorgerName={versorgerName} tarifName={tarifName} className="text-center mb-4" />

                  <Price
                    isBrutto={isBrutto}
                    timeframeYear={timeframeYear}
                    gesamtpreisBrutto={gesamtpreisBrutto}
                    gesamtpreisNetto={gesamtpreisNetto}
                    ersparnisBrutto={ersparnisBrutto}
                    ersparnisNetto={ersparnisNetto}
                    grundpreisBrutto={grundpreisBrutto}
                    grundpreisNetto={grundpreisNetto}
                    arbeitspreisHTCtKWhBrutto={arbeitspreisHTCtKWhBrutto}
                    arbeitspreisNTCtKWhBrutto={arbeitspreisNTCtKWhBrutto}
                    arbeitspreisCtKWhBrutto={arbeitspreisCtKWhBrutto}
                    arbeitspreisHTCtKWhNetto={arbeitspreisHTCtKWhNetto}
                    arbeitspreisNTCtKWhNetto={arbeitspreisNTCtKWhNetto}
                    arbeitspreisCtKWhNetto={arbeitspreisCtKWhNetto}
                    gesamtpreisMonatBrutto={monthlyPrices.gesamtpreisMonatBrutto}
                    gesamtpreisMonatNetto={monthlyPrices.gesamtpreisMonatNetto}
                    grundpreisMonatBrutto={monthlyPrices.grundpreisMonatBrutto}
                    grundpreisMonatNetto={monthlyPrices.grundpreisMonatNetto}
                    dynamischeAngaben={dynamischeAngaben}
                  />

                  {boni?.length >= 1 && <Bonus boni={boni} isBrutto={isBrutto} />}

                  {aufschlaege?.length >= 1 && <Bonus boni={aufschlaege} isBrutto={isBrutto} />}

                  <PriceGuarantee
                    priceguarantee={priceguarantee ? priceguarantee.preisgarantieFuerMonate : undefined}
                    priceguaranteeDate={priceguarantee ? priceguarantee.preisgarantieBis : undefined}
                    vertragslaufzeit={vertragslaufzeit}
                    priceguaranteeComponents={priceguarantee ? priceguarantee.preisgarantieBestandteile : undefined}
                  />
                </div>
                <Divider />

                <div className="flex justify-between mt-6">
                  <Button onClick={handleChange} variant="unstyled" className="w-full px-0">
                    <div className={responsiveClass("flex items-center justify-between", "text-sm", "text-sm")}>
                      {showTariffDetail}
                      <Chevron rotate={showTariffDetails} />
                    </div>
                  </Button>
                </div>
              </div>

              {showTariffDetails && (
                <TariffDetails
                  tarifInfo={tarifInfo}
                  strommixLight={strommixLight}
                  tableBody={tableBody}
                  arbeitspreisNTCtKWhBrutto={arbeitspreisNTCtKWhBrutto}
                  arbeitspreisNTCtKWhNetto={arbeitspreisNTCtKWhNetto}
                  isBrutto={isBrutto}
                  kuendigungsfrist={kuendigungsfrist}
                  vertragsverlaengerung={vertragsverlaengerung}
                />
              )}
              <div className="self-end">
                {!!handlingTariff && (
                  <div className="mt-6 text-center">
                    <Button
                      className="p-2 w-[200px]"
                      onClick={() => {
                        handleChooseTariff({
                          isBrutto,
                          strommixLight,
                          priceType,
                          arbeitspreisCtKWhBrutto,
                          arbeitspreisCtKWhNetto,
                          grundpreisBrutto,
                          gesamtpreisBrutto,
                          arbeitspreisBrutto,
                          arbeitspreisHTCtKWhBrutto,
                          arbeitspreisNetto,
                          arbeitspreisNTCtKWhBrutto,
                          arbeitspreisNTCtKWhNetto,
                          ersparnisBrutto,
                          ersparnisNetto,
                          arbeitspreisHTCtKWhNetto,
                          gesamtpreisNetto,
                          gesamtpreisOhneBoniBrutto,
                          gesamtpreisOhneBoniNetto,
                          grundpreisNetto,
                          gridConnectionType: 0,
                          connectionTypes: [],
                          contractPeriodType: 0,
                          contractPeriodMonths: 0,
                          noticePeriodMonths: 0,
                          contractExtensionType: 0,
                          contractExtensionMonths: 0,
                          rootId: 0,
                          productName: "",
                          productTypeId: 0,
                          productTypeName: "",
                          companyId: 0,
                          companyName: "",
                          providerId: 0,
                          providerName: "",
                          productCode: "0",
                          customerGroupPrivate: false,
                          customerGroupCommercial: false,
                          customerGroupYoungPeople: false,
                          basicPrice: 0,
                          installCost: 0,
                          landlineDataVolumeFlat: false,
                          landlineDataVolumeLimit: 0,
                          landlineInternetDownloadSpeed: 0,
                          landlineInternetDownloadSpeedMinimal: 0,
                          landlineInternetDownloadSpeedNormal: 0,
                          landlineInternetUploadSpeed: 0,
                          landlineInternetUploadSpeedMinimal: 0,
                          landlineInternetUploadSpeedNormal: 0,
                          landlineTelephone: false,
                          landlineTelephoneCostMinutes: 0,
                          landlineTelephoneFlatLandline: false,
                          landlineTelephoneNumbers: 0,
                          setupCost: 0,
                          shippingCost: 0,
                          shippingCosts: 0,
                          verbandsnummer:
                            associationNumberFromVPP || supplierInformation?.marktrollen?.[0]?.verbandsnummer || "",
                          aufschlaege: aufschlaege || [],
                          productDescription: productDescription || "",
                          gueltigVon: typeof gueltigVon === "string" ? gueltigVon : formatValidDate(gueltigVon),
                          gueltigBis: "",
                          boni,
                          dynamischeAngaben,
                          informationen:
                            informationen?.map((value) => {
                              return {
                                ...value,
                                preisgarantieBis: value.preisgarantieBis,
                                gueltigVon: formatValidDate(value.gueltigVon),
                                gueltigBis: formatValidDate(value.gueltigBis),
                              };
                            }) || [],
                          isGrundversorgungsTarif,
                          isOekostromTarif,
                          isOekogasTarif,
                          isPaketTarif,
                          isVergleichsTarif,
                          isVorkasseTarif,
                          kuendigungsfrist,
                          kuendigungsfristID,
                          markenID,
                          tarifCode: tarifCode || "",
                          tarifID,
                          tarifInfo: tarifInfo ?? "",
                          tarifName,
                          tarifRootID,
                          variantenID: variantenID ?? "",
                          versorgerID,
                          versorgerName,
                          vertragslaufzeit,
                          vertragslaufzeitID,
                          vertragsverlaengerung,
                          vertragsverlaengerungID,
                        });
                      }}
                      variant="primary"
                      id={"item" + tarifRootID}
                    >
                      {orderNow}
                    </Button>
                  </div>
                )}
                <div className="my-2 text-xs text-center text-gray-dark">
                  {isBrutto ? pricesInclTax : pricesExclTax}
                </div>
              </div>
            </div>
          </Card>
        </Modal>
      </div>
      {lastRow && showTariffDetails && (
        <>
          <div className="order-3 h-0"></div>
          <div className="order-3 h-0"></div>
          <div
            className="order-3 invisible"
            style={{
              height:
                ref?.current?.offsetHeight && contentHeight
                  ? contentHeight - ref?.current?.offsetHeight
                  : contentHeight,
            }}
          >
            {showTariffDetails && (
              <TariffDetails
                tarifInfo={tarifInfo}
                strommixLight={strommixLight}
                tableBody={tableBody}
                arbeitspreisNTCtKWhBrutto={arbeitspreisNTCtKWhBrutto}
                arbeitspreisNTCtKWhNetto={arbeitspreisNTCtKWhNetto}
                isBrutto={isBrutto}
                kuendigungsfrist={kuendigungsfrist}
                vertragsverlaengerung={vertragsverlaengerung}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Item;
