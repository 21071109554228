function createPDFComponentData(value) {
  const obj = {};

  for (let i = 0; i < value.length; i++) {
    obj[`PDF_Preiskomponente${i + 1}_Name`] = value[i].refName;
    obj[`PDF_Preiskomponente${i + 1}_Zyklus`] = value[i].priceOneTime
      ? "einmalig"
      : "monatlich";
    obj[`PDF_Preiskomponente${i + 1}_Price`] = value[i].priceOneTime
      ? value[i].priceOneTime
      : value[i].monthlyPrices[0].pricePerMonth || 0;
  }

  return obj;
}

export default createPDFComponentData;
