import ReactToast from "react-hot-toast";

const toast = {
  error: (message, options) =>
    ReactToast.error(message, {
      ...options,
      position: "bottom-right",
      icon: null,
      style: { background: "#ef4444", color: "white", fontFamily: "Roboto" },
    }),
  success: (message, options) =>
    ReactToast.success(message, {
      ...options,
      icon: null,
      style: { background: "#5cd360", color: "white", fontFamily: "Roboto" },
    }),
};

export default toast;
