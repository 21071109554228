import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import useFieldValidation from "../../hooks/useFieldValidation";
import FormData from "../AddressData/FormData";
import BankDataForm from "../BankData/BankDataForm";
import PaymentOptions from "../BankData/PaymentOptions";
import Contact from "../ContactData/Contact";
import PersonalData from "../ContactData/PersonalData";
import PreSupplierData from "../PreSupplierData";

const EditDataModal = ({
  modalVisible,
  setModalVisible,
}: {
  modalVisible: string | false;
  setModalVisible: React.Dispatch<React.SetStateAction<string | false>>;
}) => {
  const { resetForm, submitForm } = useFormikContext();

  const disabled = useFieldValidation(typeof modalVisible === "string" ? modalVisible : "");
  return (
    <Card>
      {modalVisible === "contactForm" && (
        <>
          <PersonalData />
          <Contact />
        </>
      )}
      {modalVisible === "deliveryAddress" && <FormData type="deliveryAddress" />}
      {modalVisible === "billingAddress" && (
        <div className="mb-4">
          <FormData type="billingAddress" />
        </div>
      )}
      {modalVisible === "preSupplierForm" && <PreSupplierData disableFooterButtons={true} />}
      {modalVisible === "bankForm" && (
        <>
          <PaymentOptions />
          <BankDataForm />
        </>
      )}
      <div className="flex justify-end gap-y-2 gap-x-4 flex-wrap mt-4">
        <Button
          onClick={() => {
            setModalVisible(false);
            resetForm();
          }}
          variant="secondary"
        >
          Abbrechen
        </Button>
        <Button
          type="submit"
          onClick={() => {
            setModalVisible(false);
            submitForm();
          }}
          disabled={!disabled}
          variant="primary"
        >
          Speichern
        </Button>
      </div>
    </Card>
  );
};
export default EditDataModal;
