import { useField, useFormikContext } from "formik";
import React from "react";

import Checkbox from "../../../../../components/Checkbox";
import Divider from "../../../../../components/Divider";
import Input from "../../../../../components/Input";
import useGeneralLabel from "../../../../../hooks/useGeneralLabel";
import { useContractConclusionFormName } from "../../../context";
import { useMandatoryfields } from "../../../context/Mandatoryfields";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import useGetPolicyDocument from "../../../hooks/useGetPolicyDocument";
import DigitalSignature from "../../DigitalSignature";
import FooterButtons from "../../FooterButtons";
import Headline from "../../Headline";
import PrePrint from "../PrePrint";
import AdvertisingConsent from "./AdvertisingConsent";
import Consent from "./Consent";
import SurveyConsent from "./SurveyConsent";

const PREV_STEP = "bankForm";

const OptIns = () => {
  const { setFormName } = useContractConclusionFormName();
  const { submitForm } = useFormikContext();
  const [{ value: isRemoteSignature }] = useField("isRemoteSignature");
  const { setFieldValue } = useFormikContext();
  const { data } = useGetPolicyDocument();
  const {
    prePrint,
    customersRecruitment,
    digitalSignature,
    survey,
    isAdvertisingConsent,
    customersRecruitmentText,
    telesalesSignature,
    remoteSignatureLabel,
    counsellingProtocol,
    digitalSignatureBerater,
    digitalSignatureBeraterSubHeader,
    werberIdentnummer,
    digitalSignatureCustomer,
  } = useFeaturesCC();
  const { mandatoryLabel } = useGeneralLabel();
  const mandatoryFieldsCRM = useMandatoryfields();
  const [fields] = useField("asOffer");

  React.useEffect(() => {
    if (data?.data) {
      const attachment = Object.values(data.data)
        .map((url) => typeof url === "string" && url)
        .filter(Boolean);

      setFieldValue("attachment", attachment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className="grid grid-cols-1 gap-6">
      <Divider />
      <Consent />
      {isAdvertisingConsent && (
        <>
          <Divider />
          <AdvertisingConsent />
        </>
      )}
      {survey && (
        <>
          <Divider />
          <SurveyConsent />
        </>
      )}
      {customersRecruitment && (
        <>
          <Divider />
          <Headline>{customersRecruitmentText}</Headline>
          <div>
            <div className="text-sm text-gray-dark">
              Geben Sie bitte hier die Kundennummer des werbenden Kunden ein:
            </div>
          </div>
          <Input
            name="customerRecruitment"
            placeholder={werberIdentnummer}
            label={
              werberIdentnummer + (!fields?.value && mandatoryFieldsCRM?.includes("sepa_erteilt") ? mandatoryLabel : "")
            }
          />
        </>
      )}
      {digitalSignature && (
        <>
          <Divider />
          <div>
            <Headline className="mb-2">Unterschrift</Headline>
            {telesalesSignature && (
              <Checkbox name="isRemoteSignature" labelComponent={remoteSignatureLabel} className="mb-4" />
            )}
            {(!telesalesSignature || !isRemoteSignature === true) && (
              <DigitalSignature
                name="signature"
                label={digitalSignatureCustomer + (!fields.value ? mandatoryLabel : "")}
              />
            )}
          </div>
          {counsellingProtocol && isRemoteSignature === false && (
            <>
              <Divider />
              <div>
                <Headline>{digitalSignatureBerater}</Headline>
                <div className="text-sm mb-2"> {digitalSignatureBeraterSubHeader}</div>
                <DigitalSignature
                  name="signatureBerater"
                  label={digitalSignatureCustomer + (!fields.value ? mandatoryLabel : "")}
                />
              </div>
            </>
          )}
        </>
      )}
      {prePrint && (
        <>
          <Divider />
          <PrePrint />
        </>
      )}
      <Divider />
      {telesalesSignature && isRemoteSignature === true ? (
        <div className="text-sm">
          Durch die Betätigung der Schaltfläche "Angebot zur Unterschrift" beantrage ich die Versorgung der oben
          genannten Abnahmestelle zu dem genannten oder zum nächstmöglichen Termin. Im Anschluss wird automatisch eine
          E-Mail als Auftragseingangsbestätigung an Ihre angegebene E-Mail-Adresse gesendet.
        </div>
      ) : (
        <div className="text-sm">
          Durch die Betätigung der Schaltfläche "Zahlungspflichtig bestellen" beantrage ich die Versorgung der oben
          genannten Abnahmestelle zu dem genannten oder zum nächstmöglichen Termin. Im Anschluss wird automatisch eine
          E-Mail als Auftragseingangsbestätigung an Ihre angegebene E-Mail-Adresse gesendet.
        </div>
      )}
      <FooterButtons
        onSubmitValidation={() => {}}
        onSubmit={() => submitForm()}
        onBack={() => setFormName(PREV_STEP)}
      />
    </div>
  );
};

export default OptIns;
