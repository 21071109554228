import {addMonths} from "date-fns";


const validCancellationDate =(value: string,min:number,max:number)=> {


    function isMinCancellationDate(){

        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addMonths(today, min);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const movinInDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return movinInDate >= minDate;
        }
        return true
    }
    function isMaxCancellationDate(){
        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addMonths(today, max);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const movinInDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return movinInDate <= minDate;
        }
        return true
    }

    function minCancellationDate(){
        const today = new Date( new Date().setHours(0,0,0,0));

        return  addMonths(today, min)
    }
    function maxCancellationDate(){
        const today = new Date( new Date().setHours(0,0,0,0));
        return  addMonths(today, max)

    }

    return {isMinCancellationDate,isMaxCancellationDate,minCancellationDate,maxCancellationDate}
}

export default validCancellationDate