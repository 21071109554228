import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import toast from "../../../components/Toast";
import { useConfig, useTariff } from "../../../context";
import { useCalcParams } from "../../../context/CalculationParameter";
import { useCalcResult } from "../../../context/CalculationResult";
import { useGridConnectionStatus } from "../../../context/GridConnectionStatus";
import { useSupplierInfo } from "../../../context/SupplierInfo";
import useDebounce from "../../../hooks/useDebounce";
import ProxyInstance from "../../../services/proxy";
import resolveSuggestion from "../../../utils/resolveSuggestion";
import uniqueArrObj from "../../../utils/uniqueArrObj";
import handleError from "../utils/handleError";
import useFeatureInputPage from "./useFeatureInputPage";
import useQueryParameters from "./useQueryParameters";

const useSimplifier = () => {
  const [cityOptionsList, setCityOptionsList] = useState([]);
  const [streetOptionsList, setStreetOptionsList] = useState([]);
  const [houseNumberList, setHouseNumberList] = useState([]);
  const { setCalcParams } = useCalcParams();
  const { tariffNotFoundLabel } = useFeatureInputPage();
  const { values, setFieldValue } = useFormikContext();
  const { setGridConnectionStatus } = useGridConnectionStatus();
  const config = useConfig();
  const tariff = useTariff();
  const queryParameter = useQueryParameters();
  const { setCalcResult } = useCalcResult();
  const { setSupplierInformation } = useSupplierInfo();

  const debounceHouseNumber = useDebounce(
    values.houseNumber,
    values.houseNumber.length > 1 ? 900 : 300
  );

  useEffect(() => {
    if (values.meterType === "1,2") {
      setFieldValue("twoTariff", "0");
    }
  }, [setFieldValue, values.meterType]);

  const baseUrl = useMemo(() => {
    return tariff.id === 7
      ? `/prima/calculation/telco/tr/v1/calculate?${queryParameter}`
      : `/simplifier/calc/${tariff.tariffType}/?${queryParameter}`;
  }, [queryParameter, tariff.id, tariff.tariffType]);

  return useQuery(
    [
      `GET_SIMPLIFIER_${tariff.id}`,
      values.meterType,
      values.zipCode,
      values.city,
      values.street,
      debounceHouseNumber,
      values.oneTariff,
      values.twoTariff,
      values.customerType,
    ],
    async () => {
      try {
        const { data } = await ProxyInstance(baseUrl, {
          apikey: config.apikey || "",
        });

        if (data?.error) {
          throw new Error("Bitte füllen Sie die Felder aus");
        }

        if (data?.data?.data?.gridConnectionStatus) {
          setGridConnectionStatus(data?.data?.data?.gridConnectionStatus);
        }

        if (data?.data?.data?.calculationParameter) {
          setCalcParams({...data?.data?.data?.calculationParameter, dynamischeAngaben: data?.data?.data?.calculationParameter?? []});
        }

        const response = data?.data?.data;
        const resolveSuggestLocation = resolveSuggestion(
          response.suggestLokation
        );

        const suggestions = response?.suggestLokation?.map((cityName) => ({
          value: cityName,
          label: cityName,
        }));

        const cityOptions =
          response.suggestLocationType === "CITY"
            ? suggestions.length > 1
              ? uniqueArrObj([...resolveSuggestLocation, ...suggestions])
              : suggestions
            : null;
        const streetOptions =
          response.suggestLocationType === "STREET" ? suggestions : null;
        const houseNumberOptions =
          response.suggestLocationType === "HNR"
            ? suggestions.sort((a, b) =>
                a.label.localeCompare(b.label, "de", { numeric: true })
              )
            : null;

        if (response) {
          if(response.unique){
            setCalcResult({results: response?.calculationResult?.ergebnisContainer?.[0]
              ?.ergebnis,
            type: tariff.id !== 7? "energie": "telco"})
            setSupplierInfo(response, setSupplierInformation, values.compareType);
          }
          if (cityOptions) {
            await setFieldValue("city", "");
            setCityOptionsList(cityOptions);

            return {
              zipCode: values.zipCode,
              cityOptions,
              unique: response.unique,
            };
          }

          if (streetOptions) {
            await setFieldValue("street", "");
            setStreetOptionsList(streetOptions);

            return {
              zipCode: values.zipCode,
              city: values.city?.value,
              cityOptions: cityOptionsList,
              streetOptions,
              unique: response.unique,
              networkOperator:
                data?.data?.data?.calculationParameter?.netzbetreiber,
              baseSupplier:
                data?.data?.data?.calculationParameter?.grundversorger,
            };
          }

          if (houseNumberOptions) {
            await setFieldValue("houseNumber", "");
            setHouseNumberList(houseNumberOptions);

            return {
              zipCode: values.zipCode,
              city: values.city?.value,
              cityOptions: cityOptionsList,
              street: values.street?.value,
              streetOptions: streetOptionsList,
              houseNumberOptions,
              unique: response.unique,
              networkOperator:
                data?.data?.data?.calculationParameter?.netzbetreiber,
              baseSupplier:
                data?.data?.data?.calculationParameter?.grundversorger,
            };
          }

          return {
            zipCode: values.zipCode,
            city: values.city?.value,
            cityOptions: cityOptionsList,
            street: values.street?.value,
            ...(values.street?.value
              ? { streetOptions: streetOptionsList }
              : {}),
            ...(values.houseNumber
              ? { houseNumber: values.houseNumber?.values }
              : {}),
            ...(values.houseNumber
              ? { houseNumberOptions: houseNumberList }
              : {}),
            unique: response.unique,
          };
        }
      } catch (e) {
        if (e instanceof Error) {
          e.message === "Error getting data"
            ? toast.error(tariffNotFoundLabel)
            : null;
          throw e;
        }
      }
    },
    {
      enabled: !!config.apikey && values.zipCode.length === 5,
      refetchOnWindowFocus: false,
      retryDelay: 5,
      onError: handleError,
    }
  );
};



export const setSupplierInfo = (response, setSupplierInformation, compareType) => {
  const vergleichsData = response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis.filter(
    (supplier) => {
      return supplier.isVergleichsTarif === true;
    }
  );

  const supplierInfo =
    compareType === 1
    ? {"compareType": "individueller Vergleichstarif"}
    : response?.calculationResult.ergebnisContainer?.[0].versorgerInformation?.[0];

  const suppliertariffName = vergleichsData[0]?.tarifName;

  const suppliertariffGesamtpreisBrutto = vergleichsData[0]?.gesamtpreisBrutto;

  const suppliertariffGrundpreisBrutto = vergleichsData[0]?.grundpreisBrutto;

  const suppliertariffArbeitspreisHTCtKWhBrutto = vergleichsData[0]?.arbeitspreisHTCtKWhBrutto;

  const suppliertariffArbeitspreisNTCtKWhBrutto = vergleichsData[0]?.arbeitspreisNTCtKWhBrutto;

  const versorgerName = vergleichsData[0]?.versorgerName;

  if (supplierInfo) {
    setSupplierInformation({
      ...supplierInfo,
      versorgerName,
      suppliertariffName,
      suppliertariffGesamtpreisBrutto,
      suppliertariffGrundpreisBrutto,
      suppliertariffArbeitspreisHTCtKWhBrutto,
      suppliertariffArbeitspreisNTCtKWhBrutto,
    });
  }
}

export default useSimplifier;