import React from "react";

import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import { useConfig, useStepChange } from "../../../../context";
import { useSelectTariff } from "../../../../context/Tariff";
import { useSelectTelco } from "../../../../context/Telco";
import { useContractConclusionFormName } from "../../context";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const WarningDialog = ({ modalIsOpen, setModalIsOpen }) => {
  const { alertLabel, returnToTarifBtnTextLabel, dialogTextLabel } =
    useFeaturesCC();
  const config = useConfig();
  const { setStep } = useStepChange();
  const { setSelectedTelco } = useSelectTelco();
  const { setSelectTariff } = useSelectTariff();
  const { setFormName } = useContractConclusionFormName();

  return (
    <Modal showDialog={modalIsOpen} ariaLabel="Hinweis">
      <div className="p-6">
        <div className="font-bold">{alertLabel}</div>
        <div className="my-4">{dialogTextLabel}</div>
        <div
          className={`flex justify-between items-center ${
            config.media === "mobile" ? "flex-col gap-y-2" : ""
          }`}
        >
          <Button
            className={`p-2 ${config.media === "mobile" ? "w-full" : ""}`}
            variant="primary"
            onClick={() => setModalIsOpen(false)}
          >
            Mit Dateieingabe fortfahren
          </Button>

          <div className="grid">
            <Button
              className={`p-2 ${config.media === "mobile" ? "w-full" : ""}`}
              variant="primary"
              onClick={() => {
                setStep(2);
                setFormName('contactForm');
                setSelectedTelco(null);
                setSelectTariff(null);
                setModalIsOpen(false);
              }}
            >
              {returnToTarifBtnTextLabel}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WarningDialog;
