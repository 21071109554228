import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const ExternLocationIdContext = createContext(null);
const ExternLocationIdProvider = ({ children }) => {
  const [externLocationId, setExternLocationId] = React.useState("");

  return (
    <ExternLocationIdContext.Provider
      value={{ externLocationId, setExternLocationId }}
    >
      {children}
    </ExternLocationIdContext.Provider>
  );
};

export default ExternLocationIdProvider;
export const useExternLocationId = () =>
  useContextSelector(ExternLocationIdContext, (c) => c);
