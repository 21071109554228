import React from "react";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Modal from "../../../../components/Modal";
import { useStepChange, useTariff } from "../../../../context";
import { useSelectTariff } from "../../../../context/Tariff";
import { Telco, useSelectTelco } from "../../../../context/Telco";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import Tariff from "../../../../types/Tariff";
import { useContractConclusionFormName } from "../../context";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const WarningDialog = ({
  modalIsOpen,
  setModalIsOpen,
  alternativeStep,
}: {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  alternativeStep?: number;
}) => {
  const { alert, returnToTarifBtnText, returnToSearchBtnText, dialogText, continue: continueLabel } = useFeaturesCC();
  const { setStep } = useStepChange();
  const { setSelectedTelco } = useSelectTelco();
  const { setSelectTariff } = useSelectTariff();
  const { setFormName } = useContractConclusionFormName();
  const { responsiveClass } = useResponsiveClass();
  const tariff = useTariff();

  return (
    <Modal visible={modalIsOpen} setVisible={setModalIsOpen} showCancelIcon={false} className="!max-w-screen-sm">
      <Card className="!rounded-xl text-left">
        <div className="font-medium">{alert}</div>
        <div className="mb-6 text-gray-dark">{dialogText}</div>
        <div className={responsiveClass("flex gap-y-2 gap-x-4 justify-end flex-wrap")}>
          <Button
            variant="secondary"
            onClick={() => {
              setStep(alternativeStep ?? 2);
              setFormName(tariff.id === "7" ? "options" : "contactForm");
              setSelectedTelco({} as Telco);
              setSelectTariff({} as Tariff);
              setModalIsOpen(false);
            }}
          >
            {alternativeStep === 1 ? returnToSearchBtnText : returnToTarifBtnText}
          </Button>
          <Button variant="primary" onClick={() => setModalIsOpen(false)}>
            {continueLabel}
          </Button>
        </div>
      </Card>
    </Modal>
  );
};

export default WarningDialog;
