import React from "react";

import { useTariff } from "../../context";
import { useConfig } from "../../context/Config";
import WarningDialog from "../../widgets/TCContractConclusion/components/WarningDialog";
import { useContractConclusionFormName } from "../../widgets/TCContractConclusion/context";
import useFeaturesCC from "../../widgets/TCContractConclusion/hooks/useFeaturesCC";
import Calculator from "../icons/Calculator";
import Cart from "../icons/Cart";
import EditDocument from "../icons/EditDocument";
import Euro from "../icons/Euro";
import Gee from "../icons/Gee";
import House from "../icons/House";
import OptionIcon from "../icons/Options";
import Person from "../icons/Person";
import Results from "../icons/Results";
import Step from "./Step";

const Breadcrumb = ({ step }: { step: number }) => {
  const { config } = useConfig();
  const { setFormName } = useContractConclusionFormName();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [stepID, setStepID] = React.useState(1);
  const tariff = useTariff();
  const { contactTitle, preSupplier, bankTitle, addressData, summary } = useFeaturesCC();

  const breadcrumbData = [
    {
      step: 1,
      text: "Suche",
      handleClick: () => {
        setModalIsOpen(true);
        setStepID(1);
      },
      icon: (fill: string) => <Calculator fill={fill} />,
    },
    {
      step: 2,
      text: "Ergebnisse",
      handleClick: () => {
        setModalIsOpen(true);
        setStepID(2);
      },
      icon: (fill: string) => <Results fill={fill} />,
    },
    {
      step: 3,
      text: "Optionen",
      handleClick: () => {
        setModalIsOpen(true);
        setFormName("options");
      },
      invisible: tariff.id !== "7",
      icon: (fill: string) => <OptionIcon fill={fill} />,
    },
    {
      step: 4,
      text: contactTitle,
      handleClick: () => {
        setFormName("contactForm");
      },
      icon: (fill: string) => <Person fill={fill} />,
    },
    {
      step: 5,
      text: addressData,
      handleClick: () => {
        setFormName("addressForm");
      },
      icon: (fill: string) => <House fill={fill} />,
    },
    {
      step: 6,
      text: "Gee",
      handleClick: () => {
        setFormName("gee");
      },
      invisible: tariff.id !== "7",
      icon: (fill: string) => <Gee fill={fill} />,
    },
    {
      step: 7,
      text: preSupplier,
      handleClick: () => {
        setFormName("preSupplierForm");
      },
      icon: (fill: string) => <EditDocument fill={fill} />,
    },
    {
      step: 8,
      text: bankTitle,
      handleClick: () => {
        setFormName("bankForm");
      },
      icon: (fill: string) => <Euro fill={fill} />,
    },
    {
      step: 9,
      text: summary,
      handleClick: () => {
        setFormName("summaryForm");
      },
      icon: (fill: string) => <Cart fill={fill} />,
    },
  ];

  const { formName } = useContractConclusionFormName();
  const activeStep = () => {
    let formNameAddition;
    switch (formName) {
      case "contactForm":
        formNameAddition = 1;
        break;
      case "addressForm":
        formNameAddition = 2;
        break;
      case "gee":
        formNameAddition = 3;
        break;
      case "preSupplierForm":
        formNameAddition = 4;
        break;
      case "bankForm":
        formNameAddition = 5;
        break;
      case "summaryForm":
        formNameAddition = 6;
        break;
      default:
        formNameAddition = 0;
        break;
    }
    return step + formNameAddition;
  };

  return (
    <div className="text-center">
      <div
        className={`mx-auto py-8 ${
          config.media === "tablet" ? "w-[504px] flex justify-between" : "flex justify-center"
        }`}
      >
        {breadcrumbData.map((stepData) => {
          if (!stepData.invisible)
            return (
              <Step
                text={stepData.text}
                first={stepData.step === 1}
                handleClick={stepData.handleClick}
                activeStep={activeStep()}
                step={stepData.step}
                key={stepData.step}
              >
                {stepData.icon}
              </Step>
            );
        })}
        <WarningDialog setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} alternativeStep={stepID ?? 1} />
      </div>
    </div>
  );
};

export default Breadcrumb;
