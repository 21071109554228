import qs from "query-string";
import { useMemo } from "react";
import { useQuery } from "react-query";

import { useConfig, useTariff } from "../../../../context";
import ProxyInstance from "../../../../services/proxy";

const useGetFormData = () => {
  const tariff = useTariff();
  const config = useConfig();
  const queryString = useMemo(
    () => qs.stringify({ calculatorType: tariff.id }),
    [tariff.id]
  );

  return useQuery(
    `GET_FORM_DATA_${tariff.id}`,
    async () => {
      try {
        const { data: res } = await ProxyInstance(
          `/config/contactform?${queryString}`,
          {
            apikey: config.apikey || "",
          }
        );

        const { data } = res;

        return data.map((val) => ({ label: val.text, value: val.text }));
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    { enabled: tariff.id === 7 }
  );
};

export default useGetFormData;
