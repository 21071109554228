import * as React from "react";

const Results = ({ fill = "var(--get-c-breadcrumb)" }: { fill?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="inline align-text-top"
    >
      <mask id="mask0_775_16167" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_775_16167)">
        <path
          d="M5.5 4C5.22222 4 4.98611 4.09722 4.79167 4.29167C4.59722 4.48611 4.5 4.72222 4.5 5C4.5 5.27778 4.59722 5.51389 4.79167 5.70833C4.98611 5.90278 5.22222 6 5.5 6C5.77778 6 6.01389 5.90278 6.20833 5.70833C6.40278 5.51389 6.5 5.27778 6.5 5C6.5 4.72222 6.40278 4.48611 6.20833 4.29167C6.01389 4.09722 5.77778 4 5.5 4ZM5.5 10.6667C5.22222 10.6667 4.98611 10.7639 4.79167 10.9583C4.59722 11.1528 4.5 11.3889 4.5 11.6667C4.5 11.9444 4.59722 12.1806 4.79167 12.375C4.98611 12.5694 5.22222 12.6667 5.5 12.6667C5.77778 12.6667 6.01389 12.5694 6.20833 12.375C6.40278 12.1806 6.5 11.9444 6.5 11.6667C6.5 11.3889 6.40278 11.1528 6.20833 10.9583C6.01389 10.7639 5.77778 10.6667 5.5 10.6667ZM3.16667 2H13.8333C14.0222 2 14.1806 2.06389 14.3083 2.19167C14.4361 2.31944 14.5 2.47778 14.5 2.66667V7.33333C14.5 7.52222 14.4361 7.68056 14.3083 7.80833C14.1806 7.93611 14.0222 8 13.8333 8H3.16667C2.97778 8 2.81944 7.93611 2.69167 7.80833C2.56389 7.68056 2.5 7.52222 2.5 7.33333V2.66667C2.5 2.47778 2.56389 2.31944 2.69167 2.19167C2.81944 2.06389 2.97778 2 3.16667 2ZM3.83333 3.33333V6.66667H13.1667V3.33333H3.83333ZM3.16667 8.66667H13.8333C14.0222 8.66667 14.1806 8.73056 14.3083 8.85833C14.4361 8.98611 14.5 9.14444 14.5 9.33333V14C14.5 14.1889 14.4361 14.3472 14.3083 14.475C14.1806 14.6028 14.0222 14.6667 13.8333 14.6667H3.16667C2.97778 14.6667 2.81944 14.6028 2.69167 14.475C2.56389 14.3472 2.5 14.1889 2.5 14V9.33333C2.5 9.14444 2.56389 8.98611 2.69167 8.85833C2.81944 8.73056 2.97778 8.66667 3.16667 8.66667ZM3.83333 10V13.3333H13.1667V10H3.83333Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Results;
