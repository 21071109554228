import { useField } from "formik";
import { useQuery } from "react-query";

import { useConfig } from "../../../context";
import useDebounce from "../../../hooks/useDebounce";
import ProxyInstance from "../../../services/proxy";
import handleConvertCity from "../utils/handleConvertCity";

const useQueryCityByZipCode = (fieldName, dependencies) => {
  const config = useConfig();
  const [zipCode] = useField(fieldName.zipCode);
  const debounceValue = useDebounce(zipCode.value, 600);
  const [cityField, , { setValue: setCityValue }] = useField(fieldName?.city);
  const [, , {setValue: setStreetValue}] = useField(fieldName?.street)
  const [, , {setValue: setHouseNumberValue}] = useField(fieldName?.houseNumber)

  if(zipCode.value?.length<5&&cityField.value!==''){
    setCityValue("");
    setStreetValue("");
    setHouseNumberValue("");
  }
  
  try {
    const { data: ortInfo } = useQuery(
      `GET_CITY_BY_${debounceValue}`,
      async () => {
        const { data } = await ProxyInstance(
          `/ws/locationinfo/getOrteByPlz?plz=${debounceValue}`,
          {
            apikey: config.apikey || "",
          }
        );

        const {
          data: { ort },
        } = data;
        const [city] = ort?.length>=1?ort:[ort];

        if (dependencies && dependencies.length >= 1) {
          // VPP dependencies
          const vppData = dependencies.filter((item) => item?.person);

          if (vppData) {
            const billingAddress = vppData.data.object.person.addresses?.find(
              (item) => item.address_type === "BILLING"
            );

            if (billingAddress) {
              setCityValue(billingAddress.city);
            }
          }

          // if there is any dependency, do it here!
        }

        return {
          ort: city.ortsname,
          plz: zipCode.value,
          ortList:
            ort.map((item) => ({
              label: item.ortsname,
              value: item.ortsname,
            })) || [],
        };
      },
      {
        enabled: !!debounceValue && zipCode.value.length === 5,
        refetchOnWindowFocus: false,
      }
    );

    return useQuery(
      `GET_STREET_FROM_${fieldName.zipCode}_${ortInfo?.plz}`,
      async () => {
        try {
          const { data } = await ProxyInstance(
            "/ws/locationinfo/getStrassenByPlzOrt",
            {
              method: "POST",
              body: JSON.stringify({
                plz: ortInfo?.plz,
                ort: handleConvertCity(ortInfo?.ort || ""),
              }),
              apikey: config.apikey || "",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          const {
            data: { strasse },
          } = data;

          return {
            ortList: ortInfo?.ortList,
            plz: ortInfo?.plz,
            street:
              strasse?.map((item) => ({
                label: item.name,
                value: item.name,
              })) || [],
          };
        } catch (e) {
          console.error(e);
          throw e;
        }
      },
      {
        enabled: !!ortInfo && zipCode.value.length === 5,
        refetchOnWindowFocus: false,
      }
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export default useQueryCityByZipCode;
