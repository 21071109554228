import { useField } from "formik";
import * as React from "react";

import Button from "../../../../components/Button";
import Chevron from "../../../../components/icons/Chevron";
import { useTariff } from "../../../../context";
import { useSelectTariff } from "../../../../context/Tariff";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useTarifCart from "../../../../hooks/useTarifCart";
import useTarifCartGas from "../../../../hooks/useTarifCartGas";
import TariffDetails from "../../../TCResults/components/ResultsList/Item/TariffDetails";
import useFeaturesResult from "../../../TCResults/hooks/useFeaturesResult";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import PersonalDetails from "./PersonalDetails";

const InfoAccordeon = () => {
  const { showTariffDetail } = useFeaturesResult();
  const { searchParams } = useFeaturesCC();
  const { selectedTariff } = useSelectTariff();
  const tariff = useTariff();
  const [{ value: showDetails }, , { setValue: setShowDetails }] = useField("showDetails");
  const { responsiveClass } = useResponsiveClass();

  const { data, refetch } = useTarifCart(selectedTariff?.tarifRootID, selectedTariff?.variantenID);
  const { data: dataGas, refetch: refetchGas } = useTarifCartGas(
    selectedTariff?.tarifRootID,
    selectedTariff?.variantenID
  );

  const tableBody = React.useMemo(
    () =>
      tariff.tariffType === "gas"
        ? dataGas?.data?.tarifkarten[0]?.tarifkarte[0]?.preisStaffel
        : data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[0]?.preisStaffeln.map((item: any, index: number) => {
            return {
              ...item,
              arbeitspreisNTNetto:
                data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[1]?.preisStaffeln[index]?.arbeitspreisNetto ?? 0,
              arbeitspreisNTBrutto:
                data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[1]?.preisStaffeln[index]?.arbeitspreisBrutto ?? 0,
            };
          }),
    [data?.data?.tarifkarten, dataGas?.data?.tarifkarten, tariff.tariffType]
  );
  const handleChange = (value: string) => {
    if (value === showDetails) {
      setShowDetails(false);
    } else {
      setShowDetails(value);
    }
    if (!showDetails) {
      tariff.tariffType === "gas" ? refetchGas() : refetch();
    }
  };

  return (
    <>
      <Button onClick={() => handleChange("tarifDetails")} variant="unstyled" className="w-full px-0">
        <div className={responsiveClass("flex items-center justify-between h-4 mb-2 mt-6", "text-sm", "text-sm")}>
          {showTariffDetail}
          <Chevron rotate={showDetails === "tarifDetails"} />
        </div>
      </Button>
      {showDetails === "tarifDetails" && (
        <TariffDetails
          tarifInfo={selectedTariff?.tarifInfo}
          strommixLight={selectedTariff?.strommixLight}
          tableBody={tableBody}
          arbeitspreisNTCtKWhBrutto={selectedTariff?.arbeitspreisNTCtKWhBrutto}
          arbeitspreisNTCtKWhNetto={selectedTariff?.arbeitspreisNTCtKWhNetto}
          isBrutto={selectedTariff?.isBrutto}
          kuendigungsfrist={selectedTariff.kuendigungsfrist}
          vertragsverlaengerung={selectedTariff.vertragsverlaengerung}
        />
      )}
      <div className="py-2">
        <div>
          <Button onClick={() => handleChange("personalDetails")} variant="unstyled" className="w-full px-0">
            <div className={responsiveClass("flex items-center justify-between h-4", "text-sm", "text-sm")}>
              {searchParams}
              <Chevron rotate={showDetails === "personalDetails"} />
            </div>
          </Button>
        </div>
        {showDetails == "personalDetails" && <PersonalDetails />}
      </div>
    </>
  );
};

export default InfoAccordeon;
