import { isValidDate } from "../../../../utils/isValidDate";
import isValidDesiredDate from "../../../../utils/isValidDesiredDate";
import isValidEmail from "../../../../utils/isValidEmail";
import { FormularFormik } from "../../types/FormularFormik";

const handleTerminateValidation = (values: FormularFormik) => {
  let [dd, mm, yyyy] = values.birthday.split(".");
  const validDoB = values.birthday.length === 10 && isValidDate(dd, mm, yyyy);

  [dd, mm, yyyy] = values.desiredCancellationDate.split(".");
  const validDeliveryDate =
    values.desiredCancellationDate.length === 10 &&
    isValidDesiredDate(dd, mm, yyyy);

  const contact = !!values.vorname && !!values.name && validDoB;
  const validDeliveryAddress =
    !!values.plz && !!values.ort && !!values.strasse && !!values.hausnummer;

  const vertrag = !!values.versorger && !!values.kundennummer;
  const isEmailValid = isValidEmail(values.emailadresse) || false;

  const terminateValid =
    !!values.grund &&
    (values.desiredCancellationDate === "true") === validDeliveryDate &&
    values.perMail === isEmailValid &&
    (values.perMail || values.perPost) &&
    values.isPrivacyPolicyCheck;

  return contact && validDeliveryAddress && vertrag && terminateValid;
};

export default handleTerminateValidation;
