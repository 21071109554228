import { useQuery } from "react-query";

import { useParameter } from "../context";
import { useCalcParams } from "../context/CalculationParameter";
import { useConfig } from "../context/Config";
import { useTRInputContext } from "../context/FormInputData";
import { useSelectTariff } from "../context/Tariff";
import ProxyInstance from "../services/proxy";

const useTarifCartGas = (tarifRootID: number, variantenID: string, setContext = false) => {
  const { config } = useConfig();
  const { authToken } = useParameter();
  const { calcParams } = useCalcParams();
  const inputContext = useTRInputContext();
  const { selectedTariff, setSelectTariff } = useSelectTariff();

  const body = {
    rootID: tarifRootID,
    plz: inputContext?.values?.zipCode,
    ort: typeof inputContext.values?.city !== "string" ? inputContext.values?.city?.value : undefined,
    variantenID: variantenID,
    netzbetreiber: calcParams?.netzbetreiber,
    grundversorger: calcParams?.grundversorger,
    ausgabeKonfiguration: 1,
  };

  return useQuery(
    `GET_TARIF_CART_${tarifRootID}`,
    async () => {
      try {
        const { data } = await ProxyInstance(`/ws/tariff/gas/getTarifkarte`, {
          apikey: config.apikey || "",
          method: "POST",
          authToken,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: false,
      onSuccess: (data) => {
        if (setContext) {
          setSelectTariff({
            ...selectedTariff,
            preisStaffeln: data?.data?.tarifkarten[0]?.tarifkarte[0]?.preisStaffel,
          });
        }
      },
    }
  );
};

export default useTarifCartGas;
