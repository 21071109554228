import React from "react";
import { useState } from "react";

import Button from "../Button";
import PfeilIcon from "../icons/pfeil";

const arrow = {
  open: (
    <div style={{ transform: "rotate(180deg)" }}>
      <PfeilIcon />
    </div>
  ),
  closed: <PfeilIcon />,
};

const SingleAccordion = ({ children, title, marginTopLabel, titleStyle }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button
        variant="unstyled"
        className="flex justify-between w-full items-center"
        style={{ marginTop: "10px", padding: 0 }}
        onClick={handleClick}
      >
        <span className={titleStyle}>{title}</span>
        <span>{arrow[open ? "open" : "closed"]}</span>
      </Button>
      <div
        style={{ marginTop: marginTopLabel, display: open ? "block" : "none" }}
      >
        {children}
      </div>
    </>
  );
};

export default SingleAccordion;
