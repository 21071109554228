import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --get-c-primary: #00c995;
    --get-c-primary-hover: #4cd9b5;
    --get-c-primary-text: #000000;

    --get-c-secondary: #d9f1e9;
    --get-c-secondary-light: #f4fbf9;
    --get-c-secondary-hover: #b4e4d4;
    --get-c-secondary-text: #000000;

    --get-c-statistics-1: #d9f1e9;
    --get-c-statistics-2: #00c995;
    --get-c-statistics-3: #f4bbae;
  }
`;
export default myStyle;
