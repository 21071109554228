import { useField, useFormikContext } from "formik";
import React from "react";

import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import { useStepChange, useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useSelectTariff } from "../../../../context/Tariff";
import { useSelectTelco } from "../../../../context/Telco";
import { useSelectTelcoGridConnection } from "../../../../context/TelcoGridConnection";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { FormValues } from "../../../../types/FormValues";
import Tariff from "../../../../types/Tariff";
import TelcoGridConnection from "../../../../types/TelcoGridConnection";
import { contactDataValidateKeys } from "../../Constant";
import { useActivatedPanels, useContractConclusionFormName } from "../../context";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import { checkFormValidationAndRedirect } from "../../hooks/useCheckFormValidationAndRedirect";
import useCheckPhoneNumber from "../../hooks/useCheckPhoneNumbers";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import Offermode from "../Offermode";
import Contact from "./Contact";
import PersonalData from "./PersonalData";

const NEXT_STEP = "addressForm";
const PREV_STEP = "options";

const ContactData = () => {
  const { values, setFieldError } = useFormikContext<FormValues>();
  const tariff = useTariff();
  const { setFormName } = useContractConclusionFormName();
  const { calcParams } = useCalcParams();
  const { mutateAsync } = useServerValidationForm("contactForm", {
    tarifParameter: calcParams,
    keysToValidate: contactDataValidateKeys,
  });

  const { setSelectedTelco } = useSelectTelco();
  const { setSelectedTelcoGridConnection } = useSelectTelcoGridConnection();
  const { setSelectTariff } = useSelectTariff();
  const { setStep } = useStepChange();
  const { setActivatedPanels } = useActivatedPanels();

  const mandatoryFieldsCRM = useMandatoryfields();
  const [fields] = useField("asOffer");
  const { mandatoryLabel } = useGeneralLabel();
  const { showPassword, areaCodeSwitch, safetyIndex } = useFeaturesCC();

  useSetFieldWithVPPData("contactForm");

  const phoneValidationResult = useCheckPhoneNumber(values.phoneNumber, areaCodeSwitch, false);
  const mobilePhoneValidationResult = useCheckPhoneNumber(values.mobileNumber, areaCodeSwitch, true);
  const faxValidationResult = useCheckPhoneNumber(values.faxNumber, areaCodeSwitch > 0 ? 2 : 0, false);
  return (
    <>
      <div className="mb-4">
        <PersonalData />
      </div>
      <Contact />

      {showPassword && (
        <>
          <div className="mt-6 mb-2 text-lg font-bold">{safetyIndex}</div>
          <Input
            name="safetyIndex"
            className="w-full"
            label={
              "Sicherheitskennzahl" +
              (!fields?.value && mandatoryFieldsCRM?.includes("la_sicherheitskennzahl") ? mandatoryLabel : "")
            }
            maxLength={4}
          />
        </>
      )}
      <div className="mt-auto">
        <Offermode position={1} />
        <FooterButtons
          onSubmitValidation={async () => {
            const awaited = await mutateAsync(values);
            const mobile = mobilePhoneValidationResult();
            const fixed = phoneValidationResult();
            const fax = faxValidationResult();

            if (mobile.isOk && mobile.formattedValue) values.mobileNumber = mobile.formattedValue;
            else setFieldError("mobileNumber", mobile.errorText);
            if (fixed.isOk && fixed.formattedValue) values.phoneNumber = fixed.formattedValue;
            else setFieldError("phoneNumber", fixed.errorText);
            if (fax.isOk && fax.formattedValue) values.faxNumber = fax.formattedValue;
            else setFieldError("faxNumber", fax.errorText);

            if (mobile.isOk && fixed.isOk && fax.isOk)
              checkFormValidationAndRedirect(awaited, NEXT_STEP, setActivatedPanels, setFieldError, setFormName);
          }}
          onSubmit={() => {
            setFormName(NEXT_STEP);
            setActivatedPanels((prevState) => [...prevState, NEXT_STEP]);
          }}
          onBack={() => {
            if (tariff.id === "7") {
              setFormName(PREV_STEP);
              setSelectedTelcoGridConnection({} as TelcoGridConnection);
            } else {
              setStep(2);
              setSelectTariff({} as Tariff);
            }
          }}
        />
      </div>
    </>
  );
};

export default ContactData;
