import { useQuery } from "react-query";

import { useParameter } from "../context";
import { useConfig } from "../context/Config";
import ProxyInstance from "../services/proxy";

const usePrimaService = () => {
  const { primaAddressId } = useParameter();
  const { config } = useConfig();

  return useQuery(
    `GET_PRIMA_ADDRESS_ID_${primaAddressId}`,
    async () => {
      try {
        const { data } = await ProxyInstance(
          `/prima/information/telco/v1/address/gridconnectionsByAdressId?Id=${primaAddressId}&gridConnectionType=0`,
          {
            apikey: config.apikey || "",
          }
        );

        const {
          data: { data: response },
        } = data;

        return response;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!primaAddressId && !!config.apikey,
    }
  );
};

export default usePrimaService;
