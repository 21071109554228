import ProxyInstance from "../../../services/proxy";
import { Abschlussdatenobjekt } from "../../../types/Abschlussdatenobjekt";

const sendData = async (
  tariffId: string,
  apikey: string,
  authToken: string|undefined,
  body: { jsonDaten: { abschlussDaten: Abschlussdatenobjekt[] } }
) =>
  await ProxyInstance(`/crm/sendData?calculatorType=${tariffId}`, {
    apikey: apikey || "",
    authToken,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });

export default sendData;
