import {addDays, addMonths} from "date-fns";


const validDesiredDelivery =(value: string,min:number,max:number)=> {


    function isDesiredDeliveryDate(){

        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addDays(today, min);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const movinInDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return movinInDate >= minDate;
        }
        return true
    }
    function isMaxDesiredDeliveryDate(){
        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addMonths(today, max);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const movinInDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return movinInDate <= minDate;
        }
        return true
    }

    function minDesiredDeliveryDate(){
        const today = new Date( new Date().setHours(0,0,0,0));

        return  addDays(today, min)
    }
    function maxDesiredDeliveryDate(){
        const today = new Date( new Date().setHours(0,0,0,0));
        return  addMonths(today, max)

    }

    return {isDesiredDeliveryDate,isMaxDesiredDeliveryDate,minDesiredDeliveryDate,maxDesiredDeliveryDate}
}

export default validDesiredDelivery