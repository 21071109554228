import * as React from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";

import style from "!!raw-loader!./App.css";

import RootProvider from "./context/provider";
import { dark, newTheme, rseEnergie, ugg } from "./theme";
import defaultTheme from "./theme/default";
import MainComponent from "./widgets";

const client = new QueryClient();

const ReactQueryProvider = ({ children, RQClient }) =>
  RQClient ? (
    <QueryClientProvider client={client}>{children}</QueryClientProvider>
  ) : (
    <>{children}</>
  );

function App(props) {
  const [media, setMedia] = React.useState("desktop");
  const [boxWidth, setBoxWidth] = React.useState(0);
  const sectionRef = React.useRef(null);
  const [customStyle, setCustomStyle] = React.useState("");

  const handleChangeLayout = React.useCallback(() => {
    if (sectionRef.current) {
      const clientWidth = sectionRef.current?.clientWidth;

      setBoxWidth(clientWidth);

      if (clientWidth && clientWidth <= 1280) {
        setMedia("tablet");
      }

      if (clientWidth && clientWidth <= 768) {
        setMedia("mobile");
      }
    }
  }, []);

  React.useLayoutEffect(() => {
    handleChangeLayout();
  }, [handleChangeLayout]);

  React.useEffect(() => {
    if (props.theme === "dark") setCustomStyle(dark);
    else if (props.theme === "rse-energie") setCustomStyle(rseEnergie);
    else if (props.theme === "ugg") setCustomStyle(ugg);
    else if (props.theme === "newTheme") setCustomStyle(newTheme);
  }, [props.theme]);

  const canUseReactQuery = React.useMemo(
    () =>
      !!(
        typeof window !== "undefined" &&
        window.document &&
        window.document.getElementsByTagName("tariff-calculator-5")
      ),
    []
  );
  return (
    <section ref={sectionRef}>
      <style>
        {defaultTheme}
        {customStyle}
        {style}
      </style>
        <ReactQueryProvider RQClient={canUseReactQuery}>
          <RootProvider {...props} boxWidth={boxWidth} media={media}>
            <MainComponent />
            <Toaster position="bottom-right" />
          </RootProvider>
        </ReactQueryProvider>
    </section>
  );
}

App.defaultProps = {
  theme: undefined,
  tariffCalculatorTypeId: 1,
  tarifRootIDs: undefined,
  versorgerIDs: undefined,
  bonus: undefined,
  markenIDs: undefined,
  zaehlerart: undefined,
  baiextendedWhiteList: undefined,
  stichtag: undefined,
  tarifcode: undefined,
  handlingTarife: undefined,
  oeko: undefined,
  kundenart: undefined,
  tariftyp: undefined,
  tarifRootIDsMindestfilter: undefined,
  style: undefined,
  authToken: undefined,
  onlyInputTarget: undefined,
  plz: undefined,
  ort: undefined,
  strasse: undefined,
  hausnummer: undefined,
  vppTemplateId: undefined,
  vppCustomerId: undefined,
  vppSummaryUrl: undefined,
  primaAddressId: undefined,
  productView: "default",
  cimUrl: undefined,
  authTokenRefresh: undefined,
  vppAuthTokenUrl: undefined,
};

export default App;
