import React from "react";

const Flame = ({ className = "fill-[#313131]" }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`inline ${className}`}
    >
      <path d="M4.36214 15.0526C4.02011 13.1579 6.42856 9.5614 7.67554 8C10.6255 11.8737 10.9711 14.9474 10.7752 16C16.8889 13.5579 11.7193 4.31579 8.37028 0C0.54638 9.22105 2.43822 13.8772 4.36214 15.0526Z" />
    </svg>
  );
};

export default Flame;
