import { useField } from "formik";
import React from "react";

import Input from "../../../../components/Input";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useConfig } from "../../../../context/Config";
import { useSelectTariff } from "../../../../context/Tariff";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import { useValidateMaLo } from "../../hooks/useValidateMaLo";
import DesiredDeliveryDate from "./DesiredDeliveryDate";
import PrevSupplierData from "./PrevSupplierData";

const NewSupplierForm = () => {
  const { config } = useConfig();

  const [{ value: hasCanceledPrevSupplierValue }] = useField("hasCanceledPrevSupplier");
  const { dateOfCancellation, canceledPrevSupplier, cancelationPermission } = useFeaturesCC();
  const { selectedTariff } = useSelectTariff();
  const { mandatoryLabel } = useGeneralLabel();
  const [fields] = useField("asOffer");
  const { responsiveClass } = useResponsiveClass();
  const mandatoryFieldsCRM = useMandatoryfields();

  useValidateMaLo();
  const gridCols = { desktop: "grid-cols-4", tablet: "grid-cols-2", mobile: "grid-cols-1" };

  return (
    <section className={`grid gap-y-2 gap-x-4 ${gridCols[config.media ?? "desktop"]}`}>
      <DesiredDeliveryDate />
      <PrevSupplierData />

      <div className={responsiveClass("col-span-full gap-y-2 gap-x-4", "grid", "flex", "flex")}>
        <FormikRadio value="true" name="hasCanceledPrevSupplier" label={canceledPrevSupplier} className="mb-4" />
        <FormikRadio
          value="false"
          name="hasCanceledPrevSupplier"
          label={selectedTariff?.versorgerName + cancelationPermission}
        />
      </div>

      {hasCanceledPrevSupplierValue === "true" && (
        <Input
          name="cancellationDate"
          className="w-full"
          label={
            dateOfCancellation +
            (!fields?.value && mandatoryFieldsCRM?.includes("kuendigung_datum") ? mandatoryLabel : "")
          }
          type="datepicker"
        />
      )}
    </section>
  );
};

export default NewSupplierForm;
