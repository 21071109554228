export const handleErrKeys = (key: string) => {
  switch (key) {
    case "la_anrede":
      return "salutation";
    case "la_vorname":
      return "firstName";
    case "la_nachname":
      return "lastName";
    case "la_geburtsdatum":
      return "birthday";
    case "la_plz":
      return "zipCode";
    case "la_ort":
      return "city";
    case "la_strasse":
      return "street";
    case "la_hausnummer":
      return "houseNumber";
    case "la_telefonnummer":
      return "phoneNumber";
    case "la_mobilnummer":
      return "mobileNumber";
    case "la_email":
      return "email";
    default:
      return key;
  }
};
