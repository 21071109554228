import * as React from "react";

import TextSwitch from "../../../../components/TextSwitch";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const CustomerTypeRadio: React.FC = () => {
  const { privateLabel, businessLabel, customerTypeLabel } = useFeatureInputPage();

  return (
    <div>
      <div className="pb-1 text-xs w-full">{customerTypeLabel}</div>
      <div>
        <TextSwitch left={privateLabel} right={businessLabel} name="customerType" values={["0", "1"]} />
      </div>
    </div>
  );
};
export default CustomerTypeRadio;
