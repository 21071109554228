import React from "react";

const Flash = ({ className = "fill-[#313131]" }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`inline ${className}`}
    >
      <path d="M5.17395 1.52381L8.22157 0L6.95173 6.34921L11.2692 3.30159L8.47554 13.2064L9.74538 13.7143L6.95173 16L6.18983 12.1905L7.45967 12.6984L8.47554 6.85714L4.66602 9.65079L5.17395 1.52381Z" />
    </svg>
  );
};

export default Flash;
