import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

const tariffOptions = ["1", "2", "3", "4", "7", "8"]; //@TODO?: fetch this from the config
export type tariffOptionTypes = (typeof tariffOptions)[number];

export interface tariff {
  id: tariffOptionTypes;
  medium: string;
  setTariffId: React.Dispatch<
    React.SetStateAction<{
      id: tariffOptionTypes;
      tariftyp: string;
      medium: string;
      tariffType?: string;
    }>
  >;
  tariffType: "energy" | "gas" | "telco";
  tariftyp: string;
}
interface stepChangeContext {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
export interface parameterContext {
  authToken: string;
  authTokenRefresh?: string;
  baiextendedWhiteList?: string;
  bonus?: string;
  cimUrl?: string;
  handlingTarife?: string;
  hausnummer?: string;
  kundenart?: string;
  markenIDs?: string;
  oeko?: string;
  onlyInputTarget?: string;
  ort?: string;
  plz?: string;
  primaAddressId?: string;
  stichtag?: string;
  strasse?: string;
  style?: string;
  tarifRootIDs?: string;
  tarifRootIDsMindestfilter?: string;
  tarifcode?: string;
  tariffCalculatorTypeId: string;
  tariftyp?: string;
  theme?: string;
  versorgerIDs?: string;
  vppAuthTokenUrl?: string;
  vppCustomerId?: string;
  vppSummaryUrl: string;
  vppTemplateId?: string;
  zaehlerart?: string;
  externalOfferID?: string;
  oneTariff:string;
}

export const tariffIdContext = createContext<tariff>({} as tariff);
export const parameterContext = createContext<parameterContext>({} as parameterContext);
export const stepChangeContext = createContext<stepChangeContext>({} as stepChangeContext);

export const useTariff = () => useContextSelector(tariffIdContext, (c) => c);
export const useParameter = () => useContextSelector(parameterContext, (c) => c);
export const useStepChange = () => useContextSelector(stepChangeContext, (c) => c);
