import formatISO from "date-fns/formatISO";
import { useFormikContext } from "formik";
import { useMemo } from "react";
import { useMutation } from "react-query";

import toast from "../../../components/Toast";
import { useConfig, useParameter, useTariff } from "../../../context";
import { useTRInputContext } from "../../../context/FormInputData";
import { useSupplierInfo } from "../../../context/SupplierInfo";
import { useSelectTariff } from "../../../context/Tariff";
import { useSelectTelco } from "../../../context/Telco";
import ProxyInstance from "../../../services/proxy";
import handleTariffForms from "../utils/handleTariffForms";
import handleTelcoForms from "../utils/handleTelcoForms";
import vppMappings from "../utils/vppMappings";

const useCreateOffer = (formName) => {
  const config = useConfig();
  const tariff = useTariff();
  const inputContext = useTRInputContext();
  const { values, setFieldValue } = useFormikContext();
  const { authToken, vppSummaryUrl, vppCustomerId } = useParameter();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const { supplierInformation } = useSupplierInfo();

  const nettoOrBrutto = useMemo(
    () => (selectedTariff && Number(selectedTariff?.priceType)) || 0,
    [selectedTariff]
  );

  const totalUsage = useMemo(
    () =>
      inputContext.values?.meterType === "3,4"
        ? Number.parseInt(inputContext.values.oneTariff?.replace(".", "")) +
          Number.parseInt(inputContext.values.twoTariff?.replace(".", ""))
        : Number.parseInt(inputContext.values?.oneTariff?.replace(".", "")),
    [
      inputContext.values?.oneTariff,
      inputContext.values?.twoTariff,
      inputContext.values?.meterType,
    ]
  );

  return useMutation(
    `CREATE_OFFER_${formName}_${selectedTelco ? "Telco" : "Tariff"}`,
    async (calcParams) => {
      try {
        if (selectedTariff) {
          const formData = {
            portalDaten: handleTariffForms(
              {
                ...selectedTariff,
                ...values,
                tariffId: tariff.id || 1,
                usage: calcParams?.verbrauchHT,
                verbandsnummer:
                  supplierInformation?.marktrollen[0]?.verbandsnummer || "",
                medium: tariff.medium === "gas" ? 1 : 0,
                authToken,
                ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
              },
              { bindingOffer: false }
            ),
            tarifErgebnis: selectedTariff,
            tarifParameter: calcParams || {},
            vertriebspartnerportal:
              selectedTariff &&
              vppMappings({
                value: {
                  ...selectedTariff,
                  tariftyp: tariff.tariftyp || "",
                  verbrauchGesamt: totalUsage,
                  anzeigeNettoBrutto: nettoOrBrutto,
                  asOffer: values.asOffer,
                  abschlussstatus: "angebot",
                },
              }),
          };

          return handleMutation({
            formData,
            tariffId: tariff.id,
            authToken,
            vppSummaryUrl,
            config,
          });
        }

        if (selectedTelco) {
          const formData = {
            portalDaten: handleTelcoForms(
              {
                ...values,
                ...selectedTelco,
                tariffId: tariff.id || 1,
                verbandsnummer: "",
                objectId: selectedTelco.objectId || "",
                authToken,
              },
              { bindingOffer: false }
            ),
            tarifErgebnis: selectedTelco,
            tarifParameter: {},
            vertriebspartnerportal: {
              bestelldatumZeit: values.asOffer ? "" : formatISO(new Date()),
              VParbeitspreisCtKWhNetto: 0,
              VParbeitspreisCtKWhBrutto: 0,
              VParbeitspreisHTCtKWhNetto: 0,
              VParbeitspreisHTCtKWhBrutto: 0,
              VPgrundpreisNetto: 0,
              VPgrundpreisBrutto: 0,
              VPgrundpreisMonatNetto: 0,
              VPgrundpreisMonatBrutto: 0,
              VPgesamtpreisOhneBoniNetto: 0,
              VPgesamtpreisOhneBoniBrutto: 0,
              VPgesamtpreisNetto: 0,
              VPgesamtpreisBrutto: 0,
              VPgesamtpreisMonatNetto: 0,
              VPgesamtpreisMonatBrutto: 0,
              anzeigeNettoBrutto: 1,
              // auftragsstatus: 1,
              abschlussstatus: "angebot",
              lasttyp: "slp",
              messtyp: "gemeinsam",
              priceType: 0,
              verbrauchGesamt: 20000,
              ...(vppCustomerId ? { idPerson: vppCustomerId } : {}),
            },
          };

          return handleMutation({
            formData,
            tariffId: tariff.id,
            authToken,
            vppSummaryUrl,
            config,
          });
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      onSuccess: (url) => {
        setFieldValue("pdfUrl", url);

        if (url && typeof window !== "undefined") {
          window.open(url, "_blank");
        }
      },
      onError: (error) => {
        toast.error(error.message || "Bitte versuchen Sie nochmal!");
      },
    }
  );
};

async function handleMutation({
  formData,
  tariffId,
  config,
  authToken,
  vppSummaryUrl,
}) {
  const abschlussDaten = [formData];
  const body = { jsonDaten: { abschlussDaten } };

  const { data } = await ProxyInstance(
    `/crm/sendData?calculatorType=${tariffId}`,
    {
      apikey: config.apikey || "",
      authToken,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(body),
    }
  );

  const response = data;

  if (response?.data?.cim?.id_templatedata && vppSummaryUrl) {
    if (typeof window !== "undefined") {
      return `${vppSummaryUrl}${response?.data?.cim?.id_templatedata.toString()}`;
    }
  } else if (!response?.data?.presignedDownloadUrl) {
    toast.error("Fehler beim PDF erstellen");

    return undefined;

    // throw new Error("Fehler beim PDF erstellen");
  }

  return response?.data?.presignedDownloadUrl;
}

export default useCreateOffer;
