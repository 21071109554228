import differenceInCalendarYears from "date-fns/fp/differenceInCalendarYears";
import isValid from "date-fns/isValid";
import parse from "date-fns/parse";

function isValidDate(day, month, year) {
  const parsed = parse(`${day}/${month}/${year}`, "dd/MM/yyyy", new Date());
  const age = differenceInCalendarYears(parsed, new Date());

  if (age < 18) {
    return false;
  }

  return isValid(parsed) && age >= 18;
}

export default isValidDate;
