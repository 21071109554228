import * as React from "react";

import Cancel from "../icons/cancel";

const Modal = ({
  visible,
  setVisible,
  showCancelIcon = false,
  showChildrenDefault = false,
  children,
  position,
  className = "",
}: {
  visible: boolean | string | false;
  setVisible: React.Dispatch<React.SetStateAction<boolean>> | React.Dispatch<React.SetStateAction<string | false>>;
  showCancelIcon?: boolean;
  showChildrenDefault?: boolean;
  children: React.ReactNode;
  position?: {
    left: number;
    right: number;
    width: number;
  };
  className?: string;
}) => {
  if (visible) {
    const stylesPosition = {
      left: position?.left,
      top: position?.right,
      width: position?.width,
    };
    return (
      <>
        <div className={position === undefined ? "fixed w-full h-full top-0 left-0 px-4 overflow-auto z-50" : ""}>
          <div
            className="bg-neutral-700/75 z-50 fixed w-full h-full left-0 top-0"
            onClick={() => setVisible(false)}
          ></div>
          <div
            className={`${
              position === undefined
                ? "relative max-w-screen-lg w-full flex flex-col my-[10vh] mx-auto align-center z-100"
                : "absolute z-100"
            } ${className}`}
            style={position !== undefined ? stylesPosition : {}}
          >
            {showCancelIcon && (
              <div className="absolute right-4 top-4 cursor-pointer" id="cancelModal" onClick={() => setVisible(false)}>
                <Cancel />
              </div>
            )}
            {children}
          </div>
        </div>
      </>
    );
  }
  if (showChildrenDefault) {
    return <>{children}</>;
  }
  return null;
};

export default Modal;
