import { useField, useFormikContext } from "formik";
import React from "react";

import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useFeaturesResult from "../../hooks/useFeaturesResult";

const SelfCompareTariff = ({ children, timeframe }) => {
  const [{ value: priceType }] = useField("priceType");
  const [{ value: customerType }] = useField("customerType");
  const { values, setFieldValue } = useFormikContext();

  const { basepriceLabel, workingpriceLabel, workingpriceunitLabel, pricesNetGross, isShowNetGross } = useFeaturesResult();

  const { bruttoLabel, nettoLabel } = useGeneralLabel();

  const {HTLabel, NTLabel} = useGeneralLabel();
  const isBrutto = React.useMemo(() => {
    if(isShowNetGross){
      return priceType === "0"
    }
    if(pricesNetGross === "net"){
      return false;
    }
    if(pricesNetGross === "gross"){
      return true;
    }
    return customerType === "0"
  }
  , [priceType, customerType, pricesNetGross, isShowNetGross]);

  const [fields, , { setValue }] = useField({ name });

  const handleEvent = React.useCallback(
    (value) => {
      if (fields.name === "supplier") {
        setFieldValue("tariffComparison", "");
      } 
      setValue(value);
    },
    [fields.name, setFieldValue, setValue]
  );

  return (
    <section className="my-4">
      <FormControl onChange={handleEvent}>
        <div className="flex flex-col">
          <div className="my-2 w-1/2">
            <Input
              label={`${workingpriceLabel} ${values.meterType === "3,4"? HTLabel+ " " : ""}${isBrutto ? bruttoLabel : nettoLabel}`}
              name={isBrutto ? "workingPriceBrutto" : "workingPriceNetto"}
              usage={workingpriceunitLabel}
              inputType="decimalNumber"
            />
          </div>

          {values.meterType === "3,4" && (
            <div className="my-2 w-1/2">
              <Input
                label={`${workingpriceLabel} ${NTLabel} ${isBrutto ? bruttoLabel : nettoLabel}`}
                name={isBrutto ? "workingPriceNTBrutto" : "workingPriceNTNetto"}
                usage={workingpriceunitLabel}
                inputType="decimalNumber"
              />
            </div>
          )}

          <div className="my-2  w-1/2">
            <Input
              label={`${basepriceLabel} ${isBrutto ? bruttoLabel : nettoLabel}`}
              name={isBrutto ? "basePriceBrutto" : "basePriceNetto"}
              usage={timeframe ? "€/Jahr" : "€/Monat"}
              inputType="decimalNumber"
            />
          </div>
        </div>

        <div className="mt-4">{children}</div>
      </FormControl>
    </section>
  );
};

export default SelfCompareTariff;
