import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import WarningDialog from "../../components/WarningDialog";

const showWarningDialogContext = createContext(
  {} as {
    showWarningDialog: boolean;
    setShowWarningDialog: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

const WarningDialogContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [showWarningDialog, setShowWarningDialog] = React.useState(false);
  return (
    <showWarningDialogContext.Provider value={{ showWarningDialog, setShowWarningDialog }}>
      <WarningDialog modalIsOpen={showWarningDialog} setModalIsOpen={setShowWarningDialog} />
      {children}
    </showWarningDialogContext.Provider>
  );
};

export default WarningDialogContextProvider;
export const useWarningDialogContext = () => useContextSelector(showWarningDialogContext, (c) => c);
