import React from "react";
import { createContext, useContextSelector } from "use-context-selector";
import Tariff from "../../types/Tariff";


export interface SupplierInfo {
  marktrollen: [
    {
      sonstigeAngaben: unknown;
      marktrolleID: number;
      versorgerName	:string;
      verbandsnummerKurz: string;
    },
  ];
  sonstigeAngaben: unknown;
  gebiet: unknown;
  versorgerID: number;
  versorgerName: string;
  netzbetreibername:string;
  suppliertariffName: string;
  suppliertariffGesamtpreisBrutto: string;
  suppliertariffGrundpreisBrutto: string;
  suppliertariffArbeitspreisHTCtKWhBrutto: string;
  suppliertariffArbeitspreisNTCtKWhBrutto: string;
  suppliertariffGesamtpreisNetto: string;
  suppliertariffGrundpreisNetto: string;
  suppliertariffArbeitspreisHTCtKWhNetto: string;
  suppliertariffArbeitspreisNTCtKWhNetto: string;
  suppliertariff:Tariff;
}

interface SupplierInfoContext {
  supplierInformation: SupplierInfo;
  setSupplierInformation: React.Dispatch<React.SetStateAction<SupplierInfo>>;
}

const supplierInfo = createContext<SupplierInfoContext>({
  supplierInformation: {} as SupplierInfo,
  setSupplierInformation: () => {},
});

const SupplierInfo = ({ children }: { children: React.ReactNode }) => {
  const [supplierInformation, setSupplierInformation] = React.useState({} as SupplierInfo);

  return (
    <supplierInfo.Provider value={{ supplierInformation, setSupplierInformation }}>{children}</supplierInfo.Provider>
  );
};

export default SupplierInfo;
export const useSupplierInfo = () => useContextSelector(supplierInfo, (c) => c);
