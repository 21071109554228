import * as React from "react";

export const SmallHouse = ({ fill = "white" }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="25"
      viewBox="0 0 45 25"
      fill="none"
      className="inline mb-1 stroke-primary"
    >
      <path d="M21.04 24H15.92V14.5371L22.96 8L30 14.5371V24H24.88V19.52H22.96H21.04V24Z" fill={fill} />
      <path
        d="M30 14.5371V24H24.88V19.52H22.96H21.04V24H15.92V14.5371M14 16.32L22.96 8L31.92 16.32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MediumHouse = ({ fill = "white" }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="25"
      viewBox="0 0 45 25"
      fill="none"
      className="inline mb-1 stroke-primary"
    >
      <path d="M19.8 24H13.4V12.1714L22.2 4L31 12.1714V24H24.6V18.4H22.2H19.8V24Z" fill={fill} />
      <path
        d="M31 12.1714V24H24.6V18.4H22.2H19.8V24H13.4V12.1714M11 14.4L22.2 4L33.4 14.4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BigHouse = ({ fill = "white" }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="26"
      viewBox="0 0 45 26"
      fill="none"
      className="inline mb-1 stroke-primary"
    >
      <path d="M19.56 25H11.88V10.8057L22.44 1L33 10.8057V25H25.32V18.28H22.44H19.56V25Z" fill={fill} />
      <path
        d="M33 10.8057V25H25.32V18.28H22.44H19.56V25H11.88V10.8057M9 13.48L22.44 1L35.88 13.48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
