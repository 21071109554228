import React from "react";
import { Doughnut } from "react-chartjs-2";

import Button from "../../../components/Button";
import Card from "../../../components/Card";
import CardExtend from "../../../components/CardExtend";
import Caret from "../../../components/icons/caret";
import Tablet from "../../../components/View/Tablet";
import { useConfig } from "../../../context";
import { useTariff } from "../../../context";
import { useCalcParams } from "../../../context/CalculationParameter";
import { useTRInputContext } from "../../../context/FormInputData";
import { useSelectTariff } from "../../../context/Tariff";
import useGeneralLabel from "../../../hooks/useGeneralLabel";
import useTarifCart from "../../../hooks/useTarifCart";
import useTarifCartGas from "../../../hooks/useTarifCartGas";
import formatCurrency from "../../../utils/formatCurrency";
import formatDecimal from "../../../utils/formatDecimal";
import Bonus from "../../TCResults/components/ResultsList/Item/Bonus";
import Eco from "../../TCResults/components/ResultsList/Item/Eco";
import NameAndTariff from "../../TCResults/components/ResultsList/Item/NameAndTariff";
import Price, { PriceDetail } from "../../TCResults/components/ResultsList/Item/Price";
import PriceGuarantee from "../../TCResults/components/ResultsList/Item/PriceGuarantee";
import RowItem from "../../TCResults/components/ResultsList/RowItem";
import useFeaturesResult from "../../TCResults/hooks/useFeaturesResult";
import WarningDialog from "./WarningDialog";

const SelectedTariff = () => {
  const config = useConfig();
  const { selectedTariff } = useSelectTariff();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);
  const [showSelectedTariff, setShowSelectedTariff] = React.useState(false);
  const tariff = useTariff();
  const { calcParams } = useCalcParams();
  const trInputContext = useTRInputContext();
  const { data, refetch } = useTarifCart(selectedTariff?.tarifRootID, selectedTariff?.variantenID);
  const { data: dataGas, refetch: refetchGas } = useTarifCartGas(selectedTariff?.tarifRootID, selectedTariff?.variantenID);
  const primaryRef = React.useRef();
  const secondaryRef = React.useRef();
  const tertiaryRef = React.useRef();
  const primaryBackgroundColor = primaryRef?.current && getComputedStyle(primaryRef?.current).backgroundColor;

  const secondaryBackgroundColor = secondaryRef?.current && getComputedStyle(secondaryRef?.current).backgroundColor;

  const {
    workingpriceLabel,
    nuclearLabel,
    renewableLabel,
    fossilLabel,
    radioactivLabel,
    co2Label,
    co2unitLabel,
    renewableunitLabel,
    nuclearunitLabel,
    fossilunitLabel,
    showTariffDetailLabel,
    standLabel,
    strommixLabel,
    unitUsageLabel,
    tariffTypeLabel,
    ecoTarifLabel,
    customerTypeLabel,
    businessLabel,
    privateLabel,
    usageLabel,
  } = useFeaturesResult();
  const { HTLabel, NTLabel } = useGeneralLabel

  const discount = React.useMemo(() => {
    const res = selectedTariff?.dynamischeAngaben
      .map((v) => v.doubleObj)
      .filter(Boolean)
      .flat(Number.POSITIVE_INFINITY)
      .find((d) =>
        selectedTariff?.isBrutto
          ? d.name === "gesamtpreisMonatOhneBoniBrutto"
          : d.name === "gesamtpreisMonatOhneBoniNetto"
      );

    return res.wert.toFixed(0).replace(".", ",");
  }, [selectedTariff?.dynamischeAngaben, selectedTariff?.isBrutto]);

  const isChartDataAvailable = React.useMemo(() => {
    return (
      selectedTariff?.strommixLight?.erneuerbare ||
      selectedTariff?.strommixLight?.fossile ||
      selectedTariff?.strommixLight?.kernkraft ||
      false
    );
  }, [
    selectedTariff?.strommixLight?.erneuerbare,
    selectedTariff?.strommixLight?.fossile,
    selectedTariff?.strommixLight?.kernkraft,
  ]);

  const strommixChartData = selectedTariff.strommixLight && {
    labels: Object.keys(selectedTariff.strommixLight)
      .filter((v) => v !== "stand" && v !== "dynamischeAngaben")
      .map((v) => v[0].toUpperCase() + v.slice(1)),
    datasets: [
      {
        data: Object.entries(selectedTariff.strommixLight).reduce((acc, [key, value]) => {
          if (value === null || key === "stand") return acc;

          return [...acc, { label: key, value }];
        }, []),
        backgroundColor: [secondaryBackgroundColor, primaryBackgroundColor].filter(Boolean),
      },
    ],
  };

  const tableBody = React.useMemo(
    () =>
      tariff.tariffType === "gas"
        ? dataGas?.data?.tarifkarten[0]?.tarifkarte[0]?.preisStaffel
        : data?.data?.tarifkarten[0]?.tarifkarte[0]?.zeitZone[0]?.preisStaffeln,
    [data?.data?.tarifkarten, dataGas?.data?.tarifkarten, tariff.tariffType]
  );
  const handleChange = (value) => {
    if (value === showDetails) {
      setShowDetails(false);
    } else {
      setShowDetails(value);
    }
    if (!showDetails) {
      tariff.tariffType === "gas" ? refetchGas() : refetch();
    }
  };

  if (config.media === "desktop") {
    return (
      <Card className="rounded-xl" key={selectedTariff?.tarifID}>
        <div>
          <div className="mb-5">
            <NameAndTariff versorgerName={selectedTariff?.versorgerName} tarifName={selectedTariff?.tarifName} />

            <Eco
              eco={{
                isOekostromTarif: selectedTariff?.isOekostromTarif,
                isOekogasTarif: selectedTariff?.isOekogasTarif,
              }}
              ecoTarifLabel={ecoTarifLabel}
            />

            <Price
              discount={discount}
              isBrutto={selectedTariff?.isBrutto}
              timeframeYear={true}
              gesamtpreisBrutto={selectedTariff?.gesamtpreisBrutto}
              gesamtpreisNetto={selectedTariff?.gesamtpreisNetto}
              ersparnisBrutto={selectedTariff?.ersparnisBrutto}
              ersparnisNetto={selectedTariff?.ersparnisNetto}
              grundpreisBrutto={selectedTariff?.grundpreisBrutto}
              grundpreisNetto={selectedTariff?.grundpreisNetto}
              arbeitspreisHTCtKWhBrutto={selectedTariff?.arbeitspreisHTCtKWhBrutto}
              arbeitspreisCtKWhBrutto={selectedTariff?.arbeitspreisCtKWhBrutto}
              arbeitspreisHTCtKWhNetto={selectedTariff?.arbeitspreisHTCtKWhNetto}
              arbeitspreisCtKWhNetto={selectedTariff?.arbeitspreisCtKWhNetto}
            />

            <PriceGuarantee
              priceguarantee={
                selectedTariff?.informationen ? selectedTariff?.informationen[0]?.preisgarantieFuerMonate : ""
              }
              vertragsverlaengerung={selectedTariff?.vertragsverlaengerung}
              kuendigungsfrist={selectedTariff?.kuendigungsfrist}
              vertragslaufzeit={selectedTariff?.vertragslaufzeit}
            />

            {selectedTariff?.boni && <Bonus boni={selectedTariff?.boni} isBrutto={selectedTariff?.isBrutto} />}
            {selectedTariff?.aufschlaege && <Bonus boni={selectedTariff?.aufschlaege} isBrutto={selectedTariff?.isBrutto} />}
          </div>
        </div>
        <Button onClick={() => handleChange("tarifDetails")} variant="unstyled" className="w-full px-0">
          <div className="flex items-center justify-between h-4 mb-2">
            {showTariffDetailLabel}
            {showDetails === "tarifDetails" ? (
              <div>
                <Caret />
              </div>
            ) : (
              <div style={{ transform: "rotate(180deg)" }}>
                <Caret />
              </div>
            )}
          </div>
        </Button>

        <CardExtend extendOpen={showDetails === "tarifDetails"}>
          <>
            <section id="tariff-info">
              {selectedTariff?.strommixLight?.co2 ? (
                <div className="flex justify-between my-2">
                  <div>{co2Label}</div>
                  <div>
                    {selectedTariff?.strommixLight?.co2} {co2unitLabel}
                  </div>
                </div>
              ) : null}

              {selectedTariff?.strommixLight?.radioaktiv ? (
                <div className="flex justify-between my-2">
                  <div>{radioactivLabel}</div>
                  <div>
                    {selectedTariff?.strommixLight?.radioaktiv} {co2unitLabel}
                  </div>
                </div>
              ) : null}

              {isChartDataAvailable && (
                <>
                  {selectedTariff?.strommixLight?.stand ? (
                    <div className="my-2">
                      {strommixLabel} ({standLabel} {selectedTariff?.strommixLight?.stand})
                    </div>
                  ) : null}
                  <section id="chart" className="grid grid-cols-2 items-center">
                    <div>
                      <Doughnut data={strommixChartData} />
                    </div>

                    <div>
                      {selectedTariff?.strommixLight?.erneuerbare ? (
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <div className="bg-[--default-color-hover] mr-1 w-3 h-3 rounded" ref={primaryRef} />
                            {renewableLabel}
                          </div>
                          <div>
                            {selectedTariff?.strommixLight?.erneuerbare} {renewableunitLabel}
                          </div>
                        </div>
                      ) : null}

                      {selectedTariff?.strommixLight?.fossile ? (
                        <div className="flex justify-between my-2">
                          <div className="flex items-center">
                            <div className="bg-[--get-c-grey] mr-1 w-3 h-3 rounded" ref={secondaryRef} />
                            {fossilLabel}
                          </div>
                          <div>
                            {selectedTariff?.strommixLight?.fossile} {fossilunitLabel}
                          </div>
                        </div>
                      ) : null}

                      {selectedTariff?.strommixLight?.kernkraft ? (
                        <div className="flex justify-between my-2">
                          <div className="bg-[--get-c-grey] mr-1 w-3 h-3 rounded" ref={tertiaryRef}>
                            {nuclearLabel}
                          </div>
                          <div>
                            {selectedTariff?.strommixLight?.kernkraft} {nuclearunitLabel}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </section>
                </>
              )}
            </section>

            <section id="bonus" className="mt-8">
              <div className="flex w-full mb-2">Zusammensetzung der Kosten</div>
              <table className="table-auto border-collapse w-full">
                <thead>
                  <tr key="table-head">
                    <th className="text-left pr-2 pb-2">Verbrauch</th>
                    <th className="text-right pr-2 pb-2">Jährlicher Grundpreis</th>
                    <th className="text-right pb-2">{workingpriceLabel}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableBody?.map((preisItem) => (
                    <tr key={preisItem.rootId} className="border-solid border-x-0 border-y-2 border-gray-300">
                      <td className="text-left pr-2">
                        {preisItem.verbrauchBis === 0 && "ab "}
                        {formatDecimal(preisItem.verbrauchVon, 0)}
                        {preisItem.verbrauchBis !== 0 && " - "}
                        {preisItem.verbrauchBis !== 0 && formatDecimal(preisItem.verbrauchBis, 0)} kWh
                      </td>
                      <td className="text-right pr-2">
                        {formatCurrency(
                          selectedTariff?.isBrutto ? preisItem.grundpreisBrutto : preisItem.grundpreisNetto
                        )}
                      </td>
                      <td className="text-right">
                        {formatDecimal(
                          selectedTariff?.isBrutto ? preisItem.arbeitspreisBrutto : preisItem.arbeitspreisNetto
                        )}
                        &nbsp;ct/kWh
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>
          </>
        </CardExtend>
        <div className="bg-background-orange rounded-xl p-2 -m-2 mt-2">
          <div>
            <Button onClick={() => handleChange("personalDetails")} variant="unstyled" className="w-full px-0">
              <div className="flex items-center justify-between h-4">
                Ihre Angaben
                {showDetails === "personalDetails" ? (
                  <div>
                    <Caret />
                  </div>
                ) : (
                  <div style={{ transform: "rotate(180deg)" }}>
                    <Caret />
                  </div>
                )}
              </div>
            </Button>
          </div>
          <CardExtend extendOpen={showDetails == "personalDetails"}>
            <div className="mt-2">
              <RowItem
                label="Ort"
                value={trInputContext?.values?.zipCode + " " + trInputContext?.values?.city?.label}
              />
              <RowItem label={customerTypeLabel} value={calcParams.kundenart ? businessLabel : privateLabel} />

              {tariff.id === 3 && (
                <RowItem
                  label="Tariftyp"
                  value={trInputContext?.values?.tariffType === "0" ? "Wärmepumpe" : "Speicherheizung"}
                />
              )}

              {tariff.id === 4 && (
                <RowItem
                  label="Messung"
                  value={trInputContext?.values?.measurementType === "0" ? "gemeinsame Messung" : "getrennte Messung"}
                />
              )}
              {tariff.id !== 2 && (
                <RowItem
                  label={tariffTypeLabel}
                  value={trInputContext?.values?.meterType === "1,2" ? "Eintarif" : "Zweitarif"}
                />
              )}

              <RowItem
                label={trInputContext?.values?.meterType === "3,4"? usageLabel + " " + HTLabel : usageLabel}
                value={(calcParams.verbrauchHT || calcParams.verbrauch) + " " + unitUsageLabel}
              />

              {tariff.id !== 2 && trInputContext?.values?.meterType === "3,4" && (
                <RowItem label={usageLabel + " " + NTLabel} value={(calcParams.verbrauchNT??"0") + " " + unitUsageLabel} />
              )}
            </div>
          </CardExtend>
        </div>
        <div className="text-center">
          <Button
            variant="primary"
            className="px-8 p-2 mb-2.5 mt-10"
            type="submit"
            onClick={() => setModalIsOpen(!modalIsOpen)}
          >
            Produktauswahl ändern
          </Button>
          <div className="text-xs text-[--get-c-grey-middle]">Alle angegebenen Preise sind {selectedTariff?.isBrutto?"inkl.": "zuzüglich"} USt.</div>
        </div>
        <WarningDialog modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      </Card>
    );
  } else {
    return (
      <Card className="rounded-xl" key={selectedTariff?.tarifID}>
        <Button onClick={() => setShowSelectedTariff(!showSelectedTariff)} variant="unstyled" className="w-full px-0">
          <div className="flex items-center justify-between min-h-4 mb-2">
            <NameAndTariff
              versorgerName={selectedTariff?.versorgerName}
              tarifName={selectedTariff?.tarifName}
              className="text-left"
            />
            <Tablet>
              <Eco
                eco={{
                  isOekostromTarif: selectedTariff?.isOekostromTarif,
                  isOekogasTarif: selectedTariff?.isOekogasTarif,
                }}
                ecoTarifLabel={ecoTarifLabel}
                />
            </Tablet>
            <div className="mx-4 font-bold text-xl">
                  {formatCurrency(selectedTariff?.isBrutto ? selectedTariff?.gesamtpreisBrutto : selectedTariff?.gesamtpreisNetto)}{" "}
                  <span className="text-xs font-normal text-[--get-c-grey-middle]">jährlich inkl. Boni</span>
            </div>
            {showSelectedTariff ? (
              <div>
                <Caret />
              </div>
            ) : (
              <div style={{ transform: "rotate(180deg)" }}>
                <Caret />
              </div>
            )}
          </div>
        </Button>
        {showSelectedTariff && (
          <>
            <div className="mt-6">
              <div>
                <PriceDetail
                  discount={discount}
                  isBrutto={selectedTariff?.isBrutto}
                  timeframeYear={selectedTariff?.timeframeYear}
                  ersparnisBrutto={selectedTariff?.ersparnisBrutto}
                  ersparnisNetto={selectedTariff?.ersparnisNetto}
                  grundpreisBrutto={selectedTariff?.grundpreisBrutto}
                  grundpreisNetto={selectedTariff?.grundpreisNetto}
                  arbeitspreisHTCtKWhBrutto={selectedTariff?.arbeitspreisHTCtKWhBrutto}
                  arbeitspreisCtKWhBrutto={selectedTariff?.arbeitspreisCtKWhBrutto}
                  arbeitspreisHTCtKWhNetto={selectedTariff?.arbeitspreisHTCtKWhNetto}
                  arbeitspreisCtKWhNetto={selectedTariff?.arbeitspreisCtKWhNetto}
                />

                <PriceGuarantee
                  priceguarantee={
                    selectedTariff?.informationen ? selectedTariff?.informationen[0]?.preisgarantieFuerMonate : ""
                  }
                  vertragsverlaengerung={selectedTariff?.vertragsverlaengerung}
                  kuendigungsfrist={selectedTariff?.kuendigungsfrist}
                  vertragslaufzeit={selectedTariff?.vertragslaufzeit}
                />

                {selectedTariff?.boni && <Bonus boni={selectedTariff?.boni} isBrutto={selectedTariff?.isBrutto} />}
                {selectedTariff?.aufschlaege && <Bonus boni={selectedTariff?.aufschlaege} isBrutto={selectedTariff?.isBrutto} />}

              </div>
            </div>
            <Button onClick={() => handleChange("tarifDetails")} variant="unstyled" className="w-full px-0">
              <div className="flex items-center justify-between h-4 mb-2">
                {showTariffDetailLabel}
                {showDetails === "tarifDetails" ? (
                  <div>
                    <Caret />
                  </div>
                ) : (
                  <div style={{ transform: "rotate(180deg)" }}>
                    <Caret />
                  </div>
                )}
              </div>
            </Button>

            <CardExtend extendOpen={showDetails === "tarifDetails"}>
              <>
                <section id="tariff-info">
                  {selectedTariff?.strommixLight?.co2 ? (
                    <div className="flex justify-between my-2">
                      <div>{co2Label}</div>
                      <div>
                        {selectedTariff?.strommixLight?.co2} {co2unitLabel}
                      </div>
                    </div>
                  ) : null}

                  {selectedTariff?.strommixLight?.radioaktiv ? (
                    <div className="flex justify-between my-2">
                      <div>{radioactivLabel}</div>
                      <div>
                        {selectedTariff?.strommixLight?.radioaktiv} {co2unitLabel}
                      </div>
                    </div>
                  ) : null}

                  {isChartDataAvailable && (
                    <>
                      {selectedTariff?.strommixLight?.stand ? (
                        <div className="my-2">
                          {strommixLabel} ({standLabel} {selectedTariff?.strommixLight?.stand})
                        </div>
                      ) : null}
                      <section id="chart" className="grid grid-cols-2 items-center gap-4">
                        <div className="px-4 max-w-[350px] py-2">
                          <Doughnut data={strommixChartData} />
                        </div>

                        <div>
                          {selectedTariff?.strommixLight?.erneuerbare ? (
                            <div className="flex justify-between items-center">
                              <div className="flex items-center">
                                <div className="bg-[--default-color-hover] mr-1 w-3 h-3 rounded" ref={primaryRef} />
                                {renewableLabel}
                              </div>
                              <div>
                                {selectedTariff?.strommixLight?.erneuerbare} {renewableunitLabel}
                              </div>
                            </div>
                          ) : null}

                          {selectedTariff?.strommixLight?.fossile ? (
                            <div className="flex justify-between my-2">
                              <div className="flex items-center">
                                <div className="bg-[--get-c-grey] mr-1 w-3 h-3 rounded" ref={secondaryRef} />
                                {fossilLabel}
                              </div>
                              <div>
                                {selectedTariff?.strommixLight?.fossile} {fossilunitLabel}
                              </div>
                            </div>
                          ) : null}

                          {selectedTariff?.strommixLight?.kernkraft ? (
                            <div className="flex justify-between my-2">
                              <div className="flex items-center">
                                <div className="bg-[--get-c-grey] mr-1 w-3 h-3 rounded" ref={tertiaryRef} />
                                {nuclearLabel}
                              </div>
                              <div>
                                {selectedTariff?.strommixLight?.kernkraft} {nuclearunitLabel}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </section>
                    </>
                  )}
                </section>

                <section id="bonus" className="mt-8">
                  <div className="flex w-full mb-2">Zusammensetzung der Kosten</div>
                  <table className="table-auto border-collapse w-full">
                    <thead>
                      <tr key="table-head">
                        <th className="text-left pr-2 pb-2">Verbrauch</th>
                        <th className="text-right pr-2 pb-2">Jährlicher Grundpreis</th>
                        <th className="text-right pb-2">{workingpriceLabel}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableBody?.map((preisItem) => (
                        <tr key={preisItem.rootId} className="border-solid border-x-0 border-y-2 border-gray-300">
                          <td className="text-left pr-2">
                            {preisItem.verbrauchBis === 0 && "ab "}
                            {formatDecimal(preisItem.verbrauchVon, 0)}
                            {preisItem.verbrauchBis !== 0 && " - "}
                            {preisItem.verbrauchBis !== 0 && formatDecimal(preisItem.verbrauchBis, 0)} kWh
                          </td>
                          <td className="text-right pr-2">
                            {formatCurrency(
                              selectedTariff?.isBrutto ? preisItem.grundpreisBrutto : preisItem.grundpreisNetto
                            )}
                          </td>
                          <td className="text-right">
                            {formatDecimal(
                              selectedTariff?.isBrutto ? preisItem.arbeitspreisBrutto : preisItem.arbeitspreisNetto
                            )}
                            &nbsp;ct/kWh
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </section>
              </>
            </CardExtend>
            <div className="bg-background-orange rounded-xl p-2 -m-2 mt-2">
              <div>
                <Button onClick={() => handleChange("personalDetails")} variant="unstyled" className="w-full px-0">
                  <div className="flex items-center justify-between h-4">
                    Ihre Angaben
                    {showDetails === "personalDetails" ? (
                      <div>
                        <Caret />
                      </div>
                    ) : (
                      <div style={{ transform: "rotate(180deg)" }}>
                        <Caret />
                      </div>
                    )}
                  </div>
                </Button>
              </div>
              <CardExtend extendOpen={showDetails == "personalDetails"}>
                <div className="mt-2">
                  <RowItem
                    label="Ort"
                    value={trInputContext?.values?.zipCode + " " + trInputContext?.values?.city?.label}
                  />
                  <RowItem label={customerTypeLabel} value={calcParams.kundenart ? businessLabel : privateLabel} />

                  {tariff.id === 3 && (
                    <RowItem
                      label="Tariftyp"
                      value={trInputContext?.values?.tariffType === "0" ? "Wärmepumpe" : "Speicherheizung"}
                    />
                  )}

                  {tariff.id === 4 && (
                    <RowItem
                      label="Messung"
                      value={
                        trInputContext?.values?.measurementType === "0" ? "gemeinsame Messung" : "getrennte Messung"
                      }
                    />
                  )}
                  {tariff.id !== 2 && (
                    <RowItem
                      label={tariffTypeLabel}
                      value={trInputContext?.values?.meterType === "1,2" ? "Eintarif" : "Zweitarif"}
                    />
                  )}

                  <RowItem
                    label={trInputContext?.values?.meterType === "3,4" ? usageLabel + HTLabel: usageLabel}
                    value={(calcParams.verbrauchHT || calcParams.verbrauch) + " " + unitUsageLabel}
                  />
                  {tariff.id !== 2 && trInputContext?.values?.meterTypet === "3,4" && (
                    <RowItem label={usageLabel + " " + NTLabel} value={(calcParams.verbrauchNT??"0") + " " + unitUsageLabel} />
                  )}
                </div>
              </CardExtend>
            </div>
            <div className="text-center">
              <Button
                variant="primary"
                className="px-8 p-2 mb-2.5 mt-10"
                type="submit"
                onClick={() => setModalIsOpen(!modalIsOpen)}
              >
                Produktauswahl ändern
              </Button>
              <div className="text-xs text-[--get-c-grey-middle]">Alle angegebenen Preise sind {selectedTariff?.isBrutto?"inkl.": "zuzüglich"} USt.</div>
            </div>
            <WarningDialog modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
          </>
        )}
      </Card>
    );
  }
};

export default SelectedTariff;
