import * as React from "react";

import Chevron from "../icons/Chevron";
import Desktop from "../View/Desktop";
import Mobile from "../View/Mobile";
import Tablet from "../View/Tablet";

const ActiveStep = ({ children }: { children: React.ReactNode }) => {
  return <span className="inline text-breadcrumb text-sm align-baseline">{children}</span>;
};

const FinishedStep = ({ handleClick, children }: { handleClick: () => void; children: React.ReactNode }) => {
  return (
    <span onClick={handleClick} className="inline cursor-pointer text-breadcrumb text-sm align-baseline">
      {children}
    </span>
  );
};

const UnfinishedStep = ({ children }: { children: React.ReactNode }) => {
  return <span className="inline text-breadcrumb-inactive text-sm align-baseline">{children}</span>;
};
const Step = ({
  activeStep,
  step,
  text,
  first = false,
  handleClick,
  children,
}: {
  activeStep: number;
  step: number;
  text: string;
  first?: boolean;
  formName?: null | string;
  handleClick: () => void;
  children?: (fill: string) => React.ReactNode;
}) => {
  if (activeStep < step) {
    return (
      <>
        {!first ? <Chevron className="inline -rotate-90 fill-breadcrumb-inactive mx-2 size-5" /> : ""}
        <UnfinishedStep>
          <Desktop>
            {children != undefined ? children("var(--get-c-breadcrumb-inactive)") : ""}
            <span className="ml-2">{text}</span>
          </Desktop>
          <Tablet>{children != undefined ? children("var(--get-c-breadcrumb-inactive)") : text}</Tablet>
          <Mobile>{children != undefined ? children("var(--get-c-breadcrumb-inactive)") : text}</Mobile>
        </UnfinishedStep>
      </>
    );
  } else if (step === activeStep) {
    return (
      <>
        {!first ? <Chevron className="inline -rotate-90 fill-breadcrumb mx-2 size-5" /> : ""}
        <ActiveStep>
          <Desktop>
            {children != undefined ? children("var(--get-c-breadcrumb)") : ""}
            <span className="ml-2">{text}</span>
          </Desktop>
          <Tablet>
            {children != undefined ? children("var(--get-c-breadcrumb)") : ""}
            <span className="ml-0.5">{text}</span>
          </Tablet>
          <Mobile>{children != undefined ? children("var(--get-c-breadcrumb)") : ""}</Mobile>
        </ActiveStep>
      </>
    );
  } else if (activeStep > step) {
    return (
      <>
        {!first ? <Chevron className="inline -rotate-90 fill-breadcrumb mx-2 size-5" /> : ""}
        <FinishedStep handleClick={handleClick}>
          <Desktop>
            {children != undefined ? children("var(--get-c-breadcrumb)") : ""}
            <span className="ml-2">{text}</span>
          </Desktop>
          <Tablet>{children != undefined ? children("var(--get-c-breadcrumb)") : text}</Tablet>
          <Mobile>{children != undefined ? children("var(--get-c-breadcrumb)") : text}</Mobile>
        </FinishedStep>
      </>
    );
  } else {
    return <></>;
  }
};

export default Step;
