import formatISO from "date-fns/formatISO";

import basePriceCalculation from "./basePriceCalculation";

const vppMappings = ({ value }) => {
  return {
    bestelldatumZeit: formatISO(new Date()),
    VParbeitspreisCtKWhNetto: value.arbeitspreisCtKWhNetto,
    VParbeitspreisCtKWhBrutto: value.arbeitspreisCtKWhBrutto,
    VParbeitspreisHTCtKWhNetto: value.arbeitspreisHTCtKWhNetto,
    VParbeitspreisHTCtKWhBrutto: value.arbeitspreisHTCtKWhBrutto,
    VParbeitspreisNTCtKWhNetto: value.arbeitspreisNTCtKWhNetto
      ? value.arbeitspreisNTCtKWhNetto
      : undefined,
    VParbeitspreisNTCtKWhBrutto: value.arbeitspreisNTCtKWhBrutto
      ? value.arbeitspreisNTCtKWhBrutto
      : undefined,
    ...(value.arbeitspreisEnergiepreisHTCtKWhNetto
      ? {
          VParbeitspreisEnergiepreisHTCtKWhNetto:
            value.arbeitspreisEnergiepreisHTCtKWhNetto,
        }
      : {}),
    ...(value.arbeitspreisEnergiepreisHTCtKWhBrutto
      ? {
          VParbeitspreisEnergiepreisHTCtKWhBrutto:
            value.arbeitspreisEnergiepreisHTCtKWhBrutto,
        }
      : {}),
    ...(value.arbeitspreisEnergiepreisNTCtKWhNetto
      ? {
          VParbeitspreisEnergiepreisNTCtKWhNetto:
            value.arbeitspreisEnergiepreisNTCtKWhNetto,
        }
      : {}),
    ...(value.arbeitspreisEnergiepreisNTCtKWhBrutto
      ? {
          VParbeitspreisEnergiepreisNTCtKWhBrutto:
            value.arbeitspreisEnergiepreisNTCtKWhBrutto,
        }
      : {}),
    VPgrundpreisNetto: value.grundpreisNetto,
    VPgrundpreisBrutto: value.grundpreisBrutto,
    VPgrundpreisMonatNetto: basePriceCalculation(value, "grundpreisMonatNetto"),
    VPgrundpreisMonatBrutto: basePriceCalculation(
      value,
      "grundpreisMonatBrutto"
    ),
    VPgesamtpreisOhneBoniNetto: value.gesamtpreisOhneBoniNetto,
    VPgesamtpreisOhneBoniBrutto: value.gesamtpreisOhneBoniBrutto,
    VPgesamtpreisMonatOhneBoniNetto: basePriceCalculation(
      value,
      "gesamtpreisMonatOhneBoniNetto"
    ),
    VPgesamtpreisMonatOhneBoniBrutto: basePriceCalculation(
      value,
      "gesamtpreisMonatOhneBoniBrutto"
    ),
    VPgesamtpreisNetto: value.gesamtpreisNetto,
    VPgesamtpreisBrutto: value.gesamtpreisBrutto,
    VPgesamtpreisMonatNetto: basePriceCalculation(
      value,
      "gesamtpreisMonatNetto"
    ),
    VPgesamtpreisMonatBrutto: basePriceCalculation(
      value,
      "gesamtpreisMonatBrutto"
    ),
    boni: value.boni ?? [],
    aufschlaege: value.aufschlaege || [],
    abschlussstatus: value.abschlussstatus,
    anzeigeNettoBrutto: value.anzeigeNettoBrutto,
    // auftragsstatus: value.asOffer ? 1 : 11,
    lasttyp: "slp",
    messtyp: value.tariftyp === "5" ? "getrennt" : "gemeinsam",
    priceType: 0,
    verbrauchGesamt: value.verbrauchGesamt,
    id_person: value?.idPerson,
  };
};

export default vppMappings;
