import * as React from "react";
import { useSelectTariff } from "../../../../context/Tariff";
import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesBankData = () => {
  const { selectedTariff } = useSelectTariff();
  const { data: config } = useExternalConfigs("contractconclusion");
  const { data: tariffConfig } = useExternalConfigs("contractconclusion", selectedTariff?.versorgerID);
  const contractConclusion = config?.data?.data?.contractConclusion;
  const tariffContractConclusion = tariffConfig?.data?.data?.contractConclusion;

  const resolveContractConclusion = React.useCallback(
    (key: string) => {
      return tariffContractConclusion?.[key] ?? contractConclusion?.[key];
    },
    [tariffContractConclusion, contractConclusion]
  );

  const defaultLabel = {
    agreeSepa: "SEPA-Lastschriftmandat erteilen",
    agreeSepaLabel:
      "SEPA-Lastschrift-Mandat: Der Kontoinhaber ermächtigt den Anbieter, fällige Zahlungs- und Rechnungsbeträge mittels Lastschrift einzuziehen. Das kontoführende Kreditinstitut wird hiermit vom Kontoinhaber angewiesen, die auf dieses Konto gezogenen Lastschriften einzulösen. Hinweis: Innerhalb von 8 Wochen kann der Kontoinhaber, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die Bedingungen, die der Kontoinhaber mit seinem Kreditinstitut vereinbart hat.",
    bankTitle: "Zahlungsweise",
    bic: "SWIFT-Code (BIC)",
    creditInstitution: "Kreditinstitut",
    deviateAccountHolderData: {
      label: "SEPA-Lastschriftmandat erteilen",
      options: [
        { value: "false", label: "Kontoinhaber identisch mit Lieferanschrift" },
        { value: "true", label: "Abweichender Kontoinhaber" },
      ],
    },
    iban: "IBAN",
    remoteSignatureLabel: "Die Unterschrift soll kontaktlos erfolgen (per E-Mail)",
  };

  type Label = typeof defaultLabel;
  const labels: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveContractConclusion(key) || defaultValue])
  ) as Label;

  return labels;
};
export default useFeaturesBankData;
