import { useField } from "formik";
import React from "react";

import FormRow from "../../../../components/FormRow";
import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import { SALUTATION_LIST, TITLE_LIST } from "../../../../Constant";
import { useTariff } from "../../../../context";
import { useTRInputContext } from "../../../../context/FormInputData";
import { useSelectTelco } from "../../../../context/Telco";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useQueryCityByZipCode from "../../hooks/useQueryCityByZipCode";
import useQueryStreet from "../../hooks/useQueryStreet";
import useSetFieldWithInputData from "../../hooks/useSetFieldWithInputData";
import Headline from "../Headline";

const FormData = ({ type }: { type: string }) => {
  const inputContext = useTRInputContext();
  const { selectedTelco } = useSelectTelco();
  const tariff = useTariff();
  const [billingCity] = useField("billingCity");
  const [billingStreet] = useField("billingStreet");
  const [billingZipCode] = useField("billingZipCode");
  const [fields] = useField("asOffer");
  const {
    zipCode,
    city,
    street,
    addition,
    houseNumber,
    firstName,
    lastName,
    salutationOptions,
    salutation,
    title,
    companyName,
    mailboxBilling,
    delieveryAddress,
    lockbox,
    mandatoryFields,
    billingAddress,
    installationLocation,
  } = useFeaturesCC();
  const { mandatoryLabel } = useGeneralLabel();
  const { responsiveClass, responsiveColSpan } = useResponsiveClass();

  const { data: listStreet } = useQueryStreet(
    {
      plz: inputContext.values?.zipCode || "",
      ort:
        typeof inputContext.values?.city == "string"
          ? inputContext.values?.city
          : (inputContext.values?.city?.value ?? ""),
    },
    [inputContext.values?.street?.value || ""]
  );

  useSetFieldWithInputData();

  const { data: billingListStreet } = useQueryCityByZipCode({
    zipCode: "billingZipCode",
    city: "billingCity",
    street: "billingStreet",
    houseNumber: "billingHouseNumber",
  });

  const { data: installationListStreet } = useQueryCityByZipCode({
    zipCode: "installationZipCode",
    city: "installationCity",
    street: "installationStreet",
    houseNumber: "installationHouseNumber",
  });
  if (type === "deliveryAddress") {
    return (
      <div className={responsiveClass("grid gap-x-4 gap-y-2", "grid-cols-1", "grid-cols-2", "grid-cols-4")}>
        <div className="col-span-full">
          <Headline>{tariff.id === "7" ? "Installationsanschrift" : "Lieferanschrift"}</Headline>
          <div className="text-sm text-gray-dark min-h-5">{!fields.value && mandatoryFields}</div>
        </div>
        <div className="w-full">
          <Input name="zipCode" label={zipCode + (!fields?.value ? mandatoryLabel : "")} placeholder="PLZ" disabled />
        </div>
        <div className={responsiveClass("w-full", "col-span-full", "", "col-span-3")}>
          <GetSelect
            name="city"
            options={[inputContext.values?.city]}
            label={city + (!fields?.value ? mandatoryLabel : "")}
            disabled
          />
        </div>

        <div className={responsiveClass("w-full", "col-span-full", "col-span-full", "col-span-2")}>
          <GetSelect
            label={street + (!fields?.value ? mandatoryLabel : "")}
            name="street"
            options={inputContext.values.street?.value ? [inputContext.values.street] : listStreet || []}
            isSearchable
            disabled={Boolean(inputContext.values?.street?.value)}
          />
        </div>
        <div className="w-full">
          <Input
            name="houseNumber"
            label={houseNumber + (!fields?.value ? mandatoryLabel : "")}
            placeholder="Hausnummer"
            disabled={!!inputContext.values?.houseNumber}
            inputType="otherNumber"
          />
        </div>
        <div className="w-full">
          <Input name="additionalAddress" label={addition} placeholder="Adresszusatz" disabled={tariff.id === "7"} />
        </div>

        {selectedTelco && tariff.id === "7" && (
          <Input
            name="installationLocation"
            label={installationLocation}
            placeholder="Wohnungs-/Briefkastennummer, Installationsort-Stockwerk"
            inputType="firma"
          />
        )}
      </div>
    );
  }
  if (type === "billingAddress") {
    return (
      <div className={responsiveClass("grid gap-x-4 gap-y-2", "grid-cols-1", "grid-cols-2", "grid-cols-4")}>
        <Headline>{billingAddress}</Headline>
        <div className={responsiveColSpan("full", "full", "2")}>
          <Input name="billingCompanyName" label={companyName} placeholder="Firma" inputType="firma" />
        </div>
        <GetSelect
          name="billingSalutation"
          label={salutation + (fields?.value ? "" : mandatoryLabel)}
          options={
            fields?.value
              ? (salutationOptions || SALUTATION_LIST).concat({ label: "", value: "" })
              : salutationOptions || SALUTATION_LIST
          }
        />
        <GetSelect name="billingTitle" options={TITLE_LIST} label={title} />
        <div className={responsiveColSpan("full", "1", "2")}>
          <Input
            name="billingFirstName"
            label={firstName + (fields?.value ? "" : mandatoryLabel)}
            placeholder="Vorname"
            inputType="name"
          />
        </div>
        <div className={responsiveColSpan("full", "1", "2")}>
          <Input
            name="billingLastName"
            label={lastName + (fields?.value ? "" : mandatoryLabel)}
            placeholder="Nachname"
            inputType="name"
          />
        </div>
        <Input
          name="billingZipCode"
          label={zipCode + (fields?.value ? "" : mandatoryLabel)}
          placeholder="PLZ"
          onlyNumber
          maxLength={5}
        />
        <div className={responsiveColSpan("full", "1", "3")}>
          <GetSelect
            name="billingCity"
            label={city + (fields?.value ? "" : mandatoryLabel)}
            options={billingZipCode.value.length === 5 ? billingListStreet?.ortList || [] : []}
            disabled={
              billingListStreet?.ortList === undefined ||
              billingListStreet?.ortList.length === 0 ||
              billingZipCode.value.length !== 5
            }
            defaultOpen={
              billingZipCode.value.length === 5 &&
              billingListStreet?.ortList &&
              billingListStreet?.ortList.length >= 1 &&
              billingCity?.value === ""
            }
          />
        </div>
        <div className={responsiveColSpan("full", "full", "2")}>
          <GetSelect
            name="billingStreet"
            label={street + (fields?.value ? "" : mandatoryLabel)}
            options={billingZipCode.value.length === 5 ? billingListStreet?.street || [] : []}
            disabled={billingListStreet?.street === undefined || billingListStreet?.street.length === 0}
            defaultOpen={
              billingListStreet?.street &&
              billingCity?.value !== "" &&
              billingStreet?.value === "" &&
              billingZipCode.value.length === 5
            }
            isSearchable
          />
        </div>
        <Input
          name="billingHouseNumber"
          label={houseNumber + (fields?.value ? "" : mandatoryLabel)}
          placeholder="Hausnummer"
          inputType="numberGreater0"
        />
        <Input name="billingAdditionalAddress" label={addition} placeholder={addition} inputType="address" />
        {lockbox && (
          <Input name="billingMailbox" label={mailboxBilling} placeholder="Postfach" onlyNumber maxLength={5} />
        )}
      </div>
    );
  }
  if (type === "installationAddress") {
    return (
      <>
        <div className="font-medium text-lg">{delieveryAddress}</div>
        <div className="grid grid-cols-2 gap-y-2 gap-x-4 my-4">
          <Input name="installationCompanyName" label={companyName} placeholder="Firma" inputType="firma" />
        </div>
        <div className="grid-cols-2 grid gap-y-2 gap-x-4 my-4">
          <GetSelect name="installationSalutation" label={salutation} options={salutationOptions || SALUTATION_LIST} />

          <GetSelect name="installationTitle" options={TITLE_LIST} label={title} />
        </div>
        <div className="grid-cols-2 grid gap-y-2 gap-x-4 my-4">
          <Input name="installationFirstName" label={firstName} placeholder="Vorname" inputType="name" />

          <Input name="installationLastName" label={lastName} placeholder="Nachname" inputType="name" />
        </div>

        <div className="font-medium text-lg">Adresse</div>

        <FormRow addClassName="flex flex-col sm:flex-row gap-y-2 gap-x-4">
          <div className=" sm:w-1/4 w-full">
            <Input name="installationZipCode" label={zipCode} placeholder="PLZ" onlyNumber maxLength={5} />
          </div>
          <div className=" sm:w-3/4 w-full">
            <GetSelect name="installationCity" label={city} options={installationListStreet?.ortList || []} />
          </div>
        </FormRow>
        <div className="my-4 grid sm:grid-cols-2 grid-cols-1 gap-y-2 gap-x-4">
          <div className="w-full">
            <GetSelect name="installationStreet" label={street} options={installationListStreet?.street || []} />
          </div>
          <div className="flex w-full gap-y-2 gap-x-4">
            <div className="sm:w-1/2 w-full">
              <Input
                name="installationHouseNumber"
                label={houseNumber}
                placeholder="Hausnummer"
                inputType="numberGreater0"
              />
            </div>
            <div className="sm:w-1/2 w-full">
              <Input
                name="installationAdditionalAddress"
                label={addition}
                placeholder={addition}
                onlyNumber
                inputType="address"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};

export default FormData;
