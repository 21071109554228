import { useFormikContext } from "formik";
import qs from "query-string";
import { useMemo } from "react";

import { useParameter, useTariff } from "../../../context";

const useQueryParameters = () => {
  const { values } = useFormikContext();
  const { plz, oeko } = useParameter();
  const tariff = useTariff();

  const isPrimeTimeConsumptionNT = useMemo(() => {
    if (
      values.meterType === "3,4" &&
      (values.zipCode ||
        (values.zipCode && values.city) ||
        (values.zipCode && values.city && values.street) ||
        (values.zipCode && values.city && values.street && values.houseNumber))
    ) {
      return undefined;
    }
  }, [
    values.city,
    values.houseNumber,
    values.meterType,
    values.street,
    values.zipCode,
  ]);

  return useMemo(() => {
    return qs.stringify({
      calculatorType: tariff.id,
      customerType: values.customerType,
      zipCode: plz || values.zipCode,
      ...(values.city
        ? { city: typeof values.city !== "string" && values.city.value }
        : {}),
      ...(values.street
        ? { street: typeof values.street !== "string" && values.street.value }
        : {}),
      ...(values.houseNumber
        ? {
            hnr:
              (typeof values.houseNumber !== "string" &&
                values.houseNumber.value) ||
              values.houseNumber,
          }
        : {}),
      primeTimeConsumption:
        tariff.id === 7
          ? 20000 || 1
          : parseInt(values.oneTariff.replace(".", "")),
      ...(oeko ? { eco: oeko } : {}),
      ...(values.twoTariff !== "0"
        ? { secondaryTimeConsumption: values.twoTariff?.replace(".", "") }
        : {}),
      page: 1,
      gridConnectionType: 0,
      pageSize: 20,
      primeTimeConsumptionNT: isPrimeTimeConsumptionNT,

      // TELCO
      ...(tariff.id === 7 && values.houseNumber
        ? {
            houseNumberComplete:
              typeof values.houseNumber !== "string" &&
              values.houseNumber.value,
          }
        : {}),
    });
  }, [
    isPrimeTimeConsumptionNT,
    oeko,
    plz,
    tariff.id,
    values.city,
    values.customerType,
    values.houseNumber,
    values.oneTariff,
    values.street,
    values.twoTariff,
    values.zipCode,
  ]);
};

export default useQueryParameters;
