import { useField } from "formik";
import React from "react";

import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import Desktop from "../../../../components/View/Desktop";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { useSelectTariff } from "../../../../context/Tariff";
import SelectTariff from "../../../TCResults/components/SelectTariff";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useGetSupplier from "../../hooks/useGetSupplier";
import useValidateMaLo from "../../hooks/useValidateMaLo";
import DesiredDeliveryDate from "./DesiredDeliveryDate";

const NewSupplierForm = () => {
  const { data, isLoading } = useGetSupplier();

  const [{ value: hasCanceledPrevSupplierValue }] = useField("hasCanceledPrevSupplier");
  const { prevSupplierLabel, prevCustomerNumLabel, dateOfCancellationLabel } = useFeaturesCC();
  const { selectedTariff } = useSelectTariff();

  useValidateMaLo();

  return (
    <section className="mt-7">
      <FormControl>
        <DesiredDeliveryDate />
        <div className="font-bold text-lg mb-5">Vorversorgerdaten</div>
        <div className="grid grid-cols-3 mb-5 gap-5">
          <div className="flex flex-col justify-between	col-span-2">
            <label className="text-xs leading-4 pb-1">{prevSupplierLabel}</label>
            <SelectTariff
              disabled={isLoading}
              isLoading={isLoading}
              name="prevSupplierName"
              dataKey="label"
              textField="value"
              options={data?.filter((item) => item.value !== undefined)}
            />
          </div>
          <Tablet>
            <Input name="customerNumber" label={prevCustomerNumLabel} inputType="number" />
          </Tablet>
          <Desktop>
            <div className="grid grid-cols-1 gap-4">
              <Input name="customerNumber" label={prevCustomerNumLabel} inputType="number" />
            </div>
          </Desktop>
        </div>

        <Mobile>
          <Input name="customerNumber" label={prevCustomerNumLabel} inputType="number" />
        </Mobile>

        <div className="font-bold text-lg mb-5">Kündigung Versorger</div>
        <FormikRadio value="true" name="hasCanceledPrevSupplier" label="Der Vertrag ist bereits gekündigt" className="mb-4" />
        <FormikRadio
          value="false"
          name="hasCanceledPrevSupplier"
          label={` ${selectedTariff?.versorgerName} ist zur Kündigung des bisherigen Liefervertrages bevollmächtigt`}
        />

        {hasCanceledPrevSupplierValue === "true" && (
          <div className="grid grid-cols-3 gap-4 my-4">
            <Input name="cancellationDate" className="w-full" label={dateOfCancellationLabel} type="datepicker" />
          </div>
        )}
      </FormControl>
    </section>
  );
};

export default NewSupplierForm;
