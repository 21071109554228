import { format } from "date-fns";
import { useFormikContext } from "formik";
import React from "react";

import useVPPCustomerService from "../../../hooks/useVPPCustomerService";

const useSetFieldWithVPPData = (formType) => {
  const { data: vppData } = useVPPCustomerService();
  const { setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (vppData) {
      const shippingAddress = vppData?.data.object.person?.addresses.find(
        (val) => val.address_type === "SHIPPING"
      );

      const billingAddress = vppData.data.object.person.addresses.find(
        (val) => val.address_type === "BILLING"
      );

      const phone = vppData.data.object.person.contacts.find(
        (val) => val.contact_type === "PHONE"
      );

      const email = vppData.data.object.person.contacts.find(
        (val) => val.contact_type === "EMAIL"
      );

      const dob = vppData.data.object.person.date_of_birth
        .split("-")
        .reverse()
        .join(".");

      const payment = vppData.data.object.person.payments[0];

      if (formType === "contactForm") {
        if (shippingAddress) {
          if (shippingAddress.salutation) {
            setFieldValue("salutation", {
              label: shippingAddress.salutation,
              value: shippingAddress.salutation,
            });
          }

          if (shippingAddress.title) {
            setFieldValue("title", {
              label: shippingAddress.title,
              value: shippingAddress.title,
            });
          }

          if (shippingAddress.first_name) {
            setFieldValue("firstName", shippingAddress.first_name);
          }

          if (shippingAddress.last_name) {
            setFieldValue("lastName", shippingAddress.last_name);
          }

          if (shippingAddress.company) {
            setFieldValue("companyName", shippingAddress.company);
          }

          if (shippingAddress.id_person) {
            setFieldValue("idPerson", shippingAddress.id_person);
          }
        }

        if (email) {
          if (email.contact_value) {
            setFieldValue("email", email.contact_value);
            setFieldValue("confirmationEmail", email.contact_value);
          }
        }

        if (phone) {
          if (phone.contact_value) {
            setFieldValue("mobileNumber", phone.contact_value);
          }
        }

        if (dob) {
          setFieldValue("birthday", dob);
        }
      }

      if (formType === "addressForm") {
        if (shippingAddress) {
          if (shippingAddress.zipcode) {
            setFieldValue("zipCode", shippingAddress.zipcode);
          }

          if (shippingAddress.city) {
            setFieldValue("city", shippingAddress.city);
          }

          if (shippingAddress.street) {
            setFieldValue("street", {
              label: shippingAddress.street,
              value: shippingAddress.street,
            });
          }

          if (shippingAddress.house_no) {
            setFieldValue("houseNumber", shippingAddress.house_no);
          }

          if (shippingAddress.house_no_add) {
            setFieldValue("additionalAddress", shippingAddress.house_no_add);
          }
        }

        if (billingAddress) {
          if (billingAddress.zipcode) {
            setFieldValue("billingZipCode", billingAddress.zipcode);
          }

          if (billingAddress.city) {
            setFieldValue("billingCity", billingAddress.city);
          }

          if (billingAddress.street) {
            setFieldValue("billingStreet", billingAddress.street);
          }

          if (billingAddress.house_no) {
            setFieldValue("billingHouseNumber", billingAddress.house_no);
          }

          if (billingAddress.house_no_add) {
            setFieldValue(
              "billingAdditionalAddress",
              billingAddress.house_no_add
            );
          }
        }
      }

      if (formType === "preSupplierForm") {
        const preSupplierData = vppData.data.object.person.templateData[0];
        const productType =
          vppData.data.object.person.templateData[0].data.order.productType;
        const data =
          preSupplierData.data?.[`orderdetails_${productType}`] || null;

        if (data) {
          const isE03 = data.transaktionsgrund === "Lieferantenwechsel";
          const prevSupplierName =
            data.netzbetreiber.name || data.altlieferant.name;
          const prevCustomerNumber = data.altlieferant.kundennummer;
          const maloID = data.zaehler.maloid;
          const counterNumber = data.zaehler.nummer;
          const hasCanceledPrevSupplier =
            data.altlieferant.gekuendigt === 1 ? "true" : "false";
          const cancellationDate = data.altlieferant.kuendigungsdatum;

          setFieldValue("reasonToChange", isE03 ? "E03" : "E01");

          if (prevSupplierName) {
            setFieldValue("prevSupplierName", {
              label: prevSupplierName,
              value: prevSupplierName,
            });
          }

          if (prevCustomerNumber) {
            setFieldValue("customerNumber", prevCustomerNumber);
          }

          if (maloID) {
            setFieldValue("maLoID", maloID);
          }

          if (counterNumber) {
            setFieldValue("counterNumber", counterNumber);
          }

          if (hasCanceledPrevSupplier === "true") {
            setFieldValue("hasCanceledPrevSupplier", hasCanceledPrevSupplier);

            if (cancellationDate) {
              const date = format(new Date(cancellationDate), "dd.MM.yyyy");
              setFieldValue("cancellationDate", date);
            }
          }
        }
      }

      if (formType === "bankForm") {
        if (payment) {
          if (payment.type === "SEPA_DIRECT_DEBIT") {
            setFieldValue("paymentOptions", "directDebit");
            setFieldValue("isAgreeToSEPA", true);
          }

          if (payment.account) {
            setFieldValue("iban", payment?.account?.replaceAll(" ", ""));
          }
        }
      }
    }
  }, [formType, setFieldValue, vppData]);

  return { data: vppData };
};

export default useSetFieldWithVPPData;
