import * as React from "react";

import { useConfig } from "../../context/Config";

const Mobile = ({ children }: { children: React.ReactNode }) => {
  const { config } = useConfig();

  if (config.media === "mobile") {
    return <>{children}</>;
  }
  return null;
};

export default Mobile;
