import React from "react";

import { useTariff } from "../../../context";
import { useSelectTariff } from "../../../context/Tariff";
import formatCurrency from "../../../utils/formatCurrency";
import formatDecimal from "../../../utils/formatDecimal";
import Subsection from "../subsection";

const PriceOverview = () => {
  const { selectedTariff } = useSelectTariff();
  const tariff = useTariff();

  return (
    <>
      <Subsection
        text="Grundpreis"
        value={`${formatCurrency(
          (selectedTariff?.isBrutto
            ? selectedTariff?.grundpreisBrutto
            : selectedTariff?.grundpreisNetto) || 0
        )} / Jahr`}
      />

      {tariff.id === 2 ? (
        <Subsection
          text="Arbeitspreis"
          value={`${formatDecimal(
            (selectedTariff?.isBrutto
              ? selectedTariff?.arbeitspreisCtKWhBrutto
              : selectedTariff?.arbeitspreisCtKWhNetto) || 0
          )}\xa0ct / kWh`}
        />
      ) : (
        <Subsection
          text="Arbeitspreis"
          value={`${formatDecimal(
            (selectedTariff?.isBrutto
              ? selectedTariff?.arbeitspreisHTCtKWhBrutto
              : selectedTariff?.arbeitspreisHTCtKWhNetto) || 0
          )}\xa0ct / kWh`}
        />
      )}

      {selectedTariff?.boni?.map((item) => {
        return (
          <section
            key={item.id}
            className="flex justify-between items-center my-4"
          >
            <div className="w-2/3">
              <div>{item?.art + " " + item?.bedingung}</div>
            </div>
            <div className="w-1/3 text-right">
              -
              {formatCurrency(
                selectedTariff?.isBrutto
                  ? item?.calcWertBrutto
                  : item?.calcWertNetto
              )}
            </div>
          </section>
        );
      })}
      <Subsection
        text="Ersparnis zum Vergleichstarif im ersten Jahr"
        value={`${formatCurrency(
          selectedTariff?.isBrutto
            ? selectedTariff?.ersparnisBrutto
            : selectedTariff?.ersparnisNetto || 0
        )}`}
      />
    </>
  );
};

export default PriceOverview;
