import { addYears } from "date-fns";

const validBirthday = (value: string, minAge: number, maxAge: number) => {
  function isOldEnough() {
    if (value?.length === 10) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const maxDate = addYears(today, -minAge);
      const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
      const birthday = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

      return birthday <= maxDate;
    }
    return false;
  }

  function isYoungEnough() {
    if (value?.length === 10) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const minDate = addYears(today, -maxAge);
      const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
      const birthday = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
      return birthday >= minDate;
    }
    return false;
  }

  function getMinAge() {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    return addYears(today, -minAge);
  }
  function getMaxAge() {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    return addYears(today, -maxAge);
  }

  return { getMaxAge, getMinAge, isOldEnough, isYoungEnough };
};
export default validBirthday;
