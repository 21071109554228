import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";

import toast from "../../../../components/Toast";
import { useConfig, useTariff } from "../../../../context";
import { useGridConnectionStatus } from "../../../../context/GridConnectionStatus";
import ProxyInstance from "../../../../services/proxy";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import handleError from "../../../TCInputs/utils/handleError";
import useQueryParametersResult from "../useQueryParametrs";

const useSimplifierResultPage = (values) => {
  const [tariffList, setTariffList] = useState(null);
  const [data, setData] = useState({
    citySuggestions: [],
    streetSuggestions: [],
    houseNumberSuggestions: [],
    unique: false,
    status: 0,
    tariffList: [],
    networkOperator: "",
    baseSupplier: "",
  });
  const { tariffNotFoundLabel } = useFeatureInputPage();
  const { setFieldValue } = useFormikContext();
  const { setGridConnectionStatus } = useGridConnectionStatus();
  const config = useConfig();
  const tariff = useTariff();
  const queryParameter = useQueryParametersResult();

  useEffect(() => {
    if (values.zipCode?.length !== 5) {
      setData({
        citySuggestions: [],
        streetSuggestions: [],
        houseNumberSuggestions: [],
        unique: false,
        status: 0,
        tariffList: [],
        baseSupplier: "",
        networkOperator: "",
      });

      setGridConnectionStatus(0);
    }
  }, [setGridConnectionStatus, values.zipCode]);

  useEffect(() => {
    if (values)
      setData((prevState) => ({
        ...prevState,
        status: 0,
      }));
  }, [values]);

  useEffect(() => {
    if (values.meterType === "1,2") {
      setFieldValue("twoTariff", "0");
    }
  }, [setFieldValue, values.meterType]);

  const baseUrl = useMemo(() => {
    return `/simplifier/calc/${tariff.tariffType}/?${queryParameter}`;
  }, [queryParameter, tariff.tariffType]);

  const query = useQuery(
    [
      `GET_SIMPLIFIER_RESULT_${tariff.id}`,
      values.meterType,
      values.zipCode,
      values.city,
      values.street,
      values.houseNumber,
      values.oneTariff,
      values.twoTariff,
      values.customerType,
    ],
    async () => {
      try {
        const { data } = await ProxyInstance(baseUrl, {
          apikey: config.apikey || "",
        });

        if (data?.error) {
          throw new Error("Bitte füllen Sie die Felder aus");
        }
        if (data?.data?.data.suggestLocationType === "CITY") {
          setData((prevState) => ({
            ...prevState,
            citySuggestions: data?.data?.data.suggestLokation.sort((a, b) => a.localeCompare(b)),
          }));
        }

        if (!data?.data?.data?.suggestLocationType) {
          setData((prevState) => ({
            ...prevState,
            status: 1,
          }));

          setGridConnectionStatus(data?.data?.data?.gridConnectionStatus);
        }

        if (data?.data?.data?.calculationResult?.ergebnisContainer?.[0]?.ergebnis) {
          setTariffList(data?.data?.data?.calculationResult.ergebnisContainer[0].ergebnis);
        }

        setData((prevState) => ({
          ...prevState,
          networkOperator: data?.data?.data?.calculationParameter?.netzbetreiber,
          baseSupplier: data?.data?.data?.calculationParameter?.grundversorger,
          unique: data?.data?.data.unique,
        }));

        return data?.data?.data;
      } catch (e) {
        if (e instanceof Error) {
          e.message === "Error getting data" ? toast.error(tariffNotFoundLabel) : null;
          setData((prevState)=>({
            ...prevState,
            citySuggestions: []}));
          throw e;
        }
      }
    },
    {
      enabled: !!config.apikey && values.zipCode?.length === 5,
      refetchOnWindowFocus: false,
      retry: false,
      onError: handleError,
    }
  );

  return {
    ...query,
    data: {
      citySuggestions: data.citySuggestions,
      streetSuggestions: data.streetSuggestions,
      houseNumberSuggestions: data.houseNumberSuggestions,
      unique: data.unique,
      status: data.status,
      tariffList,
      networkOperator: data.networkOperator,
      baseSupplier: data.baseSupplier,
    },
  };
};

export default useSimplifierResultPage;
