import React from "react";

import Desktop from "../../../../../components/View/Desktop";
import Mobile from "../../../../../components/View/Mobile";
import Tablet from "../../../../../components/View/Tablet";

const NameAndTariff = ({
  versorgerName,
  tarifName,
  className = "text-center",
  fixedHeight = true,
}: {
  versorgerName: string;
  tarifName: string;
  className?: string;
  fixedHeight?: boolean;
}) => {
  return (
    <>
      <Mobile>
        <section id="tariff_name" className={className}>
          <div className="text-sm">{versorgerName}</div>
          <div className="text-xl font-medium">{tarifName}</div>
        </section>
      </Mobile>
      <Tablet>
        <section id="tariff_name" className={className}>
          <div className="text-sm">{versorgerName}</div>
          <div className={`text-xl font-medium ${fixedHeight ? "h-16" : ""}`}>{tarifName}</div>
        </section>
      </Tablet>
      <Desktop>
        <section id="tariff_name" className={className}>
          <div>{versorgerName}</div>
          <div className="text-2xl font-medium h-16">{tarifName}</div>
        </section>
      </Desktop>
    </>
  );
};

export default NameAndTariff;
