import React from "react";

import Divider from "../../../../../components/Divider";
import ReactHTMLParser from "../../../../../components/HTMLParser";
import PDF from "../../../../../components/icons/Pdf";
import { ProductEntry, TelcoProductOption } from "../../../../../context/Telco";
import formatCurrency from "../../../../../utils/formatCurrency";
import removeSymbolAndAddSpace from "../../../../../utils/removeSymbolAndAddSpace";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import getFileType from "../../../utils/getFileType";

const Options = ({
  option,
  onRemove,
  onSelect,
  options,
}: {
  option: TelcoProductOption;
  onRemove: (entry: ProductEntry) => void;
  onSelect: React.Dispatch<React.SetStateAction<ProductEntry[]>>;
  options: ProductEntry[];
}) => {
  React.useEffect(() => {
    if (option.entries.some((d) => d.preselected)) {
      const preSelectedList = option.entries.filter((v) => v.preselected);
      if (preSelectedList) onSelect((prevState) => [...prevState, ...preSelectedList]);
    }
  }, [onSelect, option.entries]);
  const { periodOnce, shippingCosts } = useFeaturesResult();
  const fileType = React.useCallback((data: string) => {
    const fileExt = getFileType(data);

    if (fileExt === "pdf") {
      return <PDF />;
    }

    return null;
  }, []);

  const handleChooseRadioOption = React.useCallback(
    (entry: ProductEntry) => {
      const getOptionToRemove = option.entries.find((item) => item.refRootId !== entry.refRootId);

      const filteredOption = option.entries.filter((item) => item.refRootId !== entry.refRootId);

      onSelect((prevState) => {
        const newState = prevState.filter((item) => item.refRootId !== getOptionToRemove?.refRootId);

        if (filteredOption.length === 1) {
          newState.push(entry);
          return newState;
        } else {
          const temp = filteredOption.find((item) => item.refRootId !== getOptionToRemove?.refRootId);

          const tempNewState = newState.filter((item) => item.refRootId !== temp?.refRootId);
          tempNewState.push(entry);

          return tempNewState;
        }
      });
    },
    [onSelect, option]
  );

  return (
    <div key={option.name}>
      <div className="text-xl font-medium mb-2">{option.name}</div>

      {option.entries.map((entry, index) => {
        return (
          <div key={entry.refRootId}>
            <div className="flex justify-between">
              <div>
                {option.type === 2 ? (
                  <>
                    <input
                      id={`${entry.refName}-${index + 1}`}
                      type="checkbox"
                      defaultChecked={entry.preselected}
                      className="mr-2 accent-primary"
                      disabled={entry.readonly}
                      onChange={() => {
                        if (options.some((v) => v.refRootId === entry.refRootId)) {
                          onRemove(entry);
                        } else onSelect((prevState) => [...prevState, entry]);
                      }}
                    />
                    <label htmlFor={`${entry.refName}-${index + 1}`} className="cursor-pointer">
                      {entry.refName}
                    </label>
                  </>
                ) : (
                  <>
                    <input
                      type="radio"
                      name={option.name}
                      id={entry.refName}
                      value={entry.code}
                      defaultChecked={entry.preselected}
                      className="mr-2 accent-primary"
                      onChange={() => handleChooseRadioOption(entry)}
                    />
                    <label htmlFor={entry.refName} className="cursor-pointer">
                      {entry.refName}
                    </label>
                  </>
                )}

                <div className="my-2">
                  <ReactHTMLParser>{entry?.description || ""}</ReactHTMLParser>
                </div>

                {entry.documentName && entry.documentUrl && (
                  <div className="mt-4 flex items-center">
                    <div className="mr-2">{fileType(entry.documentName)}</div>
                    <small>
                      <a
                        href={entry.documentUrl}
                        target="_blank"
                        className="text-blue-dark hover:underline"
                        rel="noreferrer"
                      >
                        {removeSymbolAndAddSpace(entry.documentName)}
                      </a>
                    </small>
                  </div>
                )}
              </div>

              {entry.imageUrl && (
                <div>
                  <img
                    src={entry.imageUrl}
                    alt={entry.imageName}
                    width="170px"
                    height="100px"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
            </div>

            {entry.monthlyPrices.map((price) => {
              return (
                <div key={price.pricePerMonth} className="flex justify-between items-center my-4">
                  <span>{price.to ? `• ${price.from}. - ${price.to}. Monat(e)` : `• ab ${price.from}. Monat(e)`}</span>

                  <span>{formatCurrency(price.pricePerMonth)}</span>
                </div>
              );
            })}

            {entry.priceOneTime ? (
              <>
                <div className="flex justify-between items-center my-4">
                  <div>• {periodOnce}</div>
                  <div>{formatCurrency(entry.priceOneTime)}</div>
                </div>
              </>
            ) : null}

            {entry.shippingCosts ? (
              <div className="flex justify-between items-center my-4">
                <span>• {shippingCosts}</span>
                <span>{formatCurrency(entry.shippingCosts)}</span>
              </div>
            ) : null}
          </div>
        );
      })}
      <Divider className="my-4" />
    </div>
  );
};

export default Options;
