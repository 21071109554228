import React from "react";

import useExternalConfigs from "./useExternalConfigs";

const useGeneralLabel = () => {
  const { data: config } = useExternalConfigs("generalLabel");
  const generalLabel = config?.data?.data?.generalLabel;

  const HTLabel = React.useMemo(() => {
    return generalLabel?.HTLabel ?? "HT";
  }, [generalLabel?.HTLabel]);

  const NTLabel = React.useMemo(() => {
    return generalLabel?.NTLabel ?? "NT";
  }, [generalLabel?.NTLabel]);

  const mandatoryLabel = React.useMemo(() => {
    return generalLabel?.mandatoryLabel ?? "*";
  }, [generalLabel?.mandatoryLabel]);

  return {
    HTLabel,
    NTLabel,
    generalLabel,
    mandatoryLabel,
  };
};
export default useGeneralLabel;
