import { useField } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import { Option } from "../../../../components/GetSelect/types";
import Input from "../../../../components/Input";
import { useConfig } from "../../../../context/Config";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import Headline from "../Headline";

const DesiredDeliveryDate = () => {
  const [{ value: hasDesiredDeliveryDate }, , { setValue: setHasDesiredDeliveryDate }] =
    useField("hasDesiredDeliveryDate");
  const [{ value: desiredDeliveryDate }, , { setValue: setDesiredDeliveryDate }] = useField("desiredDeliveryDate");
  const { config } = useConfig();
  const [fields] = useField("asOffer");
  const mandatoryFieldsCRM = useMandatoryfields();
  const { mandatoryLabel } = useGeneralLabel();

  const { startOfDelivery, desiredDate, desiredDateExplanation } = useFeaturesCC();
  const desiredDateAsapLabel =
    startOfDelivery?.options?.find((option: Option) => option?.value === "nextPossibleDate")?.label ?? false;

  const gridCols = { desktop: "grid-cols-4", tablet: "grid-cols-2", mobile: "grid-cols-1" };

  React.useEffect(() => {
    if (desiredDeliveryDate !== "" && hasDesiredDeliveryDate && desiredDateAsapLabel) {
      setDesiredDeliveryDate("");
    }
  }, [desiredDeliveryDate, hasDesiredDeliveryDate, setDesiredDeliveryDate, desiredDateAsapLabel]);
  const setActive = () => {
    setHasDesiredDeliveryDate(false);
  };

  return (
    <div className={`col-span-full grid gap-y-2 gap-x-4 ${gridCols[config.media ?? "desktop"]}`}>
      <div className="col-span-full">
        <Headline>{startOfDelivery?.label}</Headline>
        <div className="text-sm text-gray-dark">{desiredDateExplanation}</div>
      </div>

      {desiredDateAsapLabel && (
        <Checkbox name="hasDesiredDeliveryDate" label={desiredDateAsapLabel} className="col-span-full my-2" />
      )}
      <div id="test" onMouseDown={setActive} className="relative">
        <Input
          name="desiredDeliveryDate"
          className="w-full"
          type="datepicker"
          label={desiredDate + (!fields?.value && mandatoryFieldsCRM?.includes("terminwunsch") ? mandatoryLabel : "")}
          disabled={hasDesiredDeliveryDate && desiredDateAsapLabel}
        />
        <div
          className={`absolute w-full h-full top-0 ${hasDesiredDeliveryDate && desiredDateAsapLabel ? "" : "hidden"}`}
          onMouseDown={setActive}
        ></div>
      </div>
    </div>
  );
};

export default DesiredDeliveryDate;
