import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";
import useRadioClassName from "../../hooks/useRadioClassName";
const MeterType: React.FC = () => {
  const { oneTariff, twoTariffs, selectTariff } = useFeatureInputPage();
  const radioClassName = useRadioClassName();

  return (
    <div>
      <div className="mb-2 text-xs w-full">{selectTariff}</div>
      <div className={radioClassName}>
        <FormikRadio label={oneTariff} name="meterType" value="1,2" className="m-0 accent-primary" />
        <FormikRadio label={twoTariffs} name="meterType" value="3,4" className="m-0 accent-primary" />
      </div>
    </div>
  );
};

export default MeterType;
