import {addDays, addMonths} from "date-fns";


const validMoveInDate =(value: string,min:number,max:number)=> {


    function isMoveInDate(){
        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addDays(today, -min);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const movinInDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return movinInDate >= minDate;
        }
        return true
    }
    function isMaxMoveInDate(){
        if (value?.length === 10) {
            const today = new Date(new Date().setHours(0, 0, 0, 0));
            const minDate = addMonths(today, max);
            const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
            const movinInDate = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

            return movinInDate <= minDate;
        }
        return true
    }

    function minMovingInDate(){
        const today = new Date( new Date().setHours(0,0,0,0));
        return  addDays(today, -min)
    }
    function maxMovingInDate(){
        const today = new Date( new Date().setHours(0,0,0,0));
        return  addMonths(today, max)

    }

return {isMoveInDate,isMaxMoveInDate,minMovingInDate,maxMovingInDate}
}

export default validMoveInDate