import { boolean, object, string } from "yup";

const commonShapeLabelValue = (key: string) =>
  object().shape({
    label: string().required(),
    value: string().required(`Bitte die ${key} eingeben!`),
  });

const schema = object().shape({
  zipCode: string().required(),
  questionForm: commonShapeLabelValue("frage").required(),
  customerType: commonShapeLabelValue("Kundenart").required(),
  street: commonShapeLabelValue("Straße").required(),
  city: commonShapeLabelValue("Ort").required(),
  houseNumber: commonShapeLabelValue("Hausnnummer").required(),
  message: string().required("Bitte geben Sie Ihre Nachricht ein!"),
  phone: string().required("Bitte geben Sie Ihre Telefonnummer ein!"),
  email: string()
    .email("Bitte geben Sie Ihre E-Mail-Adresse ein!")
    .required("Bitte geben Sie Ihre E-Mail-Adresse ein!"),
  firstName: string().required("Bitte geben Sie Ihren Vornamen ein!"),
  lastName: string().required("Bitte geben Sie Ihren Nachnamen ein!"),
  isAcceptPrivacyPolicy: boolean()
    .required("Bitte akzeptieren Sie unsere Datenschutzbestimmungen")
    .oneOf([true], "Bitte akzeptieren Sie unsere Datenschutzbestimmungen"),
});

export default schema;
