import { useFormikContext } from "formik";
import React from "react";

import { handleErrKeys } from "../../../utils/handleErrKeys";
import { uniqueArr } from "../../../utils/uniqueArr";
import { useActivatedPanels, useContractConclusionFormName } from "../context";

const useCheckFormValidationAndRedirect = (data, nextStep) => {
  const { setFieldError } = useFormikContext();
  const { setFormName } = useContractConclusionFormName();
  const { setActivatedPanels } = useActivatedPanels();

  React.useEffect(() => {
    if (data) {
      if (!data.data.ok && data.data.fehler.length >= 1) {
        data.data.fehler.forEach((err) => {
          const keys = handleErrKeys(err.schluessel);
          if (keys) {
            setFieldError(keys, err.grund);
          }
        });
      } else {
        setFormName(nextStep);
        setActivatedPanels((prevState) => uniqueArr([...prevState, nextStep]));
      }
    }
  }, [
    data,
    nextStep,
    setActivatedPanels,
    setFieldError,
    setFormName,
  ]);
};

export default useCheckFormValidationAndRedirect;
