import { useField } from "formik";
import * as React from "react";

import Input from "../../../../components/Input";
import DatePicker from "../../../../components/Input/DatePicker";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const CounterData = () => {
  const { maLoID, meterNumber, currentMeterReading, showReadingDate, readingDate, counterData } = useFeaturesCC();
  const { HTLabel, NTLabel } = useGeneralLabel();
  const { values: trInputContext } = useTRInputContext();
  const { config } = useConfig();
  const gridCols = { desktop: "grid-cols-4", tablet: "grid-cols-2", mobile: "grid-cols-1" };
  const { mandatoryLabel } = useGeneralLabel();
  const [fields] = useField("asOffer");
  const mandatoryFieldsCRM = useMandatoryfields();
  const { responsiveColSpan } = useResponsiveClass();

  return (
    <>
      <div className="text-xl col-span-full font-medium">{counterData}</div>
      <div className={`col-span-full grid gap-x-4 gap-y-2 ${gridCols[config.media ?? "desktop"]}`}>
        <div className={responsiveColSpan("1", "1", "2")}>
          <Input
            name="counterNumber"
            label={
              meterNumber + (!fields?.value && mandatoryFieldsCRM?.includes("zaehlernummer") ? mandatoryLabel : "")
            }
            maxLength={50}
            inputType="otherNumber"
          />
        </div>
        <div className={responsiveColSpan("1", "1", "2")}>
          <Input name="maLoID" label={maLoID} onlyNumber maxLength={11} />
        </div>
        <div className={responsiveColSpan("1", "1", "2", "flex gap-y-2 gap-x-4")}>
          <Input
            name="actualCounterReading"
            label={
              (trInputContext.meterType === "1,2" ? currentMeterReading : currentMeterReading + " " + HTLabel) +
              (!fields?.value && mandatoryFieldsCRM?.includes("zaehlerstand") ? mandatoryLabel : "")
            }
            inputType="number"
            onlyNumber={true}
            maxLength={15}
          />
          {trInputContext.meterType === "3,4" && (
            <Input
              name="actualCounterNTReading"
              inputType="number"
              onlyNumber={true}
              maxLength={15}
              label={
                currentMeterReading +
                " " +
                NTLabel +
                (!fields?.value && mandatoryFieldsCRM?.includes("zaehlerstand") ? mandatoryLabel : "")
              }
            />
          )}
        </div>
        {showReadingDate && (
          <DatePicker
            name="readingDate"
            className="w-full"
            label={
              readingDate + (!fields?.value && mandatoryFieldsCRM?.includes("zaehlerstand_datum") ? mandatoryLabel : "")
            }
            openTop={true}
          />
        )}
      </div>
    </>
  );
};

export default CounterData;
