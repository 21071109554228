import React from "react";

import Button from "../../components/Button";
import Card from "../../components/Card";
import { useStepChange } from "../../context";
import { useSelectTariff } from "../../context/Tariff";
import { useSelectTelco } from "../../context/Telco";
import useGeneralLabel from "../../hooks/useGeneralLabel";
import formatCurrency from "../../utils/formatCurrency";
import scrollToTop from "../../utils/scrollToTop";
import { useContractConclusionFormName } from "../TCContractConclusion/context";
import convertRangeTimeStringToArray from "../TCResults/utils/rangeTime";
import PriceOverview from "./components/PriceOverview";
import useFeatureConfirmationPage from "./hooks/useFeatureConfirmationPage";
import Subsection from "./subsection";

const TCConfirmation = ({ data }) => {
  const { setFormName } = useContractConclusionFormName();
  const { selectedTariff } = useSelectTariff();
  const { selectedTelco } = useSelectTelco();
  const { setStep } = useStepChange();
  const {
    umzugLabel,
    adressdatenLabel,
    auftragsbestaetigungLabel,
    gesamtpreisLabel,
    preisinformationLabel,
    perYearLabel,
    perOnceLabel,
    grundpreisLabel,
    ihrTariffLabel,
    textConfirmLabel,
    backToHomeLabel,
    vertragsInformationLabel,
    vertragslaufzeitLabel,
    ihrVerbrauchLabel,
    kuendigungsfristLabel,
    verbrauchLabel,
    lieferanschriftLabel,
    geburtsdatumLabel,
    ihrVorversorgerLabel,
    vorversorgerLabel,
    bankverbindungLabel,
    ibanLabel,
    methodeLabel,
    sepa_lastschriftLabel,
    bicLabel,
    bankinstituteLabel,
    ueberweisungLabel,
    kontaktdatenLabel,
    mailadresseLabel,
    telefonnummerLabel,
    handynummerLabel,
    anschlussdatenLabel,
    wechselgrundLabel,
    lieferantenwechselLabel,
    downloadLabel,
    tarifInfoLabel,
    tariffTypeLabel,
    eintarifLabel,
    zweitarifLabel,
    EVNLabel,
    nextPossibleDateTextLabel,
    startOfDeliveryLabel,
    newTextLabel,
    numberPortabilityLabel,
    installLocationTitleLabel,
  } = useFeatureConfirmationPage();

  const { HTLabel, NTLabel} = useGeneralLabel();

  const handleOpenPdf = React.useCallback(() => {
    if (typeof window !== "undefined") {
      window.open(data?.pdfUrl, "_blank");
    }
  }, [data?.pdfUrl]);

  const portalDaten = data?.portalDaten;
  const tariffResult = data?.tarifErgebnis;
  const telcoResult = data?.tarifErgebnis;

  const reasonToChange = React.useMemo(() => {
    switch (portalDaten?.einzug_umzug) {
      case "E03":
        return lieferantenwechselLabel;
      case "tarifwechsel":
        return "Tarifwechsel";
      case "E01":
        return umzugLabel;
      default:
        return "";
    }
  }, [portalDaten?.einzug_umzug, umzugLabel, lieferantenwechselLabel]);

  const oneTimePrice = React.useMemo(() => {
    let result = 0;

    if (telcoResult?.setupCost) {
      result += telcoResult.setupCost;
    }

    if (telcoResult?.shippingCost) {
      result += telcoResult.shippingCost;
    }

    const optionsOneTimePrice = telcoResult?.selectedOption
      ?.map((opt) => opt.priceOneTime)
      .filter(Boolean);

    if (optionsOneTimePrice) {
      if (optionsOneTimePrice.length >= 1) {
        optionsOneTimePrice?.forEach((price) => {
          if (price && price !== 0) {
            result += price;
          }
        });
      }
    }

    const optionsShippingCost = telcoResult?.selectedOption
      ?.map((opt) => opt.shippingCosts)
      .filter((opt) => opt !== 0);

    if (optionsShippingCost) {
      if (optionsShippingCost.length >= 1) {
        optionsShippingCost.forEach((price) => {
          if (price && price !== 0) {
            result += price;
          }
        });
      }
    }

    const hardwareOneTimePrice = telcoResult?.selectedHardware
      ?.map((hardware) => hardware.priceOneTime)
      .filter(Boolean);

    if (hardwareOneTimePrice) {
      if (hardwareOneTimePrice.length >= 1) {
        hardwareOneTimePrice.forEach((price) => {
          if (price && price !== 0) {
            result += price;
          }
        });
      }
    }

    const hardwareShippingCost = telcoResult?.selectedHardware
      ?.map((hardware) => hardware.shippingCosts)
      .filter((hardware) => hardware !== 0);

    if (hardwareShippingCost) {
      if (hardwareShippingCost.length > 0) {
        hardwareShippingCost.forEach((shippingCost) => {
          if (shippingCost) {
            result += shippingCost;
          }
        });
      }
    }

    return result;
  }, [
    telcoResult?.selectedHardware,
    telcoResult?.selectedOption,
    telcoResult?.setupCost,
    telcoResult?.shippingCost,
  ]);

  const fullPriceMonthly = React.useMemo(() => {
    const normalPrice = [];

    if (telcoResult?.monthlyPrices) {
      if (telcoResult.monthlyPrices.length >= 1) {
        telcoResult.monthlyPrices
          .filter((range) => range.to === null)
          .forEach((range) => {
            const total = range.pricePerMonth;
            const rangeTime = `ab ${range.from}. Monat(e)`;
            normalPrice.push({ rangeTime, total });
          });
      }
    }

    if (telcoResult?.selectedOption) {
      if (telcoResult.selectedOption.length >= 1) {
        const optionMonthlyPrice = telcoResult.selectedOption
          .map((opt) => opt.monthlyPrices)
          .flat();

        const options = optionMonthlyPrice
          .filter((item) => item.to === null)
          .reduce(
            (acc, curr) => {
              return {
                ...curr,
                pricePerMonth:
                  Math.ceil((acc.pricePerMonth + curr.pricePerMonth) * 10) / 10,
              };
            },
            { pricePerMonth: 0 }
          );

        normalPrice.push({
          rangeTime: normalPrice[0]?.rangeTime || "",
          total: options?.pricePerMonth,
        });
      }
    }

    const hasHardwareOptions = telcoResult?.selectedHardware
      ?.map((item) => item.monthlyPrices)
      ?.flat();

    if (hasHardwareOptions) {
      if (hasHardwareOptions.length >= 1) {
        const hardware = telcoResult?.selectedHardware
          ?.map((item) => item.monthlyPrices)
          ?.flat()
          ?.reduce(
            (acc, curr) => {
              return {
                ...curr,
                pricePerMonth: acc.pricePerMonth + curr.pricePerMonth,
              };
            },
            { pricePerMonth: 0 }
          );

        normalPrice.push({
          rangeTime: normalPrice[0]?.rangeTime || "",
          total: hardware?.pricePerMonth || 0,
        });
      }
    }

    const isNormalPrice = normalPrice.every((item) => item.rangeTime !== "");

    return isNormalPrice
      ? normalPrice.reduce((acc, curr) => {
          const existing = acc.find((item) => {
            const itemRangeTime = convertRangeTimeStringToArray(item.rangeTime);
            const currRangeTime = convertRangeTimeStringToArray(curr.rangeTime);

            return itemRangeTime.every(
              (rangeTimeValue, index) => rangeTimeValue === currRangeTime[index]
            );
          });

          if (existing) {
            existing.total += curr.total;
          } else {
            acc.push(curr);
          }
          return acc;
        }, [])
      : [];
  }, [
    telcoResult?.monthlyPrices,
    telcoResult?.selectedHardware,
    telcoResult?.selectedOption,
  ]);

  const reducedMonthlyPrice = React.useMemo(() => {
    const reducedPrice = [];

    if (telcoResult?.monthlyPrices) {
      if (telcoResult.monthlyPrices.length >= 1) {
        telcoResult.monthlyPrices
          .filter((range) => range.to !== null)
          .forEach((range) => {
            const total = range.pricePerMonth;
            const rangeTime = `${range.from}. - ${range.to}. Monat(e)`;
            reducedPrice.push({ rangeTime, total });
          });
      }
    }

    const hasOptionsMonthlyPrice = telcoResult?.selectedOption
      ?.map((opt) => opt.monthlyPrices)
      .flat();

    if (hasOptionsMonthlyPrice) {
      if (hasOptionsMonthlyPrice.length >= 1) {
        const optionMonthlyPrice = telcoResult?.selectedOption
          ?.map((opt) => opt.monthlyPrices)
          .flat();

        optionMonthlyPrice
          ?.filter((range) => range.to !== null)
          .forEach((range) => {
            const total = range.pricePerMonth;
            const rangeTime = `${range.from}. - ${range.to}. Monat(e)`;
            reducedPrice.push({ rangeTime, total });
          });

        const minFrom =
          optionMonthlyPrice &&
          // eslint-disable-next-line no-unsafe-optional-chaining
          Math.min(...optionMonthlyPrice?.map((item) => item?.from));

        const options = optionMonthlyPrice
          ?.filter((item) => item.from === minFrom && item.to === null)
          .reduce(
            (acc, curr) => {
              return {
                ...curr,
                pricePerMonth: acc.pricePerMonth + curr.pricePerMonth,
              };
            },
            { pricePerMonth: 0 }
          );

        reducedPrice.push({
          rangeTime: reducedPrice[0]?.rangeTime || "",
          total: options?.pricePerMonth || 0,
        });
      }
    }

    const hasHardwareOptions = telcoResult?.selectedHardware
      ?.map((item) => item.monthlyPrices)
      .flat();

    if (hasHardwareOptions) {
      if (hasHardwareOptions.length >= 1) {
        const hardware = telcoResult?.selectedHardware
          ?.map((item) => item.monthlyPrices)
          .flat()
          .reduce(
            (acc, curr) => {
              return {
                ...curr,
                pricePerMonth: acc.pricePerMonth + curr.pricePerMonth,
              };
            },
            { pricePerMonth: 0 }
          );

        reducedPrice.push({
          rangeTime:
            hardware?.from && hardware?.to
              ? `${hardware.from}. - ${hardware.to}. Monat(e)`
              : reducedPrice[0]?.rangeTime || "",
          total: hardware?.pricePerMonth || 0,
        });
      }
    }

    const isReducedPrice = reducedPrice.every((item) => item.rangeTime !== "");

    return isReducedPrice
      ? reducedPrice.reduce((acc, curr) => {
          const existing = acc.find((item) => {
            const itemRangeTime = convertRangeTimeStringToArray(item.rangeTime);
            const currRangeTime = convertRangeTimeStringToArray(curr.rangeTime);

            return itemRangeTime.every(
              (rangeTimeValue, index) => rangeTimeValue === currRangeTime[index]
            );
          });

          if (existing) {
            existing.total += curr.total;
          } else {
            acc.push(curr);
          }
          return acc;
        }, [])
      : [];
  }, [
    telcoResult?.monthlyPrices,
    telcoResult?.selectedHardware,
    telcoResult?.selectedOption,
  ]);

  if (selectedTelco && telcoResult) {
    return (
      <>
        <div className="text-center">{telcoResult.providerName}</div>
        <div className="text-center text-xl">{textConfirmLabel}</div>

        <div className="text-center">
          <Button onClick={handleOpenPdf}>{downloadLabel}</Button>
        </div>

        <section className="grid grid-cols-2 gap-4">
          <Card>
            <div className="text-center text-xl font-bold">
              {auftragsbestaetigungLabel}
            </div>
            <section className="flex justify-between items-center mt-4">
              <section>
                <div className="font-bold">{ihrTariffLabel}</div>
                <div className="font-bold text-2xl">
                  {telcoResult.productName}
                </div>
              </section>
              <section>
                <div className="font-bold">{gesamtpreisLabel}</div>
                <div className="font-bold text-2xl">
                  {formatCurrency(oneTimePrice)} {perOnceLabel}
                </div>
              </section>
            </section>

            <div className="my-6 text-center">{preisinformationLabel}</div>

            <div className="flex justify-between items-center">
              <div>Aktivierungskosten</div>
              <div>{formatCurrency(data?.tarifErgebnis?.setupCost || 0)}</div>
            </div>

            {selectedTelco.selectedOption?.map((item) => {
              if (item.priceOneTime)
                return (
                  <section
                    key={item.refName}
                    className="flex justify-between my-2 items-center"
                  >
                    <div>{item.refName}</div>
                    <div>{formatCurrency(item.priceOneTime)}</div>
                  </section>
                );
            })}

            {selectedTelco.selectedHardware?.map((item) => {
              if (item.priceOneTime)
                return (
                  <section
                    key={item.refName}
                    className="flex justify-between items-center"
                  >
                    <div>{item.refName}</div>
                    <div>{formatCurrency(item.priceOneTime)}</div>
                  </section>
                );
            })}

            <div className="flex justify-between items-center mt-4">
              <div className="font-bold">Gesamt einmalig</div>
              <div className="font-bold">{formatCurrency(oneTimePrice)}</div>
            </div>

            <div className="mt-4">
              <div>{grundpreisLabel}</div>
              {selectedTelco.monthlyPrices.map((item) => {
                return (
                  <section
                    key={item.pricePerMonth}
                    className="flex justify-between"
                  >
                    <div>
                      {item.to
                        ? `${item.from}. - ${item.to}. Monat(e)`
                        : `ab ${item.from}. Monat(e)`}
                    </div>

                    <div>{formatCurrency(item.pricePerMonth)}</div>
                  </section>
                );
              })}
            </div>

            <div className="mt-4">
              {selectedTelco.selectedOption?.map((item) => {
                if (item.monthlyPrices.length >= 1)
                  return (
                    <section key={item.refName} className="my-4">
                      <div>{item.refName}</div>
                      {item.monthlyPrices.map((price) => {
                        return (
                          <section
                            key={price.pricePerMonth}
                            className="flex justify-between items-center"
                          >
                            <div>
                              {price.to
                                ? `${price.from} - ${price.to}. Monat(e)`
                                : `ab ${price.from}. Monat(e)`}
                            </div>
                            <div>{formatCurrency(price.pricePerMonth)}</div>
                          </section>
                        );
                      })}
                    </section>
                  );
              })}
            </div>

            <div className="font-bold">Gesamt monatlich</div>
            {reducedMonthlyPrice.map((item) => {
              return (
                <section
                  key={item.total}
                  className="flex justify-between items-center font-bold"
                >
                  <div>{item.rangeTime}</div>
                  <div>{formatCurrency(item.total)}</div>
                </section>
              );
            })}

            {fullPriceMonthly.map((item) => {
              return (
                <section
                  key={item.total}
                  className="flex justify-between items-center font-bold"
                >
                  <div>{item.rangeTime}</div>
                  <div>{formatCurrency(item.total)}</div>
                </section>
              );
            })}
          </Card>

          <Card>
            <div className="font-bold text-lg">{lieferanschriftLabel}</div>

            <section className="my-4">
              <div className="font-bold">{adressdatenLabel}</div>
              <div>
                {portalDaten?.la_anrede} {portalDaten?.la_vorname}{" "}
                {portalDaten?.la_nachname}
              </div>
              <div>
                {portalDaten?.la_strasse} {portalDaten?.la_hausnummer}
              </div>
              <div>
                {portalDaten?.la_plz} {portalDaten?.la_ort}
              </div>
            </section>

            <section className="my-4">
              <div className="font-bold">{installLocationTitleLabel}</div>
              <div>{portalDaten?.la_installationsort}</div>
            </section>

            <section className="my-4">
              <div className="font-bold text-lg">{anschlussdatenLabel}</div>
              <div className="flex justify-between items-center">
                <div>{EVNLabel}</div>
                <div>{portalDaten?.einzelverbindungsnachweis}</div>
              </div>

              <div className="flex justify-between items-center">
                <div>{startOfDeliveryLabel}</div>
                <div>
                  {!portalDaten?.terminwunsch
                    ? nextPossibleDateTextLabel
                    : portalDaten?.terminwunsch}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div>{numberPortabilityLabel}</div>
                <div>
                  {portalDaten?.rufnummeruebernahme === "new"
                    ? newTextLabel
                    : ""}
                </div>
              </div>
            </section>

            <section className="my-4">
              <div className="font-bold text-lg">Bankdaten</div>
              <div className="flex justify-between items-center">
                <div>{methodeLabel}</div>
                <div>
                  {portalDaten?.paymentMethod === "directDebit"
                    ? { sepa_lastschriftLabel }
                    : { ueberweisungLabel }}
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div>{ibanLabel}</div>
                <div>{portalDaten?.iban}</div>
              </div>

              <div className="flex justify-between items-center">
                <div>{bicLabel}</div>
                <div>{portalDaten?.bic}</div>
              </div>

              <div className="flex justify-between items-center">
                <div>{bankinstituteLabel}</div>
                <div>{portalDaten?.geldinstitut}</div>
              </div>
            </section>

            <section className="my-4">
              <div className="font-bold text-lg">{kontaktdatenLabel}</div>
              <div className="flex justify-between items-center">
                <div>{mailadresseLabel}</div>
                <div>{portalDaten?.la_email}</div>
              </div>

              <div className="flex justify-between items-center">
                <div>{telefonnummerLabel}</div>
                <div>{portalDaten?.la_telefonnummer}</div>
              </div>
              {portalDaten?.la_mobilnummer && (
                <div className="flex justify-between items-center">
                  <div>{handynummerLabel}</div>
                  <div>{portalDaten?.la_mobilnummer}</div>
                </div>
              )}
              <div className="flex justify-between items-center">
                <div>{geburtsdatumLabel}</div>
                <div>
                  {new Date(portalDaten?.la_geburtsdatum).toLocaleDateString('de-DE')}
                </div>
              </div>
            </section>
          </Card>
        </section>
      </>
    );
  }

  return (
    <>
      <div className="my-6 text-center font-bold">
        {tariffResult?.versorgerName}
      </div>

      <div className="text-2xl text-center">{textConfirmLabel}</div>

      <Button
        className="w-full my-2 p-4"
        variant="primary"
        onClick={handleOpenPdf}
      >
        {downloadLabel}
      </Button>

      <section className="grid grid-cols-2 gap-4">
        <Card>
          <div className="text-center text-xl font-bold">
            {auftragsbestaetigungLabel}
          </div>

          <section className="flex justify-between items-center mt-4">
            <section>
              <div className="font-bold">{ihrTariffLabel}</div>
              <div className="font-bold text-2xl">
                {tariffResult?.tarifName}
              </div>
            </section>

            <section>
              <div className="font-bold">{gesamtpreisLabel}</div>
              <div className="font-bold text-2xl">
                {formatCurrency(
                  (selectedTariff?.isBrutto
                    ? tariffResult?.gesamtpreisBrutto
                    : tariffResult?.gesamtpreisNetto) || 0
                )}
                {perYearLabel}
              </div>
            </section>
          </section>
          <div className="my-6 text-center font-bold">
            {preisinformationLabel}
          </div>

          <PriceOverview />

          <div className="my-6 text-center font-bold">{tarifInfoLabel}</div>
          <div>{tariffResult?.tarifInfo}</div>

          <div className="my-2 mt-6 font-bold">{vertragsInformationLabel}</div>
          <hr />
          <Subsection
            text={vertragslaufzeitLabel}
            value={`${tariffResult?.vertragslaufzeit}`}
          />
          <Subsection
            text={kuendigungsfristLabel}
            value={`${tariffResult?.kuendigungsfrist}`}
          />

          <div className="my-2 mt-6 font-bold">{ihrVerbrauchLabel}</div>
          <hr />
          <Subsection
            text={verbrauchLabel + " " + HTLabel}
            value={`${data.tarifParameter?.verbrauchHT} kWh`}
            />
          {data.tarifParameter?.verbrauchNT && 
          <Subsection
            text={verbrauchLabel + " " + NTLabel}
            value={`${data.tarifParameter?.verbrauchNT} kWh`}
            />
          }
          <Subsection
            text={tariffTypeLabel}
            value={`${
              data.tarifParameter?.verbrauchNT
                ? zweitarifLabel
                : eintarifLabel
            }`}
          />
        </Card>

        <Card>
          <div className="my-2 font-bold">{lieferanschriftLabel}</div>
          <hr />
          <div className="font-bold text-sm">Addressdaten</div>
          <div className="text-sm">
            {portalDaten?.la_firma}
            {portalDaten?.la_anrede} {portalDaten?.la_vorname}{" "}
            {portalDaten?.la_nachname}
          </div>
          <div className="text-sm">
            {portalDaten?.la_strasse} {portalDaten?.la_hausnummer}
          </div>
          <div className="text-sm">
            {portalDaten?.la_plz} {portalDaten?.la_ort}
          </div>
          <div className="my-2 font-bold">{anschlussdatenLabel}</div>
          <hr />
          <Subsection text={wechselgrundLabel} value={reasonToChange} />
          <div className="my-2 font-bold">{ihrVorversorgerLabel}</div>
          <hr />
          <Subsection
            text={vorversorgerLabel}
            value={`${portalDaten?.vorlieferant_name}`}
          />
          <div className="my-2 font-bold">{bankverbindungLabel}</div>
          <hr />
          {portalDaten?.paymentMethod === "directDebit" ? (
            <>
              <Subsection text={methodeLabel} value={sepa_lastschriftLabel} />
              <Subsection text={ibanLabel} value={portalDaten?.iban} />
              <Subsection text={bicLabel} value={portalDaten?.bic} />
              <Subsection
                text={bankinstituteLabel}
                value={portalDaten?.geldinstitut}
              />
            </>
          ) : (
            <Subsection text={methodeLabel} value={ueberweisungLabel} />
          )}

          <div className="my-2 font-bold">{kontaktdatenLabel}</div>
          <hr />
          <Subsection
            text={mailadresseLabel}
            value={`${portalDaten?.la_email}`}
          />
          <Subsection
            text={telefonnummerLabel}
            value={`${portalDaten?.la_telefonnummer}`}
          />
          {portalDaten?.la_mobilnummer && (
            <Subsection
            text={handynummerLabel}
            value={`${portalDaten?.la_mobilnummer}`}
            />
          )}
          <Subsection
            text={geburtsdatumLabel}
            value={`${new Date(portalDaten?.la_geburtsdatum).toLocaleDateString('de-De')}`}
          />
        </Card>
      </section>

      <Button
        variant="primary"
        className="w-full my-4 p-4"
        type="button"
        onClick={() => {
          setStep(1);
          setFormName("contactForm");
          scrollToTop();
        }}
      >
        {backToHomeLabel}
      </Button>
    </>
  );
};

export default TCConfirmation;
