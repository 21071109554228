import React from "react";

import Button from "../../../../../components/Button";
import Card from "../../../../../components/Card";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import { useStepChange } from "../../../../../context";
import { useTRInputContext } from "../../../../../context/FormInputData";
import { ProductEntry, Telco } from "../../../../../context/Telco";
import capitalize from "../../../../../utils/capitalize";
import formatCurrency from "../../../../../utils/formatCurrency";
import removeSymbolAndAddSpace from "../../../../../utils/removeSymbolAndAddSpace";
import WarningDialog from "../../../../TCContractConclusion/components/WarningDialog";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import createRange from "../../../utils/createRange";

const SelectedTariff = ({
  product,
  options,
  hardwares,
}: {
  product: Telco;
  options: ProductEntry[];
  hardwares: ProductEntry[];
}) => {
  const { step } = useStepChange();
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const { values } = useTRInputContext();
  const {
    customerType,
    business,
    private: privateLabel,
    contractperiod,
    contractextension,
    baseprice,
    priceOnce,
    optionInfo,
    periodOnceSummary,
    shippingCosts: shippingCostsLabel,
    tariffDetails,
    infinityPeriod,
    periodMonthlySummary,
    noticePeriod,
    activationPrice,
    months,
  } = useFeaturesResult();

  const shippingCosts = React.useMemo(() => {
    const optionsShippingCost = options.map((option) => option.shippingCosts);
    const hardwareShippingCost = hardwares.map((hardware) => hardware.shippingCosts);

    const numberList = [...optionsShippingCost, ...hardwareShippingCost].filter((i) => i !== null);

    if (numberList.length > 0) return Math.max(...numberList);
    else return null;
  }, [hardwares, options]);

  const oneTimePrice = React.useMemo(() => {
    let result = 0;

    if (product.setupCost) {
      result += product.setupCost;
    }

    if (product.shippingCost) {
      result += product.shippingCost;
    }

    const optionsOneTimePrice = options.map((opt) => opt.priceOneTime).filter(Boolean);

    if (optionsOneTimePrice.length >= 1) {
      optionsOneTimePrice?.forEach((price) => {
        if (price && price !== 0) {
          result += price;
        }
      });
    }

    const hardwareOneTimePrice = hardwares.map((hardware) => hardware.priceOneTime).filter(Boolean);

    if (hardwareOneTimePrice.length >= 1) {
      hardwareOneTimePrice.forEach((price) => {
        if (price && price !== 0) {
          result += price;
        }
      });
    }

    if (shippingCosts) {
      result += shippingCosts;
    }

    return result;
  }, [hardwares, options, product.setupCost, product.shippingCost, shippingCosts]);

  React.useEffect(() => {
    let tempReducedPrice = 0;
    let tempNormalPrice = 0;

    const productSchema = product.monthlyPrices ?? [];
    const optionSchema = options.map((o) => o.monthlyPrices).flat();
    const hardwareSchema = hardwares.map((h) => h.monthlyPrices).flat();
    const data = [...productSchema, ...optionSchema, ...hardwareSchema];

    const result = data.sort((a, b) => {
      if (!a.to) return 1;
      if (!b.to) return -1;
      return a.to - b.to;
    });

    const rangeTariff = result
      .filter((item) => item.to)
      .reduce<{ from: number; to: number | undefined }>(
        (acc, curr) => {
          return {
            ...acc,
            from: curr.from,
            to: curr.to,
          };
        },
        { from: 1, to: undefined }
      );

    const rangeTime = createRange(rangeTariff);

    result.forEach((item) => {
      if (!rangeTime.includes(0)) {
        const isReducedPrice = rangeTime.includes(item.from);

        if (isReducedPrice) {
          tempReducedPrice = tempReducedPrice + item.pricePerMonth;
        } else {
          tempNormalPrice = tempNormalPrice + item.pricePerMonth;
        }

        if (!item.to && rangeTime.includes(item.from)) {
          tempNormalPrice = tempNormalPrice + item.pricePerMonth;
        }
      }
    });
  }, [hardwares, options, product.monthlyPrices]);

  return (
    <>
      <WarningDialog modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <ErrorBoundary>
        <Card>
          <div className="text-2xl font-bold">{product?.productName}</div>
          <div className="mb-4">{product?.providerName}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: product?.productDescription || "",
            }}
          />

          {hardwares[0]?.documentUrl && (
            <section className="mb-2 mt-4">
              <div className="text-lg font-bold">{optionInfo}</div>
              {hardwares.map((val) => {
                if (val.documentName && val.documentUrl) {
                  return (
                    <section key={val.refRootId} className="my-4">
                      <a href={val.documentUrl} target="_blank" rel="noreferrer">
                        {capitalize(removeSymbolAndAddSpace(val.documentName))}
                      </a>
                    </section>
                  );
                }
              })}
            </section>
          )}

          <div className="mt-6 grid gap-2">
            <div>
              <div className="text-xl font-bold">{tariffDetails}</div>
              <div className="flex w-full mb-2 justify-between">
                <div className="flex mr-2">{contractperiod}</div>
                <div className="flex justify-end text-right">{product?.contractPeriodDate || infinityPeriod}</div>
              </div>
              <div className="flex w-full mb-2 justify-between">
                <div className="flex mr-2">{contractextension}</div>
                <div className="flex justify-end text-right">{product?.contractExtensionDate || infinityPeriod}</div>
              </div>
              <div className="flex w-full mb-2 justify-between">
                <div className="flex mr-2">{noticePeriod}</div>
                <div className="flex justify-end text-right">{product?.contractPeriodDate || infinityPeriod}</div>
              </div>
            </div>

            <div>
              <div className="tex-3xl font-bold">{periodOnceSummary}</div>

              {product.setupCost && (
                <div className="mt-4 flex justify-between items-center">
                  <span>{activationPrice}</span>
                  <span>{formatCurrency(product?.setupCost)}</span>
                </div>
              )}

              {options.map((opt) => {
                if (opt.priceOneTime || opt.shippingCosts)
                  return (
                    <>
                      <div key={opt.refRootId} className="mt-4 flex justify-between items-center">
                        <span>{opt.refName}</span>
                        {opt.priceOneTime && <span>{formatCurrency(opt.priceOneTime)}</span>}
                      </div>
                    </>
                  );
              })}

              {values?.customerType === "0" &&
                hardwares.map((hardware) => {
                  if (hardware.priceOneTime || hardware.shippingCosts)
                    return (
                      <>
                        <div key={hardware.refRootId} className="mt-4 flex justify-between items-center">
                          <span>{hardware.refName}</span>
                          {hardware.priceOneTime && <span>{formatCurrency(hardware.priceOneTime)}</span>}
                        </div>
                      </>
                    );
                })}
              {shippingCosts && (
                <div className="mt-4 flex justify-between items-center">
                  <span>{shippingCostsLabel}</span>
                  <span>{formatCurrency(shippingCosts)}</span>
                </div>
              )}

              <div className="flex justify-between items-center mt-4">
                <div className="font-bold">{priceOnce}</div>
                <div>{formatCurrency(oneTimePrice)}</div>
              </div>
            </div>

            <div>
              <div className="font-bold">{periodMonthlySummary}</div>

              <div className="mt-4">{baseprice}</div>
              {product.monthlyPrices &&
                product.monthlyPrices?.map((price) => {
                  return (
                    <div key={price.pricePerMonth} className="flex justify-between items-center">
                      <span>{price.to ? `${price.from}. - ${price.to}. Monat(e)` : `ab ${price.from}. Monat(e)`}</span>
                      <span key={price.pricePerMonth}>{formatCurrency(price.pricePerMonth)}</span>
                    </div>
                  );
                })}

              {options.map((opt) => {
                if (opt.monthlyPrices.length >= 1)
                  return (
                    <section key={opt.refRootId} className="mt-4">
                      <div>{opt.refName}</div>
                      <div>
                        {opt.monthlyPrices.length >= 1 &&
                          opt.monthlyPrices.map((price) => {
                            return (
                              <div key={price.pricePerMonth} className="flex justify-between items-center">
                                <span>
                                  {price.to ? `${price.from}. - ${price.to}. ${months}` : `ab ${price.from}. ${months}`}
                                </span>
                                <span key={price.pricePerMonth}>{formatCurrency(price.pricePerMonth)}</span>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  );
              })}

              {hardwares.map((hardware) => {
                if (hardware.monthlyPrices.length >= 1) {
                  return (
                    <section key={hardware.refRootId} className="mt-4">
                      <div>{hardware.refName}</div>
                      <div>
                        {hardware.monthlyPrices.length >= 1 &&
                          hardware.monthlyPrices.map((price) => {
                            return (
                              <div key={price.pricePerMonth} className="flex justify-between items-center">
                                <span>
                                  {price.to ? `${price.from}. - ${price.to}. ${months}` : `ab ${price.from}. ${months}`}
                                </span>
                                <span key={price.pricePerMonth}>{formatCurrency(price.pricePerMonth)}</span>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  );
                }
              })}
            </div>
            {step === 3 && (
              <Button variant="primary" className="w-full p-2 mb-4" type="submit" onClick={() => setModalIsOpen(true)}>
                Produktauswahl ändern
              </Button>
            )}

            {step === 3 && (
              <div className="grid grid-cols-2 mt-4 text-center divide-x">
                <section id="city">
                  <div className="font-bold">Ort</div>
                  <div>
                    {typeof values?.street !== "string" && values?.street?.value}{" "}
                    {typeof values?.houseNumber !== "string" && values?.houseNumber?.value}
                  </div>
                  <div>
                    {values?.zipCode} {typeof values?.city !== "string" && values?.city?.value}
                  </div>
                </section>

                <section>
                  <div className="font-bold">{customerType}</div>
                  <div>{values?.customerType === "0" ? privateLabel : business}</div>
                </section>
              </div>
            )}
          </div>
        </Card>
      </ErrorBoundary>
    </>
  );
};

export default SelectedTariff;
