import React from "react";

const Chevron = ({
  className = "fill-content-text",
  size = 24,
  rotate = false,
}: {
  className?: string;
  size?: number;
  rotate?: boolean;
}) => {
  return (
    <svg height={size} width={size} viewBox="0 0 24 24" className={(rotate ? "rotate-180" : "") + " " + className}>
      <path d="M12 15.4L6 9.4L7.4 8L12 12.6L16.6 8L18 9.4L12 15.4Z" />
    </svg>
  );
};
export default Chevron;
