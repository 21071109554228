import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Divider from "../../../../components/Divider";
import Input from "../../../../components/Input";
import DatePicker from "../../../../components/Input/DatePicker";
import RangeSlider from "../../../../components/Slider";
import { useTariff } from "../../../../context";
import useDebounce from "../../../../hooks/useDebounce";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import deadLineDayValid from "../../../../utils/deadLineDayValid";
import { isValidDateAsString } from "../../../../utils/isValidDate";
import CustomerTypeRadio from "../../../TCInputs/components/Radio/CustomerType";
import MeasurementType from "../../../TCInputs/components/Radio/MeasurementType";
import MeterType from "../../../TCInputs/components/Radio/MeterType";
import TariffType from "../../../TCInputs/components/Radio/TariffType";
import useFeatureInputPage from "../../../TCInputs/hooks/useFeatureInputPage";
import useSimplifierResultPage from "../../hooks/useSimplifier";
import { InputFormModalFormik } from "../../types/InputFormModalFormik";
import TRAddressResultList from "../ResultsList/Address";

const InputFormModal = ({
  setIsShowInputTariff,
}: {
  setIsShowInputTariff: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { values, handleSubmit } = useFormikContext<InputFormModalFormik>();
  const debounceValue = useDebounce(values, 600);
  const { data, ...rest } = useSimplifierResultPage(debounceValue);
  const {
    consumption,
    usage,
    usageRuler,
    usageRulerValues,
    isHtNt,
    deadlineActivated,
    deadlineMaxDaysInFuture,
    deadlineMinDaysInPast,
    stichtag,
  } = useFeatureInputPage();
  const { HTLabel, NTLabel } = useGeneralLabel();
  const tariff = useTariff();
  const { isMaxDeadLineDay, isMinDeadLineDay } = deadLineDayValid(
    values.stichtag,
    deadlineMaxDaysInFuture,
    deadlineMinDaysInPast
  );
  const enabled =
    data?.unique &&
    values.city.value != "" &&
    (tariff.id === "7" ||
      (parseInt(values.oneTariff.replace(".", "")) > 0 &&
        (parseInt(values.twoTariff.replace(".", "")) > 0 || values.meterType !== "3,4"))) &&
    ((isValidDateAsString(values.stichtag ?? "") && isMinDeadLineDay() && isMaxDeadLineDay()) ||
      values.stichtag?.length === 0 ||
      values.stichtag === undefined);

  const sliderValues = React.useMemo(
    () => (usageRulerValues ? usageRulerValues : tariff.id === "2" ? [13000, 20000, 27000] : [1700, 2500, 3200, 4000]),
    [tariff.id, usageRulerValues]
  );
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-2 mt-4">
        <Divider className="mb-2" />
        <CustomerTypeRadio modal={true} />
        <TRAddressResultList {...rest} modal={true} />
        <div className="flex flex-col gap-4 mb-2">
          {tariff.id !== "2" && tariff.id !== "7" && isHtNt && <MeterType />}
          {tariff.id === "3" && (
            <div>
              <TariffType />
            </div>
          )}

          {tariff.id === "4" && (
            <div>
              <MeasurementType />
            </div>
          )}
        </div>
        {tariff.id !== "7" && (
          <div className="flex flex-row flex-wrap gap-4 w-full">
            <div className="min-w-32 grow basis-32">
              <Input
                name="oneTariff"
                placeholder="Verbrauch (HT)"
                label={values.meterType === "3,4" ? consumption + " " + HTLabel : consumption}
                onlyNumber
                maxLength={7}
                usage={usage}
              />
            </div>
            {values.meterType === "3,4" && (
              <div className="min-w-32 grow basis-32">
                <Input name="twoTariff" label={consumption + " " + NTLabel} onlyNumber maxLength={7} usage={usage} />
              </div>
            )}
            {values.customerType === "0" && usageRuler && values.meterType === "1,2" && (
              <div className="w-full mt-4 col-span-2">
                <RangeSlider name="oneTariff" allowedValues={sliderValues} />
              </div>
            )}
          </div>
        )}
        {deadlineActivated && <DatePicker name="stichtag" label={stichtag} className="w-full" />}
        <div className="flex justify-end flex-wrap gap-4 mt-4">
          <Button variant="secondary" onClick={() => setIsShowInputTariff(false)} id="InputFormModal_abort">
            Abbrechen
          </Button>
          <Button
            id="InputFormModal_submit"
            variant="primary"
            type="submit"
            className="p-2"
            disabled={!enabled}
            onClick={() => {
              handleSubmit();
              setIsShowInputTariff(false);
            }}
          >
            Übernehmen
          </Button>
        </div>
      </div>
    </form>
  );
};

export default InputFormModal;
