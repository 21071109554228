import React from "react";

import Divider from "../../../../../components/Divider";
import useFeaturesResult from "../../../hooks/useFeaturesResult";
import RowItem from "../RowItem";

interface PriceGuaranteeProps { 
  priceguarantee:number | null, 
  vertragsverlaengerung:string, 
  kuendigungsfrist:number, 
  vertragslaufzeit:string, 
}

const PriceGuarantee = ({ priceguarantee, vertragsverlaengerung, kuendigungsfrist, vertragslaufzeit }:PriceGuaranteeProps) => {
  const { cancellationperiodLabel, contractperiodLabel, contractextensionLabel, priceguaranteeLabel, monthsLabel } =
    useFeaturesResult();
  return (
    <section id="price_guarantee">
      <Divider />
      <div className="flex flex-col gap-2">
        {vertragsverlaengerung !== "-" && <RowItem label={contractextensionLabel} value={vertragsverlaengerung} />}
        {vertragslaufzeit !== "-" && <RowItem label={contractperiodLabel} value={vertragslaufzeit} />}
        <RowItem label={cancellationperiodLabel} value={kuendigungsfrist} />
        {Boolean(priceguarantee) && <RowItem label={priceguaranteeLabel} value={String(priceguarantee) + " " + monthsLabel} />}
      </div>
    </section>
  );
};

export default PriceGuarantee;
