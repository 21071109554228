import { useField } from "formik";
import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

import FormControl from "../../../../components/FormControl";
import Trashcan from "../../../../components/icons/trashcan";
import toast from "../../../../components/Toast";

const DigitalSignature = ({ name }) => {
  const signatureRef = useRef(null);
  const sectionRef = useRef(null);
  const [{ value }, , { setValue }] = useField({ name });

  useEffect(() => {
    if (!signatureRef.current) return;

    signatureRef.current.fromDataURL(value, {
      ratio: 1,
      width: sectionRef.current?.clientWidth || 1000,
      height: 400,
    });
    // eslint-disable-next-line
  }, []);

  const handleOnEnd = React.useCallback(async () => {
    if (!signatureRef.current) return;

    if (signatureRef.current?.isEmpty()) {
      setValue("");
      toast.error("Sie müssen erst unterschreiben!");
      return;
    }

    const base64 = signatureRef.current?.toDataURL();
    setValue(base64);
  }, [setValue]);

  const handleClear = () => {
    signatureRef.current?.clear();
    setValue("");
  }

  return (
    <section ref={sectionRef}>
      <FormControl>
        <>
          <div
            style={{ borderWidth: "1px" }}
            className="my-6 border-content-border rounded border-solid"
          >
            <span onClick={()=>handleClear()} className="float-right p-2"><Trashcan /></span>
            <SignatureCanvas
              ref={signatureRef}
              velocityFilterWeight={0.3}
              canvasProps={{
                width: sectionRef.current?.clientWidth || 1000,
                height: 400,
                className: "sig-canvas",
              }}
              clearOnResize={false}
              onEnd={handleOnEnd}
            />
          </div>
        </>
      </FormControl>
    </section>
  );
};

export default DigitalSignature;
