import { number, object, string } from "yup";

import deadLineDayValid from "../../../../utils/deadLineDayValid";
import {isValidDateAsString} from "../../../../utils/isValidDate";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const getValidationSchema = (zipCodeInvalidMessageLabel: string, zipCodeValidationLabel: string) => {
  const { deadlineMaxDaysInFuture, deadlineMinDaysInPast } = useFeatureInputPage();

  return object({
    zipCode: string().min(5, zipCodeValidationLabel).required(zipCodeInvalidMessageLabel),
    oneTariff: number().min(1, "Eingabe ungültig").required("Eingabe ungültig"),
    stichtag:string() .test({
      name: "stichtag",
      message: "Datum ungültig",
      test: (value) => {
        const { isMaxDeadLineDay, isMinDeadLineDay } = deadLineDayValid(value,  deadlineMaxDaysInFuture,deadlineMinDaysInPast);

        return (isValidDateAsString(value ?? "")  && isMinDeadLineDay() && isMaxDeadLineDay()) || value?.length === 0 || value === undefined;
      },
    }),
    twoTariff: string().test("", "Eingabe ungültig", (value, context) => {
      if (context.parent.meterType === "3,4") {
        return value ? parseInt(value) > 0 : false;
      } else {
        return true;
      }
    }),
  });
};

export default getValidationSchema;
