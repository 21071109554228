import { useMutation } from "react-query";

import { useConfig, useParameter, useTariff } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useCalcResult } from "../../../../context/CalculationResult";
import { useSupplierInfo } from "../../../../context/SupplierInfo";
import ProxyInstance from "../../../../services/proxy";
import queryParamSimplifier from "../../../TCInputs/utils/queryParamSimplifier";

const useSubmitTariffResult = () => {
  const config = useConfig();
  const tariff = useTariff();
  const { setSupplierInformation } = useSupplierInfo();
  const { calcParams } = useCalcParams();
  const { kundenart, tariftyp, style, ...restParams } = useParameter();
  const { setCalcResult } = useCalcResult();

  return useMutation(
    ["SUBMIT_TARIFF_RESULT"],
    async (values) => {
      const queryParameter = queryParamSimplifier({
        ...values,
        ...restParams,
        calculatorType: tariff.id,
        tariftyp: tariff.tariftyp,
        grundversorger: calcParams?.grundversorger || undefined,
        netzbetreiber: calcParams?.netzbetreiber || undefined,
      });

      const baseUrl =
        tariff.id === 7
          ? `/prima/calculation/telco/tr/v1/calculate?${queryParameter}`
          : `/simplifier/calc/${tariff.tariffType}/?${queryParameter}`;

      try {
        const { data } = await ProxyInstance(baseUrl, {
          apikey: config.apikey || "",
        });

        const response = data?.data?.data;
        const vergleichsData = response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis.filter(
          (supplier) => {
            return supplier.isVergleichsTarif === true;
          }
        );
        const supplierInfo =
          values.compareType === 1
            ? {"compareType": "individueller Vergleichstarif"}
            : response?.calculationResult.ergebnisContainer?.[0].versorgerInformation?.[0];

        const suppliertariffName = vergleichsData[0]?.tarifName;

        const suppliertariffGesamtpreisBrutto = vergleichsData[0]?.gesamtpreisBrutto;

        const suppliertariffGrundpreisBrutto = vergleichsData[0]?.grundpreisBrutto;

        const suppliertariffArbeitspreisHTCtKWhBrutto = vergleichsData[0]?.arbeitspreisHTCtKWhBrutto;

        const suppliertariffArbeitspreisNTCtKWhBrutto = vergleichsData[0]?.arbeitspreisNTCtKWhBrutto;

        const versorgerName = vergleichsData[0]?.versorgerName;

        if (supplierInfo) {
          setSupplierInformation({
            ...supplierInfo,
            versorgerName,
            suppliertariffName,
            suppliertariffGesamtpreisBrutto,
            suppliertariffGrundpreisBrutto,
            suppliertariffArbeitspreisHTCtKWhBrutto,
            suppliertariffArbeitspreisNTCtKWhBrutto,
          });
        }
        setCalcResult({
          results: response?.calculationResult?.ergebnisContainer?.[0]?.ergebnis,
          type: tariff.id !== 7 ? "energie" : "telco",
        });

        return data;
      } catch (e) {
        return {
          results: [],
          objectId: "",
          parameters: {},
          type: "energie",
        };
      }
    },
    {}
  );
};

export default useSubmitTariffResult;
