import { useField } from "formik";
import React from "react";

import { Option } from "../../../../components/GetSelect/types";
import Input from "../../../../components/Input";
import FormikRadio from "../../../../components/Radio/FieldFormik";
import useCancellationFeatures from "../../hooks/useCancellationFeatures";

const DesiredCancellationDate = () => {
  const [{ value: desiredCancellationDateValue }] = useField(
    "hasDesiredCancellationDate"
  );

  const { startOfCancellationLabels, internalTariffChange, desiredDateLabel } =
    useCancellationFeatures();

  return (
    internalTariffChange && (
      <div className="mb-7">
        <div className="font-bold text-lg mb-5">
          {startOfCancellationLabels?.label ||
            "Gewünschter Kündigungstermin (unverbindlich)"}
        </div>

        <>
          {startOfCancellationLabels?.options ? (
            <div className="grid lg:grid-cols-3 items-start">
              {startOfCancellationLabels.options.map((opt: Option) => {
                const value = opt.value === "desiredDate" ? "true" : "false";

                return (
                  <FormikRadio
                    key={opt.label}
                    value={value}
                    name="hasDesiredCancellationDate"
                    label={opt.label}
                  />
                );
              })}

              {desiredCancellationDateValue === "true" && (
                <div>
                  <Input
                    name="desiredCancellationDate"
                    className="w-full"
                    type="datepicker"
                  />
                  <small className="mt-6">
                    Ist die Kündigung zum Wunschtermin nicht möglich, dann
                    erfolgt sie zum nächstmöglichen Zeitpunkt nach dem
                    Wunschtermin.
                  </small>
                </div>
              )}
            </div>
          ) : (
            <>
              <div className="mb-2">
                <FormikRadio
                  value="false"
                  name="hasDesiredCancellationDate"
                  label="nächstmöglicher Termin"
                />
              </div>
              <FormikRadio
                value="true"
                name="hasDesiredCancellationDate"
                label={desiredDateLabel}
                className="mb-4"
              />
            </>
          )}
        </>
      </div>
    )
  );
};

export default DesiredCancellationDate;
