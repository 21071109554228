import React from "react";

import NoResult from "../../../../../components/NoResult";
import { Telco } from "../../../../../context/Telco";
import useResponsiveClass from "../../../../../hooks/useResponsiveClass";
import TelcoItem from "../TelcoItem";

const ProductView = ({ data, handleSelectTariff }: { data: Telco[]; handleSelectTariff: (product: Telco) => void }) => {
  const { responsiveClass } = useResponsiveClass();

  return (
    <section className="py-4">
      {data.length === 0 ? (
        <NoResult />
      ) : (
        <div className={responsiveClass("grid gap-4", "grid-cols-1", "grid-cols-2", "grid-cols-4")}>
          {data.map((list) => (
            <TelcoItem key={list.rootId} onSelect={handleSelectTariff} list={list} />
          ))}
        </div>
      )}
    </section>
  );
};

export default ProductView;
