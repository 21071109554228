



export interface VPPTokenResponse {
    data: KeyCloakToken|undefined;
}
export interface KeyCloakToken {
    name:string;
    value:string;

}

const useGetKeyCloakToken=async(authToken:string,apikey:string,vppSummaryUrl:string)=>{
    const res :VPPTokenResponse={data:undefined};

    if(!vppSummaryUrl)return authToken;


    const response= await fetch(`${vppSummaryUrl.replace('/angebotsrechner/','/api/token')}`);
    if (!response.ok) {
        throw new Error(`Error ${response.status}`);
    }

    const data = await response.json();

            res.data=data?.data;
     return res.data?.value;


  };

  export default useGetKeyCloakToken;