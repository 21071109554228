import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Button from "../../../../components/Button";
import { useConfig, useTariff } from "../../../../context";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const terminateConfig = ["1", "2", "3"];

const TRButtonGroups = ({ terminate }) => {
  const { setTariffId, id } = useTariff();
  const [active, setActive] = useState("");

  useEffect(() => {
    if (id) {
      setActive(`${id}`);
    }
  }, [id]);

  const config = useConfig();
  const {
    electricityLabel,
    gasLabel,
    heizstromLabel,
    autostromLabel,
    telcoLabel,
    thgLabel,
  } = useFeatureInputPage();
  const { values, resetForm, setFieldValue } = useFormikContext();

  const TARIF_LABELS = useMemo(
    () => ({
      1: electricityLabel,
      2: gasLabel,
      3: heizstromLabel,
      4: autostromLabel,
      7: telcoLabel,
      8: thgLabel,
    }),
    [
      autostromLabel,
      electricityLabel,
      gasLabel,
      heizstromLabel,
      telcoLabel,
      thgLabel,
    ]
  );

  const handleTariffType = useCallback((id) => {
    switch (id) {
      case "1":
        return {
          tariftyp: "1",
          medium: "electricity",
        };
      case "2":
        return {
          tariftyp: "1",
          medium: "gas",
        };
      case "3":
        return {
          tariftyp: "3",
          medium: "heating",
        };
      case "4":
        return {
          tariftyp: "1",
          medium: "autostrom",
        };
      case "7":
        return {
          tariftyp: "7",
          medium: "telco",
        };
      default:
        return {
          tariftyp: "1",
          medium: "electricity",
        };
    }
  }, []);
  const handleOnClick = useCallback(
    (id) => {
      const { medium, tariftyp } = handleTariffType(id);

      setTariffId({
        tariffCalculatorTypeId: parseInt(id),
        tariftyp,
        medium,
      });

      // if (primaData && id !== "7") resetForm();
      const previousZip = values.zipCode;
      resetForm();
      setFieldValue("zipCode", previousZip)
      if(id==="2"){
        setFieldValue("oneTariff", "20.000");
      }

      setActive(id);
    },
    [handleTariffType, resetForm, setTariffId, setFieldValue, values,]
  );

  return (
    <div className="flex justify-between mt-10">
      <div className="flex lg:gap-5 gap-2 flex-wrap">
        {!terminate
          ? config?.frontendConfig?.calculatorTypeIDs.map((id) => {
              return (
                <div key={id}>
                  <Button
                    variant="tabNav"
                    onClick={() => handleOnClick(id)}
                    type="button"
                    className={`${
                      active !== id
                        ? "bg-button-primary-bg-light focus:text-button-primary-bg border-button-primary-bg border border-solid"
                        : "bg-button-primary-bg"
                    } p-2`}
                  >
                    {TARIF_LABELS[id]}
                  </Button>
                </div>
              );
            })
          : terminateConfig.map((id) => {
              return (
                <Button
                  key={id}
                  variant="tabNav"
                  onClick={() => handleOnClick(id)}
                  type="button"
                >
                  {TARIF_LABELS[id]}
                </Button>
              );
            })}
      </div>
    </div>
  );
};

export default TRButtonGroups;
