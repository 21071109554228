const basePriceCalculation = (value, basedOn) => {
  let basePrice = 0;

  if (value.dynamischeAngaben) {
    value.dynamischeAngaben.forEach((d) => {
      if (d.doubleObj) {
        d.doubleObj.forEach((c) => {
          if (c.name === basedOn) basePrice = c.wert;
        });
      }
    });
  }

  return basePrice;
};

export default basePriceCalculation;
