import { useField, useFormikContext } from "formik";
import React from "react";

import Checkbox from "../../../../components/Checkbox";
import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import { useConfig } from "../../../../context/Config";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useCheckIBAN from "../../hooks/useCheckIBAN";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import DigitalSignature from "../DigitalSignature";
import Headline from "../Headline";

const BankDataForm = () => {
  const [{ value }] = useField("paymentOptions");
  const [{ value: ibanValue }] = useField("iban");
  const [{ value: isDifferentAccountHolder }] = useField("isDifferentAccountHolder");
  const [{ value: firstNameValue }] = useField("firstName");
  const [{ value: lastNameValue }] = useField("lastName");
  const [{ value: firstNameAccountHolder }] = useField("firstNameAccountHolder");
  const [{ value: lastNameAccountHolder }] = useField("lastNameAccountHolder");
  const [{ value: isSepaRemoteSignature }] = useField("isSepaRemoteSignature");
  const [{ value: billingFirstName }] = useField("billingFirstName");
  const [{ value: billingLastName }] = useField("billingLastName");

  const [fields] = useField("asOffer");
  const { config } = useConfig();

  const { setFieldValue } = useFormikContext();
  const {
    deviateAccountHolderData,
    agreeSepaLabel,
    agreeSepa,
    firstName,
    lastName,
    iban,
    bic,
    creditInstitution,
    sepaSignature,
    telesalesSignature,
    remoteSignatureLabel,
    digitalSignatureCustomer,
  } = useFeaturesCC();
  const { mandatoryLabel } = useGeneralLabel();
  const mandatoryFieldsCRM = useMandatoryfields();

  useCheckIBAN();

  const isGermanBankAccount = React.useMemo(() => !!iban.startsWith("DE"), [iban]);

  React.useEffect(() => {
    if (isDifferentAccountHolder === true) {
      setFieldValue("firstNameAccountHolder", firstNameAccountHolder);
      setFieldValue("lastNameAccountHolder", lastNameAccountHolder);
    } else {
      if (billingFirstName && billingLastName) {
        setFieldValue("firstNameAccountHolder", billingFirstName);
        setFieldValue("lastNameAccountHolder", billingLastName);
      } else {
        setFieldValue("firstNameAccountHolder", firstNameValue);
        setFieldValue("lastNameAccountHolder", lastNameValue);
      }
    }
  }, [firstNameValue, isDifferentAccountHolder, lastNameValue, setFieldValue]);

  React.useEffect(() => {
    if ((iban.length !== 22 && iban.startsWith("DE")) || iban.length <= 1) {
      setFieldValue("bankName", "");
      setFieldValue("bic", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iban.length]);

  if (value === "bankTransfer") return null;
  const gridCols = { desktop: "grid-cols-2", tablet: "grid-cols-2", mobile: "grid-cols-1" };

  return (
    <FormControl className={`grid gap-x-4 gap-y-2 ${gridCols[config.media ?? "tablet"]}`}>
      <Headline>Bankverbindung</Headline>
      <Checkbox
        label={deviateAccountHolderData.options[1].label}
        name="isDifferentAccountHolder"
        className="col-span-full mb-2"
      />
      <>
        <Input
          name="firstNameAccountHolder"
          placeholder="Vorname"
          label={firstName + (!fields?.value ? mandatoryLabel : "")}
          inputType="name"
          disabled={!isDifferentAccountHolder}
        />
        <Input
          name="lastNameAccountHolder"
          placeholder="Nachname"
          label={lastName + (!fields?.value ? mandatoryLabel : "")}
          inputType="name"
          disabled={!isDifferentAccountHolder}
        />
      </>

      <Input name="iban" placeholder="IBAN" inputType="iban" label={iban + (!fields?.value ? mandatoryLabel : "")} />
      <Input
        name="bic"
        inputType="BIC"
        label={bic + (!fields?.value && mandatoryFieldsCRM?.includes("bic") ? mandatoryLabel : "")}
        placeholder="SWIFT-Code (BIC)"
        disabled={!ibanValue || isGermanBankAccount}
      />
      <Input
        name="bankName"
        label={
          creditInstitution + (!fields?.value && mandatoryFieldsCRM?.includes("geldinstitut") ? mandatoryLabel : "")
        }
        placeholder="Kreditinstitute"
        disabled={!ibanValue || isGermanBankAccount}
      />

      {sepaSignature === false && (
        <div className="col-span-full">
          <Checkbox name="isAgreeToSEPA" label={agreeSepa + (!fields?.value ? mandatoryLabel : "")} />
          <div className="text-sm text-gray-dark ml-7">{agreeSepaLabel}</div>
        </div>
      )}
      {sepaSignature === true && (
        <>
          {telesalesSignature && (
            <Checkbox
              name="isSepaRemoteSignature"
              labelComponent={remoteSignatureLabel}
              className="col-span-full mb-2"
            />
          )}
          {(!telesalesSignature || !isSepaRemoteSignature === true) && (
            <div className="col-span-full">
              <DigitalSignature
                name="sepa_digitalSignature"
                label={digitalSignatureCustomer + (!fields.value ? mandatoryLabel : "")}
              />
            </div>
          )}
        </>
      )}
    </FormControl>
  );
};
export default BankDataForm;
