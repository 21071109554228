import * as React from "react";

import FormikRadio from "../../../../components/Radio/FieldFormik";
import { useConfig } from "../../../../context/Config";
import useFeatureInputPage from "../../hooks/useFeatureInputPage";

const CustomerTypeRadio = ({ modal = false }: { modal?: boolean }) => {
  const { private: privateLabel, business } = useFeatureInputPage();
  const { config } = useConfig();

  return (
    <>
      <div className={`flex gap-4 ${modal || config.media === "mobile" ? "" : "justify-end"}`}>
        <FormikRadio label={privateLabel} name="customerType" value="0" />
        <FormikRadio label={business} name="customerType" value="1" />
      </div>
    </>
  );
};
export default CustomerTypeRadio;
