import { useField } from "formik";
import React from "react";

import GetSelect from "../../../../components/GetSelect";
import Input from "../../../../components/Input";
import { SALUTATION_LIST, TITLE_LIST } from "../../../../Constant";
import { useConfig } from "../../../../context/Config";
import { useTRInputContext } from "../../../../context/FormInputData";
import useGeneralLabel from "../../../../hooks/useGeneralLabel";
import { useMandatoryfields } from "../../context/Mandatoryfields";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import Headline from "../Headline";

const PersonalData = () => {
  const { salutationOptions, salutation, title, firstName, lastName, companyName, contactTitle, birthday } =
    useFeaturesCC();
  const { mandatoryLabel } = useGeneralLabel();
  const { mandatoryFields } = useFeaturesCC();
  const inputContext = useTRInputContext();
  const { config } = useConfig();
  const [fields] = useField("asOffer");
  const gridCols = { desktop: "grid-cols-4", tablet: "grid-cols-2", mobile: "grid-cols-1" };
  const mandatoryFieldsCRM = useMandatoryfields();

  return (
    <>
      <div className={`grid gap-x-4 gap-y-2 ${gridCols[config.media ?? "tablet"]}`}>
        <div className="col-span-full">
          <Headline>{contactTitle}</Headline>
          <div className="text-sm text-gray-dark min-h-5">{!fields.value && mandatoryFields}</div>
        </div>
        {inputContext?.values?.customerType !== "0" ? (
          <div className={config.media !== "mobile" ? "col-span-2" : ""}>
            <Input
              name="companyName"
              label={companyName + (!fields?.value ? mandatoryLabel : "")}
              placeholder="Firma"
              inputType="firma"
            />
          </div>
        ) : null}
        <GetSelect
          name="salutation"
          label={salutation + (!fields?.value && mandatoryFieldsCRM?.includes("la_anrede") ? mandatoryLabel : "")}
          options={
            fields?.value || !mandatoryFieldsCRM?.includes("la_anrede")
              ? (salutationOptions || SALUTATION_LIST).concat({ label: "", value: "" })
              : salutationOptions || SALUTATION_LIST
          }
          className=""
        />
        <GetSelect name="title" options={TITLE_LIST} label={title} />
        <div className={`col-start-1 ${config.media === "desktop" ? "col-span-2" : ""}`}>
          <Input
            name="firstName"
            label={firstName + (!fields?.value ? mandatoryLabel : "")}
            placeholder="Vorname"
            inputType="name"
          />
        </div>
        <div className={config.media === "desktop" ? "col-span-2" : ""}>
          <Input
            name="lastName"
            label={lastName + (!fields?.value ? mandatoryLabel : "")}
            placeholder="Nachname"
            inputType="name"
          />
        </div>
        <Input
          type="datepicker"
          name="birthday"
          className="w-full"
          label={birthday + (!fields?.value && mandatoryFieldsCRM?.includes("la_geburtsdatum") ? mandatoryLabel : "")}
        />
      </div>
    </>
  );
};

export default PersonalData;
