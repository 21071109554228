import * as React from "react";

import Checkbox from "../../../../../components/Checkbox";
import useFeaturesCC from "../../../hooks/useFeaturesCC";
import Headline from "../../Headline";

const SurveyConsent = () => {
  const { customerSatisfactionSurveyPost, customerSatisfactionSurveyPhone, customerSatisfactionSurveyEmail } =
    useFeaturesCC();
  return (
    <div>
      <Headline>Kundenzufriedenheit</Headline>

      <div className="mb-4 text-sm">
        Ja, ich stimme zu, dass mich der Versorger zu meiner Zufriedenheit mit den mir angebotenen und/oder vermittelten
        Services befragt werden darf. Diese Einwilligung kann ich jederzeit widerrufen.
      </div>
      <div className="grid gap-2">
        <Checkbox name="customerSatisfactionSurveyByPost" label={customerSatisfactionSurveyPost} />
        <Checkbox name="customerSatisfactionSurveyByPhone" label={customerSatisfactionSurveyPhone} />
        <Checkbox name="customerSatisfactionSurveyByMail" label={customerSatisfactionSurveyEmail} />
      </div>
    </div>
  );
};

export default SurveyConsent;
