import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import { useStepChange } from "../../../../context";
import { useExternLocationId } from "../../../../context/ExternLocationId";
import { useSelectTariff } from "../../../../context/Tariff";
import { useSelectTelco } from "../../../../context/Telco";
import scrollToTop from "../../../../utils/scrollToTop";
import useFeaturesResult from "../../hooks/useFeaturesResult";
import AddressUsageInformation from "../AddressUsageInfo";
import ProductView from "../ProductView";
import Hardware from "./Hardware";
import Options from "./Options";
import SelectedTariff from "./SelectedTariff";

const TelcoResults = ({ data }) => {
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [selectedOption, setSelectedOption] = React.useState([]);
  const [selectedHardware, setSelectedHardware] = React.useState([]);
  const { setStep } = useStepChange();
  const { setSelectedTelco } = useSelectTelco();
  const { setSelectTariff } = useSelectTariff();
  const { telcoLabel, backToSearchBtnTextLabel, pleaseWaitLabel } = useFeaturesResult();
  const { externLocationId, setExternLocationId } = useExternLocationId();

  const handleBackToTariff = React.useCallback(() => {
    setSelectedProduct(null);
    setSelectedOption([]);
    setSelectedHardware([]);
    scrollToTop();
  }, []);

  const handleSelectTariff = React.useCallback(
    (product) => setSelectedProduct(product),
    []
  );

  const handleRemoveSelectedOptions = React.useCallback((entry) => {
    setSelectedOption((prevState) =>
      prevState.filter((v) => v.refRootId !== entry.refRootId)
    );
  }, []);

  const handleRemoveSelectedHardware = React.useCallback((entry) => {
    setSelectedHardware((prevState) =>
      prevState.filter((v) => v.refRootId !== entry.refRootId)
    );
  }, []);

  const handleGoToContractConclusion = React.useCallback(() => {
    setStep(3);

    if (typeof window !== "undefined") {
      const product = {
        ...selectedProduct,
        selectedOption,
        selectedHardware,
      };

      scrollToTop();
      setSelectedTelco({ ...product, objectId: externLocationId });
      setExternLocationId("");
    }
  }, [
    externLocationId,
    selectedHardware,
    selectedOption,
    selectedProduct,
    setExternLocationId,
    setSelectedTelco,
    setStep,
  ]);

  if (data) {
    return (
      <section>
        <Card>
          <ErrorBoundary fallback={<div>Address Usage is wrong</div>}>
            <AddressUsageInformation />
          </ErrorBoundary>
          <div className="my-4">
            <Button
              variant="primary"
              className="p-2"
              onClick={() => {
                setStep(1);
                setSelectedOption([]);
                setSelectedHardware([]);
                setSelectedTelco(null);
                setSelectTariff(null);
              }}
            >
              {backToSearchBtnTextLabel}
            </Button>
          </div>
        </Card>

        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          {!selectedProduct && (
            <ProductView data={data} handleSelectTariff={handleSelectTariff} />
          )}

          {selectedProduct && (
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <ErrorBoundary fallback={<div>Options is wrong</div>}>
                  {selectedProduct.optionGroups.map((option) => (
                    <Options
                      key={option.name}
                      options={selectedOption.sort(
                        (a, b) => a.refRootId - b.refRootId
                      )}
                      onSelect={setSelectedOption}
                      onRemove={handleRemoveSelectedOptions}
                      option={option}
                    />
                  ))}
                </ErrorBoundary>

                <ErrorBoundary fallback={<div>Hardware is wrong</div>}>
                  {selectedProduct.hardwareGroups.map((hardware) => (
                    <Hardware
                      key={hardware.name}
                      hardwares={selectedHardware.sort(
                        (a, b) => a.refRootId - b.refRootId
                      )}
                      onSelect={setSelectedHardware}
                      onRemove={handleRemoveSelectedHardware}
                      hardware={hardware}
                    />
                  ))}
                </ErrorBoundary>

                <div className="grid grid-cols-2 gap-4 mt-6">
                  <Button
                    className="w-full p-4"
                    variant="primary"
                    onClick={handleGoToContractConclusion}
                  >
                    {telcoLabel || "Jetzt bestellen"}
                  </Button>
                  <Button className="w-full p-4" onClick={handleBackToTariff}>
                    Abbrechen
                  </Button>
                </div>
              </div>

              {selectedProduct && (
                <ErrorBoundary fallback={<div>Selected tariff is wrong</div>}>
                  <SelectedTariff
                    product={selectedProduct}
                    options={selectedOption}
                    hardwares={selectedHardware}
                  />
                </ErrorBoundary>
              )}
            </div>
          )}
        </ErrorBoundary>
      </section>
    );
  } else {
    return <div>{pleaseWaitLabel}</div>;
  }
};

export default TelcoResults;
