import React from "react";

import { useTariff } from "../../../context";
import useExternalConfigs from "../../../hooks/useExternalConfigs";
import { sepaButtonText, sepaDescription } from "../Constant";

const STANDARD_OPTIONS = {
  label: "Ich möchte wechseln",
  options: [
    {
      label: "Wechsel zu einem neuen Anbieter",
      value: "E03",
    },
    {
      label: "Wechsel zu einem günstigeren Tarif",
      value: "tarifwechsel",
    },
    {
      label: "Umzug",
      value: "E01",
    },
  ],
};
const deviateAccountHolderOptions = [
  { value: "false", label: "Kontoinhaber identisch mit Lieferanschrift" },
  { value: "true", label: "Abweichender Kontoinhaber" },
];
const paymentMethodOptions = [
  { value: "directDebit", label: "Bankeinzug (SEPA-Lastschrift)" },
  { value: "bankTransfer", label: "Überweisung" },
];
const useFeaturesCC = () => {
  const { data: config } = useExternalConfigs("contractconclusion");
  const tariff = useTariff();

  const contractConclusion = config?.data?.data?.contractConclusion;

  const isEnabledOfferMode = React.useMemo(
    () => config?.data?.data?.functionscontractConclusion?.offerMode || false,
    [config?.data?.data?.functionscontractConclusion?.offerMode]
  );

  const isPrePrint = React.useMemo(
    () => config?.data?.data?.functionscontractConclusion?.prePrint || false,
    [config?.data?.data?.functionscontractConclusion?.prePrint]
  );

  const reasonToChangeMoveExistingCustomer = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.reasonToChangeMoveExistingCustomer ?? 
        true
    );
  }, [
    config?.data?.data?.functionscontractConclusion
      ?.reasonToChangeMoveExistingCustomer,
  ]);

  const isPrePrintSendMail = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.prePrintSendMail || false
    );
  }, [config?.data?.data?.functionscontractConclusion?.prePrintSendMail]);

  const isEnabledDigitalSignature = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.digitalSignature ||
      false,
    [config?.data?.data?.functionscontractConclusion?.digitalSignature]
  );

  const isRepeatMail = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.mailRepetition || false,
    [config?.data?.data?.functionscontractConclusion?.mailRepetition]
  );

  const isShowNetGross = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.showNetGross || false,
    [config?.data?.data?.functionscontractConclusion?.showNetGross]
  );

  const isShowPassword = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.showPassword || false,
    [config?.data?.data?.functionscontractConclusion?.showPassword]
  );

  const isAdvertisingConsent = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.advertisingConsent ||
      false,
    [config?.data?.data?.functionscontractConclusion?.advertisingConsent]
  );

  const isDatePicker = React.useMemo(
    () => config?.data?.data?.functionscontractConclusion?.calendarWidget,
    [config?.data?.data?.functionscontractConclusion?.calendarWidget]
  );
    
  const pricesNetGross = React.useMemo(() => {
    return config?.data?.data?.functionscontractConclusion?.pricesNetGross || "default";
  }, [config?.data?.data?.functionscontractConclusion?.pricesNetGross]);

  const startOfDeliveryLabels = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.startOfDelivery || {
        label: "Start der Belieferung",
        options: [
          {
            label: "zum nächstmöglichem Termin",
            value: "nextPossibleDate",
          },
          {
            label: "zu einem Wunschtermin",
            value: "desiredDate",
          },
        ],
      }
    );
  }, [config?.data?.data?.functionscontractConclusion?.startOfDelivery]);

  const desiredDateLabel = React.useMemo(
    () => contractConclusion?.desiredDate || "Wunschtermin",
    [contractConclusion?.desiredDate]
  );
  const billingDelivery = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.billingDelivery || {
        label:
          config?.data?.data?.functionscontractConclusion?.billingDelivery
            ?.label,
        options: null,
      }
    );
  }, [config?.data?.data?.functionscontractConclusion?.billingDelivery]);

  const terminatePrevSupplierLabels = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.terminatePrevSupplier || {
        label:
          contractConclusion?.currentProvider ||
          "Haben Sie Ihrn aktuellen Versorger bereits gekündigt? ",
        options: [
          {
            label:
              "Nein, daher ich bevollmächtige %versorgername% zur Kündigung meines bisherigen Liefervertrages",
            value: "false",
          },
          {
            label: "Ja, ich habe bereits gekündigt",
            value: "true",
          },
        ],
      }
    );
  }, [
    contractConclusion?.currentProvider,
    config?.data?.data?.functionscontractConclusion?.terminatePrevSupplier,
  ]);

  const reasonToChange = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.reasonToChange ||
      STANDARD_OPTIONS,
    [config?.data?.data?.functionscontractConclusion?.reasonToChange]
  );

  const paymentMethodLabels = React.useMemo(
    () => contractConclusion?.paymentMethod?.options || paymentMethodOptions,
    [contractConclusion?.paymentMethod?.options]
  );
  const isEnabledBillingDelivery = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.showBillingDelivery ||
      false
    );
  }, [config?.data?.data?.functionscontractConclusion?.showBillingDelivery]);

  const isEnabledReadingDate = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.showReadingDate ?? true
    );
  }, [config?.data?.data?.functionscontractConclusion?.showReadingDate]);

  const isEnabledInstallationDelivery = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion
        ?.showInstallationDelivery ?? true,
    [config?.data?.data?.functionscontractConclusion?.showInstallationDelivery]
  );

  const isEnabledCustomerRecruitment = React.useMemo(
    () =>
      config?.data?.data?.functionscontractConclusion?.customersRecruitment ||
      false,
    [config?.data?.data?.functionscontractConclusion?.customersRecruitment]
  );

  const internalTariffChange = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.internalTariffChange ||
      false
    );
  }, [config?.data?.data?.functionscontractConclusion?.internalTariffChange]);

  const prevSupplierLabel = React.useMemo(
    () =>
      contractConclusion?.prevSupplier || tariff.id === 2
        ? "Bisheriger Gaslieferant *"
        : "Bisheriger Stromlieferant *",
    [contractConclusion?.prevSupplier, tariff.id]
  );

  const prevCustomerNumLabel = React.useMemo(
    () =>
      contractConclusion?.prevCustomerNum || tariff.id === 2
        ? "Kundennummer beim bisherigen Gaslieferant"
        : "Kundennummer beim bisherigen Stromlieferant",
    [contractConclusion?.prevCustomerNum, tariff.id]
  );

  const dateOfCancellationLabel = React.useMemo(
    () => contractConclusion?.dateOfCancellation || "Kündigungsdatum *",
    [contractConclusion?.dateOfCancellation]
  );
  const meterNumberLabel = React.useMemo(
    () =>
      contractConclusion?.meterNumber || tariff?.id === 2
        ? "Gaszählernummer *"
        : "Stromzählernummer *",
    [contractConclusion?.meterNumber, tariff?.id]
  );
  const maLoIDLabel = React.useMemo(
    () => contractConclusion?.maLoID || "Marktlokationsnummer",
    [contractConclusion?.maLoID]
  );

  const newMeterNumberLabel = React.useMemo(
    () => contractConclusion?.newMeterNumber || "Neue Stromzählernummer *",
    [contractConclusion?.newMeterNumber]
  );

  const dateMoveInLabel = React.useMemo(
    () => contractConclusion?.dateMoveIn || "Datum des Einzugs *",
    [contractConclusion?.dateMoveIn]
  );

  const currentMeterReadingLabel = React.useMemo(
    () => contractConclusion?.currentMeterReading || "Aktueller Zählerstand",
    [contractConclusion?.currentMeterReading]
  );
  const salutationLabel = React.useMemo(
    () => contractConclusion?.salutation || "Anrede *",
    [contractConclusion?.salutation]
  );
  const titleLabel = React.useMemo(
    () => contractConclusion?.title || "Titel",
    [contractConclusion?.title]
  );
  const companyNameLabel = React.useMemo(
    () => contractConclusion?.companyName || "Firma",
    [contractConclusion?.companyName]
  );
  const firstNameLabel = React.useMemo(
    () => contractConclusion?.firstName || "Vorname *",
    [contractConclusion?.firstName]
  );
  const lastNameLabel = React.useMemo(
    () => contractConclusion?.lastName || "Nachname *",
    [contractConclusion?.lastName]
  );
  const firstNameDeviatedLabel = React.useMemo(
    () => contractConclusion?.firstNameDeviated || "Vorname *",
    [contractConclusion?.firstNameDeviated]
  );
  const lastNameDeviatedLabel = React.useMemo(
    () => contractConclusion?.lastNameDeviated || "Nachname *",
    [contractConclusion?.lastNameDeviated]
  );
  const ibanLabel = React.useMemo(
    () => contractConclusion?.iban || "IBAN *",
    [contractConclusion?.iban]
  );
  const bicLabel = React.useMemo(
    () => contractConclusion?.bic || "SWIFT-Code (BIC)",
    [contractConclusion?.bic]
  );
  const creditInstitutionLabel = React.useMemo(
    () => contractConclusion?.creditInstitution || "Kreditinstitut",
    [contractConclusion?.creditInstitution]
  );

  const zipCodeLabel = React.useMemo(
    () => contractConclusion?.zipCode || "Postleitzahl *",
    [contractConclusion?.zipCode]
  );
  const cityLabel = React.useMemo(
    () => contractConclusion?.city || "Ort *",
    [contractConclusion?.city]
  );
  const houseNumberLabel = React.useMemo(
    () => contractConclusion?.houseNumber || "Hausnummer *",
    [contractConclusion?.houseNumber]
  );
  const streetLabel = React.useMemo(
    () => contractConclusion?.street || "Straße *",
    [contractConclusion?.street]
  );
  const additionLabel = React.useMemo(
    () => contractConclusion?.addition || "Adresszusatz",
    [contractConclusion?.addition]
  );

  const zipCodeBillingLabel = React.useMemo(
    () => contractConclusion?.zipCodeBilling || "Postleitzahl *",
    [contractConclusion?.zipCodeBilling]
  );
  const cityBillingLabel = React.useMemo(
    () => contractConclusion?.cityBilling || "Ort *",
    [contractConclusion?.cityBilling]
  );
  const houseNumberBillingLabel = React.useMemo(
    () => contractConclusion?.houseNumberBilling || "Hausnummer *",
    [contractConclusion?.houseNumberBilling]
  );
  const streetBillingLabel = React.useMemo(
    () => contractConclusion?.streetBilling || "Straße *",
    [contractConclusion?.streetBilling]
  );
  const additionBillingLabel = React.useMemo(
    () => contractConclusion?.additionBilling || "Adresszusatz",
    [contractConclusion?.additionBilling]
  );
  const mailboxBillingLabel = React.useMemo(
    () => contractConclusion?.mailboxBilling || "Postfach",
    [contractConclusion?.mailboxBilling]
  );
  const deviateAccountHolderData = React.useMemo(() => {
    return (
      contractConclusion?.deviateAccountHolderData || {
        label: "",
        options:
          contractConclusion?.deviateAccountHolderData?.options ||
          deviateAccountHolderOptions,
      }
    );
  }, [contractConclusion?.deviateAccountHolderData]);

  const werberIdentnummerLabel = React.useMemo(
    () => contractConclusion?.werberIdentnummer || "Kundennummer",
    [contractConclusion?.werberIdentnummer]
  );

  const gutscheincodeLabel = React.useMemo(
    () => contractConclusion?.gutscheincode || "Gutscheincode",
    [contractConclusion?.gutscheincode]
  );

  const digitalSignatureLabel = React.useMemo(
    () =>
      contractConclusion?.digitalSignature ||
      "Bitte unterschreiben Sie hier in folgendem Feld",
    [contractConclusion?.digitalSignature]
  );

  const prePrintLabelBtn = React.useMemo(
    () =>
      contractConclusion?.prePrintLabelBtn ||
      "Vertragszusammenfassung zum Download",
    [contractConclusion?.prePrintLabelBtn]
  );

  const prePrintInfoText = React.useMemo(
    () =>
      contractConclusion?.prePrintInfoText ||
      "Bitte laden Sie die Vertragszusammenfassung herunter, um mit der Bestellung fortzufahren.",
    [contractConclusion?.prePrintInfoText]
  );

  const customersRecruitmentLabel = React.useMemo(
    () => contractConclusion?.customersRecruitment || "Kunden werben Kunden",
    [contractConclusion?.customersRecruitment]
  );
  const acceptCancellationPolicy = React.useMemo(() => {
    return (
      contractConclusion?.acceptCancellationPolicy ||
      'Ich habe die <a href="%url%" target="_blank">Widerrufsbelehrung</a> des Anbieters %versorgername% zur Kenntnis genommen und akzeptiere diese.'
    );
  }, [contractConclusion?.acceptCancellationPolicy]);

  const acceptGeneralTNC = React.useMemo(() => {
    return (
      contractConclusion?.acceptGeneralTnc ||
      'Ich habe die Allgemeinen Geschäftsbedingungen <a href="%url%" target="_blank">(AGB)</a> des Anbieters %versorgername% zur Kenntnis genommen und akzeptiere diese. Der Anbieter darf ggf. meinen derzeitigen Stromvertrag kündigen und die nötigen Verträge mit dem zuständigen Netzbetreiber schließen.'
    );
  }, [contractConclusion?.acceptGeneralTnc]);

  const acceptPrivacyPolicy = React.useMemo(() => {
    return (
      contractConclusion?.acceptPrivacyPolicy ||
      'Ich habe die <a href="%url%" target="_blank">Datenschutzhinweise</a> mit den Informationen zur Verarbeitung meiner personenbezogenen Daten des Anbieters %versorgername% zur Kenntnis genommen.'
    );
  }, [contractConclusion?.acceptPrivacyPolicy]);

  const serviceDescription = React.useMemo(() => {
    return 'Die <a href=%url% target="_blank">Leistungsbeschreibung</a> habe ich zur Kenntnis genommen.';
  }, []);

  const preisList = React.useMemo(() => {
    return "Die <a href=%url% >Preisliste</a> habe ich zur Kenntnis genommen.";
  }, []);

  const emailLabel = React.useMemo(() => {
    return contractConclusion?.email || "E-Mail";
  }, [contractConclusion?.email]);

  const emailValidateLabel = React.useMemo(() => {
    return contractConclusion?.emailValidate || "E-Mail-Adresse bestätigen";
  }, [contractConclusion?.emailValidate]);

  const customerStromNumberLabel = React.useMemo(() => {
    return contractConclusion?.customerStromNumber || tariff.id === 2
      ? "Kundennummer beim bisherigen Gaslieferant"
      : "Kundennummer beim bisherigen Stromlieferant";
  }, [contractConclusion?.customerStromNumber, tariff.id]);
  const lastStromPreSupplierLabel = React.useMemo(() => {
    return (
      contractConclusion?.lastStromPreSupplier || "Bisheriger Stromlieferant"
    );
  }, [contractConclusion?.lastStromPreSupplier]);

  const meterStromNumberLabel = React.useMemo(() => {
    return contractConclusion?.meterStromNumber || "Stromzählernummer";
  }, [contractConclusion?.meterStromNumber]);
  const newMeterStromNumberLabel = React.useMemo(() => {
    return contractConclusion?.newMeterStromNumber || "Neue Stromzählernumme";
  }, [contractConclusion?.newMeterStromNumber]);
  const newMeterGasNumberLabel = React.useMemo(() => {
    return contractConclusion?.newMeterGasNumber || "Neue Gaszählernummer";
  }, [contractConclusion?.newMeterGasNumber]);
  const meterGasNumberLabel = React.useMemo(() => {
    return contractConclusion?.meterGasNumber || "Gaszählernummer";
  }, [contractConclusion?.meterGasNumber]);
  const lastGasPreSupplierLabel = React.useMemo(() => {
    return contractConclusion?.lastGasPreSupplier || "Bisheriger Gaslieferant";
  }, [contractConclusion?.lastGasPreSupplier]);
  const ibanNotFoundLabel = React.useMemo(() => {
    return (
      contractConclusion?.ibanNotFound ||
      "Ihre IBAN ist nicht korrekt, bitte überprüfen Sie nochmal!"
    );
  }, [contractConclusion?.ibanNotFound]);
  const checkZipCodeAndCityLabel = React.useMemo(() => {
    return (
      contractConclusion?.checkZipCodeAndCity ||
      "Bitte prüfen Sie Ihre PLZ oder Ort"
    );
  }, [contractConclusion?.checkZipCodeAndCity]);
  const orderDateLabel = React.useMemo(() => {
    return contractConclusion?.orderDate || "Bestelldatum";
  }, [contractConclusion?.orderDate]);
  const backToInputBtnTextLabel = React.useMemo(() => {
    return contractConclusion?.backToInputBtnText || "Zur Eingabeseite";
  }, [contractConclusion?.backToInputBtnText]);
  const cancelBtnTextLabel = React.useMemo(() => {
    return contractConclusion?.cancelBtnText || "Abbrechen";
  }, [contractConclusion?.cancelBtnText]);
  const addressTitleLabel = React.useMemo(() => {
    return contractConclusion?.addressTitle || "Adressdaten";
  }, [contractConclusion?.addressTitle]);
  const bankTitleLabel = React.useMemo(() => {
    return contractConclusion?.bankTitle || "Zahlungsweise";
  }, [contractConclusion?.bankTitle]);
  const confirmLabel = React.useMemo(() => {
    return contractConclusion?.confirm || "bestätigen";
  }, [contractConclusion?.confirm]);
  const contactTitleLabel = React.useMemo(() => {
    return contractConclusion?.contactTitle || "Persönliche Daten";
  }, [contractConclusion?.contactTitle]);
  const submissionErrorLabel = React.useMemo(() => {
    return contractConclusion?.submissionError || "Fehler beim Versenden";
  }, [contractConclusion?.submissionError]);
  const submissionSuccessLabel = React.useMemo(() => {
    return contractConclusion?.submissionSuccess || "Erfolgreich gesendet";
  }, [contractConclusion?.submissionSuccess]);
  const summaryTitleLabel = React.useMemo(() => {
    return contractConclusion?.summaryTitle || "Auftragsbestätigung";
  }, [contractConclusion?.summaryTitle]);
  const preSupplierLabel = React.useMemo(() => {
    return contractConclusion?.preSupplier || "Anschlussdaten";
  }, [contractConclusion?.preSupplier]);
  const summaryOfInputTitleLabel = React.useMemo(() => {
    return contractConclusion?.summaryOfInputTitle || "Ihr gewählter Tarif";
  }, [contractConclusion?.summaryOfInputTitle]);
  const billAdressLabel = React.useMemo(() => {
    return contractConclusion?.billAdress || "Rechnungsanschrift";
  }, [contractConclusion?.billAdress]);
  const geldinstitutLabel = React.useMemo(() => {
    return contractConclusion?.geldinstitut || "Geldinstitut";
  }, [contractConclusion?.geldinstitut]);
  const delieveryAddressLabel = React.useMemo(() => {
    return contractConclusion?.delieveryAddress || "Lieferanschrift";
  }, [contractConclusion?.delieveryAddress]);
  const sepaLabel = React.useMemo(() => {
    return contractConclusion?.sepa || "SEPA Lastschriftmandat";
  }, [contractConclusion?.sepa]);
  const totalpriceLabel = React.useMemo(() => {
    return contractConclusion?.totalprice || "Gesamtpreis";
  }, [contractConclusion?.totalprice]);
  const totalpriceunitLabel = React.useMemo(() => {
    return contractConclusion?.totalpriceunit || "€/Jahr";
  }, [contractConclusion?.totalpriceunit]);
  const basepriceLabel = React.useMemo(() => {
    return contractConclusion?.baseprice || "Grundpreis";
  }, [contractConclusion?.baseprice]);
  const basepriceunitLabel = React.useMemo(() => {
    return contractConclusion?.basepriceunit || "€/Jahr";
  }, [contractConclusion?.basepriceunit]);
  const workingpriceLabel = React.useMemo(() => {
    return contractConclusion?.workingprice || "Arbeitspreis";
  }, [contractConclusion?.workingprice]);
  const workingpriceunitLabel = React.useMemo(() => {
    return contractConclusion?.workingpriceunit || "ct/kWh";
  }, [contractConclusion?.workingpriceunit]);
  const priceguaranteeLabel = React.useMemo(() => {
    return contractConclusion?.priceguarantee || "Preisgarantie";
  }, [contractConclusion?.priceguarantee]);
  const contractperiodLabel = React.useMemo(() => {
    return contractConclusion?.contractperiod || "Vertragslaufzeit";
  }, [contractConclusion?.contractperiod]);
  const locationNotFoundLabel = React.useMemo(() => {
    return contractConclusion?.locationNotFound || "Standort nicht gefunden";
  }, [contractConclusion?.locationNotFound]);

  const savingLabel = React.useMemo(() => {
    return (
      contractConclusion?.saving || "Ersparnis gegenüber dem Vergleichstarif"
    );
  }, [contractConclusion?.saving]);
  const savingunitLabel = React.useMemo(() => {
    return contractConclusion?.savingunit || "€/Jahr";
  }, [contractConclusion?.savingunit]);
  const usageHTLabel = React.useMemo(() => {
    return contractConclusion?.usageHT || "Ihr Jahresverbrauch";
  }, [contractConclusion?.usageHT]);
  const usageHTunitLabel = React.useMemo(() => {
    return contractConclusion?.usageHTunit || "kWh";
  }, [contractConclusion?.usageHTunit]);
  const usageNTLabel = React.useMemo(() => {
    return contractConclusion?.usageNT || "Ihr Jahresverbrauch Nebenzeit";
  }, [contractConclusion?.usageNT]);
  const usageNTunitLabel = React.useMemo(() => {
    return contractConclusion?.usageNTunit || "kWh";
  }, [contractConclusion?.usageNTunit]);
  const anschlussdatenLabel = React.useMemo(() => {
    return contractConclusion?.anschlussdaten || "Ihre Anschlussdaten";
  }, [contractConclusion?.anschlussdaten]);
  const reasonLabel = React.useMemo(() => {
    return contractConclusion?.reason || "Grund für den Wechsel";
  }, [contractConclusion?.reason]);
  const reasonChangeSupplierLabel = React.useMemo(() => {
    return contractConclusion?.reasonChangeSupplier || "Lieferantenwechsel";
  }, [contractConclusion?.reasonChangeSupplier]);
  const reasonRelocationLabel = React.useMemo(() => {
    return contractConclusion?.reasonRelocation || "Umzug";
  }, [contractConclusion?.reasonRelocation]);
  const presupplierLabel = React.useMemo(() => {
    return contractConclusion?.presupplier || "Ihr Vorversorger";
  }, [contractConclusion?.presupplier]);
  const presuppliernameLabel = React.useMemo(() => {
    return contractConclusion?.presuppliername || "Vorversorgername";
  }, [contractConclusion?.presuppliername]);
  const presuppliernumberLabel = React.useMemo(() => {
    return contractConclusion?.presuppliernumber || "bisherige Kundennummer";
  }, [contractConclusion?.presuppliernumber]);
  const kuendigung_vorversorgerLabel = React.useMemo(() => {
    return (
      contractConclusion?.kuendigung_vorversorger || "Vorversorger gekündigt"
    );
  }, [contractConclusion?.kuendigung_vorversorger]);
  const zaehlernummerLabel = React.useMemo(() => {
    return contractConclusion?.zaehlernummer || "bisherige Zählernummer";
  }, [contractConclusion?.zaehlernummer]);
  const kuendigung_datumLabel = React.useMemo(() => {
    return contractConclusion?.kuendigung_datum || "Kündigungsdatum";
  }, [contractConclusion?.kuendigung_datum]);
  const postsupplierLabel = React.useMemo(() => {
    return contractConclusion?.postsupplier || "Neue Anschlussdaten";
  }, [contractConclusion?.postsupplier]);
  const datum_einzug_umzugLabel = React.useMemo(() => {
    return contractConclusion?.datum_einzug_umzug || "Einzugsdatum";
  }, [contractConclusion?.datum_einzug_umzug]);
  const zaehlerstandLabel = React.useMemo(() => {
    return contractConclusion?.zaehlerstand || "Zählerstand";
  }, [contractConclusion?.zaehlerstand]);
  const newzaehlernummerLabel = React.useMemo(() => {
    return contractConclusion?.newzaehlernummer || "Zählernummer";
  }, [contractConclusion?.newzaehlernummer]);
  const startOfDeliveryLabel = React.useMemo(() => {
    return contractConclusion?.startOfDelivery || "Gewünschter Lieferbeginn";
  }, [contractConclusion?.startOfDelivery]);
  const prekuendigungstextLabel = React.useMemo(() => {
    return contractConclusion?.prekuendigungstext || "Ich beauftrage ";
  }, [contractConclusion?.prekuendigungstext]);
  const postkuendigungstextLabel = React.useMemo(() => {
    return (
      contractConclusion?.postkuendigungstext ||
      "mit der Kündigung meines bestehenden Vertrages."
    );
  }, [contractConclusion?.postkuendigungstext]);
  const kuendigungsdatumLabel = React.useMemo(() => {
    return contractConclusion?.kuendigungsdatum || "zum";
  }, [contractConclusion?.kuendigungsdatum]);
  const nextPossibleDateTextLabel = React.useMemo(() => {
    return (
      contractConclusion?.nextPossibleDateText || "zum nächstmöglichen Termin."
    );
  }, [contractConclusion?.nextPossibleDateText]);
  const postkuendigungstextNextTimeLabel = React.useMemo(() => {
    return (
      contractConclusion?.postkuendigungstextNextTime ||
      "mit der Kündigung meines bestehenden Vertrages"
    );
  }, [contractConclusion?.postkuendigungstextNextTime]);
  const alertLabel = React.useMemo(() => {
    return contractConclusion?.alert || "Wichtiger Hinweis";
  }, [contractConclusion?.alert]);
  const showDialogBtnTextLabel = React.useMemo(() => {
    return contractConclusion?.showDialogBtnText || "Tarifübersicht";
  }, [contractConclusion?.showDialogBtnText]);
  const continueBtnTextLabel = React.useMemo(() => {
    return contractConclusion?.continueBtnText || "Mit Dateneingabe fortfahren";
  }, [contractConclusion?.continueBtnText]);
  const returnToTarifBtnTextLabel = React.useMemo(() => {
    return (
      contractConclusion?.returnToTarifBtnText || "Zurück zur Tarifübersicht"
    );
  }, [contractConclusion?.returnToTarifBtnText]);
  const changeUsageLabel = React.useMemo(() => {
    return contractConclusion?.changeUsage || "Jahresverbrauch ändern?";
  }, [contractConclusion?.changeUsage]);
  const returnToInputBodyLabel = React.useMemo(() => {
    return (
      contractConclusion?.returnToInputBody ||
      "Die Änderung des Verbrauchs an dieser Stelle hat zur Folge, dass die Tarife erneut berechnet werden, da dies Auswirkungen auf den Gesamtpreis hat. Sie werden somit erneut zur Eingabeseite geleitet um einen neuen Tarifvergleich zu starten."
    );
  }, [contractConclusion?.returnToInputBody]);
  const dialogTextLabel = React.useMemo(() => {
    return (
      contractConclusion?.dialogText ||
      "Wenn Sie jetzt zur Tarifübersicht zurückkehren, verlassen Sie die Dateneingabe. Ihre Tarifauswahl und die bereits eingegebenen Daten gehen dadurch verloren."
    );
  }, [contractConclusion?.dialogText]);
  const tarifInfoLabel = React.useMemo(() => {
    return contractConclusion?.tarifInfo || "Tarifinformation";
  }, [contractConclusion?.tarifInfo]);
  const tariffTypeLabel = React.useMemo(() => {
    return contractConclusion?.tariffType || "Zählerart";
  }, [contractConclusion?.tariffType]);
  const ecoTarifLabel = React.useMemo(() => {
    return contractConclusion?.ecoTarif || "Ökotarif";
  }, [contractConclusion?.ecoTarif]);
  const yesLabel = React.useMemo(() => {
    return contractConclusion?.yes || "ja";
  }, [contractConclusion?.yes]);
  const irrelevantLabel = React.useMemo(() => {
    return contractConclusion?.irrelevant || "egal";
  }, [contractConclusion?.irrelevant]);
  const customerTypeLabel = React.useMemo(() => {
    return contractConclusion?.customerType || "Kundenart";
  }, [contractConclusion?.customerType]);
  const businessLabel = React.useMemo(() => {
    return contractConclusion?.business || "Gewerbe";
  }, [contractConclusion?.business]);
  const privateLabel = React.useMemo(() => {
    return contractConclusion?.private || "Privat";
  }, [contractConclusion?.private]);
  const tarifftypeNameLabel = React.useMemo(() => {
    return contractConclusion?.tarifftypeName || "Tariftyp";
  }, [contractConclusion?.tarifftypeName]);
  const waermepumpeLabel = React.useMemo(() => {
    return contractConclusion?.waermepumpe || "Wärmepumpe";
  }, [contractConclusion?.waermepumpe]);
  const speicherheizungLabel = React.useMemo(() => {
    return contractConclusion?.speicherheizung || "Speicherheizung";
  }, [contractConclusion?.speicherheizung]);
  const autoStromGemeinsameMessungLabel = React.useMemo(() => {
    return (
      contractConclusion?.autoStromGemeinsameMessung ||
      "Autostrom gemeinsame Messung"
    );
  }, [contractConclusion?.autoStromGemeinsameMessung]);
  const autoStromGetrennteMessungLabel = React.useMemo(() => {
    return (
      contractConclusion?.autoStromGetrennteMessung ||
      "Autostrom getrennte Messung"
    );
  }, [contractConclusion?.autoStromGetrennteMessung]);
  const MaLoIDNotValidLabel = React.useMemo(() => {
    return (
      contractConclusion?.MaLoIDNotValid ||
      "Marktlokationsnummer ist nicht gültig"
    );
  }, [contractConclusion?.MaLoIDNotValid]);
  const PDF_Vorabdruck_JaLabel = React.useMemo(() => {
    return (
      contractConclusion?.PDF_Vorabdruck_Ja || "Zusammenfassung Ihres Angebots"
    );
  }, [contractConclusion?.PDF_Vorabdruck_Ja]);
  const PDF_Vorabdruck_NeinLabel = React.useMemo(() => {
    return (
      contractConclusion?.PDF_Vorabdruck_Nein ||
      "Vielen Dank für Ihren Auftrag!"
    );
  }, [contractConclusion?.PDF_Vorabdruck_Nein]);

  const usageLabel = React.useMemo(() => {
    return contractConclusion?.usage || "Verbrauch";
  }, [contractConclusion?.usage]);

  const cancellationperiodLabel = React.useMemo(() => {
    return contractConclusion?.cancellationperiod || "Kündigungfrist";
  }, [contractConclusion?.cancellationperiod]);

  const contractextensionLabel = React.useMemo(() => {
    return contractConclusion?.contractextension || "Vertragsverlängerung";
  }, [contractConclusion?.contractextension]);

  const buttontextLabel = React.useMemo(() => {
    return contractConclusion?.buttontext || "Vertrag abschließen";
  }, [contractConclusion?.buttontext]);

  const thgURLLabel = React.useMemo(() => {
    return contractConclusion?.thgURL || "";
  }, [contractConclusion?.thgURL]);

  const advertisingConsentPost = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.advertisingConsentPost ||
      contractConclusion?.advertisingConsentPost ||
      "per Post"
    );
  }, [
    config?.data?.data?.functionscontractConclusion?.advertisingConsentPost,
    contractConclusion?.advertisingConsentPost,
  ]);

  const advertisingConsentPhone = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.advertisingConsentPhone ||
      contractConclusion?.advertisingConsentPhone ||
      "per Phone"
    );
  }, [
    config?.data?.data?.functionscontractConclusion?.advertisingConsentPhone,
    contractConclusion?.advertisingConsentPhone,
  ]);

  const advertisingConsentEmail = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.advertisingConsentEmail ||
      contractConclusion?.advertisingConsentEmail ||
      "per E-Mail"
    );
  }, [
    config?.data?.data?.functionscontractConclusion?.advertisingConsentEmail,
    contractConclusion?.advertisingConsentEmail,
  ]);

  const customerSatisfactionSurveyEmail = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.customerSatisfactionSurveyEmail ||
      contractConclusion?.customerSatisfactionSurveyEmail ||
      "per E-Mail"
    );
  }, [
    config?.data?.data?.functionscontractConclusion
      ?.customerSatisfactionSurveyEmail,
    contractConclusion?.customerSatisfactionSurveyEmail,
  ]);

  const customerSatisfactionSurveyPhone = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.customerSatisfactionSurveyPhone ||
      contractConclusion?.customerSatisfactionSurveyPhone ||
      "per Phone"
    );
  }, [
    config?.data?.data?.functionscontractConclusion
      ?.customerSatisfactionSurveyPhone,
    contractConclusion?.customerSatisfactionSurveyPhone,
  ]);

  const customerSatisfactionSurveyPost = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion
        ?.customerSatisfactionSurveyPost ||
      contractConclusion?.customerSatisfactionSurveyPost ||
      "per Post"
    );
  }, [
    config?.data?.data?.functionscontractConclusion
      ?.customerSatisfactionSurveyPost,
    contractConclusion?.customerSatisfactionSurveyPost,
  ]);

  const createAccountAgreementLabel = React.useMemo(() => {
    return (
      contractConclusion?.createAccountAgreement ||
      "Ja, ich möchte ein Kundenkonto anlegen."
    );
  }, [contractConclusion?.createAccountAgreement]);

  const salutationOptions = React.useMemo(() => {
    return config?.data?.data?.salutation?.map((x: string) => {
      return {
        label: x,
        value: x,
      };
    });
  }, [config?.data?.data?.salutation]);

  const isSurveyActive = React.useMemo(
    () => config?.data?.data?.functionscontractConclusion?.survey || false,
    [config?.data?.data?.functionscontractConclusion?.survey]
  );

  const agreeSepaCheckboxLabel = React.useMemo(() => {
    return contractConclusion?.agreeSepa || sepaButtonText;
  }, [contractConclusion?.agreeSepa]);

  const agreeSepaText = React.useMemo(() => {
    return contractConclusion?.agreeSepaLabel || sepaDescription;
  }, [contractConclusion?.agreeSepaLabel]);

  const billingSameAsDeliveryLabel = React.useMemo(() => {
    return (
      contractConclusion?.billingSameAsDelivery ||
      "Rechnungsanschrift identisch mit Lieferanschrift"
    );
  }, [contractConclusion?.billingSameAsDelivery]);

  const selectedTariffLabel = React.useMemo(() => {
    return contractConclusion?.summaryOfInputTitle;
  }, [contractConclusion?.summaryOfInputTitle]);

  const birthdayTextLabel = React.useMemo(() => {
    return contractConclusion?.birthdayLabel || "";
  }, [contractConclusion?.birthdayLabel]);

  const mobileNumberLabel = React.useMemo(() => {
    return contractConclusion?.mobileNumber || "Mobilfunknummer";
  }, [contractConclusion?.mobileNumber]);

  const phoneNumberLabel = React.useMemo(() => {
    return contractConclusion?.phoneNumber || "Telefonnummer";
  }, [contractConclusion?.phoneNumber]);
  const mobileLabel = React.useMemo(() => {
    return contractConclusion?.mobil || "Mobilfunknummer";
  }, [contractConclusion?.mobil]);

  const phoneLabel = React.useMemo(() => {
    return contractConclusion?.phone || "Telefonnummer";
  }, [contractConclusion?.phone]);

  const faxLabel = React.useMemo(() => {
    return contractConclusion?.faxNumber || "Faxnummer";
  }, [contractConclusion?.faxNumber]);

  const birthdayLabel = React.useMemo(() => {
    return contractConclusion?.birthday || "Geburtsdatum *";
  }, [contractConclusion?.birthday]);

  const reasonToChangeHeadline = React.useMemo(() => {
    return contractConclusion?.reasonToChangeHeadline || "Wechselgrund";
  }, [contractConclusion?.reasonToChangeHeadline]);

  const isEnableInvoiceDelivery = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.invoiceDelivery ?? false
    );
  }, [config?.data?.data?.functionscontractConclusion?.invoiceDelivery]);

  const isEnableInvoiceMail = React.useMemo(() => {
    return config?.data?.data?.functionscontractConclusion?.invoiceMail ?? true;
  }, [config?.data?.data?.functionscontractConclusion?.invoiceMail]);

  const isEnableInvoicePost = React.useMemo(() => {
    return config?.data?.data?.functionscontractConclusion?.invoicePost ?? true;
  }, [config?.data?.data?.functionscontractConclusion?.invoicePost]);

  const usageSlider = React.useMemo(() => {
    return config?.data?.data?.functionscontractConclusion?.usageSlider ?? true;
  }, [config?.data?.data?.functionscontractConclusion?.usageSlider]);

  const onlyEcoSwitch = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.onlyEcoSwitch ?? true
    );
  }, [config?.data?.data?.functionscontractConclusion?.onlyEcoSwitch]);

  const showFax = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.showFax ?? 
      false
    );
  }, [config?.data?.data?.functionscontractConclusion?.showFax]);

  const desiredDateASAP = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.desiredDateASAP ?? 
      false
    );
  }, [config?.data?.data?.functionscontractConclusion?.desiredDateASAP]);

  const moveInDateDaysInPast = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.moveInDateDaysInPast ?? 
      42
    );
  }, [config?.data?.data?.functionscontractConclusion?.moveInDateDaysInPast]);

  const moveInDateMonthsInFuture = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.moveInDateMonthsInFuture ?? 
      6
    );
  }, [config?.data?.data?.functionscontractConclusion?.moveInDateMonthsInFuture]);

  const requestedDesiredDateDaysInAdvanceEnergy = React.useMemo(() => {
    return (
      config?.data?.data?.functionscontractConclusion?.requestedDesiredDateDaysInAdvanceEnergy ?? 
      30
    );
  }, [config?.data?.data?.functionscontractConclusion?.requestedDesiredDateDaysInAdvanceEnergy]);

  const cityNotFoundLabel = React.useMemo(() => {
    return (
      contractConclusion?.cityNotFoundLabel || "Zur gewählten Postleitzahl konnte leider kein Ort gefunden werden!"
    );
  }, [contractConclusion?.cityNotFoundLabel]);
  const emailIsIncorrect = React.useMemo(() => {
    return (
      contractConclusion?.emailIsIncorrect || "Ihre Email-Adresse entspricht nicht dem korrekten Format."
    );
  }, [contractConclusion?.emailIsIncorrect]);
  const emailRepeatIsIncorrect = React.useMemo(() => {
    return (
      contractConclusion?.emailRepeatIsIncorrect || "Ihre Email-Adressen stimmen nicht überein."
    );
  }, [contractConclusion?.emailRepeatIsIncorrect]);

  return {
    isPrePrint,
    isEnabledOfferMode,
    isEnabledDigitalSignature,
    isRepeatMail,
    isDatePicker,
    startOfDeliveryLabels,
    terminatePrevSupplierLabels,
    reasonToChange,
    isEnabledBillingDelivery,
    isEnabledReadingDate,
    isEnabledInstallationDelivery,
    isEnabledCustomerRecruitment,
    customersRecruitmentLabel,
    acceptCancellationPolicy,
    acceptGeneralTNC,
    acceptPrivacyPolicy,
    serviceDescription,
    preisList,
    emailLabel,
    emailValidateLabel,
    faxLabel,
    birthdayLabel,
    salutationOptions,
    billingDelivery,
    isSurveyActive,
    agreeSepaCheckboxLabel,
    paymentMethodLabels,
    agreeSepaText,
    billingSameAsDeliveryLabel,
    mobileLabel,
    selectedTariffLabel,
    phoneLabel,
    isEnableInvoiceDelivery,
    isEnableInvoiceMail,
    isEnableInvoicePost,
    advertisingConsentPost,
    advertisingConsentPhone,
    advertisingConsentEmail,
    customerSatisfactionSurveyPost,
    customerSatisfactionSurveyPhone,
    customerSatisfactionSurveyEmail,
    isAdvertisingConsent,
    usageSlider,
    mobileNumberLabel,
    onlyEcoSwitch,
    prevSupplierLabel,
    prevCustomerNumLabel,
    meterNumberLabel,
    dateOfCancellationLabel,
    maLoIDLabel,
    newMeterNumberLabel,
    dateMoveInLabel,
    currentMeterReadingLabel,
    firstNameLabel,
    lastNameLabel,
    firstNameDeviatedLabel,
    lastNameDeviatedLabel,
    ibanLabel,
    bicLabel,
    creditInstitutionLabel,
    deviateAccountHolderData,
    werberIdentnummerLabel,
    gutscheincodeLabel,
    digitalSignatureLabel,
    zipCodeLabel,
    cityLabel,
    houseNumberLabel,
    streetLabel,
    additionLabel,
    zipCodeBillingLabel,
    cityBillingLabel,
    houseNumberBillingLabel,
    streetBillingLabel,
    additionBillingLabel,
    salutationLabel,
    titleLabel,
    companyNameLabel,
    mailboxBillingLabel,
    createAccountAgreementLabel,
    customerStromNumberLabel,
    lastStromPreSupplierLabel,
    meterStromNumberLabel,
    newMeterStromNumberLabel,
    newMeterGasNumberLabel,
    meterGasNumberLabel,
    lastGasPreSupplierLabel,
    ibanNotFoundLabel,
    checkZipCodeAndCityLabel,
    orderDateLabel,
    backToInputBtnTextLabel,
    cancelBtnTextLabel,
    addressTitleLabel,
    bankTitleLabel,
    confirmLabel,
    contactTitleLabel,
    submissionErrorLabel,
    submissionSuccessLabel,
    summaryTitleLabel,
    preSupplierLabel,
    phoneNumberLabel,
    summaryOfInputTitleLabel,
    billAdressLabel,
    geldinstitutLabel,
    delieveryAddressLabel,
    sepaLabel,
    totalpriceLabel,
    totalpriceunitLabel,
    basepriceLabel,
    basepriceunitLabel,
    workingpriceLabel,
    workingpriceunitLabel,
    priceguaranteeLabel,
    cancellationperiodLabel,
    savingLabel,
    savingunitLabel,
    usageHTLabel,
    usageHTunitLabel,
    usageNTLabel,
    usageNTunitLabel,
    anschlussdatenLabel,
    reasonLabel,
    reasonChangeSupplierLabel,
    reasonRelocationLabel,
    presupplierLabel,
    presuppliernameLabel,
    presuppliernumberLabel,
    kuendigung_vorversorgerLabel,
    zaehlernummerLabel,
    kuendigung_datumLabel,
    postsupplierLabel,
    datum_einzug_umzugLabel,
    zaehlerstandLabel,
    newzaehlernummerLabel,
    startOfDeliveryLabel,
    prekuendigungstextLabel,
    postkuendigungstextLabel,
    kuendigungsdatumLabel,
    nextPossibleDateTextLabel,
    postkuendigungstextNextTimeLabel,
    alertLabel,
    showDialogBtnTextLabel,
    continueBtnTextLabel,
    returnToTarifBtnTextLabel,
    changeUsageLabel,
    returnToInputBodyLabel,
    dialogTextLabel,
    tarifInfoLabel,
    tariffTypeLabel,
    ecoTarifLabel,
    birthdayTextLabel,
    yesLabel,
    irrelevantLabel,
    customerTypeLabel,
    businessLabel,
    privateLabel,
    tarifftypeNameLabel,
    waermepumpeLabel,
    speicherheizungLabel,
    autoStromGemeinsameMessungLabel,
    autoStromGetrennteMessungLabel,
    MaLoIDNotValidLabel,
    PDF_Vorabdruck_JaLabel,
    PDF_Vorabdruck_NeinLabel,
    usageLabel,
    thgURLLabel,
    contractperiodLabel,
    contractextensionLabel,
    buttontextLabel,
    desiredDateLabel,
    locationNotFoundLabel,
    isShowPassword,
    isShowNetGross,
    isPrePrintSendMail,
    internalTariffChange,
    reasonToChangeMoveExistingCustomer,
    prePrintLabelBtn,
    prePrintInfoText,
    cityNotFoundLabel,
    emailRepeatIsIncorrect,
    emailIsIncorrect,
    pricesNetGross,
    showFax,
    desiredDateASAP,
    moveInDateDaysInPast,
    moveInDateMonthsInFuture,
    requestedDesiredDateDaysInAdvanceEnergy,
    reasonToChangeHeadline,
  };
};

export default useFeaturesCC;
