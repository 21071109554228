import React from "react";

import Card from "../../../../components/Card";
import FormikRadio from "../../../../components/Radio/FieldFormik";

const SubscribeListForm = () => {
  return (
    <Card className="mb-4">
      <div className="font-bold text-lg">Eintrag Teilnehmerverzeichnis</div>
      <div className="mb-2">
        <FormikRadio
          value="false"
          name="joinSubscribeList"
          label="Nein, ich wünsche keinen Eintrag"
        />
      </div>

      <FormikRadio
        value="true"
        name="joinSubscribeList"
        label="Ja, ich wünsche einen Eintrag ins Teilnehmerverzeichnis"
      />
    </Card>
  );
};

export default SubscribeListForm;
