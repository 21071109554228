import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --get-c-primary: #ff5300;
    --get-c-primary-hover: #f13a00;
    --get-c-primary-text: #ffffff;

    --get-c-secondary: #ffede5;
    --get-c-secondary-light: #fffbfa;
    --get-c-secondary-hover: #ffceb8;
    --get-c-secondary-text: #313131;

    --get-c-statistics-1: #deeaed;
    --get-c-statistics-2: #087e9f;
    --get-c-statistics-3: #003043;

    --get-c-breadcrumb: var(--get-c-content-bg);
    --get-c-breadcrumb-inactive: #9999a3;
  }
`;
export default myStyle;
