import * as React from "react";

import { useConfig } from "../../context/Config";

const Media = ({
  children,
  desktop = false,
  tablet = false,
  mobile = false,
}: {
  children: React.ReactNode;
  desktop?: boolean;
  tablet?: boolean;
  mobile?: boolean;
}) => {
  const { config } = useConfig();
  if (
    (config.media === "desktop" && desktop) ||
    (config.media === "tablet" && tablet) ||
    (config.media === "mobile" && mobile)
  ) {
    return <>{children}</>;
  }
  return null;
};

export default Media;
