import css from "../../utils/cssSyntax";

const myStyle = css`
  :host {
    --get-c-primary: #ff2850;
    --get-c-primary-hover: #ff8097;
    --get-c-primary-text: #ffffff;

    --get-c-secondary: #003c5a;
    --get-c-secondary-light: #d9e2e6;
    --get-c-secondary-hover: #001f2e;
    --get-c-secondary-text: #ffffff;

    --get-c-statistics-1: #003c5a;
    --get-c-statistics-2: #ff2850;
    --get-c-statistics-3: #3f6194;
  }
`;
export default myStyle;
