import qs from "query-string";
import { useCallback } from "react";
import { useMutation } from "react-query";

import toast from "../../../components/Toast";
import {
  useConfig,
  useParameter,
  useStepChange,
  useTariff,
} from "../../../context";
import ProxyInstance from "../../../services/proxy";

const useSendContactForm = () => {
  const config = useConfig();
  const tariff = useTariff();
  const { authToken } = useParameter();
  const { setStep } = useStepChange();

  const queryString = useCallback(
    ({ email }) =>
      qs.stringify({ calculatorType: tariff.id, id: 1, customermail: email }),
    [tariff.id]
  );

  return useMutation(
    "SEND_CONTACT_FORM_DATA",
    async (values) => {
      try {
        const { data } = await ProxyInstance(
          `/mail/sendMailContact?${queryString({
            email: values.Email,
          })}`,
          {
            apikey: config.apikey || "",
            method: "POST",
            authToken,
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        return data;
      } catch (e) {
        toast.error("Fehler beim Senden");
        throw e;
      }
    },
    {
      onSuccess: () => {
        toast.success("Anfrage versendet");
        setStep(1);
      },
    }
  );
};

export default useSendContactForm;
