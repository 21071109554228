import React from "react";

import { useSelectTariff } from "../../../../context/Tariff";
import useExternalConfigs from "../../../../hooks/useExternalConfigs";
import useFeaturesAddressData from "./useFeaturesAddressData";
import useFeaturesBankData from "./useFeaturesBankData";
import useFeaturesContactData from "./useFeaturesContactData";
import useFeaturesPreSupplierData from "./useFeaturesPreSupplierData";
import useFeaturesSummary from "./useFeaturesSummary";

const STANDARD_OPTIONS = {
  label: "Ich möchte wechseln",
  options: [
    {
      label: "Anbieterwechsel",
      value: "E03",
    },
    {
      label: "Tarifwechsel",
      value: "tarifwechsel",
    },
    {
      label: "Umzug",
      value: "E01",
    },
  ],
};
const paymentMethodOptions = {
  options: [
    { value: "directDebit", label: "Bankeinzug (SEPA-Lastschrift)" },
    { value: "bankTransfer", label: "Überweisung" },
  ],
};

const useFeaturesCC = () => {
  const { selectedTariff } = useSelectTariff();
  const { data: config } = useExternalConfigs("contractconclusion");
  const { data: tariffConfig } = useExternalConfigs("contractconclusion", selectedTariff?.versorgerID);
  const featuresContactData = useFeaturesContactData();
  const featuresAddressData = useFeaturesAddressData();
  const featuresPreSupplierData = useFeaturesPreSupplierData();
  const featuresBankData = useFeaturesBankData();
  const featuresSummary = useFeaturesSummary();

  const contractConclusion = config?.data?.data?.contractConclusion;
  const tariffContractConclusion = tariffConfig?.data?.data?.contractConclusion;
  const functionscontractConclusion = config?.data?.data?.functionscontractConclusion;
  const tariffFunctionscontractConclusion = tariffConfig?.data?.data?.functionscontractConclusion;
  const missingCRM =
    tariffConfig?.data?.exportformat || config?.data?.exportformat
      ? ""
      : `Versorger ${selectedTariff?.versorgerID} nicht konfiguriert`;

  const resolveContractConclusion = React.useCallback(
    (key: string) => {
      return tariffContractConclusion?.[key] ?? contractConclusion?.[key];
    },
    [tariffContractConclusion, contractConclusion]
  );
  const resolveFunctionscontractConclusion = React.useCallback(
    (key: string) => {
      return tariffFunctionscontractConclusion?.[key] ?? functionscontractConclusion?.[key];
    },
    [tariffFunctionscontractConclusion, functionscontractConclusion]
  );

  const defaultFunctionSettings = {
    isEnableInvoiceDelivery: false,
    isEnableInvoiceMail: true,
    isEnableInvoicePost: true,
    onlyEcoSwitch: true,
    showFax: false,
    desiredDateASAP: false,
    moveInDateDaysInPast: 42,
    moveInDateMonthsInFuture: 12,
    requestedDesiredDateDaysMin: 20,
    requestedDesiredDateDaysInAdvance: 12,
    requestedDesiredDateDaysInAdvanceEnergy: 20,
    requestedDesiredDateDaysMax: 12,
    showBillingDelivery: false,
    showReadingDate: true,
    areaCodeSwitch: 0,
    lockbox: false,
    offerMode: false,
    prePrint: false,
    reasonToChangeMoveExistingCustomer: false,
    prePrintSendMail: false,
    digitalSignature: false,
    mailRepetition: false,
    showNetGross: false,
    showPassword: false,
    isAdvertisingConsent: false,
    isShowAdvertisingConsentPost: false,
    calendarWidget: true,
    pricesNetGross: "default",
    startOfDelivery: {
      label: "Lieferbeginn",
      options: [
        { label: "Nächstmöglicher Termin", value: "nextPossibleDate" },
        { label: "zu einem Wunschtermin", value: "desiredDate" },
      ],
    },
    billingDelivery: {
      label: null,
      options: null,
    },
    terminatePrevSupplier: {
      label: "Haben Sie Ihren aktuellen Versorger bereits gekündigt?",
      options: [
        {
          label: "Nein, daher ich bevollmächtige %versorgername% zur Kündigung meines bisherigen Liefervertrages",
          value: "false",
        },
        {
          label: "Ja, ich habe bereits gekündigt",
          value: "true",
        },
      ],
    },
    reasonToChange: STANDARD_OPTIONS,
    sepaSignature: false,
    paymentMethod: paymentMethodOptions,
    showInstallationDelivery: true,
    customersRecruitment: false,
    telesalesSignature: false,
    counsellingProtocol: false,
    internalTariffChange: false,
    switchAccountLandHolder: false,
    survey: false,
  };

  type FunctionSettings = typeof defaultFunctionSettings;
  const functionSettings: FunctionSettings = Object.fromEntries(
    Object.entries(defaultFunctionSettings).map(([key, defaultValue]) => [
      key,
      resolveFunctionscontractConclusion(key) || defaultValue,
    ])
  ) as FunctionSettings;

  const defaultLabel = {
    alert: "Hinweis",
    btnBestellenLabel: "Zahlungspflichtig bestellen",
    btnBestellenTelesalesLabel: "Angebot zur Unterschrift",
    dialogText: "Beim Verlassen gehen die Produktauswahl sowie die bereits eingegebenen Daten verloren.",
    mandatoryFields: "Pflichtfelder sind mit einem Stern * gekennzeichnet.",
    nextPossibleDateText: "Nächstmöglicher Termin",
    returnToTarifBtnText: "zur Produktübersicht",
    returnToSearchBtnText: "zur Suche",
    signatureText: "Mit der Maus im weißen Bereich unterschreiben",
    createOffer: "Angebot erstellen",
    offer: "Angebot",
    forwardAsOffer: "Weiter als Angebot",
    changeSelection: "Produktauswahl ändern",
    priceInclBoni: "Gesamtpreis inkl. Boni",
    searchParams: "Suchkriterien",
    measurementHeader: "Messung",
    continue: "Dateneingabe fortsetzen",
  };

  type Label = typeof defaultLabel;
  const label: Label = Object.fromEntries(
    Object.entries(defaultLabel).map(([key, defaultValue]) => [key, resolveContractConclusion(key) || defaultValue])
  ) as Label;

  const salutationOptions = React.useMemo(() => {
    return config?.data?.data?.salutation?.map((x: string) => {
      return {
        label: x,
        value: x,
      };
    });
  }, [config?.data?.data?.salutation]);

  return {
    ...featuresContactData,
    ...featuresAddressData,
    ...featuresPreSupplierData,
    ...featuresBankData,
    ...featuresSummary,
    ...functionSettings,
    ...label,
    salutationOptions,
    missingCRM,
  };
};

export default useFeaturesCC;
