import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import { FormularFormik } from "../../widgets/TCFormular/types/FormularFormik";

const formCancellationData = createContext(
  {} as {
    values: FormularFormik;
    setValues: React.Dispatch<React.SetStateAction<FormularFormik>>;
  }
);

const CancellationFormInputDataProvider = ({ children }: { children: React.ReactNode }) => {
  const [values, setValues] = React.useState({} as FormularFormik);
  return <formCancellationData.Provider value={{ values, setValues }}>{children}</formCancellationData.Provider>;
};

export default CancellationFormInputDataProvider;
export const useTRInputContext = () => useContextSelector(formCancellationData, (c) => c);
