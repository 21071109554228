import * as React from "react";

const Euro = ({ fill = "var(--get-c-breadcrumb)" }: { fill: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      className="inline align-text-top"
    >
      <mask id="mask0_775_16193" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
        <rect x="0.5" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_775_16193)">
        <path
          d="M10.5 14C9.18889 14 8.02222 13.6278 7 12.8833C5.97778 12.1389 5.25556 11.1778 4.83333 10H2.5V8.66667H4.53333C4.51111 8.54444 4.5 8.43333 4.5 8.33333V7.66667C4.5 7.56667 4.51111 7.45556 4.53333 7.33333H2.5V6H4.83333C5.25556 4.82222 5.97778 3.86111 7 3.11667C8.02222 2.37222 9.18889 2 10.5 2C11.2667 2 11.9917 2.13333 12.675 2.4C13.3583 2.66667 13.9667 3.04444 14.5 3.53333L13.3333 4.7C12.9444 4.37778 12.5083 4.125 12.025 3.94167C11.5417 3.75833 11.0333 3.66667 10.5 3.66667C9.66667 3.66667 8.90833 3.88056 8.225 4.30833C7.54167 4.73611 7.02222 5.3 6.66667 6H10.5V7.33333H6.23333C6.21111 7.45556 6.19444 7.56667 6.18333 7.66667C6.17222 7.76667 6.16667 7.87778 6.16667 8C6.16667 8.12222 6.17222 8.23333 6.18333 8.33333C6.19444 8.43333 6.21111 8.54444 6.23333 8.66667H10.5V10H6.66667C7.02222 10.7 7.54167 11.2639 8.225 11.6917C8.90833 12.1194 9.66667 12.3333 10.5 12.3333C11.0333 12.3333 11.5472 12.2417 12.0417 12.0583C12.5361 11.875 12.9667 11.6222 13.3333 11.3L14.5 12.4667C13.9667 12.9556 13.3583 13.3333 12.675 13.6C11.9917 13.8667 11.2667 14 10.5 14Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default Euro;
