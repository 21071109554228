import isValid from "date-fns/isValid";
import parse from "date-fns/parse";

function isValidDesiredDate(day, month, year) {
  const parsed = parse(`${day}/${month}/${year}`, "dd/MM/yyyy", new Date());

  if (parsed <= new Date()) {
    return false;
  }

  return isValid(parsed) && parsed > new Date();
}

export default isValidDesiredDate;
