import { useField } from "formik";
import React, { useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";

import Cancel from "../../../../components/icons/cancel";
import toast from "../../../../components/Toast";
import useFeaturesCC from "../../hooks/useFeaturesCC";

const DigitalSignature = ({ name, label }: { name: string; label: string }) => {
  const signatureRef = useRef<SignatureCanvas>(null);
  const sectionRef = useRef<HTMLElement>(null);
  const [{ value }, , { setValue }] = useField({ name });
  const { signatureText } = useFeaturesCC();

  useEffect(() => {
    if (!signatureRef.current) return;

    signatureRef.current.fromDataURL(value, {
      ratio: 1,
      width: sectionRef.current?.clientWidth || 1000,
      height: 200,
    });
    // eslint-disable-next-line
  }, []);

  const handleOnEnd = React.useCallback(async () => {
    if (!signatureRef.current) return;

    if (signatureRef.current?.isEmpty()) {
      setValue("");
      toast.error("Sie müssen erst unterschreiben!");
      return;
    }

    const base64 = signatureRef.current?.toDataURL();
    setValue(base64);
  }, [setValue]);

  const handleClear = () => {
    signatureRef.current?.clear();
    setValue("");
  };

  return (
    <section ref={sectionRef}>
      <div className="text-xs mb-1">{label}</div>
      <div style={{ borderWidth: "1px" }} className="rounded-2xl border-solid border-primary relative">
        <span onClick={() => handleClear()} className="absolute float-right right-5 top-5 cursor-pointer">
          <Cancel />
        </span>
        <SignatureCanvas
          ref={signatureRef}
          velocityFilterWeight={0.3}
          canvasProps={{
            width: sectionRef.current?.clientWidth || 1000,
            height: 200,
            className: "sig-canvas rounded-2xl",
          }}
          clearOnResize={false}
          onEnd={handleOnEnd}
        />
      </div>
      <div className="text-gray-dark text-xs mt-1">{signatureText}</div>
    </section>
  );
};

export default DigitalSignature;
