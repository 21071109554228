import { object, string } from "yup";

const getValidationSchema = (zipCodeInvalidMessageLabel: string, zipCodeValidationLabel: string) => {
  return object({
    zipCode: string()
      .min(5, zipCodeValidationLabel)
      .required(zipCodeInvalidMessageLabel),
  });
};

export default getValidationSchema;
