import React from "react";

import Card from "../../components/Card";
import Divider from "../../components/Divider";
import ArrowRight from "../../components/icons/ArrowRight";
import NotificationBox from "../../components/NotificationBox";
import { useStepChange } from "../../context";
import scrollToTop from "../../utils/scrollToTop";
import { useContractConclusionFormName } from "../TCContractConclusion/context";
import useFeatureConfirmationPage from "./hooks/useFeatureConfirmationPage";

const TCConfirmation = () => {
  const { setFormName } = useContractConclusionFormName();
  const { setStep } = useStepChange();
  const { confirmMessage, confirmHeader, confirmText, backToHomeLabel } = useFeatureConfirmationPage();

  return (
    <div className="grid gap-10 mt-8">
      <NotificationBox variant="success" text={confirmMessage} />
      <Card className="grid gap-4">
        <div className="text-xl font-medium">{confirmHeader}</div>
        <div>{confirmText}</div>
        <Divider />
        <div
          className="text-primary cursor-pointer"
          onClick={() => {
            setStep(1);
            setFormName("contactForm");
            scrollToTop();
          }}
        >
          {backToHomeLabel} <ArrowRight />
        </div>
      </Card>
    </div>
  );
};

export default TCConfirmation;
