import React from "react";

import useExternalConfigs from "../../../hooks/useExternalConfigs";

const useFeatureConfirmationPage = () => {
  const { data: config } = useExternalConfigs("confirmationPage");
  const confirmationPage = config?.data?.data?.confirmationPage;

  const umzugLabel = React.useMemo(() => {
    
    return confirmationPage?.umzug || "Umzug";
  }, [confirmationPage?.umzug]);
  const adressdatenLabel = React.useMemo(() => {
    return confirmationPage?.adressdaten || "Adressdaten";
  }, [confirmationPage?.adressdaten]);
  const ihrFirmaLabel = React.useMemo(() => {
    return confirmationPage?.ihrFirma || "Ihr Firma";
  }, [confirmationPage?.ihrFirma]);
  const auftragsbestaetigungLabel = React.useMemo(() => {
    return confirmationPage?.auftragsbestaetigung || "Auftragsbestätigung";
  }, [confirmationPage?.auftragsbestaetigung]);
  const afterChangeLabel = React.useMemo(() => {
    return confirmationPage?.afterChange || "(nach Abzug aller Boni)";
  }, [confirmationPage?.afterChange]);
  const gesamtpreisLabel = React.useMemo(() => {
    return confirmationPage?.gesamtpreis || "Gesamtpreis";
  }, [confirmationPage?.gesamtpreis]);
  const preisinformationLabel = React.useMemo(() => {
    return confirmationPage?.preisinformation || "Preisinformation";
  }, [confirmationPage?.preisinformation]);
  const perYearLabel = React.useMemo(() => {
    return confirmationPage?.perYear || " / Jahr";
  }, [confirmationPage?.perYear]);
  const perMonthLabel = React.useMemo(() => {
    return confirmationPage?.perMonth || "/ Monat";
  }, [confirmationPage?.perMonth]);
  const perOnceLabel = React.useMemo(() => {
    return confirmationPage?.perOnce || "einmalig";
  }, [confirmationPage?.perOnce]);
  const arbeitspreisLabel = React.useMemo(() => {
    return confirmationPage?.arbeitspreis || "Arbeitspreis";
  }, [confirmationPage?.arbeitspreis]);
  const grundpreisLabel = React.useMemo(() => {
    return confirmationPage?.grundpreis || "Grundpreis";
  }, [confirmationPage?.grundpreis]);
  const centPerKwhLabel = React.useMemo(() => {
    return confirmationPage?.centPerKwh || "ct / kWh";
  }, [confirmationPage?.centPerKwh]);
  const ihrTariffLabel = React.useMemo(() => {
    return confirmationPage?.ihrTariff || "Ihr Tarif";
  }, [confirmationPage?.ihrTariff]);
  const textConfirmLabel = React.useMemo(() => {
    return (
      confirmationPage?.textConfirm ||
      "Vielen Dank für Ihren Auftrag! Wir werden uns in Kürze mit Ihnen in Verbindung setzen."
    );
  }, [confirmationPage?.textConfirm]);
  const btnTextLabel = React.useMemo(() => {
    return confirmationPage?.btnText || "Auftragsbestätigung ausdrucken";
  }, [confirmationPage?.btnText]);
  const backToHomeLabel = React.useMemo(() => {
    return confirmationPage?.backToHome || "Zurück zum Tarifrechner";
  }, [confirmationPage?.backToHome]);
  const ersparnisLabel = React.useMemo(() => {
    return (
      confirmationPage?.ersparnis ||
      "Ersparnis zum Vergleichstarif im ersten Jahr"
    );
  }, [confirmationPage?.ersparnis]);
  const vertragsInformationLabel = React.useMemo(() => {
    return confirmationPage?.vertragsInformation || "Vertragsinformation";
  }, [confirmationPage?.vertragsInformation]);
  const vertragslaufzeitLabel = React.useMemo(() => {
    return confirmationPage?.vertragslaufzeit || "Vertragslaufzeit";
  }, [confirmationPage?.vertragslaufzeit]);
  const ihrVerbrauchLabel = React.useMemo(() => {
    return confirmationPage?.ihrVerbrauch || "Ihr Jahresverbrauch";
  }, [confirmationPage?.ihrVerbrauch]);
  const kuendigungsfristLabel = React.useMemo(() => {
    return confirmationPage?.kuendigungsfrist || "Kündigungsfrist";
  }, [confirmationPage?.kuendigungsfrist]);
  const verbrauchLabel = React.useMemo(() => {
    return confirmationPage?.verbrauch || "Verbrauch";
  }, [confirmationPage?.verbrauch]);
  const lieferanschriftLabel = React.useMemo(() => {
    return confirmationPage?.lieferanschrift || "Lieferanschrift";
  }, [confirmationPage?.lieferanschrift]);
  const rechnungsanschriftLabel = React.useMemo(() => {
    return (
      confirmationPage?.rechnungsanschrift || "abweichende Rechnungsanschrift"
    );
  }, [confirmationPage?.rechnungsanschrift]);
  const abwlieferanschriftLabel = React.useMemo(() => {
    return confirmationPage?.abwlieferanschrift || "abweichende Lieferadresse";
  }, [confirmationPage?.abwlieferanschrift]);
  const geburtsdatumLabel = React.useMemo(() => {
    return confirmationPage?.geburtsdatum || "Geburtsdatum";
  }, [confirmationPage?.geburtsdatum]);
  const ihrVorversorgerLabel = React.useMemo(() => {
    return confirmationPage?.ihrVorversorger || "Ihr Vorversorger";
  }, [confirmationPage?.ihrVorversorger]);
  const kundennummerLabel = React.useMemo(() => {
    return confirmationPage?.kundennummer || "Kundennummer";
  }, [confirmationPage?.kundennummer]);
  const vorversorgerLabel = React.useMemo(() => {
    return confirmationPage?.vorversorger || "Vorversorger";
  }, [confirmationPage?.vorversorger]);
  const bankverbindungLabel = React.useMemo(() => {
    return confirmationPage?.bankverbindung || "Bankverbindung";
  }, [confirmationPage?.bankverbindung]);
  const ibanLabel = React.useMemo(() => {
    return confirmationPage?.iban || "IBAN";
  }, [confirmationPage?.iban]);
  const methodeLabel = React.useMemo(() => {
    return confirmationPage?.methode || "Methode";
  }, [confirmationPage?.methode]);
  const sepa_lastschriftLabel = React.useMemo(() => {
    return confirmationPage?.sepa_lastschrift || "SEPA Lastschrift";
  }, [confirmationPage?.sepa_lastschrift]);
  const bicLabel = React.useMemo(() => {
    return confirmationPage?.bic || "BIC";
  }, [confirmationPage?.bic]);
  const bankinstituteLabel = React.useMemo(() => {
    return confirmationPage?.bankinstitute || "Bankinstitut";
  }, [confirmationPage?.bankinstitute]);
  const ueberweisungLabel = React.useMemo(() => {
    return confirmationPage?.ueberweisung || "Überweisung";
  }, [confirmationPage?.ueberweisung]);
  const kontaktdatenLabel = React.useMemo(() => {
    return confirmationPage?.kontaktdaten || "Kontaktdaten";
  }, [confirmationPage?.kontaktdaten]);
  const mailadresseLabel = React.useMemo(() => {
    return confirmationPage?.mailadresse || "E-Mail-Adresse";
  }, [confirmationPage?.mailadresse]);
  const telefonnummerLabel = React.useMemo(() => {
    return confirmationPage?.telefonnummer || "Telefonnummer";
  }, [confirmationPage?.telefonnummer]);
  const faxnummerLabel = React.useMemo(() => {
    return confirmationPage?.faxnummer || "Faxnummer";
  }, [confirmationPage?.faxnummer]);
  const handynummerLabel = React.useMemo(() => {
    return confirmationPage?.handynummer || "Mobilfunknummer";
  }, [confirmationPage?.handynummer]);
  const zaehlernummerLabel = React.useMemo(() => {
    return confirmationPage?.zaehlernummer || "Zählernummer";
  }, [confirmationPage?.zaehlernummer]);
  const einzugsdatumLabel = React.useMemo(() => {
    return confirmationPage?.einzugsdatum || "Einzugsdatum";
  }, [confirmationPage?.einzugsdatum]);
  const bisherigeLabel = React.useMemo(() => {
    return confirmationPage?.bisherige || "bisherige";
  }, [confirmationPage?.bisherige]);
  const zaehlerstandLabel = React.useMemo(() => {
    return confirmationPage?.zaehlerstand || "Zählerstand";
  }, [confirmationPage?.zaehlerstand]);
  const anschlussdatenLabel = React.useMemo(() => {
    return confirmationPage?.anschlussdaten || "Anschlussdaten";
  }, [confirmationPage?.anschlussdaten]);
  const wechselgrundLabel = React.useMemo(() => {
    return confirmationPage?.wechselgrund || "Grund für den Wechsel";
  }, [confirmationPage?.wechselgrund]);
  const lieferantenwechselLabel = React.useMemo(() => {
    return confirmationPage?.lieferantenwechsel || "Lieferantenwechsel";
  }, [confirmationPage?.lieferantenwechsel]);
  const downloadLabel = React.useMemo(() => {
    return confirmationPage?.download || "Download";
  }, [confirmationPage?.download]);
  const tarifInfoLabel = React.useMemo(() => {
    return confirmationPage?.tarifInfo || "Tarifinformation";
  }, [confirmationPage?.tarifInfo]);
  const ecoTarifLabel = React.useMemo(() => {
    return confirmationPage?.ecoTarif || "Ökotarif";
  }, [confirmationPage?.ecoTarif]);
  const yesLabel = React.useMemo(() => {
    return confirmationPage?.yes || "ja";
  }, [confirmationPage?.yes]);
  const irrelevantLabel = React.useMemo(() => {
    return confirmationPage?.irrelevant || "egal";
  }, [confirmationPage?.irrelevant]);
  const tariffTypeLabel = React.useMemo(() => {
    return confirmationPage?.tariffType || "Zählerart";
  }, [confirmationPage?.tariffType]);
  const customerTypeLabel = React.useMemo(() => {
    return confirmationPage?.customerType || "Kundenart";
  }, [confirmationPage?.customerType]);
  const businessLabel = React.useMemo(() => {
    return confirmationPage?.business || "Gewerbe";
  }, [confirmationPage?.business]);
  const privateLabel = React.useMemo(() => {
    return confirmationPage?.private || "Privat";
  }, [confirmationPage?.private]);
  const tarifftypeNameLabel = React.useMemo(() => {
    return confirmationPage?.tarifftypeName || "Tariftyp";
  }, [confirmationPage?.tarifftypeName]);
  const waermepumpeLabel = React.useMemo(() => {
    return confirmationPage?.waermepumpe || "Wärmepumpe";
  }, [confirmationPage?.waermepumpe]);
  const speicherheizungLabel = React.useMemo(() => {
    return confirmationPage?.speicherheizung || "Speicherheizung";
  }, [confirmationPage?.speicherheizung]);
  const eintarifLabel = React.useMemo(() => {
    return confirmationPage?.eintarif || "Eintarif";
  }, [confirmationPage?.eintarif]);
  const zweitarifLabel = React.useMemo(() => {
    return confirmationPage?.zweitarif || "Zweitarif";
  }, [confirmationPage?.zweitarif]);
  const autoStromGemeinsameMessungLabel = React.useMemo(() => {
    return (
      confirmationPage?.autoStromGemeinsameMessung ||
      "Autostrom gemeinsame Messung"
    );
  }, [confirmationPage?.autoStromGemeinsameMessung]);
  const autoStromGetrennteMessungLabel = React.useMemo(() => {
    return (
      confirmationPage?.autoStromGetrennteMessung ||
      "Autostrom getrennte Messung"
    );
  }, [confirmationPage?.autoStromGetrennteMessung]);
  const EVNLabel = React.useMemo(() => {
    return confirmationPage?.EVN || "Einzelverbindungsnachweis";
  }, [confirmationPage?.EVN]);
  const nextPossibleDateTextLabel = React.useMemo(() => {
    return (
      confirmationPage?.nextPossibleDateText || "zum nächstmöglichen Termin"
    );
  }, [confirmationPage?.nextPossibleDateText]);
  const startOfDeliveryLabel = React.useMemo(() => {
    return confirmationPage?.startOfDelivery || "Gewünschter Lieferbeginn";
  }, [confirmationPage?.startOfDelivery]);
  const newTextLabel = React.useMemo(() => {
    return confirmationPage?.newText || "neue Rufnummer";
  }, [confirmationPage?.newText]);
  const oldTextLabel = React.useMemo(() => {
    return confirmationPage?.oldText || "Bestehende Rufnummer(n) portieren";
  }, [confirmationPage?.oldText]);
  const numberPortabilityLabel = React.useMemo(() => {
    return confirmationPage?.numberPortability || "Rufnummer(n)";
  }, [confirmationPage?.numberPortability]);
  const installLocationTitleLabel = React.useMemo(() => {
    return (
      confirmationPage?.installLocationTitle || "Installationsort/Stockwerk"
    );
  }, [confirmationPage?.installLocationTitle]);
  const isprepaidtarifftrueLabel = React.useMemo(() => {
    return confirmationPage?.isprepaidtarifftrue || "ja";
  }, [confirmationPage?.isprepaidtarifftrue]);
  const isprepaidtarifffalseLabel = React.useMemo(() => {
    return confirmationPage?.isprepaidtarifffalse || "nein";
  }, [confirmationPage?.isprepaidtarifffalse]);
  const bonusChangeLabel = React.useMemo(() => {
    return confirmationPage?.bonusChange || "Wechselbonus";
  }, [confirmationPage?.bonusChange]);
  const waiverCancellationPolicyLabel = React.useMemo(() => {
    return (
      confirmationPage?.waiverCancellationPolicy ||
      "Hiermit verzichte auf mein Widerrufsrecht"
    );
  }, [confirmationPage?.waiverCancellationPolicy]);
  return {
    umzugLabel,
    adressdatenLabel,
    ihrFirmaLabel,
    auftragsbestaetigungLabel,
    afterChangeLabel,
    gesamtpreisLabel,
    preisinformationLabel,
    perYearLabel,
    perMonthLabel,
    perOnceLabel,
    arbeitspreisLabel,
    grundpreisLabel,
    centPerKwhLabel,
    ihrTariffLabel,
    textConfirmLabel,
    btnTextLabel,
    backToHomeLabel,
    ersparnisLabel,
    vertragsInformationLabel,
    vertragslaufzeitLabel,
    ihrVerbrauchLabel,
    kuendigungsfristLabel,
    verbrauchLabel,
    lieferanschriftLabel,
    rechnungsanschriftLabel,
    abwlieferanschriftLabel,
    geburtsdatumLabel,
    ihrVorversorgerLabel,
    kundennummerLabel,
    vorversorgerLabel,
    bankverbindungLabel,
    ibanLabel,
    methodeLabel,
    sepa_lastschriftLabel,
    bicLabel,
    bankinstituteLabel,
    ueberweisungLabel,
    kontaktdatenLabel,
    mailadresseLabel,
    telefonnummerLabel,
    faxnummerLabel,
    handynummerLabel,
    zaehlernummerLabel,
    einzugsdatumLabel,
    bisherigeLabel,
    zaehlerstandLabel,
    anschlussdatenLabel,
    wechselgrundLabel,
    lieferantenwechselLabel,
    downloadLabel,
    tarifInfoLabel,
    ecoTarifLabel,
    yesLabel,
    irrelevantLabel,
    tariffTypeLabel,
    customerTypeLabel,
    businessLabel,
    privateLabel,
    tarifftypeNameLabel,
    waermepumpeLabel,
    speicherheizungLabel,
    eintarifLabel,
    zweitarifLabel,
    autoStromGemeinsameMessungLabel,
    autoStromGetrennteMessungLabel,
    EVNLabel,
    nextPossibleDateTextLabel,
    startOfDeliveryLabel,
    newTextLabel,
    oldTextLabel,
    numberPortabilityLabel,
    installLocationTitleLabel,
    isprepaidtarifftrueLabel,
    isprepaidtarifffalseLabel,
    bonusChangeLabel,
    waiverCancellationPolicyLabel,
  };
};

export default useFeatureConfirmationPage;
