import { useFormikContext } from "formik";
import React from "react";

import FormControl from "../../../../components/FormControl";
import Input from "../../../../components/Input";
import { useStepChange } from "../../../../context";
import { useCalcParams } from "../../../../context/CalculationParameter";
import { useSelectTariff } from "../../../../context/Tariff";
import { useSelectTelco } from "../../../../context/Telco";
import { contactDataValidateKeys } from "../../Constant";
import {
  useActivatedPanels,
  useContractConclusionFormName,
} from "../../context";
import useCheckFormValidationAndRedirect from "../../hooks/useCheckFormValidationAndRedirect";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import useServerValidationForm from "../../hooks/useServerValidationForm";
import useSetFieldWithVPPData from "../../hooks/useSetFieldWithVPPData";
import FooterButtons from "../FooterButtons";
import Contact from "./Contact";
import PersonalData from "./PersonalData";

const NEXT_STEP = "addressForm";

const ContactData = () => {
  const { values } = useFormikContext();
  const { setFormName } = useContractConclusionFormName();
  const { calcParams } = useCalcParams();
  const { mutateAsync, data } = useServerValidationForm("contactForm", {
    tarifParameter: calcParams,
    keysToValidate: contactDataValidateKeys,
  });
  const { setSelectedTelco } = useSelectTelco();
  const { setSelectTariff } = useSelectTariff();
  const { setStep } = useStepChange();
  const { setActivatedPanels } = useActivatedPanels();

  const { isShowPassword } = useFeaturesCC();
  useSetFieldWithVPPData("contactForm");
  useCheckFormValidationAndRedirect(data, NEXT_STEP);

  return (
    <FormControl className="flex-wrap flex-shrink-0">
      <PersonalData />
      <Contact />

      {isShowPassword && (
        <>
          <div className="mt-6 mb-2 text-lg font-bold">Sicherheitskennzahl</div>
          <Input
            name="safetyIndex"
            className="w-full"
            label="Sicherheitskennzahl *"
            maxLength={4}
          />
        </>
      )}

      <FooterButtons
        onSubmitValidation={async () => {
          await mutateAsync(values);
        }}
        onSubmit={() => {
          setFormName(NEXT_STEP);
          setActivatedPanels((prevState) => [...prevState, NEXT_STEP]);
        }}
        onBack={() => {
          setStep(2);
          setSelectedTelco(null);
          setSelectTariff(null);
        }}
      />
    </FormControl>
  );
};

export default ContactData;
