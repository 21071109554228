import React from "react";
import { createContext, useContextSelector } from "use-context-selector";

import { FeConfig } from "../../hooks/useFrontendConfig";

export interface config {
  apikey: string;
  getAPIKey: () => Promise<string>;
  frontendConfig?: FeConfig;
  media?: "tablet" | "desktop" | "mobile";
  boxWidth: number;
  reference: React.MutableRefObject<HTMLElement | null>;
}

interface configContext {
  config: config;
  setConfig: React.Dispatch<React.SetStateAction<config>>;
}

const configContext = createContext<configContext>({} as configContext);

const ConfigContextProvider = ({ children, value }: { children: React.ReactNode; value: config }) => {
  const [config, setConfig] = React.useState(value);

  React.useEffect(() => {
    setConfig((config) => {
      return { ...config, media: value.media };
    });
  }, [value.media]);

  return <configContext.Provider value={{ config, setConfig }}>{children}</configContext.Provider>;
};

export default ConfigContextProvider;
export const useConfig = () => useContextSelector(configContext, (c) => c);
