import { useField, useFormikContext } from 'formik';
import React from 'react';

import Button from '../../../../components/Button';
import { useSelectTariff } from "../../../../context/Tariff";
import { useSelectTelco } from "../../../../context/Telco";
import useFeaturesCC from '../../hooks/useFeaturesCC';
import handleAddressValidation from "../../utils/handleValidation/address";
import handleBankValidation from "../../utils/handleValidation/bank";
import handleContactValidation from "../../utils/handleValidation/contact";
import handlePreSupplierValidation from "../../utils/handleValidation/preSupplier";
import FormData from '../AddressData/FormData';
import BankDataForm from '../BankData/BankDataForm';
import PaymentOptions from '../BankData/PaymentOptions';
import Contact from '../ContactData/Contact';
import PersonalData from '../ContactData/PersonalData';
import PreSupplierData from '../PreSupplierData';

const EditDataModal = ({modalVisible, setModalVisible}) =>{
    const { resetForm, submitForm, values } = useFormikContext();
    const { selectedTelco } = useSelectTelco();
    const { selectedTariff } = useSelectTariff();
    const ibanError = useField("iban")[1].error;
    const {
        isRepeatMail,
        phoneLabel,
        reasonToChangeMoveExistingCustomer,
    } = useFeaturesCC();

    const isTelco = React.useMemo(
    () => !selectedTariff && selectedTelco,
    [selectedTariff, selectedTelco]
    );

    const disabled = React.useMemo(() => {
        if(values?.asOffer) {
          return true;
        }

        if (modalVisible === "personalData") {
          return handleContactValidation(values, { isRepeatMail, phoneLabel });
        }
    
        if (modalVisible === "deliveryAddress"|| modalVisible === "billingAddress") {
          return handleAddressValidation(values, { isTelco });
        }
    
        if (modalVisible === "preSupplierData") {
          return handlePreSupplierValidation(values, {
            isTelco,
            reasonToChangeMoveExistingCustomer,
          });
        }
    
        if (modalVisible === "bankData") {
          return handleBankValidation(values, !!ibanError);
        }
        return false;
  }, [
    modalVisible,
    isRepeatMail,
    isTelco,
    phoneLabel,
    reasonToChangeMoveExistingCustomer,
    values,
    ibanError,
  ]);
    return(
            <div className="p-7">
                {modalVisible==="personalData" &&
                <>
                    <PersonalData />
                    <Contact />
                </>
                }
                {modalVisible==="deliveryAddress" &&<FormData type="deliveryAddress" disableFooterButtons={true}/>}
                {modalVisible==="billingAddress" &&<div className="mb-4"><FormData type="billingAddress" disableFooterButtons={true}/></div>}
                {modalVisible==="preSupplierData" &&<PreSupplierData disableFooterButtons={true} />}
                {modalVisible==="bankData" &&     
                <>
                    <PaymentOptions />
                    <BankDataForm />
                </> 
                }
                <Button onClick={()=>{setModalVisible(false); resetForm();}} variant="primary" className="px-6 py-2 float-left mb-7">abbrechen</Button>
                <Button type="submit" onClick={()=>{setModalVisible(false); submitForm()}} disabled={!disabled} variant="primary" className="px-6 py-2 float-right mb-7">speichern und schließen</Button>
            </div>
    )
}
export default EditDataModal;