import React, { useCallback, useEffect, useRef, useState } from "react";

interface ScrollBoxProps {
  children: React.ReactNode;
  maxHeight?: number;
}

const ScrollBox: React.FC<ScrollBoxProps> = ({ children, maxHeight = 300 }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [thumbHeight, setThumbHeight] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);
  const [containerHeight, setContainerHeight] = useState<number | "auto">("auto");

  const calculateHeight = useCallback(() => {
    if (boxRef.current) {
      const contentScrollable = boxRef.current.scrollHeight > maxHeight;
      setIsScrollable(contentScrollable);

      if (!contentScrollable) {
        setContainerHeight(boxRef.current.scrollHeight);
      } else {
        setContainerHeight(maxHeight);
      }
      if (contentScrollable) {
        const thumbHeight = (boxRef.current.clientHeight / boxRef.current.scrollHeight) * maxHeight;
        setThumbHeight(thumbHeight);
      }
    }
  }, [maxHeight]);

  useEffect(() => {
    calculateHeight();

    const resizeObserver = new ResizeObserver(() => {
      calculateHeight();
    });

    const currentContentRef = contentRef.current;
    if (currentContentRef) {
      resizeObserver.observe(currentContentRef);
    }
    const currentboxRef = boxRef.current;
    if (currentboxRef) {
      resizeObserver.observe(currentboxRef);
    }

    return () => {
      if (currentContentRef) {
        resizeObserver.unobserve(currentContentRef);
      }
      if (currentboxRef) {
        resizeObserver.unobserve(currentboxRef);
      }
    };
  }, [calculateHeight]);

  const handleScroll = () => {
    if (boxRef.current && thumbRef.current) {
      const scrollPercentage = boxRef.current.scrollTop / (boxRef.current.scrollHeight - boxRef.current.clientHeight);
      setScrollTop(scrollPercentage * ((containerHeight as number) - thumbHeight));
    }
  };

  const handleThumbDrag = (e: React.MouseEvent) => {
    e.preventDefault();
    const startY = e.clientY;
    const startScrollTop = scrollTop;

    const onMouseMove = (eMove: MouseEvent) => {
      const deltaY = eMove.clientY - startY;
      const newScrollTop = startScrollTop + deltaY;

      if (boxRef.current) {
        const scrollPercentage = newScrollTop / ((containerHeight as number) - thumbHeight);
        boxRef.current.scrollTop = scrollPercentage * (boxRef.current.scrollHeight - boxRef.current.clientHeight);
      }
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  return (
    <div className="relative w-full overflow-hidden" style={{ height: containerHeight ?? 300 }}>
      <div
        ref={boxRef}
        onScroll={handleScroll}
        className={`max-h-full ${isScrollable ? "overflow-y-scroll hide-scrollbar" : "overflow-y-auto"}`}
      >
        <div ref={contentRef}>{children}</div>
      </div>

      {isScrollable && (
        <div className="absolute top-0 right-1 w-2 h-full">
          <div
            ref={thumbRef}
            onMouseDown={handleThumbDrag}
            className="absolute right-0 w-full bg-secondary rounded-md cursor-pointer"
            style={{ top: `${scrollTop}px`, height: `${thumbHeight}px` }}
          />
        </div>
      )}
    </div>
  );
};

export default ScrollBox;
