import { addDays } from "date-fns";

const deadLineDayValid = (value: string, min: number, max: number) => {
  function isMaxDeadLineDay() {
    if (value?.length === 10) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const maxDate = addDays(today, -min);
      const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
      const birthday = new Date(Number(yyyy), Number(mm) - 1, Number(dd));

      return birthday <= maxDate;
    }
    return false;
  }

  function isMinDeadLineDay() {
    if (value?.length === 10) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const minDate = addDays(today, -max);
      const [dd, mm, yyyy] = value?.split(".") || [0, 1, 1];
      const birthday = new Date(Number(yyyy), Number(mm) - 1, Number(dd));
      return birthday >= minDate;
    }
    return false;
  }

  function getMinDeadLineDay() {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    return addDays(today, -min);
  }
  function getMaxDeadLineDay() {
    const today = new Date(new Date().setHours(0, 0, 0, 0));
    return addDays(today, -max);
  }

  return { getMaxDeadLineDay, getMinDeadLineDay, isMinDeadLineDay, isMaxDeadLineDay };
};
export default deadLineDayValid;
