import { useMemo } from "react";

import useExternalConfigs from "../../../../hooks/useExternalConfigs";

const useFeaturesResult = () => {
  const { data: config } = useExternalConfigs("resultpage");
  const resultPage = config?.data?.data?.resultPage;
  const functionPage = config?.data?.data?.functionsresultPage;

  const showMonthlyPaymentAmount = useMemo(() => {
    return functionPage?.showMonthlyPaymentAmount || false;
  }, [functionPage?.showMonthlyPaymentAmount]);

  const pricesNetGross = useMemo(() => {
    return functionPage?.pricesNetGross || "default";
  }, [functionPage?.pricesNetGross]);

  const isShowNetGross = useMemo(
    () =>
      functionPage?.showNetGross || false,
    [functionPage?.showNetGross]
  );

  const telcoLabel = useMemo(() => {
    return resultPage?.buttontext;
  }, [resultPage?.buttontext]);

  const unitUsageLabel = useMemo(() => {
    return resultPage?.unitUsage || "kWh/Jahr";
  }, [resultPage?.unitUsage]);

  const strommixLabel = useMemo(() => {
    return resultPage?.strommix || "Strommix";
  }, [resultPage?.strommix]);

  const onlyGasEcoLabel = useMemo(() => {
    return resultPage?.onlyGasEco || "Nur Ökogas";
  }, [resultPage?.onlyGasEco]);

  const onlyElectricityEcoLabel = useMemo(() => {
    return resultPage?.onlyElectricityEco || "Nur Ökostrom";
  }, [resultPage?.onlyElectricityEco]);

  const showTariffDetailLabel = useMemo(() => {
    return resultPage?.showTariffDetail || "Tarifdetails anzeigen";
  }, [resultPage?.showTariffDetail]);

  const unitPerYearLabel = useMemo(() => {
    return resultPage?.unitPerYear || "/Jahr";
  }, [resultPage?.unitPerYear]);

  const tryAgainLabel = useMemo(() => {
    return (
      resultPage?.tryAgain ||
      "Über den Button 'Suchkriterien ändern' können Sie diese anpassen."
    );
  }, [resultPage?.tryAgain]);

  const tariffTypeLabel = useMemo(() => {
    return resultPage?.tariffType || "Zählerart";
  }, [resultPage?.tariffType]);

  const oneTariffLabel = useMemo(() => {
    return resultPage?.oneTariff || "Eintarif";
  }, [resultPage?.oneTariff]);

  const twoTariffsLabel = useMemo(() => {
    return resultPage?.twoTariffs || "Zweitarif";
  }, [resultPage?.twoTariffs]);

  const customerTypeLabel = useMemo(() => {
    return resultPage?.customerType || "Kundenart";
  }, [resultPage?.customerType]);

  const privateLabel = useMemo(() => {
    return resultPage?.private || "Privat";
  }, [resultPage?.private]);

  const businessLabel = useMemo(() => {
    return resultPage?.business || "Gewerbe";
  }, [resultPage?.business]);

  const usageInvalidMessageLabel = useMemo(() => {
    return resultPage?.usageInvalidMessage || "dieser Wert ist nicht erlaubt";
  }, [resultPage?.usageInvalidMessage]);

  const usageLabel = useMemo(() => {
    return resultPage?.usage || "Verbrauch";
  }, [resultPage?.usage]);

  const headerLabel = useMemo(() => {
    return resultPage?.header || "Ergebnisliste";
  }, [resultPage?.header]);

  const headerOptionsLabel = useMemo(() => {
    return resultPage?.headerOptions || "Produktoptionen";
  }, [resultPage?.headerOptions]);

  const headerSubLabel = useMemo(() => {
    return resultPage?.headerSub || "Ihre Suchkriterien";
  }, [resultPage?.headerSub]);

  const savingLabel = useMemo(() => {
    return resultPage?.saving || "Ersparnis zum Vergleichstarif im ersten Jahr";
  }, [resultPage?.saving]);

  const savingTariffLabel = useMemo(() => {
    return resultPage?.savingTariff || "Ihr Vergleichstarif";
  }, [resultPage?.savingTariff]);

  const cancellationperiodLabel = useMemo(() => {
    return resultPage?.cancellationperiod || "Kündigungfrist";
  }, [resultPage?.cancellationperiod]);

  const priceInformationLabel = useMemo(() => {
    return resultPage?.priceInformation || "Preisinformation";
  }, [resultPage?.priceInformation]);

  const priceguaranteeLabel = useMemo(() => {
    return resultPage?.priceguarantee || "Preisgarantie";
  }, [resultPage?.priceguarantee]);

  const contractperiodLabel = useMemo(() => {
    return resultPage?.contractperiod || "Vertragslaufzeit";
  }, [resultPage?.contractperiod]);

  const co2unitLabel = useMemo(() => {
    return resultPage?.co2unit || "g/kWh";
  }, [resultPage?.co2unit]);

  const renewableLabel = useMemo(() => {
    return resultPage?.renewable || "Erneuerbare Energien nach EEG";
  }, [resultPage?.renewable]);

  const renewableunitLabel = useMemo(() => {
    return resultPage?.renewableunit || "%";
  }, [resultPage?.renewableunit]);

  const fossilLabel = useMemo(() => {
    return resultPage?.fossil || "Fossile Energien";
  }, [resultPage?.fossil]);

  const nuclearLabel = useMemo(() => {
    return resultPage?.nuclear || "Kernkraft";
  }, [resultPage?.nuclear]);

  const radioactivLabel = useMemo(() => {
    return resultPage?.radioactiv || "radioaktiver Abfall";
  }, [resultPage?.radioactiv]);

  const fossilunitLabel = useMemo(() => {
    return resultPage?.fossilunit || "%";
  }, [resultPage?.fossilunit]);

  const nuclearunitLabel = useMemo(() => {
    return resultPage?.nuclearunit || "%";
  }, [resultPage?.nuclearunit]);

  const contractextensionLabel = useMemo(() => {
    return resultPage?.contractextension || "Vertragsverlängerung";
  }, [resultPage?.contractextension]);

  const totalpriceLabel = useMemo(() => {
    return resultPage?.totalprice || "Gesamtpreis";
  }, [resultPage?.totalprice]);

  const totalpriceAfterBonusLabel = useMemo(() => {
    return resultPage?.totalpriceAfterBonus || "(nach Abzug aller Boni)";
  }, [resultPage?.totalpriceAfterBonus]);

  const totalpriceBeforeBonusLabel = useMemo(() => {
    return (
      resultPage?.totalpriceBeforeBonus ||
      "(vor Abzug aller Boni, inklusive Aufschläge)"
    );
  }, [resultPage?.totalpriceBeforeBonus]);

  const basepriceLabel = useMemo(() => {
    return resultPage?.baseprice || "Grundpreis";
  }, [resultPage?.baseprice]);

  const workingpriceLabel = useMemo(() => {
    return resultPage?.workingprice || "Arbeitspreis";
  }, [resultPage?.workingprice]);

  const buttontextLabel = useMemo(() => {
    return resultPage?.buttontext || "Vertrag abschließen";
  }, [resultPage?.buttontext]);

  const workingpriceunitLabel = useMemo(() => {
    return resultPage?.workingpriceunit || "ct/kWh";
  }, [resultPage?.workingpriceunit]);

  const tariffdescriptionLabel = useMemo(() => {
    return resultPage?.tariffdescription || "Tarifbeschreibung";
  }, [resultPage?.tariffdescription]);

  const co2Label = useMemo(() => {
    return resultPage?.co2 || "CO2-Emmision";
  }, [resultPage?.co2]);

  const radioactivunitLabel = useMemo(() => {
    return resultPage?.radioactivunit || "%";
  }, [resultPage?.radioactivunit]);

  const standLabel = useMemo(() => {
    return resultPage?.stand || "Stand";
  }, [resultPage?.stand]);

  const isprepaidtariffLabel = useMemo(() => {
    return resultPage?.isprepaidtariff || "Vorkassetarif";
  }, [resultPage?.isprepaidtariff]);

  const ispackagetariffLabel = useMemo(() => {
    return resultPage?.ispackagetariff || "Pakettarif";
  }, [resultPage?.ispackagetariff]);

  const isprepaidtarifftrueLabel = useMemo(() => {
    return resultPage?.isprepaidtarifftrue || "ja";
  }, [resultPage?.isprepaidtarifftrue]);

  const isprepaidtarifffalseLabel = useMemo(() => {
    return resultPage?.isprepaidtarifffalse || "nein";
  }, [resultPage?.isprepaidtarifffalse]);

  const ispackagetarifftrueLabel = useMemo(() => {
    return resultPage?.ispackagetarifftrue || "ja";
  }, [resultPage?.ispackagetarifftrue]);

  const ispackagetarifffalseLabel = useMemo(() => {
    return resultPage?.ispackagetarifffalse || "nein";
  }, [resultPage?.ispackagetarifffalse]);

  const yourDiscountLabel = useMemo(() => {
    return resultPage?.yourDiscount || "Abschlag (geschätzt)";
  }, [resultPage?.yourDiscount]);

  const ecoTarifLabel = useMemo(() => {
    return resultPage?.ecoTarif || "Ökotarif";
  }, [resultPage?.ecoTarif]);

  const bonusLabel = useMemo(() => {
    return resultPage?.bonus || "Bonus";
  }, [resultPage?.bonus]);

  const bonusChangeLabel = useMemo(() => {
    return resultPage?.bonusChange || "Wechselbonus";
  }, [resultPage?.bonusChange]);

  const backToSearchBtnTextLabel = useMemo(() => {
    return resultPage?.backToSearchBtnText || "Suchkriterien ändern";
  }, [resultPage?.backToSearchBtnText]);

  const noListProductLabel = useMemo(() => {
    return (
      resultPage?.noListProduct ||
      "Leider wurden zu Ihren Suchkriterien keine passenden Angebote gefunden."
    );
  }, [resultPage?.noListProduct]);

  const yesLabel = useMemo(() => {
    return resultPage?.yes || "ja";
  }, [resultPage?.yes]);

  const noLabel = useMemo(() => {
    return resultPage?.no || "nein";
  }, [resultPage?.no]);

  const irrelevantLabel = useMemo(() => {
    return resultPage?.irrelevant || "egal";
  }, [resultPage?.irrelevant]);

  const waermepumpeLabel = useMemo(() => {
    return resultPage?.waermepumpe || "Wärmepumpe";
  }, [resultPage?.waermepumpe]);

  const speicherheizungLabel = useMemo(() => {
    return resultPage?.speicherheizung || "Speicherheizung";
  }, [resultPage?.speicherheizung]);

  const tarifftypeNameLabel = useMemo(() => {
    return resultPage?.tarifftypeName || "Tariftyp";
  }, [resultPage?.tarifftypeName]);

  const autoStromGemeinsameMessungLabel = useMemo(() => {
    return (
      resultPage?.autoStromGemeinsameMessung || "Autostrom gemeinsame Messung"
    );
  }, [resultPage?.autoStromGemeinsameMessung]);

  const autoStromGetrennteMessungLabel = useMemo(() => {
    return (
      resultPage?.autoStromGetrennteMessung || "Autostrom getrennte Messung"
    );
  }, [resultPage?.autoStromGetrennteMessung]);

  const internetSpeedUnitLabel = useMemo(() => {
    return resultPage?.internetSpeedUnit || "Mbit/s";
  }, [resultPage?.internetSpeedUnit]);

  const priceMonthlyLabel = useMemo(() => {
    return resultPage?.priceMonthly || "Gesamt monatlich";
  }, [resultPage?.priceMonthly]);

  const priceOnceLabel = useMemo(() => {
    return resultPage?.priceOnce || "Gesamt einmalig";
  }, [resultPage?.priceOnce]);

  const setupCostLabel = useMemo(() => {
    return resultPage?.setupCost || "Aktivierungskosten";
  }, [resultPage?.setupCost]);

  const periodOnceLabel = useMemo(() => {
    return resultPage?.periodOnce || "einmalig";
  }, [resultPage?.periodOnce]);

  const periodMonthlyLabel = useMemo(() => {
    return resultPage?.periodMonthly || "monatlich";
  }, [resultPage?.periodMonthly]);

  const periodOnceSummaryLabel = useMemo(() => {
    return resultPage?.periodOnceSummary || "Ihre einmaligen Kosten";
  }, [resultPage?.periodOnceSummary]);

  const periodMonthlySummaryLabel = useMemo(() => {
    return resultPage?.periodMonthlySummary || "Ihre monatlichen Kosten";
  }, [resultPage?.periodMonthlySummary]);

  const backToOptionsBtnTextLabel = useMemo(() => {
    return resultPage?.backToOptionsBtnText || "Zurück zur Tarifauswahl";
  }, [resultPage?.backToOptionsBtnText]);

  const optionInfoLabel = useMemo(() => {
    return resultPage?.optionInfo || "Produktinformationsblatt (PDF)";
  }, [resultPage?.optionInfo]);

  const shippingCostsLabel = useMemo(() => {
    return resultPage?.shippingCosts || "Versandkosten";
  }, [resultPage?.shippingCosts]);

  const tariffDetailsLabel = useMemo(() => {
    return resultPage?.tariffDetails || "Tarifdetails";
  }, [resultPage?.tariffDetails]);

  const productDescriptionLabel = useMemo(() => {
    return resultPage?.productDescription || "Produktbeschreibung";
  }, [resultPage?.productDescription]);

  const missingProductDescriptionLabel = useMemo(() => {
    return (
      resultPage?.missingProductDescription ||
      "Dieses Produkt hat keine Beschreibung"
    );
  }, [resultPage?.missingProductDescription]);

  const infinityPeriodLabel = useMemo(() => {
    return resultPage?.infinityPeriod || "auf unbestimmte Zeit";
  }, [resultPage?.infinityPeriod]);

  const TCS_BtnLabel = useMemo(() => {
    return resultPage?.TCS_Btn || "ändern und neu berechnen";
  }, [resultPage?.TCS_Btn]);

  const townLabel = useMemo(() => {
    return resultPage?.townLabel || "Ort";
  }, [resultPage?.townLabel]);

  const consumptionLabel = useMemo(() => {
    return resultPage?.consumptionLabel || "Verbrauch";
  }, [resultPage?.consumptionLabel]);

  const consumptionNTLabel = useMemo(() => {
    return resultPage?.consumptionNTLabel || "Verbrauch NT";
  }, [resultPage?.consumptionNTLabel]);

  const zipCodeLabel = useMemo(() => {
    return resultPage?.zipCodeLabel || "PLZ";
  }, [resultPage?.zipCodeLabel]);

  const tieredPricingLabel = useMemo(() => {
    return resultPage?.tieredPricingLabel || "Preisstaffelung";
  }, [resultPage?.tieredPricingLabel]);

  const yearlyBasePriceLabel = useMemo(() => {
    return resultPage?.yearlyBasePriceLabel || "Jährlicher Grundpreis";
  }, [resultPage?.yearlyBasePriceLabel]);

  const orderNowLabel = useMemo(() => {
    return resultPage?.orderNowLabel || "Jetzt bestellen";
  }, [resultPage?.orderNowLabel]);

  const pricesInclTaxLabel = useMemo(() => {
    return resultPage?.pricesInclTaxLabel || "Alle angegebenen Preise sind inkl. USt.";
  }, [resultPage?.pricesInclTaxLabel]);

  const pricesExclTaxLabel = useMemo(() => {
    return resultPage?.pricesExclTaxLabel || "Alle angegebenen Preise sind zuzüglich USt.";
  }, [resultPage?.pricesExclTaxLabel]);

  const monthsLabel = useMemo(() => {
    return resultPage?.monthsLabel || "Monat(e)";
  }, [resultPage?.monthsLabel]);
  
  const inclBonusesLabel = useMemo(() => {
    return resultPage?.inclBonusesLabel || "inkl. Boni";
  }, [resultPage?.inclBonusesLabel]);

  const yearlyInclBonusesLabel = useMemo(() => {
    return resultPage?.yearlyInclBonusesLabel || "jährlich inkl. Boni";
  }, [resultPage?.yearlyInclBonusesLabel]);
  
  const priceDisplaySelectionLabel = useMemo(() => {
    return resultPage?.priceDisplaySelectionLabel || "Auswahl der Preisanzeige";
  }, [resultPage?.priceDisplaySelectionLabel]);

  const comparisonTariffLabel = useMemo(() => {
    return resultPage?.comparisonTariffLabel || "Vergleichstarif";
  }, [resultPage?.comparisonTariffLabel]);

  const noticePeriodLabel = useMemo(() => {
    return resultPage?.noticePeriodLabel || "Kündigungsfrist";
  }, [resultPage?.noticePeriodLabel]);

  const activationPriceLabel = useMemo(() => {
    return resultPage?.activationPriceLabel || "Aktivierungspreis";
  }, [resultPage?.activationPriceLabel]);

  const pleaseWaitLabel = useMemo(() => {
    return resultPage?.pleaseWaitLabel || "Bitte warten...";
  }, [resultPage?.pleaseWaitLabel]);

  const downloadLabel = useMemo(() => {
    return resultPage?.downloadLabel || "Download";
  }, [resultPage?.downloadLabel]);

  return {
    pricesNetGross,
    isShowNetGross,
    telcoLabel,
    unitUsageLabel,
    strommixLabel,
    onlyGasEcoLabel,
    onlyElectricityEcoLabel,
    showTariffDetailLabel,
    unitPerYearLabel,
    tryAgainLabel,
    tariffTypeLabel,
    twoTariffsLabel,
    oneTariffLabel,
    customerTypeLabel,
    businessLabel,
    privateLabel,
    usageInvalidMessageLabel,
    usageLabel,
    buttontextLabel,
    headerLabel,
    headerOptionsLabel,
    headerSubLabel,
    savingLabel,
    savingTariffLabel,
    cancellationperiodLabel,
    priceInformationLabel,
    priceguaranteeLabel,
    contractperiodLabel,
    co2unitLabel,
    renewableLabel,
    renewableunitLabel,
    fossilLabel,
    nuclearLabel,
    radioactivLabel,
    fossilunitLabel,
    nuclearunitLabel,
    contractextensionLabel,
    totalpriceLabel,
    totalpriceAfterBonusLabel,
    totalpriceBeforeBonusLabel,
    basepriceLabel,
    workingpriceLabel,
    workingpriceunitLabel,
    tariffdescriptionLabel,
    co2Label,
    radioactivunitLabel,
    standLabel,
    isprepaidtariffLabel,
    ispackagetariffLabel,
    isprepaidtarifftrueLabel,
    isprepaidtarifffalseLabel,
    ispackagetarifftrueLabel,
    ispackagetarifffalseLabel,
    yourDiscountLabel,
    ecoTarifLabel,
    bonusLabel,
    bonusChangeLabel,
    backToSearchBtnTextLabel,
    noListProductLabel,
    yesLabel,
    noLabel,
    irrelevantLabel,
    waermepumpeLabel,
    speicherheizungLabel,
    tarifftypeNameLabel,
    autoStromGemeinsameMessungLabel,
    autoStromGetrennteMessungLabel,
    internetSpeedUnitLabel,
    priceMonthlyLabel,
    priceOnceLabel,
    setupCostLabel,
    periodOnceLabel,
    periodMonthlyLabel,
    periodOnceSummaryLabel,
    periodMonthlySummaryLabel,
    backToOptionsBtnTextLabel,
    optionInfoLabel,
    shippingCostsLabel,
    tariffDetailsLabel,
    productDescriptionLabel,
    missingProductDescriptionLabel,
    infinityPeriodLabel,
    TCS_BtnLabel,
    showMonthlyPaymentAmount,
    townLabel,
    consumptionLabel,
    consumptionNTLabel,
    zipCodeLabel,
    tieredPricingLabel,
    yearlyBasePriceLabel,
    orderNowLabel,
    pricesInclTaxLabel,
    pricesExclTaxLabel,
    monthsLabel,
    inclBonusesLabel,
    yearlyInclBonusesLabel,
    priceDisplaySelectionLabel,
    comparisonTariffLabel,
    noticePeriodLabel,
    activationPriceLabel,
    pleaseWaitLabel,
    downloadLabel,
  };
};

export default useFeaturesResult;
