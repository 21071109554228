import { useFormikContext } from "formik";
import * as React from "react";

import GetSelect from "../../../../../components/GetSelect";
import Input from "../../../../../components/Input";
import NotificationBox from "../../../../../components/NotificationBox";
import { useTariff } from "../../../../../context";
import { useConfig } from "../../../../../context/Config";
import useDebounce from "../../../../../hooks/useDebounce";
import useFeatureInputPage from "../../../../TCInputs/hooks/useFeatureInputPage";
import useSimplifierResultPage from "../../../hooks/useSimplifier";
import { InputFormModalFormik } from "../../../types/InputFormModalFormik";

const CitySelect = () => {
  const { city } = useFeatureInputPage();
  const { values } = useFormikContext<InputFormModalFormik>();
  const { data, isLoading } = useSimplifierResultPage(values, "city");

  const disabledCity = React.useMemo(() => {
    return data?.citySuggestions?.length === 0;
  }, [data?.citySuggestions?.length]);
  return (
    <div className="w-full">
      <GetSelect
        label={city}
        name="city"
        isLoading={isLoading}
        disabled={disabledCity}
        options={data?.citySuggestions || []}
      />
    </div>
  );
};

const StreetSelect = ({ modal }: { modal: boolean }) => {
  const { config } = useConfig();
  const { street } = useFeatureInputPage();
  const { values } = useFormikContext<InputFormModalFormik>();
  const { data, isLoading } = useSimplifierResultPage(values, "street");
  const tariff = useTariff();

  const showStreet = React.useMemo(() => {
    return data?.streetSuggestions?.length > 0 || (values?.street?.value !== "" && values?.street !== undefined);
  }, [data?.streetSuggestions, values?.street]);

  return (
    <>
      {showStreet && (
        <div
          className={`flex ${config.media === "desktop" && !modal ? "gap-10" : "gap-2"} ${
            config.media === "desktop" || modal || (!modal && tariff.id === "7") ? "flex-row" : "flex-col"
          }`}
        >
          <div className="w-full">
            <GetSelect
              label={street}
              name="street"
              isLoading={isLoading}
              isSearchable
              options={
                data?.streetSuggestions?.map((opt) => ({
                  label: opt,
                  value: opt,
                })) || []
              }
            />
          </div>
          <HouseNumberSelect />
        </div>
      )}
    </>
  );
};

const HouseNumberSelect = () => {
  const { houseNumber } = useFeatureInputPage();
  const { values } = useFormikContext<InputFormModalFormik>();
  const tariff = useTariff();
  const debounceValue = useDebounce(values, 600);
  const { data } = useSimplifierResultPage(debounceValue, "houseNumber");
  if (data?.houseNumberSuggestions) {
    if (data?.houseNumberSuggestions?.length >= 1 && tariff.id === "7")
      return (
        <div className="w-1/2 lg:w-1/4">
          <GetSelect
            label={houseNumber}
            name="houseNumber"
            isSearchable
            options={
              data?.houseNumberSuggestions.map((opt) => ({
                label: opt,
                value: opt,
              })) || []
            }
          />
        </div>
      );
  } else if (values?.houseNumber || !!data?.houseNumberSuggestions)
    return (
      <div className="w-1/2 lg:w-1/4">
        <Input label={houseNumber} name="houseNumber" inputType="otherNumber" />
      </div>
    );
  return <></>;
};

const TRAddressResultList = ({
  isError,
  isLoading,
  modal = false,
}: {
  isError: boolean;
  isLoading: boolean;
  modal?: boolean;
}) => {
  const { config } = useConfig();
  const { values, setFieldValue } = useFormikContext<InputFormModalFormik>();
  const { zipCode, tariffNotFound } = useFeatureInputPage();
  const tariff = useTariff();

  React.useEffect(() => {
    if (values.zipCode?.length < 5) {
      setFieldValue("city", { value: "", label: "" });
      setFieldValue("street", { value: "", label: "" });
      setFieldValue("houseNumber", "");
    }
  }, [setFieldValue, values.zipCode]);

  return (
    <>
      <div
        className={`${config.media === "desktop" && !modal ? "gap-10 flex" : modal && tariff.id !== "7" ? "gap-4 grid" : "gap-2 flex"} ${
          config.media === "desktop" || modal ? "flex-row" : ""
        }`}
      >
        <div
          className={`${config.media === "desktop" && !modal ? "w-1/4" : modal && tariff.id !== "7" ? "w-full" : "w-1/4"}`}
        >
          <Input label={zipCode} name="zipCode" onlyNumber maxLength={5} tabIndex={1} isLoading={isLoading} />
        </div>
        <CitySelect />
      </div>

      <StreetSelect modal={modal} />

      {isError && <NotificationBox text={tariffNotFound} variant="info" />}
    </>
  );
};

export default TRAddressResultList;
