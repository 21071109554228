import { useField } from "formik";
import React from "react";

import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import Divider from "../../../../components/Divider";
import Chevron from "../../../../components/icons/Chevron";
import Mobile from "../../../../components/View/Mobile";
import Tablet from "../../../../components/View/Tablet";
import { useConfig } from "../../../../context/Config";
import { useSelectTariff } from "../../../../context/Tariff";
import useResponsiveClass from "../../../../hooks/useResponsiveClass";
import formatCurrency from "../../../../utils/formatCurrency";
import Badges from "../../../TCResults/components/ResultsList/Item/Badges";
import Bonus from "../../../TCResults/components/ResultsList/Item/Bonus";
import NameAndTariff from "../../../TCResults/components/ResultsList/Item/NameAndTariff";
import Price, { PriceDetail } from "../../../TCResults/components/ResultsList/Item/Price";
import PriceGuarantee from "../../../TCResults/components/ResultsList/Item/PriceGuarantee";
import RowItem from "../../../TCResults/components/ResultsList/RowItem";
import useFeaturesCC from "../../hooks/useFeaturesCC";
import FooterButtonGroup from "./FooterButtonGroup";
import InfoAccordeon from "./InfoAccordeon";

const SelectedTariff = () => {
  const { config } = useConfig();
  const { selectedTariff } = useSelectTariff();
  const { responsiveClass } = useResponsiveClass();
  const [{ value: showSelectedTariff }, , { setValue: setShowSelectedTariff }] = useField("showSelectedTariff");
  const { priceInclBoni } = useFeaturesCC();

  const priceguarantee = selectedTariff?.informationen?.find((el) => el.bedingung === "Preisgarantie");
  const BAI_BEDINGUNGID_FLEXTARIF = 209;
  const BAI_BEDINGUNGID_DYNTARIF = 208;
  const monthlyPrices = React.useMemo(() => {
    const res = selectedTariff?.dynamischeAngaben?.find((d) =>
      d?.doubleObj?.find((n) => n.name === "grundpreisMonatNetto")
    );
    if (!res || !res.doubleObj || res.doubleObj?.at(0)?.wert) {
      return {};
    }
    return Object.assign(
      {},
      ...res.doubleObj.map((el) => {
        return { [el.name]: el.wert };
      })
    );
  }, [selectedTariff.dynamischeAngaben]);

  if (config.media === "desktop") {
    return (
      <Card key={selectedTariff?.tarifID}>
        <div className="mb-5">
          <Badges
            isOekogasTarif={selectedTariff?.isOekogasTarif}
            isOekostromTarif={selectedTariff?.isOekostromTarif}
            isDynamischerTarif={
              selectedTariff?.informationen?.find((el) => {
                return el.bedingungID === BAI_BEDINGUNGID_DYNTARIF;
              }) !== undefined
            }
            isFlexTarif={
              selectedTariff?.informationen?.find((el) => {
                return el.bedingungID === BAI_BEDINGUNGID_FLEXTARIF;
              }) !== undefined
            }
          />
          <NameAndTariff
            versorgerName={selectedTariff?.versorgerName}
            tarifName={selectedTariff?.tarifName}
            className="text-center mb-4"
          />

          <Price
            isBrutto={selectedTariff?.isBrutto}
            timeframeYear={true}
            gesamtpreisBrutto={selectedTariff?.gesamtpreisBrutto}
            gesamtpreisNetto={selectedTariff?.gesamtpreisNetto}
            ersparnisBrutto={selectedTariff?.ersparnisBrutto}
            ersparnisNetto={selectedTariff?.ersparnisNetto}
            grundpreisBrutto={selectedTariff?.grundpreisBrutto}
            grundpreisNetto={selectedTariff?.grundpreisNetto}
            arbeitspreisHTCtKWhBrutto={selectedTariff?.arbeitspreisHTCtKWhBrutto}
            arbeitspreisCtKWhBrutto={selectedTariff?.arbeitspreisCtKWhBrutto}
            arbeitspreisHTCtKWhNetto={selectedTariff?.arbeitspreisHTCtKWhNetto}
            arbeitspreisCtKWhNetto={selectedTariff?.arbeitspreisCtKWhNetto}
            arbeitspreisNTCtKWhBrutto={selectedTariff?.arbeitspreisNTCtKWhBrutto}
            arbeitspreisNTCtKWhNetto={selectedTariff?.arbeitspreisNTCtKWhNetto}
            gesamtpreisMonatBrutto={monthlyPrices.gesamtpreisMonatBrutto}
            gesamtpreisMonatNetto={monthlyPrices.gesamtpreisMonatNetto}
            grundpreisMonatBrutto={monthlyPrices?.grundpreisMonatBrutto}
            grundpreisMonatNetto={monthlyPrices?.grundpreisMonatNetto}
            dynamischeAngaben={selectedTariff.dynamischeAngaben}
          />

          {selectedTariff?.boni?.length >= 1 && (
            <Bonus boni={selectedTariff?.boni} isBrutto={selectedTariff?.isBrutto} />
          )}
          {selectedTariff?.aufschlaege?.length >= 1 && (
            <Bonus boni={selectedTariff?.aufschlaege} isBrutto={selectedTariff?.isBrutto} />
          )}

          <PriceGuarantee
            priceguarantee={priceguarantee ? priceguarantee.preisgarantieFuerMonate : undefined}
            priceguaranteeDate={priceguarantee ? priceguarantee.preisgarantieBis : undefined}
            priceguaranteeComponents={priceguarantee ? priceguarantee.preisgarantieBestandteile : undefined}
            vertragslaufzeit={selectedTariff?.vertragslaufzeit}
          />
        </div>
        <Divider />
        <InfoAccordeon />
        <FooterButtonGroup />
      </Card>
    );
  } else {
    return (
      <Card key={selectedTariff?.tarifID}>
        <Button onClick={() => setShowSelectedTariff(!showSelectedTariff)} variant="unstyled" className="w-full px-0">
          <Badges
            isOekogasTarif={selectedTariff?.isOekogasTarif}
            isOekostromTarif={selectedTariff?.isOekostromTarif}
            isDynamischerTarif={
              selectedTariff?.informationen?.find((el) => {
                return el.bedingungID === BAI_BEDINGUNGID_DYNTARIF;
              }) !== undefined
            }
            isFlexTarif={
              selectedTariff?.informationen?.find((el) => {
                return el.bedingungID === BAI_BEDINGUNGID_FLEXTARIF;
              }) !== undefined
            }
          />
          <div className={responsiveClass("mb-2", "text-left", "flex text-right justify-between")}>
            <NameAndTariff
              versorgerName={selectedTariff?.versorgerName}
              tarifName={selectedTariff?.tarifName}
              className={config.media === "tablet" ? "text-left" : undefined}
              fixedHeight={false}
            />
            <Tablet>
              <div className="font-medium text-xl text-primary">
                {formatCurrency(
                  selectedTariff?.isBrutto ? selectedTariff?.gesamtpreisBrutto : selectedTariff?.gesamtpreisNetto
                )}
                <div className="text-sm font-normal text-gray-dark">{priceInclBoni}</div>
              </div>
            </Tablet>
            <Mobile>
              <RowItem
                label={priceInclBoni}
                value={formatCurrency(
                  selectedTariff?.isBrutto ? selectedTariff?.gesamtpreisBrutto : selectedTariff?.gesamtpreisNetto
                )}
                className="font-medium text-xl text-primary text-right"
              />
            </Mobile>
          </div>

          <div className="grid justify-center">
            <Chevron rotate={showSelectedTariff} />
          </div>
        </Button>
        {showSelectedTariff && (
          <>
            <div className="mt-4 mb-6">
              <PriceDetail
                isBrutto={selectedTariff?.isBrutto}
                timeframeYear={selectedTariff?.timeframeYear ?? true}
                ersparnisBrutto={selectedTariff?.ersparnisBrutto}
                ersparnisNetto={selectedTariff?.ersparnisNetto}
                grundpreisBrutto={selectedTariff?.grundpreisBrutto}
                grundpreisNetto={selectedTariff?.grundpreisNetto}
                arbeitspreisHTCtKWhBrutto={selectedTariff?.arbeitspreisHTCtKWhBrutto}
                arbeitspreisCtKWhBrutto={selectedTariff?.arbeitspreisCtKWhBrutto}
                arbeitspreisHTCtKWhNetto={selectedTariff?.arbeitspreisHTCtKWhNetto}
                arbeitspreisCtKWhNetto={selectedTariff?.arbeitspreisCtKWhNetto}
                arbeitspreisNTCtKWhBrutto={selectedTariff?.arbeitspreisNTCtKWhBrutto}
                arbeitspreisNTCtKWhNetto={selectedTariff?.arbeitspreisNTCtKWhNetto}
                grundpreisMonatBrutto={monthlyPrices?.grundpreisMonatBrutto}
                grundpreisMonatNetto={monthlyPrices?.grundpreisMonatNetto}
                dynamischeAngaben={selectedTariff.dynamischeAngaben}
              />

              {selectedTariff?.boni?.length >= 1 && (
                <Bonus boni={selectedTariff?.boni} isBrutto={selectedTariff?.isBrutto} />
              )}
              {selectedTariff?.aufschlaege?.length >= 1 && (
                <Bonus boni={selectedTariff?.aufschlaege} isBrutto={selectedTariff?.isBrutto} />
              )}
              <PriceGuarantee
                priceguarantee={priceguarantee ? priceguarantee.preisgarantieFuerMonate : undefined}
                priceguaranteeDate={priceguarantee ? priceguarantee.preisgarantieBis : undefined}
                priceguaranteeComponents={priceguarantee ? priceguarantee.preisgarantieBestandteile : undefined}
                vertragslaufzeit={selectedTariff?.vertragslaufzeit}
              />
            </div>
            <Divider />
            <InfoAccordeon />
            <FooterButtonGroup />
          </>
        )}
      </Card>
    );
  }
};

export default SelectedTariff;
