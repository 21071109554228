import { baseURL, xOrigin } from "../Constant";

const abortController = new AbortController();

const isLocalhost =
  typeof window !== "undefined" && window.location.hostname === "localhost";

const ConfigInstance = async (url, config) => {
  if (!baseURL) {
    throw new Error("Env baseURL is missing");
  }

  try {
    const response = await fetch(`${baseURL}${url}`, {
      headers: {
        ...(process.env.NODE_ENV === "development" || isLocalhost
          ? {
              "x-origin": xOrigin,
            }
          : {}),
      },
      signal: abortController.signal,
      ...config,
    });

    const data = await response.json();

    if (!response.ok && data.error) {
      throw new Error(data.error);
    }

    return { data };
  } catch (error) {
    throw new Error(error.message);
  }
};

export default ConfigInstance;
