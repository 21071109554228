import React from "react";

const Car = ({ className = "fill-[#313131]" }: { className?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={`inline ${className}`}
    >
      <path d="M4 9.99984V10.6665C4 10.8554 3.93611 11.0137 3.80833 11.1415C3.68056 11.2693 3.52222 11.3332 3.33333 11.3332H2.66667C2.47778 11.3332 2.31944 11.2693 2.19167 11.1415C2.06389 11.0137 2 10.8554 2 10.6665V5.33317L3.4 1.33317C3.46667 1.13317 3.58611 0.972059 3.75833 0.849837C3.93056 0.727615 4.12222 0.666504 4.33333 0.666504H11.6667C11.8778 0.666504 12.0694 0.727615 12.2417 0.849837C12.4139 0.972059 12.5333 1.13317 12.6 1.33317L14 5.33317V10.6665C14 10.8554 13.9361 11.0137 13.8083 11.1415C13.6806 11.2693 13.5222 11.3332 13.3333 11.3332H12.6667C12.4778 11.3332 12.3194 11.2693 12.1917 11.1415C12.0639 11.0137 12 10.8554 12 10.6665V9.99984H4ZM3.86667 3.99984H12.1333L11.4333 1.99984H4.56667L3.86667 3.99984ZM5 7.99984C5.27778 7.99984 5.51389 7.90262 5.70833 7.70817C5.90278 7.51373 6 7.27762 6 6.99984C6 6.72206 5.90278 6.48595 5.70833 6.2915C5.51389 6.09706 5.27778 5.99984 5 5.99984C4.72222 5.99984 4.48611 6.09706 4.29167 6.2915C4.09722 6.48595 4 6.72206 4 6.99984C4 7.27762 4.09722 7.51373 4.29167 7.70817C4.48611 7.90262 4.72222 7.99984 5 7.99984ZM11 7.99984C11.2778 7.99984 11.5139 7.90262 11.7083 7.70817C11.9028 7.51373 12 7.27762 12 6.99984C12 6.72206 11.9028 6.48595 11.7083 6.2915C11.5139 6.09706 11.2778 5.99984 11 5.99984C10.7222 5.99984 10.4861 6.09706 10.2917 6.2915C10.0972 6.48595 10 6.72206 10 6.99984C10 7.27762 10.0972 7.51373 10.2917 7.70817C10.4861 7.90262 10.7222 7.99984 11 7.99984ZM8.66667 15.3332L4.66667 13.3332H7.33333V11.9998L11.3333 13.9998H8.66667V15.3332ZM3.33333 8.6665H12.6667V5.33317H3.33333V8.6665Z" />
    </svg>
  );
};
export default Car;
