import * as React from "react";

const Button = ({ disabled, ...props }) => {
  return (
    <>
      {props.variant === "tabNav" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled ? "bg-slate-300" : ""
          } border-0 rounded-full hover:bg-button-primary-bg hover:text-white px-4 text-nav shadow-md`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}

      {props.variant === "unstyled" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled ? "bg-slate-300" : "bg-accent text-primary"
          } border-0 font-trfont rounded`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}

      {props.variant === "primary" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled
              ? "bg-button-primary-bg-light"
              : "bg-button-primary-bg hover:bg-button-primary-bgHover text-button-primary-color"
          } border-0 rounded-full font-trfont shadow-md`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}

      {props.variant === "secondary" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled
              ? "bg-slate-300"
              : "bg-button-secondary-bg hover:bg-button-secondary-bgHover text-button-secondary-color"
          } border-0 rounded-button`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}

      {props.variant === "rounded" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled
              ? "bg-slate-300"
              : "bg-button-secondary-bg hover:bg-button-secondary-bgHover text-button-secondary-color"
          } flex justify-center align-middle border-0 rounded-button font-trfont`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}

      {props.variant === "householder" ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled
              ? "bg-slate-300"
              : "bg-button-secondary-bg hover:bg-button-secondary-bgHover text-button-secondary-color"
          } border-0 rounded`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}

      {props.variant === undefined ? (
        <button
          {...props}
          disabled={disabled}
          className={`${props.className || ""} ${
            disabled ? "" : "cursor-pointer"
          } ${
            disabled
              ? "bg-slate-300"
              : "bg-button-secondary-bg hover:bg-button-secondary-bgHover text-button-secondary-color"
          } border-0 rounded-button`.trim()}
          type={props.type || "button"}
        >
          {props.children}
        </button>
      ) : null}
    </>
  );
};
export default Button;
