import React from "react";

import Button from "../../../../components/Button";
import { useCalcParams } from "../../../../context/CalculationParameter";
import useCreateOffer from "../../hooks/useCreateOffer";

const CreateOffer = ({ formName, children }) => {
  const { mutateAsync } = useCreateOffer(formName);
  const { calcParams } = useCalcParams();

  const handleCreateOffer = React.useCallback(async () => {
    try {
      await mutateAsync(calcParams);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }, [calcParams, mutateAsync]);

  return (
    <Button
      type="button"
      variant="primary"
      className="p-2"
      onClick={handleCreateOffer}
    >
      {children}
    </Button>
  );
};

export default CreateOffer;
