import { differenceInCalendarYears, isValid, parse } from "date-fns";

function isValidDateAsString(date: string) {
  const parsed = parse(date, "dd.MM.yyyy", new Date());

  return date.length==10 && isValid(parsed);
}
function isValidDate(day: string, month: string, year: string) {
  const parsed = parse(`${day}/${month}/${year}`, "dd/MM/yyyy", new Date());
  const age = differenceInCalendarYears(parsed, new Date());

  if (age < 18) {
    return false;
  }

  return isValid(parsed) && age >= 18;
}

export { isValidDate, isValidDateAsString };
