import { useMutation } from "react-query";

import { useConfig, useTariff } from "../../../context";
import { useSelectTariff } from "../../../context/Tariff";
import ProxyInstance from "../../../services/proxy";
import convertKeys from "../utils/convertKeys";
import useQueryParameters from "./useQueryParameters";

const useServerValidationForm = (formName, configValidate) => {
  const { selectedTariff } = useSelectTariff();
  const tariff = useTariff();
  const config = useConfig();
  const param = useQueryParameters({
    calculatorType: tariff.id,
    supplierId: selectedTariff?.versorgerID,
  });

  return useMutation(`VALIDATE_FORM_${formName}`, async (values) => {
    const portalData = convertKeys(
      {
        ...values,
        calculatorType: tariff.id,
        supplierId: selectedTariff?.versorgerID,
      },
      formName
    );

    const body = {
      portalDaten: portalData,
      schluessel: configValidate.keysToValidate,
      tarifParameter: configValidate.tarifParameter,
    };

    try {
      const { data } = await ProxyInstance(`/crm/validate?${param}`, {
        apikey: config.apikey || "",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(body),
      });

      return data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  });
};

export default useServerValidationForm;
