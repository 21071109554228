import { useQuery } from "react-query";

import { useParameter } from "../context";
import { useConfig } from "../context/Config";
import VPPInstance from "../services/vpp";
import useGetKeyCloakToken from "../widgets/TCContractConclusion/hooks/useGetKeyCloakToken";

const useVPPCustomerService = () => {
  const { vppCustomerId, authToken,vppSummaryUrl } = useParameter();
  const { config } = useConfig();

  return useQuery(
    `GET_VPP_CUSTOMER_ID_${vppCustomerId}`,
    async () => {
      try {
        const aktAuthToken:string|undefined=await useGetKeyCloakToken(authToken,config.apikey,vppSummaryUrl);
        const { data } = await VPPInstance(`/customer/get/${vppCustomerId}`, {
          apikey: config.apikey || "",
          authToken: `Bearer ${aktAuthToken}`,
        });

        return data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    {
      enabled: !!vppCustomerId && !!authToken && !!config.apikey,
      refetchOnWindowFocus:false
    }
  );
};

export default useVPPCustomerService;
