import * as React from "react";

const NotFound = () => {
  return (

      <svg width="546" height="354" viewBox="0 0 546 354" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M474.995 277.955C468.27 268.843 455.993 248.928 460.678 242.159C465.364 235.39 480.417 225.454 487.358 221.332L497.77 238.905C501.675 235.651 510.134 229.403 512.737 230.444C515.27 231.457 517.803 233.259 521.256 249.969C528.611 254.742 543.452 265.589 543.973 270.796C544.624 277.304 533.561 294.226 529.657 294.877C527.879 295.173 520.535 293.937 512.737 292.417C507.531 299.095 488.009 307.503 451.567 287.718C451.567 277.721 451.208 258.006 449.797 255.985L449.615 255.827C449.678 255.847 449.738 255.901 449.797 255.985L474.995 277.955Z" fill="white"/>
          <path d="M497.77 238.905L487.358 221.332C480.417 225.454 465.364 235.39 460.678 242.159C455.993 248.928 468.27 268.843 474.995 277.955L449.615 255.827C451.177 256.347 451.567 277.304 451.567 287.718C488.009 307.503 507.531 299.095 512.737 292.417M497.77 238.905L486.057 249.318M497.77 238.905C501.675 235.651 510.134 229.403 512.737 230.444C515.27 231.457 517.803 233.259 521.256 249.969M524.451 268.193C523.297 260.692 522.239 254.727 521.256 249.969M521.256 249.969C528.611 254.742 543.452 265.589 543.973 270.796C544.624 277.304 533.561 294.226 529.657 294.877C527.879 295.173 520.535 293.937 512.737 292.417M496.469 289.019C500.674 289.954 506.836 291.267 512.737 292.417" stroke="#969696" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M454.821 289.67C458.986 281.86 469.571 277.738 474.343 276.653L450.266 257.779L451.567 287.067L454.821 289.67Z" fill="#F2F2F2"/>
          <path d="M465.883 294.877C478.898 289.67 493.431 289.236 499.071 289.67L512.086 292.273C500.632 303.728 476.512 298.782 465.883 294.877Z" fill="#F2F2F2"/>
          <path d="M529.005 276.653C529.005 277.174 535.513 267.759 538.767 262.986L522.498 251.271L524.45 265.589C529.005 271.447 529.005 276.003 529.005 276.653Z" fill="#F2F2F2"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M463.251 197.722C465.561 164.8 411.65 137.442 342.837 136.617C274.024 135.792 216.368 161.812 214.057 194.735C213.962 196.098 213.962 197.452 214.056 198.794V214.26C213.709 219.216 214.635 224.046 216.694 228.679L174.315 245.593C169.807 242.05 165.686 240.469 164.125 241.98L23.8174 297.981C23.2751 298.505 23.0956 299.351 23.2344 300.425L23.23 300.428L23.1977 308.377C23.3871 311.354 26.3297 316.364 30.9238 321.112C36.9112 327.299 42.5203 330.197 45.4983 328.702L185.806 272.701C186.185 272.334 186.387 271.811 186.427 271.163V265.419L232.129 247.177C253.255 263.669 290.872 274.843 334.47 275.365C403.283 276.19 460.94 250.17 463.25 217.247V197.734L463.251 197.722Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M334.475 255.84C403.288 256.665 460.945 230.645 463.255 197.722L463.255 177.726L214.061 177.726L214.061 194.735C211.751 227.658 265.662 255.015 334.475 255.84Z" fill="#C7C7C7" stroke="#969696" strokeWidth="3"/>
          <ellipse cx="59.7582" cy="124.606" rx="59.7582" ry="124.606" transform="matrix(0.0700018 -0.997547 0.999928 0.0119871 209.877 234.822)" fill="#F7F7F7" stroke="#969696" strokeWidth="3"/>
          <ellipse cx="48.6482" cy="101.44" rx="48.6482" ry="101.44" transform="matrix(0.0700018 -0.997547 0.999928 0.0119871 233.82 224.016)" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M422.17 173.753C421.846 178.372 419.817 182.779 416.381 186.838M296.04 203.917C308.419 208.135 323.492 210.689 339.89 210.886C369.619 211.242 396.089 203.756 410.571 192.368" stroke="#969696" strokeWidth="3" strokeLinecap="round"/>
          <path d="M138.456 256.646C142.206 260.521 145.718 262.335 147.583 261.399L235.454 226.327C235.691 226.098 235.818 225.77 235.843 225.364L235.843 220.031L134.005 243.461L134.004 247.366C132.713 248.616 134.706 252.77 138.456 256.646Z" fill="#949494"/>
          <path d="M30.9111 301.587C36.8985 307.774 42.5075 310.671 45.4856 309.177L185.793 253.176C186.172 252.809 186.374 252.286 186.414 251.638L186.414 243.122L23.8035 280.534L23.2173 280.903L23.185 288.852C23.3743 291.828 26.317 296.839 30.9111 301.587Z" fill="#C7C7C7" stroke="#969696" strokeWidth="3"/>
          <path d="M231.002 211.841C227.252 207.966 223.165 205.837 221.874 207.087L134.003 242.159C132.712 243.409 134.705 247.564 138.455 251.439C142.204 255.314 145.717 257.128 147.582 256.192L235.453 221.12C236.744 219.87 234.751 215.716 231.002 211.841Z" fill="#969696"/>
          <path d="M178.687 230.045C172.7 223.857 166.174 220.459 164.112 222.455L23.8047 278.455C21.7428 280.451 24.9251 287.085 30.9125 293.273C36.9 299.46 42.509 302.357 45.487 300.863L185.795 244.862C187.857 242.866 184.674 236.232 178.687 230.045Z" fill="#F7F7F7" stroke="#969696" strokeWidth="3"/>
          <path d="M78.0413 17.6201L109.277 2L106.674 26.7318H127.498C133.224 22.5665 149.84 23.2607 157.432 24.1285C188.147 29.3352 194.524 43.2197 193.874 49.5111C202.203 49.5111 212.094 55.1517 215.999 57.972L201.032 73.5921L196.477 97.6731L214.047 117.849C216.65 143.362 210.793 152.343 207.539 153.645C185.154 159.893 136.174 162.323 114.483 162.757C100.817 159.937 71.4038 152.343 63.0743 144.533C54.7448 136.723 52.2286 123.056 52.0117 117.198L61.7728 112.642C55.5256 102.229 59.1698 91.8155 61.7728 87.9105L63.725 76.8463L68.9309 71.6396V50.8128L78.0413 17.6201Z" fill="white"/>
          <path d="M68.9309 71.6396V50.8128L78.0413 17.6201L109.277 2L106.674 26.7318M68.9309 71.6396L63.725 76.8463L61.7728 87.9105C59.1698 91.8155 55.5256 102.229 61.7728 112.642M68.9309 71.6396L96.2622 48.8603M61.7728 112.642L52.0117 117.198C52.2286 123.056 54.7448 136.723 63.0743 144.533C71.4038 152.343 100.817 159.937 114.483 162.757C136.174 162.323 185.154 159.893 207.539 153.645C210.793 152.343 216.65 143.362 214.047 117.849L196.477 97.6731L201.032 73.5921M61.7728 112.642L81.2951 106.134M201.032 73.5921L215.999 57.972C212.094 55.1517 202.203 49.5111 193.874 49.5111C194.524 43.2197 188.147 29.3352 157.432 24.1285C149.84 23.2607 133.224 22.5665 127.498 26.7318M201.032 73.5921L173.701 100.886M127.498 26.7318H106.674M127.498 26.7318L145.719 28.0335C156.564 41.4841 177.735 69.0362 175.653 71.6396C173.57 74.2429 153.961 74.4599 144.417 74.2429M106.674 26.7318L105.373 46.9078L134.401 106.785M144.417 74.2429L135.958 69.0362L140.513 89.2122L134.401 106.785M144.417 74.2429L163.289 82.053L173.701 100.886M193.874 137.374L173.701 100.886M71.534 137.374L130.101 119.151L134.401 106.785" stroke="#969696" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M70.2322 141.279L76.7397 136.073L121.641 121.103L122.292 108.087L98.8649 79.4497L107.325 109.388L89.1038 130.866L66.9785 131.517L70.2322 141.279Z" fill="#F2F2F2"/>
          <path d="M158.082 145.835L130.1 162.106L199.729 155.598L186.063 136.723L156.78 110.69L150.923 126.31C157.691 132.037 158.515 141.713 158.082 145.835Z" fill="#F2F2F2"/>
          <path d="M165.241 85.958C170.88 82.7038 182.16 75.2843 182.16 71.6396C182.16 68.0722 175.094 62.1355 171.337 59.444L176.303 71.6396L171.748 72.9413L157.432 74.2429L150.273 75.5446L161.336 80.7513L165.241 85.958Z" fill="#F2F2F2"/>
          <path d="M196.477 97.6732L200.381 76.1956L174.352 100.277L191.922 133.469C193.657 138.242 198.299 147.267 202.984 145.184C207.67 143.102 209.709 123.924 210.142 114.595L196.477 97.6732Z" fill="#F2F2F2"/>
          <path d="M79.9919 61.2263C74.7859 29.9861 94.742 9.59319 105.371 3.30176L78.0404 17.6202L68.93 50.8129L68.2793 70.9888L79.9919 61.2263Z" fill="#F2F2F2"/>
          <path d="M124.893 54.7177C123.852 40.6597 112.312 33.2401 106.673 31.2876L105.371 46.9077L134.655 104.832L140.511 89.2121L136.607 71.6395C133.136 71.8565 125.935 68.7758 124.893 54.7177Z" fill="#F2F2F2"/>
          <path d="M68.9277 98.9749C64.7629 93.7682 70.663 76.4125 74.1336 68.3855L63.7238 77.4972L61.7708 88.5615C56.0452 100.537 59.3851 109.605 61.7708 112.642L79.9915 106.785C78.0389 106.351 73.0924 104.182 68.9277 98.9749Z" fill="#F2F2F2"/>
          <path d="M8.41206 158.852L6.45983 149.089C14.5971 127.546 38.0279 120.125 51.3604 118.732C52.9221 152.241 89.1034 155.597 115.133 162.757C157.301 162.757 193.439 156.682 206.237 153.645V155.597C205.196 159.242 183.244 167.529 172.398 171.217C138.56 209.747 65.6767 199.854 53.3126 192.695L38.3455 183.583C29.6689 184.885 11.9254 187.488 10.3636 187.488C8.41136 187.488 6.45983 182.282 1.25389 174.472C-2.91086 168.224 4.29069 161.455 8.41206 158.852Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M68.9309 71.6396V50.8128L78.0413 17.6201L109.277 2L106.674 26.7318M68.9309 71.6396L63.725 76.8463L61.7728 87.9105C59.1698 91.8155 55.5256 102.229 61.7728 112.642M68.9309 71.6396L96.2622 48.8603M61.7728 112.642L52.0117 117.198C52.2286 123.056 54.7448 136.723 63.0743 144.533C71.4038 152.343 100.817 159.937 114.483 162.757C136.174 162.323 185.154 159.893 207.539 153.645C210.793 152.343 216.65 143.362 214.047 117.849L196.477 97.6731L201.032 73.5921M61.7728 112.642L81.2951 106.134M201.032 73.5921L215.999 57.972C212.094 55.1517 202.203 49.5111 193.874 49.5111M201.032 73.5921L173.701 100.886M193.874 49.5111C194.524 43.2197 188.147 29.3352 157.432 24.1285C149.84 23.2607 133.224 22.5665 127.498 26.7318M193.874 49.5111L158.733 44.7001M127.498 26.7318H106.674M127.498 26.7318L145.719 28.0335C149.326 32.5078 154.077 38.5424 158.733 44.7001M106.674 26.7318L105.373 46.9078L134.401 106.785M144.417 74.2429C153.961 74.4599 173.57 74.2429 175.653 71.6396C177.042 69.9022 168.076 57.0536 158.733 44.7001M144.417 74.2429L135.958 69.0362L140.513 89.2122L134.401 106.785M144.417 74.2429L163.289 82.053L173.701 100.886M193.874 137.374L173.701 100.886M71.534 137.374L130.101 119.151L134.401 106.785" stroke="#969696" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M510.784 262.335C512.346 258.69 510.567 240.423 509.482 231.746C518.333 227.06 521.847 248.45 522.497 259.732C517.942 262.118 509.222 265.98 510.784 262.335Z" fill="#F2F2F2"/>
          <path d="M472.391 247.366C470.829 241.638 474.777 232.396 476.946 228.491C473.692 230.444 466.013 235.651 461.328 240.857C456.643 246.064 464.148 261.684 468.486 268.843L489.31 275.352C484.321 268.409 473.953 253.093 472.391 247.366Z" fill="#F2F2F2"/>
          <path d="M435.948 302.687C419.289 291.753 438.985 282.945 450.915 279.907L451.566 287.067C453.519 290.104 462.499 296.829 482.802 299.433C503.105 302.036 511.218 295.745 512.736 292.273L530.306 294.877C513.647 324.034 489.526 320.91 479.548 315.704C469.657 311.017 461.544 312.449 458.725 313.751C431.133 318.958 432.044 308.544 435.948 302.687Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M497.77 238.905L487.358 221.332C480.417 225.454 465.364 235.39 460.678 242.159C455.993 248.928 468.27 268.843 474.995 277.955L449.615 255.827C451.177 256.347 451.567 277.304 451.567 287.718C488.009 307.503 507.531 299.095 512.737 292.417M497.77 238.905L486.057 249.318M497.77 238.905C501.675 235.651 510.134 229.403 512.737 230.444C515.27 231.457 517.803 233.259 521.256 249.969M524.451 268.193C523.297 260.692 522.239 254.727 521.256 249.969M521.256 249.969C528.611 254.742 543.452 265.589 543.973 270.796C544.624 277.304 533.561 294.226 529.657 294.877C527.879 295.173 520.535 293.937 512.737 292.417M496.469 289.019C500.674 289.954 506.836 291.267 512.737 292.417" stroke="#969696" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M401.462 76.8867C398.208 80.1408 400.163 89.9438 408.62 84.0459C417.077 78.1479 404.064 72.9815 401.462 76.8867Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M432.74 85.462C429.305 84.3121 422.382 88.8486 429.352 92.6747C436.322 96.5008 436.482 85.956 432.74 85.462Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M428.324 113.962C427.185 117.4 431.744 124.309 435.547 117.326C439.349 110.342 428.805 110.217 428.324 113.962Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M482.625 115.328C486.215 114.851 490.441 107.734 482.499 107.36C474.557 106.987 479.046 116.529 482.625 115.328Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M513.747 113.648C515.985 113.351 518.618 108.915 513.668 108.682C508.719 108.449 511.517 114.396 513.747 113.648Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M503.571 96.1721C502.86 94.0295 498.009 92.2734 498.708 97.1797C499.407 102.086 504.724 98.223 503.571 96.1721Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M450.599 102.888C448.575 96.3657 456.14 90.7929 462.964 90.5222C469.312 90.2704 480.296 95.6247 475.977 100.285C474.117 102.293 472.102 102.136 469.47 102.888C462.384 104.913 452.784 109.927 450.599 102.888Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M455.893 78.6719C456.368 83.6236 449.492 86.0815 444.099 84.9696C439.083 83.9352 431.389 78.0922 435.566 75.6539C437.366 74.6034 438.918 75.0972 441.108 75.0727C447.005 75.0069 455.381 73.3279 455.893 78.6719Z" fill="#5E5E5E" fillOpacity="0.4"/>
          <path d="M404.065 74.2834C400.811 77.5376 402.767 87.3405 411.223 81.4426C419.68 75.5447 406.668 70.3782 404.065 74.2834Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M435.343 82.8587C431.909 81.7088 424.986 86.2454 431.955 90.0715C438.925 93.8976 439.085 83.3527 435.343 82.8587Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M430.927 111.358C429.789 114.797 434.348 121.706 438.15 114.723C441.952 107.739 431.409 107.614 430.927 111.358Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M485.229 112.725C488.819 112.248 493.044 105.13 485.102 104.757C477.161 104.383 481.65 113.926 485.229 112.725Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M516.351 111.044C518.588 110.747 521.221 106.311 516.272 106.078C511.322 105.846 514.12 111.793 516.351 111.044Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M506.174 93.5688C505.463 91.4262 500.613 89.6701 501.312 94.5764C502.01 99.4827 507.327 95.6198 506.174 93.5688Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M453.203 100.285C451.179 93.7624 458.744 88.1897 465.567 87.919C471.915 87.6671 482.899 93.0215 478.581 97.6816C476.72 99.6893 474.705 99.5329 472.073 100.285C464.988 102.31 455.388 107.324 453.203 100.285Z" fill="white" stroke="#969696" strokeWidth="3"/>
          <path d="M458.497 76.0686C458.972 81.0203 452.095 83.4782 446.703 82.3664C441.686 81.3319 433.992 75.4889 438.17 73.0506C439.969 72.0001 441.522 72.4939 443.712 72.4694C449.608 72.4036 457.984 70.7247 458.497 76.0686Z" fill="white" stroke="#969696" strokeWidth="3"/>
      </svg>
  );
};

export default NotFound;
